import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { useFeatureFlag } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { CompanyFeatureFlags } from '@amal-ia/tenants/companies/types';

import { PaymentsLockView } from './paymentsLock/PaymentsLock.view';

const RateListPage = lazy(() => import('./rates'));
const ApiKeySettings = lazy(() => import('./settings/ApiKeySettings/ApiKeySettings'));
const CompanyPage = lazy(() => import('./settings/CompanyPage'));
const WorkflowContainer = lazy(() => import('./workflows/WorkflowContainer'));
const CustomizationPage = lazy(() => import('./settings/CustomizationPage'));

export const CompanyRoutes = memo(function CompanyRoutes() {
  const { formatMessage } = useIntl();
  const { isFeatureEnabled: isPaymentsLockEnabled } = useFeatureFlag(CompanyFeatureFlags.PAYMENTS_LOCK);

  return (
    <RootRoutes>
      <Route
        path={routes.COMPANY_WORKFLOWS}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Workflow)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Workflows' })} />
            <WorkflowContainer />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.COMPANY_RATES}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_rates, SubjectsEnum.Company)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Currency' })} />
            <RateListPage />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.COMPANY_API}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.ApiKeys)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Company API' })} />
            <ApiKeySettings />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.COMPANY_PAYMENTS_LOCK}
        element={
          <AbilityRouteProtector
            can={(ability) => !!isPaymentsLockEnabled && ability.can(ActionsEnum.modify_settings, SubjectsEnum.Company)}
          >
            <AmaliaHead title={formatMessage({ defaultMessage: 'Payments lock' })} />
            <PaymentsLockView />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.COMPANY_CUSTOMIZATION}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_settings, SubjectsEnum.Company)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Company customization' })} />
            <CustomizationPage />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.COMPANY_SETTINGS}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_settings, SubjectsEnum.Company)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Company' })} />
            <CompanyPage />
          </AbilityRouteProtector>
        }
      />
    </RootRoutes>
  );
});
