import { defineMessages } from 'react-intl';

export const formulaPortalMessages = defineMessages({
  CONDITION_NAME: {
    defaultMessage: 'Condition name',
  },

  HIDE_CONDITION: {
    defaultMessage: 'Hide condition formula',
  },

  FORMULA: {
    defaultMessage: 'Formula',
  },
} as const);
