import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniq } from 'lodash';
import { memo, useMemo } from 'react';

import { type BadgeAward, type BadgeConfiguration } from '@amal-ia/compensation-definition/plans/types';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { AvatarGroupWithUserToolTip, Tooltip, Text, TextType } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { BADGE_LOGOS } from './badges.enum';
import { BadgeSummary } from './BadgeSummary';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  card: {
    padding: theme.spacing(2, 0),
    width: '33.33%',
    flex: '0 0 calc(33.33% - 1.4rem)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
  },
  itemIcon: {
    display: 'block',
    width: theme.spacing(10),
  },
  itemTitle: {
    width: theme.spacing(25),
    whiteSpace: 'normal',
  },
}));

interface BadgeCardProps {
  readonly configuration: BadgeConfiguration;
  readonly awards: BadgeAward[];
  readonly userMap: UsersMap;
}

export const BadgeCard = memo(function BadgeCard({ configuration, awards, userMap }: BadgeCardProps) {
  const classes = useStyles();

  const Logo = BADGE_LOGOS[configuration.icon].iconColor;

  const users = useMemo(() => {
    const uniqUserIds = uniq(awards.map((a) => a.userId));
    return Object.values(userMap).filter((u) => uniqUserIds.includes(u.id));
  }, [userMap, awards]);

  return (
    <Box className={classes.card}>
      <Box className={classes.itemIcon}>
        <Logo />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        ml={amaliaTheme.spacing(2.5)}
      >
        <Text
          className={classes.itemTitle}
          type={TextType.BADGE_TITLE}
        >
          {configuration.description ? (
            <Tooltip title={<BadgeSummary badgeConfiguration={configuration} />}>
              <Box>{configuration.name}</Box>
            </Tooltip>
          ) : (
            configuration.name
          )}
        </Text>
        <Box
          display="flex"
          flexDirection="row"
        >
          <AvatarGroupWithUserToolTip users={users} />
        </Box>
      </Box>
    </Box>
  );
});
