import { Box } from '@mui/material';
import { memo } from 'react';

import { type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { type Filter, type PlansMap, type Rule } from '@amal-ia/compensation-definition/plans/types';
import { type KeysOfStringValues } from '@amal-ia/ext/typescript';
import { Text, TextType } from '@amal-ia/lib-ui';

interface DesignerItemWithContextProps {
  readonly object?: Filter | Rule | Variable;
  readonly nameAccessor?: string;
  readonly planIdAccessor?: string;
  readonly nameOverride?: string;
  readonly planNameOverride?: string;
  readonly plansMap: PlansMap;
}

export const DesignerItemWithContext = memo(function DesignerItemWithContext({
  object,
  nameAccessor,
  planIdAccessor,
  nameOverride,
  planNameOverride,
  plansMap,
}: DesignerItemWithContextProps) {
  const planId = object?.[(planIdAccessor as KeysOfStringValues<typeof object>) || 'planId'];
  const planName = planNameOverride || (planId && plansMap?.[planId]?.name);

  return (
    <Box
      display="inline-flex"
      flex="1"
      flexDirection="column"
    >
      <Box>
        <Text type={TextType.DESIGNER_OBJECT}>
          {nameOverride || object?.[(nameAccessor as keyof typeof object) || 'name']}
        </Text>
      </Box>
      {!!planName && (
        <Text
          style={{ opacity: 0.8 }}
          type={TextType.STEPPER_ITEM}
        >
          {planName}
        </Text>
      )}
    </Box>
  );
});
