import Skeleton from '@mui/lab/Skeleton';
import { Box, type BoxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo, type CSSProperties, type SVGProps, type FC } from 'react';
import {
  Bar,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ComposedChart as Chart,
  type LineProps,
  type CurveProps,
  type BarProps,
  Scatter,
  type ScatterProps,
  ReferenceLine as RefLine,
  type XAxisProps,
  type ReferenceLineProps,
  type YAxisProps,
  Tooltip,
} from 'recharts';
import { type PresentationAttributesAdaptChildEvent } from 'recharts/types/util/types';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';

import { useStateInLocalStorage } from '../../../../utils/hooks/useStateInLocalStorage';

import { Actions } from './Actions';

export type Stat = Record<string, number | string>;
export enum PlotTypesEnum {
  LINE = 'Line',
  BAR = 'Bar',
  DOT = 'Dot',
  REFERENCELINE = 'ReferenceLine',
}
export interface PlotDefinition {
  type: PlotTypesEnum;
  LineProps?: LineProps & Omit<CurveProps, 'pathRef' | 'points'>;
  BarProps?: Omit<BarProps & Omit<PresentationAttributesAdaptChildEvent<any, SVGPathElement>, 'radius'>, 'dataKey'>;
  DotProps?: Omit<PresentationAttributesAdaptChildEvent<any, SVGElement> & ScatterProps, 'dataKey'>;
  ReferenceLineProps?: ReferenceLineProps & SVGProps<SVGLineElement>;
  [key: string]: any;
}
export type PlotDefinitions = Record<string, PlotDefinition>;
export interface ActionButtonsProps {
  layout: 'column-reverse' | 'column' | 'row-reverse' | 'row';
  containerStyle?: BoxProps;
  CustomButton?: FC<any>;
  buttonStyle?: CSSProperties;
  labels?: Record<string, string>;
  colors?: string[];
}
export interface ComposedChartProps {
  readonly plots?: PlotDefinitions;
  readonly data?: Stat[];
  readonly XaxisProps?: (Omit<SVGProps<SVGElement>, 'scale'> & XAxisProps)[];
  readonly YaxisProps?: (Omit<SVGProps<SVGElement>, 'scale'> & YAxisProps)[];
  readonly actionButtons?: ActionButtonsProps;
  readonly width?: string;
  readonly height?: string;
  readonly minHeight?: string;
  readonly CustomTooltip?: FC<{ labels?: Record<string, string>; currency?: CurrencySymbolsEnum }>;
  readonly currency?: CurrencySymbolsEnum;
  readonly loading?: boolean;
}
const useStyles = makeStyles(() => ({
  loadingContainer: {
    width: '99%',
    height: '99%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'end',
  },
  skeleton: {
    width: amaliaTheme.spacing(1),
    marginBlock: amaliaTheme.spacing(1),
    borderRadius: amaliaTheme.spacing(1, 1, 0, 0),
  },
}));
export const ComposedChart = memo(function ComposedChart({
  height = '300px',
  minHeight = '300px',
  width,
  data = [],
  plots,
  XaxisProps = [{}],
  YaxisProps = [{}],
  actionButtons: ActionButtons,
  CustomTooltip = () => null,
  currency,
  loading = true,
}: ComposedChartProps) {
  const classes = useStyles();
  const [hidden, setHidden] = useStateInLocalStorage<string[]>([], 'hide_charts_items');
  const graphs = useMemo(
    () =>
      Object.keys(plots)
        .filter((plot) => !hidden.includes(plot))
        .map((plot, index) => {
          if (plots[plot].type === PlotTypesEnum.LINE) {
            return (
              <Line
                key={index}
                {...({
                  type: 'monotone',
                  dataKey: plot,
                  strokeWidth: 1,
                  ...plots[plot].LineProps,
                } as any)}
              />
            );
          }
          if (plots[plot].type === PlotTypesEnum.BAR) {
            return (
              <Bar
                key={index}
                {...({
                  dataKey: plot,
                  strokeWidth: 1,
                  ...plots[plot].BarProps,
                } as any)}
              />
            );
          }
          if (plots[plot].type === PlotTypesEnum.DOT) {
            return (
              <Scatter
                key={index}
                {...({
                  dataKey: plot,
                  strokeWidth: 1,
                  ...plots[plot].DotProps,
                } as any)}
              />
            );
          }
          if (plots[plot].type === PlotTypesEnum.REFERENCELINE) {
            return (
              <RefLine
                key={index}
                {...({ ...plots[plot].ReferenceLineProps } as any)}
              />
            );
          }
          return null;
        }),
    [hidden, plots],
  );
  const xAxe = useMemo(
    () =>
      XaxisProps.map((axisProps, index) => (
        <XAxis
          key={index}
          {...({
            type: 'monotone',
            dataKey: 'X',
            strokeWidth: 1,
            ...axisProps,
          } as any)}
        />
      )),
    [XaxisProps],
  );

  const yAxe = useMemo(
    () =>
      YaxisProps.map((axisProps, index) => (
        <YAxis
          key={index}
          {...({
            hide: true,
            ...axisProps,
          } as any)}
        />
      )),
    [YaxisProps],
  );
  return (
    <Box
      height={height}
      minHeight={minHeight}
      width={width}
      {...(ActionButtons ? { flexDirection: ActionButtons.layout, display: 'flex' } : {})}
    >
      <Box
        height="100%"
        width={
          ActionButtons && (ActionButtons?.containerStyle?.width || ActionButtons?.buttonStyle?.width)
            ? `calc(100% - ${
                ActionButtons?.buttonStyle?.width ||
                ActionButtons?.containerStyle?.width ||
                ActionButtons?.buttonStyle?.width
              } - 50px)`
            : '100%'
        }
      >
        {!loading && (
          <ResponsiveContainer
            className="auto-width"
            height="100%"
            width="99%"
          >
            <Chart data={data}>
              {xAxe}
              {yAxe}
              <Tooltip
                active
                cursor={false}
                content={({ ...props }) => (
                  <CustomTooltip
                    labels={ActionButtons?.labels}
                    {...props}
                    currency={currency}
                  />
                )}
              />
              {graphs}
            </Chart>
          </ResponsiveContainer>
        )}
        {loading ? (
          <Box className={classes.loadingContainer}>
            {Array(12)
              .fill(0)
              .map((_x, idx) => (
                <Skeleton
                  key={idx}
                  className={classes.skeleton}
                  style={{ height: amaliaTheme.spacing(30 - Math.random() * (10 + idx) - idx * 1.5) }}
                  variant="rectangular"
                />
              ))}
          </Box>
        ) : null}
      </Box>
      <Actions
        {...{
          ActionButtons,
          XaxisProps,
          YaxisProps,
          data,
          hidden,
          plots,
          setHidden,
          loading,
        }}
      />
    </Box>
  );
});
