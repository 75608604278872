import {
  type WorkflowCondition,
  WorkflowConditionType,
  type WorkflowOptions,
  WorkflowStatementStateAction,
  type WorkflowStep,
} from '@amal-ia/lib-types';
import { type UserContract, UserRole } from '@amal-ia/tenants/users/shared/types';

import { type AuthenticatedContext } from '../authenticatedContext';

const checkCondition = (
  authenticatedContext: AuthenticatedContext,
  statementUser: UserContract,
  condition: WorkflowCondition,
): WorkflowStatementStateAction => {
  let authorized = false;
  switch (condition.conditionType) {
    case WorkflowConditionType.OWNER:
      authorized = authenticatedContext.user.id === statementUser.id;
      break;
    case WorkflowConditionType.ROLE:
      authorized = authenticatedContext.user.role === condition.role;
      break;
    case WorkflowConditionType.USER:
      authorized = condition.userId === authenticatedContext.user.id;
      break;
  }
  return authorized ? WorkflowStatementStateAction.NEXT : WorkflowStatementStateAction.UNAUTHORIZED;
};

const canValidStep = (
  authenticatedContext: AuthenticatedContext,
  statementUser: UserContract,
  step: WorkflowStep,
  options?: WorkflowOptions,
): WorkflowStatementStateAction => {
  const conditions = step.conditions.map((condition) => checkCondition(authenticatedContext, statementUser, condition));

  // Once we computed conditions on current workflow, check if we're already authorized or not
  const authorizedAction =
    conditions.find((elm) => elm !== WorkflowStatementStateAction.UNAUTHORIZED) ||
    WorkflowStatementStateAction.UNAUTHORIZED;

  // If we're still unauthorized, but user is an admin and he can skip, then return force
  if (
    authorizedAction === WorkflowStatementStateAction.UNAUTHORIZED &&
    options?.adminCanSkip &&
    authenticatedContext.user.role === UserRole.ADMIN
  ) {
    return WorkflowStatementStateAction.FORCE;
  }
  return authorizedAction;
};

const canResetSteps = (
  authenticatedContext: AuthenticatedContext,
  statementUser: UserContract,
  reviewed?: boolean,
): WorkflowStatementStateAction => {
  if (reviewed && authenticatedContext.user.role !== UserRole.ADMIN) {
    return WorkflowStatementStateAction.UNAUTHORIZED;
  }
  if (authenticatedContext.user.id === statementUser.id) {
    return WorkflowStatementStateAction.RESET;
  }
  if ([UserRole.READ_ONLY_MANAGER, UserRole.READ_ONLY_ADMIN].includes(authenticatedContext.user.role)) {
    return WorkflowStatementStateAction.UNAUTHORIZED;
  }
  const authorized =
    authenticatedContext.user.role === UserRole.ADMIN ||
    authenticatedContext.hierarchy.isManagerOf(statementUser.id, new Date()); // TODO: Should be at statement date.
  return authorized ? WorkflowStatementStateAction.RESET : WorkflowStatementStateAction.UNAUTHORIZED;
};

export const WorkflowUtils = {
  canValidStep,
  canResetSteps,
};
