import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AlertBanner, PageHeader } from '@amal-ia/frontend/design-system/components';
import { selectStatementPaymentAmountByCategory, type CreateAdjustmentDto } from '@amal-ia/frontend/web-data-layers';
import { CalculationStatus, type Calculation } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { type StatementThread } from '@amal-ia/payout-collaboration/comments/shared/types';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

import { CalculationError } from '../../common/CalculationError';
import { StatementSummary } from '../statement-detail-summary/StatementDetailSummary';
import { type StatementViewEnum } from '../StatementDetail';

import { StatementDetailHeaderActions } from './actions/StatementDetailHeaderActions';
import { StatementDetailHeaderBreadcrumbs } from './breadcrumbs/StatementDetailHeaderBreadcrumbs';
import * as styles from './StatementDetailHeader.styles';
import StatementDetailHeaderPayoutQuotaError from './StatementDetailHeaderPayoutQuotaError';
import { StatementDetailHeaderUserInformations } from './user-informations/StatementDetailHeaderUserInformations';

interface StatementDetailHeaderProps {
  readonly setSelectAdjustment: (adjustment: CreateAdjustmentDto) => void;
  readonly onClickGoToStatement: (period: string) => void;
  readonly statementCommentThread: StatementThread | null;
  readonly isTracingActive: boolean;
  readonly lastCalculation?: Calculation;
  readonly handleForecastedSwitch: (nextState: StatementViewEnum) => Promise<void>;
}

const StatementDetailHeader = memo(function StatementDetailHeader({
  setSelectAdjustment,
  onClickGoToStatement,
  statementCommentThread,
  isTracingActive,
  lastCalculation,
  handleForecastedSwitch,
}: StatementDetailHeaderProps) {
  const statement = useStatementDetailContext();
  const { data: company } = useCurrentCompany();
  const paymentTotalByType = useSelector(selectStatementPaymentAmountByCategory);

  return (
    <Fragment>
      <PageHeader
        stickyRow={
          <div css={{ padding: '4px 0' }}>
            <PageHeader.Row
              left={<StatementDetailHeaderBreadcrumbs statement={statement} />}
              right={
                <StatementDetailHeaderActions.StickyRow
                  companyFeatureFlags={company.featureFlags}
                  handleForecastedSwitch={handleForecastedSwitch}
                  paymentTotalByType={paymentTotalByType}
                  setSelectAdjustment={setSelectAdjustment}
                  statementCommentThread={statementCommentThread}
                />
              }
            />
          </div>
        }
      >
        <PageHeader.Row
          left={<StatementDetailHeaderBreadcrumbs statement={statement} />}
          right={
            <StatementDetailHeaderActions.FirstRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
        <PageHeader.Row
          left={
            <StatementDetailHeaderUserInformations
              statement={statement}
              user={statement.user}
            />
          }
          right={
            <StatementDetailHeaderActions.SecondRow
              companyFeatureFlags={company.featureFlags}
              handleForecastedSwitch={handleForecastedSwitch}
              setSelectAdjustment={setSelectAdjustment}
              statementCommentThread={statementCommentThread}
            />
          }
        />
      </PageHeader>

      <div css={styles.summaryContainer}>
        <div css={styles.alertsContainer}>
          {!isTracingActive && lastCalculation?.status === CalculationStatus.ERROR && (
            <CalculationError calculation={lastCalculation} />
          )}

          {!!statement.error && <StatementDetailHeaderPayoutQuotaError />}

          {!!statement.isForecastedView && !statement.forecastId && (
            <AlertBanner
              inline
              withBorder
            >
              <FormattedMessage defaultMessage="There is no forecasted statement calculated for this period." />
            </AlertBanner>
          )}
        </div>

        <StatementSummary onClickGoToStatement={onClickGoToStatement} />

        {!!isTracingActive && (
          <AlertBanner withBorder>
            <FormattedMessage
              defaultMessage="<span>Tracing</span> is in beta version"
              values={{
                span: (chunks) => <span css={styles.tracingText}>{chunks}</span>,
              }}
            />
          </AlertBanner>
        )}
      </div>
    </Fragment>
  );
});

export default StatementDetailHeader;
