import { Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { lowerCase } from 'lodash';
import { type FC, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { tokenTypeLabels } from '@amal-ia/amalia-lang/tokens/components';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type TokenType } from '@amal-ia/lib-types';
import { useCustomIconStyles, Badge } from '@amal-ia/lib-ui';

import { designerObjectsDetails } from '../../../../utils/designer';

interface DesignerObjectTabItemProps {
  readonly designerObject: TokenType;
  readonly goToObject: (type: TokenType) => any;
  readonly isCurrent: boolean;
  readonly count: number;
}

const useStylesTabItem = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    flex: 1,
    cursor: 'pointer',
    '&:hover $iconParent, &:hover $iconParent': {
      opacity: 1,
    },
  },
  iconParent: {
    opacity: 0.8,
    transition: 'opacity .1s',
    color: (props: any) => (props.isCurrent ? theme.palette.primary.main : theme.palette.grey[600]),
    paddingBottom: theme.spacing(1),
  },
  name: {
    backgroundColor: (props: any) => props.color,
    color: (props: any) => props.textColor,
    opacity: 0.75,
    padding: '1px 4px',
    lineHeight: 1,
  },
}));

export const DesignerObjectTabItem = memo(function DesignerObjectTabItem({
  designerObject,
  count,
  isCurrent,
  goToObject,
}: DesignerObjectTabItemProps) {
  const iconClasses = useCustomIconStyles();
  const { color, textColor, icon } = designerObjectsDetails[designerObject];
  const classes = useStylesTabItem({ color, textColor, isCurrent });

  const handleClick = useCallback(() => {
    goToObject(designerObject);
  }, [goToObject, designerObject]);

  const IconTag = icon as FC<any>;

  return (
    <Grid
      container
      alignItems="center"
      className={classes.root}
      direction="column"
      id={`navitem-${lowerCase(designerObject)}`}
      justifyContent="center"
      onClick={handleClick}
    >
      <Grid className={classes.iconParent}>
        <Badge
          badgeColor={amaliaTheme.palette.tertiary.main}
          badgeContent={count}
        >
          <IconTag className={iconClasses.customIcon} />
        </Badge>
      </Grid>
      <Grid className={classes.name}>
        <FormattedMessage
          {...tokenTypeLabels[designerObject]}
          values={{ count: undefined }}
        />
      </Grid>
    </Grid>
  );
});
