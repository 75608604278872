import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  progressBar: {
    borderRadius: 5,
    width: '100%',
    backgroundColor: theme.palette.grey['200'],
  },
}));

export interface ProgressProps {
  readonly total: number;
  readonly current: number;
  readonly color?: 'primary' | 'secondary';
}

export const Progress = memo(function Progress({ total, current, color }: ProgressProps) {
  const classes = useStyles();

  return (
    <LinearProgress
      className={classes.progressBar}
      color={color}
      value={(current / total) * 100}
      variant="determinate"
    />
  );
});
