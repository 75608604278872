import { isNil } from 'lodash';
import { type ReactNode, memo } from 'react';

import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../general/typography/Typography';
import { TableDataCellContent } from '../../layout/table-data-cell-content/TableDataCellContent';

import * as styles from './CellWithActions.styles';

export type CellWithActionsProps = {
  /** Cell actions. */
  readonly actions: ReactNode;
  /** Cell content. */
  readonly children?: ReactNode;
  /** Hide actions when cell is not hovered. */
  readonly hideActions?: boolean;
};

export const CellWithActions = memo(function CellWithActions({
  actions,
  children,
  hideActions = true,
}: CellWithActionsProps) {
  return (
    <TableDataCellContent css={styles.cellWithActions}>
      {!isNil(children) && (
        <Typography
          as={TextOverflow}
          variant={Typography.Variant.BODY_BASE_REGULAR}
        >
          {children}
        </Typography>
      )}

      {!!actions && (
        <div css={[styles.actionsContainer, hideActions && styles.actionsContainerHideHover]}>{actions}</div>
      )}
    </TableDataCellContent>
  );
});
