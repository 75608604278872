import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type CreateCompanyRequest } from '@amal-ia/lib-types';

import { saCreateCalculation, saSetCalculationStatus } from './service/calculations.superAdmin.repository';
import { addCompany, saModifyCompanyProperty } from './service/companies.superAdmin.repository';
import { saSetImpersonateUser, saSetMasterSettings } from './service/masterSettings.superAdmin.repository';
import { saSetRefreshmentStatus } from './service/refreshments.superAdmin.repository';
import { SUPER_ADMIN_QUERY_KEYS, getSaCompanyKey } from './superAdmin.queries.keys';

export const useSetMasterSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetMasterSettings>) => saSetMasterSettings(...params),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_MASTER_SETTINGS],
      }),
  });
};

export const useAddCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (companyToAdd: CreateCompanyRequest) => addCompany(companyToAdd),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES],
      }),
  });
};

export const useUpdateCompanyPropertyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saModifyCompanyProperty>) => saModifyCompanyProperty(...params),
    onSuccess: (_, [companyId]) => {
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES],
      });
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_COMPANY, getSaCompanyKey(companyId)],
      });
    },
  });
};

export const useImpersonateUserMutation = () =>
  useMutation({
    mutationFn: (email: string) => saSetImpersonateUser(email),
    onSuccess: () => window.location.reload(),
  });

export const useSetCalculationStatusMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetCalculationStatus>) => saSetCalculationStatus(...params),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_CALCULATIONS],
      }),
  });
};

export const useLaunchCalculationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saCreateCalculation>) => saCreateCalculation(...params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_CALCULATIONS],
      });
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_CALCULATIONS],
      });
    },
  });
};

export const useStopRefreshmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: Parameters<typeof saSetRefreshmentStatus>) => saSetRefreshmentStatus(...params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_REFRESHMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_REFRESHMENTS],
      });
    },
  });
};
