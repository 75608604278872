import { css } from '@emotion/react';
import { IconArrowLeft, IconHelpCircle } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useGetFormulaBuilderAttributeLabel,
  type FormulaConditionTagProps,
  useFormulaBuilderContext,
} from '@amal-ia/amalia-lang/formula/components';
import {
  type FormulaBuilderFunctionBlockUserForm,
  type FormulaBuilderFunctionBlockUserOneArgForm,
} from '@amal-ia/amalia-lang/formula/form';
import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
  ValueOrAttributeType,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { ComponentSwitch } from '@amal-ia/ext/react/components';
import { isEnum } from '@amal-ia/ext/typescript';
import { Dropdown, IconButton, Tooltip, Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import {
  fieldOperatorMessages,
  functionUserPopoverMessages,
  helpMessages,
  operatorNoArgsMessages,
  operatorOneArgMessages,
} from './FunctionUserPopover.messages';
import * as styles from './FunctionUserPopover.styles';
import { FunctionUserOneArgPopover } from './one-arg/FunctionUserOneArgPopover';
import { OPERATOR_ICON_MAPPING } from './operator.mappers';

enum FunctionUserPopoverStep {
  OPERATOR = 'OPERATOR',
  OTHER = 'OTHER',
}

export type FunctionUserPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockUserForm;
  readonly onChange: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserForm>['onChange'];
  readonly onClose?: () => void;
};

export const FunctionUserPopover = memo(function FunctionUserPopover({
  condition,
  onChange = noop,
  onClose = noop,
}: FunctionUserPopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const [step, setStep] = useState<FunctionUserPopoverStep>(
    condition.isDraft || !condition.operator || isEnum(condition.operator, FormulaBuilderUserOperatorNoArgs)
      ? FunctionUserPopoverStep.OPERATOR
      : FunctionUserPopoverStep.OTHER,
  );

  const handleGoToOperatorStep = useCallback(() => setStep(FunctionUserPopoverStep.OPERATOR), []);

  const handleChangeOperator = useCallback(
    (operator: FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg) => {
      const args = isEnum(operator, FormulaBuilderUserOperatorOneArg)
        ? [condition.args[0], { type: ValueOrAttributeType.ATTRIBUTE }]
        : [condition.args[0]];

      onChange({
        ...condition,
        operator,
        args,
        isDraft: !isEnum(operator, FormulaBuilderUserOperatorNoArgs),
      } as FormulaBuilderFunctionBlockUserForm);

      if (isEnum(operator, FormulaBuilderUserOperatorOneArg)) {
        setStep(FunctionUserPopoverStep.OTHER);
      } else {
        onClose();
      }
    },
    [condition, onChange, onClose],
  );
  return (
    <div>
      <ComponentSwitch value={step}>
        <ComponentSwitch.Item value={FunctionUserPopoverStep.OPERATOR}>
          <Dropdown.Title>{propertyName}</Dropdown.Title>

          {Object.values(FormulaBuilderUserOperatorNoArgs).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // TODO remove gap once #6097 is done
                    gap: 8px;
                  `}
                >
                  <FormattedMessage {...operatorNoArgsMessages[operator]} />
                  {operator === FormulaBuilderUserOperatorNoArgs.IS_SELECTED && (
                    <Tooltip
                      content={
                        <FormattedMessage
                          {...helpMessages.SELECTED_HELP}
                          values={{
                            datasource: customObjectDefinition?.name || '',
                          }}
                        />
                      }
                    >
                      <IconHelpCircle
                        color="currentColor"
                        size={16}
                      />
                    </Tooltip>
                  )}
                </div>
              }
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
          {Object.values(FormulaBuilderUserOperatorOneArg).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...operatorOneArgMessages[operator]} />}
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
        </ComponentSwitch.Item>

        <ComponentSwitch.Item value={FunctionUserPopoverStep.OTHER}>
          <div css={styles.otherStepPopover}>
            <div css={styles.headerContainer}>
              <IconButton
                icon={<IconArrowLeft />}
                label={<FormattedMessage {...functionUserPopoverMessages.BACK_TO_OPERATOR} />}
                onClick={handleGoToOperatorStep}
              />

              {!!condition.operator && (
                <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>
                  <FormattedMessage
                    {...fieldOperatorMessages[condition.operator]}
                    values={{ propertyName }}
                  />
                </Typography>
              )}
            </div>
            <FunctionUserOneArgPopover
              condition={condition as FormulaBuilderFunctionBlockUserOneArgForm}
              onChange={onChange}
            />
          </div>
        </ComponentSwitch.Item>
      </ComponentSwitch>
    </div>
  );
});
