import { type BaseCustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type ComputedStatement } from '@amal-ia/lib-types';

export const getCustomObjectDefinitionFromDefinitionIdInStatemnet = (
  computedStatement: ComputedStatement,
  definitionId: string,
): BaseCustomObjectDefinition | undefined =>
  Object.values(computedStatement.definitions.customObjects).find((co) => co.id === definitionId);

export const getDatasetNameFromStatementDefinition = (
  computedStatement: ComputedStatement,
  filterMachineName: string,
): string | undefined => computedStatement.definitions.filters[filterMachineName]?.name;
