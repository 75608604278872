import { type BulkSyncQuotasRequest, type QuotaType, type SyncQuotasRequest } from '@amal-ia/amalia-lang/tokens/types';
import { http } from '@amal-ia/frontend/kernel/http';
import { type QuotaAssignment } from '@amal-ia/management/assignments/quotas/types';

import { QuotaEntityAttributes } from './variableValues.service';

export async function bulkSyncQuotas(quotas: SyncQuotasRequest[]) {
  return http.post('/variable_values/bulk', { quotas } as BulkSyncQuotasRequest);
}

export async function createVariableValue(variableValue: QuotaAssignment): Promise<void> {
  await http.post('/variable_values', variableValue);
}

export async function updateVariableValue(variableValue: QuotaAssignment): Promise<void> {
  await http.patch('/variable_values', variableValue);
}

export async function deleteVariableValue(variableValue: QuotaAssignment): Promise<void> {
  await http.delete('/variable_values', {
    data: variableValue,
  });
}

export async function clearValuesFor(type: QuotaType, entityId: string, variableId: string) {
  await http.delete('/variable_values', {
    data: {
      variableId,
      [QuotaEntityAttributes[type].uniqueIdAttribute]: entityId,
    },
  });
}

export async function getVariableValues(variableId?: string, userId?: string): Promise<QuotaAssignment[]> {
  const { data } = await http.get<QuotaAssignment[]>('/variable_values', {
    params: {
      variableId,
      userId,
    },
  });
  return data;
}
