import moment from 'moment/moment';

import { type DateInput } from '../types/dates';

const getDateInput = (dateInput: DateInput) => {
  if (!dateInput.momentInput && !dateInput.timestamp) {
    throw new Error('Missing parameter');
  }

  return dateInput.momentInput || dateInput.timestamp;
};

export const formatUnixTimestamp = (unixTimeStamp: number) => moment.unix(unixTimeStamp).format('YYYY-MM-DD');

export const checkUnixTimestampBeforeFormat = (unixTimestamp: number | null) =>
  unixTimestamp ? formatUnixTimestamp(unixTimestamp) : null;

export const endOfMonthTimestamp = (dateInput: DateInput) => {
  const input = getDateInput(dateInput);

  return +moment.utc(input).endOf('month').format('X');
};

export const startOfMonthTimestamp = (dateInput: DateInput) => {
  const input = getDateInput(dateInput);

  return +moment.utc(input).startOf('month').format('X');
};

export const endOfDayTimestamp = (date: Date) => +moment.utc(date).endOf('day').format('X');

export const dateIsInAssignmentRange = (
  timestamp: number,
  assignment: { effectiveAsOf?: number | null; effectiveUntil?: number | null },
) =>
  (assignment.effectiveAsOf ? assignment.effectiveAsOf <= timestamp : true) &&
  (assignment.effectiveUntil ? timestamp < assignment.effectiveUntil : true);

export const fromNow = (date: Date | string) => moment(date).fromNow();

export type DateFormat =
  | '[Q]Q YY'
  | '[Q]Q YYYY'
  | '[Q]Q'
  | 'dddd'
  | 'L'
  | 'll'
  | 'LLL'
  | 'lll'
  | 'MM/YYYY'
  | 'MMM YY'
  | 'MMM'
  | 'MMMM YYYY'
  | 'YYYY-MM-DD HH:mm:ss'
  | 'YYYY-MM-DD'
  | 'YYYY-MM-DDThh:mm:ss'
  | 'YYYY';
export const formatDate = (date: NonNullable<moment.MomentInput>, format: DateFormat) =>
  moment.utc(date).format(format);

export const isValidDate = (dateString: string, format: DateFormat) => moment(dateString, format, true).isValid();

export const reformatDateString = (dateString: string, oldFormat: DateFormat, newFormat: DateFormat) =>
  moment(dateString, oldFormat).format(newFormat);

export const convertTimestampToDate = (timestamp: number) => moment.utc(timestamp, 'X').toDate();

export const convertUtcTimestampToDate = (timestamp?: number): string | undefined =>
  timestamp ? moment.utc(timestamp, 'X').format('YYYY-MM-DD') : undefined;

export const convertDateToUtcTimestamp = (date: Date | null, boundary: 'end' | 'start'): number | null => {
  switch (true) {
    case !date:
      return null;
    case boundary === 'start':
      return +moment.utc(date, 'YYYY-MM-DD').startOf('day').format('X');
    case boundary === 'end':
      return +moment.utc(date, 'YYYY-MM-DD').endOf('day').format('X');
    default:
      throw new Error('Invalid date');
  }
};

export const convertTimestampToUtcDate = (timestamp: number | null): Date | null => {
  if (!timestamp) {
    return null;
  }
  // https://stackoverflow.com/questions/34050389/remove-timezone-from-a-moment-js-object
  return moment
    .utc(timestamp, 'X')
    .add(-1 * moment().utcOffset(), 'm')
    .toDate();
};

export const convertYYYYMMDDToUtcTimestamp = (date: string | null): number | null => {
  if (!date) {
    return null;
  }

  const momentDate = moment.utc(date, 'YYYY-MM-DD', true);

  if (!momentDate.isValid()) {
    throw new Error('Date is not in YYYY-MM-DD format');
  }

  return +momentDate.format('X');
};

export type ShiftDateUnits = 'days' | 'months' | 'weeks';

export const shiftDate = (datesString: string, offset: number, unit: ShiftDateUnits, format: string = 'YYYY-MM-DD') =>
  moment(datesString, format).add(offset, unit).format(format);

/**
 * Get startDate en endDate of a year in timestamp format
 * @param year
 * @returns
 */
export const getTimestampRangeOfYear = (year: number) => {
  const startDate = +moment.utc({ year }).startOf('year').format('X');
  const endDate = +moment.utc({ year }).endOf('year').format('X');

  return {
    startDate,
    endDate,
  };
};

/**
 * Get startDate en endDate of a month in timestamp format
 * @param year
 * @param month
 * @returns
 */
export const getTimestampRangeOfMonth = (year: number, month: number) => {
  const startDate = +moment
    ?.utc({ year, month: month - 1 })
    .startOf('month')
    .format('X');
  const endDate = +moment
    ?.utc({ year, month: month - 1 })
    .endOf('month')
    .format('X');

  return {
    startDate,
    endDate,
  };
};

/**
 * Get startDate en endDate of a quarter in timestamp format
 * @param year
 * @param quarter
 * @returns
 */
export const getTimestampRangeOfQuarter = (year: number, quarter: number) => {
  const startDate = +moment?.utc({ year }).quarter(quarter).startOf('quarter').format('X');
  const endDate = +moment?.utc({ year }).quarter(quarter).endOf('quarter').format('X');

  return {
    startDate,
    endDate,
  };
};

export const isDateRangeValid = (startDate: Date | number, endDate: Date | number): boolean => {
  if (!startDate || !endDate) {
    return true;
  }

  return moment(startDate).isSameOrBefore(endDate);
};

/**
 * Verify the timestamp is in UTC and match the start/end of a day.
 *
 * Based on the assumption that we don't have anything that has a granularity smaller than a day.
 *
 * @param timestamp
 * @param boundary
 */
export const isValidDateBoundary = (timestamp: number, boundary: 'END' | 'START'): boolean => {
  const momentObject = moment.utc(timestamp, 'X');
  switch (boundary) {
    case 'START':
      return momentObject.hour() === 0 && momentObject.minutes() === 0 && momentObject.seconds() === 0;
    case 'END':
      return momentObject.hour() === 23 && momentObject.minutes() === 59 && momentObject.seconds() === 59;
    default:
      throw new Error('Operation not supported');
  }
};

export const isDateValid = (date: Date) => date instanceof Date && !!date.getTime();

/**
 * Compare two dates, check if they are equal (in the tolerance).
 *
 * @param date1
 * @param date2
 * @param tolerance
 */
export const datesAreTheSame = (date1: Date | null, date2: Date | null, tolerance: number = 1000) => {
  if (!date1 && !date2) {
    return true;
  }

  if (!date1 || !date2) {
    return false;
  }

  return Math.abs(date1.getTime() - date2.getTime()) < tolerance;
};

/**
 * The calculation engine returns dates in two different formats, either a unix
 * timestamp (in case it's a result of a calculation), or date/date-time (fields from
 * the object definition. This function parse any input and returns a JS Date object.
 *
 * @param date
 */
export const dateFromCalculationEngineToJsDate = (date: number | string | null): Date => {
  const momentObject = !Number.isNaN(+date)
    ? // Case where the calculation engine returned a unix timestamp.
      moment.utc(date, 'X')
    : // For the rest it should be a properly formatted date or date-time string.
      moment.utc(date);

  if (!momentObject.isValid()) {
    throw new Error(`Cannot parse date ${date}`);
  }
  return momentObject.toDate();
};
