import { createContext, useContext } from 'react';

import { type ObjectsToDisplay } from '@amal-ia/compensation-definition/plans/types';
import { assert } from '@amal-ia/ext/typescript';
import { type Statement, type StatementError, type WorkflowStatementState } from '@amal-ia/lib-types';
import { type StatementThread, type StatementThreadScope } from '@amal-ia/payout-collaboration/comments/shared/types';

export interface StatementDetailContextInterface extends Statement {
  launchCalculation: () => Promise<void>;
  stopCalculation: () => Promise<void>;
  calculationProgress: string;
  isCalculationRunning: boolean;
  isForecastedView: boolean;
  objectsToDisplay?: Record<string, ObjectsToDisplay>;
  statementId: string;
  statementError?: StatementError;
  workflowComplete: boolean;
  workflowSteps?: WorkflowStatementState[];
  lastCalculationDate: Date;
  // When we are in forecast we need to have the orginal statement to show the diff
  originalStatement?: Statement;
  userId: string;
  openStatementThreadPanel: (statementThread?: StatementThread, scope?: StatementThreadScope) => void;
  statementThreads: Record<string, StatementThread>;
}

const StatementContext = createContext<StatementDetailContextInterface | null>(null);

export const StatementDetailContextProvider = StatementContext.Provider;

export const useStatementDetailContext = () => {
  const context = useContext(StatementContext);
  assert(context, 'useStatementDetailContext must be used within a StatementDetailContext');
  return context;
};
