import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const TeamDetailContainer = lazy(() => import('./detail/TeamDetailContainer'));
const TeamListContainer = lazy(() => import('./list/TeamListContainer'));

export const TeamRoutes = memo(function TeamRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="teams">
      <RootRoutes>
        <Route
          path={`${routes.TEAM_ITEM}/*`}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Team)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Team details' })} />
              <TeamDetailContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.TEAM_LIST}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Team)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Teams' })} />
              <TeamListContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
