import { css, type Theme } from '@emotion/react';

import { DatasetButtonVariant } from './DatasetButton.types';

export const datasetInnerButton = () => css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const datasetButton = (theme: Theme) => css`
  /* No transition on outline because it does not work well. */
  transition: ${theme.ds.transitions.default('color', 'background-color', 'border-color', 'box-shadow', 'padding')};

  height: 32px;
  border-radius: ${theme.ds.borderRadiuses.squared};
  border: 1px solid ${theme.ds.colors.gray[100]};
  padding: 0 8px;
  box-shadow: ${theme.ds.shadows.soft};
  box-sizing: border-box;

  &.${DatasetButtonVariant.DESIGNER} {
    padding: 0 8px 0 8px;
  }

  &.active {
    /** Only show border if there are multiple datasets. */
    &.hasMultipleDatasets {
      border-color: ${theme.ds.colors.secondary[200]};
    }

    &.forecasted {
      background: ${theme.ds.hues.blue[50]};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${theme.ds.colors.gray[300]};
    background-color: ${theme.ds.colors.gray[0]};
  }

  &:not(:disabled) {
    &:focus-visible {
      background: ${theme.ds.colors.gray[50]};
    }

    &:hover {
      background-color: ${theme.ds.colors.gray[50]};
    }

    &:focus-visible {
      background: ${theme.ds.colors.gray[0]};
      outline-color: ${theme.ds.colors.secondary[200]};
    }

    &.forecasted {
      background: ${theme.ds.hues.blue[50]};

      &:hover {
        background-color: ${theme.ds.colors.gray[50]};
      }
    }
  }
`;
