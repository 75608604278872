export enum HidableElementVisibility {
  ON_DISPLAY = 'ON_DISPLAY',
  AVAILABLE = 'AVAILABLE',
}

export interface PlanRuleFilterToDisplay {
  id: string;
  displayStatus: HidableElementVisibility;
  fieldsToDisplay: PlanRuleFieldToDisplay[];
}

export interface PlanRuleFieldToDisplay {
  name: string;
  displayStatus: HidableElementVisibility;
}

export type PlanRuleKpiSection = {
  id: string;
  name?: string;
  kpis: PlanRuleKpiToDisplay[];
};

export interface PlanRuleKpiToDisplay {
  id: string;
  displayStatus: HidableElementVisibility;
}

export interface ComputedPlanRuleFieldsToDisplay extends PlanRuleFieldToDisplay {
  label: string;
}
