import { memo } from 'react';

import { assert } from '@amal-ia/ext/typescript';
import { Badge, type BadgeProps } from '@amal-ia/frontend/design-system/components';
import { UserRole } from '@amal-ia/tenants/users/shared/types';

import { UserRoleLabel } from './UserRoleLabel';

export type UserRoleBadgeProps = Omit<BadgeProps, 'children' | 'variant'> & {
  readonly role: UserRole;
};

type BadgeHue = (typeof Badge.Hue)[keyof typeof Badge.Hue];

export const UserRoleBadgeVariantRecord: Record<UserRole, BadgeHue> = {
  [UserRole.ADMIN]: Badge.Hue.ORANGE,
  [UserRole.READ_ONLY_ADMIN]: Badge.Hue.ORANGE,
  [UserRole.FINANCE]: Badge.Hue.CYAN,
  [UserRole.MANAGER]: Badge.Hue.MAGENTA,
  [UserRole.READ_ONLY_MANAGER]: Badge.Hue.MAGENTA,
  [UserRole.EMPLOYEE]: Badge.Hue.BLUE,
  [UserRole.READ_ONLY_EMPLOYEE]: Badge.Hue.BLUE,
} as const;

const UserRoleBadgeBase = memo(function UserRoleBadge({
  role,
  size = Badge.Size.MEDIUM,
  ...props
}: UserRoleBadgeProps) {
  assert(role in UserRoleBadgeVariantRecord, `Invalid role: ${role}`);

  const badgeVariant = UserRoleBadgeVariantRecord[role];

  return (
    <Badge
      {...props}
      size={size}
      variant={badgeVariant}
    >
      <UserRoleLabel role={role} />
    </Badge>
  );
});

export const UserRoleBadge = Object.assign(UserRoleBadgeBase, {
  Size: Badge.Size,
});
