import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, Paper } from '@amal-ia/frontend/design-system/components';

import { DrawingSetupConnectors, DrawingSetupReports } from '../../assets';

import { Text, TextType } from './typography';

export const IMAGES_LIBRARY = {
  SETUP_CONNECTORS: DrawingSetupConnectors,
  SETUP_REPORTS: DrawingSetupReports,
};

interface PlaceholderProps {
  readonly title: ReactNode;
  readonly children: ReactNode;
  readonly image: keyof typeof IMAGES_LIBRARY;
  readonly callToActionText?: ReactNode;
  readonly onClickCallToAction: () => void;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
  },
  description: {
    margin: `${theme.spacing(8)} 0`,
  },
  imageContainer: {
    textAlign: 'center',
    '& > svg': {
      maxWidth: 280,
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
}));

export const Placeholder = memo(function Placeholder({
  title,
  children: description,
  callToActionText,
  image,
  onClickCallToAction,
}: PlaceholderProps) {
  const classes = useStyles();
  const Image = IMAGES_LIBRARY[image];
  return (
    <Container maxWidth="sm">
      <Paper className={classes.container}>
        <Text type={TextType.PAGE_TITLE}>{title}</Text>
        <div className={classes.description}>{description}</div>
        <div className={classes.imageContainer}>
          <Image />
        </div>
        <div className={classes.buttonContainer}>
          {callToActionText ? (
            <Button
              variant={Button.Variant.SECONDARY}
              onClick={onClickCallToAction}
            >
              {callToActionText}
            </Button>
          ) : null}
        </div>
      </Paper>
    </Container>
  );
});
