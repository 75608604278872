import { defineMessages } from 'react-intl';

export const addRecordsModalMessages = defineMessages({
  NO_RECORD_FOUND: { defaultMessage: 'No records found.' },
  SEARCH_RECORDS: {
    defaultMessage:
      'Search a record <strong>by name</strong> or <strong>by ID</strong>{br}' +
      'then <strong>select</strong> it to add it to your statement.',
  },
  SELECTED_RECORDS: { defaultMessage: 'Selected records' },
});
