import { ClassNames } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo, cloneElement } from 'react';

import { toLinkElement, type ToOrLinkElement } from '@amal-ia/ext/react-router-dom';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { Button, type ButtonProps } from '../../general/button/Button';

export type ButtonLinkProps = MergeAll<
  [
    ButtonProps,
    {
      to?: ToOrLinkElement;
    },
  ]
>;

const ButtonLinkForwardRef = forwardRef(function ButtonLink(
  { to, children, ...props }: ButtonLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const linkElement = toLinkElement(to);

  return (
    <ClassNames>
      {({ css }) =>
        cloneElement(
          linkElement,
          {
            ref,
            disabled: props.disabled ?? linkElement.props.disabled,
            className: css`
              text-decoration: none;
              display: inline-flex;
            `,
          },
          <Button {...props}>{children}</Button>,
        )
      }
    </ClassNames>
  );
});

export const ButtonLink = Object.assign(memo(ButtonLinkForwardRef), {
  IconPosition: Button.IconPosition,
  Size: Button.Size,
  Variant: Button.Variant,
});
