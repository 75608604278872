import { css } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { Tooltip } from '@amal-ia/frontend/design-system/components';
import { StatementAvatar, type StatementAvatarProps } from '@amal-ia/lib-ui-business';

import { StatementKpisList } from './statement-kpis-list/StatementKpisList';
import { StatementKpisTooltip } from './statement-kpis-tooltip/StatementKpisTooltip';

export type StatementKpisProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    Pick<StatementAvatarProps, 'error' | 'hideAvatar' | 'highlightedKpis' | 'isForecast' | 'user'>,
    {
      isTooltipDisabled?: boolean;
    },
  ]
>;

export const StatementKpis = memo(function StatementKpis({
  user = undefined,
  error = undefined,
  highlightedKpis = undefined,
  isForecast = false,
  hideAvatar = false,
  isTooltipDisabled = false,
  ...props
}: StatementKpisProps) {
  return (
    <Tooltip
      disabled={isTooltipDisabled}
      placement="right"
      content={
        <StatementKpisTooltip
          isForecast={isForecast}
          kpis={highlightedKpis}
        />
      }
    >
      <div
        {...props}
        css={css`
          display: flex;
          align-items: center;
          gap: 24px;
        `}
      >
        <StatementAvatar
          error={error}
          hideAvatar={hideAvatar}
          highlightedKpis={highlightedKpis}
          isForecast={isForecast}
          user={user}
        />

        <StatementKpisList
          isForecast={isForecast}
          kpis={highlightedKpis}
        />
      </div>
    </Tooltip>
  );
});
