import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type AttributeValueForm } from '@amal-ia/amalia-lang/formula/form';
import { type AttributeValue } from '@amal-ia/amalia-lang/formula/shared/types';
import { CountBadge, Select, type SelectProps } from '@amal-ia/frontend/design-system/components';
import { TokenType } from '@amal-ia/lib-types';

import {
  type AttributesOptions,
  type AttributeSelectOption,
} from '../../hooks/use-attributes-options/useAttributesOptions';
import { useMapAttributeValueToSelectOption } from '../../hooks/use-map-attribute-value-to-select-option/useMapAttributeValueToSelectOption';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';

import { attributeSelectorMessages } from './AttributeSelector.messages';

export type AttributeSelectorProps = {
  readonly selectedAttribute: AttributeValueForm;
  readonly options: AttributesOptions;
  readonly onChange: (attribute: AttributeValue) => void;
};

export const AttributeSelector = memo(function AttributeSelector({
  selectedAttribute,
  options,
  onChange,
}: AttributeSelectorProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const intl = useIntl();
  const mapAttributeValueToSelectOption = useMapAttributeValueToSelectOption();

  const handleChangeAttributeValue = useCallback(
    (option: AttributeSelectOption<AttributeValue>) => onChange(option.attribute),
    [onChange],
  );

  const value = useMemo(
    () => mapAttributeValueToSelectOption(selectedAttribute),
    [mapAttributeValueToSelectOption, selectedAttribute],
  );

  const selectOptions: SelectProps<AttributeSelectOption<AttributeValue>, false, true, false>['options'] = useMemo(
    () => [
      {
        label: intl.formatMessage(attributeSelectorMessages.VARIABLES),
        countBadge: <CountBadge variant={CountBadge.Variant.CYAN}>{options[TokenType.VARIABLE].length}</CountBadge>,
        options: options[TokenType.VARIABLE],
        initialIsOpen: false,
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.PROPERTIES, {
          customObjectName: customObjectDefinition?.name ?? '',
        }),
        countBadge: <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.PROPERTY].length}</CountBadge>,
        initialIsOpen: false,
        options: options[TokenType.PROPERTY],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.VIRTUALS),
        countBadge: (
          <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.VIRTUAL_PROPERTY].length}</CountBadge>
        ),
        initialIsOpen: false,
        options: options[TokenType.VIRTUAL_PROPERTY],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.FIELDS),
        countBadge: <CountBadge variant={CountBadge.Variant.ORANGE}>{options[TokenType.FIELD].length}</CountBadge>,
        initialIsOpen: false,
        options: options[TokenType.FIELD],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.QUOTAS),
        initialIsOpen: false,
        countBadge: <CountBadge variant={CountBadge.Variant.PURPLE}>{options[TokenType.QUOTA].length}</CountBadge>,
        options: options[TokenType.QUOTA],
      },
      ...options[TokenType.LINK].map(({ relationship, ...relAttributes }) => ({
        label: intl.formatMessage(attributeSelectorMessages.LINKS, {
          relationshipName: relationship.label,
        }),
        countBadge: (
          <CountBadge variant={CountBadge.Variant.RED}>
            {relAttributes[TokenType.PROPERTY].length + relAttributes[TokenType.VIRTUAL_PROPERTY].length}
          </CountBadge>
        ),
        initialIsOpen: false,
        options: [...relAttributes[TokenType.PROPERTY], ...relAttributes[TokenType.VIRTUAL_PROPERTY]],
      })),
      {
        label: intl.formatMessage(attributeSelectorMessages.KEYWORDS),
        initialIsOpen: false,
        countBadge: <CountBadge variant={CountBadge.Variant.MAGENTA}>{options[TokenType.KEYWORD].length}</CountBadge>,
        options: options[TokenType.KEYWORD],
      },
    ],
    [intl, options, customObjectDefinition],
  );

  return (
    <Select<AttributeSelectOption<AttributeValue>, false, true, false>
      useOptionAsValue
      isClearable={false}
      options={selectOptions}
      placeholder={intl.formatMessage(attributeSelectorMessages.SELECT_FIELD)}
      size={Select.Size.SMALL}
      value={value}
      onChange={handleChangeAttributeValue}
    />
  );
});
