import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { type FC, useState, useMemo, memo } from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { type AxisDomain } from 'recharts/types/util/types';

import { amaliaTheme, colors } from '@amal-ia/ext/mui/theme';

import './styles.css';
import { useStateInLocalStorage } from '../../../../utils/hooks/useStateInLocalStorage';

export interface ChartProps {
  /**
   * data: list of elements of the form :/n
   *  {
   *    x:xValue,
   *    yi:YiValue,
   * }
   * where :
   * - x is the datakey accessor for XAxis (must be in XAxis prop )
   * - Yi represents subsequent fields in the object where each key Yi is
   * the datakey for a plot
   */
  data?: FormattedData[];
  /**
   * YAxis: list of elements of the type :
   *  {
   *     AxisId : string | number,
   *    orientation : right or left,
   *    stroke :  string,
   *    domain ; [number, number] | [string, string],
   *    datakey: string,
   *    tickFormatter: (value: any, index: number) => string,
   * }
   * where:
   * - AxisId is the axis reference that you intend to pass to chart in order to bind them
   * - orientation: is either left or right for y axis
   * - stroke: a string that represents a color
   * - domain: array of two elemnts (either strings or numbers) that define a range
   * - datakey: used for X axis to determine which data element key is associated with X axis
   * - tickFormatter: function that modifies the string
   */
  yAxis?: Axis[];
  /**
   * XAxis: element of the type :
   *  {
   *     AxisId : string | number,
   *    orientation : right or left,
   *    stroke :  string,
   *    domain ; [number, number] | [string, string],
   *    datakey: string,
   *    tickFormatter: (value: any, index: number) => string,
   * }
   * where:
   * - AxisId is the axis reference that you intend to pass to chart in order to bind them
   * - orientation: is either left or right for y axis
   * - stroke: a string that represents a color
   * - domain: array of two elemnts (either strings or numbers) that define a range
   * - datakey: used for X axis to determine which data element key is associated with X axis
   * - tickFormatter: function that modifies the string
   */
  xAxis?: Axis;
  chartMetas?: ChartMeta[];
  CustomTooltip?: FC;
}
export interface FormattedData {
  name: string;
  [x: number | string | symbol]: unknown;
}

export interface Axis {
  AxisId?: number | string;
  orientation?: 'left' | 'right';
  stroke?: string;
  domain?: AxisDomain;
  datakey?: string;
  tickFormatter?: (value: any, index: number) => string;
}
export interface ChartMeta {
  yAxisId?: number | string;
  orientation?: 'left' | 'right';
  stroke?: string;
  domain?: AxisDomain;
  dot?: any;
  strokeDasharray?: number;
  strokeWidth?: number;
  type?: 'bar' | 'line';
  dataKey: string;
  fill?: string;
  scale?: number;
  barSize?: number;
  radius?: number | [number, number, number, number];
  buttonColor?: string;
  buttonLabel?: string;
  hide?: boolean;
}

interface MixedchartProps {
  readonly data?: any;
  readonly xAxis?: any;
  readonly yAxis?: any;
  readonly chartMetas?: any;
  readonly CustomTooltip?: any;
}

export const Mixedchart = memo(function Mixedchart({
  data,
  xAxis,
  yAxis,
  chartMetas = [
    {
      yAxisId: 'left',
      scale: 1,
      barSize: 10,
      dataKey: 'pv',
      radius: [4, 4, 0, 0],
      fill: 'url(#colorUv)',
      type: 'bar',
    },
    {
      type: 'line',
      yAxisId: 'left',
      dot: false,
      strokeDasharray: 7,
      strokeWidth: 2,
      dataKey: 'amt',
      fill: 'hsl(256deg 100% 61%)',
      stroke: 'hsl(256deg 100% 61%)',
    },
    {
      type: 'line',
      yAxisId: 'right',
      dataKey: 'uv',
      stroke: colors['primary-500'],
    },
  ],
  CustomTooltip = () => null,
}: MixedchartProps) {
  const [hidden, setHidden] = useStateInLocalStorage(
    chartMetas.map((chart: any) => (chart?.hide ? chart.dataKey : null)),
    'hide_charts_items',
  );
  const [hoveredDataKey, setHoveredDataKey] = useState<string | null>(null);

  const TooltipWithContext = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components -- We can't pass props in another way
      function TooltipWithContextComponent({ ...props }: any) {
        return (
          <CustomTooltip
            dataKey={hoveredDataKey}
            {...props}
          />
        );
      },
    [CustomTooltip, hoveredDataKey],
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: 200,
      }}
    >
      <ResponsiveContainer
        height={250}
        minWidth={200}
        width="100%"
      >
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            bottom: 5,
            left: 60,
            right: 60,
          }}
        >
          <XAxis
            hide
            dataKey={xAxis?.datakey || undefined}
            domain={xAxis?.domain || undefined}
          />
          {yAxis?.map((axis: any, ind: any) => (
            <YAxis
              key={`key-chart-axis${ind + 1}`}
              hide
              domain={axis?.domain || undefined}
              orientation={axis?.orientation || undefined}
              stroke={axis?.stroke || undefined}
              tickFormatter={axis?.tickFormatter || undefined}
              width={80}
              yAxisId={axis?.AxisId || undefined}
            />
          ))}
          <Tooltip
            active
            content={TooltipWithContext}
            cursor={false}
          />
          <defs>
            <linearGradient
              gradientUnits="userSpaceOnUse"
              id="colorUv"
              x1="0"
              x2="0"
              y1="0"
              y2="100%"
            >
              <stop
                offset="0"
                stopColor={amaliaTheme.palette.primary.main}
              />
              <stop
                offset="1"
                stopColor={amaliaTheme.palette.primary.light}
              />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#f5f5f5" />
          {chartMetas.map((chart: ChartMeta, ind: number) => {
            if (chart.type === 'line') {
              return (
                <Line
                  key={`key-chart-axis${ind + 1}`}
                  cursor={5}
                  dataKey={chart.dataKey}
                  dot={chart.dot}
                  fill={chart.fill}
                  hide={hidden.includes(chart.dataKey)}
                  stroke={chart.stroke}
                  strokeDasharray={chart.strokeDasharray}
                  strokeWidth={chart.strokeWidth || 3}
                  type="monotone"
                  width={1}
                  yAxisId={chart.yAxisId}
                  activeDot={{
                    onMouseOver: () => setHoveredDataKey(chart.dataKey),
                    onMouseLeave: () => setHoveredDataKey(null),
                  }}
                />
              );
            }
            return (
              <Bar
                key={`key-chart-axis${ind + 1}`}
                barSize={chart.barSize}
                dataKey={chart.dataKey}
                fill={chart.fill}
                hide={hidden.includes(chart.dataKey)}
                radius={chart.radius}
                scale={chart.scale}
                yAxisId={chart.yAxisId}
                onMouseEnter={() => setHoveredDataKey(chart.dataKey)}
                onMouseLeave={() => setHoveredDataKey(null)}
              />
            );
          })}
        </ComposedChart>
      </ResponsiveContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '10px 70px',
        }}
      >
        {chartMetas.map((plot: any) => (
          <button
            key={`${plot.dataKey}-btn-chart-datakey`}
            className={`chart-mixed-btn ${hidden.includes(plot.dataKey) ? 'hidden-btn-chart' : ''}`}
            type="button"
            style={{
              width: 'auto',
              margin: '0px 20px',
              padding: '8px 12px',
              borderRadius: '8px',
              color:
                plot.type !== 'bar'
                  ? plot.buttonColor ||
                    ((plot.stroke || plot.fill) === 'url(#colorUv)'
                      ? amaliaTheme.palette.primary.light
                      : plot.stroke || plot.fill)
                  : undefined,
              backgroundColor:
                plot.type === 'bar'
                  ? plot.buttonColor ||
                    ((plot.stroke || plot.fill) === 'url(#colorUv)'
                      ? amaliaTheme.palette.primary.light
                      : plot.stroke || plot.fill)
                  : 'hsl(40deg 23% 97%)',
            }}
            onClick={() =>
              setHidden(
                hidden.includes(plot.dataKey)
                  ? hidden.filter((hide: any) => hide !== plot.dataKey)
                  : [...hidden, plot.dataKey],
              )
            }
          >
            <span style={{ marginRight: 15 }}>{plot.buttonLabel || plot.dataKey}</span>
            {hidden.includes(plot.dataKey) ? (
              <VisibilityOff
                fontSize="small"
                htmlColor="#666666"
              />
            ) : (
              <VisibilityIcon
                fontSize="small"
                htmlColor="rgba(0, 0, 0, 0.54)"
              />
            )}
          </button>
        ))}
      </div>
    </div>
  );
});
