import { IconArrowBarToDown } from '@tabler/icons-react';
import { memo, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { PageContainer } from '@amal-ia/frontend/connected-components/layout';
import {
  DataGrid,
  getPageCount,
  IconButton,
  PageHeader,
  Paper,
  Typography,
  useDataGridStateInLocalStorage,
} from '@amal-ia/frontend/design-system/components';
import {
  fetchOverwrites,
  selectOverwriteList,
  selectOverwritesIsLoading,
  useThunkDispatch,
  getOverwrites,
  downloadCsvFile,
} from '@amal-ia/frontend/web-data-layers';
import { type Option } from '@amal-ia/lib-types';
import { useStateInLocalStorage } from '@amal-ia/lib-ui';

import { OverwritesFilters } from './filters/OverwritesFilters';
import { mapOverwritesToCsv } from './overwrites.utils';
import { messages } from './OverwritesContainer.messages';
import * as styles from './OverwritesContainer.styles';
import { OverwritesTable } from './OverwritesTable/OverwritesTable';

export type FiltersMap = Record<
  string,
  {
    menuTitle: string;
    elements: Option[];
    checkboxFilter: Option[];
    onReset?: () => void;
    onChange: (elements: Option[]) => void;
    isActive: boolean;
  }
>;

export type FilterProps = {
  userIds: string[];
  planIds: string[];
  dataSourceIds: string[];
  overwrittenByIds: string[];
  periodIds: string[];
  typeValues: string[];
  statusValues: string[];
};

export const OverwritesContainer = memo(function OverwritesContainer() {
  const dispatch = useThunkDispatch();
  const { formatMessage } = useIntl();

  const [filter, setFilter] = useStateInLocalStorage<FilterProps | null>(null, 'overwrites_filtertab');

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    columnSorting,
    setColumnSorting,
    columnOrder,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
    searchText,
    setSearchText,
  } = useDataGridStateInLocalStorage({
    key: ['overwrites'],
    initialState: {
      columnVisibility: {
        rule_name: false,
        overwrite_type: false,
      },
    },
  });

  const overwritesListDetails = useSelector(selectOverwriteList);
  const isOverwritesLoading = useSelector(selectOverwritesIsLoading);

  // Data fetcher
  useAsyncEffect(async () => {
    await dispatch(
      fetchOverwrites(searchText, page + 1, pageSize, columnSorting[0]?.id, columnSorting[0]?.direction, {
        userIds: filter?.userIds,
        planIds: filter?.planIds,
        dataSourceIds: filter?.dataSourceIds,
        overwrittenByIds: filter?.overwrittenByIds,
        periodIds: filter?.periodIds,
        typeName: filter?.typeValues,
        status: filter?.statusValues,
      }),
    );
  }, [dispatch, columnSorting, searchText, page, pageSize, filter]);

  // Page overflow handler
  useEffect(() => {
    if (!isOverwritesLoading && page > overwritesListDetails.totalItems / pageSize) {
      setPage(0);
    }
  }, [overwritesListDetails, page, pageSize, isOverwritesLoading, setPage]);

  // Trigger CSV export
  const onExportOverwrites = useCallback(async () => {
    const overwrites = await getOverwrites(
      searchText,
      0,
      overwritesListDetails.totalItems,
      columnSorting[0]?.id,
      columnSorting[0]?.direction,
      {
        userIds: filter?.userIds,
        planIds: filter?.planIds,
        dataSourceIds: filter?.dataSourceIds,
        overwrittenByIds: filter?.overwrittenByIds,
        periodIds: filter?.periodIds,
        typeName: filter?.typeValues,
        status: filter?.statusValues,
      },
    );
    const csvData = await mapOverwritesToCsv(overwrites.items);
    downloadCsvFile(csvData, 'overwrites_export');
  }, [filter, overwritesListDetails.totalItems, columnSorting, searchText]);

  return (
    <PageContainer showLoadingBar={isOverwritesLoading}>
      <div css={styles.container}>
        <PageHeader>
          <PageHeader.Row
            left={
              <Typography variant={Typography.Variant.HEADING_1_BOLD}>
                <FormattedMessage {...messages.OVERWRITES} />
              </Typography>
            }
            right={
              <IconButton
                withBackground
                icon={<IconArrowBarToDown />}
                label={formatMessage(messages.EXPORT_OVERWRITES)}
                onClick={onExportOverwrites}
              />
            }
          />
        </PageHeader>
        <Paper css={styles.gridContainer}>
          <OverwritesTable
            data={overwritesListDetails.items}
            totalItems={overwritesListDetails.totalItems}
            columnOrder={
              <DataGrid.ColumnOrder
                columnOrder={columnOrder}
                onChangeColumnOrder={setColumnOrder}
              />
            }
            columnSorting={
              <DataGrid.ColumnSorting.Single
                columnSorting={columnSorting}
                onChangeColumnSorting={setColumnSorting}
              />
            }
            columnVisibility={
              <DataGrid.ColumnVisibility
                columnVisibility={columnVisibility}
                onChangeColumnVisibility={setColumnVisibility}
              />
            }
            leftHeaderSlot={
              <OverwritesFilters
                filter={filter}
                setFilter={setFilter}
              />
            }
            pageSize={
              <DataGrid.PageSize
                value={pageSize}
                onChange={setPageSize}
              />
            }
            pagination={
              <DataGrid.Pagination
                page={page}
                pageCount={getPageCount(overwritesListDetails.totalItems, pageSize)}
                onChangePage={setPage}
              />
            }
            search={
              <DataGrid.Search
                value={searchText}
                onChange={setSearchText}
              />
            }
          />
        </Paper>
      </div>
    </PageContainer>
  );
});
