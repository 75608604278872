import { css } from '@emotion/react';
import { IconInfoCircle } from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type VariableDefinition } from '@amal-ia/amalia-lang/tokens/types';
import { type BaseCustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { Tooltip } from '@amal-ia/frontend/design-system/components';
import { ColorCategory } from '@amal-ia/frontend/design-system/meta';
import { type DatasetRow } from '@amal-ia/lib-types';

const isConvertCurrencyOptionsEquals = (
  convertCurrenyOptions: VariableDefinition['formulaOptions']['convertCurrency'],
) => convertCurrenyOptions.from === convertCurrenyOptions.to;

const getConvertCurrencyOptionLabel = (
  defaultValue: string,
  customObjects: Record<string, BaseCustomObjectDefinition>,
  statementCurrency: string,
  row?: DatasetRow,
) => {
  if (defaultValue === 'user.currency') {
    return statementCurrency;
  }
  const [customObjectMachineName, property] = defaultValue.split('.');
  if (customObjectMachineName && property && customObjects?.[customObjectMachineName]?.properties[property]) {
    return (row?.content?.[property] as string) || customObjects[customObjectMachineName].properties[property].name;
  }
  return defaultValue.replaceAll('"', '');
};

type TracingFormulaOptionsTooltipProps = {
  readonly formulaOptions?: VariableDefinition['formulaOptions'] | null;
  readonly customObjects: Record<string, BaseCustomObjectDefinition>;
  readonly statementCurrency: CurrencySymbolsEnum;
  readonly row?: DatasetRow;
};

export const TracingFormulaOptionsTooltip = memo(function TracingFormulaOptionsTooltip({
  formulaOptions,
  customObjects,
  statementCurrency,
  row,
}: TracingFormulaOptionsTooltipProps) {
  if (!formulaOptions) {
    return null;
  }
  const { convertCurrency, round } = formulaOptions;
  if ((!convertCurrency || isConvertCurrencyOptionsEquals(convertCurrency)) && !round) {
    return null;
  }

  return (
    <Tooltip
      content={
        <Fragment>
          {!!convertCurrency && !isConvertCurrencyOptionsEquals(convertCurrency) && (
            <div>
              <FormattedMessage
                defaultMessage="Converted from {fromCurrency} to {toCurrency}"
                values={{
                  fromCurrency: getConvertCurrencyOptionLabel(
                    convertCurrency.from,
                    customObjects,
                    statementCurrency,
                    row,
                  ),
                  toCurrency: getConvertCurrencyOptionLabel(convertCurrency.to, customObjects, statementCurrency, row),
                }}
              />
            </div>
          )}
          {!!round && (
            <div>
              <FormattedMessage
                defaultMessage="Rounded at {precision, plural, one {# decimal place} other {# decimal places}}"
                values={{ precision: round.precision }}
              />
            </div>
          )}
        </Fragment>
      }
    >
      <IconInfoCircle
        data-testid="formula-options-tooltip"
        css={(theme) => css`
          color: ${theme.ds.colors[ColorCategory.GRAY][500]};
          width: 18px;
        `}
      />
    </Tooltip>
  );
});
