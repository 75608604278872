import { css } from '@emotion/react';
import { type ForwardedRef, forwardRef, memo } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { Button, type ButtonProps } from '../../../../general/button/Button';

export type CellActionProps = MergeAll<
  [
    Omit<ButtonProps, 'size'>,
    {
      /** If the <Table.Cell.WithActions /> uses hideActions={false}, set this to true to only this specific action on hover. */
      showOnHoverCell?: boolean;
    },
  ]
>;

const CellActionForwardRef = forwardRef(function CellAction(
  { showOnHoverCell, variant = Button.Variant.SECONDARY_LIGHT, ...props }: CellActionProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Button
      {...props}
      ref={ref}
      size={Button.Size.SMALL}
      variant={variant}
      css={
        !!showOnHoverCell &&
        css`
          td:not(:hover) & {
            display: none;
          }
        `
      }
    />
  );
});

export const CellAction = Object.assign(memo(CellActionForwardRef), {
  IconPosition: Button.IconPosition,
  Variant: Button.Variant,
});
