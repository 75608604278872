import { DEFAULT_REPORTS_PRECISION } from '@amal-ia/lib-types';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';
import { type CompanyCustomization } from '@amal-ia/tenants/companies/types';

/**
 * Better be realistic with the type here, if a configuration option disappears for instance.
 */
export const useCompanyCustomization = (): Partial<CompanyCustomization> | undefined => {
  const { data: company } = useCurrentCompany();

  return {
    holdAndReleasePaymentDateLabel: company?.customization?.holdAndReleasePaymentDateLabel || '',
    holdAndReleaseForecastPaymentDateLabel: company?.customization?.holdAndReleaseForecastPaymentDateLabel || '',
    totalCommissionLabel: company?.customization?.totalCommissionLabel || '',
    reportsPrecision: company?.customization?.reportsPrecision || DEFAULT_REPORTS_PRECISION,
    legacyKpiCardView: company?.customization?.legacyKpiCardView || false,
  };
};
