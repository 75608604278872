import { Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { SelectFieldBase } from '@amal-ia/lib-ui';

import { type FilterOptions, type Filters } from './tableFiltersSelect.types';
import { TableFiltersSelectFilter } from './TableFiltersSelectFilter';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  headerFilter: {
    paddingTop: theme.spacing(1),
  },
}));

interface TableFiltersSelectProps {
  readonly filter: Filters;
  readonly setFilter: (value: any) => void;
  readonly filterOptions: FilterOptions;
  readonly periodList: Period[];
}

export const TableFiltersSelect = memo(function TableFiltersSelect({
  filter,
  setFilter,
  filterOptions,
  periodList,
}: TableFiltersSelectProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const setFilterValue = (key: string, value: string[]) => {
    if (filter) {
      setFilter({
        ...filter,
        [key]: value,
      });
    } else {
      setFilter(null);
    }
  };

  // Create filter selection options by removing filter types already selected and required filters
  const filterSelectOptions = useMemo(
    () =>
      Object.keys(filterOptions)
        .filter((o) => !filter?.selected?.includes(o) && !filterOptions[o].required)
        .map((f) => ({ label: filterOptions[f].label, value: f })),
    [filter, filterOptions],
  );

  const onDelete = useCallback(
    (filterId) =>
      setFilter({
        ...filter,
        [filterOptions[filterId].filterName]: [],
        selected: filter.selected?.filter((f) => f !== filterId),
      }),
    [setFilter, filter, filterOptions],
  );

  const requiredFilters = useMemo(
    () => Object.keys(filterOptions).filter((fo) => !!filterOptions[fo].required),
    [filterOptions],
  );

  const setSelectedFilter = useCallback(
    (e) => setFilter({ ...filter, selected: [...(filter.selected ?? []), e.target.value] }),
    [setFilter, filter],
  );

  if (!filter) return null;

  return (
    <Grid
      container
      className={classes.headerFilter}
      direction="column"
      spacing={3}
    >
      <Grid xs={12}>
        <SelectFieldBase
          isFilter
          isSearchable
          searchWithAutocompletion
          id="filterList"
          options={filterSelectOptions}
          placeholder={formatMessage({ defaultMessage: 'Add a filter…' })}
          value=""
          onChange={setSelectedFilter}
        />
      </Grid>
      {requiredFilters.map((filterId) => (
        <TableFiltersSelectFilter
          key={filterId}
          filter={filter}
          filterId={filterId}
          filterOption={filterOptions[filterId]}
          periodList={periodList}
          setFilterValue={setFilterValue}
        />
      ))}
      {filter.selected?.map((filterId) => (
        <TableFiltersSelectFilter
          key={filterId}
          multiple
          filter={filter}
          filterId={filterId}
          filterOption={filterOptions[filterId]}
          periodList={periodList}
          setFilterValue={setFilterValue}
          onDelete={onDelete}
        />
      ))}
    </Grid>
  );
});
