import { IconSearch, IconTrash } from '@tabler/icons-react';
import { isEmpty, last } from 'lodash';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type PlanRule, type ComputedPlanRuleFieldsToDisplay } from '@amal-ia/compensation-definition/plans/types';
import { listConnectorsDefinitionWithLogos } from '@amal-ia/data-capture/connectors/assets';
import {
  CustomObjectDefToDataConnectorType,
  type BaseCustomObjectDefinition,
} from '@amal-ia/data-capture/models/types';
import { FilterOverwriteRemoveModalContainer } from '@amal-ia/data-capture/overwrites/components';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { Link } from '@amal-ia/ext/react-router-dom';
import { IconButtonLink, Table } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { OverwriteTypesEnum, type DatasetRow, type Overwrite, type Statement } from '@amal-ia/lib-types';
import { DatasetType, type Dataset } from '@amal-ia/payout-calculation/shared/types';

const connectorsDefinitions = listConnectorsDefinitionWithLogos();

export type DatasetRecordsDataGridActionsCellProps = {
  readonly dataset: Dataset;
  readonly datasetRow: DatasetRow;
  readonly fields: ComputedPlanRuleFieldsToDisplay[];
  readonly rowIndex: number;
  readonly rule: PlanRule;
  readonly statement?: Statement;
  readonly customObjectDefinition?: BaseCustomObjectDefinition;
  readonly isForecasted?: boolean;
  readonly onSetCurrentTracingData: (props: {
    datasetRow: DatasetRow;
    dataset: Dataset;
    fields: ComputedPlanRuleFieldsToDisplay[];
  }) => void;
  readonly onClearOverwrite: (overwrite: Overwrite) => void;
  readonly onSubmitOverwrite: (overwrite: unknown) => void;
};

export const DatasetRecordsDataGridActionsCell = memo(function DatasetRecordsDataGridActionsCell({
  dataset,
  datasetRow,
  fields,
  rowIndex,
  rule,
  statement,
  customObjectDefinition,
  isForecasted,
  onSetCurrentTracingData,
  onClearOverwrite,
  onSubmitOverwrite,
}: DatasetRecordsDataGridActionsCellProps) {
  const { formatMessage } = useIntl();
  const ability = useAbilityContext();

  const { isOverwriteModalOpen, setOverwriteModalOpenFalse, setOverwriteModalOpenTrue } = useBoolState(
    false,
    'overwriteModalOpen',
  );

  const connectorType = CustomObjectDefToDataConnectorType[customObjectDefinition?.type];
  const connectorDefinition = connectorsDefinitions[connectorType];

  const canDeleteRecord =
    dataset.type === DatasetType.filter &&
    !isForecasted &&
    (isEmpty(statement?.workflowSteps) || last(statement.workflowSteps).to === 0) &&
    ability.can(ActionsEnum.overwrite, SubjectsEnum.Statement);

  const deleteRecordDetails = useMemo(
    () => ({
      rule: rule?.name || 'no name', // Not sure that this is an actual use case, I copied from legacy.
      filterId: statement?.results?.definitions.filters[dataset.filterMachineName]?.id || 'no-id', // Not sure that this is an actual use case, I copied from legacy.
      definitionName: customObjectDefinition?.name,
      definitionId: customObjectDefinition?.id,
      rowNameOrId: datasetRow.name || datasetRow.externalId,
      rowExternalId: datasetRow.externalId,
      rowId: rowIndex,
      isAdditionalRow: datasetRow.isAdditionalRow,
    }),
    [rule, statement, dataset, customObjectDefinition, datasetRow, rowIndex],
  );

  const handleClickOpenTracing = useCallback(
    () => onSetCurrentTracingData({ datasetRow, dataset, fields }),
    [datasetRow, dataset, fields, onSetCurrentTracingData],
  );

  const handleClearOverwrite = useCallback(() => {
    const addedRowOverwrite = datasetRow.overwrites?.find(
      (overwrite) => overwrite.overwriteType === OverwriteTypesEnum.FILTER_ROW_ADD,
    );

    if (addedRowOverwrite) {
      onClearOverwrite(addedRowOverwrite);
    }
  }, [datasetRow, onClearOverwrite]);

  return (
    <Table.Cell.Actions>
      {datasetRow.isAdditionalRow ? (
        <Table.Cell.IconAction
          icon={<IconTrash />}
          label={formatMessage({ defaultMessage: 'Remove deal added from simulation' })}
          variant={Table.Cell.IconAction.Variant.DANGER}
          onClick={handleClearOverwrite}
        />
      ) : (
        <Fragment>
          {!!(connectorDefinition && datasetRow.url) && (
            <IconButtonLink
              withBackground
              icon={<connectorDefinition.CompactLogo />}
              size={IconButtonLink.Size.SMALL}
              label={formatMessage(
                { defaultMessage: 'View deal in {connector}' },
                { connector: connectorDefinition.name },
              )}
              to={
                <Link
                  openInNewTab
                  to={datasetRow.url}
                />
              }
            />
          )}

          <Table.Cell.IconAction
            icon={<IconSearch />}
            label={formatMessage({ defaultMessage: 'Open tracing' })}
            onClick={handleClickOpenTracing}
          />

          {!!canDeleteRecord && (
            <Table.Cell.IconAction
              icon={<IconTrash />}
              label={formatMessage({ defaultMessage: 'Remove this deal via an overwrite' })}
              variant={Table.Cell.IconAction.Variant.DANGER}
              onClick={setOverwriteModalOpenTrue}
            />
          )}
        </Fragment>
      )}

      <FilterOverwriteRemoveModalContainer
        currentFilterOverwriteRemoveObjectDetails={deleteRecordDetails}
        handleClose={setOverwriteModalOpenFalse}
        handleSubmit={onSubmitOverwrite}
        isOpen={isOverwriteModalOpen}
      />
    </Table.Cell.Actions>
  );
});
