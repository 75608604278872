import { pick } from 'lodash';
import { type SetRequired } from 'type-fest';

import { toTimestamp } from '@amal-ia/kernel/dates';
import { dateIsInAssignmentRange } from '@amal-ia/lib-types';
import { type TeamAssignment, TeamRole } from '@amal-ia/tenants/assignments/teams/types';
import { type Team } from '@amal-ia/tenants/teams/types';
import { type UserContract, UserRole } from '@amal-ia/tenants/users/shared/types';

type TeamId = NonNullable<Team['id']>;

export interface HierarchyContextDehydrated {
  teamAssignments: SetRequired<TeamAssignment, 'user'>[];
  subTeamsOfEachTeam: Record<TeamId, TeamId[] | undefined>;
}

export class HierarchyContext {
  private subTeamsOfEachTeam: HierarchyContextDehydrated['subTeamsOfEachTeam'];

  private readonly currentUserAssignments: TeamAssignment[];

  public constructor(
    private readonly currentUser: UserContract,
    private readonly teamAssignments: SetRequired<TeamAssignment, 'user'>[],
    teamHierarchy: Team[],
  ) {
    // Check team assignments contains users.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Falsy when relation isn't loaded.
    if (teamAssignments.some((ta) => !ta.user)) {
      throw new Error('Hierarchy Context should be instantiated with its users.');
    }

    this.currentUserAssignments = teamAssignments.filter((t) => t.user.id === currentUser.id);

    // If the current user is a manager, populate the subteams.
    if ([UserRole.MANAGER, UserRole.READ_ONLY_MANAGER].includes(currentUser.role)) {
      // Build the list of teamIds in the hierarchy where the current user can be manager.
      // It avoids dumping the full hierarchy in this class.
      this.subTeamsOfEachTeam = pick(
        HierarchyContext.buildSubTeamsHierarchyTree(teamHierarchy),
        // Filter the list of assignments to retrieve those where the current user is manager.
        this.currentUserAssignments
          .filter((managerAssignment) => managerAssignment.teamRole === TeamRole.TEAM_MANAGER)
          .map((ta) => ta.teamId),
      );
    } else {
      this.subTeamsOfEachTeam = {};
    }
  }

  /**
   * Get team assignments of the current user at a given date.
   * @param date
   */
  public getUserTeamAssignments(date: Date | number): TeamAssignment[] {
    const observationDateTimestamp = toTimestamp(date);
    return this.currentUserAssignments.filter((teamAssignment) =>
      dateIsInAssignmentRange(observationDateTimestamp, teamAssignment),
    );
  }

  /**
   * Returns true if the user is a member of the team at a given date.
   * @param teamId
   * @param date
   */
  public isTeamMember(teamId: TeamId, date: Date | number): boolean {
    return this.getUserTeamAssignments(date)
      .map((ta) => ta.teamId)
      .includes(teamId);
  }

  /**
   * Returns true if the user is managing a specific team at a given date.
   *
   * @param teamId
   * @param date
   */
  public isTeamManager(teamId: TeamId, date: Date | number): boolean {
    return this.getTeamIdsWhereUserIsManager(date).includes(teamId);
  }

  private getOngoingManagerAssignmentsOfCurrentUser(observationDateTimestamp: number): TeamAssignment[] {
    return this.currentUserAssignments.filter(
      (managerAssignment) =>
        managerAssignment.teamRole === TeamRole.TEAM_MANAGER &&
        dateIsInAssignmentRange(observationDateTimestamp, managerAssignment),
    );
  }

  /**
   * Get the list of team ids that are managed by the current user.
   * @param date
   */
  public getTeamIdsWhereUserIsManager(date: Date | number): TeamId[] {
    const observationDateTimestamp = toTimestamp(date);

    const ongoingManagerAssignments = this.getOngoingManagerAssignmentsOfCurrentUser(observationDateTimestamp);

    if (!ongoingManagerAssignments.length) {
      return [];
    }

    // Compute the list of teams where the current user is manager based on the hierarchy.
    return ongoingManagerAssignments.flatMap((ma) => [ma.teamId, ...(this.subTeamsOfEachTeam[ma.teamId] || [])]);
  }

  /**
   * Get the list of all team assignments for which the current user is manager at a given date.
   * @param date
   */
  public getSubordinates(date: Date | number) {
    const observationDateTimestamp = toTimestamp(date);

    const listOfTeamsWhereCurrentUserIsCurrentlyManager = this.getTeamIdsWhereUserIsManager(observationDateTimestamp);

    const listOfTeamsWhereCurrentUserIsAssignedAsManager = this.getOngoingManagerAssignmentsOfCurrentUser(
      observationDateTimestamp,
    ).map((ma) => ma.teamId);

    // Knowing all the teams where the current user has authority on the given date, we can filter assignments.
    return this.teamAssignments.filter(
      (employeeAssignment) =>
        listOfTeamsWhereCurrentUserIsCurrentlyManager.includes(employeeAssignment.teamId) &&
        dateIsInAssignmentRange(observationDateTimestamp, employeeAssignment) &&
        // The user is me
        (employeeAssignment.user.id === this.currentUser.id ||
          // OR the user is not a manager.
          employeeAssignment.teamRole !== TeamRole.TEAM_MANAGER ||
          // OR the user is a manager of a team I manage (but not at the same level as me).
          // Because business rules states that if we're two manager of the same team,
          // we technically don't manage each others.
          !listOfTeamsWhereCurrentUserIsAssignedAsManager.includes(employeeAssignment.teamId)),
    );
  }

  public getSubordinateIds(date: Date | number) {
    return this.getSubordinates(date).map((employeeAssignment) => employeeAssignment.user.id);
  }

  /**
   * Return true if the current user is manager of a given employee at a given date.
   *
   * @param employeeId - UserId of the employee.
   * @param date
   */
  public isManagerOf(employeeId: string, date: Date | number) {
    return (
      this.getSubordinates(date).filter((employeeAssignment) => employeeAssignment.user.id === employeeId).length > 0
    );
  }

  /**
   * Builds a hashmap. Key is the teamId, value is an array containing the id of all
   * teams that are a subteam of the teamId.
   *
   * @param teamHierarchy
   * @private
   */
  private static buildSubTeamsHierarchyTree(teamHierarchy: Team[]): Record<TeamId, TeamId[]> {
    return teamHierarchy.reduce(
      (acc, curr) => {
        const parsingOfMyChildren = curr.childrenTeams?.length
          ? HierarchyContext.buildSubTeamsHierarchyTree(curr.childrenTeams)
          : {};

        return {
          ...acc,
          ...parsingOfMyChildren,
          [curr.id!]: Object.keys(parsingOfMyChildren),
        };
      },
      {} as Record<TeamId, TeamId[]>,
    );
  }

  /**
   * Returning undefined to avoid having it unintentionally in a DTO.
   */
  public toString() {
    return undefined;
  }

  /**
   * Transform the manager scope, to put it in a key value storage or to send
   * it to the frontend.
   */
  public dehydrate(): HierarchyContextDehydrated {
    return {
      teamAssignments: this.teamAssignments,
      subTeamsOfEachTeam: this.subTeamsOfEachTeam,
    };
  }

  /**
   * Given an objectManagerScope, builds a class.
   *
   * @param currentUser
   * @param objectManagerScope
   */
  public static hydrate(currentUser: UserContract, objectManagerScope: HierarchyContextDehydrated) {
    const hierarchyContext = new HierarchyContext(currentUser, objectManagerScope.teamAssignments, []);

    hierarchyContext.subTeamsOfEachTeam = objectManagerScope.subTeamsOfEachTeam;

    return hierarchyContext;
  }
}
