import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const DesignerContainer = lazy(() => import('./Designer/common/DesignerContainer'));
const PlanDesignerWrapped = lazy(() => import('./Designer/PlanDesignerWrapped'));
const ToDesignerV2 = lazy(() => import('./DesignerNew/ToDesignerV2'));

export const DesignerRoutes = memo(function DesignerRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="designer">
      <RootRoutes>
        <Route
          path={routes.DESIGNER_V2_OBJECT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <PlanDesignerWrapped />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DESIGNER_V2_PLAN}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <PlanDesignerWrapped />
            </AbilityRouteProtector>
          }
        />

        {/* Link to designer v2 without a plan => load a plan and redirects to designer v2 */}
        <Route
          path={routes.DESIGNER_V2}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <ToDesignerV2 />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DESIGNER_OBJECT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <DesignerContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DESIGNER}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Plan)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Designer' })} />
              <DesignerContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
