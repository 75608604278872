import { flatten } from 'lodash';
import { useMemo } from 'react';

import { type VariableDefinition } from '@amal-ia/amalia-lang/tokens/types';
import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { type ComputedVariable, type ComputedVariableWithVariableIdAndLabel } from '@amal-ia/lib-types';
import { type StatementDetailContextInterface } from '@amal-ia/lib-ui';
import { type ComputedItem } from '@amal-ia/payout-calculation/shared/types';

/**
 * A hook that return all the computed variables from a statement context that are related to a specific rule.
 */
export const useStatementRuleComputedVariables = (
  statementContext: StatementDetailContextInterface,
  ruleDefinition: PlanRule,
) =>
  useMemo(() => {
    const kpisToDisplay = statementContext.isForecastedView
      ? flatten(statementContext.objectsToDisplay?.[ruleDefinition.id]?.kpisToDisplay?.map((section) => section.kpis))
      : flatten(ruleDefinition.kpisToDisplay?.map((section) => section.kpis));

    // Retrieve the variable definition of each KPI
    const variableDefinitions: VariableDefinition[] = (kpisToDisplay || [])
      .map((kpi) => Object.values(statementContext.results.definitions.variables).find((vd) => vd.id === kpi.id))
      .filter(Boolean);

    // For each definition, search for its computed variable
    return variableDefinitions
      .map((variableDefinition) => {
        let achievedVariable: ComputedItem;

        if (statementContext.isForecastedView && statementContext.originalStatement) {
          achievedVariable = statementContext.originalStatement.results?.computedObjects?.find(
            (computedObject) =>
              (computedObject as ComputedVariable).variableMachineName === variableDefinition.machineName,
          );
        }

        const computedVariable = statementContext.results.computedObjects.find(
          (computedObject) =>
            (computedObject as ComputedVariable).variableMachineName === variableDefinition.machineName,
        );

        if (computedVariable) {
          return {
            ...(computedVariable as ComputedVariable),
            label: variableDefinition.name,
            kpiVariableId: variableDefinition.id,
            achievedValue:
              statementContext.isForecastedView && !!achievedVariable
                ? achievedVariable.overwrite?.overwriteValue ?? achievedVariable.value
                : null,
          };
        }

        return null;
      })
      .filter(Boolean) as ComputedVariableWithVariableIdAndLabel[];
  }, [
    ruleDefinition.id,
    ruleDefinition.kpisToDisplay,
    statementContext.isForecastedView,
    statementContext.objectsToDisplay,
    statementContext.originalStatement,
    statementContext.results,
  ]);
