import { css, useTheme } from '@emotion/react';
import { IconGripVertical, type TablerIconsProps } from '@tabler/icons-react';
import { type ComponentPropsWithoutRef, forwardRef, type ForwardedRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

export type GrabHandleProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      size?: TablerIconsProps['size'];
    },
  ]
>;

export const GrabHandle = forwardRef(function GrabHandle(
  { size = 16, ...props }: GrabHandleProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme();

  return (
    <div
      {...props}
      ref={ref}
      css={css`
        cursor: grab;
        display: flex;
        align-items: center;
        outline: none;
      `}
    >
      <IconGripVertical
        color={theme.ds.colors.gray[500]}
        size={size}
      />
    </div>
  );
});
