// FIXME: cant import React libs for now, should probably use babel-loader?
import { UserRole } from '@amal-ia/tenants/users/shared/types';

export const EXPECTED_ROLE_LABELS: Readonly<Record<UserRole, string>> = {
  [UserRole.ADMIN]: 'Admin',
  [UserRole.READ_ONLY_ADMIN]: 'Read-only admin',
  [UserRole.FINANCE]: 'Finance',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.READ_ONLY_MANAGER]: 'Read-only manager',
  [UserRole.EMPLOYEE]: 'Employee',
  [UserRole.READ_ONLY_EMPLOYEE]: 'Read-only Employee',
} as const;
