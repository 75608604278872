import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { formatTotal } from '@amal-ia/lib-types';
import { Bar, type AxisColor } from '@amal-ia/lib-ui';

import { type LeaderboardDetailsProps } from './LeaderboardDetails/LeaderboardDetails';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(10),
  },
}));

const HISTOGRAM_PROPS = {
  margin: 20,
  height: 220,
  width: 500,
  styles: {
    color: 'primary' as 'primary' | 'secondary' | 'tertiary' | undefined,
    isGradient: true,
    mainAxisColor: 'notVisible' as AxisColor,
    mainAxisLabelColor: 'notVisible' as AxisColor,
    dimensionAxisColor: 'notVisible' as AxisColor,
    rotateXAxisLabels: true,
  },
  options: {
    showDataLabels: true,
    barWidth: 20,
    radiusCoefficient: 1,
  },
};

export const LeaderboardHistogram = memo(function LeaderboardHistogram({
  challenge,
  usersMap,
  teamsMap,
  company,
  comparisonVariable,
}: LeaderboardDetailsProps) {
  const classes = useStyles();

  const isTeamMode = useMemo(() => challenge.leaderboard.some((res) => !!res.teamId), [challenge]);

  const data = useMemo(
    () =>
      challenge.leaderboard // Remove non-eligible.
        .filter((l) => !!l.position)
        // Map labels.
        .map((res) => {
          const label = isTeamMode ? teamsMap[res.teamId].name : usersMap[res.userId].firstName;
          return {
            x: label,
            value: res.comparisonValue,
            valueLabel: formatTotal(res.comparisonValue, comparisonVariable.format, company.currency)?.toString(),
          };
        }),
    [challenge, usersMap, teamsMap, isTeamMode, comparisonVariable.format, company],
  );

  return (
    <Box className={classes.root}>
      <Bar
        data={data}
        {...HISTOGRAM_PROPS}
      />
    </Box>
  );
});
