import { memo } from 'react';

import { assert } from '@amal-ia/ext/typescript';
import { Badge } from '@amal-ia/frontend/design-system/components';
import { TeamRole } from '@amal-ia/tenants/assignments/teams/types';

import { TeamRoleLabel } from './TeamRoleLabel';

export type TeamRoleBadgeProps = {
  readonly teamRole: TeamRole;
};

type BadgeHue = (typeof Badge.Hue)[keyof typeof Badge.Hue];

export const TeamRoleBadgeVariantRecord: Readonly<Record<TeamRole, BadgeHue>> = {
  [TeamRole.TEAM_MANAGER]: Badge.Hue.MAGENTA,
  [TeamRole.TEAM_EMPLOYEE]: Badge.Hue.BLUE,
} as const;

export const TeamRoleBadge = memo(function TeamRoleBadge({ teamRole }: TeamRoleBadgeProps) {
  assert(teamRole in TeamRoleBadgeVariantRecord, `Invalid team role: ${teamRole}`);

  const badgeVariant = TeamRoleBadgeVariantRecord[teamRole];

  return (
    <Badge
      size={Badge.Size.MEDIUM}
      variant={badgeVariant}
    >
      <TeamRoleLabel teamRole={teamRole} />
    </Badge>
  );
});
