import { type ActionCreator } from 'redux';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { assert } from '@amal-ia/ext/typescript';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';
import {
  CompanyCurrencyRatesApiClient,
  CurrenciesApiClient,
  CurrencyRatesApiClient,
} from '@amal-ia/tenants/companies/currency-rates/shared/api-client';
import { type CompanyCurrency, type Currency } from '@amal-ia/tenants/companies/currency-rates/shared/types';

import { COMPANIES_ACTIONS } from './constants';

const currencyStart: ActionCreator<ReduxAction> = () => ({
  type: COMPANIES_ACTIONS.START,
});

const currencyError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: COMPANIES_ACTIONS.ERROR,
  error,
});

// CURRENCY RATES
const setCurrencyRate: ActionCreator<ReduxAction> = (currencyRate: number) => ({
  type: COMPANIES_ACTIONS.SET_CURRENCY_RATE,
  payload: { currencyRate },
});

export const fetchCurrencyRate =
  (symbol: CurrencySymbolsEnum, date: number): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const currencyRate = await CurrencyRatesApiClient.getCurrencyRate(symbol, date);
      return dispatch(setCurrencyRate(currencyRate));
    } catch (error) {
      return dispatch(currencyError(error));
    }
  };

// COMPANY CURRENCIES
const setCurrenciesWithCompanyCurrencies: ActionCreator<ReduxAction> = (
  currencies: Currency[],
  companyCurrencies: CompanyCurrency[],
) => ({
  type: COMPANIES_ACTIONS.SET_CURRENCIES_WITH_COMPANY_CURRENCIES,
  payload: { currencies, companyCurrencies },
});

export const fetchCurrenciesWithCompanyCurrenciesForYear =
  (year: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const [currencies, companyCurrencies] = await Promise.all([
        CurrenciesApiClient.getCurrencies(year.toString()),
        CompanyCurrencyRatesApiClient.list(year.toString()),
      ]);

      return dispatch(setCurrenciesWithCompanyCurrencies(currencies, companyCurrencies));
    } catch (error) {
      return dispatch(currencyError(error));
    }
  };

// COMPANY CURRENCY
const createCompanyCurrencyAction: ActionCreator<ReduxAction> = (companyCurrency: CompanyCurrency) => ({
  type: COMPANIES_ACTIONS.COMPANY_CURRENCY_CREATE,
  payload: { companyCurrency },
});

export const createCompanyCurrency =
  (companyCurrencyToCreate: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const companyCurrencyCreated = await CompanyCurrencyRatesApiClient.create(companyCurrencyToCreate);
      return dispatch(createCompanyCurrencyAction(companyCurrencyCreated));
    } catch (error) {
      return dispatch(currencyError(error));
    }
  };

const updateCompanyCurrencyAction: ActionCreator<ReduxAction> = (companyCurrency: CompanyCurrency) => ({
  type: COMPANIES_ACTIONS.COMPANY_CURRENCY_UPDATE,
  payload: { companyCurrency },
});

export const updateCompanyCurrency =
  (companyCurrencyToUpdate: Pick<CompanyCurrency, 'currency' | 'rate' | 'symbol'>): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      const companyCurrencyUpdated = await CompanyCurrencyRatesApiClient.update(companyCurrencyToUpdate);
      return dispatch(updateCompanyCurrencyAction(companyCurrencyUpdated));
    } catch (error) {
      return dispatch(currencyError(error));
    }
  };

const deleteCompanyCurrencyAction: ActionCreator<ReduxAction> = (companyCurrency: CompanyCurrency) => ({
  type: COMPANIES_ACTIONS.COMPANY_CURRENCY_DELETE,
  payload: { companyCurrency },
});

export const deleteCompanyCurrency =
  (companyCurrencyToDelete: CompanyCurrency): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(currencyStart());

    try {
      assert(companyCurrencyToDelete.id, 'Company currency was not created yet!');
      await CompanyCurrencyRatesApiClient.delete(companyCurrencyToDelete.id);
      return dispatch(deleteCompanyCurrencyAction(companyCurrencyToDelete));
    } catch (error) {
      return dispatch(currencyError(error));
    }
  };
