import { Search } from '@mui/icons-material';
import { InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback, type ReactNode, type ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { Spinner } from '../../loaders/Spinner/Spinner';

interface SearchModalProps {
  readonly isLoading: boolean;
  readonly filter: string;
  readonly onChangeFilter: (value: string) => void;
  readonly className?: string;
  readonly children?: ReactNode;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  modal: {
    width: '400px',
    backgroundColor: theme.palette.common.white,
    // Boundary for the Spinner.
    position: 'relative',
  },
  listContainer: {
    height: '400px',
    overflow: 'auto',
  },
  nameContainer: {
    flex: '1',
    paddingLeft: '8px',
  },
  searchInput: {
    flex: '1',
    margin: `0 ${theme.spacing(1)}`,
  },

  inputBorderNone: {
    border: 'none',
  },
  inputRound: {
    borderRadius: '24px',
  },
  inputSquared: {
    borderRadius: '4px',
  },
  simpleSearchBorder: {
    border: `1px solid ${theme.palette.grey['200']}`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.25),
    marginBottom: theme.spacing(1),
  },
}));

export const SearchModal = memo(function SearchModal({
  children,
  isLoading,
  className,
  filter,
  onChangeFilter,
}: SearchModalProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const onChangeProxy = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChangeFilter(e.target.value);
    },
    [onChangeFilter],
  );

  return (
    <div className={clsx(className, classes.modal)}>
      <div className={classes.simpleSearchBorder}>
        <Search />
        <InputBase
          className={classes.searchInput}
          name="search-modal-input"
          placeholder={formatMessage({ defaultMessage: 'Search' })}
          value={filter}
          onChange={onChangeProxy}
        />
      </div>
      <div className={classes.listContainer}>
        {isLoading ? <Spinner /> : null}
        {children}
      </div>
    </div>
  );
});
