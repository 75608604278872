import { type FormulaEditorToken } from '@amal-ia/amalia-lang/formula/components';
import { DesignerTokenIcon } from '@amal-ia/amalia-lang/tokens/components';
import { VENDOR_LOGOS } from '@amal-ia/data-capture/connectors/assets';
import { IconAmaliaLogoColoured } from '@amal-ia/frontend/ui-icons';
import { TokenType } from '@amal-ia/lib-types';

export const useDropdownItemIcon = (item: FormulaEditorToken) => {
  if (item.type === TokenType.FILTER) {
    const CompactLogo = item.dataConnectorType
      ? VENDOR_LOGOS[item.dataConnectorType].CompactLogo
      : IconAmaliaLogoColoured;
    return <CompactLogo />;
  }

  return (
    <DesignerTokenIcon
      tokenFormat={item.format}
      tokenType={item.type}
    />
  );
};
