import { type CSSProperties } from 'react';

import { type Filter, type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class FilterFormulaNode extends IFormulaNode {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Filter,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.FILTER, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.filter };
  }

  public getDesignerPath() {
    return (this.nodeObject as Filter)?.id ? { type: TokenType.FILTER, id: (this.nodeObject as Filter).id } : null;
  }

  public getLabel(): string {
    return (this.nodeObject as Filter).name.trim();
  }
}
