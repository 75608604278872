import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AlertBanner } from '@amal-ia/frontend/design-system/components';
import {
  selectCurrentPaymentCategory,
  selectPeriodContainsStatementWithHoldRules,
} from '@amal-ia/frontend/web-data-layers';
import { PaymentCategory } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { StatementHistogram } from '@amal-ia/lib-ui-business';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { StatementSummaryLayoutHistogram } from '../../../common/statement-summary-layout/histogram/StatementSummaryLayoutHistogram';
import { useStatementDetailStatistics } from '../hooks/useStatementDetailStatistics';

export type StatementDetailSummaryHistogramProps = {
  readonly onClickGoToStatement: (period: string) => void;
};

export const StatementDetailSummaryHistogram = memo(function StatementDetailSummaryHistogram({
  onClickGoToStatement,
}: StatementDetailSummaryHistogramProps) {
  const statement = useStatementDetailContext();
  const containsHoldRules = useSelector(selectPeriodContainsStatementWithHoldRules);
  const currentPaymentCategory = useSelector(selectCurrentPaymentCategory);

  const { histogramDefinitions, histogramData } = useStatementDetailStatistics(statement);

  const hasHistogram = !statement.isForecastedView && !isEmpty(histogramData);

  return (
    <StatementSummaryLayoutHistogram>
      {hasHistogram ? (
        <div
          css={
            // Lower opacity when the payment category is not achieved because the chart is confusing for users.
            currentPaymentCategory !== PaymentCategory.achievement &&
            css`
              opacity: 0.33;
            `
          }
        >
          <StatementHistogram
            containsHoldRules={containsHoldRules}
            definitions={histogramDefinitions}
            items={histogramData}
            planName={statement.plan.name}
            user={statement.user as UserContract}
            onClickBar={onClickGoToStatement}
          />
        </div>
      ) : (
        <AlertBanner inline>
          {statement.isForecastedView ? (
            <FormattedMessage defaultMessage="This chart is not available for forecast." />
          ) : (
            <FormattedMessage defaultMessage="No data available." />
          )}
        </AlertBanner>
      )}
    </StatementSummaryLayoutHistogram>
  );
});
