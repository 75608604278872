import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { type RootState, type TracingTypes } from '@amal-ia/lib-types';

import { type RelationshipsMap } from '../../types/relationships';

export const selectCustomObjectDefinitionsMap = (state: RootState): CustomObjectDefinitionsMap =>
  state.customObjectDefinitions.map;

export const selectCustomObjectDefinitionsList = createSelector(
  [selectCustomObjectDefinitionsMap],
  (map) => map && Object.values(map).sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectCustomObjectDefinitionsMapById = createSelector([selectCustomObjectDefinitionsMap], (map) =>
  keyBy(Object.values(map || {}) || [], 'id'),
);

export const selectRelationshipsMap = (state: RootState): RelationshipsMap =>
  state.customObjectDefinitions.relationshipsMap;

export const selectRelationshipsList = createSelector([selectRelationshipsMap], (map) => Object.values(map || {}));

export const selectIsCustomObjectDefinitionsLoading = (state: RootState): boolean =>
  !!state.customObjectDefinitions.isLoading;

export const selectCustomObjectDefinitionsProperties = createSelector(
  [selectCustomObjectDefinitionsList],
  (customObjectDefinitions): TracingTypes.Property[] =>
    (customObjectDefinitions || []).flatMap((definition) =>
      Object.entries(definition.properties).map(([propertyMachineName, property]) => ({
        ...property,
        id: propertyMachineName,
        name: property.name || propertyMachineName,
        definition,
        format: property?.format,
      })),
    ),
);
