import { type PlanCategory, PlanRuleCategoryColor } from '@amal-ia/compensation-definition/plans/types';

const STATEMENT_CATEGORY_COLORS = Object.values(PlanRuleCategoryColor);

/**
 * Return the category defined color, or a deterministic color based on the category name.
 *
 * @param category Plan rule category.
 * @returns Plan rule category color.
 */
export const usePlanRuleCategoryColor = (category: PlanCategory): PlanRuleCategoryColor => {
  // At some point we will allow the user to choose the color of the category, so we will just need to return said color here, and fallback to the deterministic algorithm below.

  // Sum all the character codes of the category name.
  // This is arbitrary, but deterministic.
  const index = category.name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Return the color at the index of the sum modulo the number of colors.
  return STATEMENT_CATEGORY_COLORS[index % STATEMENT_CATEGORY_COLORS.length];
};
