import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { http } from '@amal-ia/frontend/kernel/http';

export async function saGetPeriods(companyId: string): Promise<Period[]> {
  const { data } = await http.get<Period[]>('/sa_periods', {
    params: {
      companyId,
    },
  });
  return data;
}
