import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';

import { PeriodsApiClient } from '@amal-ia/compensation-definition/api-client';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';

import { PERIOD_QUERY_KEYS } from './query-keys';

export const usePeriods = () => {
  const { snackError } = useSnackbars();

  const {
    data: periods,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: [PERIOD_QUERY_KEYS.PERIODS],
    queryFn: () => PeriodsApiClient.getAllPeriods(),

    staleTime: Infinity,
  });

  useEffect(() => {
    if (isError) {
      snackError((error as AxiosError<{ message?: string }>).response?.data.message || error.message);
    }
  }, [isError, error, snackError]);

  const { periodsMap, periodsList } = useMemo(
    () => ({
      periodsMap: keyBy(periods || [], 'id'),
      periodsList: (periods || []).sort((p1, p2) => (p1.startDate > p2.startDate ? 1 : -1)),
    }),
    [periods],
  );

  return {
    periodsMap,
    periodsList,
    ...rest,
  };
};
