import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const FlowFormContainer = lazy(() => import('./edit/FlowFormContainer'));
const FlowList = lazy(() => import('./list/FlowList'));

export const FlowRoutes = memo(function FlowRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="flow">
      <RootRoutes>
        <Route
          path={routes.FLOW_CREATE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Flow)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Create Flow' })} />
              <FlowFormContainer isCreateMode />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FLOW}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Flow)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Flow' })} />
              <FlowFormContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FLOWS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Flow)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Flows' })} />
              <FlowList />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
