import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { FormField, Typography } from '@amal-ia/frontend/design-system/components';
import { formatUserFullName, type Statement } from '@amal-ia/lib-types';
import { Avatar } from '@amal-ia/lib-ui';

interface StatementDetailsProps {
  readonly statement: Statement;
}

export const AdjustmentStatementDetails = memo(function AdjustmentStatementDetails({
  statement,
}: StatementDetailsProps) {
  const useStyles = makeStyles((theme: AmaliaThemeType) => ({
    avatar: {
      margin: `0 ${theme.spacing(2)} 0 ${theme.spacing(1)}`,
    },
  }));

  const classes = useStyles();

  return (
    <FormField
      id="statement"
      label={<FormattedMessage defaultMessage="Statement" />}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box className={classes.avatar}>
          <Avatar user={statement.user} />
        </Box>
        <Box>
          <Grid
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Typography
              as="div"
              variant={Typography.Variant.BODY_BASE_MEDIUM}
            >
              <FormattedMessage
                defaultMessage="{userName} / {periodName}"
                values={{ userName: formatUserFullName(statement.user), periodName: statement.period.name }}
              />
            </Typography>
            <Typography
              as="div"
              variant={Typography.Variant.BODY_BASE_MEDIUM}
            >
              {statement.plan.name}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </FormField>
  );
});
