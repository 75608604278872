import moment from 'moment';

import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { TokenType } from '@amal-ia/lib-types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.daysTo, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 2;

func.description = 'Return the number of days between two dates';

func.params = [
  {
    name: 'date1',
    description:
      'Date to start counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  {
    name: 'date2',
    description:
      'Date to end counting at: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns 5',
    formula: 'daysTo(toDate("2023-05-01"), toDate("2023-05-06"))' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns 6',
    formula: 'daysTo(toDate("2023-04-28"), toDate("2023-05-04"))' as AmaliaFormula,
    result: 6,
  },
  {
    desc: 'Returns the number of days between the statement start date and the Close Date.',
    formula: 'daysTo(statementPeriod.startDate, opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date1: moment.MomentInput | string, date2: moment.MomentInput | string): number | null =>
  moment(date2, 'X').diff(moment(date1, 'X'), 'days');

export default func;
