import { useCallback } from 'react';

import { type FormulaBuilderFunctionBlockForm } from '@amal-ia/amalia-lang/formula/form';
import {
  type AttributeValue,
  ValueOrAttributeType,
  FormulaBuilderFunctionCategory,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { type InputProps, type RadioButtonGroupProps } from '@amal-ia/frontend/design-system/components';

import { type FormulaConditionTagProps } from '../../formula-condition-tag/FormulaConditionTag';

export type useValueOrAttributeHandlersProps<TFunctionBlockForm extends FormulaBuilderFunctionBlockForm> = {
  condition: TFunctionBlockForm;
  onChange: Required<FormulaConditionTagProps<TFunctionBlockForm>>['onChange'];
};

const formatValue = (category: FormulaBuilderFunctionCategory, value: number | string | null) => {
  switch (category) {
    case FormulaBuilderFunctionCategory.PERCENT:
      return Number(value) / 100;
    case FormulaBuilderFunctionCategory.NUMBER:
      return Number(value);
    default:
      return `${value}`;
  }
};

export const useValueOrAttributeHandlers = <TFunctionBlockForm extends FormulaBuilderFunctionBlockForm>({
  onChange,
  condition,
}: useValueOrAttributeHandlersProps<TFunctionBlockForm>) => {
  const handleChangeValueOrAttribute: Required<RadioButtonGroupProps<ValueOrAttributeType>>['onChange'] = useCallback(
    (type) =>
      onChange({
        ...condition,
        args: [
          condition.args[0],
          condition.args[1]!.type !== type
            ? type === ValueOrAttributeType.VALUE
              ? {
                  type,
                  value: '',
                }
              : {
                  type,
                }
            : condition.args[1],
        ],
      }),
    [onChange, condition],
  );

  const handleChangeManualValue: Required<InputProps>['onChange'] = useCallback(
    (value) =>
      onChange({
        ...condition,
        isDraft: false,
        args: [
          condition.args[0],
          {
            type: ValueOrAttributeType.VALUE,
            value: formatValue(condition.category, value),
          },
        ],
      }),
    [onChange, condition],
  );

  const handleChangeAttributeValue = useCallback(
    (attribute: AttributeValue) =>
      onChange({
        ...condition,
        isDraft: false,
        args: [condition.args[0], attribute],
      }),
    [onChange, condition],
  );

  return {
    handleChangeValueOrAttribute,
    handleChangeManualValue,
    handleChangeAttributeValue,
  };
};
