import { defineMessages } from 'react-intl';

import { RuleType } from '@amal-ia/compensation-definition/plans/types';

export const RULE_TYPES_CONF_LABELS = defineMessages({
  [RuleType.PAYOUT]: { defaultMessage: 'Payout', description: 'Rules Types Admin label' },
  [RuleType.LINE_PAYOUT]: { defaultMessage: 'Line payout', description: 'Rules Types Admin label' },
  [RuleType.NON_PAYOUT]: { defaultMessage: 'Non-payout', description: 'Rules Types Admin label' },
  [RuleType.SPLIT]: { defaultMessage: 'Split payout', description: 'Rules Types Admin label' },
  [RuleType.HOLD_AND_RELEASE]: { defaultMessage: 'Hold and Release', description: 'Rules Types Admin label' },
  [RuleType.CHALLENGE]: { defaultMessage: 'Challenge', description: 'Rules Types Admin label' },
});

export const RULE_TYPES_LABELS_TRANSLATED = defineMessages({
  [RuleType.PAYOUT]: {
    defaultMessage: 'Payout',
    description: 'Rules Types Statement label',
  },
  [RuleType.LINE_PAYOUT]: {
    defaultMessage: 'Payout',
    description: 'Rules Types Statement label',
  },
  [RuleType.NON_PAYOUT]: {
    defaultMessage: 'Non-payout',
    description: 'Rules Types Statement label',
  },
  [RuleType.SPLIT]: {
    defaultMessage: 'Split payout',
    description: 'Rules Types Statement label',
  },
  [RuleType.HOLD_AND_RELEASE]: {
    defaultMessage: 'Hold & Release',
    description: 'Rules Types Statement label',
  },
  [RuleType.CHALLENGE]: {
    defaultMessage: 'Challenge',
    description: 'Rules Types Statement label',
  },
});
