import { type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type Company } from '@amal-ia/tenants/companies/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { type BadgeConfiguration } from './badges';
import { type PlanAssignment } from './plan-assignments';
import { type PlanForecast } from './plans/forecasts';
import { type PlanRule, type PlanRuleCategory } from './plans/rules';
import { PlanRuleChartEnumType } from './plans/rules/charts';

export interface ComputedHighlightedKpi {
  format: FormatsEnum;

  minimumValue: number;
  maximumValue: number;
  currentValue: number | null;

  progress: number;

  minimumVariableLabel: string | null;
  maximumVariableLabel: string | null;
  variableLabel: string;
}

export interface PlanRuleCategoryAssignment {
  id: string;
  name: string;
  index: number;
}

export enum HighlightedKpiIdentifier {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export interface HighlightedKpi {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  identifier: HighlightedKpiIdentifier;

  company?: Company;

  plan?: Plan;
  planId: string;

  variable?: Variable;
  variableId: string;

  minimumVariable?: Variable | null;
  minimumVariableId: string | null;

  maximumVariable?: Variable | null;
  maximumVariableId: string | null;
}

export interface IndexedPlanTemplate {
  dependencyIds: string[];
}

export interface PlanTemplateError {
  context: string;
  message: string;
}

export interface Plan {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  name: string;
  frequency: PeriodFrequencyEnum;
  calculationBatchSize: number | null;
  calculationMaxParallel: number | null;
  maxRecursionLevel: number | null;
  linkToPlanDocumentation: string | null;
  indexedWeight: number;
  manualWeight: number | null;

  archived: boolean;
  isForecasted: boolean;
  calculateBothStatements: boolean;
  isSimulationEnabled: boolean;
  isSimulationAddRecordEnabled: boolean;

  planTemplate?: object | null;
  indexedPlanTemplate?: IndexedPlanTemplate | null;
  planTemplateError: PlanTemplateError | null;

  company?: Company;
  user?: UserContract;
  userId: string | null;
  forecast?: PlanForecast;
  forecastId: string | null;
  workflowId: string | null;

  // FIXME: we should remove this one and use a better DTO instead
  planAssignements?: PlanAssignment[];

  highlightedKpis?: HighlightedKpi[];
  badgeConfigurations?: BadgeConfiguration[];
  rules?: PlanRule[];
  categories?: PlanRuleCategory[];
  categoriesV2: PlanCategory[] | null;
  /* True if the plan uses a variable result instead of total */
  useVariableInsteadOfTotal: boolean | null;
  /* Variable to use if useVariableInsteadOfTotal is true */
  totalVariableId: string | null;

  adjustments_index?: number;
}

// FIXME: @sylvain I picked that were returned by the API
// It removes id & dates from kpis for example (should we return them/ also used in computed statement)
export type PlanResponseDto = Pick<
  Plan,
  | 'archived'
  | 'badgeConfigurations'
  | 'calculateBothStatements'
  | 'categoriesV2'
  | 'forecastId'
  | 'frequency'
  | 'id'
  | 'indexedWeight'
  | 'isForecasted'
  | 'isSimulationAddRecordEnabled'
  | 'isSimulationEnabled'
  | 'linkToPlanDocumentation'
  | 'manualWeight'
  | 'name'
  | 'planTemplateError'
  | 'totalVariableId'
  | 'useVariableInsteadOfTotal'
  | 'workflowId'
> & {
  rules: PlanRule[];
  categories: PlanRuleCategory[];
  planAssignements: Pick<PlanAssignment, 'effectiveAsOf' | 'effectiveUntil' | 'id' | 'planId' | 'userId'>[];
  highlightedKpis: Pick<HighlightedKpi, 'identifier' | 'maximumVariableId' | 'minimumVariableId' | 'variableId'>[];
};

export interface CreatePlanRequest extends Omit<Plan, 'frequency' | 'id'> {
  duplicateOf?: string;
  frequency?: PeriodFrequencyEnum;
}

export interface PlanCategory {
  name: string;
}

// Indexed by planId.
export type PlansMap = Record<string, Plan>;

export type PlanRuleCategoryMap = Record<string, PlanRuleCategory>;

type PlanRuleChartDetail = { name: string };

export const PlanRuleChartDetails: Record<PlanRuleChartEnumType, PlanRuleChartDetail> = {
  [PlanRuleChartEnumType.TARGET_ACHIEVEMENT]: {
    name: 'Target Achievement',
  },
  [PlanRuleChartEnumType.STATEMENT_METRIC]: {
    name: 'Metric',
  },
};
