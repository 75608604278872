import { lazy, memo } from 'react';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const CompaniesEditPage = lazy(() => import('./CompaniesEditPage'));
const CompaniesPage = lazy(() => import('./CompaniesPage'));

const CompaniesRoutes = memo(function CompaniesRoutes() {
  return (
    <RootRoutes>
      <Route
        path={routes.SUPERADMIN_COMPANY}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <AmaliaHead title="Superadmin | Company details" />
            <CompaniesEditPage />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.SUPERADMIN_COMPANIES}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <AmaliaHead title="Superadmin | Companies" />
            <CompaniesPage />
          </AbilityRouteProtector>
        }
      />
    </RootRoutes>
  );
});

export default CompaniesRoutes;
