import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlanRulesLayout } from '@amal-ia/compensation-definition/plans/components';
import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { RuleType } from '@amal-ia/compensation-definition/plans/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { type Adjustment, type Statement } from '@amal-ia/lib-types';
import { RuleAccordion } from '@amal-ia/lib-ui-business';

import { AdjustmentsDataGrid } from './adjustments-data-grid/AdjustmentsDataGrid';

interface StatementAdjustmentsListProps {
  readonly statement: Statement;
  readonly currencyRate: number;
  readonly currencySymbol: CurrencySymbolsEnum;
  readonly setSelectAdjustment: (adjustment: Adjustment) => void;
}

const StatementAdjustmentsList = memo(function StatementAdjustmentsList({
  statement,
  currencySymbol,
  currencyRate,
  setSelectAdjustment,
}: StatementAdjustmentsListProps) {
  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(false, 'ruleExpanded');

  const total = (statement.adjustments || []).reduce((acc, adjustment) => acc + adjustment.amount, 0);

  return (
    <PlanRulesLayout.Category>
      <RuleAccordion
        header={
          <RuleAccordion.Header
            isExpanded={isRuleExpanded}
            label={<FormattedMessage defaultMessage="Adjustments" />}
            machineName="adjustments"
            mainKpi={{
              value: { amount: total, currencyRate, currencySymbol },
              type: RuleType.PAYOUT,
            }}
            onToggleExpanded={toggleRuleExpanded}
          />
        }
      >
        <RuleLayout>
          <RuleLayout.Dataset>
            <AdjustmentsDataGrid
              adjustments={statement.adjustments}
              onSelectAdjustment={setSelectAdjustment}
            />
          </RuleLayout.Dataset>
        </RuleLayout>
      </RuleAccordion>
    </PlanRulesLayout.Category>
  );
});

export default StatementAdjustmentsList;
