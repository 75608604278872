import { css, type Theme } from '@emotion/react';
import { memo } from 'react';

import { type ChartSeries, ChartSeriesType } from '../chartSeries';

const barIconCss = (theme: Theme) => css`
  background-color: ${theme.ds.hues.blue[900]};
  border-radius: 4px;
  width: 16px;
  height: 16px;
`;

const lineIconCss = (theme: Theme) => css`
  background-color: ${theme.ds.colors.secondary[200]};
  border-radius: 16px;
  width: 8px;
  height: 8px;
`;

type LegendIconProps = {
  readonly series: ChartSeries;
};

export const LegendIcon = memo(function LegendIcon({ series }: LegendIconProps) {
  return series.type === ChartSeriesType.BAR ? (
    <div
      css={[
        barIconCss,
        {
          backgroundColor: series.color,
        },
      ]}
    />
  ) : (
    <div
      css={[
        lineIconCss,
        {
          backgroundColor: series.color,
        },
      ]}
    />
  );
});
