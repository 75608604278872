import { type RootState } from '@amal-ia/lib-types';

export const selectIsAppLoading = (state: RootState): boolean =>
  state.currencies.isLoading ||
  state.customObjectDefinitions.isLoading ||
  state.filters.isLoading ||
  state.planForecasts.isLoading ||
  state.flows.isLoading ||
  state.overwrites.isLoading ||
  state.payments.isLoading ||
  state.planAgreements.isLoading ||
  state.plans.isLoading ||
  state.rules.isLoading ||
  state.statements.isLoading ||
  state.teams.isLoading ||
  state.todos.isLoading ||
  state.users.isLoading ||
  state.variables.isLoading;
