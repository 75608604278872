import { css, type Theme } from '@emotion/react';
import { type SuggestionProps } from '@tiptap/suggestion';
import { type ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LabelDisplay, UserPrettyFormat } from '@amal-ia/data-capture/fields/components';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export type MentionListProps = {
  readonly items: Partial<UserContract>[];
  readonly command: SuggestionProps['command'];
};

export type ForwardedMentionListRef = {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
};

const itemsStyle = (theme: Theme) => css`
  padding: 6px;
  border: 1px solid ${theme.ds.colors.gray[100]};
  background: ${theme.ds.colors.gray[0]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  max-height: 270px;
  overflow: auto;
  position: relative;
  width: 100%;
`;

const itemStyle = (theme: Theme) => css`
  display: inline-flex;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border: 1px solid transparent;
  border-radius: ${theme.ds.borderRadiuses.squared};
  align-items: center;
  padding: 4px 12px;
  cursor: pointer;
  &:hover {
    background: ${theme.ds.colors.secondary[50]};
  }
`;

const itemSelected = (theme: Theme) => css`
  background: ${theme.ds.colors.secondary[50]};
`;

export const MentionList = forwardRef(function MentionList(
  props: MentionListProps,
  ref: ForwardedRef<ForwardedMentionListRef>,
) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const selectItem = (index: number) => {
    const item = props.items[index];
    props.command({ label: `${item.firstName} ${item.lastName}`, id: item.id });
  };

  const upHandler = () => {
    const idx = (selectedIndex + props.items.length - 1) % props.items.length;
    containerRef.current?.children[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setSelectedIndex(idx);
  };

  const downHandler = () => {
    const idx = (selectedIndex + 1) % props.items.length;
    containerRef.current?.children[idx].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setSelectedIndex(idx);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items.length]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));
  return (
    <div
      ref={containerRef}
      css={itemsStyle}
    >
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            key={item.id}
            css={[itemStyle, index === selectedIndex && itemSelected]}
            type="button"
            onClick={() => selectItem(index)}
          >
            <UserPrettyFormat
              display={LabelDisplay.INLINE}
              firstName={item.firstName}
              lastName={item.lastName}
              pictureURL={item.pictureURL}
            />
          </button>
        ))
      ) : (
        <div css={itemStyle}>
          <FormattedMessage defaultMessage="No results" />
        </div>
      )}
    </div>
  );
});
