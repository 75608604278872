import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { formatUserFullName, type WorkflowCondensedStep } from '@amal-ia/lib-types';
import { Avatar } from '@amal-ia/lib-ui';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

const useStepStyles = makeStyles<
  AmaliaThemeType,
  {
    compact: boolean;
    isLongLine: boolean;
    isLastStepComplete: boolean;
    index: number;
  }
>((theme) => ({
  stepTitle: {
    marginLeft: theme.spacing(1),
    borderRadius: '4px',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  checkedIcon: {
    color: theme.palette.success.main,
  },
  AccessTimeFilledIcon: {
    color: theme.palette.common.white,
    backgroundColor: (props) =>
      props.isLastStepComplete || props.index === 0 ? theme.palette.primary.light : theme.palette.grey[600],
    borderRadius: '100%',
  },
  stepDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#525252',
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarUser: {
    marginRight: theme.spacing(1),
  },
  numberOfStatementsLabel: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: ${({ theme }) => theme.ds.borderRadiuses.round};
  flex: none;
  position: relative;
  top: 6px;
`;

export const formatStepNameForDisplay = (stepName: string) => {
  const [first, second, ...rest] = stepName.split(', ');
  return [first, second, rest.length > 0 && `(+${rest.length})`].filter(Boolean).join(', ');
};

interface WorkflowStepperStepProps {
  readonly index: number;
  readonly workflowName: string;
  readonly step: WorkflowCondensedStep;
  readonly usersMap: UsersMap;
  readonly isLast: boolean;
  readonly isLastStepComplete: boolean;
  readonly compact: boolean;
}

export const WorkflowStepperStep = memo(function WorkflowStepperStep({
  index,
  workflowName,
  step,
  usersMap,
  isLast: _isLast,
  isLastStepComplete,
  compact,
}: WorkflowStepperStepProps) {
  const classes = useStepStyles({
    compact,
    isLongLine: step.completed,
    isLastStepComplete,
    index,
  });

  const { formatMessage } = useIntl();

  return (
    <Box display="flex">
      <Dot
        css={(theme) => css`
          background-color: ${step.completed
            ? theme.ds.colors.success[500]
            : isLastStepComplete || index === 0
              ? theme.ds.colors.warning[500]
              : theme.ds.colors.gray[500]};
        `}
      />

      <div className={classes.stepTitle}>
        <div
          aria-checked={step.completed}
          aria-label={formatMessage(
            { defaultMessage: 'Workflow {workflowName} - step #{stepNumber, number}' },
            { workflowName, stepNumber: index + 1 },
          )}
        >
          <Typography
            as="div"
            variant={Typography.Variant.BODY_SMALL_MEDIUM}
          >
            {formatStepNameForDisplay(step.stepName || '')}
          </Typography>
        </div>

        {!compact && (
          <div className={classes.stepDetails}>
            {!!step.userId && !!usersMap[step.userId] && (
              <div className={classes.userInfo}>
                <Avatar
                  className={classes.avatarUser}
                  user={usersMap[step.userId]}
                />
                <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
                  {formatUserFullName(usersMap[step.userId])}
                </Typography>
              </div>
            )}

            {!!step.date && (
              <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
                {moment(step.date).format('YYYY-MM-DD')}
              </Typography>
            )}

            {!!step.numberOfStatementsLabel && (
              <Typography
                as="div"
                className={classes.numberOfStatementsLabel}
                variant={Typography.Variant.BODY_XSMALL_REGULAR}
              >
                <FormattedMessage
                  defaultMessage="Statements on this step: {stepsCount}"
                  values={{ stepsCount: step.numberOfStatementsLabel }}
                />
              </Typography>
            )}
          </div>
        )}
      </div>
    </Box>
  );
});
