import { ListItem, ListItemAvatar, type ListItemAvatarProps, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { Avatar } from '../../../Avatar';
import { CheckboxFieldBase } from '../../../formElements/Checkbox/CheckboxField';
import { useUserSelectStyles } from '../common';

const UserListItemAvatar = withStyles(() => ({
  root: {
    minWidth: 0,
  },
}))((props: ListItemAvatarProps) => <ListItemAvatar {...props} />);

export interface UserListItemProps<TUser extends UserComputed = UserComputed> {
  /** User to display. */
  readonly user: TUser;
  /** Callback when user is selected. */
  readonly onChange: (user: TUser) => void;
  /** Selected user(s). If multiple, value is an array. */
  readonly value: TUser[] | null;
  /** Callback when user is selected ??? */
  readonly selectUser: (user: TUser) => void;
  /** If true, allow multiple selection. */
  readonly multiple?: boolean;
  /** If true, display avatars in the list. */
  readonly withAvatarsInList?: boolean;
}

const UserListItemBase = function UserListItem<TUser extends UserComputed = UserComputed>({
  user,
  onChange,
  value,
  selectUser,
  multiple,
  withAvatarsInList,
}: UserListItemProps<TUser>) {
  const classes = useUserSelectStyles({ multiple });

  const checked = useMemo(() => value?.some((elm) => elm.id === user.id), [value, user]);

  const { onChangeProxy, onSelectProxy } = useMemo(
    () => ({
      onChangeProxy: () => onChange(user),
      onSelectProxy: () => selectUser(user),
    }),
    [user, onChange, selectUser],
  );

  return (
    <ListItem
      button
      component="li"
      onClick={onChangeProxy}
    >
      {multiple ? (
        <div>
          <CheckboxFieldBase
            aria-labelledby={`selectUser_${user.id}`}
            className={classes.selectCheckbox}
            color="primary"
            size="small"
            value={checked}
            onChange={onSelectProxy}
          />
        </div>
      ) : null}
      <ListItemText
        id={`selectUser_${user.id}`}
        primary={`${user.firstName} ${user.lastName}`}
      />
      {withAvatarsInList ? (
        <UserListItemAvatar>
          <Avatar
            className={classes.avatarList}
            user={user}
          />
        </UserListItemAvatar>
      ) : null}
    </ListItem>
  );
};

export const UserListItem = memo(UserListItemBase) as typeof UserListItemBase;
