import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: `${theme.palette.grey['100']}`,
    marginTop: '0.5em',
  },
}));

type SearchModalListElementProps = ComponentPropsWithoutRef<'div'>;

export const SearchModalListElement = memo(function SearchModalListElement({
  children,
  ...props
}: SearchModalListElementProps) {
  const classes = useStyles();
  return (
    <div
      {...props}
      className={clsx(props.className, classes.listElement)}
    >
      {children}
    </div>
  );
});
