import { get, noop } from 'lodash';
import { cloneElement, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFunctionBlockStringForm } from '@amal-ia/amalia-lang/formula/form';
import { FormulaBuilderStringOperatorNoArgs, ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { isEnum } from '@amal-ia/ext/typescript';
import { Dropdown, Tooltip } from '@amal-ia/frontend/design-system/components';

import { useGetFormulaBuilderAttributeLabel } from '../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionOneArgGenericTag } from '../formula-condition-one-arg-generic-tag/FormulaConditionOneArgGenericTag';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';

import {
  formulaConditionTagFunctionStringMessages,
  valueOrAttributeValuesMessages,
} from './FormulaConditionTagFunctionString.messages';
import * as styles from './FormulaConditionTagFunctionString.styles';
import { FunctionStringPopover } from './function-string-popover/FunctionStringPopover';
import { operatorMessages, operatorNegativeMessages } from './function-string-popover/FunctionStringPopover.messages';
import { NEGATIVE_OPERATOR_ICON_MAPPING, OPERATOR_ICON_MAPPING } from './function-string-popover/operators.mappers';

export type FormulaConditionTagFunctionStringProps = {
  readonly condition: FormulaBuilderFunctionBlockStringForm;
  readonly path: string;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockStringForm>['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFunctionBlockStringForm>['onDelete'];
};

export const FormulaConditionTagFunctionString = memo(function FormulaConditionTagFunctionString({
  condition,
  path,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFunctionStringProps) {
  const { activeConditionId, errors: builderErrors } = useFormulaBuilderContext();
  const isActive = condition.id === activeConditionId;

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);
  const isInitialOpen = !!condition.isDraft && isActive;
  const { isOpen, setOpen, setOpenFalse } = useBoolState(isInitialOpen, 'open');

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.args[1].value`) as string | undefined,
        get(builderErrors, `${path}.args[1].caseSensitive`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );
  const getTooltipContent = () => {
    if (!condition.operator) {
      return <FormattedMessage {...formulaConditionTagFunctionStringMessages.CLICK_TO_EDIT_CONDITION} />;
    }

    if (isEnum(condition.operator, FormulaBuilderStringOperatorNoArgs)) {
      return (
        <FormattedMessage {...(condition.not ? operatorNegativeMessages : operatorMessages)[condition.operator]} />
      );
    }

    return (
      <FormattedMessage
        {...formulaConditionTagFunctionStringMessages.OPERATOR_VALUE}
        values={{
          operator: (
            <FormattedMessage {...(condition.not ? operatorNegativeMessages : operatorMessages)[condition.operator]} />
          ),
          value: condition.args[1] && (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[1].type]}
              values={{
                value:
                  condition.args[1].type === ValueOrAttributeType.VALUE
                    ? condition.args[1].value
                    : getFormulaBuilderAttributeLabel(condition.args[1]),
              }}
            />
          ),
        }}
      />
    );
  };

  const getTagLabel = () => {
    if (!condition.operator) {
      return propertyName;
    }

    if (isEnum(condition.operator, FormulaBuilderStringOperatorNoArgs)) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          {cloneElement((condition.not ? NEGATIVE_OPERATOR_ICON_MAPPING : OPERATOR_ICON_MAPPING)[condition.operator], {
            size: 16,
            // Need to add display flex here to prevent the icon from shifting to the right
            style: {
              display: 'flex',
            },
          })}
        </div>
      );
    }

    if (condition.args[1]) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          {cloneElement((condition.not ? NEGATIVE_OPERATOR_ICON_MAPPING : OPERATOR_ICON_MAPPING)[condition.operator], {
            size: 16,
          })}
          {condition.args[1].type === ValueOrAttributeType.VALUE ? (
            <FormattedMessage
              defaultMessage="“{value}”"
              values={{ value: condition.args[1].value }}
            />
          ) : (
            getFormulaBuilderAttributeLabel(condition.args[1])
          )}
        </div>
      );
    }

    return (
      <FormulaConditionOneArgGenericTag
        arg={condition.args[1]}
        propertyName={propertyName}
      />
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      content={
        <FunctionStringPopover
          condition={condition}
          onChange={onChange}
          onClose={setOpenFalse}
        />
      }
      onChangeIsOpen={setOpen}
    >
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip
            content={getTooltipContent()}
            title={
              propertyName ? (
                <FormattedMessage
                  {...formulaConditionTagFunctionStringMessages.FILTER_ON}
                  values={{ propertyName }}
                />
              ) : null
            }
          >
            <span>{getTagLabel()}</span>
          </Tooltip>
        }
        onDelete={onDelete}
      />
    </Dropdown>
  );
});
