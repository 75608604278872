import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { type IntlShape, useIntl } from 'react-intl';
import useAsyncEffect from 'use-async-effect';

import { useIsMounted } from '@amal-ia/ext/react/hooks';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { log } from '@amal-ia/frontend/kernel/logger';
import { CalculationsRepository, STATEMENT_DATASET_QUERY_KEYS } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type Calculation, type CalculationRequest, CalculationStatus, CalculationType } from '@amal-ia/lib-types';

const formatProgressValue = (
  calculation: Calculation | undefined,
  formatMessage: IntlShape['formatMessage'],
): string => {
  if (!calculation) {
    return '';
  }

  let progress = '';

  if (calculation.status === CalculationStatus.STOPPING) {
    progress = formatMessage({ defaultMessage: 'STOPPING', description: 'Calculation status' });
  } else if (calculation.status === CalculationStatus.PENDING) {
    progress = formatMessage({ defaultMessage: 'PENDING', description: 'Calculation status' });
  } else if (calculation.status === CalculationStatus.STARTED && calculation.calculated === 0) {
    progress = formatMessage({ defaultMessage: 'STARTED', description: 'Calculation status' });
  } else if (calculation.total) {
    progress = formatMessage(
      { defaultMessage: '{calculated, number} / {total, number}' },
      { calculated: calculation.calculated, total: calculation.total },
    );
  }

  return progress
    ? calculation.type === CalculationType.FORECAST
      ? formatMessage({ defaultMessage: 'Forecast {progress}' }, { progress })
      : progress
    : '';
};

const calculatedStepThreshold = 10;

/**
 * This function is used to avoid having too much re-render when the calculation is running, we only update the last calculation state
 * if something important has changed like the status, the error or the number of calculated changes is greater than a threshold.
 *
 */
const needLastCalculationStateRefresh = (
  memoizedCalculated: number,
  memoizedCalculation: Calculation | undefined,
  updatedCalculation: Calculation | undefined,
): boolean => {
  if (memoizedCalculation?.id !== updatedCalculation?.id) return true;
  if (memoizedCalculation?.status !== updatedCalculation?.status) return true;
  if (memoizedCalculation?.error !== updatedCalculation?.error) return true;

  return !!(
    updatedCalculation?.calculated && memoizedCalculated + calculatedStepThreshold < updatedCalculation?.calculated
  );
};

class CalculationPoller {
  private interval: any;

  private count = 0;

  public startPolling = (
    periodId: string,
    onFinish: () => any,
    onProgress: (calculation: Calculation) => any,
    onError: (e: Error) => any,
  ) => {
    // Reset polling to avoid having twice in parallel.
    this.stopPolling();

    log.info('Starting polling for calculations');

    this.interval = setInterval(() => {
      log.info(`Polled ${++this.count} times`);

      CalculationsRepository.getRunningCalculation(periodId)
        .then((calculation) => {
          // If we don't see the calculation in the response, it means that it's over.
          if (!calculation) {
            this.stopPolling();
            onFinish();
          } else {
            onProgress(calculation);
          }
        })
        .catch((e) => {
          this.stopPolling();
          onError(e);
        });
    }, 1000);
  };

  public stopPolling = () => {
    log.info('Reset polling');
    clearInterval(this.interval);
    this.interval = null;
    this.count = 0;
  };
}

export const useCalculation = (calculationRequest: CalculationRequest | null, postUpdate: () => Promise<void>) => {
  const { snackError } = useSnackbars();
  const queryClient = useQueryClient();
  const isMounted = useIsMounted();
  const ability = useAbilityContext();
  const [isCalculationRunning, setIsCalculationRunning] = useState<boolean>(false);
  const [lastCalculation, setLastCalculation] = useState<Calculation | undefined>(undefined);

  const fetchLastCalculationForPeriod = useCallback(async () => {
    if (calculationRequest) {
      const lastCalculationOfPeriod = await CalculationsRepository.getLastCalculationForPeriod(
        calculationRequest.periodId,
      );
      setLastCalculation(lastCalculationOfPeriod);
    }
  }, [setLastCalculation, calculationRequest]);

  // Instantiate a calculationPoller instance per hook.
  const calculationPoller = useMemo(() => new CalculationPoller(), []);

  // This functions contacts the API and sets loaders on start and on end fetching results.
  const pollCalculationStatus = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (calculationRequest && isMounted()) {
          let lastCalculationMemoized: Calculation | undefined;
          let lastCalculatedSynced = 0;

          setIsCalculationRunning(true);
          calculationPoller.startPolling(
            calculationRequest.periodId,
            () => {
              if (isMounted()) {
                setIsCalculationRunning(false);
              }
              resolve();
            },
            (calculation) => {
              // We only update the last calculation state if something important has changed to avoid having too much re-render.
              if (
                isMounted() &&
                needLastCalculationStateRefresh(lastCalculatedSynced, lastCalculationMemoized, calculation)
              ) {
                lastCalculatedSynced = calculation.calculated;
                setLastCalculation(calculation);
              }
              lastCalculationMemoized = calculation;
            },
            (e) => {
              if (isMounted()) {
                setIsCalculationRunning(false);
              }
              reject(e);
            },
          );
        }
      }),
    [calculationRequest, calculationPoller, setIsCalculationRunning, isMounted],
  );

  const launchCalculation = useCallback(async () => {
    if (calculationRequest) {
      try {
        setLastCalculation(undefined);

        const runningCalculation = await CalculationsRepository.calculateStatement(calculationRequest);
        setLastCalculation(runningCalculation);

        await pollCalculationStatus();

        // Fetch the last calculation, this way we'll see if it's in error.
        await fetchLastCalculationForPeriod();

        if (postUpdate) {
          await postUpdate();
        }
        await queryClient.invalidateQueries({
          queryKey: [STATEMENT_DATASET_QUERY_KEYS.STATEMENT_DATASET],
        });
      } catch (e) {
        snackError(e.message);
      }
    }
  }, [calculationRequest, pollCalculationStatus, fetchLastCalculationForPeriod, postUpdate, queryClient, snackError]);

  const stopCalculation = useCallback(async () => {
    if (lastCalculation) {
      try {
        await CalculationsRepository.stopCalculation(lastCalculation);
      } catch (e) {
        snackError(e.message);
      }
    }
  }, [lastCalculation, snackError]);

  useAsyncEffect(
    async () => {
      // When the screen loads, fetch API to see if there is a calculation running.
      // Only admin have access to this endpoint.
      if (calculationRequest && ability.can(ActionsEnum.calculate, SubjectsEnum.Statement)) {
        const isRunning = await CalculationsRepository.getRunningCalculation(calculationRequest.periodId);

        // If there is a calculation running, poll on it until it's finished.
        if (isRunning) {
          await pollCalculationStatus().catch((e) => {
            snackError(e.message);
          });
        }

        // After polling resolve, or just on arriving on the page if there were no polling,
        // go fetch the latest calculation. It'll allows us to show the last calculation details,
        // like status and errors.
        await fetchLastCalculationForPeriod();
      }
    },
    () => {
      // On unmounting component, clear currently running intervals if any.
      calculationPoller.stopPolling();
      setLastCalculation(undefined);
      setIsCalculationRunning(false);
    },
    // Performing a deep compare, or else calculation restarts on a statement detail.
    [
      calculationRequest?.userIds,
      calculationRequest?.periodId,
      calculationRequest?.planId,
      calculationRequest?.teamId,
      calculationRequest?.type,
    ],
  );

  const { formatMessage } = useIntl();

  const calculationProgress = useMemo(
    () => formatProgressValue(lastCalculation, formatMessage),
    [lastCalculation, formatMessage],
  );

  return {
    launchCalculation,
    calculationProgress,
    lastCalculation,
    isCalculationRunning,
    stopCalculation,
  };
};
