import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useGetFormulaBuilderAttributeLabel } from '@amal-ia/amalia-lang/formula/components';
import { type ValueOrAttributeForm } from '@amal-ia/amalia-lang/formula/form';
import { ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';

import { valueOrAttributeValuesMessages } from '../formula-condition-tag-function-string/FormulaConditionTagFunctionString.messages';

import { formulaConditionOneArgGenericTagMessages } from './FormulaConditionOneArgGenericTag.messages';

export type FormulaConditionOneArgGenericTagProps = {
  readonly propertyName: string;
  readonly arg?: ValueOrAttributeForm;
};

export const FormulaConditionOneArgGenericTag = memo(function FormulaConditionOneArgGenericTag({
  propertyName,
  arg,
}: FormulaConditionOneArgGenericTagProps) {
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  return (
    <FormattedMessage
      {...formulaConditionOneArgGenericTagMessages.TAG_LABEL}
      values={{
        propertyName,
        hasValue:
          !!arg && (arg.type === ValueOrAttributeType.VALUE ? !!arg.value : !!getFormulaBuilderAttributeLabel(arg)),
        value: !!arg && (
          <FormattedMessage
            {...valueOrAttributeValuesMessages[arg.type]}
            values={{
              value: arg.type === ValueOrAttributeType.VALUE ? arg.value : getFormulaBuilderAttributeLabel(arg),
            }}
          />
        ),
      }}
    />
  );
});
