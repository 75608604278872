import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { WorkflowConditionType } from '@amal-ia/lib-types';
import { UserRoleLabels } from '@amal-ia/tenants/users/roles/components';
import { type UserRole } from '@amal-ia/tenants/users/shared/types';

export const useWorkflowStepName = () => {
  const { formatMessage } = useIntl();

  const formatStepName = useCallback(
    (stepName?: string) =>
      (stepName || '')
        .split(',')
        .map((condition) => {
          if (!condition) {
            return '';
          }

          const [conditionType, payload] = condition.split(':');
          switch (conditionType) {
            case WorkflowConditionType.ROLE:
              return formatMessage(
                { defaultMessage: 'Any {role}' },
                { role: formatMessage(UserRoleLabels[payload as UserRole]) },
              );
            case WorkflowConditionType.OWNER:
              return formatMessage({ defaultMessage: 'Statement owner' });
            case WorkflowConditionType.USER: {
              return payload;
            }
            default:
              return null;
          }
        })
        .join(', '),
    [formatMessage],
  );

  return { formatStepName };
};
