import { memo, type ReactNode, useMemo } from 'react';

export enum IconTransformationsTypes {
  ROTATE_90 = 'ROTATE_90',
  SIZE = 'SIZE',
  COLOR = 'COLOR',
}

export const IconTransformations: Record<IconTransformationsTypes, (params?: any) => object> = {
  [IconTransformationsTypes.ROTATE_90]: () => ({
    transform: 'rotate(90deg)',
    transformOrigin: '50% 50%',
  }),
  [IconTransformationsTypes.SIZE]: (params: any) => ({
    fontSize: `${params || 1}em`,
  }),
  [IconTransformationsTypes.COLOR]: (params: any) => ({
    color: params || 'black',
  }),
};

interface TransformedIconProps {
  readonly icon: ReactNode;
  readonly transformations: object[];
  readonly size?: number;
}

export const TransformedIcon = memo(function TransformedIcon({ icon, transformations, size }: TransformedIconProps) {
  const baseStyle = useMemo(
    () => ({
      height: size || 25,
      width: size || 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [size],
  );

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...transformations.reduce((acc, childStyle) => ({
        ...acc,
        ...childStyle,
      })),
    }),
    [baseStyle, transformations],
  );

  return <span style={style}>{icon}</span>;
});

export default TransformedIcon;
