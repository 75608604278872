import { ClassNames, css, useTheme } from '@emotion/react';
import { IconHelp } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  type ReactElement,
  type JSXElementConstructor,
  useCallback,
  cloneElement,
  Fragment,
} from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { Link, type LinkProps } from '@amal-ia/ext/react-router-dom';

import { type IconButtonProps } from '../../../general/icon-button/IconButton';
import { type TablerIconElement } from '../../../general/icons/types';
import { TextOverflow } from '../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../general/typography/Typography';
import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { Tooltip, type TooltipProps } from '../../../overlays/tooltip/Tooltip';
import { SideMenuItemAction } from '../side-menu-item-action/SideMenuItemAction';
import { useSideMenuContext } from '../SideMenu.context';
import { type SideMenuItemValue } from '../SideMenu.types';

import { SideMenuItemContext, type SideMenuItemContextValue } from './SideMenuItem.context';
import * as styles from './SideMenuItem.styles';
import { sideMenuItemTestIds } from './SideMenuItem.testIds';

export type SideMenuItemProps = {
  /** Item value to determine if the item is selected (compared to value passed to `<SideMenu>`). */
  readonly value: SideMenuItemValue;
  /** Item label. */
  readonly children: ReactNode;
  /** If defined, the item will be rendered as a link. */
  readonly to?: LinkProps['to'];
  /** The item is disabled. */
  readonly disabled?: boolean;
  /** Item help. */
  readonly tooltip?: TooltipProps['content'];
  /** Item icon. */
  readonly icon?: TablerIconElement;
  /** Item optional action (right side). */
  readonly action?: ReactElement<IconButtonProps, JSXElementConstructor<IconButtonProps>>;
};

const SideMenuItemBase = function SideMenuItem({
  value,
  children,
  to = undefined,
  disabled = false,
  tooltip = undefined,
  icon = undefined,
  action = undefined,
}: SideMenuItemProps) {
  const theme = useTheme();
  const { value: selectedValue, onChange } = useSideMenuContext();

  const isActive = value === selectedValue;
  const handleClick = useCallback(() => onChange?.(value), [onChange, value]);

  const contextValue = useShallowObjectMemo<SideMenuItemContextValue>({ disabled });

  const className = clsx({
    [styles.IS_ACTIVE_CLASSNAME]: isActive,
    [styles.HAS_ACTION_CLASSNAME]: !!action,
  });

  const clonedIconWithTheme = icon ? (
    <ClassNames>
      {({ css }) =>
        cloneElement(icon, {
          width: 16,
          height: 16,
          color: disabled || !icon.props.color ? 'currentColor' : icon.props.color,
          className: css`
            flex: none;
          `,
        })
      }
    </ClassNames>
  ) : null;

  const content = (
    <Fragment>
      {clonedIconWithTheme}

      <Typography
        as={TextOverflow}
        variant={isActive ? Typography.Variant.BODY_BASE_MEDIUM : Typography.Variant.BODY_BASE_REGULAR}
      >
        {children}
      </Typography>

      {!!tooltip && (
        <Tooltip content={tooltip}>
          <IconHelp
            color={disabled ? 'currentColor' : theme.ds.colors.gray[800]}
            size={14}
            css={css`
              flex: none;
            `}
          />
        </Tooltip>
      )}
    </Fragment>
  );

  return (
    <SideMenuItemContext.Provider value={contextValue}>
      <li css={styles.listElement}>
        {to && !disabled ? (
          <Link
            className={className}
            css={styles.sideMenuItem}
            data-testid={sideMenuItemTestIds.item(value)}
            to={to}
          >
            {content}
          </Link>
        ) : (
          <UnstyledButton
            className={className}
            css={styles.sideMenuItem}
            data-testid={sideMenuItemTestIds.item(value)}
            disabled={disabled}
            onClick={handleClick}
          >
            {content}
          </UnstyledButton>
        )}

        {!!action && <div css={styles.actionContainer}>{action}</div>}
      </li>
    </SideMenuItemContext.Provider>
  );
};

export const SideMenuItem = Object.assign(memo(SideMenuItemBase), {
  Action: SideMenuItemAction,
});
