import { defineMessages } from 'react-intl';

import {
  DateTransform,
  FormulaBuilderDateOperatorNoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorTwoArgs,
  ValueOrAttributeType,
} from '@amal-ia/amalia-lang/formula/shared/types';

export const formulaConditionTagFunctionDateMessages = defineMessages({
  FILTER_ON: {
    defaultMessage: 'Filter on “{propertyName}”',
  },

  SINGLE_OPERATOR: {
    defaultMessage: '{operator}: {value}',
  },

  DOUBLE_OPERATOR: {
    defaultMessage: '{operator}: {value1} and {value2}',
  },

  TAG_LABEL: {
    defaultMessage: '{propertyName}{hasValue, select, true {: {value}} other {}}',
  },

  CLICK_TO_EDIT_CONDITION: {
    defaultMessage: 'Click to edit condition',
  },
} as const);

export const operatorTooltipMessage = defineMessages<
  FormulaBuilderDateOperatorNoArgs | FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs
>({
  [FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE]: {
    defaultMessage: 'Year to date',
  },
  [FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE]: {
    defaultMessage: 'Quarter to date',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_MONTH]: {
    defaultMessage: 'In current month',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_PERIOD]: {
    defaultMessage: 'In current period',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
  [FormulaBuilderDateOperatorOneArg.DATE_AFTER]: {
    defaultMessage: 'After',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_AFTER]: {
    defaultMessage: 'Strictly after',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_BEFORE]: {
    defaultMessage: 'Strictly before',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_BEFORE]: {
    defaultMessage: 'Before',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_EQUALS]: {
    defaultMessage: 'Exact',
  },

  [FormulaBuilderDateOperatorTwoArgs.DATE_BETWEEN]: {
    defaultMessage: 'Between',
  },
} as const);

export const operatorTag = defineMessages<
  FormulaBuilderDateOperatorNoArgs | FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs
>({
  [FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE]: {
    defaultMessage: 'Year to date',
  },
  [FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE]: {
    defaultMessage: 'Quarter to date',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_MONTH]: {
    defaultMessage: 'In month',
  },
  [FormulaBuilderDateOperatorNoArgs.IN_PERIOD]: {
    defaultMessage: 'In current period',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
  [FormulaBuilderDateOperatorOneArg.DATE_AFTER]: {
    defaultMessage: '{propertyName}{hasValue, select, true { >= {value}{dateTransform}} other {}}',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_AFTER]: {
    defaultMessage: '{propertyName}{hasValue, select, true { > {value}{dateTransform}} other {}}',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_BEFORE]: {
    defaultMessage: '{propertyName}{hasValue, select, true { < {value}{dateTransform}} other {}}',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_BEFORE]: {
    defaultMessage: '{propertyName}{hasValue, select, true { <= {value}{dateTransform}} other {}}',
  },

  [FormulaBuilderDateOperatorOneArg.DATE_EQUALS]: {
    defaultMessage: '{propertyName}{hasValue, select, true { = {value}{dateTransform}} other {}}',
  },

  [FormulaBuilderDateOperatorTwoArgs.DATE_BETWEEN]: {
    defaultMessage:
      '{hasValue, select, true {{value}{dateTransform}} other {}} <= {propertyName} <= {hasValue2, select, true {{value2}{dateTransform2}} other {}}',
  },
} as const);

export const transformTags = defineMessages<DateTransform>({
  [DateTransform.DAYS_AGO]: {
    defaultMessage: '{hasTransform, select, true { {operator} {value, plural, one {# day} other {# days}}} other {}}',
  },
  [DateTransform.MONTHS_AGO]: {
    defaultMessage:
      '{hasTransform, select, true { {operator} {value, plural, one {# month} other {# months}}} other {}} ',
  },
});

export const valueOrAttributeValuesMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: '“{value}”',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: '{value}',
  },
} as const);
