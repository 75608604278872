import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amal-ia/frontend/design-system/components';
import { COMMON_MESSAGES } from '@amal-ia/lib-ui';

import { messages } from './RemoveConditionGroupModal.messages';

type RemoveConditionGroupModalProps = {
  readonly isOpen: ModalProps['isOpen'];
  readonly onClose: ModalProps['onClose'];
  readonly onGroupDeletion: () => void;
};

export const RemoveConditionGroupModal = memo(function RemoveConditionGroupModal({
  isOpen,
  onClose,
  onGroupDeletion,
}: RemoveConditionGroupModalProps) {
  return (
    <Modal
      isDismissable
      isOpen={isOpen}
      variant={Modal.Variant.DANGER}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage {...messages.REMOVE_CONDITION_GROUP_MODAL_TITLE} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage {...messages.REMOVE_CONDITION_GROUP_MODAL_CONTENT} />
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />
        <Modal.MainAction onClick={onGroupDeletion}>
          <FormattedMessage {...COMMON_MESSAGES.DELETE} />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
