import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type Period, RelativePeriodKeyword } from '@amal-ia/compensation-definition/periods/types';
import { type SelectOptionGroup, type SelectDropdownOption } from '@amal-ia/frontend/design-system/components';
import { RELATIVE_PERIOD_KEYWORD_LABELS } from '@amal-ia/lib-ui';

import { type PeriodOption } from './PeriodSelect.types';

type UsePeriodOptionsOptions = {
  overridesPeriodOptions?: SelectDropdownOption<string>[];
  periodList: Period[];
};

export const usePeriodOptions = ({
  overridesPeriodOptions,
  periodList,
}: UsePeriodOptionsOptions): (PeriodOption | SelectOptionGroup<PeriodOption>)[] => {
  const { formatMessage } = useIntl();

  return useMemo<(PeriodOption | SelectOptionGroup<PeriodOption>)[]>(
    () => [
      {
        value: 'None', // FIXME this should be a constant, it's used in the backend as well.
        label: formatMessage({ defaultMessage: 'None' }),
      },
      {
        label: formatMessage({ defaultMessage: 'Relative periods' }),
        options: Object.keys(RelativePeriodKeyword).map((keyword: string) => ({
          value: keyword,
          label: formatMessage(RELATIVE_PERIOD_KEYWORD_LABELS[keyword as RelativePeriodKeyword]),
          componentWhenMultiple: 'RADIO',
        })),
      },
      {
        label: formatMessage({ defaultMessage: 'Custom periods' }),
        options:
          overridesPeriodOptions ||
          periodList.map((period) => ({
            value: period.id,
            label: period.name,
          })),
      },
    ],
    [periodList, overridesPeriodOptions, formatMessage],
  );
};
