import styled from '@emotion/styled';

import { ModalSize } from '../Modal.types';

export const ModalActions = styled.div`
  flex: none;

  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;

  transition: ${({ theme }) => theme.ds.transitions.default('padding')};

  border-top: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};

  .${ModalSize.SMALL} & {
    padding: 16px 24px;
  }

  .${ModalSize.MEDIUM} & {
    padding: 16px 32px;
  }

  .${ModalSize.LARGE} & {
    padding: 18px 40px;
  }
`;
