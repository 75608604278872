import { type ReactNode, memo, type JSXElementConstructor, type ReactElement, cloneElement } from 'react';

import { PlanRuleCategoryBadge } from '@amal-ia/compensation-definition/plans/rules/categories/components';
import { type PlanCategory } from '@amal-ia/compensation-definition/plans/types';
import { type GrabHandleProps, Typography } from '@amal-ia/frontend/design-system/components';

import * as styles from './RuleCategoryHeader.styles';

export type RuleCategoryHeaderProps = {
  /** Category to display. */
  readonly category: PlanCategory;
  /** Optional total to display. */
  readonly total?: ReactNode;
  /** Optional grab handle if in drag and drop context. */
  readonly grabHandle?: ReactElement<GrabHandleProps, JSXElementConstructor<GrabHandleProps>>;
  /** Optional actions. */
  readonly actions?: ReactNode;
};

export const RuleCategoryHeader = memo(function RuleCategoryHeader({
  category,
  total,
  grabHandle,
  actions,
}: RuleCategoryHeaderProps) {
  return (
    <div css={styles.ruleCategoryHeader}>
      <div css={styles.leftContainer}>
        {!!grabHandle && cloneElement(grabHandle, { size: 18 })}

        <PlanRuleCategoryBadge
          withShadow
          category={category}
          size={PlanRuleCategoryBadge.Size.LARGE}
        />
      </div>

      {!!(total || actions) && (
        <div css={[styles.rightContainer, !!total && styles.totalContainer]}>
          {!!total && (
            <Typography
              css={styles.total}
              variant={Typography.Variant.BODY_LARGE_MEDIUM}
            >
              {total}
            </Typography>
          )}

          {actions}
        </div>
      )}
    </div>
  );
});
