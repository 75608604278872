import { InfoOutlined } from '@mui/icons-material';
import { memo, type ReactElement } from 'react';

import { Tooltip } from './Tooltip/Tooltip';
import { Text, TextType } from './typography';

interface InfoWithTooltipProps {
  readonly children: ReactElement | string;
}

const ICON_STYLE = {
  display: 'inline-block',
  marginLeft: '0.5em',
  verticalAlign: 'bottom',
  fontSize: '1rem',
};

export const InfoWithTooltip = memo(function InfoWithTooltip({ children }: InfoWithTooltipProps) {
  return (
    <Text
      style={{ display: 'inline-flex' }}
      type={TextType.INPUT_NAME}
    >
      <Tooltip
        arrow={false}
        placement="top"
        title={<Text type={TextType.TOOLTIP}>{children}</Text>}
      >
        <InfoOutlined style={ICON_STYLE} />
      </Tooltip>
    </Text>
  );
});
