import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route, useLocation } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const StatementDetail = lazy(() => import('./detail/StatementDetail'));
const Statements = lazy(() => import('./list/Statements'));
const StatementNoDetail = lazy(() => import('./noDetail/StatementNoDetail'));

export const StatementsRoutes = memo(function StatementsRoutes() {
  const { formatMessage } = useIntl();
  const isForecast = useLocation().pathname.includes('forecasts');

  return (
    <Layout currentPage={isForecast ? 'forecasts' : 'statements'}>
      <RootRoutes>
        <Route
          path={routes.STATEMENT_COMMENT}
          element={
            <AbilityRouteProtector
              can={(ability) => ability.can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement)}
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE_ACTION_EXTERNAL_ID}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE_ACTION}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT_RULE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statement' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECAST}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecast' })} />
              <StatementDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS_BY_USER_PERIOD}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <StatementNoDetail />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECASTS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.FORECASTS_BY_DATE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Forecasts' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.STATEMENTS_BY_DATE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Statement)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Statements' })} />
              <Statements />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
