import { css } from '@emotion/react';
import { type ReactNode, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';

export type StatementSummaryLayoutHistogramProps = {
  /** Is the widget mark as disabled. */
  readonly disabled?: boolean;
  /** Histogram component. */
  readonly children: ReactNode;
};

export const StatementSummaryLayoutHistogram = memo(function StatementSummaryLayoutHistogram({
  disabled,
  children,
}: StatementSummaryLayoutHistogramProps) {
  return (
    <div
      css={css`
        padding: 12px 8px 8px 24px;
        display: flex;
        flex-direction: column;
        gap: 6px;
      `}
    >
      <Typography
        variant={Typography.Variant.BODY_XSMALL_BOLD}
        css={(theme) => css`
          color: ${disabled ? theme.ds.colors.gray[300] : theme.ds.colors.gray[800]};
        `}
      >
        <FormattedMessage defaultMessage="Year to date performance" />
      </Typography>

      <div>{children}</div>
    </div>
  );
});
