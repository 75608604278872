import { type Theme, css } from '@emotion/react';

export const dot = (theme: Theme) => css`
  flex: none;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${theme.ds.colors.primary[500]};
`;

export const stepsContainer = css`
  display: flex;
  align-items: center;
`;

export const step = css`
  display: flex;
  align-items: center;
`;

export const icon = css`
  margin: 0 8px;
  transform: rotate(90deg);
`;
