import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { type Plan, type PlansMap } from '@amal-ia/compensation-definition/plans/types';
import { type RootState } from '@amal-ia/lib-types';

export const selectCurrentPlan = (state: RootState): Plan | null => state.plans.currentPlan;

export const selectPlansMap = (state: RootState): PlansMap => state.plans.map;
export const selectPlansMapIsFullyLoaded = (state: RootState): boolean => state.plans.isMapFullyLoaded;

export const selectPlans = createSelector([selectPlansMap], (plansMap: PlansMap) => Object.values(plansMap));

export const selectPlansSortedByName = createSelector([selectPlans], (plans: Plan[]) =>
  [...plans].sort((a, b) => a.name.localeCompare(b.name)),
);

export const selectPeriodPlans = (state: RootState): Plan[] => sortBy(state.plans.periodPlans as Plan[], 'name');

export const selectPlansIsLoading = (state: RootState): boolean => !!state.plans.isLoading;
