import { createContext } from 'react';

import { type Statement, type StatementDataset } from '@amal-ia/lib-types';

export type TracingContextInterface = {
  statementDatasets: Record<string, StatementDataset>;
  statementResults: Statement['results'];
};

export const TracingContext = createContext<TracingContextInterface>({} as TracingContextInterface);
