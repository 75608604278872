import {
  Delete as DeleteIcon,
  DeleteOutline as DeleteOutlined,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Group as GroupIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { IconSquareMinus } from '@tabler/icons-react';
import { capitalize } from 'lodash';
import { type ReactNode } from 'react';

import { type FormulaKeywordDisplayDetails } from '@amal-ia/amalia-lang/formula/keywords';
import { quotasObjectLabel } from '@amal-ia/amalia-lang/tokens/components';
import { type QuotaObjectsEnum, type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { type Filter, type Relationship, type RuleType } from '@amal-ia/compensation-definition/plans/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';
import {
  IconCardTextOutline,
  IconFileOutline,
  IconFilterOutline,
  IconFormatListBulletedSquare,
  IconFormatText,
  IconFunction,
  IconIFrameVariableOutline,
  IconLinkVariant,
  IconTableArrowUp,
  IconTargetAccount,
} from '@amal-ia/frontend/ui-icons';
import { type DesignerObjectDetails, TokenType, DesignerObjectsActions, type TracingTypes } from '@amal-ia/lib-types';
import { RULE_TYPES_CONF_LABELS } from '@amal-ia/lib-ui';

import { type AmaliaFunctionWithId } from '../components/designer/drawer/drawer.types';

/**
 * @deprecated
 * We should try and remove any coupling to this configuration in the refactored designer.
 * Looks great, but it is hard to maintain and introduce hasty / unnecessary abstraction & coupling.
 */
export const designerObjectsDetails: Record<TokenType, DesignerObjectDetails> = {
  [TokenType.PLAN]: {
    key: 'PLAN',
    icon: IconFileOutline,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.plan,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.ASSIGN,
      DesignerObjectsActions.DUPLICATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.DELETE,
    ],
  },
  [TokenType.RULE]: {
    key: TokenType.RULE,
    icon: IconCardTextOutline,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.rule,
    fieldsToSearchIn: [
      'name',
      'machineName',
      'filterMachineName',
      'formula',
      'filter.name',
      'filter.machineName',
      'filter.object.name',
      'filter.object.machineName',
    ],
    group: {
      field: 'type',
      format: (value) => RULE_TYPES_CONF_LABELS[value as RuleType],
    },
    sortField: 'machineName',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.DELETE_ASSIGNMENT,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.FILTER]: {
    key: TokenType.FILTER,
    icon: IconFilterOutline,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.filter,
    fieldsToSearchIn: ['name', 'machineName', 'condition', 'object.name', 'object.machineName'],
    group: {
      field: (f) => f.object?.name || f.virtualObjectMachineName,
    },
    sortField: 'machineName',
    onCopy: (object: Filter) => `filter.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.VARIABLE]: {
    key: TokenType.VARIABLE,
    icon: IconIFrameVariableOutline,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.statement,
    fieldsToSearchIn: ['name', 'machineName', 'formula'],
    group: {
      field: (variable) => variable?.planId || 'Global',
    },
    sortField: 'machineName',
    onCopy: (object: Variable) => `statement.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.FIELD]: {
    key: TokenType.FIELD,
    icon: IconTableArrowUp,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    fieldsToSearchIn: ['name', 'machineName', 'formula', 'object.name', 'object.machineName'],
    group: {
      field: 'object.name',
    },
    sortField: 'machineName',
    onCopy: (object: Variable) => `${object.object.machineName}.${object.machineName}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.EDIT_ACHIEVED,
      DesignerObjectsActions.FORECAST,
      DesignerObjectsActions.EDIT_DESCRIPTION,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
  },
  [TokenType.PROPERTY]: {
    key: TokenType.PROPERTY,
    icon: IconFormatListBulletedSquare,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
  [TokenType.VIRTUAL_PROPERTY]: {
    key: TokenType.VIRTUAL_PROPERTY,
    icon: IconFormatListBulletedSquare,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
  [TokenType.LINK]: {
    key: TokenType.LINK,
    icon: IconLinkVariant,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.link,
    fieldsToSearchIn: [
      'name',
      'label',
      'fromDefinitionMachineName',
      'toDefinitionMachineName',
      'fromDefinitionField',
      'toDefinitionField',
      'condition',
    ],
    sortField: 'name',
    onCopy: (object: Relationship) => `${object.fromDefinitionMachineName}.${object.name}`,
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
    group: {
      field: 'fromDefinitionMachineName',
    },
  },
  [TokenType.QUOTA]: {
    key: TokenType.QUOTA,
    icon: IconTargetAccount,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.quota,
    fieldsToSearchIn: ['name', 'machineName'],
    sortField: 'machineName',
    possibleActions: [
      DesignerObjectsActions.CREATE,
      DesignerObjectsActions.EDIT,
      DesignerObjectsActions.REMOVE_FROM_RULE,
      DesignerObjectsActions.DELETE,
      DesignerObjectsActions.DUPLICATE,
    ],
    onCopy: (object: Variable) => `${object.type}.${object.machineName}`,
    group: {
      field: 'type',
      format: (value: QuotaObjectsEnum) => quotasObjectLabel[value] || value,
    },
  },
  [TokenType.FUNCTION]: {
    key: TokenType.FUNCTION,
    icon: IconFunction,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.functionOverlay,
    textColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.function,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    onCopy: (object: AmaliaFunctionWithId) => `${object.name}`,
    getDescription: (object: AmaliaFunctionWithId) => `${object.description}`,
    possibleActions: [DesignerObjectsActions.OPEN],
    group: {
      field: 'category',
    },
  },
  [TokenType.KEYWORD]: {
    key: TokenType.KEYWORD,
    icon: IconFormatText,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.keyword,
    fieldsToSearchIn: ['name'],
    sortField: 'name',
    onCopy: (object: FormulaKeywordDisplayDetails) => `${object.formula}`,
    getDescription: (object: FormulaKeywordDisplayDetails) => object.description,
    possibleActions: [DesignerObjectsActions.OPEN],
    group: {
      field: (object: FormulaKeywordDisplayDetails) => object.id.split('.')[0],
      format: (value: string) => capitalize(value),
    },
  },
  // TEMPORARY
  [TokenType.OBJECT]: {
    key: TokenType.OBJECT,
    icon: IconFormatListBulletedSquare,
    color: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
    fieldsToSearchIn: ['name', 'definition.name', '$comment', 'description'],
    sortField: 'name',
    group: {
      field: 'definition.name',
    },
    onCopy: (object: TracingTypes.Property) => `${object.definition.machineName}.${object.id}`,
    possibleActions: [],
  },
};

export const designerObjectsActionsDetails: Record<
  DesignerObjectsActions,
  {
    icon: (classes: { customIcon?: string }) => ReactNode;
    label: string;
  }
> = {
  [DesignerObjectsActions.ASSIGN]: {
    label: 'Assign',
    icon: () => <GroupIcon />,
  },
  [DesignerObjectsActions.DUPLICATE]: {
    label: 'Duplicate',
    icon: () => <FileCopyIcon />,
  },
  [DesignerObjectsActions.EDIT]: {
    label: 'Edit',
    icon: () => <EditIcon />,
  },
  [DesignerObjectsActions.EDIT_DESCRIPTION]: {
    label: 'Edit description',
    icon: (classes) => <IconCardTextOutline className={classes?.customIcon} />,
  },
  [DesignerObjectsActions.DELETE]: {
    label: 'Delete',
    icon: () => <DeleteIcon />,
  },
  [DesignerObjectsActions.OPEN]: {
    label: 'Open',
    icon: () => <VisibilityIcon />,
  },
  [DesignerObjectsActions.CREATE]: {
    label: 'Create',
    icon: () => null,
  },
  [DesignerObjectsActions.DELETE_ASSIGNMENT]: {
    label: 'Delete assignment',
    icon: () => <DeleteOutlined />,
  },
  [DesignerObjectsActions.FORECAST]: {
    label: 'Forecast',
    icon: () => <EditIcon />,
  },
  [DesignerObjectsActions.EDIT_ACHIEVED]: {
    label: 'Achieved',
    icon: () => <EditIcon />,
  },
  [DesignerObjectsActions.REMOVE_FROM_RULE]: {
    label: 'Remove from rule',
    icon: () => <IconSquareMinus />,
  },
};
