import { Fragment, memo, useCallback, useEffect } from 'react';

import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { type ComputedPlanRuleFieldsToDisplay, type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type ComputedRule, type DatasetRow } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';

import { DatasetRecordsDataGrid } from './dataset-records-data-grid/DatasetRecordsDataGrid';
import RuleResultAccessoryResults from './RuleResultAccessoryResults';
import { StatementRuleDatasetMenu } from './statement-rule-dataset-menu/StatementRuleDatasetMenu';
import { useDatasets } from './statement-rule-dataset-menu/useDatasets';

interface RuleResultProps {
  readonly computedRule: ComputedRule;
  readonly ruleDefinition: PlanRule;
  readonly setCurrentTracingData?: any;
  readonly globalSearchValue?: string;
}

const RuleResult = memo(function RuleResult({
  computedRule,
  ruleDefinition,
  setCurrentTracingData,
  globalSearchValue,
}: RuleResultProps) {
  const ability = useAbilityContext();
  const canViewPlan = ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {}));

  // =============== DEBUG ==============
  const statement = useStatementDetailContext();

  const { datasetsWithFields, selectedDataset, setSelectedDataset } = useDatasets(statement, ruleDefinition);

  /**
   * When the datasets list change, we select the first dataset.
   */
  useEffect(() => {
    const firstDataset = datasetsWithFields[0];
    setSelectedDataset(firstDataset);
  }, [datasetsWithFields, setSelectedDataset]);

  const enrichTracingDataWithRule = useCallback(
    (tracingData: { datasetRow: DatasetRow; fields: ComputedPlanRuleFieldsToDisplay[] }) => {
      setCurrentTracingData({
        ...tracingData,
        rule: computedRule,
      });
    },
    [setCurrentTracingData, computedRule],
  );

  return (
    <Fragment>
      {computedRule ? <RuleResultAccessoryResults ruleDefinition={ruleDefinition} /> : null}

      {!!selectedDataset && !!selectedDataset.fields && (
        <RuleLayout.Dataset>
          <DatasetRecordsDataGrid
            dataset={selectedDataset}
            fields={selectedDataset.fields}
            globalSearchValue={globalSearchValue}
            isForecasted={statement.isForecastedView}
            rule={ruleDefinition}
            statement={statement}
            datasetMenu={
              !!((canViewPlan && datasetsWithFields?.length > 0) || datasetsWithFields?.length > 1) && (
                <StatementRuleDatasetMenu
                  computedStatementDefinitions={statement.results.definitions}
                  datasets={datasetsWithFields}
                  selectDatasetToDisplay={setSelectedDataset}
                  selectedDataset={selectedDataset}
                />
              )
            }
            onSetCurrentTracingData={enrichTracingDataWithRule}
          />
        </RuleLayout.Dataset>
      )}
    </Fragment>
  );
});

export default RuleResult;
