import { get, noop } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFormulaBlockForm } from '@amal-ia/amalia-lang/formula/form';
import { Portal } from '@amal-ia/ext/react/components';
import { Tooltip } from '@amal-ia/frontend/design-system/components';

import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';
import { formulaConditionTagMessages } from '../formula-condition-tag/FormulaConditionTag.messages';

import { FormulaPortal, type FormulaPortalProps } from './formula-portal/FormulaPortal';
import { formulaConditionTagFormulaMessages } from './FormulaConditionTagFormula.messages';

export type FormulaConditionTagFormulaProps = {
  readonly condition: FormulaBuilderFormulaBlockForm;
  readonly path: string;
  readonly onChange?: FormulaPortalProps['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFormulaBlockForm>['onDelete'];
};

export const FormulaConditionTagFormula = memo(function FormulaConditionTagFormula({
  condition,
  path,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFormulaProps) {
  const { portalElement, activeConditionId, errors: builderErrors } = useFormulaBuilderContext();

  const isActive = condition.id === activeConditionId;

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.label`) as string | undefined,
        get(builderErrors, `${path}.formula`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );

  return (
    <Fragment>
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip content={<FormattedMessage {...formulaConditionTagFormulaMessages.CLICK_TO_EDIT_FORMULA} />}>
            <span>
              {condition.label.trim() || <FormattedMessage {...formulaConditionTagMessages.CUSTOM_CONDITION} />}
            </span>
          </Tooltip>
        }
        onDelete={onDelete}
      />

      {isActive ? (
        <Portal element={portalElement}>
          <FormulaPortal
            condition={condition}
            path={path}
            onChange={onChange}
          />
        </Portal>
      ) : null}
    </Fragment>
  );
});
