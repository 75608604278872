import { IconFilterPlus, IconTrash } from '@tabler/icons-react';
import { partition, without } from 'lodash';
import {
  memo,
  type ReactElement,
  useState,
  useCallback,
  Fragment,
  isValidElement,
  Children,
  cloneElement,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';

import { IconButton } from '../../general/icon-button/IconButton';
import { MenuDropdown } from '../../overlays/menu-dropdown/MenuDropdown';
import { type SelectOptionGroup } from '../../overlays/select-dropdown/SelectDropdown.types';

import { Filter, type FilterProps } from './filter/Filter';
import { isEmptyValue } from './filter/helpers/filterValue';
import { FiltersContext, type FiltersContextValue } from './Filters.context';
import * as styles from './Filters.styles';
import { filtersTestIds } from './Filters.testIds';
import { type FilterOption, type FilterKey } from './Filters.types';

type FilterElementProps = FilterProps<
  FilterOption,
  boolean | undefined,
  boolean | undefined,
  SelectOptionGroup<FilterOption>
>;

type FilterElement = ReactElement<FilterElementProps>;

export type FiltersProps = {
  /** List of ids of displayed filters. Static filters are implicitly displayed (not in this list). If omitted, the component controls the state itself. */
  readonly displayedFiltersIds?: FilterKey[];
  /** Callback when the list of displayed filters changes. */
  readonly onChangeDisplayedFiltersIds?: (displayedFiltersIds: FilterKey[]) => void;
  /** List of `<Filters.Filter />` elements. If you need to encapsulate a filter inside a subcomponent, you will have to pass `id`/`value`/`onChange`/`label`/`isMultiple`/`isStatic` to the direct child of `<Filters />` or it might not work properly. */
  readonly children: FilterElement | FilterElement[];
};

const FiltersBase = function Filters({
  displayedFiltersIds: controlledDisplayedFiltersIds,
  onChangeDisplayedFiltersIds: controlledOnChangeDisplayedFiltersIds,
  children,
}: FiltersProps) {
  const { formatMessage } = useIntl();

  // List of ids of displayed filters. Static filters are implicitly displayed (not in this list).
  const [uncontrolledDisplayedFiltersIds, setUncontrolledDisplayedFiltersIds] = useState<FilterKey[]>([]);
  const displayedFiltersIds = controlledDisplayedFiltersIds ?? uncontrolledDisplayedFiltersIds;
  const setDisplayedFiltersIds = controlledOnChangeDisplayedFiltersIds ?? setUncontrolledDisplayedFiltersIds;

  // Id of the last filter that was added. Used to open its dropdown on mount.
  const [lastFilterAddedId, setLastFilterAddedId] = useState<FilterKey | null>(null);

  // Filters are deduced from the direct children components.
  const filters = Children.toArray(children).filter(isValidElement<FilterElementProps>);

  // Split filters into static and optional filters.
  const [staticFilters, optionalFilters] = partition(filters, (filter) => filter.props.isStatic);

  // List of optional filters that are not displayed yet.
  const availableFilters = optionalFilters.filter((filter) => !displayedFiltersIds.includes(filter.props.id));

  // List of optional filters that are displayed, in the order in which they were selected.
  const displayedOptionalFilters = displayedFiltersIds
    .map((id) => optionalFilters.find((filter) => filter.props.id === id))
    .filter(Boolean);

  const handleHideFilter = useCallback(
    (id: FilterKey) => setDisplayedFiltersIds(without(displayedFiltersIds, id)),
    [displayedFiltersIds, setDisplayedFiltersIds],
  );

  const handleAddFilter = useCallback(
    (id: FilterKey) => {
      setDisplayedFiltersIds([...displayedFiltersIds, id]);
      setLastFilterAddedId(id);
    },
    [displayedFiltersIds, setDisplayedFiltersIds, setLastFilterAddedId],
  );

  const handleClearFilters = useCallback(() => {
    setDisplayedFiltersIds([]);
    filters.forEach((filter) => {
      filter.props.onChange(filter.props.isMultiple ? [] : null);
    });
  }, [setDisplayedFiltersIds, filters]);

  const contextValue = useShallowObjectMemo<FiltersContextValue>({
    onHideFilter: handleHideFilter,
    lastFilterAddedId,
  });

  return (
    <FiltersContext.Provider value={contextValue}>
      <div css={styles.filters}>
        {/**
         * Show the add filter button if there are optional filters. If all optional filters are already displayed, disable the button but still show it.
         * When there are no optional filters, don't show the button.
         */}
        {optionalFilters.length > 0 && (
          <MenuDropdown
            title={<FormattedMessage defaultMessage="Add filter on" />}
            content={
              <Fragment>
                {availableFilters.map((filter) => (
                  <MenuDropdown.Item
                    key={filter.props.id}
                    data-testid={filtersTestIds.addFilter(filter.props.id)}
                    label={filter.props.label}
                    onClick={() => handleAddFilter(filter.props.id)}
                  />
                ))}
              </Fragment>
            }
          >
            <MenuDropdown.IconButton
              disabled={!availableFilters.length}
              icon={<IconFilterPlus />}
              label={formatMessage({ defaultMessage: 'Add filters' })}
              size={MenuDropdown.IconButton.Size.SMALL}
            />
          </MenuDropdown>
        )}

        {/* Show static filters first, in the order in which they were defined, then displayed optional filters, in the order in which they were selected. */}
        {[...staticFilters, ...displayedOptionalFilters].map((filter) =>
          cloneElement(filter, { key: filter.props.id }),
        )}

        <IconButton
          icon={<IconTrash />}
          label={formatMessage({ defaultMessage: 'Clear filters' })}
          size={IconButton.Size.SMALL}
          variant={IconButton.Variant.DANGER}
          disabled={
            // Disable the button if there are no added optional filters and no filter has a value.
            !displayedOptionalFilters.length &&
            ![...staticFilters, ...displayedOptionalFilters].some((filter) => !isEmptyValue(filter.props.value))
          }
          onClick={handleClearFilters}
        />
      </div>
    </FiltersContext.Provider>
  );
};

export const Filters = Object.assign(memo(FiltersBase), {
  Filter,
});
