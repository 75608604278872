import { type Rule } from '@amal-ia/compensation-definition/plans/types';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/rules';

export class RulesApiClient {
  public static async create(rule: Rule): Promise<Rule> {
    const { data } = await http.post<Rule>(`${apiEndpoint}/`, rule);
    return data;
  }

  public static async update(rule: Rule): Promise<Rule> {
    const { data } = await http.put<Rule>(`${apiEndpoint}/${rule.id}`, rule);
    return data;
  }

  public static async delete(ruleId: string): Promise<void> {
    await http.delete(`${apiEndpoint}/${ruleId}`);
  }

  public static async list(): Promise<Rule[]> {
    const { data } = await http.get<Rule[]>(`${apiEndpoint}`);
    return data;
  }

  public static async get(ruleId: string): Promise<Rule> {
    const { data } = await http.get<Rule>(`${apiEndpoint}/${ruleId}`);
    return data;
  }

  public static async createV2(rule: Partial<Rule>): Promise<Rule> {
    const { data } = await http.post<Rule>(`${apiEndpoint}/v2`, rule);
    return data;
  }
}
