import { css, type Theme } from '@emotion/react';

export const IS_CHECKED_CLASSNAME = 'checked';
export const IS_DISABLED_CLASSNAME = 'disabled';

export const dropdownItemButton = (theme: Theme) => css`
  width: 100%;
  display: block;
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default('box-shadow')};

  &:focus-visible {
    outline: 1px solid ${theme.ds.colors.secondary[200]};
    outline-offset: -1px;
    box-shadow: ${theme.ds.shadows.focusSmall(theme.ds.colors.secondary[50])};

    position: relative;
    z-index: 1;
  }
`;

export const dropdownItem = (theme: Theme) => css`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 2px 12px;

  min-height: 32px;

  color: ${theme.ds.colors.gray[900]};
  border-radius: ${theme.ds.borderRadiuses.squared};

  transition: ${theme.ds.transitions.default('background-color', 'color')};

  button:disabled > & {
    color: ${theme.ds.colors.gray[300]};
  }

  button:not(:disabled) > & {
    &:hover {
      background-color: ${theme.ds.colors.graySecondary[50]};
    }
  }
`;
