import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { memo, type ReactNode } from 'react';

import { AuthorizationProtector } from '@amal-ia/frontend/connected-components/auth';
import { AccessProtector } from '@amal-ia/frontend/kernel/authz/context';
import { getEnvironmentVariable } from '@amal-ia/frontend/kernel/config';
import { ChangelogProvider } from '@amal-ia/frontend/views/amalia-meta/changelog';

interface AppProvidersProps {
  readonly children: ReactNode;
}

export const AppProviders = memo(function AppProviders({ children }: AppProvidersProps) {
  return (
    <AuthorizationProtector>
      <AccessProtector>
        <ChangelogProvider />
        {!getEnvironmentVariable('NX_REACT_APP_IS_CYPRESS') && (
          <ReactQueryDevtools
            buttonPosition="top-left"
            initialIsOpen={false}
            position="bottom"
          />
        )}
        {children}
      </AccessProtector>
    </AuthorizationProtector>
  );
});
