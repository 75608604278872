import { Edit as EditIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { routes } from '@amal-ia/common/routes';
import { type Filter, type Plan } from '@amal-ia/compensation-definition/plans/types';
import { colors } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';
import { formikToMui } from '@amal-ia/lib-ui';

const useStyles = makeStyles({
  root: {
    color: colors['grey-700'],
    cursor: 'pointer',
    width: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.25rem',
  },
});

export interface DesignerRedirectProps {
  readonly plans?: Plan[];
  readonly filters?: Filter[];
  readonly variables?: Variable[];
  readonly value?: string | null;
}

export const DesignerRedirectBase = memo(function DesignerRedirectBase({
  plans,
  filters,
  variables,
  value,
}: DesignerRedirectProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const { objectType, objectName, objectId } = useMemo(() => {
    const result = { objectType: '', objectName: '', objectId: '' };

    if (!value) {
      return result;
    }

    const planFound = (plans || []).find((p) => p.name === value || p.id === value);
    const filterFound = (filters || []).find((f) => f.machineName === value || f.id === value);
    const variableFound = (variables || []).find((v) => v.machineName === value || v.id === value);

    if (planFound) {
      return {
        objectType: TokenType.PLAN,
        objectName: planFound.name,
        objectId: planFound.id,
      };
    }

    if (filterFound) {
      return {
        objectType: TokenType.FILTER,
        objectName: filterFound.name,
        objectId: filterFound.id,
      };
    }

    if (variableFound) {
      return {
        objectType: variableFound.object ? TokenType.FIELD : TokenType.VARIABLE,
        objectName: variableFound.name,
        objectId: variableFound.id,
      };
    }

    return result;
  }, [plans, filters, variables, value]);

  const openLink = useCallback(
    () => navigate(generatePath(routes.DESIGNER_OBJECT, { objectType, objectId })),
    [navigate, objectType, objectId],
  );

  if (!objectId) {
    return null;
  }

  const title = formatMessage(
    { defaultMessage: 'Open {objectType} {objectName}' },
    { objectType: objectType?.toLowerCase() ?? '', objectName },
  );

  return (
    <Box
      aria-label={title}
      className={classes.root}
      title={title}
      onClick={openLink}
    >
      <EditIcon />
    </Box>
  );
});

export const DesignerRedirect = memo<DesignerRedirectProps & { name?: string }>(formikToMui(DesignerRedirectBase));

DesignerRedirect.displayName = 'DesignerRedirect';
