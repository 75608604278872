import { camelCase } from 'lodash';

export class StringUtils {
  public static camelCase(name: string): string {
    if (!name) {
      return name;
    }
    // Transform to camel case.
    return camelCase(
      // Remove all characters that are not alpha num, replace with a space instead.
      name.replace(/[^a-zA-Z0-9]/gu, ' '),
    );
  }

  public static slugify(text: string) {
    return (
      text
        // Convert to a string.
        .toString()
        // Replace accentuated characters by their non accentuated equivalent (é -> e).
        .normalize('NFD')
        // Remove emojies and "weird" characters.
        .replace(/[\u0300-\u036f]/gu, '')
        // Put down to lowercase.
        .toLowerCase()
        // Remove spaces at the beginning and at the end.
        .trim()
        // Replace spaces by dashes.
        .replace(/\s+/gu, '-')
        // Remove non word character, like punctuations or symbols.
        .replace(/[^\w-]+/gu, '')
        // Replace double dashes by dashes.
        .replace(/--+/gu, '-')
    );
  }

  public static isSlug(text: string): boolean {
    return /^[a-z0-9-]*$/u.exec(text) !== null;
  }

  public static isMachineName(text: string): boolean {
    return !!text && !!/^[A-Za-z0-9]*$/u.exec(text);
  }
}

export const pluralize = (value: string, quantity: number): string => (quantity > 1 ? `${value}s` : value);

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export const generateRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789*-!%_';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
