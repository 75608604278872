import { css, useTheme } from '@emotion/react';
import { type ComponentPropsWithoutRef, memo, type ForwardedRef, forwardRef } from 'react';

import { useTextOverflows } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Tooltip, type TooltipProps } from '../../overlays/tooltip/Tooltip';

export type TextClampProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Maximum number of lines to display. */
      maxLines: number;
      /** Typography variant. */
      variant: TypographyVariant;
      /** If true, will always take the max height regardless of if the text truncates or not. */
      shouldFillSpace?: boolean;
      /** Tooltip content on overflow. */
      tooltipContent?: TooltipProps['content'];
      /** Tooltip placement on overflow. */
      tooltipPlacement?: TooltipProps['placement'];
    },
  ]
>;

const TextClampForwardRef = forwardRef(function TextClamp(
  {
    maxLines,
    variant,
    shouldFillSpace = false,
    tooltipContent = undefined,
    tooltipPlacement = undefined,
    children = undefined,
    ...props
  }: TextClampProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { doesTextOverflow, ref: overflowRef } = useTextOverflows([children]);
  const theme = useTheme();

  return (
    <Tooltip
      content={doesTextOverflow ? tooltipContent || children : ''}
      placement={tooltipPlacement}
    >
      <div
        {...props}
        ref={ref}
        css={[
          theme.ds.typographies[variant],
          shouldFillSpace &&
            css`
              height: calc(${theme.ds.typographies[variant].lineHeight} * ${maxLines});
            `,
        ]}
      >
        <div
          ref={overflowRef}
          css={css`
            display: -webkit-box;
            -webkit-line-clamp: ${maxLines};
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-wrap: break-word;
          `}
        >
          {children}
        </div>
      </div>
    </Tooltip>
  );
});

export const TextClamp = Object.assign(memo(TextClampForwardRef), {
  Variant: TypographyVariant,
});
