import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toLower } from 'lodash';
import { memo, useCallback, useMemo, useState, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { COMMON_MESSAGES } from '../../../utils/messages/common.messages';
import { Avatar } from '../Avatar';
import { ListPopUp } from '../ListPopUp/ListPopUp';
import { LightTooltip } from '../Tooltip/Tooltip';
import { TextType, Text } from '../typography';

interface RemainUsersPopUpProps {
  readonly children: any;
  readonly allUsers?: UserContract[];
  readonly title?: ReactNode | string;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  userLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px 10px',
  },
  username: {
    marginLeft: '20px',
    color: theme.palette.secondary.main,
  },
  avatar: {
    width: '25px',
    height: '25px',
    color: theme.palette.grey[700],
  },
}));

const LOAD_MORE_USERS_STEP = 9;
export const RemainUsersPopUp = memo(function RemainUsersPopUp({ children, title, allUsers }: RemainUsersPopUpProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [step, setStep] = useState(LOAD_MORE_USERS_STEP);

  const users = useMemo(() => {
    if (search !== '') {
      return allUsers.filter((u) => toLower(formatUserFullName(u)).includes(toLower(search)));
    }
    return [...allUsers].slice(0, step);
  }, [allUsers, search, step]);

  const handleLoadMore = useCallback(
    (e) => {
      e.preventDefault();
      const toLoad =
        allUsers.length - users.length < LOAD_MORE_USERS_STEP ? allUsers.length - users.length : LOAD_MORE_USERS_STEP;
      setStep(step + toLoad);
    },
    [allUsers.length, step, users.length],
  );

  return (
    <Box>
      <LightTooltip
        placement="right-end"
        title={
          <ListPopUp
            search={search}
            setSearch={setSearch}
            title={title}
            buttonComponent={
              users.length !== allUsers.length &&
              search === '' && (
                <Box onClick={handleLoadMore}>
                  <FormattedMessage
                    {...COMMON_MESSAGES.LOAD_MORE_PROGRESS}
                    values={{
                      count: users.length < 10 ? users.length : step,
                      total: allUsers.length,
                    }}
                  />
                </Box>
              )
            }
          >
            {users.map((user) => (
              <Box
                key={user.id}
                className={classes.userLine}
              >
                <Avatar
                  className={classes.avatar}
                  user={user}
                />
                <Text
                  className={classes.username}
                  type={TextType.USERS_IN_TOOLTIP}
                >
                  {formatUserFullName(user)}
                </Text>
              </Box>
            ))}
          </ListPopUp>
        }
      >
        {children}
      </LightTooltip>
    </Box>
  );
});
