import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type RuleType } from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { assert } from '@amal-ia/ext/typescript';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { type CurrencyAmount, formatTotal, type MainKpi, type PaymentCategory } from '@amal-ia/lib-types';
import { PAYMENT_CATEGORIES_LABELS, RULE_TYPES_LABELS_TRANSLATED } from '@amal-ia/lib-ui';

interface KpiRuleItemProps {
  readonly mainKpi: MainKpi;
  readonly variant?: 'default' | 'small';
}

export const KpiRuleItem = memo(function KpiRuleItem({ mainKpi, variant = 'default' }: KpiRuleItemProps) {
  const { formatMessage } = useIntl();

  const isNilValue =
    !mainKpi.overrideFormattedValue && (isNil(mainKpi.value) || isNil((mainKpi.value as CurrencyAmount).amount));

  const formattedTotal = useMemo(() => {
    if (mainKpi.overrideFormattedValue) {
      return mainKpi.overrideFormattedValue;
    }

    if (isNilValue) {
      return <FormattedMessage defaultMessage="No value" />;
    }

    const valueAsAmount = mainKpi.value as CurrencyAmount;

    assert(!isNil(valueAsAmount.amount), 'mainKpi.value should be a CurrencyAmount');

    return formatTotal(
      valueAsAmount.amount,
      FormatsEnum.currency,
      valueAsAmount.currencySymbol,
      valueAsAmount.currencyRate,
    );
  }, [mainKpi, isNilValue]);

  const mainKpiLabel = useMemo(() => {
    if (mainKpi.label) {
      return mainKpi.label;
    }

    if (RULE_TYPES_LABELS_TRANSLATED[mainKpi.type as RuleType]) {
      return formatMessage(RULE_TYPES_LABELS_TRANSLATED[mainKpi.type as RuleType]);
    }

    if (PAYMENT_CATEGORIES_LABELS[mainKpi.type as PaymentCategory]) {
      return formatMessage(PAYMENT_CATEGORIES_LABELS[mainKpi.type as PaymentCategory]);
    }

    return '';
  }, [formatMessage, mainKpi.label, mainKpi.type]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      {!!(mainKpi.label || mainKpi.type) && (
        <Typography
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
          css={(theme) => css`
            color: ${theme.ds.colors.gray[600]};
          `}
        >
          {mainKpi.isForecastedView
            ? formatMessage({ defaultMessage: 'Forecasted {rulelabel}' }, { rulelabel: mainKpiLabel })
            : mainKpiLabel}
        </Typography>
      )}

      <div aria-label={formatMessage({ defaultMessage: 'Rule total' })}>
        <Typography
          as="div"
          variant={variant === 'small' ? Typography.Variant.BODY_LARGE_REGULAR : Typography.Variant.HEADING_4_MEDIUM}
          css={[
            (theme) => css`
              color: ${isNilValue ? theme.ds.colors.gray[500] : theme.ds.colors.gray[900]};
            `,
            mainKpi.isForecastedView && mainKpi.isValueForecasted && !isNilValue
              ? (theme) => css`
                  display: inline-flex;
                  padding: 0 4px;
                  background-color: ${theme.ds.hues.blue[50]};
                `
              : undefined,
          ]}
        >
          {formattedTotal}
        </Typography>
      </div>
    </Box>
  );
});
