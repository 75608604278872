import moment from 'moment';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { toDate, toTimestamp } from '@amal-ia/kernel/dates';
import { type ComputedTeam, type UserStatements } from '@amal-ia/lib-types';
import { TeamRole } from '@amal-ia/tenants/assignments/teams/types';

import { type ComputedTeamAssignment, type TeamAssignment, type TeamAssignmentDto } from '../../types/teams';

export const computeTeamAssignments = (assignments: TeamAssignment[]): Record<string, ComputedTeamAssignment> => {
  // So here I'm building a temporary dictionary to index, by userId, current employee and manager assignment.
  // The values of this hashmap contain data from both assignments if they exist.
  const finalCollection = {} as Record<string, Partial<ComputedTeamAssignment>>;
  assignments.forEach((assignment) => {
    finalCollection[assignment.userId] = {
      id: assignment.userId,
      userId: assignment.userId,
      assignments: [
        ...(finalCollection[assignment.userId]?.assignments || []),
        {
          id: assignment.id,
          status: true,
          teamRole: assignment.teamRole,
          effectiveAsOf: assignment?.effectiveAsOf || null,
          effectiveUntil: assignment?.effectiveUntil || null,
        },
      ],
    };
  });
  return finalCollection as Record<string, ComputedTeamAssignment>;
};

// Convert second timestamps to js dates.
export const formatTeamAssignmentFromApi = (assignment: TeamAssignmentDto): TeamAssignment => ({
  ...assignment,
  effectiveUntil: assignment.effectiveUntil ? toDate(assignment.effectiveUntil) : null,
  effectiveAsOf: assignment.effectiveAsOf ? toDate(assignment.effectiveAsOf) : null,
});

// Convert dates to second timestamps.
export const formatTeamAssignmentToApi = (assignment: TeamAssignment): TeamAssignmentDto => ({
  ...assignment,
  effectiveUntil: assignment.effectiveUntil ? toTimestamp(assignment.effectiveUntil) : null,
  effectiveAsOf: assignment.effectiveAsOf ? toTimestamp(assignment.effectiveAsOf) : null,
});

export const sortComputedTeams = (t1: ComputedTeam, t2: ComputedTeam) => {
  // If team is archived, put it at the end
  if (t1.isTeamArchived && !t2.isTeamArchived) return 1;
  if (!t1.isTeamArchived && t2.isTeamArchived) return -1;

  // If user is manager on t1 and not in t2, or the inverse, manage this
  const isT1Manager = t1.teamRole === TeamRole.TEAM_MANAGER;
  const isT2Manager = t2.teamRole === TeamRole.TEAM_MANAGER;
  if (isT1Manager && !isT2Manager) {
    return -1;
  }
  if (!isT1Manager && isT2Manager) {
    return 1;
  }

  // Otherwise, if he has the same role on both teams, sort them by name
  return t1.name.localeCompare(t2.name);
};

export const filterComputedTeamsOverPeriod = (team: ComputedTeam, period: Period) => {
  if (!team || !period) {
    return false;
  }
  if (team.effectiveAsOf && team.effectiveAsOf >= period.endDate) {
    return false;
  }
  return !(team.effectiveUntil && team.effectiveUntil <= period.startDate);
};

export const getUserTeamRoleFromUserStatementsList = (userStatements: UserStatements): ComputedTeam | null => {
  if (userStatements.lines.length >= 1) {
    const statement = userStatements.lines[0];
    if (statement.resultSummary?.computedTeams && statement.resultSummary.computedTeams.length > 0) {
      const userTeams = statement.resultSummary.computedTeams
        .filter((t: ComputedTeam) => filterComputedTeamsOverPeriod(t, statement.period))
        .sort(sortComputedTeams);

      if (userTeams.length > 0) {
        // Return the most convenient team, with isManager at true if user is manager
        let teamToTake = userTeams[0];

        if (statement.resultSummary?.planAssignment?.mainTeamId) {
          const mainTeamFound = userTeams.find((t) => t.teamId === statement.resultSummary?.planAssignment?.mainTeamId);
          if (mainTeamFound) {
            teamToTake = mainTeamFound;
          }
        }

        return teamToTake;
      }
    }
  }

  return null;
};

export const checkOngoingTeamAssignments = (ta: ComputedTeamAssignment) => {
  const currentMoment = moment();

  return ta.assignments.some(
    (assignment) =>
      (!assignment.effectiveAsOf || currentMoment.isAfter(moment(assignment.effectiveAsOf))) &&
      (!assignment.effectiveUntil || currentMoment.isBefore(moment(assignment.effectiveUntil))),
  );
};
