import { groupBy, uniq } from 'lodash';

import { http } from '@amal-ia/frontend/kernel/http';
import { type TeamRole } from '@amal-ia/tenants/assignments/teams/types';
import { type TeamContract, type TeamNode } from '@amal-ia/tenants/teams/types';

import {
  type ComputedTeamAssignment,
  type TeamAssignment,
  type TeamAssignmentDto,
  type TeamPlanAssignment,
} from '../../types/teams';

import { computeTeamAssignments, formatTeamAssignmentFromApi, formatTeamAssignmentToApi } from './teams.service';

// =================== TEAM ===================
export async function getTeamHierarchy(showArchived: boolean = false): Promise<TeamNode[]> {
  const { data } = await http.get('/teams/hierarchy', { params: { showArchived } });
  return data;
}

export async function createTeam(team: Partial<TeamContract>): Promise<TeamContract> {
  const { data } = await http.post<TeamContract>('/teams/', team);
  return data;
}

export async function updateTeam(team: Partial<TeamContract>): Promise<TeamContract> {
  const { data } = await http.put<TeamContract>('/teams/', team);
  return data;
}

export async function deleteTeam(id: string): Promise<TeamContract> {
  const { data } = await http.delete<TeamContract>(`/teams/${id}`);
  return data;
}

export async function getTeams(userIds?: string[], planIds?: string[]): Promise<TeamContract[]> {
  const qs = [
    ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
    ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
  ]
    .filter(Boolean)
    .join('&');

  const { data } = await http.get(`/teams?${qs}`);
  return data;
}

export async function getTeam(teamId: string): Promise<TeamContract> {
  const { data } = await http.get(`/teams/team/${teamId}`);
  return data;
}

//= ================== TEAM ASSIGNMENTS ===================

export async function getTeamAssignments(teamsIds: string[], teamRole?: TeamRole): Promise<TeamAssignment[]> {
  const { data } = await http.get<TeamAssignmentDto[]>('/team_assignments', {
    params: {
      teamId: teamsIds || undefined,
      teamRole: teamRole || undefined,
    },
  });
  return data.map(formatTeamAssignmentFromApi);
}

export async function getTeamAssignmentsForUser(userId: string, teamRole?: TeamRole): Promise<TeamAssignment[]> {
  const { data } = await http.get<TeamAssignmentDto[]>('/team_assignments', {
    params: {
      userId,
      relations: 'team',
      teamRole: teamRole || undefined,
    },
  });

  return data.map(formatTeamAssignmentFromApi);
}

export async function addTeamAssignment(teamId: string, userId: string, teamRole: TeamRole) {
  const { data } = await http.post<TeamAssignmentDto>('/team_assignments', {
    team: { id: teamId },
    user: { id: userId },
    teamRole,
  });

  return formatTeamAssignmentFromApi(data);
}

export async function updateTeamAssignment(id: string, teamId: string, teamAssignment: TeamAssignment) {
  const { data } = await http.patch<TeamAssignmentDto>(`/team_assignments/${id}`, {
    ...formatTeamAssignmentToApi(teamAssignment),
    team: { id: teamId },
  });
  return formatTeamAssignmentFromApi(data);
}

export async function removeTeamAssignment(id: string, teamId: string) {
  const { data } = await http.delete(`/team_assignments/${id}`, {
    data: {
      team: { id: teamId },
    },
  });
  return data;
}

// =================== ASSIGNMENTS ===================

export async function fetchTeamMembersAssignments(teamId: string): Promise<Record<string, ComputedTeamAssignment>> {
  const assignments = await getTeamAssignments([teamId]);
  return computeTeamAssignments(assignments);
}

export async function fetchMultipleTeamMembersAssignments(
  teamIds: string[],
): Promise<Record<string, Record<string, ComputedTeamAssignment>>> {
  const assignments = await getTeamAssignments(teamIds);
  const assignmentsPerTeam = groupBy(assignments, 'teamId') as Record<string, TeamAssignment[]>;

  return uniq(Object.keys(assignmentsPerTeam)).reduce<Record<string, Record<string, ComputedTeamAssignment>>>(
    (acc, teamId) => {
      acc[teamId] = computeTeamAssignments(assignmentsPerTeam[teamId] || []);
      return acc;
    },
    {},
  );
}

export async function applyAssignmentDiff(
  teamId: string,
  previousAssignment: ComputedTeamAssignment | null,
  nextAssignment: ComputedTeamAssignment,
): Promise<ComputedTeamAssignment> {
  const updatedAssignment = nextAssignment;
  const { userId } = nextAssignment;

  // if assignemnt has 'new' as id, add it to db
  const newAssignIndex = updatedAssignment.assignments.findIndex((ass) => ass.id === 'new');
  if (newAssignIndex !== -1) {
    const assignmentToAdd = updatedAssignment.assignments[newAssignIndex];
    const newAssignFromDatabase: TeamAssignment = await addTeamAssignment(teamId, userId, assignmentToAdd.teamRole);
    updatedAssignment.assignments[newAssignIndex].id = newAssignFromDatabase.id;
  }
  if (previousAssignment) {
    // Delete and Update assignments
    previousAssignment.assignments.forEach(async (prevAssign) => {
      const nextAssign = updatedAssignment.assignments.find((ass) => ass.id === prevAssign.id);
      if (!nextAssign?.status) {
        await removeTeamAssignment(prevAssign.id, teamId);
        const indexToRemove = updatedAssignment.assignments.findIndex((ass) => ass.id === prevAssign.id);
        updatedAssignment.assignments.splice(indexToRemove, 1);
      } else {
        await updateTeamAssignment(nextAssign.id, teamId, {
          id: nextAssign.id,
          teamRole: nextAssign.teamRole,
          userId,
          effectiveAsOf: nextAssign.effectiveAsOf,
          effectiveUntil: nextAssign.effectiveUntil,
        });
      }
    });
  }
  return updatedAssignment;
}

// =================== TEAM PLAN ASSIGNMENTS ===================

export async function getTeamPlanAssignments(teamId: string): Promise<TeamPlanAssignment[]> {
  const { data } = await http.get(`/teams/${teamId}/plan-assignments`);
  return data;
}

export async function addTeamPlanAssignment(teamId: string, planAssignment: TeamPlanAssignment) {
  const { data } = await http.post(`/teams/${teamId}/plan-assignments`, planAssignment);
  return data;
}

export async function updateTeamPlanAssignment(teamId: string, planAssignment: TeamPlanAssignment) {
  const { data } = await http.put(`/teams/${teamId}/plan-assignments`, planAssignment);
  return data;
}

export async function removeTeamPlanAssignment(teamId: string, planAssignment: TeamPlanAssignment) {
  const { data } = await http.delete(`/teams/${teamId}/plan-assignments`, { data: planAssignment });
  return data;
}
