import { css } from '@emotion/react';
import {
  Box,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Divider,
  type Theme,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { IconArrowNarrowRight, IconCalendar } from '@tabler/icons-react';
import clsx from 'clsx';
import moment, { type Moment } from 'moment';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, IconButton, useSnackbars } from '@amal-ia/frontend/design-system/components';

import { type DateRange } from '../../../../../utils/common.types';
import { COMMON_MESSAGES } from '../../../../../utils/messages/common.messages';
import { DialogTitleWithCloseButton } from '../../../DialogTitleWithCloseButton';
import { Text, TextType } from '../../../typography';
import { InputLabel } from '../../inputs/Input/Input';

interface DateRangeMoment {
  startDate: Moment | null;
  endDate: Moment | null;
}

interface DateRangeModalProps {
  readonly label?: string;
  readonly value: DateRange;
  readonly onChange: (value: DateRange) => void;
  readonly placeholder?: string;
  readonly classNames?: { button?: string };
  readonly disabled?: boolean;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  divider: {
    marginTop: theme.spacing(1),
  },
  submitButton: {
    marginLeft: theme.spacing(1),
  },
  dateRangeButton_withValue: {
    width: '100%',
  },
  valueLabel: {
    textAlign: 'center',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  deactivatedUsers: {
    color: theme.palette.grey['600'],
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogActions);

const dateRangeToMomentRange = (range: DateRange): DateRangeMoment => ({
  startDate: range.startDate ? moment.utc(range.startDate) : null,
  endDate: range.endDate ? moment.utc(range.endDate) : null,
});

const momentRangeToDateRange = (range: DateRangeMoment): DateRange => ({
  startDate: range.startDate ? range.startDate.toDate() : null,
  endDate: range.endDate ? range.endDate.toDate() : null,
});

export const DateRangeModal = memo(function DateRangeModal({
  label,
  value,
  onChange,
  placeholder,
  classNames,
  disabled,
}: DateRangeModalProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { snackError } = useSnackbars();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // We're proxifing values so you can fiddle in the dialog without modifying the real value.
  const [valueProxy, setValueProxy] = useState<DateRangeMoment>({ startDate: null, endDate: null });

  // If user submits, send proxified values to onChange.
  const onApply = useCallback(() => {
    if (disabled) {
      return;
    }

    const dateRange = momentRangeToDateRange(valueProxy);
    // Checking here if startDate < endDate
    if (dateRange.startDate && dateRange.endDate && dateRange.startDate.getTime() > dateRange.endDate.getTime()) {
      snackError(<FormattedMessage defaultMessage="You can't select a start date that is after the end date" />);
      return;
    }

    setIsModalOpen(false);
    onChange(dateRange);
  }, [valueProxy, setIsModalOpen, onChange, disabled, snackError]);

  // If user dismisses, close the popup and reset proxy.
  const onDismiss = useCallback(() => {
    setIsModalOpen(false);
    // Reset proxy to initial value.
    setValueProxy(dateRangeToMomentRange(value));
  }, [setValueProxy, value, setIsModalOpen]);

  const onChangeStart = useCallback(
    (newValue: any) => {
      if (disabled) {
        return;
      }
      setValueProxy({
        ...valueProxy,
        startDate: newValue,
      });
    },
    [setValueProxy, valueProxy, disabled],
  );

  const onChangeEnd = useCallback(
    (newValue: any) => {
      if (disabled) {
        return;
      }
      setValueProxy({
        ...valueProxy,
        endDate: newValue,
      });
    },
    [setValueProxy, valueProxy, disabled],
  );

  // When value changed in parent component, refresh the value proxy too
  useEffect(() => setValueProxy(dateRangeToMomentRange(value)), [value]);

  const onOpenModal = useCallback(() => {
    if (disabled) {
      return;
    }
    setIsModalOpen(true);
  }, [disabled, setIsModalOpen]);

  const hasValue = valueProxy.startDate || valueProxy.endDate;

  const restCommonButtonProps = {
    className: clsx(hasValue && classes.dateRangeButton_withValue, classNames?.button),
    onClick: onOpenModal,
    size: hasValue || placeholder ? Button.Size.SMALL : undefined,
    disabled,
    children: hasValue ? (
      <span className={classes.valueLabel}>
        <span>
          {valueProxy.startDate ? valueProxy.startDate.format('YYYY-MM-DD') : formatMessage({ defaultMessage: 'N/A' })}
        </span>
        <IconArrowNarrowRight />
        <span>
          {valueProxy.endDate ? valueProxy.endDate.format('YYYY-MM-DD') : formatMessage({ defaultMessage: 'N/A' })}
        </span>
      </span>
    ) : (
      placeholder || <IconCalendar />
    ),
  };

  const commonButtonProps = {
    ...restCommonButtonProps,
    startIcon: hasValue || placeholder ? <IconCalendar /> : null,
  };

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {label ? (
          <InputLabel>
            <Text type={TextType.INPUT_NAME}>{label}</Text>
          </InputLabel>
        ) : null}
        {hasValue ? (
          <Button
            variant={Button.Variant.LIGHT}
            {...commonButtonProps}
          />
        ) : placeholder ? (
          <Button
            variant={Button.Variant.LIGHT}
            {...commonButtonProps}
          />
        ) : (
          <IconButton
            label={formatMessage({ defaultMessage: 'Set a date range' })}
            {...restCommonButtonProps}
            icon={<IconCalendar />}
            size={IconButton.Size.MEDIUM}
          />
        )}
      </div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isModalOpen}
        onClose={onDismiss}
      >
        <DialogTitleWithCloseButton handleClose={onDismiss} />
        <DialogContent>
          <Box
            display="flex"
            justifyContent="space-evenly"
          >
            <Box
              alignItems="center"
              aria-label={formatMessage({ defaultMessage: 'Start date' })}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Text type={TextType.MODAL_TITLE}>
                <FormattedMessage
                  defaultMessage="Start"
                  description="Date Range Modal"
                />
              </Text>
              <StaticDatePicker
                value={valueProxy.startDate ?? moment.utc()}
                slots={{
                  actionBar: () => null,
                  toolbar: () => null,
                }}
                onChange={onChangeStart}
              />
              <Button
                variant={Button.Variant.LIGHT}
                onClick={() => onChangeStart(null)}
              >
                <FormattedMessage
                  defaultMessage="Reset"
                  description="Date Range Modal"
                />
              </Button>
            </Box>
            <Box
              alignItems="center"
              aria-label={formatMessage({ defaultMessage: 'End date' })}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Text type={TextType.MODAL_TITLE}>
                <FormattedMessage
                  defaultMessage="End"
                  description="Date Range Modal"
                />
              </Text>
              <StaticDatePicker
                value={valueProxy.endDate ?? moment.utc()}
                slots={{
                  actionBar: () => null,
                  toolbar: () => null,
                }}
                onChange={onChangeEnd}
              />
              <Button
                variant={Button.Variant.LIGHT}
                onClick={() => onChangeEnd(null)}
              >
                <FormattedMessage
                  defaultMessage="Reset"
                  description="Date Range Modal"
                />
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <Divider className={classes.divider} />
        <DialogActions>
          <Button
            variant={Button.Variant.LIGHT}
            onClick={onDismiss}
          >
            <FormattedMessage {...COMMON_MESSAGES.CANCEL} />
          </Button>
          <Button onClick={onApply}>
            <FormattedMessage {...COMMON_MESSAGES.SAVE} />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
});
