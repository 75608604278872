import { Box, FormGroup } from '@mui/material';
import { memo, useCallback, useMemo, useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Checkbox } from '@amal-ia/frontend/design-system/components';
import { useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { processWorkflowStepThunkAction, useThunkDispatch } from '@amal-ia/frontend/web-data-layers';
import { WorkflowStatementStateAction, formatUserFullName } from '@amal-ia/lib-types';
import { ConfirmationModal, useStatementDetailContext } from '@amal-ia/lib-ui';
import { StatementWorkflow } from '@amal-ia/lib-ui-business';
import { useWorkflowsMap } from '@amal-ia/payout-collaboration/workflows/state';

import { useWorkflowSteps } from '../../../useWorkflowSteps';

export const StatementDetailSummaryReview = memo(function StatementDetailSummaryReview() {
  const dispatch = useThunkDispatch();

  const { formatMessage } = useIntl();
  const { authenticatedContext } = useAuthenticatedContext();
  const { data: workflowMap } = useWorkflowsMap();
  const statement = useStatementDetailContext();

  const [modalType, setModalType] = useState<'FORCE' | 'NOTIFY' | null>(null);
  const [statementStateAction, setStatementStateAction] = useState<WorkflowStatementStateAction>(
    WorkflowStatementStateAction.UNAUTHORIZED,
  );
  const [shouldSendNotification, setShouldSendNotification] = useState(true);

  const handleSubmit = useCallback(
    async (workflowAction: WorkflowStatementStateAction) => {
      if (statement.id) {
        await dispatch(processWorkflowStepThunkAction(statement.id, workflowAction, shouldSendNotification));
        setModalType(null);
      }
    },
    [dispatch, statement, shouldSendNotification],
  );

  const workflowDetails = useWorkflowSteps(statement);

  const onPreviousStep = useCallback(async () => {
    await handleSubmit(WorkflowStatementStateAction.PREVIOUS);
  }, [handleSubmit]);

  const isCurrentUserOwnerOfTheStatement = statement.user.id === authenticatedContext.user.id;

  const modalContent = useMemo(() => {
    if (modalType && ['FORCE', 'NOTIFY'].includes(modalType)) {
      const checkboxLabel = formatMessage({ defaultMessage: 'Notify {u}' }, { u: formatUserFullName(statement.user) });
      return (
        <Box>
          {modalType === 'FORCE' && (
            <p>
              <FormattedMessage defaultMessage="You are about to review that statement instead of another user." />
            </p>
          )}

          {modalType === 'NOTIFY' && (
            <p>
              <FormattedMessage defaultMessage="The reviewed statement will be blocked in its current state and will no longer be updated." />
            </p>
          )}

          {/* Show checkbox only if currentUser is not owner of the statement */}
          {!isCurrentUserOwnerOfTheStatement && (
            <Box mt={1}>
              <Checkbox
                aria-label={checkboxLabel}
                checked={shouldSendNotification}
                label={checkboxLabel}
                onChange={setShouldSendNotification}
              />
            </Box>
          )}
        </Box>
      );
    }
    return null;
  }, [isCurrentUserOwnerOfTheStatement, shouldSendNotification, modalType, statement, formatMessage]);

  const onNextStep = useCallback(
    async (canValidStep: WorkflowStatementStateAction, checked: boolean) => {
      setStatementStateAction(canValidStep);
      // Show modal if it's the last state or if admin force review
      // Make a PREVIOUS submit if uncheck the toggle
      if (!checked) {
        await handleSubmit(WorkflowStatementStateAction.PREVIOUS);
      } else if (canValidStep === WorkflowStatementStateAction.FORCE) {
        setModalType('FORCE');
      } else {
        setModalType('NOTIFY');
      }
    },
    [handleSubmit],
  );

  const onReset = useCallback(async () => {
    await handleSubmit(WorkflowStatementStateAction.RESET);
  }, [handleSubmit]);
  const handleModalClose = useCallback(() => setModalType(null), []);

  const handleConfirm = useCallback(() => handleSubmit(statementStateAction), [handleSubmit, statementStateAction]);

  return (
    <Fragment>
      <FormGroup>
        <StatementWorkflow
          statementHeaderView
          statement={statement}
          workflowDetails={workflowDetails}
          workflowMap={workflowMap}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
          onReset={onReset}
        />
      </FormGroup>
      <ConfirmationModal
        cancelText={<FormattedMessage defaultMessage="No, cancel" />}
        confirmText={<FormattedMessage defaultMessage="Yes, review statement" />}
        contentText={modalContent}
        handleClose={handleModalClose}
        handleConfirm={handleConfirm}
        isOpened={!!modalContent}
        title={<FormattedMessage defaultMessage="Review this statement" />}
      />
    </Fragment>
  );
});
