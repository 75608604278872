import { type ActionCreator } from 'redux';

import { RulesApiClient } from '@amal-ia/compensation-definition/api-client';
import { type Rule } from '@amal-ia/compensation-definition/plans/types';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import { addSnackbar } from '../snackbars/actions';

import { RULES_ACTIONS } from './constants';

const ruleStart: ActionCreator<ReduxAction> = () => ({
  type: RULES_ACTIONS.START,
});

const ruleError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: RULES_ACTIONS.ERROR,
  error,
});

const setRule: ActionCreator<ReduxAction> = (rule: Rule) => ({
  type: RULES_ACTIONS.SET_RULE,
  payload: { rule },
});

const setRules: ActionCreator<ReduxAction> = (rules: Rule[]) => ({
  type: RULES_ACTIONS.SET_RULES,
  payload: { rules },
});

export const fetchRules = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(ruleStart());

  try {
    const rules = await RulesApiClient.list();
    return dispatch(setRules(rules));
  } catch (error) {
    return dispatch(ruleError(error));
  }
};

export const updateRule =
  (rule: Rule): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(ruleStart());

    try {
      const updatedRule = await RulesApiClient.update(rule);
      dispatch(addSnackbar({ message: 'Rule Updated!', options: { variant: 'success' } }));
      return dispatch(setRule(updatedRule));
    } catch (error) {
      dispatch(addSnackbar({ message: 'An error occured while updating the rule.', options: { variant: 'error' } }));
      return dispatch(ruleError(error));
    }
  };
