import { useQuery } from '@tanstack/react-query';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { getUserStatementsFacets } from '@amal-ia/frontend/web-data-layers';
import { type StatusStatementType } from '@amal-ia/lib-types';

export const USE_STATEMENTS_FACETS_QUERY_KEY = 'statements-facets';

export const useStatementFacets = (
  period: Period,
  planId?: string,
  teamId?: string,
  status?: StatusStatementType,
  isForecast?: boolean,
) => {
  const { data: statementsListFacets, ...rest } = useQuery({
    queryKey: [USE_STATEMENTS_FACETS_QUERY_KEY, period?.id, planId, teamId, status, isForecast],
    queryFn: () => getUserStatementsFacets(period?.id, planId ? [planId] : undefined, teamId, status, isForecast),
    enabled: !!(period?.id || planId),
  });

  return {
    // Provides a default set of facets before initialization.
    statementsListFacets: statementsListFacets || {
      users: [],
      plans: [],
      teams: [],
      workflow: undefined,
    },
    ...rest,
  };
};
