import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { tokenTypeLabels } from '@amal-ia/amalia-lang/tokens/components';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type TokenType } from '@amal-ia/lib-types';
import { useCustomIconStyles } from '@amal-ia/lib-ui';

import { designerObjectsDetails } from '../../../utils/designer';

type UseStylesProps = {
  color: string;
  selected: boolean;
  v2?: boolean;
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  rightArrow: {
    marginLeft: 'auto',
  },
  numberContainer: {
    borderRadius: '8px',
    minWidth: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props: UseStylesProps) => props.color,
    height: '15px',
    marginLeft: '10px',
    padding: '2px',
  },
  item: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'start',
    fontSize: '12px',
    fontWeight: 600,
    minHeight: (props: UseStylesProps) => (props.v2 ? '20px' : '40px'),
    minWidth: (props: UseStylesProps) => props.v2 && '100px',
    color: amaliaTheme.palette.grey['700'],
    cursor: 'pointer',
    justifySelf: 'stretch',
    backgroundColor: (props: UseStylesProps) => (!props.v2 && props.selected ? theme.palette.grey['100'] : ''),
    position: (props: UseStylesProps) => (props.v2 ? 'relative' : undefined),
    left: (props: UseStylesProps) => (props.v2 ? '2px' : undefined),
    borderRight: (props: UseStylesProps) =>
      props.v2 &&
      (props.selected ? `2px solid ${theme.palette.tertiary.main}` : `2px solid ${theme.palette.grey[200]}`),
    paddingRight: (props: UseStylesProps) => props.v2 && theme.spacing(1),
  },
}));

interface FormulaEditorGroupButtonProps {
  readonly designerObjectsType: TokenType;
  readonly handleHover?: (event: any) => void;
  readonly handleClick: (event: any) => void;
  readonly matchesFound?: number;
  readonly selected: boolean;
  readonly v2?: boolean;
  readonly object?: CustomObjectDefinition;
}

export const FormulaEditorGroupButton = memo(function FormulaEditorGroupButton({
  designerObjectsType,
  handleHover,
  handleClick,
  matchesFound,
  selected,
  v2,
  object,
}: FormulaEditorGroupButtonProps) {
  const { formatMessage } = useIntl();
  const iconClasses = useCustomIconStyles();
  const classes = useStyles({
    color: designerObjectsDetails[designerObjectsType].color,
    selected,
    v2,
  });
  if (v2) {
    return (
      <div
        aria-hidden="true"
        className={classes.item}
        onClick={handleClick}
      >
        <span>
          <FormattedMessage
            {...tokenTypeLabels[designerObjectsType]}
            values={{ count: undefined }}
          />
        </span>
        <Box className={classes.numberContainer}>{matchesFound || 0}</Box>
      </div>
    );
  }

  const IconTag = designerObjectsDetails[designerObjectsType].icon;

  return (
    <div
      aria-hidden="true"
      className={classes.item}
      onClick={handleClick}
      onMouseEnter={handleHover}
    >
      {!object && <IconTag className={iconClasses.customIcon} />}
      <span data-testid={object ? object.name : ''}>
        {object ? object.name : formatMessage(tokenTypeLabels[designerObjectsType], { count: undefined })}
      </span>
      <Box className={classes.numberContainer}>{matchesFound || 0}</Box>
      <ArrowRightIcon className={clsx(iconClasses.customIcon, classes.rightArrow)} />
    </div>
  );
});
