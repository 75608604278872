import { IconChecks } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Modal, type ModalProps } from '@amal-ia/frontend/design-system/components';

export type MarkAllAsReadConfirmationModalProps = Omit<ModalProps, 'children' | 'size'> & {
  readonly onConfirm: () => void;
  readonly isLoading?: boolean;
};

export const MarkAllAsReadConfirmationModal = memo(function MarkAllAsReadConfirmationModal({
  onConfirm,
  isLoading = false,
  ...props
}: MarkAllAsReadConfirmationModalProps) {
  return (
    <Modal
      {...props}
      size={Modal.Size.SMALL}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Mark all as read?" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage defaultMessage="All notifications will be marked as read." />
        </Modal.Body>
      </Modal.Content>

      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          icon={<IconChecks />}
          isLoading={isLoading}
          onClick={onConfirm}
        >
          <FormattedMessage defaultMessage="Proceed" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
