import { css } from '@emotion/react';
import { type ReactNode, memo, type ReactElement } from 'react';

import { Collapse } from '@amal-ia/frontend/design-system/components';

import { RuleAccordionHeader, type RuleAccordionHeaderProps } from './rule-accordion-header/RuleAccordionHeader';

export type RuleAccordionProps = {
  readonly header: ReactElement<RuleAccordionHeaderProps>;
  readonly children: ReactNode;
};

const RuleAccordionBase = function RuleAccordion({ header, children }: RuleAccordionProps) {
  return (
    <div
      css={(theme) => css`
        border-radius: ${theme.ds.borderRadiuses.squared};
        background-color: ${theme.ds.colors.gray[0]};
      `}
    >
      {header}

      <Collapse
        lazy
        isOpen={header.props.isExpanded}
      >
        {children}
      </Collapse>
    </div>
  );
};

export const RuleAccordion = Object.assign(memo(RuleAccordionBase), {
  Header: RuleAccordionHeader,
});
