import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

import { HidableElementVisibility, type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { type StatementDetailContextInterface } from '@amal-ia/lib-ui';
import { type FilterDataset, type MetricsDataset, type QuotaDataset } from '@amal-ia/payout-calculation/shared/types';

import { type DatasetWithFields } from './datasetWithField.type';
import { listDatasetFields } from './listDatasetFields';

const getDatasetFilterMachineName = (datasetId: string): string => {
  const datasetIdMetadata = datasetId.split(':');
  return datasetIdMetadata[2];
};
/**
 * Select datasets to display from statement results with their respective fields.
 * @param statement
 * @param ruleDefinition
 */
export const useDatasets = (statement: StatementDetailContextInterface, ruleDefinition: PlanRule) => {
  const [selectedDataset, setSelectedDataset] = useState<DatasetWithFields>();

  const datasetsWithFields: DatasetWithFields[] = useMemo(() => {
    const filtersToDisplay = statement.isForecastedView
      ? statement?.objectsToDisplay[ruleDefinition?.id]?.filtersToDisplay
      : ruleDefinition.filtersToDisplay;

    return (filtersToDisplay || [])
      .map((planRuleFilterToDisplay) => {
        if (planRuleFilterToDisplay.displayStatus !== HidableElementVisibility.ON_DISPLAY) {
          return undefined;
        }

        const filterDefinition = Object.values(statement.results.definitions.filters).find(
          (f) => planRuleFilterToDisplay.id === f.id,
        );
        const dataset = statement.results.datasets.find(
          (d) => getDatasetFilterMachineName(d.id) === filterDefinition?.machineName,
        ) as FilterDataset | MetricsDataset | QuotaDataset;

        const fieldsToDisplay = planRuleFilterToDisplay?.fieldsToDisplay || [];
        const fields = listDatasetFields(statement, fieldsToDisplay, dataset, ruleDefinition.formula);

        // Do not display datasets with empty fields.
        if (isEmpty(fields)) {
          return undefined;
        }

        return {
          ...dataset,
          fields,
        };
      })
      .filter(Boolean);
  }, [statement, ruleDefinition]);

  return {
    selectedDataset,
    setSelectedDataset,
    datasetsWithFields,
  };
};
