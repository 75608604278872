import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPlus } from '@tabler/icons-react';
import { Fragment, memo, useCallback, useState } from 'react';

import { type VariablesMap } from '@amal-ia/amalia-lang/tokens/types';
import { type PlanRule, type PlanRuleChart } from '@amal-ia/compensation-definition/plans/types';
import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, IconButton } from '@amal-ia/frontend/design-system/components';
import { ConfirmationModal, List, Text, TextType } from '@amal-ia/lib-ui';

import { type SelectedPlanRuleChart } from '../modals/PlanRuleChartsModal/PlanRuleChartsModal';

import { PlanRuleListChartListChart } from './PlanRuleListChartListChart';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  list: {
    marginTop: theme.spacing(1),
    '& $listElement': {
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
}));

interface PlanRuleListChartListProps {
  readonly customObjectDefinitionsMap: CustomObjectDefinitionsMap;
  readonly ruleAssignment: PlanRule;
  readonly onAddChartToRule: (rule: SelectedPlanRuleChart) => any;
  readonly onPatchRules: (rulesToPatch: PlanRule[]) => any;
  readonly variablesMap: VariablesMap;
}

export const PlanRuleListChartList = memo(function PlanRuleListChartList({
  customObjectDefinitionsMap,
  ruleAssignment,
  onAddChartToRule,
  onPatchRules,
  variablesMap,
}: PlanRuleListChartListProps) {
  const classes = useStyles();

  const [chartToDelete, setChartToDelete] = useState<PlanRuleChart | null>(null);

  const addNewChart = useCallback(() => onAddChartToRule({ rule: ruleAssignment }), [onAddChartToRule, ruleAssignment]);

  const onEditChart = useCallback(
    (chart: PlanRuleChart) => onAddChartToRule({ rule: ruleAssignment, chart }),
    [ruleAssignment, onAddChartToRule],
  );

  const onDeleteChart = useCallback((chart: PlanRuleChart) => setChartToDelete(chart), [setChartToDelete]);
  const handleDeleteModalClose = useCallback(() => setChartToDelete(null), [setChartToDelete]);

  const onDeleteConfirmChart = useCallback(() => {
    if (!chartToDelete) {
      return;
    }

    const newCharts = (ruleAssignment.charts || [])
      // Remove chart to delete
      .filter((c) => c.id !== chartToDelete.id);

    onPatchRules([
      {
        ...ruleAssignment,
        charts: newCharts,
      },
    ]);

    // Close modal
    setChartToDelete(null);
  }, [onPatchRules, chartToDelete, ruleAssignment]);

  return (
    <Fragment>
      <List
        className={classes.list}
        mode="horizontalWrap"
      >
        {!ruleAssignment.charts?.length ? (
          <Button
            aria-label={`Add Charts on rule ${ruleAssignment.name}`}
            icon={<IconPlus />}
            variant={Button.Variant.SECONDARY_LIGHT}
            onClick={addNewChart}
          >
            Add Chart
          </Button>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
          >
            <Text type={TextType.SUBTITLE}>Charts</Text>
            <Box
              display="flex"
              flexDirection="row"
            >
              <IconButton
                icon={<IconPlus />}
                label={`Add Charts on rule ${ruleAssignment.name}`}
                onClick={addNewChart}
              />
              {(ruleAssignment.charts || []).map((chart) => (
                <PlanRuleListChartListChart
                  key={chart.id}
                  chart={chart}
                  customObjectDefinitionsMap={customObjectDefinitionsMap}
                  deleteChart={onDeleteChart}
                  editChart={onEditChart}
                  variablesMap={variablesMap}
                />
              ))}
            </Box>
          </Box>
        )}
      </List>
      <ConfirmationModal
        isDeleteAction
        cancelText="Cancel"
        confirmDataTestId="delete"
        confirmText="Delete"
        contentText={`Do you really want to delete the "${chartToDelete?.name}" chart?"`}
        handleClose={handleDeleteModalClose}
        handleConfirm={onDeleteConfirmChart}
        isOpened={!!chartToDelete}
        title="Delete chart?"
      />
    </Fragment>
  );
});
