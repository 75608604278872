import { ListItemButton } from '@mui/material';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FormulaEditorToken } from '@amal-ia/amalia-lang/formula/components';
import { tokenTypeLabels } from '@amal-ia/amalia-lang/tokens/components';
import { CountBadge, Dropdown } from '@amal-ia/frontend/design-system/components';
import { type TokenType } from '@amal-ia/lib-types';
import { COMMON_MESSAGES, useLoadMore } from '@amal-ia/lib-ui';

import { SuggestionDropdownItem } from '../suggestion-dropdown-item/SuggestionDropdownItem';

type SuggestionGroupProps = {
  readonly command: (item: FormulaEditorToken) => void;
  readonly isOpen: boolean;
  readonly options: FormulaEditorToken[];
  readonly selectedFormulaToken?: FormulaEditorToken;
  readonly toggleGroup: (tokenType: TokenType) => void;
  readonly tokenType: TokenType;
};

export const SuggestionGroup = memo(function SuggestionGroup({
  tokenType,
  command,
  isOpen,
  options,
  selectedFormulaToken,
  toggleGroup,
}: SuggestionGroupProps) {
  const { formatMessage } = useIntl();
  const { elementsCapped, onClickLoadMore, count, total } = useLoadMore<FormulaEditorToken>(options);

  const handleChangeIsOpen = useCallback(() => {
    toggleGroup(tokenType);
  }, [toggleGroup, tokenType]);
  return (
    <Dropdown.Group
      key={tokenType}
      countBadge={<CountBadge>{total}</CountBadge>}
      isOpen={isOpen}
      label={formatMessage(tokenTypeLabels[tokenType], { count: undefined })}
      onChangeIsOpen={handleChangeIsOpen}
    >
      {(elementsCapped as FormulaEditorToken[]).map((item) => (
        <SuggestionDropdownItem
          key={`${item.formula}-${item.type}`}
          item={item}
          selectedItem={selectedFormulaToken}
          onClick={command}
        />
      ))}

      {!!onClickLoadMore && (
        <ListItemButton
          key="loadMoreButton"
          onClick={onClickLoadMore}
        >
          <i>
            <FormattedMessage
              {...COMMON_MESSAGES.LOAD_MORE_PROGRESS}
              values={{ count, total }}
            />
          </i>
        </ListItemButton>
      )}
    </Dropdown.Group>
  );
});
