import { type Challenge } from '@amal-ia/compensation-definition/plans/types';
import { http } from '@amal-ia/frontend/kernel/http';

export async function getChallenge(ruleId: string, periodId: string): Promise<Challenge> {
  const { data } = await http.get(`/challenges?ruleId=${ruleId}&periodId=${periodId}`);
  return data[0];
}

export async function testNotificationChallenge(ruleId: string): Promise<Challenge> {
  const { data } = await http.post(`/challenges/test-slack-notifications?ruleId=${ruleId}`, {});
  return data[0];
}
