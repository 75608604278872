import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormLayout, FormikInput, FormikSelect } from '@amal-ia/frontend/design-system/components';
import { getSupportedPlanFrequencies } from '@amal-ia/lib-types';
import { PeriodFrequencyLabels } from '@amal-ia/lib-ui';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

export const CreatePlanFormFields = memo(function CreatePlanFormFields() {
  const { formatMessage } = useIntl();
  const { data: company } = useCurrentCompany();

  const supportedFrequenciesOptions = useMemo(
    () =>
      getSupportedPlanFrequencies(company).map((f) => ({
        value: f,
        label: formatMessage(PeriodFrequencyLabels[f]),
      })),
    [company, formatMessage],
  );

  return (
    <FormLayout>
      <FormLayout.Group>
        <FormikInput
          required
          id="name"
          label={<FormattedMessage defaultMessage="Name" />}
          name="name"
        />

        <FormikSelect
          required
          id="frequency"
          isClearable={false}
          label={<FormattedMessage defaultMessage="Frequency" />}
          name="frequency"
          options={supportedFrequenciesOptions}
        />
      </FormLayout.Group>
    </FormLayout>
  );
});
