import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.COUNT, AmaliaFunctionCategory.ARRAY);

func.nbParamsRequired = 1;

func.description = 'Get the number of records in an array';

func.params = [{ name: 'array', description: 'Array, Filters or Links', validFormats: [FormatsEnum.table] }];

func.examples = [
  {
    desc: 'Returns 2',
    formula: 'COUNT([0, 1])' as AmaliaFormula,
  },
  {
    desc: 'Returns the number of records in the filtered dataset',
    formula: 'COUNT(filter.closedOppsInPeriod)' as AmaliaFormula,
  },
];

func.exec = (array: any[]): any => array.length;

export default func;
