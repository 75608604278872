import { css, type Theme } from '@emotion/react';
import { Box } from '@mui/material';
import { round } from 'lodash';
import { memo } from 'react';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';
import { formatValue } from '@amal-ia/lib-types';
import { DonutChart } from '@amal-ia/lib-ui';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';

export const kpiLabel = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[600]};
  text-transform: uppercase;
`;

type DashboardKpiProps = {
  readonly label?: string;
  readonly color: string;
  readonly bg: string;
  readonly value?: number;
  readonly emptyMessage?: string;
  readonly loading?: boolean;
};

export const DashboardKpi = memo(function Kpi({ label, color, bg, value, emptyMessage, loading }: DashboardKpiProps) {
  const kpiValue = value ? round(value * 100) : undefined;

  return (
    <DashboardWidget>
      <DonutChart
        colors={[bg, color]}
        innerRadius={35}
        loading={loading}
        thickness={7}
        data={[
          { name: 'Remainder', value: 100 - (kpiValue || 0) },
          { name: 'Progress', value: kpiValue || 0 },
        ]}
        ReplaceAnnotation={() =>
          kpiValue ? (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              marginLeft="20px"
              width="fill-available"
            >
              <Box width="100%">
                <Typography
                  css={kpiLabel}
                  variant={TypographyVariant.BODY_BASE_REGULAR}
                >
                  {label}
                </Typography>
              </Box>
              <Typography variant={TypographyVariant.HEADING_4_BOLD}>
                {formatValue(value, FormatsEnum.percent)}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
              marginLeft="15px"
              width="fill-available"
            >
              <Box width="100%">
                <Typography
                  variant={TypographyVariant.BODY_BASE_REGULAR}
                  css={(theme) => css`
                    color: ${theme.ds.colors.gray[600]};
                  `}
                >
                  {emptyMessage}
                </Typography>
              </Box>
            </Box>
          )
        }
      />
    </DashboardWidget>
  );
});
