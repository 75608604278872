import { createContext, useContext } from 'react';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import {
  type ComputedHighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amal-ia/compensation-definition/plans/types';
import { assert } from '@amal-ia/ext/typescript';
import {
  type CalculationRequest,
  type ListSelectedOptions,
  type StatusStatementType,
  type UserStatements,
  type UserStatementsFacets,
  type PaginationStoredInRedux,
} from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/companies/types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

export type StatementListContextInterface = {
  company: Company;
  status: StatusStatementType | null;
  setStatus: (status: StatusStatementType) => void;
  userStatementsPage: PaginationStoredInRedux<UserStatements> & {
    kpisAverage: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>;
  };

  isForecast?: boolean;

  period: Period;
  userStatementsFacet: UserStatementsFacets;
  userStatementsList: UserStatements[];
  calculationRequest: CalculationRequest;
  listSelectedOptions: ListSelectedOptions;
  usersFiltered: UserComputed[];
  setUsersFiltered: (users: UserComputed[]) => void;
};

const StatementListContext = createContext<StatementListContextInterface | null>(null);
export const StatementListContextProvider = StatementListContext.Provider;

export const useStatementListContext = () => {
  const context = useContext(StatementListContext);
  assert(context, 'useStatementListContext must be used within a StatementListContext');
  return context;
};
