import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { useFeatureFlag } from '@amal-ia/frontend/web-data-layers';
import { CompanyFeatureFlags } from '@amal-ia/tenants/companies/types';

const PlanAgreementDetailsContainer = lazy(() => import('./details/PlanAgreementDetailsContainer'));
const PlanAgreementDetailsPreviewContainer = lazy(() => import('./details/PlanAgreementDetailsPreviewContainer'));
const PlanAgreementDetailsViewContainer = lazy(() => import('./details/PlanAgreementDetailsViewContainer'));
const PlanAgreementsContainer = lazy(() => import('./list/PlanAgreementsContainer'));

export const PlanAgreementsRoutes = memo(function PlanAgreementsRoutes() {
  const { formatMessage } = useIntl();
  const { isFeatureEnabled: isPlanAgreementsFFEnabled } = useFeatureFlag(CompanyFeatureFlags.PLAN_AGREEMENTS);

  return (
    <Layout currentPage="planAgreements">
      <RootRoutes>
        <Route
          path={routes.PLAN_AGREEMENT_ASSIGNMENT_VIEW_DOCUMENT}
          element={
            <AbilityRouteProtector can={() => isPlanAgreementsFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan agreement | View document' })} />
              <PlanAgreementDetailsViewContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.PLAN_AGREEMENT_PREVIEW_DOCUMENT}
          element={
            <AbilityRouteProtector can={() => isPlanAgreementsFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan agreement | Preview document' })} />
              <PlanAgreementDetailsPreviewContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.PLAN_AGREEMENT}
          element={
            <AbilityRouteProtector can={() => isPlanAgreementsFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan agreement' })} />
              <PlanAgreementDetailsContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.PLAN_AGREEMENTS}
          element={
            <AbilityRouteProtector can={() => isPlanAgreementsFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan agreements' })} />
              <PlanAgreementsContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
