import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { PayoutCollaborationCommentsApiClient } from '@amal-ia/payout-collaboration/comments/api-client';
import {
  type AddMessageInStatementThread,
  type CreateStatementThread,
  type DeleteStatementThreadComment,
  type ReviewStatementThread,
  type StatementThread,
} from '@amal-ia/payout-collaboration/comments/shared/types';

import { STATEMENT_COMMENTS_QUERY_KEYS } from './queries.keys';

// --------------------------------------
// Statement threads operations.
// --------------------------------------

export const useStatementThreads = (statementId: string) =>
  useQuery({
    queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREADS, statementId],
    queryFn: async () => PayoutCollaborationCommentsApiClient.getThreadsForStatement(statementId),
    select: (data) =>
      data.reduce(
        (obj: object, statementThread: StatementThread) => ({
          ...obj,
          [statementThread.id]: statementThread,
        }),
        {},
      ) as Record<string, StatementThread>,
    enabled: !!statementId,
  });

/**
 * Creates statement thread.
 * It should invalidate the query for the statement threads.
 */
export const useCreateStatementThreadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (createStatementThreadMutation: CreateStatementThread) =>
      PayoutCollaborationCommentsApiClient.createThreadOnStatement(
        createStatementThreadMutation.statementId,
        createStatementThreadMutation.scope,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREADS] });
    },
  });
};

/**
 * Review statement thread.
 */
export const useReviewStatementThreadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (reviewStatementThread: ReviewStatementThread) =>
      PayoutCollaborationCommentsApiClient.setThreadIsReviewed(
        reviewStatementThread.statementId,
        reviewStatementThread.statementThreadId,
        reviewStatementThread.isReviewed,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREADS] });
    },
  });
};

// --------------------------------------
// Statement thread messages operations.
// --------------------------------------

/**
 * Get statement thread messages.
 */
export const useStatementThreadMessages = (statementId: string, statementThreadId: string) =>
  useQuery({
    queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREAD_MESSAGES, statementId, statementThreadId],
    queryFn: async () =>
      PayoutCollaborationCommentsApiClient.getStatementThreadMessages(statementId, statementThreadId),
    enabled: !!statementId && !!statementThreadId,
  });

/**
 * Delete Statement thread comment.
 * It should invalidate the query for the statement thread messages.
 */
export const useDeleteCommentMutation = () => {
  const { snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation({
    mutationFn: (variables: DeleteStatementThreadComment) =>
      PayoutCollaborationCommentsApiClient.deleteMessageFromStatementThread(
        variables.statementId,
        variables.statementThreadId,
        variables.messageId,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREAD_MESSAGES] });
      snackSuccess(formatMessage({ defaultMessage: 'The comment has been successfully deleted' }));
    },
  });
};

/**
 * Update Statement thread comment.
 * It should invalidate the query for the statement thread messages.
 */
export const useUpdateCommentMutation = () => {
  const { snackSuccess } = useSnackbars();
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  return useMutation({
    mutationFn: PayoutCollaborationCommentsApiClient.updateMessageFromStatementThread,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREAD_MESSAGES] });
      snackSuccess(formatMessage({ defaultMessage: 'The comment has been successfully updated' }));
    },
  });
};

/**
 * Add message in statement thread.
 * It should invalidate the query for the statement thread messages.
 */
export const useAddMessageInStatementThreadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (addMessageInStatementThread: AddMessageInStatementThread) =>
      PayoutCollaborationCommentsApiClient.postMessageOnStatementThread(
        addMessageInStatementThread.statementId,
        addMessageInStatementThread.statementThreadId,
        addMessageInStatementThread.message,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [STATEMENT_COMMENTS_QUERY_KEYS.STATEMENT_THREAD_MESSAGES] });
    },
  });
};
