import { type Dispatch, type SetStateAction, useCallback, useState } from 'react';
import store from 'store';

/**
 * As the same interface than useState, but put state in the query string instead.
 * @param defaultValue
 * @param key
 * @param preferDefaultValue
 */
export const useStateInLocalStorage = <T>(
  defaultValue: T,
  key: string,
  preferDefaultValue?: boolean,
): [state: T, setState: Dispatch<SetStateAction<T>>, hasValueInStorage: boolean, clearState: () => void] => {
  const hasValueInStorage = !!store.get(key);

  const [valueInState, setValueInState] = useState<T>(() => {
    // If we set preferDefaultValue at true, use it as initial state.
    if (preferDefaultValue) {
      return defaultValue;
    }

    // Else, read the value from the localStorage.
    return hasValueInStorage ? (store.get(key) as T) : defaultValue;
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (newValueOrCallback) => {
      setValueInState((currentValue) => {
        const newValue =
          typeof newValueOrCallback === 'function'
            ? (newValueOrCallback as (old: T) => T)(currentValue)
            : newValueOrCallback;

        store.set(key, newValue);
        return newValue;
      });
    },
    [key],
  );

  const clearValue = useCallback(() => {
    store.remove(key);
    setValueInState(defaultValue);
  }, [key, setValueInState, defaultValue]);

  return [valueInState, setValue, hasValueInStorage, clearValue];
};
