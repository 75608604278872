import {
  Box,
  Checkbox,
  ClickAwayListener,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { IconSearch, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { uniqBy } from 'lodash';
import { type ChangeEvent, type ElementType, memo, type ReactNode, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { useBoolState, useUniqueId } from '@amal-ia/ext/react/hooks';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { useLoadMore } from '../../../../../utils/hooks/useLoadMore';
import { COMMON_MESSAGES } from '../../../../../utils/messages/common.messages';
import { Input } from '../../../formElements/inputs/Input/Input';
import { Text, TextType } from '../../../typography';
import { useUserSelectStyles } from '../common';

import { UserListItem, type UserListItemProps } from './UserListItem';
import { messages } from './UserSelect.messages';

export const USER_SELECT_DIALOG_CLASSNAME = 'user-select-dialog';
export const USER_SELECT_SEARCH_CLASSNAME = 'user-select-search';

export type UserSelectRenderParams<TUser extends UserComputed> = {
  dropdownId: string;
  value: TUser | TUser[] | null;
  onClick: VoidFunction;
  open: boolean;
};

export type UserSelectRender<TUser extends UserComputed> = (params: UserSelectRenderParams<TUser>) => ReactNode;

export { type UserListItemProps } from './UserListItem';

export type UserSelectProps<TUser extends UserComputed = UserComputed> = {
  /** List of users to display. */
  readonly users: TUser[] | null;
  /** If true, disable the select. */
  readonly disabled?: boolean;
  /** If true, the select is centered. */
  readonly centered?: boolean;
  /** Children render function, used to render Select Anchor */
  readonly children?: UserSelectRender<TUser>;
  /** Title of the select. */
  readonly title?: ReactNode;
  /** Options to customize the select. */
  readonly options?: {
    hideSelectAll?: boolean;
    hideUnselectAll?: boolean;
  };
  /** If true, display avatars in the list. */
  readonly withAvatarsInList?: boolean;
  /** If true, give focus to the search input when the select is open. */
  readonly giveFocusSearchOnOpen?: boolean;
  /** Custom item component. */
  readonly itemComponent?: ElementType<UserListItemProps>;

  readonly className?: string;

  readonly onChange: (value: TUser[]) => void;
} & (
  | {
      /** If true, allow multiple selection. */
      multiple: true;
      value: TUser[] | null;
    }
  | {
      multiple?: false;
      value: TUser | null;
    }
);

const UserSelectBase = function UserSelect<TUser extends UserComputed = UserComputed>({
  users,
  onChange,
  multiple,
  value,
  title,
  options,
  children,
  disabled,
  withAvatarsInList,
  centered,
  giveFocusSearchOnOpen,
  itemComponent,
  className,
}: UserSelectProps<TUser>) {
  const dropdownId = useUniqueId({ prefix: 'user-select' });

  const { formatMessage } = useIntl();
  const classes = useUserSelectStyles({ multiple });

  // ============ OPTIONS TOOLTIP CONTROL ============
  const { isOpen, setOpenFalse, toggleOpen } = useBoolState(false, 'open');

  // ============ SEARCH CONTROL ============
  const [search, setSearch] = useState<string>('');

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value), []);

  const usersFiltered = useMemo(() => {
    if (!search) {
      return users;
    }

    return users.filter((user) =>
      `${user.firstName?.toLowerCase()} ${user.lastName?.toLowerCase()}`.includes(search.toLowerCase()),
    );
  }, [users, search]);

  const { elementsCapped: usersCapped, onClickLoadMore, count, total } = useLoadMore(usersFiltered);

  // ============ ON CHANGE ============
  const onChangeProxy = useCallback(
    (user: TUser) => {
      if (multiple) {
        // Change value if multiple.
        const newValue = uniqBy(
          value.find((elm) => elm.id === user.id) ? [...value.filter((elm) => elm.id !== user.id)] : [...value, user],
          'email',
        );
        onChange(newValue);
      } else {
        // Otherwise close the modal then change the value.
        setOpenFalse();
        onChange([user]);
      }
    },
    [multiple, onChange, value, setOpenFalse],
  );

  const onClickClearSearch = useCallback(() => setSearch(''), [setSearch]);

  const toggleSelectAll = useCallback(() => {
    if (users === null) {
      // If we don't have users, we don't do anything.
      return;
    }

    if (multiple && value?.length) {
      // If we have a value, we unselect all.
      onChange([]);

      return;
    }

    // Otherwise, we select all.
    onChange(users);
  }, [multiple, value, users, onChange]);

  const shouldDisplaySelectAll = (() => {
    if (!multiple || !value) {
      return false;
    }

    const areUsersSelected = !!value.length;

    // We hide:
    // - If no users are selected, and we decide to hide the "select all" option.
    // - If users are selected, and we decide to hide the "unselect all" option.
    return !((options?.hideSelectAll && !areUsersSelected) || (options?.hideUnselectAll && areUsersSelected));
  })();

  const UserListItemComponent = itemComponent || UserListItem;

  return (
    <ClickAwayListener onClickAway={setOpenFalse}>
      <Box
        className={className}
        position="relative"
      >
        {children({ dropdownId, onClick: toggleOpen, value, open: isOpen }) ?? null}
        {isOpen && !disabled ? (
          <Box
            className={clsx(classes.dialog, centered && classes.dialog__centered, USER_SELECT_DIALOG_CLASSNAME)}
            id={dropdownId}
          >
            {title !== null && (
              <Grid>
                <span className={classes.dialogTitle}>{title || <FormattedMessage {...messages.FILTER_REP} />}</span>
              </Grid>
            )}
            <Grid>
              <Input
                autoFocus={!!giveFocusSearchOnOpen}
                className={USER_SELECT_SEARCH_CLASSNAME}
                placeholder={formatMessage(COMMON_MESSAGES.SEARCH)}
                startAdornment={<IconSearch />}
                value={search}
                endAdornment={
                  search ? (
                    <InputAdornment position="end">
                      <IconButton
                        icon={<IconX />}
                        label={formatMessage(messages.CLEAR_SEARCH)}
                        onClick={onClickClearSearch}
                      />
                    </InputAdornment>
                  ) : null
                }
                onChange={onSearch}
              />
            </Grid>
            <List
              dense
              className={classes.userList}
              role="listbox"
            >
              {shouldDisplaySelectAll && multiple ? (
                <ListItemButton
                  key="selectUser_all"
                  component="li"
                  onClick={toggleSelectAll}
                >
                  <Checkbox
                    checked={value?.length === users?.length}
                    className={classes.selectCheckbox}
                    color="primary"
                    indeterminate={!!value?.length && value.length !== users?.length}
                    size="small"
                    onChange={toggleSelectAll}
                    onClick={toggleSelectAll}
                  />
                  <ListItemText primary={<FormattedMessage {...COMMON_MESSAGES.SELECT_ALL} />} />
                </ListItemButton>
              ) : null}
              {usersCapped.map((user: TUser) => (
                <UserListItemComponent
                  key={`selectUser_${user.id}`}
                  multiple={multiple}
                  selectUser={onChangeProxy}
                  user={user}
                  value={value ? (Array.isArray(value) ? value : [value]) : null}
                  withAvatarsInList={withAvatarsInList}
                  onChange={onChangeProxy}
                />
              ))}
              {usersCapped.length === 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={amaliaTheme.spacing(1.6)}
                >
                  <Text type={TextType.INFO_MESSAGE}>
                    <FormattedMessage {...messages.NO_USER_MATCHING_THESE_FILTERS} />
                  </Text>
                </Box>
              )}
              {onClickLoadMore ? (
                <ListItemButton
                  component="li"
                  onClick={onClickLoadMore}
                >
                  <i>
                    <FormattedMessage
                      {...COMMON_MESSAGES.LOAD_MORE_PROGRESS}
                      values={{ count, total }}
                    />
                  </i>
                </ListItemButton>
              ) : null}
            </List>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export const UserSelect = memo(UserSelectBase) as typeof UserSelectBase;
