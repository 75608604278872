import { isValid } from 'date-fns';

import { assert } from '@amal-ia/ext/typescript';

export type UnixTimestampInSeconds = number;

/**
 * Get the Unix timestamp (in seconds) of the given date.
 * - Decimal values are rounded.
 * - Number inputs are treated as Unix timestamps.
 */
export const toTimestamp = <TDate extends Date>(v: TDate | UnixTimestampInSeconds): UnixTimestampInSeconds => {
  const timestamp = typeof v === 'number' ? v : Math.round(new Date(v).getTime() / 1000);

  assert(timestamp && Number.isFinite(timestamp), `Invalid date ${v}`);

  return timestamp;
};

/**
 * Create a `Date` from a Unix timestamp (in seconds).
 * - Date inputs are cloned.
 * - Number inputs are treated as Unix timestamp (in seconds).
 */
export const toDate = (v: Date | UnixTimestampInSeconds | string): Date => {
  const date = new Date(typeof v === 'number' ? v * 1000 : v);

  assert(isValid(date), `Invalid timestamp ${v}`);

  return date;
};
