import { useTheme } from '@emotion/react';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type PayoutAndPerformanceChartStatistics } from '@amal-ia/lib-types';

import { type ChartSeries, ChartSeriesType } from './chartSeries';
import { findPayoutAndPerformanceChartStatisticsKpiColumns } from './kpiColumns.finder';
import { payoutAndPerformanceChartMessages } from './PayoutAndPerformanceChart.messages';

/**
 * Property key of the bar data to display.
 */
const AMOUNT_DEFINITION_NAME = 'ruleMetricPayment__value';

const isSeriesHidden = (seriesIdentifier: string, hiddenSeries: string[] = []) =>
  !!seriesIdentifier && !hiddenSeries.includes(seriesIdentifier);

export const useChartSeries = (statistics: PayoutAndPerformanceChartStatistics) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);

  const [kpi1, kpi2] = useMemo(() => findPayoutAndPerformanceChartStatisticsKpiColumns(statistics), [statistics]);

  const seriesMetadata: Record<string, ChartSeries> = useMemo(() => {
    const records = {
      [AMOUNT_DEFINITION_NAME]: {
        label: formatMessage(payoutAndPerformanceChartMessages.COMMISSION),
        name: AMOUNT_DEFINITION_NAME,
        color: theme.ds.hues.blue[900],
        type: ChartSeriesType.BAR,
        selected: isSeriesHidden(AMOUNT_DEFINITION_NAME, hiddenSeries),
        format: FormatsEnum.currency,
      },
    };

    if (kpi1) {
      records[kpi1.identifier] = {
        label: kpi1?.label,
        name: kpi1?.identifier,
        color: theme.ds.colors.primary[300],
        type: ChartSeriesType.LINE,
        selected: isSeriesHidden(kpi1?.identifier, hiddenSeries),
        format: FormatsEnum.percent,
      };
    }

    if (kpi2) {
      records[kpi2.identifier] = {
        label: kpi2?.label,
        name: kpi2?.identifier,
        color: theme.ds.colors.secondary[200],
        type: 'line',
        selected: isSeriesHidden(kpi2?.identifier, hiddenSeries),
        format: FormatsEnum.percent,
      };
    }

    return records;
  }, [formatMessage, theme.ds.hues.blue, theme.ds.colors.primary, theme.ds.colors.secondary, hiddenSeries, kpi1, kpi2]);

  const isKpi1Selected = seriesMetadata[kpi1?.identifier]?.selected;
  const isKpi2Selected = seriesMetadata[kpi2?.identifier]?.selected;
  const isAmountSelected = seriesMetadata[AMOUNT_DEFINITION_NAME]?.selected;

  return {
    seriesMetadata,
    hiddenSeries,
    setHiddenSeries,
    isAmountSelected,
    isKpi1Selected,
    isKpi2Selected,
    kpi1DataKey: kpi1?.identifier,
    kpi2DataKey: kpi2?.identifier,
  };
};
