import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.SEARCH, AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 2;

func.description =
  'Return the position of a Search String inside a Source String starting at 0. Return -1 if Search String is not found.';

func.params = [
  { name: 'searchString', description: 'String to search for: variables, fields, properties, string' },
  { name: 'sourceString', description: 'String to look into: array, variables, fields, properties, string' },
];

func.examples = [
  {
    desc: 'Returns 5.',
    formula: 'SEARCH("Dupont", "Jean Dupont")' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns 3. It takes the position of the first match.',
    formula: 'SEARCH("n", "Jean Dupont")' as AmaliaFormula,
    result: 3,
  },
];

/**
 * Search text within formula.
 * @param findText
 * @param withinText
 */
func.exec = (findText: any, withinText: string): number | null => withinText.search(findText);

export default func;
