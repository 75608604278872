import { css } from '@emotion/react';
import { Container } from '@mui/material';
import { Fragment, type ReactNode, memo } from 'react';

import { FullWidthLoader, Spinner } from '@amal-ia/lib-ui';

export type PageContainerProps = {
  readonly containerMaxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  readonly showLoadingBar?: boolean;
  readonly removeYMargins?: boolean;
  readonly isLoading?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
};

export const PageContainer = memo(function PageContainer({
  containerMaxWidth = undefined,
  showLoadingBar = false,
  removeYMargins = false,
  isLoading = false,
  className = undefined,
  children = undefined,
}: PageContainerProps) {
  return (
    <Fragment>
      <FullWidthLoader show={showLoadingBar} />

      <Container
        className={className}
        maxWidth={containerMaxWidth || false}
        css={[
          css`
            position: relative;
            min-height: 300px;
            font-size: 12px; // Needed for some em sizes in the app (RuleSummary).
          `,
          !containerMaxWidth &&
            css`
              @media (min-width: 2000px) {
                max-width: 1900px;
              }
            `,
          !removeYMargins &&
            css`
              padding-bottom: 40px;
              padding-top: 24px;
            `,
        ]}
      >
        {isLoading ? <Spinner /> : children}
      </Container>
    </Fragment>
  );
});
