import { orderBy } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { type PlansMap } from '@amal-ia/compensation-definition/plans/types';
import { LabelDisplay, UserPrettyFormat } from '@amal-ia/data-capture/fields/components';
import {
  FormLayout,
  FormikRadioButtonGroup,
  Select,
  type RadioButtonOptionShape,
  type SelectOption,
} from '@amal-ia/frontend/design-system/components';
import { type DataExport, type DataExportFormat, formatUserFullName } from '@amal-ia/lib-types';
import { type TeamMap } from '@amal-ia/tenants/teams/types';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { type ExportFormEventChange } from './statementExportModal.types';

interface StatementExportModalFormProps {
  readonly setFieldValue: (field: string, value: any) => void;
  readonly values: Partial<DataExport>;
  readonly isUsersLoading: boolean;
  readonly isPlansLoading: boolean;
  readonly isTeamsLoading: boolean;
  readonly isPeriodsLoading: boolean;
  readonly teamsMap: TeamMap;
  readonly plansMap: PlansMap;
  readonly usersMap: UsersMap;
  readonly periodList: Period[];
  readonly onFormChangeHandler: (event: ExportFormEventChange) => void;
  readonly exportFormats: RadioButtonOptionShape<DataExport['format'], boolean>[];
}

export const StatementExportModalForm = memo(function StatementExportModalForm({
  setFieldValue,
  values,
  isUsersLoading,
  isPlansLoading,
  isTeamsLoading,
  isPeriodsLoading,
  teamsMap,
  plansMap,
  usersMap,
  periodList,
  onFormChangeHandler,
  exportFormats,
}: StatementExportModalFormProps) {
  const { formatMessage } = useIntl();

  // TEAM SELECT
  const teamOptions: SelectOption<string>[] = useMemo(
    () =>
      Object.values(teamsMap)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((elt) => ({
          label: elt.name,
          value: elt.id,
        })),
    [teamsMap],
  );

  const onTeamChange = useCallback(
    (teamIds: string[]) => {
      // Empty users field
      setFieldValue('params.userIds', []);
      setFieldValue('params.teamIds', teamIds);
      onFormChangeHandler({
        eventType: 'TEAM_CHANGE',
        periodIds: values.params?.periodIds || [],
        planIds: values.params?.planIds || [],
        teamIds,
        userIds: values.params?.userIds || [],
      });
    },
    [setFieldValue, onFormChangeHandler, values.params],
  );

  // PLAN SELECT
  const planOptions: SelectOption<string>[] = useMemo(
    () =>
      Object.values(plansMap)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((elt) => ({
          label: elt.name,
          value: elt.id,
        })),
    [plansMap],
  );

  const onPlanChange = useCallback(
    (planIds: string[]) => {
      // Empty users field
      setFieldValue('params.userIds', []);
      setFieldValue('params.planIds', planIds);
      onFormChangeHandler({
        eventType: 'PLAN_CHANGE',
        periodIds: values.params?.periodIds || [],
        planIds,
        teamIds: values.params?.teamIds || [],
        userIds: values.params?.userIds || [],
      });
    },
    [setFieldValue, onFormChangeHandler, values.params],
  );

  // PERIODS SELECT
  const periodOptions: SelectOption<string>[] = useMemo(
    () =>
      orderBy(periodList || [], 'startDate').map((elt) => ({
        label: elt.name,
        value: elt.id,
      })),
    [periodList],
  );

  const onPeriodsChange = useCallback(
    (periodIds: string[]) => {
      setFieldValue('params.periodIds', periodIds);
      onFormChangeHandler({
        eventType: 'PERIOD_CHANGE',
        periodIds,
        planIds: values.params?.planIds || [],
        teamIds: values.params?.teamIds || [],
        userIds: values.params?.userIds || [],
      });
    },
    [setFieldValue, onFormChangeHandler, values.params],
  );

  const userOptions: SelectOption<string>[] = useMemo(
    () =>
      Object.values(usersMap)
        .sort((a, b) => (a.firstName || '').localeCompare(b.firstName || ''))
        .map((user) => ({
          label: (
            <UserPrettyFormat
              display={LabelDisplay.INLINE}
              firstName={user.firstName}
              lastName={user.lastName}
              pictureURL={user.pictureURL}
            />
          ),
          filterLabel: formatUserFullName(user),
          value: user.id,
        })),
    [usersMap],
  );

  const onUserChange = useCallback(
    (userIds: string[]) => {
      setFieldValue('params.userIds', userIds);
      onFormChangeHandler({
        eventType: 'USER_CHANGE',
        periodIds: values.params?.periodIds || [],
        planIds: values.params?.planIds || [],
        teamIds: values.params?.teamIds || [],
        userIds,
      });
    },
    [setFieldValue, onFormChangeHandler, values.params],
  );

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select
          isMultiple
          disabled={isPeriodsLoading}
          label={<FormattedMessage defaultMessage="Periods" />}
          options={periodOptions}
          placeholder={formatMessage({ defaultMessage: 'Please select a period' })}
          value={values.params?.periodIds}
          onChange={onPeriodsChange}
        />

        <FormLayout.Row>
          <Select
            isMultiple
            disabled={isTeamsLoading}
            label={<FormattedMessage defaultMessage="Teams" />}
            options={teamOptions}
            placeholder={formatMessage({ defaultMessage: 'All teams' })}
            value={values.params?.teamIds}
            onChange={onTeamChange}
          />

          <Select
            isMultiple
            disabled={isPlansLoading}
            label={<FormattedMessage defaultMessage="Plans" />}
            options={planOptions}
            placeholder={formatMessage({ defaultMessage: 'All plans' })}
            value={values.params?.planIds}
            onChange={onPlanChange}
          />
        </FormLayout.Row>

        <Select
          isMultiple
          disabled={isUsersLoading}
          label={<FormattedMessage defaultMessage="Sales reps" />}
          options={userOptions}
          placeholder={formatMessage({ defaultMessage: 'All sales reps' })}
          value={values.params?.userIds}
          onChange={onUserChange}
        />

        <FormikRadioButtonGroup<DataExportFormat>
          label={<FormattedMessage defaultMessage="Export format" />}
          name="format"
          options={exportFormats}
        />
      </FormLayout.Group>
    </FormLayout>
  );
});
