import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { assert } from '@amal-ia/ext/typescript';
import { CompaniesApiClient } from '@amal-ia/tenants/companies/shared/api-client';

import { COMPANIES_QUERY_KEYS } from './queries.keys';

export const useCompany = ({ enabled }: { enabled?: boolean } = {}) =>
  useQuery({
    queryKey: [COMPANIES_QUERY_KEYS.CURRENT_COMPANY],
    queryFn: CompaniesApiClient.getCompany,
    refetchOnWindowFocus: false,
    enabled,
  });

export const useCurrentCompany = () => {
  const { data, ...rest } = useCompany();

  assert(data, 'useCurrentCompany: data should be defined');

  return {
    data,
    ...rest,
  };
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [COMPANIES_QUERY_KEYS.CURRENT_COMPANY],
    mutationFn: (params: Parameters<typeof CompaniesApiClient.updateCompany>) =>
      CompaniesApiClient.updateCompany(...params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [COMPANIES_QUERY_KEYS.CURRENT_COMPANY],
      });
    },
  });
};
