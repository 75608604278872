import { type Period, PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import {
  type BadgeAward,
  type HighlightedKpi,
  type Plan,
  type RuleType,
} from '@amal-ia/compensation-definition/plans/types';
import { type FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type TeamAssignment } from '@amal-ia/tenants/assignments/teams/types';
import { type Company } from '@amal-ia/tenants/companies/types';
import { type AssignmentType, type MatchScope } from '@amal-ia/tenants/teams/types';
import { type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { type CalculationType } from './calculation';
import { type ComputedStatement, type ComputedStatementSummary } from './computedStatement';
import { type Option, type TeamOption } from './options';
import { type PaymentCategory } from './payments';
import { type StatementForecast } from './statementForecast';
import { type Statistics } from './statistics';
import { type PlanOption } from './userStatements';
import { type WorkflowStatementState } from './workflow';

export const StatementFrequency = {
  [PeriodFrequencyEnum.month]: PeriodFrequencyEnum.month,
  [PeriodFrequencyEnum.quarter]: PeriodFrequencyEnum.quarter,
};

export interface Adjustment {
  id?: string;
  name: string;
  paymentPeriodId: string;
  description?: string;
  amount: number;
  rowDefinitionId?: string;
  rowExternalId?: string;
  rowName?: string;
}

export enum StatementErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum StatementErrorType {
  CALCULATION = 'CALCULATION',
  CONFIGURATION = 'CONFIGURATION',
  PAYMENT = 'PAYMENT',
}

export interface StatementError {
  message: string;
  type: StatementErrorType;
  level: StatementErrorLevel;
  stack?: string;
  context?: { variableId?: string; variableName?: string };
}

export interface Statement {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;

  workflowComplete: boolean;
  workflowSteps?: WorkflowStatementState[];
  workflowCompletedDate?: Date;
  workflowUpdatedAt?: Date;

  badgeAwards?: BadgeAward[];

  results: ComputedStatement;
  resultSummary?: ComputedStatementSummary;
  statistics?: Statistics;
  forecast?: Pick<StatementForecast, 'currency' | 'error' | 'rate' | 'results' | 'total'> & {
    resultSummary?: ComputedStatementSummary;
  };
  forecastId?: string;
  lastCalculationDate?: Date;

  userId?: string;
  user: UserComputed;
  error?: StatementError | null;
  currency: CurrencySymbolsEnum;
  rate: number;
  total: number;

  adjustments?: Adjustment[];
  plan: Plan;
  period: Period;
  company?: Company;
}

export interface StatementResponse {
  id: string;

  badgeAwards?: BadgeAward[];

  updatedAt: Date;

  workflowComplete: boolean;

  workflowCompletedDate: Date;

  error?: StatementError;

  currency: CurrencySymbolsEnum;

  rate: number;

  total: number;

  adjustments: Adjustment[];

  user: {
    id: string;

    email: string;

    firstName: string;

    lastName: string;

    pictureURL: string;
  };

  company: {
    id: string;

    name: string;
  };

  plan: {
    id: string;
    name: string;
    highlightedKpis: HighlightedKpi[];
    forecastId?: string;
    frequency: PeriodFrequencyEnum;
    isSimulationEnabled?: boolean;
    isSimulationAddRecordEnabled?: boolean;
    isForecasted?: boolean;
    workflowId?: string;
    linkToPlanDocumentation?: string;
  };

  period: {
    id: string;

    name: string;

    startDate: number;

    endDate: number;
  };

  results: ComputedStatement;

  resultSummary: ComputedStatementSummary;

  workflowSteps: WorkflowStatementState[];

  workflowUpdatedAt: Date;

  forecastId: string;

  forecast?: Pick<StatementForecast, 'currency' | 'error' | 'rate' | 'results' | 'total'>;

  lastCalculationDate?: Date;
}

export interface StatementSummary {
  period: string;
  year: string;
  id?: string;
  total: {
    value: number;
    formattedValue: string;
    currencySymbol: CurrencySymbolsEnum;
    currencyRate?: number;
    format: FormatsEnum;
  };
}

export interface StatementFilters {
  plans?: Option[];
  users?: Option[];
  periods?: Option[];
  paymentPeriods?: Option[];
  rules?: Option[];
  ruleTypes?: Option[];
  teams?: Option[];
  currencies?: Option[];
}

export interface CreateStatementOverwriteRequest {
  readonly ruleId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly overwriteValue: number | object | string | null;

  readonly field: string;

  readonly variableId: string;
}

export interface CreateDatasetOverwriteRequest {
  readonly scope: OverwriteScopeEnum;
  readonly overwriteValue: number | object | string | null;
  readonly rowExternalId?: string;
  readonly definitionId?: string;
  readonly field: string;
  readonly filterId?: string;
  readonly calculationType: CalculationType;
  readonly variableId: string;
}

export interface DeleteDatasetOverwriteRequest {
  readonly filterId: string;

  readonly definitionId?: string;

  readonly scope: OverwriteScopeEnum;

  readonly calculationType;
}

export interface AddDatasetRowsRequest {
  readonly customObjectIds: string[];
}

export interface CurrencyAmount {
  amount: number;
  currencySymbol: CurrencySymbolsEnum;
  currencyRate?: number;
}

export interface Kpi {
  label?: string;
  value: CurrencyAmount | number | null;
}

export interface MainKpi extends Kpi {
  type?: PaymentCategory | RuleType;
  hideTotal?: boolean;
  isSubMainKpi?: boolean;
  isForecastedView?: boolean;
  overrideFormattedValue?: string;
  formattedForecastedValue?: number | string;
  formattedOriginalValue?: number | string;
  isValueForecasted?: boolean;
}

export type AssignmentListScope = {
  [AssignmentType.MINE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.EMPLOYEE]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MANAGER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
  [AssignmentType.MEMBER]: {
    [MatchScope.ALL]: Record<string, TeamAssignment[]>;
    [MatchScope.SUB]: Record<string, TeamAssignment[]>;
  };
};

export type ListSelectedOptions = { team?: TeamOption; plan?: PlanOption };
