import { createContext, type Dispatch, type SetStateAction, useContext } from 'react';

import { assert } from '@amal-ia/ext/typescript';
import { type MessageContent } from '@amal-ia/payout-collaboration/comments/shared/types';

export type EditableStatementCommentContextValue = {
  statementThreadId: string | null;
  editedCommentId: string | null;
  editedCommentContent: MessageContent[];
  setEditedCommentId: Dispatch<SetStateAction<string | null>>;
  setEditedCommentContent: Dispatch<SetStateAction<MessageContent[]>>;
};

export const EditableStatementCommentContext = createContext<EditableStatementCommentContextValue | null>(null);

export const useEditableStatementCommentContext = (): EditableStatementCommentContextValue => {
  const context = useContext(EditableStatementCommentContext);
  assert(
    context,
    'useEditableStatementCommentContext must be used within a <EditableStatementCommentContext.Provider>',
  );
  return context;
};
