import { Menu, MenuItem } from '@mui/material';
import { memo, type MouseEvent, type ReactNode } from 'react';

import { Link } from '@amal-ia/ext/react-router-dom';
import { type DesignerActionToRender } from '@amal-ia/lib-types';

export interface ActionToRender {
  label: string;
  callback: (event?: any) => any;
  children: ReactNode;
  disabled?: boolean;
  linkTo?: string;
}

export interface ContextMenuProps {
  readonly anchorEl: HTMLElement | null;
  readonly handleCloseMenu: (event: MouseEvent<HTMLElement>) => any;
  readonly actionsToRender: ActionToRender[] | DesignerActionToRender[];
  readonly isOpen: boolean;
  readonly renderPosition?: {
    anchorVertical: 'bottom' | 'center' | 'top';
    transformHorizontal: 'center' | 'left' | 'right';
  };
  readonly callbackParam?: string;
}

export const ContextMenu = memo(function ContextMenu({
  anchorEl,
  handleCloseMenu,
  actionsToRender,
  isOpen,
  renderPosition,
  callbackParam = undefined,
}: ContextMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      anchorOrigin={{
        vertical: renderPosition ? renderPosition.anchorVertical : 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: renderPosition ? renderPosition.transformHorizontal : 'center',
      }}
      onClose={handleCloseMenu}
    >
      {actionsToRender.map(({ children, label, callback, disabled = false, linkTo = undefined }) => {
        if (linkTo) {
          return (
            <Link
              key={label}
              style={{ color: 'unset', textDecoration: 'none' }}
              to={linkTo}
            >
              <MenuItem
                aria-label={label}
                disabled={disabled}
              >
                {children}
              </MenuItem>
            </Link>
          );
        }
        return (
          <MenuItem
            key={label}
            aria-label={label}
            disabled={disabled}
            onClick={(event: MouseEvent<HTMLElement>) => {
              handleCloseMenu(event);
              if (callbackParam) {
                callback(event, callbackParam);
              } else {
                callback(event);
              }
            }}
          >
            {children}
          </MenuItem>
        );
      })}
    </Menu>
  );
});
