import { memo } from 'react';

import { DataGrid, useControlledDataGridData, useDataGridState } from '@amal-ia/frontend/design-system/components';
import { type WorkflowDefinition } from '@amal-ia/lib-types';

import { useWorkflowListContext } from '../WorkflowList/useWorkflowListContext';

import { useWorkflowDataGridColumns } from './useWorkflowDataGridColumns';

export type WorkflowDataGridProps = {
  readonly workflows: WorkflowDefinition[];
};

export const WorkflowDataGrid = memo(function WorkflowDataGrid({ workflows }: WorkflowDataGridProps) {
  const { actions, setWorkflowToDelete } = useWorkflowListContext();

  const columns = useWorkflowDataGridColumns({
    onClickEdit: actions.onClickEdit,
    onClickDuplicate: actions.onClickDuplicate,
    onClickDelete: setWorkflowToDelete,
  });

  const { searchText, setSearchText } = useDataGridState();
  const { data: controlledData } = useControlledDataGridData(columns, workflows, { searchText });

  return (
    <DataGrid
      columns={columns}
      data={controlledData}
      rowKey="id"
      search={
        <DataGrid.Search
          value={searchText}
          onChange={setSearchText}
        />
      }
    />
  );
});
