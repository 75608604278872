import clsx from 'clsx';
import { noop } from 'lodash';
import { type CSSProperties, type ReactNode } from 'react';

import { AmaliaFunction } from '@amal-ia/amalia-lang/formula/evaluate';
import { type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { type Relationship, type Filter } from '@amal-ia/compensation-definition/plans/types';
import { type Property } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type TokenType } from '@amal-ia/lib-types';

export enum FormulaNodeEnum {
  OBJECT_FIELD = 'OBJECT_FIELD',
  FILTER = 'FILTER',
  VARIABLE = 'VARIABLE',
  RELATIONSHIP = 'RELATIONSHIP',
  FUNCTION = 'FUNCTION',
  KEYWORD = 'KEYWORD',
  CONSTANT = 'CONSTANT',
  OPERATOR = 'OPERATOR',
}

const FunctionsEnum = AmaliaFunction.getFunctionsEnum();

export type FormulaNodeObject = Filter | Property | Relationship | Variable | string | keyof typeof FunctionsEnum;

export abstract class IFormulaNode {
  protected readonly partialFormula: string;

  protected readonly startIndex: number;

  private readonly type: FormulaNodeEnum;

  protected readonly relationship?: Relationship;

  protected readonly objectDefinition?: CustomObjectDefinition;

  protected readonly nodeObject: FormulaNodeObject;

  public constructor(
    partialFormula: string,
    startIndex: number,
    type: FormulaNodeEnum,
    nodeObject: FormulaNodeObject,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    this.partialFormula = partialFormula;
    this.startIndex = startIndex;
    this.type = type;
    this.nodeObject = nodeObject;
    this.objectDefinition = objectDefinition;
    this.relationship = relationship;
  }

  public getIndices = (): { start: number; end: number; length: number } => ({
    start: this.startIndex,
    end: this.startIndex + this.partialFormula.length,
    length: this.partialFormula.length,
  });

  public abstract getStyles(): CSSProperties;

  public abstract getLabel(): string;

  public getDesignerPath(): { type: TokenType; id: string; definitionMachinename?: string } | null {
    return null;
  }

  public readonly toHTML = (
    classNames?: { chip?: string; clickable?: string },
    onClickDesignerElement?: (type: TokenType, id: string, definitionMachinename?: string) => void,
  ): ReactNode => {
    const designerPath = this.getDesignerPath();
    const onClickCallback =
      designerPath && onClickDesignerElement && designerPath.id
        ? () => onClickDesignerElement(designerPath.type, designerPath.id, designerPath.definitionMachinename)
        : noop;
    return (
      <button
        key={`${this.partialFormula}_${this.startIndex}`}
        className={clsx(classNames?.chip, onClickCallback !== noop && classNames?.clickable)}
        data-node-type={this.type}
        style={this.getStyles()}
        title={this.partialFormula}
        type="button"
        onClick={onClickCallback}
      >
        {this.getLabel()}
      </button>
    );
  };
}
