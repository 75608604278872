import { IconArrowLeft } from '@tabler/icons-react';
import { noop } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FormulaBuilderFunctionBlockStringForm,
  isFormulaBuilderFunctionBlockStringOneArgForm,
} from '@amal-ia/amalia-lang/formula/form';
import { FORMULA_KEYWORDS } from '@amal-ia/amalia-lang/formula/shared/keywords';
import {
  FormulaBuilderStringOperatorOneArg,
  FormulaBuilderStringOperatorNoArgs,
  FormulaKeyword,
  ValueOrAttributeType,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { ComponentSwitch } from '@amal-ia/ext/react/components';
import { assert, isEnum } from '@amal-ia/ext/typescript';
import { Typography, IconButton, Switch, type SwitchProps, Dropdown } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import {
  type UseAttributesOptionsOptions,
  useAttributesOptions,
  isQuotaOrVariableTokenType,
  isFieldOrPropertyTokenType,
} from '../../../hooks/use-attributes-options/useAttributesOptions';
import { useGetFormulaBuilderAttributeLabel } from '../../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useValueOrAttributeOptions } from '../../../hooks/use-value-or-attribute-options/useValueOrAttributeOptions';
import { useFormulaBuilderContext } from '../../formula-builder/FormulaBuilder.context';
import { type FormulaConditionTagProps } from '../../formula-condition-tag/FormulaConditionTag';
import { PopoverValueOrAttributeSelector } from '../../popover-value-or-attribute-selector/PopoverValueOrAttributeSelector';
import { useValueOrAttributeHandlers } from '../../popover-value-or-attribute-selector/use-value-or-attribute-handlers/useValueOrAttributeHandlers';

import {
  fieldOperatorMessages,
  fieldOperatorNegativeMessages,
  functionStringPopoverMessages,
  operatorMessages,
  operatorNegativeMessages,
} from './FunctionStringPopover.messages';
import * as styles from './FunctionStringPopover.styles';
import { NEGATIVE_OPERATOR_ICON_MAPPING, OPERATOR_ICON_MAPPING } from './operators.mappers';

enum FunctionStringPopoverStep {
  OPERATOR = 'OPERATOR',
  OTHER = 'OTHER',
}

export type FunctionStringPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockStringForm;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockStringForm>['onChange'];
  readonly onClose?: () => void;
};

export const FunctionStringPopover = memo(function FunctionStringPopover({
  condition,
  onChange = noop,
  onClose = noop,
}: FunctionStringPopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();

  const STRING_ATTRIBUTES_OPTIONS_FILTERS: UseAttributesOptionsOptions = useMemo(
    () => ({
      filterProperty: (property) =>
        property.format === FormatsEnum.text &&
        (!isFieldOrPropertyTokenType(condition.args[0]) ||
          property.machineName !== condition.args[0].propertyMachineName),
      filterVariable: (variable) =>
        variable.format === FormatsEnum.text &&
        (isFieldOrPropertyTokenType(condition.args[0])
          ? variable.machineName !== condition.args[0].propertyMachineName
          : isQuotaOrVariableTokenType(condition.args[0])
            ? variable.machineName !== condition.args[0].machineName
            : true),
      keywords: Object.values(FormulaKeyword).filter((key) => FORMULA_KEYWORDS[key].format === FormatsEnum.text),
      showFilteredAsDisabled: false,
    }),
    [condition.args],
  );

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const valueOrAttributeOptions = useValueOrAttributeOptions(propertyName, customObjectDefinition?.name);

  const [step, setStep] = useState<FunctionStringPopoverStep>(
    condition.isDraft || !condition.operator || isEnum(condition.operator, FormulaBuilderStringOperatorNoArgs)
      ? FunctionStringPopoverStep.OPERATOR
      : FunctionStringPopoverStep.OTHER,
  );

  const handleGoToOperatorStep = useCallback(() => setStep(FunctionStringPopoverStep.OPERATOR), []);

  const handleChangeOperator = useCallback(
    (operator: FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg) => {
      const args = isEnum(operator, FormulaBuilderStringOperatorOneArg)
        ? [condition.args[0], { type: ValueOrAttributeType.ATTRIBUTE }]
        : [condition.args[0]];

      onChange({
        ...condition,
        operator,
        args,
        ...(isEnum(operator, FormulaBuilderStringOperatorNoArgs) && {
          isDraft: false, // If string function doesn't need a second arg, then remove draft
        }),
      } as FormulaBuilderFunctionBlockStringForm);

      // If string function needs a second arg, then go to next step.
      // If string function doesn't need a second arg, then close popover.
      if (isEnum(operator, FormulaBuilderStringOperatorOneArg)) {
        setStep(FunctionStringPopoverStep.OTHER);
      } else {
        onClose();
      }
    },
    [onChange, condition, onClose],
  );

  const handleChangeCaseSensitive: Required<SwitchProps>['onChange'] = useCallback(
    (caseSensitive: boolean) => {
      assert(isFormulaBuilderFunctionBlockStringOneArgForm(condition), '');
      onChange({
        ...condition,
        caseSensitive,
      });
    },
    [onChange, condition],
  );

  const handleChangeNot: Required<SwitchProps>['onChange'] = useCallback(
    (not) =>
      onChange({
        ...condition,
        not,
      }),
    [onChange, condition],
  );

  const { handleChangeValueOrAttribute, handleChangeManualValue, handleChangeAttributeValue } =
    useValueOrAttributeHandlers({ condition, onChange });

  const attributesOptions = useAttributesOptions(STRING_ATTRIBUTES_OPTIONS_FILTERS);

  return (
    <div>
      <ComponentSwitch value={step}>
        <ComponentSwitch.Item value={FunctionStringPopoverStep.OPERATOR}>
          <Dropdown.Title>{propertyName}</Dropdown.Title>

          <Dropdown.Divider />

          <Switch
            checked={condition.not}
            label={<FormattedMessage {...functionStringPopoverMessages.INVERT_CONDITIONS} />}
            size={Switch.Size.SMALL}
            tooltip={
              condition.not ? (
                <FormattedMessage {...functionStringPopoverMessages.INVERT_CONDITIONS_POSITIVE_TOOLTIP} />
              ) : (
                <FormattedMessage {...functionStringPopoverMessages.INVERT_CONDITIONS_NEGATIVE_TOOLTIP} />
              )
            }
            onChange={handleChangeNot}
          />

          <Dropdown.Divider />

          {Object.values({
            ...FormulaBuilderStringOperatorNoArgs,
            ...FormulaBuilderStringOperatorOneArg,
          }).map((operator) => (
            <Dropdown.ItemOption
              key={operator}
              checked={condition.operator === operator}
              icon={condition.not ? NEGATIVE_OPERATOR_ICON_MAPPING[operator] : OPERATOR_ICON_MAPPING[operator]}
              label={<FormattedMessage {...(condition.not ? operatorNegativeMessages : operatorMessages)[operator]} />}
              onClick={() => handleChangeOperator(operator)}
            />
          ))}
        </ComponentSwitch.Item>

        {isFormulaBuilderFunctionBlockStringOneArgForm(condition) && (
          <ComponentSwitch.Item value={FunctionStringPopoverStep.OTHER}>
            <div css={styles.otherStepPopover}>
              <div css={styles.headerContainer}>
                <IconButton
                  icon={<IconArrowLeft />}
                  label={<FormattedMessage {...functionStringPopoverMessages.BACK_TO_OPERATOR} />}
                  onClick={handleGoToOperatorStep}
                />
                {!!condition.operator && (
                  <Typography variant={TypographyVariant.BODY_SMALL_BOLD}>
                    <FormattedMessage
                      {...(condition.not ? fieldOperatorNegativeMessages : fieldOperatorMessages)[condition.operator]}
                      values={{ propertyName }}
                    />
                  </Typography>
                )}
              </div>

              <div css={styles.form}>
                <PopoverValueOrAttributeSelector
                  arg={condition.args[1]}
                  attributesOptions={attributesOptions}
                  conditionCategory={condition.category}
                  valueOrAttributeOptions={valueOrAttributeOptions}
                  onChangeAttributeValue={handleChangeAttributeValue}
                  onChangeManualValue={handleChangeManualValue}
                  onChangeValueOrAttribute={handleChangeValueOrAttribute}
                />

                <Switch
                  checked={condition.caseSensitive}
                  label={<FormattedMessage {...functionStringPopoverMessages.CASE_SENSITIVE} />}
                  size={Switch.Size.SMALL}
                  tooltip={<FormattedMessage {...functionStringPopoverMessages.CASE_SENSITIVE_TOOLTIP} />}
                  onChange={handleChangeCaseSensitive}
                />
              </div>
            </div>
          </ComponentSwitch.Item>
        )}
      </ComponentSwitch>
    </div>
  );
});
