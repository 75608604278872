import { type Filter } from '@amal-ia/compensation-definition/plans/types';
import { http } from '@amal-ia/frontend/kernel/http';

export class FiltersApiClient {
  public static async create(
    filter: Partial<Filter>,
    createdFrom?: { ruleId?: string; planId?: string },
  ): Promise<Filter> {
    const response = await http.post<Filter>('/filters', filter, {
      params: {
        planId: createdFrom?.planId,
        ruleId: createdFrom?.ruleId,
      },
    });
    return response.data;
  }

  public static async patch(filter: Partial<Filter>): Promise<Filter> {
    const response = await http.patch<Filter>(`/filters/${filter.id}`, filter);
    return response.data;
  }

  public static async delete(filterId: string): Promise<void> {
    await http.delete(`/filters/${filterId}`);
  }

  public static async list(): Promise<Filter[]> {
    const { data } = await http.get<Filter[]>('/filters');
    return data;
  }

  public static async duplicateInNewContext(
    filtersId: string[],
    from: { planId: string; ruleId: string },
  ): Promise<{ isErrorWhileDuplicating: boolean }> {
    const { data } = await http.post<{ isErrorWhileDuplicating: boolean }>('/filters/duplicateInContext', filtersId, {
      params: {
        planId: from.planId,
        ruleId: from.ruleId,
      },
    });
    return data;
  }
}
