import moment from 'moment';

import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { TokenType } from '@amal-ia/lib-types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.monthNumber, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the month in number format of a given date';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns 9',
    formula: 'monthNumber(toDate("2023-09-16"))' as AmaliaFormula,
    result: 9,
  },
  {
    desc: 'Returns the month in number format of Close Date.',
    formula: 'monthNumber(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null => moment(date, 'X').month() + 1;

export default func;
