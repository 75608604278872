import { useSelector } from 'react-redux';

import { type VariableDefinition } from '@amal-ia/amalia-lang/tokens/types';
import { type PlanRule, RuleType } from '@amal-ia/compensation-definition/plans/types';
import { selectStatementPaymentForExternalIdAndRuleId } from '@amal-ia/frontend/web-data-layers';
import { PaymentButtonStatus, type DatasetRow, type Payment, type Statement } from '@amal-ia/lib-types';

const getPaymentButtonStatus = ({
  ruleDefinition,
  payment,
  statement,
}: {
  ruleDefinition?: PlanRule;
  payment?: Payment;
  statement?: Statement;
}) => {
  // Show payments only IF user has right to see payments, the rule is Hold & Release and
  // the current column is the commission variable of ONE of HnR rules
  if (!payment || !ruleDefinition) {
    return PaymentButtonStatus.none;
  }

  if (
    !payment.paymentPeriodId ||
    !payment.paymentPeriod ||
    payment.paymentPeriod.startDate > statement?.period.startDate
  ) {
    return PaymentButtonStatus.unpaid;
  }

  return PaymentButtonStatus.paid;
};

export const useDatasetRecordsDataGridCellPayment = ({
  datasetRow,
  statement,
  variableDefinition,
}: {
  datasetRow: DatasetRow;
  statement?: Statement;
  variableDefinition?: VariableDefinition;
}) => {
  const hnrRuleDefinition = statement?.results.definitions.plan.rules.find(
    (ruleDefinition) =>
      ruleDefinition.type === RuleType.HOLD_AND_RELEASE &&
      ruleDefinition.commissionVariableId === variableDefinition?.id,
  );

  const payment = useSelector((state) =>
    selectStatementPaymentForExternalIdAndRuleId(state, {
      ruleId: hnrRuleDefinition?.id,
      externalId: datasetRow.externalId,
    }),
  );

  const paymentButtonStatus = getPaymentButtonStatus({
    ruleDefinition: hnrRuleDefinition,
    payment,
    statement,
  });

  return {
    isPaymentEnabled: paymentButtonStatus !== PaymentButtonStatus.none,
    paymentButtonStatus,
    payment,
  };
};
