import { type EditorState } from '@tiptap/pm/state';
import { createContext, useContext } from 'react';

import { assert } from '@amal-ia/ext/typescript';

import { type FormulaEditorToken } from '../../../types/formulaEditorToken';

export type FormulaEditorContextValue = {
  /** List of all the available tokens in the formula, scoped to the current plan. */
  planTokens: Record<FormulaEditorToken['formula'], FormulaEditorToken>;
  onTokenClick: (token: FormulaEditorToken) => void;
  activeNode?: { node: EditorState['doc']; position: number };
};

export const FormulaEditorContext = createContext<FormulaEditorContextValue | null>(null);

export const useFormulaEditorContext = (): FormulaEditorContextValue => {
  const context = useContext(FormulaEditorContext);
  assert(context, 'useFormulaEditorContext must be used within a <FormulaEditorContext.Provider>');
  return context;
};
