import { css } from '@emotion/react';
import { IconInfoCircle } from '@tabler/icons-react';
import { memo, type ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { IconButton, Popover } from '@amal-ia/frontend/design-system/components';

type FunctionTokenPopoverPros = {
  readonly content: string;
  readonly children: ReactElement;
  readonly onClick: () => void;
  readonly isOpen?: boolean;
};

export const FunctionTokenPopover = memo(function FunctionTokenPopover({
  content,
  children,
  isOpen,
  onClick,
}: FunctionTokenPopoverPros) {
  const { formatMessage } = useIntl();

  return (
    <Popover
      autoFocus={false}
      distance={20}
      initialFocus={-1}
      isOpen={isOpen}
      placement="bottom-start"
      content={
        <Popover.Layout
          css={css`
            min-height: 48px;
            min-width: 360px;
          `}
        >
          <Popover.Body
            css={css`
              padding: 8px 16px;
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px;
              `}
            >
              <div>{content}</div>
              <div>
                <IconButton
                  icon={<IconInfoCircle />}
                  label={formatMessage({ defaultMessage: 'Open function documentation' })}
                  onClick={onClick}
                />
              </div>
            </div>
          </Popover.Body>
        </Popover.Layout>
      }
    >
      {children}
    </Popover>
  );
});
