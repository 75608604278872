import { memo } from 'react';
import { useIntl } from 'react-intl';

import { RelativePeriodKeyword } from '@amal-ia/compensation-definition/periods/types';
import { RadioButtonGroup } from '@amal-ia/frontend/design-system/components';

import { type PayoutMode } from '../../types/payoutMode';

import { payoutModeButtonMessages } from './PayoutModeButton.messages';

type PayoutModeButtonProps = Readonly<{
  payoutMode: PayoutMode;
  setPayoutMode: (payoutMode: PayoutMode) => void;
}>;

export const PayoutModeButton = memo(function PayoutModeButton({ payoutMode, setPayoutMode }: PayoutModeButtonProps) {
  const { formatMessage } = useIntl();
  return (
    <RadioButtonGroup
      name="switch-payout-mode"
      value={payoutMode}
      options={[
        {
          value: RelativePeriodKeyword.LAST_12_MONTHS,
          label: formatMessage(payoutModeButtonMessages.LAST_YEAR),
        },
        {
          value: RelativePeriodKeyword.YEAR_TO_DATE,
          label: formatMessage(payoutModeButtonMessages.CURRENT_YEAR),
        },
      ]}
      onChange={(newValue) => setPayoutMode(newValue)}
    />
  );
});
