import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, subject, SubjectsEnum } from '@amal-ia/lib-rbac';

const PlanAssignmentsContainer = lazy(() => import('./Assignments/PlanAssignmentsContainer'));
const PlanListContainer = lazy(() => import('./Overview/PlanListContainer'));

export const PlansRoutes = memo(function PlansRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="plans">
      <RootRoutes>
        <Route
          path={routes.PLAN_ASSIGNMENT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Plan_Assignment)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan assignments' })} />
              <PlanAssignmentsContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.PLANS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, subject(SubjectsEnum.Plan, {}))}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plans' })} />
              <PlanListContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
