import clsx from 'clsx';
import {
  type ComponentPropsWithoutRef,
  type ElementType,
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
} from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { BorderRadiusVariant } from '@amal-ia/frontend/design-system/meta';

import * as styles from './Skeleton.styles';

export type SkeletonProps = MergeAll<
  [
    Omit<ComponentPropsWithoutRef<'div'>, 'aria-hidden'>,
    {
      /** Override the default element for the variant. */
      as?: ElementType;
      /** Skeleton shape. */
      shape?: BorderRadiusVariant;
      /** Is the skeleton visible? (Defaults to true). */
      visible?: boolean;
      /** Skeleton content. */
      children?: ReactNode;
    },
  ]
>;

const SkeletonForwardRef = forwardRef(function Skeleton(
  {
    visible = true,
    children = null,
    as: SkeletonComponent = 'div',
    shape = BorderRadiusVariant.SQUARED,
    className,
    ...props
  }: SkeletonProps,
  ref: ForwardedRef<HTMLElement>,
) {
  return (
    <SkeletonComponent
      data-testid="skeleton"
      {...props}
      ref={ref}
      aria-hidden={visible}
      className={clsx(className, shape, { [styles.IS_VISIBLE_CLASSNAME]: visible })}
      css={styles.skeleton(shape)}
    >
      {children}
    </SkeletonComponent>
  );
});

export const Skeleton = Object.assign(memo(SkeletonForwardRef), {
  Shape: BorderRadiusVariant,
});
