import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  type ComponentPropsWithoutRef,
  type ReactElement,
  type JSXElementConstructor,
} from 'react';

import { useTextOverflows } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { type GrabHandleProps } from '../../../general/grab-handle/GrabHandle';
import { type TablerIconElement } from '../../../general/icons/types';
import { Tooltip } from '../../tooltip/Tooltip';

import { DropdownItemContentActions } from './dropdown-item-content-actions/DropdownItemContentActions';
import { DropdownItemIcon } from './dropdown-item-icon/DropdownItemIcon';
import { DropdownItemLabel } from './dropdown-item-label/DropdownItemLabel';
import { DropdownItemSecondaryLabel } from './dropdown-item-secondary-label/DropdownItemSecondaryLabel';
import { DropdownItemTooltip } from './dropdown-item-tooltip/DropdownItemTooltip';
import * as styles from './DropdownItemContent.styles';

export type DropdownItemContentProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Item label. */
      label: ReactNode;
      /** Optional secondary label (e.g. property source). */
      secondaryLabel?: ReactNode;
      /** Help tooltip content. */
      tooltip?: ReactNode;
      /** Is the entire option interactive (to differentiate with items with actions inside the item). */
      isInteractive?: boolean;
      /** Optional icon. */
      icon?: TablerIconElement;
      /** Is the option disabled. */
      disabled?: boolean;
      /** Is the option visually active. */
      isActive?: boolean;
      /** Optional actions on the right side of the item. */
      rightActions?: ReactNode;
      /** Optional grab handle in case of drag and drop. */
      grabHandle?: ReactElement<GrabHandleProps, JSXElementConstructor<GrabHandleProps>>;
    },
  ]
>;

const DropdownItemContentBase = memo(function DropdownItemContent({
  label,
  secondaryLabel = undefined,
  tooltip = undefined,
  isInteractive = false,
  icon = undefined,
  disabled = false,
  isActive = false,
  grabHandle = undefined,
  rightActions = undefined,
  ...props
}: DropdownItemContentProps) {
  const { ref: labelRef, doesTextOverflow } = useTextOverflows([label]);

  return (
    <Tooltip
      content={doesTextOverflow ? label : ''}
      placement="right"
    >
      <div
        {...props}
        css={styles.dropdownItemContent}
        className={clsx(props.className, {
          [styles.IS_ACTIVE_CLASSNAME]: isActive,
          [styles.IS_DISABLED_CLASSNAME]: disabled,
          [styles.IS_INTERACTIVE_CLASSNAME]: isInteractive,
          [styles.HAS_HANDLE_CLASSNAME]: grabHandle && !disabled,
        })}
      >
        {!disabled && grabHandle}

        {!!icon && (
          <DropdownItemIcon
            disabled={disabled}
            icon={icon}
          />
        )}

        <DropdownItemLabel
          ref={labelRef}
          data-active={!!isInteractive && isActive}
        >
          {label}
        </DropdownItemLabel>

        {!!secondaryLabel && (
          <DropdownItemSecondaryLabel data-disabled={disabled}>{secondaryLabel}</DropdownItemSecondaryLabel>
        )}

        {!!tooltip && <DropdownItemTooltip tooltip={tooltip} />}

        {rightActions}
      </div>
    </Tooltip>
  );
});

export const DropdownItemContent = Object.assign(DropdownItemContentBase, {
  Actions: DropdownItemContentActions,
});
