import { memo, type ReactNode } from 'react';

export interface PillProps {
  readonly label: ReactNode | string;
  readonly backgroundColor: string;
}

export const Pill = memo(function Pill({ label, backgroundColor }: PillProps) {
  return (
    <span
      style={{
        backgroundColor,
        marginRight: '10px',
        padding: '4px 6px',
        borderRadius: '2px',
      }}
    >
      {label}
    </span>
  );
});
