import { memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import { OverwritesContainer } from './OverwritesContainer';

export const OverwritesRoutes = memo(function OverwritesRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="overwrites">
      <RootRoutes>
        <Route
          path={routes.OVERWRITES}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Overwrites' })} />
              <OverwritesContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
