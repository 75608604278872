import { type ComponentPropsWithoutRef, type ForwardedRef, forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Avatar, Skeleton, Typography, useAvatarUser } from '@amal-ia/frontend/design-system/components';
import { BorderRadiusVariant } from '@amal-ia/frontend/design-system/meta';

import { accentuatedLabel, defaultLabel, dimmedLabel } from '../../FieldValuePrettyFormat.styles';
import { LabelDisplay, LabelVariant } from '../../types';

import { messages } from './UserPrettyFormat.messages';
import { EXTERNAL_ID_TYPOGRAPHY_VARIANT, NAME_TYPOGRAPHY_VARIANT, styles } from './UserPrettyFormat.styles';

export type UserPrettyFormatProps = ComponentPropsWithoutRef<'div'> & {
  /** Whether the user info is loading. This will display a Skeleton over the avatar and the full name. */
  readonly isLoading?: boolean;

  /**
   * What is displayed under the full name.
   * For example, the user's external id or the email.
   */
  readonly subLabel?: string;
  /** The user's first name */
  readonly firstName?: string | null;
  /** The user's last name */
  readonly lastName?: string | null;
  /** The user's avatar url */
  readonly pictureURL?: string | null;

  /**
   * How the label is displayed.
   * BLOCK is multiline, INLINE is on a single line.
   */
  readonly display?: LabelDisplay.BLOCK | LabelDisplay.INLINE;
  /**
   * The variant of the label.
   * Accentuated is bolder and colored.
   * Note: DIMMED is not supported and you should fall back to DEFAULT.
   */
  readonly variant?: LabelVariant;
};

const UserPrettyFormatBase = forwardRef(function UserPrettyFormat(
  {
    isLoading = false,
    firstName,
    lastName,
    subLabel,
    pictureURL,
    display = LabelDisplay.BLOCK,
    variant = LabelVariant.DEFAULT,
    ...props
  }: UserPrettyFormatProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  // DIMMED variant is not supported yet
  const supportedVariant = variant === LabelVariant.DIMMED ? LabelVariant.DEFAULT : variant;

  const isUserNotFoundState = !firstName && !lastName;

  const avatarUser = useAvatarUser({
    firstName,
    lastName,
    pictureURL,
  });

  return (
    <div
      ref={ref}
      {...props}
      css={[
        styles.container,
        display === LabelDisplay.BLOCK && styles.containerBlockDisplay,
        display === LabelDisplay.INLINE && styles.containerInlineDisplay,
      ]}
    >
      <Skeleton
        css={[styles.avatarSkeleton, display === LabelDisplay.BLOCK && styles.avatarSkeletonBlockDisplay]}
        data-testid="user-field-label-avatar-skeleton"
        shape={BorderRadiusVariant.ROUND}
        visible={isLoading}
      >
        <Avatar
          size={display === LabelDisplay.BLOCK ? 28 : 21}
          user={avatarUser}
        />
      </Skeleton>

      <div css={[styles.userInfoContainer, display === LabelDisplay.INLINE && styles.userInfoContainerInlineDisplay]}>
        <Skeleton
          css={[styles.nameSkeleton, (theme) => theme.ds.typographies[NAME_TYPOGRAPHY_VARIANT[supportedVariant]]]}
          data-testid="user-field-label-name-skeleton"
          visible={isLoading}
        >
          {!isUserNotFoundState ? (
            <Typography
              variant={NAME_TYPOGRAPHY_VARIANT[supportedVariant]}
              css={[
                styles.name,
                supportedVariant === LabelVariant.DEFAULT && defaultLabel,
                supportedVariant === LabelVariant.ACCENTUATED && accentuatedLabel,
              ]}
            >
              {`${firstName} ${lastName}`.toLowerCase()}
            </Typography>
          ) : (
            <Typography
              variant={NAME_TYPOGRAPHY_VARIANT[supportedVariant]}
              css={[
                supportedVariant === LabelVariant.DEFAULT && dimmedLabel,
                supportedVariant === LabelVariant.ACCENTUATED && accentuatedLabel,
              ]}
            >
              <FormattedMessage {...messages.UNSYNCED_USER} />
            </Typography>
          )}
        </Skeleton>

        {!!subLabel && (
          <div css={[dimmedLabel, styles.subLabel]}>
            <Typography variant={EXTERNAL_ID_TYPOGRAPHY_VARIANT}>{subLabel}</Typography>
          </div>
        )}
      </div>
    </div>
  );
});

export const UserPrettyFormat = memo(UserPrettyFormatBase) as typeof UserPrettyFormatBase;

/*
 * There are multiple possible states for this component:
 *
 * - Loading: We show a loading indicator using a Skeleton.
 * Only the subLabel (externalId) is shown as it is the only prop we have.
 *
 * - User Is Not Found: We show a message saying that the user
 * was not synced (yet/anymore).
 * We show the subLabel (externalId) and * "User not synced" as the fullName.
 * Fallback avatar should be a random "default" avatar.
 * (TODO): default avatar should be handled by Avatar component
 *
 * - Idle: We show the fullName, the subLabel (externalId) and the avatar.
 */
