import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const CustomReportEdit = lazy(() => import('./edit/CustomReportEdit'));
const CustomReports = lazy(() => import('./list/CustomReportList'));

export const CustomReportRoutes = memo(function CustomReportRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="customReport">
      <RootRoutes>
        <Route
          path={routes.CUSTOM_REPORT_ITEM}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.CustomReport)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Custom report' })} />
              <CustomReportEdit />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.CUSTOM_REPORT_LIST}
          element={
            <AbilityRouteProtector
              can={(ability) =>
                ability.can(ActionsEnum.view, SubjectsEnum.CustomReport) ||
                ability.can(ActionsEnum.modify, SubjectsEnum.CustomReport) ||
                ability.can(ActionsEnum.delete, SubjectsEnum.CustomReport)
              }
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Custom reports' })} />
              <CustomReports />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});

export default CustomReportRoutes;
