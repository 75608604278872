import {
  type ComputedHighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amal-ia/compensation-definition/plans/types';
import {
  type ListSelectedOptions,
  type PaginationStoredInRedux,
  type RootState,
  type UserStatements,
} from '@amal-ia/lib-types';

export const selectLastUserStatementsFetchParams = (state: RootState): Record<string, any> =>
  state.userStatements.lastparams;

export const selectUserStatementsList = (state: RootState): UserStatements[] => state.userStatements.userStatementsList;

export const selectUserStatementsListCurrentPage = (
  state: RootState,
): PaginationStoredInRedux<UserStatements> & {
  kpisAverage: Record<HighlightedKpiIdentifier, ComputedHighlightedKpi | null>;
} => state.userStatements.currentPage;

/**
 * @deprecated
 * @param state
 */
export const selectUserStatementsIsLoading = (state: RootState): boolean => !!state.userStatements.isLoading;

export const selectUserStatementsListSelectedOptions = (state: RootState): ListSelectedOptions =>
  state.userStatements.listSelectedOptions;
