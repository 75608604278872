import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { assert } from '@amal-ia/ext/typescript';
import { type Calculation, type DataRefreshment, type MasterSettings } from '@amal-ia/lib-types';

import { saGetLastCalculations, saGetOngoingCalculations } from './service/calculations.superAdmin.repository';
import { saDeleteCompany, saGetCompanies, saGetCompany } from './service/companies.superAdmin.repository';
import { saGetConnectors } from './service/connectors.superAdmin.repository';
import { saGetAllMasterSettings } from './service/masterSettings.superAdmin.repository';
import { saGetPlans } from './service/plans.superAdmin.repository';
import { saGetLastRefreshments, saGetOngoingRefreshments } from './service/refreshments.superAdmin.repository';
import { saGetStatementsOnError } from './service/statements.superAdmin.repository';
import { saGetPeriods } from './service/superAdmin.repository';
import { saGetUsers } from './service/users.superAdmin.repository';
import { getSaCompanyKey, SUPER_ADMIN_QUERY_KEYS } from './superAdmin.queries.keys';
import { type PaginationOf } from './types';

export const useIsSALoading = () =>
  useIsFetching({
    predicate: (query) => Object.values(SUPER_ADMIN_QUERY_KEYS).includes(query.queryKey[0] as SUPER_ADMIN_QUERY_KEYS),
  });

// QUERY
export const useCompaniesList = () =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES],
    queryFn: () => saGetCompanies(),
    initialData: [],
  });

export const useCompany = (companyId?: string) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_COMPANY, getSaCompanyKey(companyId ?? '')],
    queryFn: () => {
      assert(companyId, 'companyId is required');
      return saGetCompany(companyId);
    },
    enabled: !!companyId,
  });

type DeleteCompanyMutationInput = {
  companyId: string;
};

/**
 * React query mutation hook to delete a company.
 */
export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: DeleteCompanyMutationInput) => saDeleteCompany(variables.companyId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_COMPANIES] });
    },
  });
};

export const useUsersList = () =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_USERS],
    queryFn: () => saGetUsers(),
    select: (data) => data.toSorted((u1, u2) => u1.company.name.localeCompare(u2.company.name)),
    initialData: [],
  });

export const useOnGoingCalculations = () =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_CALCULATIONS],
    queryFn: () => saGetOngoingCalculations(),
    placeholderData: (previousData) => previousData ?? [],
    refetchInterval: 10_000,
  });

export const useLastCalculations = (companiesId: string[], filterErrors: boolean, page: number = 1) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_CALCULATIONS, companiesId, filterErrors, page],
    queryFn: () => saGetLastCalculations(page, companiesId, filterErrors),
    refetchInterval: 10_000,
    placeholderData: (previousData) =>
      previousData ??
      ({
        items: [],
        totalItems: 0,
        currentPage: 1,
        pageCount: 1,
      } satisfies PaginationOf<Calculation>),
  });

export const useOnGoingRefreshments = () =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_ON_GOING_REFRESHMENTS],
    queryFn: () => saGetOngoingRefreshments(),
    initialData: [],
    refetchInterval: 10_000,
  });

export const useLastRefreshments = (companiesId: string[], filterErrors: boolean, page: number = 1) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LAST_REFRESHMENTS, companiesId, filterErrors, page],
    queryFn: () => saGetLastRefreshments(page, companiesId, filterErrors),
    placeholderData: (previousData) =>
      previousData ??
      ({
        items: [],
        totalItems: 0,
        currentPage: 1,
        pageCount: 1,
      } satisfies PaginationOf<DataRefreshment>),
    refetchInterval: 10_000,
  });

export const useCompanyPeriods = (companyId: string) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_COMPANY_PERIODS, getSaCompanyKey(companyId)],
    queryFn: () => saGetPeriods(companyId),
    select: (data) =>
      data
        .toSorted((a, b) => b.startDate - a.startDate)
        .map((period) => ({
          label: period.name,
          value: period.id,
        })),
    placeholderData: [] satisfies Period[],
    enabled: !!companyId,
  });

export const useConnectorsList = (companiesId: string[]) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_CONNECTORS, companiesId],
    queryFn: () => saGetConnectors(companiesId),
    placeholderData: [],
  });

export const useStatementsOnErrorList = (companiesId: string[]) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_STATEMENTS_ON_ERROR, companiesId],
    queryFn: () => saGetStatementsOnError(companiesId),
    placeholderData: [],
  });

export const usePlansList = (companiesId: string[]) =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_LIST_PLANS, companiesId],
    queryFn: () => saGetPlans(companiesId),
    placeholderData: [],
  });

export const useMasterSettings = () =>
  useQuery({
    queryKey: [SUPER_ADMIN_QUERY_KEYS.SA_GET_MASTER_SETTINGS],
    queryFn: () => saGetAllMasterSettings(),
    initialData: {} as MasterSettings,
  });
