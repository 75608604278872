import { IconHelpCircle } from '@tabler/icons-react';
import { memo } from 'react';

import { Link, type LinkProps } from '@amal-ia/ext/react-router-dom';

import { IconAction } from '../../../../general/icon-action/IconAction';
import { type TooltipProps } from '../../../../overlays/tooltip/Tooltip';

export type ColumnLinkTooltipProps = {
  readonly to: LinkProps['to'];
  readonly content: TooltipProps['content'];
};

export const ColumnLinkTooltip = memo(function ColumnLinkTooltip({ to, content }: ColumnLinkTooltipProps) {
  return (
    <Link
      openInNewTab
      to={to}
    >
      <IconAction
        icon={<IconHelpCircle />}
        label={content}
        size={IconAction.Size.XSMALL}
      />
    </Link>
  );
});
