import { memo, useMemo } from 'react';

import { AlertBanner, AlertBannerVariant } from '@amal-ia/frontend/design-system/components';
import { StatementErrorLevel, type StatementError } from '@amal-ia/lib-types';

const STATEMENT_ERROR_LEVEL_TO_ALERT_BANNER_VARIANT: Record<StatementErrorLevel, AlertBannerVariant> = {
  [StatementErrorLevel.ERROR]: AlertBannerVariant.ERROR,
  [StatementErrorLevel.WARNING]: AlertBannerVariant.WARNING,
} as const;

type StatementErrorBannerProps = {
  readonly statementError: StatementError;
};

export const StatementErrorBanner = memo(function StatementErrorBanner({ statementError }: StatementErrorBannerProps) {
  const errorMessage = useMemo(() => {
    if (statementError?.context?.variableName && statementError.message.includes(statementError.context.variableName)) {
      return statementError.message.split(statementError.context.variableName).map((sentence, idx) => {
        if (idx === 0) {
          return [sentence, <b key={idx}>{statementError.context.variableName}</b>];
        }
        return [sentence];
      });
    }
    return statementError.message;
  }, [statementError.context, statementError.message]);

  return (
    <AlertBanner
      withBorder
      variant={STATEMENT_ERROR_LEVEL_TO_ALERT_BANNER_VARIANT[statementError.level]}
    >
      {errorMessage}
    </AlertBanner>
  );
});
