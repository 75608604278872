import { http } from '@amal-ia/frontend/kernel/http';
import {
  type AuditDateRange,
  type AuditRecord,
  type CountAuditRecords,
  type SearchAuditRecords,
} from '@amal-ia/kernel/monitoring/audit/types';

import { type AuditFiltersOptions } from '../../types/audit';

const endpoint = '/audit';

const buildCountAuditRequest = (
  filters: AuditFiltersOptions,
  { users, objects, targets, types, whats, fromDate, toDate }: CountAuditRecords,
): CountAuditRecords => ({
  // Search filters
  objects: filters.objects.length !== objects.length ? objects : [],
  targets: filters.targets.length !== targets.length ? targets : [],
  types: filters.types.length !== types.length ? types : [],
  users: filters.users.length !== users.length ? users : [],
  whats: filters.whats.length !== whats.length ? whats : [],
  fromDate,
  toDate,
});

export async function getAuditFilters({ fromDate, toDate }: AuditDateRange): Promise<AuditFiltersOptions> {
  const params = new URLSearchParams();
  if (fromDate !== null) params.append('fromDate', fromDate.toISOString().split('T')[0]);
  if (toDate !== null) params.append('toDate', toDate.toISOString().split('T')[0]);

  const { data } = await http.get(`${endpoint}/filters?${params.toString()}`);
  return data;
}

export async function countAuditRecords(
  filters: AuditFiltersOptions,
  filtersState: CountAuditRecords,
): Promise<{ count: number }> {
  const { data } = await http.post(`${endpoint}/records/_count`, buildCountAuditRequest(filters, filtersState));
  return data;
}

export async function searchAuditRecords(
  filters: AuditFiltersOptions,
  filtersState: SearchAuditRecords,
): Promise<AuditRecord[]> {
  const searchBody: SearchAuditRecords = {
    ...buildCountAuditRequest(filters, filtersState),
    limit: filtersState.limit,
    offset: filtersState.offset,
  };

  const { data } = await http.post(`${endpoint}/records/_search`, searchBody);
  return data;
}
