import { css } from '@emotion/react';
import { keyBy } from 'lodash';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type Variable, type VariablesMap } from '@amal-ia/amalia-lang/tokens/types';
import { type HighlightedKpi, HighlightedKpiIdentifier, type Plan } from '@amal-ia/compensation-definition/plans/types';
import { Button } from '@amal-ia/frontend/design-system/components';

import { StatementGaugeGroup } from '../../../../statement/statement-gauge-group/StatementGaugeGroup';

import { HighlightedKpiModal } from './HighlightedKpiModal';
import { messages } from './HighlightedKpisSelected.messages';

const container = css`
  display: flex;
  align-items: center;
  gap: 36px;
`;

interface HighlightedKpiSelectorProps {
  readonly plan: Plan;
  readonly onAdd: (kpi: HighlightedKpi) => any;
  readonly onRemove: (identifier: HighlightedKpiIdentifier) => any;
  readonly variablesMap: VariablesMap;
  readonly availableVariables: Variable[];
}

const FAKE_PROGRESS = {
  primary: 60,
  secondary: 70,
};

export const HighlightedKpiSelector = memo(function HighlightedKpiSelector({
  plan,
  onRemove,
  onAdd,
  variablesMap,
  availableVariables,
}: HighlightedKpiSelectorProps) {
  const [selectedIdentifier, setSelectedIdentifier] = useState<HighlightedKpiIdentifier | null>(null);

  const highlightedKpisMap = useMemo(() => keyBy(plan.highlightedKpis, 'identifier'), [plan]);

  const closeModal = useCallback(() => {
    setSelectedIdentifier(null);
  }, [setSelectedIdentifier]);

  const onSubmitProxy = useCallback(
    async (values: any) => {
      closeModal();
      await onAdd(values);
    },
    [onAdd, closeModal],
  );

  const onRemoveProxy = useCallback(
    (currentlySelectedIdentifier: HighlightedKpiIdentifier) => {
      closeModal();
      onRemove(currentlySelectedIdentifier);
    },
    [onRemove, closeModal],
  );

  return (
    <Fragment>
      <div css={container}>
        <Button
          data-testid="primary-kpi"
          onClick={() => setSelectedIdentifier(HighlightedKpiIdentifier.PRIMARY)}
        >
          {highlightedKpisMap[HighlightedKpiIdentifier.PRIMARY] ? (
            variablesMap[highlightedKpisMap[HighlightedKpiIdentifier.PRIMARY].variableId]?.name
          ) : (
            <FormattedMessage {...messages.SET_HIGHLIGHT_KPI} />
          )}
        </Button>

        <StatementGaugeGroup
          progress={FAKE_PROGRESS}
          width={96}
        />
        <Button
          data-testid="secondary-kpi"
          variant={Button.Variant.SECONDARY}
          onClick={() => setSelectedIdentifier(HighlightedKpiIdentifier.SECONDARY)}
        >
          {highlightedKpisMap[HighlightedKpiIdentifier.SECONDARY] ? (
            variablesMap[highlightedKpisMap[HighlightedKpiIdentifier.SECONDARY].variableId]?.name
          ) : (
            <FormattedMessage {...messages.SET_HIGHLIGHT_KPI} />
          )}
        </Button>
      </div>

      <HighlightedKpiModal
        availableVariables={availableVariables}
        currentValue={selectedIdentifier ? highlightedKpisMap[selectedIdentifier] : undefined}
        selectedIdentifier={selectedIdentifier}
        onCancel={closeModal}
        onRemove={onRemoveProxy}
        onSubmit={onSubmitProxy}
      />
    </Fragment>
  );
});
