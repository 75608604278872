import { defineMessages } from 'react-intl';

import { DateTransform } from '@amal-ia/amalia-lang/formula/shared/types';

export const functionDateDelayMessages = defineMessages<DateTransform>({
  [DateTransform.DAYS_AGO]: {
    defaultMessage: 'Days',
  },
  [DateTransform.MONTHS_AGO]: {
    defaultMessage: 'Months',
  },
} as const);
