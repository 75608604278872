import { type Editor } from '@tiptap/react';
import { type FormikErrors } from 'formik';
import { type Dispatch, type SetStateAction, createContext, useContext } from 'react';
import { type RequiredDeep } from 'type-fest';

import { type FormulaBuilder } from '@amal-ia/amalia-lang/formula/shared/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type DesignerAllObjects } from '@amal-ia/lib-ui-business';

export type FormulaBuilderContextValue = {
  portalElement?: HTMLElement | null;
  activeConditionId?: string | null;
  setActiveConditionId?: Dispatch<SetStateAction<string | null>>;
  editor?: Editor;
  /** Errors for fields inside the builder. Conditions use their path to find their own errors. */
  errors?: FormikErrors<RequiredDeep<FormulaBuilder>>; // Need RequiredDeep to declare errors, otherwise on optional objects it only accepts string.
  /** Selected custom object. */
  customObjectDefinition?: CustomObjectDefinition;
  /** Current plan id. */
  planId?: string;
  /** Tokens available for the current plan (plan local tokens + global tokens). */
  planObjects?: DesignerAllObjects;
  /** Is the formula autocomplete enabled. */
  enableFormulaAutocomplete?: boolean;
};

export const FormulaBuilderContext = createContext<FormulaBuilderContextValue>({});

export const useFormulaBuilderContext = () => useContext(FormulaBuilderContext);
