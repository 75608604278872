import { search as fuzzySearch, sortKind } from 'fast-fuzzy';
import { unionBy, uniqueId } from 'lodash';
import { type FunctionNode } from 'mathjs';
import { useMemo } from 'react';

import { commonMathJs } from '@amal-ia/amalia-lang/amalia-mathjs';
import { type FormulaEditorToken, type FormulaEditorTokenFilter } from '@amal-ia/amalia-lang/formula/components';
import { AmaliaFunction } from '@amal-ia/amalia-lang/formula/evaluate';
import { TokenType } from '@amal-ia/lib-types';

import { sortTokens } from '../../hooks/use-formula-editor-suggestions/sortTokens';

/**
 * mathjs cannot parse formulas with empty arguments.
 * This function will replace empty arguments with placeholders.
 * @param formula
 */
const getFakedFormula = (formula: string) =>
  // mathjs cannot parse formulas with empty arguments.
  formula
    // Replace empty arguments with placeholders (uuids to ensure it's unique).
    .replace(/\s*,\s*(?=[,)])/gu, `,${uniqueId('arg_')}`)
    .replace(/\(\s*(?=,)/gu, ')');

type SearchedFunctionArgument = { function: string; argIndex: number; selectedFilterMachineName?: string } | undefined;

/**
 * Search function and argument index of the current cursor position.
 * @param formula
 * @param items
 * @returns {SearchedFunctionArgument} The function name and the argument index.
 */
const searchFunctionArgumentAtSuggestionPosition = (
  formula: string,
  items: FormulaEditorToken[],
): SearchedFunctionArgument => {
  const suggestionPlaceholder = uniqueId('arobase_');
  const fakedFormula = getFakedFormula(
    formula
      // @ is not a valid character for mathjs, so we replace it.
      .replace('@', suggestionPlaceholder),
  );

  const suggestionCharacterIndex = fakedFormula.indexOf(suggestionPlaceholder);

  let functionArgAtSuggestionPosition: SearchedFunctionArgument;
  try {
    const filterFormulas = items.filter((i) => i.type === TokenType.FILTER).map((i) => i.formula);
    const node = commonMathJs.parse(fakedFormula);
    node.traverse((node) => {
      // If we already found the function argument, or if the node is not a function,
      // we don't need to continue the iteration.
      if (functionArgAtSuggestionPosition || node.type !== 'FunctionNode') {
        return;
      }

      const functionNode = node as FunctionNode;
      const args = functionNode.args;

      // We search for the function argument that contains the suggestion character index.
      const suggestionArgIndex = args.findIndex((argNode) => {
        const argNodeString = argNode.toString();
        const argNodeIndexStart = fakedFormula.indexOf(argNodeString);
        const argNodeIndexEnd = argNodeIndexStart + argNodeString.length;
        return argNodeIndexStart <= suggestionCharacterIndex && suggestionCharacterIndex <= argNodeIndexEnd;
      });

      // If we have a filter argument previous to the suggestion arg, we want to keep it to filter the items
      // example in `SUM(filter.closedByRepInPeriod, @)`, I want to filter the second argument based on `filter.closedByRepInPeriod`.
      const selectedFilterArg = args.find((argNode, index) => {
        const argNodeString = argNode.toString();
        return filterFormulas.includes(argNodeString) && index < suggestionArgIndex;
      });

      if (suggestionArgIndex > -1) {
        functionArgAtSuggestionPosition = {
          function: functionNode.fn.name,
          argIndex: suggestionArgIndex,
          selectedFilterMachineName: selectedFilterArg?.toString(),
        };
      }
    });
  } catch (e) {
    // Formula is invalid, or unknown error, so we didn't find function argument.
    return undefined;
  }

  return functionArgAtSuggestionPosition;
};

/**
 * Build formula editor token filters, based on the suggestion position.
 * For example, if the user is currently writing a SUM function, we want to only show the filter tokens.
 * @param formula
 * @param items
 */
const getApplicableSuggestionFilters = (
  formula: string,
  items: FormulaEditorToken[],
): FormulaEditorTokenFilter[] | undefined => {
  const functionArg = searchFunctionArgumentAtSuggestionPosition(formula, items);
  if (!functionArg) {
    return undefined;
  }

  const amaliaFunctions = AmaliaFunction.getAllFunctions();
  const currentFunction = amaliaFunctions[functionArg.function];
  const currentFunctionParam = currentFunction.params?.[functionArg.argIndex];
  if (!currentFunctionParam) {
    return undefined;
  }

  return [
    {
      tokenTypes: currentFunctionParam.validTokenTypes,
      formats: currentFunctionParam.validFormats,
      tokenValues: currentFunctionParam.validTokenValues,
      selectedFilterMachineName: functionArg.selectedFilterMachineName,
    },
  ];
};

const searchTokens = (query: string, tokens: FormulaEditorToken[]) => {
  if (!query) {
    return tokens;
  }

  // I want to perform a fuzzy search for tokens when type is not keyword or function. Otherwise, I want to perform a strict search.
  const searchedTokens = fuzzySearch(
    query,
    tokens.filter((t) => ![TokenType.KEYWORD, TokenType.FUNCTION].includes(t.type)),
    {
      threshold: 0.8,
      keySelector: (token) => [token.name, token.formula],
      ignoreCase: true,
      sortBy: sortKind.insertOrder,
    },
  );

  const strictSearchedTokens = tokens.filter(
    (t) =>
      [TokenType.KEYWORD, TokenType.FUNCTION].includes(t.type) && t.name.toLowerCase().includes(query.toLowerCase()),
  );

  return unionBy(strictSearchedTokens, searchedTokens, 'formula');
};

const applyTokenTypesFilter = (filter: FormulaEditorTokenFilter, item: FormulaEditorToken) =>
  filter.tokenTypes?.length ? filter.tokenTypes.includes(item.type) : true;

const applyTokenValueFilter = (filter: FormulaEditorTokenFilter, item: FormulaEditorToken) =>
  filter.tokenValues?.[item.type] ? filter.tokenValues[item.type]?.includes(item.formula) : true;

const applyTokenFormatsFilter = (filter: FormulaEditorTokenFilter, item: FormulaEditorToken) =>
  filter.formats?.length ? (item.format ? filter.formats.includes(item.format) : false) : true;

const applyMachineNameFilter = (item: FormulaEditorToken, filterItemToken?: FormulaEditorToken) =>
  filterItemToken?.objectDefinitionName && item.objectDefinitionName
    ? item.objectDefinitionName === filterItemToken.objectDefinitionName
    : true;

const buildFilterPredicate = (filter: FormulaEditorTokenFilter, items: FormulaEditorToken[]) => {
  // If there is a filter selected, we want to filter the items based on the objectDefinitionName.
  const filterItemToken = items.find((item) => item.formula === filter.selectedFilterMachineName);
  return (item: FormulaEditorToken) => {
    const hasItemSupportedTokenType = applyTokenTypesFilter(filter, item);

    const hasItemSupportedTokenValue = applyTokenValueFilter(filter, item);

    const hasItemSupportedFormat = applyTokenFormatsFilter(filter, item);

    const isFilterByFilterMachineName = applyMachineNameFilter(item, filterItemToken);

    return (
      hasItemSupportedTokenType && hasItemSupportedTokenValue && hasItemSupportedFormat && isFilterByFilterMachineName
    );
  };
};

/**
 * Filter items based on the filters provided.
 */
export const useFilteredItems = (items: FormulaEditorToken[], formula: string, query: string) =>
  useMemo(() => {
    const tokensFilteredByQuerySearch = sortTokens(searchTokens(query, items));

    if (!formula) {
      return tokensFilteredByQuerySearch;
    }

    const filters = getApplicableSuggestionFilters(formula, items);

    const filtersPredicate = filters?.map((filter) => buildFilterPredicate(filter, items));

    return filtersPredicate
      ? tokensFilteredByQuerySearch.filter((item) => filtersPredicate.some((predicate) => predicate(item)))
      : tokensFilteredByQuerySearch;
  }, [formula, items, query]);
