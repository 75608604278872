import { IconHelp, IconSpeakerphone, IconStar } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Link } from '@amal-ia/ext/react-router-dom';
import { IconButton } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

import Todos from '../../todos/Todos';

import * as styles from './LeftBarActions.styles';

export const LeftBarActions = memo(function LeftBarActions() {
  const ability = useAbilityContext();
  const { pathname } = useLocation();

  return (
    <div css={styles.leftBarActions}>
      <Todos />

      {!!ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin) && (
        <Link to={generatePath(routes.SUPERADMIN_HOME)}>
          <IconButton
            icon={<IconStar />}
            isActive={pathname.includes('superadmin')}
            label={<FormattedMessage defaultMessage="Superadmin" />}
          />
        </Link>
      )}

      <Link to={generatePath(routes.CHANGELOG)}>
        <IconButton
          icon={<IconSpeakerphone />}
          isActive={pathname === '/changelog'}
          label={<FormattedMessage defaultMessage="What's new" />}
        />
      </Link>

      <Link to="https://docs.amalia.io">
        <IconButton
          icon={<IconHelp />}
          label={<FormattedMessage defaultMessage="Helpdoc" />}
        />
      </Link>
    </div>
  );
});
