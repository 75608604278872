import { combineReducers } from 'redux';

import { type Rule } from '@amal-ia/compensation-definition/plans/types';
import { type ReduxAction } from '@amal-ia/lib-types';

import { RULES_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action: ReduxAction) => {
    switch (action.type) {
      case RULES_ACTIONS.START:
        return state + 1;
      case RULES_ACTIONS.ERROR:
      case RULES_ACTIONS.SET_RULE:
        return state - 1;
      case RULES_ACTIONS.SET_RULES:
        return state - 1;
      default:
        return state;
    }
  },
  list: (state: Rule[] | null = null, action: ReduxAction) => {
    switch (action.type) {
      case RULES_ACTIONS.SET_RULE:
        return [...(state || []).filter((rule) => rule.id !== action.payload.rule.id), action.payload.rule];
      case RULES_ACTIONS.SET_RULES:
        return action.payload.rules;
      default:
        return state;
    }
  },
});
