import { type Editor } from '@tiptap/core';
import { type EditorState } from '@tiptap/pm/state';

import { type FormulaEditorToken } from '@amal-ia/amalia-lang/formula/components';

import { FunctionBoundariesParser } from './function-boundaries-parser';

/**
 * Find function node in the formula editor which contains the caret position (if some).
 * @param editor
 * @param tokens
 */
export const findActiveFunctionNode = (
  editor: Editor,
  tokens: FormulaEditorToken[],
): { node: EditorState['doc']; position: number } | undefined => {
  const { state } = editor;
  const { doc } = state;
  const caretPosition = state.selection.$from.pos;
  const parenthesisBoundaries = new FunctionBoundariesParser(doc, tokens).parse();

  // We search function boundaries = all functions which parenthesis contains the caret position.
  const functionBoundaries = parenthesisBoundaries.filter(
    (parenthesisBoundary) =>
      parenthesisBoundary.functionNode &&
      parenthesisBoundary.openingParenthesisPosition.nodePosition +
        parenthesisBoundary.openingParenthesisPosition.index <=
        caretPosition &&
      parenthesisBoundary.closingParenthesisPosition &&
      parenthesisBoundary.closingParenthesisPosition.nodePosition +
        parenthesisBoundary.closingParenthesisPosition.index >=
        caretPosition,
  );

  if (functionBoundaries.length === 0) {
    return undefined;
  }

  // Active function node is the one which is closest to the caret position (so the last one which has been parsed).
  const activeFunctionNode = functionBoundaries[functionBoundaries.length - 1];
  if (activeFunctionNode.functionNode && activeFunctionNode.functionNodePosition) {
    return {
      node: activeFunctionNode.functionNode,
      position: activeFunctionNode.functionNodePosition,
    };
  }

  return undefined;
};
