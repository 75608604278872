import { isEqual } from 'lodash';
import moment from 'moment';
import { type ActionCreator } from 'redux';

import { PeriodsApiClient } from '@amal-ia/compensation-definition/api-client';
import { type Period, type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import {
  type Adjustment,
  type CreateDatasetOverwriteRequest,
  type CreateStatementOverwriteRequest,
  type Overwrite,
  type ReduxAction,
  type Statement,
  type StatementForecast,
  type ThunkResult,
  type WorkflowStatementStateAction,
} from '@amal-ia/lib-types';
import { type ComputedOverwrite } from '@amal-ia/payout-calculation/shared/types';

import {
  createStatementAdjustment,
  deleteStatementAdjustment,
  editStatementAdjustment,
} from '../../services/statements/statementAdjustments.repository';
import * as StatementDatasetsRepository from '../../services/statements/statementDatasets.repository';
import * as StatementRepository from '../../services/statements/statements.repository';
import {
  findStatementByCriteria,
  findStatements,
  getForecastedStatement,
  getStatement,
  getStatements,
  processWorkflowStep as processWorkflowStepRepo,
} from '../../services/statements/statements.repository';

import { STATEMENTS_ACTIONS } from './constants';
import { selectLastStatementsFetchParams, selectStatements } from './selectors';

const statementsStart: ActionCreator<ReduxAction> = () => ({
  type: STATEMENTS_ACTIONS.START,
});

const statementsError: ActionCreator<ReduxAction<typeof STATEMENTS_ACTIONS.ERROR, never, Error>> = (error: Error) => ({
  type: STATEMENTS_ACTIONS.ERROR,
  error,
});

const setStatementsSuccess: ActionCreator<ReduxAction> = (statements: Statement[], options: any) => ({
  type: STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS,
  payload: { statements, options },
});

export const clearStatements: ActionCreator<ReduxAction> = () => ({
  type: STATEMENTS_ACTIONS.CLEAR_STATEMENTS,
});

/// //////////
/// STATEMENTS
/// /////////
export const fetchStatementsThunkAction =
  (
    periodId?: string,
    planId?: string,
    teamId?: string,
    userId?: string,
    withKpiResults?: boolean,
    force: boolean = false,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(statementsStart());
    const lastOptions = selectLastStatementsFetchParams(getState());
    const statementsFromState = selectStatements(getState());

    const options = {
      periodId,
      planId,
      teamId,
      userId,
    };

    if (!force && isEqual(options, lastOptions)) {
      return dispatch(setStatementsSuccess(statementsFromState, options));
    }
    try {
      const statements = await findStatements(periodId, planId, teamId, userId ? [userId] : [], withKpiResults);
      return dispatch(setStatementsSuccess(statements, options));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

export const getStatementsByIdThunkAction =
  (ids: string[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const statements = await getStatements(ids);
      return dispatch(setStatementsSuccess(statements, { ids }));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

const setUserStatementsSuccess: ActionCreator<
  ReduxAction<typeof STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS, { userStatements: Statement[] }, never>
> = (userStatements: Statement[]) => ({
  type: STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS,
  payload: { userStatements },
});

export const fetchUserStatementsThunkAction =
  (
    periodId?: string,
    planId?: string,
    teamId?: string,
    userId?: string,
    withKpiResults?: boolean,
  ): ThunkResult<Promise<ReturnType<typeof setUserStatementsSuccess> | ReturnType<typeof statementsError>>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statements = await findStatements(periodId, planId, teamId, userId ? [userId] : [], withKpiResults);
      return dispatch(setUserStatementsSuccess(statements));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

const setStatementSuccess: ActionCreator<ReduxAction> = (statement: Statement) => ({
  type: STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS,
  payload: { statement },
});

export const clearCurrentStatement: ActionCreator<ReduxAction> = () => ({
  type: STATEMENTS_ACTIONS.CLEAR_STATEMENT,
});

export const setForecastedStatementSuccess: ActionCreator<ReduxAction> = (forecastedStatement: StatementForecast) => ({
  type: STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS,
  payload: { forecastedStatement },
});

export const fetchStatementThunkAction =
  (id: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statement = await getStatement(id);
      return dispatch(setStatementSuccess(statement));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

export const fetchForecastedStatementThunkAction =
  (forecastId: string, statementId: string, withObjectsToDisplay: boolean = false): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const forecastedStatement = await getForecastedStatement(forecastId, statementId, withObjectsToDisplay);
      return dispatch(setForecastedStatementSuccess(forecastedStatement));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

/**
 * @deprecated use the React query function from statements.queries.ts instead.
 * @param planId
 * @param userId
 * @param periodId
 */
export const fetchStatementByPlanPeriodUserThunkAction =
  (planId: string, userId: string, periodId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const statement = await findStatementByCriteria(periodId, planId, userId);
      if (statement) {
        return dispatch(setStatementSuccess(statement));
      }
      return null;
    } catch (error) {
      dispatch(clearCurrentStatement());
      if (error.statusCode === 404) {
        return dispatch(statementsError());
      }
      return dispatch(statementsError(error));
    }
  };

const setCurrentPeriodSuccess: ActionCreator<ReduxAction> = (currentPeriod: Period) => ({
  type: STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS,
  payload: { currentPeriod },
});

export const setCurrentPeriodThunkAction =
  (currentPeriod: Period): ThunkResult<Promise<ReduxAction>> =>
  (dispatch) => {
    dispatch(statementsStart());
    return Promise.resolve(dispatch(setCurrentPeriodSuccess(currentPeriod)));
  };

/**
 * @deprecated: use period.queries.ts instead.
 * You need to pass the frequency defaulting to company.statementFrequency (legacy from redux -> react-query).
 */
export const fetchCurrentPeriodThunkAction =
  (currentDate: string | undefined, frequency: PeriodFrequencyEnum): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    const periodDateString = currentDate || moment.utc().format('YYYY-MM-DD');

    try {
      const currentPeriod = await PeriodsApiClient.getPeriodByDate(periodDateString, frequency);
      return dispatch(setCurrentPeriodSuccess(currentPeriod));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

/// ///////
/// REVIEW
/// //////
const processWorkflowAction: ActionCreator<ReduxAction> = (
  statementId: string,
  workflowAction: WorkflowStatementStateAction,
  updatedStatement: Statement,
) => ({
  type: STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP,
  payload: { statementId, workflowAction, updatedStatement },
});

export const processWorkflowStepThunkAction =
  (
    statementId: string,
    workflowAction: WorkflowStatementStateAction,
    isNotify: boolean,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      const updatedStatement = await processWorkflowStepRepo(statementId, workflowAction, isNotify);
      return dispatch(processWorkflowAction(statementId, workflowAction, updatedStatement));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

/// ////////////
/// ADJUSTEMENTS
/// ///////////
const createAdjustmentAction: ActionCreator<ReduxAction> = (statementId: string, adjustment: Adjustment) => ({
  type: STATEMENTS_ACTIONS.CREATE_ADJUSTMENT,
  payload: { statementId, adjustment },
});

export const createAdjustmentThunkAction =
  (statementId: string, adjustment: Adjustment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      await createStatementAdjustment(statementId, adjustment);
      return dispatch(createAdjustmentAction(statementId, adjustment));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

const deleteAdjustmentAction: ActionCreator<ReduxAction> = (statementId: string, adjustmentId: string) => ({
  type: STATEMENTS_ACTIONS.DELETE_ADJUSTMENT,
  payload: { statementId, adjustmentId },
});

export const deleteAdjustment =
  (statementId: string, adjustmentId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      await deleteStatementAdjustment(adjustmentId);
      return dispatch(deleteAdjustmentAction(statementId, adjustmentId));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

const editAdjustmentAction: ActionCreator<ReduxAction> = (statementId: string, adjustment: Adjustment) => ({
  type: STATEMENTS_ACTIONS.EDIT_ADJUSTMENT,
  payload: { statementId, adjustment },
});

export const editAdjustmentThunkAction =
  (statementId: string, adjustment: Adjustment): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());

    try {
      await editStatementAdjustment(adjustment);
      return dispatch(editAdjustmentAction(statementId, adjustment));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

/// //////////
/// OVERWRITES
/// /////////
const createOverwriteAction: ActionCreator<ReduxAction> = (overwrite: Overwrite) => ({
  type: STATEMENTS_ACTIONS.CREATE_OVERWRITE,
  payload: { overwrite },
});

const createSimulatedOverwriteAction: ActionCreator<ReduxAction> = (overwrite: Overwrite) => ({
  type: STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE,
  payload: { overwrite },
});

export const createKpiOverwriteThunkAction =
  (
    statementId: string,
    createStatementOverwriteRequest: CreateStatementOverwriteRequest,
  ): ThunkResult<Promise<ReduxAction | undefined>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const overwrite = await StatementRepository.createOverwrite(statementId, createStatementOverwriteRequest);
      return overwrite?.scope === OverwriteScopeEnum.FORECAST
        ? dispatch(createSimulatedOverwriteAction(overwrite))
        : dispatch(createOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

export const createDatasetOverwriteThunkAction =
  (
    statementId: string,
    datasetId: string,
    createStatementDatasetOverwriteRequest: CreateDatasetOverwriteRequest,
  ): ThunkResult<Promise<ReduxAction | undefined>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      const overwrite = await StatementDatasetsRepository.createStatementDatasetOverwrite(
        statementId,
        datasetId,
        createStatementDatasetOverwriteRequest,
      );
      return dispatch(createOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };

const clearOverwriteAction: ActionCreator<ReduxAction> = (overwrite: ComputedOverwrite | Overwrite) => ({
  type: STATEMENTS_ACTIONS.CLEAR_OVERWRITE,
  payload: { overwrite },
});

const clearSimulatedOverwriteAction: ActionCreator<ReduxAction> = (overwrite: ComputedOverwrite | Overwrite) => ({
  type: STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE,
  payload: { overwrite },
});

export const clearStatementOverwriteThunkAction =
  (statementId: string, overwrite: ComputedOverwrite | Overwrite): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(statementsStart());
    try {
      await StatementRepository.clearStatementOverwrite(statementId, overwrite.id);
      return overwrite.scope === OverwriteScopeEnum.FORECAST
        ? dispatch(clearSimulatedOverwriteAction(overwrite))
        : dispatch(clearOverwriteAction(overwrite));
    } catch (error) {
      return dispatch(statementsError(error));
    }
  };
