import { memo, useCallback } from 'react';

interface GaugeProps {
  readonly radius: number;
  readonly stroke: number;
  readonly progress: number;
  readonly color: string;
  readonly colorEmptyStroke?: string;
  readonly boxSize: number;
  readonly onGaugeHover?: (isHover: boolean) => void;
}

export const Gauge = memo(function Gauge({
  radius,
  stroke,
  progress,
  color,
  colorEmptyStroke,
  boxSize,
  onGaugeHover,
}: GaugeProps) {
  const normalizedRadius = radius - stroke / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const gaugeHover = useCallback((isHover: boolean) => onGaugeHover?.(isHover), [onGaugeHover]);

  return (
    <g
      transform={`rotate(90 ${boxSize / 2} ${boxSize / 2})`}
      onMouseEnter={() => gaugeHover(true)}
      onMouseLeave={() => gaugeHover(false)}
    >
      <circle
        cx={boxSize / 2}
        cy={boxSize / 2}
        fill="#fff"
        r={normalizedRadius}
        stroke={colorEmptyStroke || '#eee'}
        strokeWidth={stroke}
      />

      <circle
        cx={boxSize / 2}
        cy={boxSize / 2}
        fill="none"
        r={normalizedRadius}
        stroke={color}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        strokeWidth={stroke}
      />
    </g>
  );
});
