import { css } from '@emotion/react';
import { memo } from 'react';

import { HighlightedKpiIdentifier } from '@amal-ia/compensation-definition/plans/types';

export type StatementKpiDotProps = {
  readonly kpiId: HighlightedKpiIdentifier;
  readonly isForecast?: boolean;
  readonly isEmpty?: boolean;
};

export const StatementKpiDot = memo(function StatementKpiDot({
  kpiId,
  isEmpty = false,
  isForecast = false,
}: StatementKpiDotProps) {
  return (
    <div
      css={[
        (theme) => css`
          display: block;
          content: '';
          width: 6px;
          height: 6px;
          border-radius: ${theme.ds.borderRadiuses.round};
        `,
        kpiId === HighlightedKpiIdentifier.PRIMARY &&
          !isEmpty &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.primary[500]};
          `),
        kpiId === HighlightedKpiIdentifier.PRIMARY &&
          isEmpty &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.primary[50]};
          `),
        kpiId === HighlightedKpiIdentifier.PRIMARY &&
          !isEmpty &&
          isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.hues.blue[300]};
          `),
        kpiId === HighlightedKpiIdentifier.SECONDARY &&
          !isEmpty &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.secondary[500]};
          `),
        kpiId === HighlightedKpiIdentifier.SECONDARY &&
          isEmpty &&
          !isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.colors.secondary[50]};
          `),
        kpiId === HighlightedKpiIdentifier.SECONDARY &&
          !isEmpty &&
          isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.hues.blue[700]};
          `),
        isEmpty &&
          isForecast &&
          ((theme) => css`
            background-color: ${theme.ds.hues.blue[50]};
          `),
      ]}
    />
  );
});
