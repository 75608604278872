import { subject } from '@casl/ability';
import {
  IconDots,
  IconMessage,
  IconMessageCheck,
  IconMessagePlus,
  IconPencil,
  IconPlusMinus,
} from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Divider, IconButton, MenuDropdown, RadioButtonGroup } from '@amal-ia/frontend/design-system/components';
import { Can } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { objectToQs } from '@amal-ia/lib-ui';

import StatementExportModalContainer from '../../../StatementExportModalContainer';
import { StatementViewEnum } from '../../StatementDetail';

import { messages } from './StatementDetailHeaderActions.messages';
import * as styles from './StatementDetailHeaderActions.styles';
import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderSecondRow = memo(function StatementDetailHeaderSecondRow(
  props: StatementDetailHeaderActionsProps,
) {
  const {
    statement,
    companyFeatureFlags,
    ability,
    handleForecastedSwitch,
    handleExportModalClose,
    handleExportModalSubmit,
    isWorkflowBegun,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    isExportModalOpen,
  } = useStatementDetailHeaderActions(props);
  return (
    <Fragment>
      <div css={styles.container}>
        {companyFeatureFlags.FORECAST && statement?.plan?.isForecasted ? (
          <Fragment>
            <RadioButtonGroup
              name="switch-forecast-view"
              value={statement.isForecastedView ? StatementViewEnum.FORECASTED : StatementViewEnum.ACHIEVED}
              options={[
                {
                  value: StatementViewEnum.ACHIEVED,
                  label: <FormattedMessage {...messages.ACHIEVED} />,
                },
                {
                  value: StatementViewEnum.FORECASTED,
                  label: <FormattedMessage {...messages.FORECASTED} />,
                },
              ]}
              onChange={handleForecastedSwitch}
            />
            <Divider.Vertical />
          </Fragment>
        ) : null}
        <div css={styles.actionContainer}>
          {ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && (
            <IconButton
              withBackground
              data-testid="new-adjustment"
              disabled={isWorkflowBegun || statement.isForecastedView}
              icon={<IconPlusMinus />}
              label={formatMessage(messages.NEW_ADJUSTMENT)}
              onClick={handleNewAdjustment}
            />
          )}
          {ability.can(ActionsEnum.view_statement_threads, subject(SubjectsEnum.Statement, statement)) && (
            <IconButton
              withBackground
              data-testid="comment-statement"
              disabled={statement.isForecastedView}
              label={formatMessage(messages.STATEMENT_COMMENTS)}
              icon={
                statementCommentThread ? (
                  statementCommentThread.thread.isReviewed ? (
                    <IconMessageCheck />
                  ) : (
                    <IconMessagePlus />
                  )
                ) : (
                  <IconMessage />
                )
              }
              onClick={onStatementCommentsClicked}
            />
          )}

          <Can
            a={SubjectsEnum.Plan}
            I={ActionsEnum.modify}
          >
            <Link
              to={{
                pathname: generatePath(routes.DESIGNER_V2_PLAN, {
                  planId: statement.plan.id,
                }),
                search: `?${objectToQs({
                  userId: statement.user.id,
                  periodId: statement.period.id,
                })}`,
              }}
            >
              <IconButton
                withBackground
                icon={<IconPencil />}
                label={formatMessage(messages.EDIT_IN_DESIGNER)}
              />
            </Link>
          </Can>

          <MenuDropdown
            content={
              <div>
                {menuIconsList.map((action) => (
                  <MenuDropdown.Item
                    key={action.label}
                    icon={action.icon}
                    label={action.label}
                    onClick={action.handleClick}
                  />
                ))}
              </div>
            }
          >
            <MenuDropdown.IconButton
              withBackground
              icon={<IconDots />}
              label={formatMessage(messages.MORE_ACTIONS)}
            />
          </MenuDropdown>
        </div>
      </div>

      <StatementExportModalContainer
        handleClose={handleExportModalClose}
        handleConfirm={handleExportModalSubmit}
        isOpened={isExportModalOpen}
        statement={statement}
      />
    </Fragment>
  );
});
