import { keyBy } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { usePeriods } from '@amal-ia/compensation-definition-periods-components';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { IconDataExcel, IconDataPayfit2, IconDataPdf2, IconDataPersonio2 } from '@amal-ia/frontend/ui-icons';
import {
  fetchPlans,
  selectPlansIsLoading,
  selectPlansMap,
  selectCurrentPeriod,
  useThunkDispatch,
  fetchAllTeamsThunkAction,
  selectIsTeamsLoading,
  selectTeamMap,
  selectUserStatementsListSelectedOptions,
  getInError,
  listUsersThatCurrentUserCanSee,
  useDataFetch,
} from '@amal-ia/frontend/web-data-layers';
import { type DataExport, DataExportFormat, DataExportType, type Statement } from '@amal-ia/lib-types';
import {
  type ExportFormEventChange,
  type StatementExportModalContainerProps,
  StatementExportModalPresentation,
} from '@amal-ia/lib-ui-business';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';
import { CompanyFeatureFlags } from '@amal-ia/tenants/companies/types';

const StatementExportModalContainer = memo(function StatementExportModalContainer({
  isOpened,
  handleClose,
  handleConfirm,
  statement,
}: StatementExportModalContainerProps) {
  const dispatch = useThunkDispatch();
  const { snackSuccess } = useSnackbars();
  const { formatMessage } = useIntl();

  const { data: company } = useCurrentCompany();

  const currentPeriod = useSelector(selectCurrentPeriod);

  const listSelectedOptions = useSelector(selectUserStatementsListSelectedOptions);

  const teamsMap = useSelector(selectTeamMap);
  const isTeamsLoading = useSelector(selectIsTeamsLoading);

  const plansMap = useSelector(selectPlansMap);
  const isPlansLoading = useSelector(selectPlansIsLoading);

  const { periodsList, isLoading: isPeriodsLoading } = usePeriods();

  const [statementsInError, setStatementsInError] = useState<Statement[]>([]);

  useAsyncEffect(async () => {
    if (isOpened) {
      await Promise.all([dispatch(fetchPlans()), dispatch(fetchAllTeamsThunkAction())]);
    }
  }, [isOpened]);

  const {
    isLoading: isUsersLoading,
    data: users,
    getData: fetchUsersAccordingToPlanAndTeamsCallback,
  } = useDataFetch(listUsersThatCurrentUserCanSee, []);

  const usersMap = useMemo(() => keyBy(users, (u) => u.id), [users]);

  const onFormChangeHandler = useCallback(
    async ({ eventType, periodIds, planIds, teamIds, userIds }: ExportFormEventChange) => {
      if (['PLAN_CHANGE', 'TEAM_CHANGE'].includes(eventType)) {
        await fetchUsersAccordingToPlanAndTeamsCallback(planIds, teamIds);
      }
      setStatementsInError(
        await getInError({
          periodIds,
          teamIds,
          planIds,
          userIds,
          isForecast: false,
        }),
      );
    },
    [fetchUsersAccordingToPlanAndTeamsCallback],
  );

  // Teams and Plans are already loaded in <Statements /> to avoid multiple fetches
  // But if we are on a statement, we need to reload them
  useAsyncEffect(async () => {
    await Promise.all(
      [!!statement && dispatch(fetchAllTeamsThunkAction()), !!statement && dispatch(fetchPlans())].filter(Boolean),
    );
  }, [statement?.id]);

  // Compute usable export Formats
  const exportFormats = useMemo(
    () => [
      {
        value: DataExportFormat.EXCEL,
        label: (
          <FormattedMessage
            defaultMessage="Excel"
            description="Export to Excel file"
          />
        ),
        icon: <IconDataExcel size={30} />,
      },

      // Enable Personio export only if feature flag is enabled
      ...(company?.featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_PERSONIO]
        ? [
            {
              value: DataExportFormat.PERSONIO,
              label: (
                <FormattedMessage
                  defaultMessage="Personio"
                  description="Export to Personio"
                />
              ),
              icon: <IconDataPersonio2 size={30} />,
            },
          ]
        : []),

      // Enable Payfit export only if feature flag is enabled
      ...(company?.featureFlags?.[CompanyFeatureFlags.USER_CONNECTOR_PAYFIT]
        ? [
            {
              value: DataExportFormat.PAYFIT,
              label: (
                <FormattedMessage
                  defaultMessage="Payfit"
                  description="Export to Payfit"
                />
              ),
              icon: <IconDataPayfit2 size={30} />,
            },
          ]
        : []),
      // Enable PDF export only if feature flag is enabled
      ...(company?.featureFlags?.[CompanyFeatureFlags.STATEMENT_PDF_EXPORT]
        ? [
            {
              value: DataExportFormat.PDF,
              label: (
                <FormattedMessage
                  defaultMessage="PDF"
                  description="Export to PDF file"
                />
              ),
              icon: <IconDataPdf2 size={30} />,
            },
          ]
        : []),
    ],
    [company],
  );

  // If we're on statement, take user's teams. Otherwise, take filter values
  const defaultTeamIds = useMemo(
    () =>
      statement?.results
        ? (statement?.results?.computedTeams || []).map((ct) => ct.teamId)
        : listSelectedOptions?.team?.value
          ? [listSelectedOptions.team.value]
          : [],
    [statement?.results, listSelectedOptions],
  );

  // If we're on statement, take user's plan. Otherwise, take filter values
  const defaultPlanIds = useMemo(
    () =>
      statement?.results
        ? statement?.plan?.id
          ? [statement.plan.id]
          : []
        : listSelectedOptions?.plan?.value
          ? [listSelectedOptions.plan.value]
          : [],
    [statement?.results, statement?.plan, listSelectedOptions],
  );

  // Migrating default values to correct ones
  const usableDefaultValues = useMemo(() => {
    const userIds: string[] = statement?.user?.id ? [statement.user.id] : [];

    return {
      type: DataExportType.STATEMENT,
      format: DataExportFormat.EXCEL,
      params: {
        teamIds: defaultTeamIds,
        planIds: defaultPlanIds,
        userIds,
        isForecast: false,
        periodIds: currentPeriod ? [currentPeriod.id] : [],
      },
    };
  }, [defaultPlanIds, defaultTeamIds, statement, currentPeriod]);

  useAsyncEffect(
    () =>
      isOpened &&
      onFormChangeHandler({
        eventType: 'PLAN_CHANGE',
        periodIds: currentPeriod ? [currentPeriod.id] : [],
        planIds: defaultPlanIds,
        teamIds: defaultTeamIds,
        userIds: [],
      }),
    [defaultTeamIds, defaultPlanIds, currentPeriod, isOpened],
  );

  const handleSubmit = useCallback(
    async (formValues: Partial<DataExport>) => {
      await handleConfirm({
        ...formValues,
        params: formValues.params,
        type: formValues.type || DataExportType.STATEMENT,
        format: formValues.format || DataExportFormat.EXCEL,
      });
      snackSuccess(
        formatMessage({
          defaultMessage: 'Export is being computed. You will receive a notification and an email when finished!',
        }),
      );
    },
    [handleConfirm, snackSuccess, formatMessage],
  );

  return (
    <StatementExportModalPresentation
      defaultValues={usableDefaultValues}
      exportFormats={exportFormats}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isOpened={isOpened}
      isPeriodsLoading={isPeriodsLoading}
      isPlansLoading={isPlansLoading}
      isTeamsLoading={isTeamsLoading}
      isUsersLoading={isUsersLoading}
      periodList={periodsList}
      plansMap={plansMap}
      statementsInError={statementsInError}
      teamsMap={teamsMap}
      usersMap={usersMap}
      onFormChangeHandler={onFormChangeHandler}
    />
  );
});

export default StatementExportModalContainer;
