import { useFormikContext } from 'formik';
import { type ChangeEvent, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { Input } from '@amal-ia/lib-ui';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';
import { getThePercentageOfValue } from '../../overwrite.utils';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export const OVERWRITE_INPUT_TEST_ID = `${OverwriteFormField.PERCENTAGE}-input` as const;

export type PercentOverwriteSelectorProps = CommonOverwriteSelectorProps & {
  readonly formFieldContext: OverwriteFormField.NEW_VALUE | OverwriteFormField.PERCENTAGE;
};

export const PercentOverwriteSelector = memo(function PercentOverwriteSelector({
  currentObjectDetails,
  formFieldContext,
}: PercentOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      // If the field type is percentage, we need to calculate the new value
      if (OverwriteFormField.NEW_VALUE === formFieldContext) {
        await setFieldValue(OverwriteFormField.NEW_VALUE, e.target.value);
      } else {
        // If the field type is number / currency, we also propose the new value based on the percentage
        await setFieldValue(OverwriteFormField.PERCENTAGE, e.target.value);
        setFieldValue(
          OverwriteFormField.NEW_VALUE,
          `${getThePercentageOfValue(currentObjectDetails.oldValue as CurrencyValue | number, Number(e.target.value))}`,
        );
      }
    },
    [currentObjectDetails, formFieldContext, setFieldValue],
  );

  return (
    <Input
      autoFocus
      data-testid={OVERWRITE_INPUT_TEST_ID}
      endAdornment={<span>%</span>}
      id={formFieldContext}
      inputProps={{ 'aria-label': formFieldContext }}
      name={formFieldContext}
      placeholder={formatMessage(messages[FormatsEnum.percent])}
      value={values[formFieldContext]}
      onChange={handleChange}
    />
  );
});
