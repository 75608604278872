import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { HidableElementVisibility, type PlansMap } from '@amal-ia/compensation-definition/plans/types';
import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Checkbox, ListElement } from '@amal-ia/lib-ui';

import { DesignerItemWithContext } from '../../../DesignerItemWithContext/DesignerItemWithContext';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  listElement: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

interface PlanRuleSelectFieldsModalListElementProps {
  readonly name: string;
  readonly contextPlanName?: string;
  readonly label: string;
  readonly displayStatus?: HidableElementVisibility;
  readonly onChangeDisplayStatus?: (name: string) => any;
  readonly dndProvidedProps: any;
  readonly plansMap: PlansMap;
}

export const PlanRuleSelectFieldsModalListElement = memo(function PlanRuleSelectFieldsModalListElement({
  name,
  contextPlanName,
  label,
  displayStatus,
  dndProvidedProps,
  onChangeDisplayStatus,
  plansMap,
}: PlanRuleSelectFieldsModalListElementProps) {
  const classes = useStyles();

  return (
    <ListElement
      {...dndProvidedProps.draggableProps}
      className={classes.listElement}
      handleProps={dndProvidedProps.dragHandleProps}
      innerRef={dndProvidedProps.innerRef}
    >
      <DesignerItemWithContext
        nameOverride={label}
        planNameOverride={contextPlanName}
        plansMap={plansMap}
      />
      {displayStatus && onChangeDisplayStatus ? (
        <Checkbox
          compact
          checked={displayStatus === HidableElementVisibility.ON_DISPLAY}
          color="tertiary"
          onChange={() => onChangeDisplayStatus(name)}
        />
      ) : null}
    </ListElement>
  );
});
