import { IconLayoutGrid, IconLayoutList, IconTimeline } from '@tabler/icons-react';
import { useState, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { RadioButtonGroup } from '@amal-ia/frontend/design-system/components';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';

import Charts from './Charts/Charts';
import { KPIList } from './kpis/KPIList';

enum RuleView {
  KPI = 'kpi',
  CHART = 'chart',
}

interface RuleResultAccessoryResultsProps {
  readonly ruleDefinition: PlanRule;
}

const RuleResultAccessoryResults = memo(function RuleResultAccessoryResults({
  ruleDefinition,
}: RuleResultAccessoryResultsProps) {
  const [selectedView, setSelectedView] = useState<RuleView>(RuleView.KPI);
  const { formatMessage } = useIntl();
  const { legacyKpiCardView } = useCompanyCustomization();

  const viewOptions = useMemo(
    () => [
      {
        value: RuleView.KPI,
        icon: legacyKpiCardView ? <IconLayoutGrid /> : <IconLayoutList />,
        label: formatMessage({ defaultMessage: 'Variables' }),
      },
      {
        value: RuleView.CHART,
        icon: <IconTimeline />,
        label: formatMessage({ defaultMessage: 'Charts' }),
      },
    ],
    [formatMessage, legacyKpiCardView],
  );

  // If no charts are available, print only KPIs
  if ((ruleDefinition.charts || []).length === 0) {
    return <KPIList ruleDefinition={ruleDefinition} />;
  }

  const viewSelector = (
    <RadioButtonGroup<RuleView>
      name="ruleViewRadio"
      options={viewOptions}
      value={selectedView}
      onChange={setSelectedView}
    />
  );

  switch (selectedView) {
    case RuleView.KPI:
      return (
        <KPIList
          ruleDefinition={ruleDefinition}
          viewSelector={viewSelector}
        />
      );
    case RuleView.CHART:
      return (
        <Charts
          ruleDefinition={ruleDefinition}
          viewSelector={viewSelector}
        />
      );
    default:
      return null;
  }
});

export default RuleResultAccessoryResults;
