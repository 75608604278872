import { type EditorState } from '@tiptap/pm/state';
import { type Node } from 'prosemirror-model';

export const traverseTextNodes = (doc: EditorState['doc'], callback: (node: Node, position: number) => void) => {
  doc.descendants((node, pos) => {
    if (node.type.name === 'text') {
      callback(node, pos);
    }
  });
};
