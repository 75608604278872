import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';
import { filterComputedTeamsOverPeriod, sortComputedTeams } from '@amal-ia/frontend/web-data-layers';
import { type ComputedTeam, formatUserFullName, type Statement } from '@amal-ia/lib-types';
import { Avatar } from '@amal-ia/lib-ui';
import { type UserComputed, type UserContract } from '@amal-ia/tenants/users/shared/types';

import { messages } from './StatementDetailHeaderUserInformations.messages';
import * as styles from './StatementDetailHeaderUserInformations.styles';

type StatementDetailHeaderUserInformationsProps = {
  readonly statement?: Statement;
  readonly user: UserComputed | UserContract;
};

export const StatementDetailHeaderUserInformations = memo(function StatementDetailHeaderUserInformationsBase({
  statement,
  user,
}: StatementDetailHeaderUserInformationsProps) {
  const { formatMessage } = useIntl();

  const teamName = useMemo(() => {
    const statementComputedTeams: ComputedTeam[] = statement?.results?.computedTeams ?? [];
    let teamToShow: ComputedTeam = statementComputedTeams[0];

    if (statementComputedTeams.length) {
      if (statement.results?.planAssignment?.mainTeamId) {
        const mainTeam = statementComputedTeams.find((t) => t.teamId === statement.results.planAssignment.mainTeamId);
        if (mainTeam) {
          teamToShow = mainTeam;
        }
      } else {
        const userTeams = statementComputedTeams
          .filter((t) => filterComputedTeamsOverPeriod(t, statement.period))
          .sort(sortComputedTeams);
        teamToShow = userTeams[0];
      }
    }

    if (teamToShow) {
      return formatMessage(messages.ARCHIVED, {
        teamName: teamToShow.name,
        isArchived: teamToShow.isTeamArchived,
      });
    }

    return null;
  }, [formatMessage, statement]);

  return (
    <div css={styles.container}>
      <Avatar
        css={styles.avatar}
        user={user}
      />
      <div css={styles.nameAndTeamContainer}>
        <Typography variant={Typography.Variant.HEADING_1_BOLD}>{formatUserFullName(user)}</Typography>
        {teamName ? <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>{teamName}</Typography> : null}
      </div>
    </div>
  );
});
