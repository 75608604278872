import { isEmpty, noop } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate, type To } from 'react-router-dom';

import { type VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { routes } from '@amal-ia/common/routes';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { RightSidebarPortal } from '@amal-ia/frontend/connected-components/layout';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import {
  fetchUsers,
  getCustomObjectDefinitionFromDefinitionIdInStatemnet,
  getDatasetNameFromStatementDefinition,
  getRuleDefinitionFromIdInStatement,
  getVariableDefinitionFromIdInStatement,
  getVariableDefinitionFromMachineNameInStatement,
  refreshTodosCount,
  selectCurrentStatement,
  selectUsersMap,
  useThunkDispatch,
} from '@amal-ia/frontend/web-data-layers';
import { objectToQs } from '@amal-ia/lib-ui';
import {
  CommentDrawerPresentation,
  CommentMessage,
  EditableStatementCommentContext,
  type EditableStatementCommentContextValue,
  NoCommentsFound,
} from '@amal-ia/payout-collaboration/comments/components';
import {
  type CommentThreadMessage,
  type MessageContent,
  type StatementThread,
  type StatementThreadScope,
  StatementThreadScopesType,
} from '@amal-ia/payout-collaboration/comments/shared/types';
import {
  useReviewStatementThreadMutation,
  useStatementThreadMessages,
} from '@amal-ia/payout-collaboration/comments/state';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

interface CommentDrawerContainerProps {
  readonly statementThread?: StatementThread;
  readonly statementThreadScope?: StatementThreadScope;
  readonly handleNewMessage: (messageContent: MessageContent[], scope?: StatementThreadScope) => Promise<void>;
  readonly closeDrawer: () => void;
}

// Shows an entire comment message thread
export const CommentDrawerContainer = memo(function CommentDrawerContainer({
  statementThread,
  statementThreadScope,
  handleNewMessage,
  closeDrawer,
}: CommentDrawerContainerProps) {
  const dispatch = useThunkDispatch();

  const statement = useSelector(selectCurrentStatement);
  const navigate = useNavigate();
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
  const { data: currentUser } = useCurrentUser();

  const [editedCommentId, setEditedCommentId] = useState<string | undefined>(undefined);
  const [editedCommentContent, setEditedCommentContent] = useState<MessageContent[]>([]);

  // Current thread scope (variable / cell), undefined if the scope is global
  const scope = statementThread?.scope || statementThreadScope || undefined;

  // Get the users map to have messages' authors infos
  const threadUsers: UsersMap = useSelector(selectUsersMap);

  const closeOpenedThread = useCallback(() => {
    closeDrawer();
    // change the URL to close it.
    // If there's an threadId, it will dispatch an action to close it
    navigate(generatePath(routes.STATEMENT, { id: statement.id }));
  }, [navigate, statement.id, closeDrawer]);

  // Sorted comments
  const { data: sortedComments } = useStatementThreadMessages(statement?.id, statementThread?.id);
  useEffect(() => {
    // If we have a thread, load related data
    if (!isEmpty(sortedComments)) {
      // Compute authors ids and fetch associated users
      const userIds = sortedComments.map((msg: CommentThreadMessage) => msg.authorId);

      dispatch(fetchUsers(userIds)).catch(noop);
    }
  }, [sortedComments, dispatch]);

  // Handle new comment form
  const handleNewComment = useCallback(
    async (commentMessage: MessageContent[]): Promise<void> => {
      // Add a message on this thread
      await Promise.all([
        // Post the message.
        handleNewMessage(commentMessage, scope),
        // Also fetch me, now than i'm part of the discussion.
        dispatch(fetchUsers([currentUser.id])),
      ]);
    },
    [handleNewMessage, currentUser, dispatch, scope],
  );

  useEffect(() => {
    const scrollToBottom = () => {
      endOfMessagesRef?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    scrollToBottom();
  }, [sortedComments]);

  const { mutateAsync: reviewStatementThread } = useReviewStatementThreadMutation();
  const handleReview = async (checked: boolean) => {
    if (!statement?.id || !statementThread?.id) {
      return;
    }

    // Mark thread as reviewed.
    await reviewStatementThread({
      statementId: statement.id,
      statementThreadId: statementThread.id,
      isReviewed: checked,
    });
    // And update todos.
    await dispatch(refreshTodosCount());
  };

  const threadDetails = useMemo(() => {
    if (scope?.type === StatementThreadScopesType.OBJECT) {
      // Get rule and variables name
      const rule = getRuleDefinitionFromIdInStatement(statement.results, scope.ruleId)?.name;

      const variableName =
        getVariableDefinitionFromMachineNameInStatement(statement.results, scope.field)?.name || scope.field;

      const variableType: VariableObjectsEnum = getVariableDefinitionFromMachineNameInStatement(
        statement.results,
        scope.field,
      )?.type;

      // Get custom object definition and field from schema
      const customObjectDefinition = getCustomObjectDefinitionFromDefinitionIdInStatemnet(
        statement.results,
        scope.definitionId,
      );

      let link: To;
      if (customObjectDefinition) {
        link = {
          pathname: customObjectDefinition.machineName
            ? generatePath(routes.DATA_SLUG, { slug: customObjectDefinition.machineName })
            : generatePath(routes.DATA),
          search: `?${objectToQs({ search: scope.id })}`,
        };
      }

      const dataset = scope?.datasetMachineName
        ? getDatasetNameFromStatementDefinition(statement.results, scope.datasetMachineName)
        : undefined;

      return {
        dataset,
        rule,
        dataSource: {
          label: scope?.name ? scope.name : scope?.id,
          link,
          type: variableType,
        },
        variable: variableName,
      };
    }

    if (scope?.type === StatementThreadScopesType.KPI) {
      // Get rule name and KPI variable name
      const ruleName = getRuleDefinitionFromIdInStatement(statement.results, scope?.ruleId)?.name;
      const variableName = getVariableDefinitionFromIdInStatement(statement.results, scope?.id)?.name;

      return {
        rule: ruleName,
        variable: variableName,
      };
    }

    return {};
  }, [scope, statement]);

  const editableContextValues: EditableStatementCommentContextValue = useShallowObjectMemo({
    statementThreadId: statementThread?.id,
    editedCommentId,
    editedCommentContent,
    setEditedCommentId,
    setEditedCommentContent,
  });
  return (
    <RightSidebarPortal>
      <EditableStatementCommentContext.Provider value={editableContextValues}>
        <CommentDrawerPresentation
          closeOpenedThread={closeOpenedThread}
          commentScope={threadDetails}
          handleNewComment={handleNewComment}
          isReviewed={statementThread?.thread.isReviewed || false}
          setIsReviewed={handleReview}
          thread={statementThread?.thread}
        >
          {sortedComments?.map((message: CommentThreadMessage) => (
            <CommentMessage
              key={message.id}
              author={threadUsers[message.authorId]}
              message={message}
              statementId={statement.id}
              statementThreadId={statementThread?.id}
            />
          ))}
          <div
            ref={endOfMessagesRef}
            style={{ paddingBottom: '15px' }}
          />

          {!sortedComments?.length && <NoCommentsFound />}
        </CommentDrawerPresentation>
      </EditableStatementCommentContext.Provider>
    </RightSidebarPortal>
  );
});
