import moment from 'moment';

import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { TokenType } from '@amal-ia/lib-types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.endOfQuarter, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 1;

func.description = 'Return the last day of quarter of a given date';

func.params = [
  {
    name: 'date',
    description:
      'Date to apply function on: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
];

func.examples = [
  {
    desc: 'Returns "2023-06-30"',
    formula: 'endOfQuarter(toDate("2023-05-19"))' as AmaliaFormula,
    result: 1_688_083_200,
  },
  {
    desc: "Returns last day of quarter of the user's plan assignment start date.",
    formula: 'endOfQuarter(planAssignement.effectiveAsOf)' as AmaliaFormula,
  },
  {
    desc: 'Returns last day of quarter of opportunity close date.',
    formula: 'endOfQuarter(opportunity.closeDate)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string): number | null =>
  +moment(date, 'X').endOf('quarter').startOf('day').format('X') || null;

export default func;
