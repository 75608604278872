import { defineMessages } from 'react-intl';

export const formulaBuilderMessages = defineMessages({
  SWITCH_DATA_OBJECT: {
    defaultMessage: 'Switch data object',
  },

  ADD_CONDITION_GROUP: {
    defaultMessage: 'Add a group of conditions',
  },

  CLEAR_ALL: {
    defaultMessage: 'Clear all',
  },

  DISABLED_ADD_CONDITION_GROUP_TOOLTIP: {
    defaultMessage: 'To add conditions, start by selecting a data object in the settings on the right.',
  },
} as const);
