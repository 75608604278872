import clsx from 'clsx';
import { memo } from 'react';
import { FormattedNumber } from 'react-intl';

import {
  type ComputedHighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amal-ia/compensation-definition/plans/types';
import { Typography } from '@amal-ia/frontend/design-system/components';

import { StatementKpiDot } from '../statement-kpi-dot/StatementKpiDot';

import * as styles from './StatementKpi.styles';

const EMPTY_KPI = '— %';

export type StatementKpiProps = {
  readonly kpiId: HighlightedKpiIdentifier;
  readonly kpi: ComputedHighlightedKpi;
  readonly isForecast?: boolean;
};

export const StatementKpi = memo(function StatementKpi({ kpiId, kpi, isForecast = false }: StatementKpiProps) {
  const isEmpty = !Number.isFinite(kpi?.progress);

  return (
    <li
      className={clsx({ [styles.IS_EMPTY_CLASSNAME]: isEmpty })}
      css={styles.statementKpi}
    >
      <StatementKpiDot
        isEmpty={isEmpty}
        isForecast={isForecast}
        kpiId={kpiId}
      />

      <Typography variant={Typography.Variant.BODY_XSMALL_REGULAR}>
        {Number.isFinite(kpi?.progress) ? (
          <FormattedNumber
            style="percent"
            value={kpi.progress / 100}
          />
        ) : (
          EMPTY_KPI
        )}
      </Typography>
    </li>
  );
});
