import { type CustomReportColumn, type PayoutAndPerformanceChartStatistics } from '@amal-ia/lib-types';

/**
 * Kpi columns are all columns except the payment value and the achievement period.
 * @param statistics
 */
export const findPayoutAndPerformanceChartStatisticsKpiColumns = (
  statistics: PayoutAndPerformanceChartStatistics,
): CustomReportColumn[] | undefined =>
  Object.values(statistics?.definitions || {}).filter(
    (definition) =>
      ![
        statistics.definitions.ruleMetricPayment__value.identifier,
        statistics.definitions.ruleMetricPeriod__month.identifier,
      ].includes(definition.identifier),
  );
