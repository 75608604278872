import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Form, Formik } from 'formik';
import { max } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { type Variable, VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import {
  type Filter,
  type PlanRule,
  type PlanRuleChart,
  PlanRuleChartDetails,
  PlanRuleChartEnumType,
} from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinitionsMap } from '@amal-ia/data-capture/models/types';
import { amaliaTheme } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { filterVariablesThatContainsKeywords } from '@amal-ia/lib-types';
import {
  DialogTitleWithCloseButton,
  type Option,
  type OptionType,
  Radios,
  SelectField,
  TextField,
} from '@amal-ia/lib-ui';

export interface SelectedPlanRuleChart {
  rule: PlanRule;
  chart?: PlanRuleChart;
}

interface PlanRuleChartsModalProps {
  readonly availableVariables: Variable[];
  readonly availableFilters: Filter[];
  readonly customObjectDefinitionsMap: CustomObjectDefinitionsMap;
  readonly onPatchRules: (rulesToPatch: PlanRule[]) => any;
  readonly onCancel: () => any;
  readonly selectedChart: SelectedPlanRuleChart | null;
}

const typeOptions: OptionType[] = Object.entries(PlanRuleChartDetails).map(([key, { name }]) => ({
  label: name,
  value: key,
}));

export const PlanRuleChartsModal = memo(function PlanRuleChartsModal({
  availableVariables,
  availableFilters,
  customObjectDefinitionsMap,
  onPatchRules,
  onCancel,
  selectedChart,
}: PlanRuleChartsModalProps) {
  const validationSchema = useMemo(() => {
    const schema = Yup.object().shape({
      configuration: Yup.object().when('type', ([value]: [PlanRuleChartEnumType], yupSchema) => {
        if (value === PlanRuleChartEnumType.STATEMENT_METRIC) {
          return yupSchema.shape({
            metricsFilterId: Yup.string().required(),
            metricsPropertyMachineName: Yup.string().required(),
          });
        }

        if (value === PlanRuleChartEnumType.TARGET_ACHIEVEMENT) {
          return yupSchema.shape({
            targetAchievementVariableId: Yup.string().required(),
          });
        }

        return yupSchema.optional();
      }),
      id: Yup.string().required(),
      name: Yup.string(),
      type: Yup.string().required(),
    });

    Yup.reach(schema, 'configuration');
    return schema;
  }, []);

  const initialValues = useMemo(() => {
    // Some ugly code. If chart has no id yet, we compute max of ids of charts (that are number) then add 1
    const id =
      selectedChart?.chart?.id ||
      ((max((selectedChart?.rule.charts || []).map((c) => parseInt(c.id, 10))) || 0) + 1).toString();
    return {
      id,
      name: selectedChart?.chart?.name || '',
      type: selectedChart?.chart?.type || PlanRuleChartEnumType.TARGET_ACHIEVEMENT,
      configuration: selectedChart?.chart?.configuration || {
        targetAchievementVariableId: '',
        metricsFilterId: '',
        metricsPropertyMachineName: '',
      },
    };
  }, [selectedChart]);

  const onSubmitProxy = useCallback(
    (values: PlanRuleChart) => {
      if (selectedChart?.rule) {
        const newCharts = (selectedChart.rule.charts || [])
          // Remove existing filter with id
          .filter((c) => c.id !== values.id);

        // Add new chart
        newCharts.push(values);

        onPatchRules([
          {
            ...selectedChart.rule,
            charts: newCharts,
          },
        ]);
      }
    },
    [selectedChart, onPatchRules],
  );

  const targetAchievementVariableOptions = useMemo(
    () =>
      filterVariablesThatContainsKeywords(availableVariables, ['TIER', 'LINEAR'])
        .filter((v) => v.type === VariableObjectsEnum.statement)
        .map((v) => ({
          label: v.name,
          value: v.id,
        })),
    [availableVariables],
  );

  const metricsFilterOptions = useMemo(
    () =>
      availableFilters
        .filter((f) => f.virtualObjectMachineName === 'statementMetric')
        .map((f) => ({
          label: f.name,
          value: f.id,
        })),
    [availableFilters],
  );

  const metricsFields = useMemo(
    () =>
      Object.values(customObjectDefinitionsMap.statementMetric?.properties || {}).filter((p) =>
        [FormatsEnum.number, FormatsEnum.currency, FormatsEnum.percent].includes(p.format),
      ),
    [customObjectDefinitionsMap],
  );

  const metricsFieldOptions: Option[] = useMemo(
    () =>
      metricsFields
        .map((mf) => ({
          value: mf.machineName,
          label: mf.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [metricsFields],
  );

  return (
    <Dialog
      fullWidth
      open={!!selectedChart}
      onClose={onCancel}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitProxy}
      >
        {({ values, isValid, dirty }) => (
          <Form>
            <DialogTitleWithCloseButton handleClose={onCancel}>Chart Configuration</DialogTitleWithCloseButton>
            <DialogContent>
              <TextField
                label="Name"
                name="name"
              />
              <Radios
                label="Type of Chart"
                name="type"
                options={typeOptions}
              />
              {values.type === PlanRuleChartEnumType.TARGET_ACHIEVEMENT && (
                <SelectField
                  required
                  help="Select here a variable that contains tier / linear target reach computation"
                  id="targetAchievementVariableId"
                  label="Tier / Linear Target Variable"
                  name="configuration.targetAchievementVariableId"
                  options={targetAchievementVariableOptions}
                />
              )}
              {values.type === PlanRuleChartEnumType.STATEMENT_METRIC && (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  gap={amaliaTheme.spacing(2)}
                  width="100%"
                >
                  <Box flex="1">
                    <SelectField
                      required
                      help="Only select a filter that gather metrics for a certain period of time and for current salesRep"
                      id="metricsFilterId"
                      label="Metrics Filter"
                      name="configuration.metricsFilterId"
                      options={metricsFilterOptions}
                    />
                  </Box>
                  <Box flex="1">
                    <SelectField
                      required
                      help="The property from which chart data will be computed"
                      id="metricsField"
                      label="Metrics Field"
                      name="configuration.metricsPropertyMachineName"
                      options={metricsFieldOptions}
                    />
                  </Box>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant={Button.Variant.LIGHT}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || !dirty}
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
});
