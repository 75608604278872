import { DataConnectorTypes } from '@amal-ia/data-capture/connectors/types';
import { type Property } from '@amal-ia/data-capture/fields/types';
import { type Merge } from '@amal-ia/ext/typescript';
import { type Company } from '@amal-ia/tenants/companies/types';

export type Properties = Record<string, Property>;

export enum CustomObjectDefinitionType {
  AMALIA = 'AMALIA',
  VIRTUAL = 'VIRTUAL',
  SALESFORCE = 'SALESFORCE',
  HUBSPOT = 'HUBSPOT',
  NETSUITE = 'NETSUITE',
  GOOGLESHEET = 'GOOGLESHEET',
  REDSHIFT = 'REDSHIFT',
  SNOWFLAKE = 'SNOWFLAKE',
  POSTGRESQL = 'POSTGRESQL',
  POWERBI = 'POWERBI',
  SQLSERVER = 'SQLSERVER',
  MYSQL = 'MYSQL',
  BIGQUERY = 'BIGQUERY',
  ZOHO = 'ZOHO',
  SALESFORCE_SECOND_ACCOUNT = 'SALESFORCE_SECOND_ACCOUNT',
  SALESFORCE_THIRD_ACCOUNT = 'SALESFORCE_THIRD_ACCOUNT',
  PERSONIO = 'PERSONIO',
  ADPWORKFORCENOW = 'ADP_WORKFORCE_NOW',
  SAPSUCCESSFACTORS = 'SAP_SUCCESSFACTORS',
  SAGEHR = 'SAGE_HR',
  BAMBOOHR = 'BAMBOOHR',
  WORKDAY = 'WORKDAY',
}

export interface BaseCustomObjectDefinition {
  id: string;

  company?: Company;
  type: CustomObjectDefinitionType;
  name: string;
  machineName: string;

  properties: Properties;

  externalIds: string[];
}

/*
 * TODO: Maybe rename to "CapturedModel" ?
 * Or "{Captured|Imported}{Data|Record}Model" ?
 *
 * FIXME: some of these fields should not be optional (id, type, company).
 */
export type CustomObjectDefinition = Merge<
  BaseCustomObjectDefinition,
  {
    createdAt: Date;
    updatedAt: Date;
    nameField: string | null;
  }
>;

export const isCustomObjectDefinition = (obj: BaseCustomObjectDefinition): obj is CustomObjectDefinition =>
  typeof obj === 'object' && 'type' in obj && obj.type !== CustomObjectDefinitionType.VIRTUAL;

export type VirtualCustomObjectDefinition = Merge<
  BaseCustomObjectDefinition,
  { type: CustomObjectDefinitionType.VIRTUAL }
>;

export type CreateCustomObjectDefinition = Merge<
  /** those are auto generated */
  Omit<CustomObjectDefinition, 'createdAt' | 'id' | 'machineName' | 'updatedAt'>,
  /** those have default values */
  Partial<Pick<CustomObjectDefinition, 'properties'>>
>;

export type CustomObjectDefinitionsMap = Record<string, CustomObjectDefinition>;

export enum VirtualCustomObjects {
  STATEMENT_METRIC = 'statementMetric',
  OBJECT_METRIC = 'objectMetric',
  QUOTA = 'quota',
}

export const HUMAN_READABLE_VIRTUAL_CUSTOM_OBJECT_DEFINITION: Record<VirtualCustomObjects, string> = {
  [VirtualCustomObjects.STATEMENT_METRIC]: 'Statement Metric',
  [VirtualCustomObjects.OBJECT_METRIC]: 'Object Metric',
  [VirtualCustomObjects.QUOTA]: 'Quota',
};

export const CustomObjectDefToDataConnectorType: Record<CustomObjectDefinitionType, DataConnectorTypes | null> = {
  [CustomObjectDefinitionType.SALESFORCE]: DataConnectorTypes.SALESFORCE,
  [CustomObjectDefinitionType.SALESFORCE_SECOND_ACCOUNT]: DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT,
  [CustomObjectDefinitionType.SALESFORCE_THIRD_ACCOUNT]: DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT,
  [CustomObjectDefinitionType.HUBSPOT]: DataConnectorTypes.HUBSPOT,
  [CustomObjectDefinitionType.NETSUITE]: DataConnectorTypes.NETSUITE,
  [CustomObjectDefinitionType.GOOGLESHEET]: DataConnectorTypes.GOOGLESHEET,
  [CustomObjectDefinitionType.REDSHIFT]: DataConnectorTypes.REDSHIFT,
  [CustomObjectDefinitionType.SNOWFLAKE]: DataConnectorTypes.SNOWFLAKE,
  [CustomObjectDefinitionType.POSTGRESQL]: DataConnectorTypes.POSTGRESQL,
  [CustomObjectDefinitionType.POWERBI]: DataConnectorTypes.POWERBI,
  [CustomObjectDefinitionType.SQLSERVER]: DataConnectorTypes.SQLSERVER,
  [CustomObjectDefinitionType.MYSQL]: DataConnectorTypes.MYSQL,
  [CustomObjectDefinitionType.BIGQUERY]: DataConnectorTypes.BIGQUERY,
  [CustomObjectDefinitionType.ZOHO]: DataConnectorTypes.ZOHO,
  [CustomObjectDefinitionType.PERSONIO]: DataConnectorTypes.PERSONIO,
  [CustomObjectDefinitionType.ADPWORKFORCENOW]: DataConnectorTypes.ADPWORKFORCENOW,
  [CustomObjectDefinitionType.SAPSUCCESSFACTORS]: DataConnectorTypes.SAPSUCCESSFACTORS,
  [CustomObjectDefinitionType.SAGEHR]: DataConnectorTypes.SAGEHR,
  [CustomObjectDefinitionType.BAMBOOHR]: DataConnectorTypes.BAMBOOHR,
  [CustomObjectDefinitionType.WORKDAY]: DataConnectorTypes.WORKDAY,
  [CustomObjectDefinitionType.AMALIA]: null,
  [CustomObjectDefinitionType.VIRTUAL]: null,
} as const;

export const DataConnectorTypeToCustomObjectDef: Record<DataConnectorTypes, CustomObjectDefinitionType | null> = {
  [DataConnectorTypes.SALESFORCE]: CustomObjectDefinitionType.SALESFORCE,
  [DataConnectorTypes.SALESFORCE_SECOND_ACCOUNT]: CustomObjectDefinitionType.SALESFORCE_SECOND_ACCOUNT,
  [DataConnectorTypes.SALESFORCE_THIRD_ACCOUNT]: CustomObjectDefinitionType.SALESFORCE_THIRD_ACCOUNT,
  [DataConnectorTypes.HUBSPOT]: CustomObjectDefinitionType.HUBSPOT,
  [DataConnectorTypes.NETSUITE]: CustomObjectDefinitionType.NETSUITE,
  [DataConnectorTypes.GOOGLESHEET]: CustomObjectDefinitionType.GOOGLESHEET,
  [DataConnectorTypes.REDSHIFT]: CustomObjectDefinitionType.REDSHIFT,
  [DataConnectorTypes.SNOWFLAKE]: CustomObjectDefinitionType.SNOWFLAKE,
  [DataConnectorTypes.POSTGRESQL]: CustomObjectDefinitionType.POSTGRESQL,
  [DataConnectorTypes.POWERBI]: CustomObjectDefinitionType.POWERBI,
  [DataConnectorTypes.SQLSERVER]: CustomObjectDefinitionType.SQLSERVER,
  [DataConnectorTypes.MYSQL]: CustomObjectDefinitionType.MYSQL,
  [DataConnectorTypes.BIGQUERY]: CustomObjectDefinitionType.BIGQUERY,
  [DataConnectorTypes.ZOHO]: CustomObjectDefinitionType.ZOHO,
  [DataConnectorTypes.BAMBOOHR]: CustomObjectDefinitionType.BAMBOOHR,
  [DataConnectorTypes.ADPWORKFORCENOW]: CustomObjectDefinitionType.ADPWORKFORCENOW,
  [DataConnectorTypes.PERSONIO]: CustomObjectDefinitionType.PERSONIO,
  [DataConnectorTypes.SAPSUCCESSFACTORS]: CustomObjectDefinitionType.SAPSUCCESSFACTORS,
  [DataConnectorTypes.SAGEHR]: CustomObjectDefinitionType.SAGEHR,
  [DataConnectorTypes.WORKDAY]: CustomObjectDefinitionType.WORKDAY,
} as const;
