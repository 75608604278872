import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { difference } from 'lodash';
import { Fragment, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { Avatar } from './Avatar';
import { RemainUsersPopUp } from './RemainUsersPopUp/RemainUsersPopUp';
import { LightTooltip } from './Tooltip/Tooltip';
import { TextType, Text } from './typography';

interface AvatarGroupWithToolTipProp {
  readonly users: UserContract[];
  readonly max?: number;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  extarRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 -3px',
  },
  avatar: {
    color: theme.palette.grey[700],
    width: theme.spacing(6),
    height: theme.spacing(6),
    margin: '0 -3px',
    border: `2px solid ${theme.palette.common.white}`,
  },
  inactiveUsers: {
    color: theme.palette.grey['600'],
  },
  grayedAvatar: {
    filter: 'grayscale(1)',
  },
  moreThanLimit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '100%',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    cursor: 'pointer',
    height: '48px',
    width: '48px',
  },
  popup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  popupAvatar: {
    margin: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.grey[700],
  },
}));

export const AvatarGroupWithUserToolTip = memo(function AvatarGroupWithUserToolTip({
  users,
  max = 4,
}: AvatarGroupWithToolTipProp) {
  const classes = useStyles();
  const firstAssignments = (users || []).slice(0, max);
  const usersRemaining = difference(users, firstAssignments);

  const firstAssignmentsAvatars = useMemo(
    () =>
      (firstAssignments || []).map((user: UserContract, index: number) => (
        <Box
          key={user.id}
          className={classes.root}
          display="flex"
          style={{ zIndex: max - index }}
        >
          <LightTooltip
            className={clsx(user.clearedAt && classes.inactiveUsers)}
            title={
              <Box className={classes.popup}>
                <Avatar
                  className={classes.popupAvatar}
                  user={user}
                />
                <Text type={TextType.USERS_IN_TOOLTIP}>{formatUserFullName(user)}</Text>
              </Box>
            }
          >
            <Box>
              <Avatar
                className={clsx(user.clearedAt ? classes.grayedAvatar : classes.avatar)}
                user={user}
              />
            </Box>
          </LightTooltip>
        </Box>
      )),
    [classes, max, firstAssignments],
  );

  const extraAvatar = useMemo(
    () => (
      <Box className={classes.extarRoot}>
        <RemainUsersPopUp
          allUsers={usersRemaining}
          title={
            <FormattedMessage
              defaultMessage="Other users"
              description="Remain user not show on card"
            />
          }
        >
          <Text
            className={classes.moreThanLimit}
            type={TextType.GROUPED_NUMBER}
          >
            <FormattedMessage
              defaultMessage="+{count, number}"
              values={{ count: usersRemaining.length }}
            />
          </Text>
        </RemainUsersPopUp>
      </Box>
    ),
    [classes, usersRemaining],
  );
  return (
    <Fragment>
      {firstAssignmentsAvatars}
      {users.length > max && extraAvatar}
    </Fragment>
  );
});
