import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { IconExternalLink } from '@tabler/icons-react';
import clsx from 'clsx';
import { camelCase } from 'lodash';
import { type Dispatch, memo, type ReactNode, type SetStateAction, useCallback, useState } from 'react';
import { FormattedList, FormattedMessage } from 'react-intl';
import { formatFileSize, useCSVReader } from 'react-papaparse';

import { Link } from '@amal-ia/ext/react-router-dom';
import { AlertBanner, Button, Modal } from '@amal-ia/frontend/design-system/components';

import { DEFAULT_REMOVE_HOVER_COLOR, REMOVE_HOVER_COLOR_LIGHT, useBulkCsvModalStyles } from './useBulkCsvModal';

interface BulkCsvImportModalProps {
  readonly isOpen: boolean;
  readonly setModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly submitUploadedRows: (rows: any[]) => void;
  readonly templateLink?: string;
  readonly mandatoryColumns?: string[];
  readonly optionalColumns?: string[];
  readonly transform?: (value: string, field: number | string) => any;
  readonly name?: string;
  readonly previewComponent?: ReactNode;
}

export const BulkCsvImportModal = memo(function BulkCsvImportModal({
  isOpen,
  submitUploadedRows,
  setModalOpen,
  templateLink,
  mandatoryColumns,
  optionalColumns,
  transform,
}: BulkCsvImportModalProps) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

  const [rows, setRows] = useState([]);

  const [isValid, setIsValid] = useState(false);
  const onClose = useCallback(() => {
    setModalOpen(false);
    setIsValid(false);
  }, [setModalOpen]);

  const classes = useBulkCsvModalStyles();

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.Size.LARGE}
      onClose={onClose}
    >
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage defaultMessage="Upload CSV" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <AlertBanner variant={AlertBanner.Variant.INFO}>
            <FormattedMessage
              defaultMessage="Your CSV file must contain the following columns: {columnTitles}.{br}Your CSV file should contain the following optional columns: {optionalColumnTitles}."
              values={{
                columnTitles: (
                  <FormattedList
                    style="short"
                    type="unit"
                    value={mandatoryColumns}
                  />
                ),
                optionalColumnTitles: (
                  <FormattedList
                    style="short"
                    type="unit"
                    value={optionalColumns}
                  />
                ),
              }}
            />
          </AlertBanner>

          {templateLink ? (
            <Box className={classes.blocGoogleSheetTemplate}>
              <Link
                openInNewTab
                to={templateLink}
                css={css`
                  text-decoration: none;
                `}
              >
                <Button icon={<IconExternalLink />}>
                  <FormattedMessage defaultMessage="Open Google Sheets template" />
                </Button>
              </Link>
            </Box>
          ) : null}

          <Box className={classes.boxForm}>
            <CSVReader
              config={{
                skipEmptyLines: true,
                header: true,
                transformHeader: (header: string): string => camelCase(header),
                transform,
              }}
              onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
              }}
              onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onUploadAccepted={(results: any) => {
                setRows(results.data);
                setZoneHover(false);
                setIsValid(true);
              }}
              onUploadRejected={() => {
                setIsValid(false);
              }}
            >
              {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: any) => (
                <div
                  {...getRootProps()}
                  className={clsx(classes.zone, zoneHover && classes.zoneHover)}
                >
                  {acceptedFile ? (
                    <div className={classes.file}>
                      <div className={classes.info}>
                        <span className={classes.size}>{formatFileSize(acceptedFile.size)}</span>
                        <span className={classes.name}>{acceptedFile.name}</span>
                      </div>
                      <div className={classes.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        className={classes.remove}
                        onBlur={() => {}}
                        onFocus={() => {}}
                        onMouseOut={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                        onMouseOver={(event: Event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  ) : (
                    <FormattedMessage defaultMessage="Drop CSV file here or click to upload" />
                  )}
                </div>
              )}
            </CSVReader>
          </Box>
        </Modal.Body>
      </Modal.Content>
      <Modal.Actions>
        <Modal.CancelAction />

        <Modal.MainAction
          disabled={!isValid}
          onClick={() => {
            submitUploadedRows(rows);
          }}
        >
          <FormattedMessage defaultMessage="Save" />
        </Modal.MainAction>
      </Modal.Actions>
    </Modal>
  );
});
