import { defineMessages } from 'react-intl';

export const formulaConditionGroupMessages = defineMessages({
  REMOVE_CONDITION_GROUP: {
    defaultMessage: 'Clear group of conditions',
  },

  ADD_CONDITION_INSIDE_GROUP: {
    defaultMessage: 'Add a condition',
  },
} as const);
