import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const Connection = lazy(() => import('./connectors/connector/connection/Connection'));
const DataConnectorPage = lazy(() => import('./connectors/connector/DataConnectorPage'));
const UserConnectorContainer = lazy(() => import('./connectors/userConnector/UserConnectorContainer'));
const Connectors = lazy(() => import('./connectors/Connectors'));
const DataPage = lazy(() => import('./index'));

export const CustomObjectRoutes = memo(function CustomObjectRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="data">
      <RootRoutes>
        <Route
          path={routes.DATA_CONNECTOR_CONNECTION}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connector | Connection' })} />
              <Connection />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_CONNECTOR}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connector' })} />
              <DataConnectorPage />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_USER_CONNECTOR}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'User connectors' })} />
              <UserConnectorContainer />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_CONNECTORS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, SubjectsEnum.Data_Connector)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data connectors' })} />
              <Connectors />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA_SLUG}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data' })} />
              <DataPage />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.DATA}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Data' })} />
              <DataPage />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
