import { memo, useMemo } from 'react';

import { Badge, BadgeHue, Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { type CategoryWithRulesSummary } from '../StatementSummaryWidget.types';

import * as styles from './StatementSummaryRow.styles';

export type StatementSummaryRowProps = {
  readonly category: CategoryWithRulesSummary['category'];
  readonly categoryIndex: number;
  readonly rule: CategoryWithRulesSummary['rules'][number];
  readonly isFirstRow: boolean;
};

const CATEGORY_BADGE_VARIANTS = [
  BadgeHue.YELLOW,
  BadgeHue.CYAN,
  BadgeHue.MAGENTA,
  BadgeHue.BLUE,
  BadgeHue.BROWN,
  BadgeHue.GREY,
] as const;

export const StatementSummaryRow = memo(function StatementSummaryRow({
  category,
  categoryIndex,
  isFirstRow,
  rule,
}: StatementSummaryRowProps) {
  // Fallback to grey if there are more categories than the ones defined in CATEGORY_BADGE_VARIANTS.
  const badgeVariant = useMemo(() => CATEGORY_BADGE_VARIANTS[categoryIndex] ?? BadgeHue.GREY, [categoryIndex]);

  return (
    <div css={styles.rowContainer(isFirstRow)}>
      <div css={styles.ruleNameAndCategoryContainer}>
        <Typography
          css={styles.ruleName}
          title={rule.name}
          variant={TypographyVariant.BODY_BASE_REGULAR}
        >
          {rule.name}
        </Typography>

        {!!category && (
          <Badge
            size={Badge.Size.SMALL}
            variant={badgeVariant}
          >
            {category}
          </Badge>
        )}
      </div>

      <div css={styles.dashSeparator} />

      <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>{rule.valueFormatted}</Typography>
    </div>
  );
});
