import { http } from '@amal-ia/frontend/kernel/http';
import { type CreateCompanyRequest } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/companies/types';

export async function saGetCompanies(): Promise<CompanyWithUsersAdmin[]> {
  const { data } = await http.get<CompanyWithUsersAdmin[]>('/sa_companies');
  return data;
}

export async function saGetCompany(companyId: string): Promise<CompanyWithUsersAdmin> {
  const { data } = await http.get<CompanyWithUsersAdmin>(`/sa_companies/${companyId}`);
  return data;
}

export async function saDeleteCompany(companyId: string): Promise<void> {
  await http.delete(`/sa_companies/${companyId}`);
}

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type CompanyPropertyType =
  | 'batchNumber'
  | 'featureFlags'
  | 'nbBackwardRollingPeriods'
  | 'nbForwardPaymentRollingPeriods'
  | 'nbForwardRollingPeriods'
  | 'status';

export async function saModifyCompanyProperty(
  companyId: string,
  type: CompanyPropertyType,
  newValue: number | object | string,
): Promise<void> {
  await http.patch(`/sa_companies/${companyId}`, {
    type,
    value: newValue,
  });
}

export type CompanyWithUsersAdmin = Company & {
  admin: { firstName: string; lastName: string; email: string };
};

export async function addCompany(companyToAdd: CreateCompanyRequest): Promise<Company> {
  const { data } = await http.post('/companies', companyToAdd);
  return data;
}
