import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo, forwardRef } from 'react';

import { Link } from '@amal-ia/ext/react-router-dom';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { FatTabInner, type FatTabInnerProps } from '../fat-tab-inner/FatTabInner';
import * as styles from '../FatTab.styles';

export type FatTabLinkProps = MergeAll<
  [
    ComponentPropsWithoutRef<typeof Link>,
    FatTabInnerProps,
    {
      isSelected?: boolean;
      withBorderTop?: boolean;
    },
  ]
>;

const FatTabLinkForwardRef = forwardRef<HTMLAnchorElement, FatTabLinkProps>(function FatTabLink(
  { data, label, subtitle, tooltip, isSelected, withBorderTop, ...props },
  ref,
) {
  return (
    <Link
      {...props}
      ref={ref}
      css={styles.fatTab}
      className={clsx(props.className, {
        [styles.IS_SELECTED_CLASSNAME]: isSelected,
        [styles.HAS_SUBTITLE_CLASSNAME]: !!subtitle,
        [styles.WITH_BORDER_TOP_CLASSNAME]: !!withBorderTop,
      })}
    >
      <FatTabInner
        data={data}
        label={label}
        subtitle={subtitle}
        tooltip={tooltip}
      />
    </Link>
  );
});

export const FatTabLink = memo(FatTabLinkForwardRef);
