import { Box, type BoxProps, type TypographyProps } from '@mui/material';
import { type ElementType, forwardRef, useMemo } from 'react';

import { amaliaTheme, colors } from '@amal-ia/ext/mui/theme';

export enum TextType {
  // STATEMENT
  STATEMENT_KPI_TITLE = 'STATEMENT_KPI_TITLE',
  STATEMENT_NAME = 'STATEMENT_NAME',
  STATEMENT_CATEGORY = 'STATEMENT_CATEGORY',
  STATEMENT_TEAM_NAME = 'STATEMENT_TEAM_NAME',
  STATEMENT_KPI_VALUE = 'STATEMENT_KPI_VALUE',
  BADGE_TITLE = 'BADGE_TITLE',
  RULE_ACCORDION_NAME = 'RULE_ACCORDION_NAME',
  USER_NAME = 'USER_NAME',
  FORECAST_ACHIEVED_VALUE = 'FORECAST_ACHIEVED_VALUE',
  FORECAST_FORECASTED_VALUE = 'FORECAST_FORECASTED_VALUE',
  PAYOUT = 'PAYOUT',
  FORECASTED_PAYOUT = 'FORECASTED_PAYOUT',
  PAYOUT_LABEL = 'PAYOUT_LABEL',
  PAYOUT_IN_TOOLTIP = 'PAYOUT_IN_TOOLTIP',
  PERIOD_NAME = 'PERIOD_NAME',
  COMMENT_DATE = 'COMMENT_DATE',
  CATEGORY_TITLE = 'CATEGORY_TITLE',

  // OVERWRITE
  OVERWRITE_RECAP = 'OVERWRITE_RECAP',
  OVERWRITE_VALUE_TITLE = 'OVERWRITE_VALUE_TITLE',
  OVERWRITE_OLD_VALUE = 'OVERWRITE_OLD_VALUE',
  OVERWRITE_VALUE = 'OVERWRITE_VALUE',

  // LEFTBAR
  TOOLTIP_LEFT_BAR = 'TOOLTIP_LEFT_BAR',
  PAGE_TITLE = 'PAGE_TITLE',
  PAGE_SUBTITLE = 'PAGE_SUBTITLE',
  POPIN_TITLE = 'POPIN_TITLE',

  // INPUTS
  OPTION_MENU = 'OPTION_MENU',
  SMALL_BUTTON = 'SMALL_BUTTON',
  LARGE_BUTTON = 'LARGE_BUTTON',
  INPUT_NAME = 'INPUT_NAME',
  INPUT_NAME_DISABLED = 'INPUT_NAME_DISABLED',
  SELECTOR_PILL = 'SELECTOR_PILL',
  GROUPED_NUMBER = ' GROUPED_NUMBER',

  // TABLES
  TABLE_HEADER = 'TABLE_HEADER',
  NAMES_IN_TABLE = 'NAMES_IN_TABLE',
  DISABLED_NAMES_TABLE = 'DISABLED_NAMES_TABLE',
  TABLE_ROW = 'TABLE_ROW',
  DATAGRID_COLUMN_NOT_FOUND = 'DATAGRID_COLUMN_NOT_FOUND',

  // MODAL
  MODAL_TITLE = 'MODAL_TITLE',
  MODAL_TEXT = 'MODAL_TEXT',
  MODAL_TEXT_BOLDER = 'MODAL_TEXT_BOLDER',

  // BUSINESS
  CARD_NAME = 'CARD_NAME',
  ARCHIVED_CARD_NAME = 'ARCHIVED_CARD_NAME',
  USERS_IN_TOOLTIP = 'USERS_IN_TOOLTIP',
  PLAN_AGREEMENT_NAME = 'PLAN_AGREEMENT_NAME',
  PLAN_CARD_FREQUENCY = 'PLAN_CARD_FREQUENCY',
  USER_SETTINGS_LABEL = 'USER_SETTINGS_LABEL',
  CONNECTOR_OBJECT_NAME = 'CONNECTOR_OBJECT_NAME',

  // REPORTS
  REPORT_NAME = 'REPORT_NAME',
  REPORT_SHARED = 'REPORT_SHARED',
  FOLDER_REPORT_NAME = 'FOLDER_REPORT_NAME',
  REPORT_FILTER_TITLE = 'REPORT_FILTER_TITLE',
  REPORT_FILTER_ITEM = 'REPORT_FILTER_ITEM',
  REPORT_FILTER_BOX = 'REPORT_FILTER_BOX',
  REPORT_FILTER_BOX_VALUE = 'REPORT_FILTER_BOX_VALUE',
  REPORT_FIELD_CATEGORY = 'REPORT_FIELD_CATEGORY',
  REPORT_DRAWER_ITEM_VALUE = 'REPORT_DRAWER_ITEM_VALUE',
  REPORT_FILTER_LABEL = 'REPORT_FILTER_LABEL',

  // WORKFLOW
  WORKFLOW_STEP = 'WORKFLOW_STEP',
  WORKFLOW_NAME = 'WORKFLOW_NAME',
  WORKFLOW_RECAP_DATE = 'WORKFLOW_RECAP_DATE',

  // DESIGNER
  FORMULA = 'FORMULA',
  PREVIEW_PLACEHOLDER = 'PREVIEW_PLACEHOLDER',
  DESIGNER_NAME = 'DESIGNER_NAME',
  USED_IN = 'USED_IN',
  DESIGNER_OBJECT = 'DESIGNER_OBJECT',
  DESIGNER_TAB = 'DESIGNER_TAB',
  DESIGNER_TAB_TITLE = 'DESIGNER_TAB_TITLE',
  DESIGNER_TAB_SECTION_TITLE = 'DESIGNER_TAB_SECTION_TITLE',
  DESIGNER_TAB_FUNCTION = 'DESIGNER_TAB_FUNCTION',
  TOOLTIP_TITLE = 'TOOLTIP_TITLE',

  // CHART
  DONUT_ANNOTATION_TITLE = 'DONUT_ANNOTATION_TITLE',
  DONUT_ANNOTATION_VALUE = 'DONUT_ANNOTATION_VALUE',

  // DASHBOARD
  DASHBOARD_SECTION_TITLE = 'DASHBOARD_SECTION_TITLE',
  LEADERBOARD_ROW_TITLE = 'LEADERBOARD_ROW_TITLE',
  LEADERBOARD_ROW_VALUE = 'LEADERBOARD_ROW_VALUE',

  // PLAN_AGREEEMENT
  STEP_IN_PLAN_AGREEMENT_EDITION = 'STEP_IN_PLAN_AGREEMENT_EDITION',

  // PRESENTATION
  NAVBAR_ITEM = 'NAVBAR_ITEM',
  NAVBAR_ITEM_ACTIVE = 'NAVBAR_ITEM_ACTIVE',
  NAVBAR_TITLE = 'NAVBAR_TITLE',
  ACCORDION_NAME = 'ACCORDION_NAME',
  SUBTITLE = 'SUBTITLE',
  INFO_MESSAGE = 'INFO_MESSAGE',
  TAB_TITLE = 'TAB_TITLE',
  TOOLTIP = 'TOOLTIP',
  NORMAL_TEXT = 'NORMAL_TEXT',
  ACTIVE_TEXT = 'ACTIVE_TEXT',
  CHIP_LABEL = 'CHIP_LABEL',
  MENU_ACTION = 'MENU_ACTION',

  // NOTIFICATIONS
  NOTIFICATION_CONTENT = 'NOTIFICATION_CONTENT',
  NOTIFICATION_DATE = 'NOTIFICATION_DATE',
  // FLOW
  DESIGNER_PILL = 'DESIGNER_PILL',

  // STEPPER
  STEPPER_ITEM = 'STEPPER_ITEM',
  STEPPER_ITEM_ACTIVE = 'STEPPER_ITEM_ACTIVE',

  // INFORMATION BANNER
  INFORMATION_BANNER_TITLE = 'INFORMATION_BANNER_TITLE',
  INFORMATION_BANNER_DESCRIPTION = 'INFORMATION_BANNER_DESCRIPTION',
}

export enum TextStyle {
  TOP_HEADING = 'TOP_HEADING',

  HEADING_1 = 'HEADING_1',
  HEADING_2 = 'HEADING_2',
  HEADING_3 = 'HEADING_3',
  HEADING_4 = 'HEADING_4',

  SUBTITLE_BOLDER = 'SUBTITLE_BOLDER',
  SUBTITLE = 'SUBTITLE',

  BODY_LARGE_BOLDER = 'BODY_LARGE_BOLDER',
  BODY_LARGE = 'BODY_LARGE',
  BODY_LARGE_COURIER = 'BODY_LARGE_COURIER',
  BODY_MEDIUM_BOLDER = 'BODY_MEDIUM_BOLDER',
  BODY_MEDIUM = 'BODY_MEDIUM',

  CAPTION = 'CAPTION',
  CAPTION_BOLD = 'CAPTION_BOLD',
}

export const textStyleDetails: Record<
  TextStyle,
  {
    component: ElementType;
    fontSize: string;
    fontWeight: number | 'bold' | 'normal';
    fontFamily: 'Courier' | 'Nunito' | 'Roboto';
  }
> = {
  [TextStyle.TOP_HEADING]: {
    component: 'h1',
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  [TextStyle.HEADING_1]: {
    component: 'h1',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  [TextStyle.HEADING_2]: {
    component: 'h2',
    fontSize: '18px',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  [TextStyle.HEADING_3]: {
    component: 'h3',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  [TextStyle.HEADING_4]: {
    component: 'h4',
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  [TextStyle.SUBTITLE_BOLDER]: {
    component: 'p',
    fontSize: '15px',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  [TextStyle.SUBTITLE]: {
    component: 'p',
    fontSize: '15px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
  },
  [TextStyle.BODY_LARGE_BOLDER]: {
    component: 'p',
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  [TextStyle.BODY_LARGE]: {
    component: 'p',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
  },
  [TextStyle.BODY_LARGE_COURIER]: {
    component: 'p',
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: 'Courier',
  },
  [TextStyle.BODY_MEDIUM_BOLDER]: {
    component: 'p',
    fontSize: '13px',
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  [TextStyle.BODY_MEDIUM]: {
    component: 'p',
    fontSize: '13px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
  },
  [TextStyle.CAPTION]: {
    component: 'p',
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
  },
  [TextStyle.CAPTION_BOLD]: {
    component: 'p',
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
};

export const styleForType: Record<TextType, TextStyle> = {
  [TextType.NAVBAR_TITLE]: TextStyle.TOP_HEADING,
  [TextType.RULE_ACCORDION_NAME]: TextStyle.HEADING_1,
  [TextType.STATEMENT_NAME]: TextStyle.HEADING_1,
  [TextType.PAYOUT]: TextStyle.HEADING_1,
  [TextType.FORECASTED_PAYOUT]: TextStyle.HEADING_1,

  [TextType.STATEMENT_KPI_VALUE]: TextStyle.HEADING_2,
  [TextType.CARD_NAME]: TextStyle.HEADING_2,
  [TextType.ARCHIVED_CARD_NAME]: TextStyle.HEADING_2,
  [TextType.PAYOUT_IN_TOOLTIP]: TextStyle.HEADING_2,
  [TextType.STATEMENT_CATEGORY]: TextStyle.HEADING_2,
  [TextType.DESIGNER_NAME]: TextStyle.HEADING_2,
  [TextType.SELECTOR_PILL]: TextStyle.HEADING_2,
  [TextType.PERIOD_NAME]: TextStyle.HEADING_2,

  [TextType.PAGE_TITLE]: TextStyle.HEADING_3,
  [TextType.MODAL_TITLE]: TextStyle.HEADING_3,
  [TextType.OPTION_MENU]: TextStyle.HEADING_3,
  [TextType.LARGE_BUTTON]: TextStyle.HEADING_3,
  [TextType.INFORMATION_BANNER_TITLE]: TextStyle.HEADING_3,

  [TextType.ACCORDION_NAME]: TextStyle.HEADING_4,
  [TextType.SMALL_BUTTON]: TextStyle.HEADING_4,

  [TextType.NAVBAR_ITEM]: TextStyle.SUBTITLE_BOLDER,
  [TextType.NAVBAR_ITEM_ACTIVE]: TextStyle.SUBTITLE_BOLDER,
  [TextType.DASHBOARD_SECTION_TITLE]: TextStyle.SUBTITLE_BOLDER,
  [TextType.FORECAST_ACHIEVED_VALUE]: TextStyle.SUBTITLE_BOLDER,
  [TextType.FORECAST_FORECASTED_VALUE]: TextStyle.SUBTITLE_BOLDER,
  [TextType.OVERWRITE_OLD_VALUE]: TextStyle.SUBTITLE_BOLDER,
  [TextType.OVERWRITE_VALUE]: TextStyle.SUBTITLE_BOLDER,

  [TextType.STATEMENT_TEAM_NAME]: TextStyle.SUBTITLE,
  [TextType.STATEMENT_KPI_TITLE]: TextStyle.SUBTITLE,
  [TextType.DATAGRID_COLUMN_NOT_FOUND]: TextStyle.SUBTITLE,
  [TextType.PAGE_SUBTITLE]: TextStyle.SUBTITLE,
  [TextType.PLAN_CARD_FREQUENCY]: TextStyle.SUBTITLE,
  [TextType.MENU_ACTION]: TextStyle.SUBTITLE,
  [TextType.INFORMATION_BANNER_DESCRIPTION]: TextStyle.SUBTITLE,

  [TextType.FORMULA]: TextStyle.BODY_LARGE_COURIER,

  [TextType.REPORT_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.REPORT_FILTER_TITLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.REPORT_FIELD_CATEGORY]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.USER_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.PLAN_AGREEMENT_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.WORKFLOW_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.DONUT_ANNOTATION_VALUE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.LEADERBOARD_ROW_VALUE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.PAYOUT_LABEL]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.DESIGNER_TAB_TITLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.DESIGNER_TAB_SECTION_TITLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.DESIGNER_TAB]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.SUBTITLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.NAMES_IN_TABLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.DISABLED_NAMES_TABLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.WORKFLOW_STEP]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.GROUPED_NUMBER]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.INPUT_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.INPUT_NAME_DISABLED]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.TABLE_HEADER]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.USERS_IN_TOOLTIP]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.TOOLTIP_TITLE]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.CONNECTOR_OBJECT_NAME]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.CHIP_LABEL]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.STEP_IN_PLAN_AGREEMENT_EDITION]: TextStyle.BODY_LARGE_BOLDER,
  [TextType.MODAL_TEXT_BOLDER]: TextStyle.BODY_LARGE_BOLDER,

  [TextType.DONUT_ANNOTATION_TITLE]: TextStyle.BODY_LARGE,
  [TextType.LEADERBOARD_ROW_TITLE]: TextStyle.BODY_LARGE,
  [TextType.BADGE_TITLE]: TextStyle.BODY_LARGE,
  [TextType.POPIN_TITLE]: TextStyle.BODY_LARGE,
  [TextType.DESIGNER_OBJECT]: TextStyle.BODY_LARGE,
  [TextType.MODAL_TEXT]: TextStyle.BODY_LARGE,
  [TextType.USER_SETTINGS_LABEL]: TextStyle.BODY_LARGE,
  [TextType.NOTIFICATION_CONTENT]: TextStyle.BODY_LARGE,
  [TextType.DESIGNER_PILL]: TextStyle.BODY_LARGE,

  [TextType.TAB_TITLE]: TextStyle.BODY_MEDIUM_BOLDER,
  [TextType.ACTIVE_TEXT]: TextStyle.BODY_MEDIUM_BOLDER,
  [TextType.CATEGORY_TITLE]: TextStyle.BODY_MEDIUM_BOLDER,
  [TextType.FOLDER_REPORT_NAME]: TextStyle.BODY_MEDIUM_BOLDER,
  [TextType.REPORT_FILTER_BOX_VALUE]: TextStyle.BODY_MEDIUM_BOLDER,
  [TextType.REPORT_DRAWER_ITEM_VALUE]: TextStyle.BODY_MEDIUM_BOLDER,

  [TextType.REPORT_FILTER_ITEM]: TextStyle.BODY_MEDIUM,
  [TextType.TABLE_ROW]: TextStyle.BODY_MEDIUM,
  [TextType.USED_IN]: TextStyle.BODY_MEDIUM,
  [TextType.INFO_MESSAGE]: TextStyle.BODY_MEDIUM,
  [TextType.NORMAL_TEXT]: TextStyle.BODY_MEDIUM,
  [TextType.OVERWRITE_RECAP]: TextStyle.BODY_MEDIUM,
  [TextType.TOOLTIP]: TextStyle.BODY_MEDIUM,
  [TextType.WORKFLOW_RECAP_DATE]: TextStyle.BODY_MEDIUM,
  [TextType.TOOLTIP_LEFT_BAR]: TextStyle.BODY_MEDIUM,
  [TextType.REPORT_FILTER_BOX]: TextStyle.BODY_MEDIUM,
  [TextType.REPORT_FILTER_LABEL]: TextStyle.BODY_MEDIUM,
  [TextType.PREVIEW_PLACEHOLDER]: TextStyle.BODY_MEDIUM,

  [TextType.NOTIFICATION_DATE]: TextStyle.CAPTION,
  [TextType.COMMENT_DATE]: TextStyle.CAPTION,
  [TextType.OVERWRITE_VALUE_TITLE]: TextStyle.CAPTION,
  [TextType.REPORT_SHARED]: TextStyle.CAPTION,
  [TextType.DESIGNER_TAB_FUNCTION]: TextStyle.CAPTION,
  [TextType.STEPPER_ITEM]: TextStyle.CAPTION,

  [TextType.STEPPER_ITEM_ACTIVE]: TextStyle.CAPTION_BOLD,
};

type TextProps = BoxProps<ElementType> &
  Omit<TypographyProps, 'color' | 'display'> & {
    readonly type: TextType;
  };

export const Text = forwardRef(function Text(props: TextProps, ref) {
  const fontProps: any = useMemo(() => {
    const baseProps = {
      margin: 0,
      padding: 0,
      color: amaliaTheme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      'data-style': styleForType[props.type],
      bgcolor: undefined,
      paddingX: '0',
      paddingY: '0',
      borderRadius: '0px',
      fontStyle: 'normal',
      cursor: 'inherit',
    };

    const additionalProps = textStyleDetails[styleForType[props.type]];

    switch (props.type) {
      case TextType.PERIOD_NAME:
        baseProps.color = amaliaTheme.palette.tertiary.main;
        break;
      case TextType.RULE_ACCORDION_NAME:
      case TextType.STATEMENT_KPI_VALUE:
      case TextType.ACCORDION_NAME:
      case TextType.DESIGNER_OBJECT:
      case TextType.TOOLTIP_LEFT_BAR:
      case TextType.MODAL_TEXT:
      case TextType.MODAL_TEXT_BOLDER:
      case TextType.OPTION_MENU:
      case TextType.TOOLTIP:
      case TextType.DONUT_ANNOTATION_TITLE:
      case TextType.DONUT_ANNOTATION_VALUE:
      case TextType.LEADERBOARD_ROW_TITLE:
      case TextType.LEADERBOARD_ROW_VALUE:
      case TextType.TABLE_HEADER:
      case TextType.PAGE_SUBTITLE:
      case TextType.NOTIFICATION_DATE:
      case TextType.OVERWRITE_VALUE_TITLE:
      case TextType.NOTIFICATION_CONTENT:
      case TextType.USER_SETTINGS_LABEL:
      case TextType.PLAN_CARD_FREQUENCY:
      case TextType.DESIGNER_TAB_TITLE:
      case TextType.ARCHIVED_CARD_NAME:
      case TextType.STEP_IN_PLAN_AGREEMENT_EDITION:
      case TextType.MENU_ACTION:
      case TextType.REPORT_FILTER_ITEM:
      case TextType.REPORT_FILTER_BOX:
      case TextType.REPORT_FILTER_BOX_VALUE:
      case TextType.STEPPER_ITEM_ACTIVE:
      case TextType.INFORMATION_BANNER_TITLE:
        baseProps.color = amaliaTheme.palette.grey['800'];
        break;
      case TextType.SMALL_BUTTON:
      case TextType.LARGE_BUTTON:
      case TextType.PAYOUT:
      case TextType.CHIP_LABEL:
      case TextType.DESIGNER_TAB_FUNCTION:
      case TextType.FORECAST_FORECASTED_VALUE:
      case TextType.FORECAST_ACHIEVED_VALUE:
        baseProps.color = null;
        break;
      case TextType.STATEMENT_TEAM_NAME:
      case TextType.STATEMENT_KPI_TITLE:
      case TextType.INPUT_NAME:
      case TextType.PAYOUT_LABEL:
      case TextType.INFO_MESSAGE:
      case TextType.CATEGORY_TITLE:
      case TextType.DISABLED_NAMES_TABLE:
      case TextType.DASHBOARD_SECTION_TITLE:
      case TextType.COMMENT_DATE:
      case TextType.WORKFLOW_RECAP_DATE:
      case TextType.OVERWRITE_RECAP:
      case TextType.NAVBAR_ITEM:
      case TextType.TAB_TITLE:
      case TextType.REPORT_SHARED:
      case TextType.FOLDER_REPORT_NAME:
      case TextType.REPORT_FIELD_CATEGORY:
      case TextType.REPORT_DRAWER_ITEM_VALUE:
      case TextType.REPORT_FILTER_LABEL:
      case TextType.DESIGNER_PILL:
      case TextType.STEPPER_ITEM:
      case TextType.INFORMATION_BANNER_DESCRIPTION:
        baseProps.color = amaliaTheme.palette.grey['700'];
        break;
      case TextType.OVERWRITE_OLD_VALUE:
        baseProps.color = amaliaTheme.palette.grey['600'];
        break;
      case TextType.INPUT_NAME_DISABLED:
        baseProps.color = amaliaTheme.palette.grey['300'];
        baseProps.cursor = 'not-allowed';
        break;
      case TextType.DATAGRID_COLUMN_NOT_FOUND:
      case TextType.PREVIEW_PLACEHOLDER:
        baseProps.color = amaliaTheme.palette.grey['300'];
        break;
      case TextType.OVERWRITE_VALUE:
        baseProps.color = amaliaTheme.palette.tertiary.main;
        baseProps.fontStyle = 'italic';
        break;
      case TextType.FORECASTED_PAYOUT:
        baseProps.color = amaliaTheme.palette.grey['800'];
        baseProps.bgcolor = colors['blue-100'];
        baseProps.paddingY = amaliaTheme.spacing(0.05);
        baseProps.paddingX = amaliaTheme.spacing(0.15);
        baseProps.borderRadius = '4px';
        break;
      default:
        break;
    }

    return { ...baseProps, ...additionalProps };
  }, [props.type]);

  return (
    <Box
      {...props}
      {...fontProps}
      ref={ref}
    />
  );
});

Text.displayName = 'Text';
