import { http } from '@amal-ia/frontend/kernel/http';
import { type Calculation, CalculationStatus, type CalculationRequest, CalculationTrigger } from '@amal-ia/lib-types';

const apiEndpoint = '/calculations';

export class CalculationsRepository {
  public static async calculateStatement(calculationRequest: CalculationRequest): Promise<Calculation> {
    const { data } = await http.post<Calculation>(`${apiEndpoint}/`, {
      periodId: calculationRequest.periodId || undefined,
      userIds:
        !!calculationRequest.userIds && calculationRequest.userIds.length > 0 ? calculationRequest.userIds : undefined,
      teamId: calculationRequest.teamId || undefined,
      planId: calculationRequest.planId || undefined,
      action: calculationRequest.action || undefined,
      trigger: CalculationTrigger.MANUAL,
      type: calculationRequest.type || undefined,
    });
    return data;
  }

  public static async getRunningCalculation(periodId: string): Promise<Calculation | undefined> {
    const { data } = await http.get<Calculation[]>(`${apiEndpoint}/${periodId}`, {
      params: {
        status: [CalculationStatus.STARTED, CalculationStatus.PENDING, CalculationStatus.STOPPING],
      },
    });

    return data[0];
  }

  public static async getLastCalculationForPeriod(periodId: string) {
    const { data } = await http.get<Calculation[]>(`${apiEndpoint}/${periodId}`, {
      params: {
        count: 1,
      },
    });

    return data[0];
  }

  public static async stopCalculation(calculation: Calculation): Promise<void> {
    await http.patch(`${apiEndpoint}/${calculation.id}`, { status: CalculationStatus.STOPPING });
  }
}
