import { useCallback } from 'react';

import { type AttributeValueForm } from '@amal-ia/amalia-lang/formula/form';
import { type AttributeValue } from '@amal-ia/amalia-lang/formula/shared/types';
import { type SelectProps } from '@amal-ia/frontend/design-system/components';
import { TokenType } from '@amal-ia/lib-types';

import { useFormulaBuilderContext } from '../../components/formula-builder/FormulaBuilder.context';
import {
  mapVariableToAttributeSelectOption,
  type AttributeSelectOption,
  mapRelationshipPropertyToAttributeSelectOption,
  mapFieldToAttributeSelectOption,
  mapPropertyToAttributeSelectOption,
  mapQuotaToAttributeSelectOption,
  mapKeywordToAttributeSelectOption,
} from '../use-attributes-options/useAttributesOptions';
import { useGetFormulaBuilderAttribute } from '../use-get-formula-builder-attribute/useGetFormulaBuilderAttribute';
import { useGetFormulaBuilderAttributeLabel } from '../use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';

export type UseMapAttributeValueToSelectOptionValue = (
  attributeValue: AttributeValueForm,
) => SelectProps<AttributeSelectOption<AttributeValue>, false, true, false>['value'];

/**
 * Return a callback taking an AttributeValue as parameter and returning an AttributeSelectOption like useAttributeOptions.
 */
export const useMapAttributeValueToSelectOption = (): UseMapAttributeValueToSelectOptionValue => {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const getFormulaBuilderAttribute = useGetFormulaBuilderAttribute();
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  return useCallback(
    (attributeValue) => {
      if (!('fieldType' in attributeValue)) {
        return null;
      }

      switch (attributeValue.fieldType) {
        case TokenType.VARIABLE: {
          const variable = getFormulaBuilderAttribute(attributeValue);
          return variable ? mapVariableToAttributeSelectOption(variable, getFormulaBuilderAttributeLabel, false) : null;
        }

        case TokenType.LINK: {
          const relationshipAndProperty = getFormulaBuilderAttribute(attributeValue);
          return relationshipAndProperty && customObjectDefinition
            ? mapRelationshipPropertyToAttributeSelectOption(
                customObjectDefinition,
                relationshipAndProperty.relationship,
                relationshipAndProperty.property,
                getFormulaBuilderAttributeLabel,
                false,
              )
            : null;
        }

        case TokenType.FIELD: {
          const field = getFormulaBuilderAttribute(attributeValue);
          return field ? mapFieldToAttributeSelectOption(field, getFormulaBuilderAttributeLabel, false) : null;
        }

        case TokenType.PROPERTY:
        case TokenType.VIRTUAL_PROPERTY: {
          const property = getFormulaBuilderAttribute(attributeValue);
          return property
            ? mapPropertyToAttributeSelectOption(
                property,
                attributeValue.fieldType,
                getFormulaBuilderAttributeLabel,
                false,
              )
            : null;
        }

        case TokenType.QUOTA: {
          const quota = getFormulaBuilderAttribute(attributeValue);
          return quota ? mapQuotaToAttributeSelectOption(quota, getFormulaBuilderAttributeLabel, false) : null;
        }

        case TokenType.KEYWORD: {
          return mapKeywordToAttributeSelectOption(attributeValue.keyword, getFormulaBuilderAttributeLabel);
        }

        default:
          return null;
      }
    },
    [getFormulaBuilderAttribute, getFormulaBuilderAttributeLabel, customObjectDefinition],
  );
};
