import { EditOutlined, TrackChanges } from '@mui/icons-material';
import { Box, Divider, type TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { Text, TextType, Tooltip } from '@amal-ia/lib-ui';

import { forecastedKpiTooltipMessages } from './ForecastedKpiTooltip.messages';

import type * as React from 'react';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: theme.spacing(2),
  },
  tooltipTitleContainer: {
    marginBottom: theme.spacing(1),
  },
  tooltipLabel: {
    marginBottom: theme.spacing(0.5),
  },
  tooltipTitle: {
    color: theme.palette.secondary.main,
  },
  forecastedValue: {
    backgroundColor: theme.palette.info.light,
  },
  achievedValue: {
    backgroundColor: colors['primary-100'],
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    height: 'auto',
    margin: theme.spacing(1),
  },
  overwriteDetails: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'auto auto',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
    alignItems: 'center',
    justifyItems: 'center',
  },
  value: {
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: 'fit-content',
  },
}));

export interface OverwriteInfoProps {
  author: string;
  date: string;
  sourceValue: number | string;
  value: number | string;
}

interface ForecastedKpiTooltipProps {
  readonly formattedAchievedValue: number | string;
  readonly formattedForecastedValue: number | string;
  readonly overwriteInfo?: OverwriteInfoProps;
  readonly isValueForecasted?: boolean;
  readonly position?: TooltipProps['placement'];
  readonly resetOverwriteProxy?: () => void;
  readonly children: React.ReactNode;
}
export const ForecastedKpiTooltip = memo(function ForecastedKpiTooltip({
  formattedAchievedValue,
  formattedForecastedValue,
  overwriteInfo,
  children,
  resetOverwriteProxy,
  isValueForecasted,
  position = 'top-start',
}: ForecastedKpiTooltipProps) {
  const classes = useStyles();

  return (
    <Tooltip
      placement={position}
      title={
        <div className={classes.tooltipContainer}>
          {isValueForecasted ? (
            <Box
              className={classes.section}
              data-title="forecastedKpiTooltip"
            >
              <Text
                className={classes.tooltipTitle}
                type={TextType.PAYOUT_LABEL}
              >
                <TrackChanges />
                &nbsp;
                <FormattedMessage {...forecastedKpiTooltipMessages.FORECAST_DETAILS} />
              </Text>
              <div>
                <Text
                  className={classes.tooltipLabel}
                  type={TextType.PAYOUT_LABEL}
                >
                  <FormattedMessage {...forecastedKpiTooltipMessages.ACHIEVED_VALUE} />
                </Text>
                <Text
                  className={clsx(classes.achievedValue, classes.value)}
                  data-title="achievedValue"
                  type={TextType.FORECAST_ACHIEVED_VALUE}
                >
                  {formattedAchievedValue}
                </Text>
              </div>
              <div>
                <Text
                  className={classes.tooltipLabel}
                  type={TextType.PAYOUT_LABEL}
                >
                  <FormattedMessage {...forecastedKpiTooltipMessages.FORECASTED_VALUE} />
                </Text>
                <Text
                  className={clsx(classes.forecastedValue, classes.value)}
                  data-title="forecastedValue"
                  type={TextType.FORECAST_FORECASTED_VALUE}
                >
                  {formattedForecastedValue}
                </Text>
              </div>
            </Box>
          ) : null}
          {overwriteInfo && isValueForecasted ? (
            <Divider
              className={classes.divider}
              data-title="divider"
              orientation="vertical"
            />
          ) : null}
          {overwriteInfo ? (
            <div
              className={classes.section}
              data-title="simulatedKpiTooltip"
            >
              <Text
                className={classes.tooltipTitle}
                type={TextType.PAYOUT_LABEL}
              >
                <EditOutlined />
                &nbsp;
                <FormattedMessage {...forecastedKpiTooltipMessages.SIMULATION_DETAILS} />
              </Text>
              <div className={classes.overwriteDetails}>
                <Text
                  data-title="overwriteInfo"
                  style={{ display: 'block' }}
                  type={TextType.OVERWRITE_VALUE_TITLE}
                >
                  <FormattedMessage
                    {...forecastedKpiTooltipMessages.OVERWRITTEN_BY}
                    values={{
                      b: (chunks: ReactNode) => <b>{chunks}</b>,
                      author: overwriteInfo.author,
                      date: overwriteInfo.date,
                    }}
                  />
                </Text>
                <div>
                  <Text
                    className={classes.tooltipLabel}
                    type={TextType.PAYOUT_LABEL}
                  >
                    <FormattedMessage {...forecastedKpiTooltipMessages.OLD_VALUE} />
                  </Text>
                  <Text
                    className={classes.value}
                    data-title="simulationSourceValue"
                    type={TextType.OVERWRITE_OLD_VALUE}
                  >
                    {overwriteInfo.sourceValue}
                  </Text>
                </div>
                <Button
                  variant={Button.Variant.SECONDARY}
                  onClick={resetOverwriteProxy}
                >
                  <FormattedMessage {...forecastedKpiTooltipMessages.CLEAR_SIMULATION} />
                </Button>
                <div>
                  <Text
                    className={classes.tooltipLabel}
                    type={TextType.PAYOUT_LABEL}
                  >
                    <FormattedMessage {...forecastedKpiTooltipMessages.NEW_VALUE} />
                  </Text>
                  <Text
                    className={classes.value}
                    data-title="simulationValue"
                    type={TextType.OVERWRITE_VALUE}
                  >
                    {overwriteInfo.value}
                  </Text>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
});
