import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';

import { type CustomReportFolder } from './folder';
import { type PaginatedQuery } from './pagination';

export interface CustomReportOptions {
  /**
   * Use this property when we want to show all currencies amount in the company currency.
   *
   * If this parameter is not specified, then the Query Container will evaluate what's best for you. Examples:
   *  - If there is no groupBy, it'll show currency amounts as saved.
   *  - If there is at least one groupBy:
   *    - If the currencies we're summing (or AVGing) are homogeneous, show it in their currency.
   *    - If they are heterogeneous (for instance we're doing a groupBy team but a team has sales-reps
   *      using USD and others SGD), then the result will be automatically converted to the company currency for that team.
   */
  convertToCompanyCurrency?: boolean;
}

export interface CustomReport {
  id?: string;
  name: string;
  isPreset?: boolean;
  source: CustomReportSourceIdentifier;
  isAccessibleFromApi?: boolean;
  configuration?: CustomReportConfiguration;
  dataSourceConfiguration?: CustomReportDataSourceConfiguration;
  folderId?: string;
  folder?: CustomReportFolder;
}

export type CustomReportDataSourceConfiguration = Record<string, unknown>;

export interface CustomReportRecordsRequest {
  // Giving the pagination options.
  pagination: Pick<PaginatedQuery, 'limit' | 'page' | 'search'>;

  // Eventually override the custom report configuration. Only for edit mode.
  configuration?: CustomReportConfiguration;

  context?: {
    year?: number;
  };
}

export enum CustomReportAggregationOperation {
  sum = 'sum',
  avg = 'avg',
  weighted_average = 'weighted_average',
  min = 'min',
  max = 'max',
  concat = 'concat',
  concat_distinct = 'concat_distinct',
  first = 'first',
  all = 'all',
  some = 'some',
}

export interface CustomReportAggregation {
  operation: CustomReportAggregationOperation;
}

export enum CustomReportSourceIdentifier {
  RULE_METRIC = 'RULE_METRIC',
  RECORD_METRIC = 'RECORD_METRIC',
  USER = 'USER',
  PAYMENT = 'PAYMENT',
  PERIOD = 'PERIOD',
  CAPTURED_RECORD = 'CAPTURED_RECORD',
}

// A select could either be the name of the column, or something more advanced that needs SQL functions.
// In the latter case, we often need the table alias, so we need a callback.
type CustomReportDataSourceManifestSelect = string | ((tableAlias: string) => string);

export interface CustomReportDataSourceManifest {
  identifier: CustomReportSourceIdentifier;
  label: string;

  joins: {
    identifier: string;
    label: string;
    target: CustomReportSourceIdentifier;
    // First level is AND, second level is OR.
    // Ex: [[A], [B, C]] => (A and (B or C))
    conditions: {
      sourceSelect: string;
      targetSelect: string;
    }[][];
    // Configuration of the join if the destination needs one.
    // It'll override the one coming from the configuration.
    dataSourceConfiguration?: CustomReportDataSourceConfiguration;
  }[];

  fields: {
    // Unique identifier for the field.
    identifier: string;
    // Human readable label.
    label: string;

    // SQL select for the field.
    select: CustomReportDataSourceManifestSelect;
    // SQL select for the currency rate.
    selectRate?: CustomReportDataSourceManifestSelect;
    // SQL select for the currency symbol.
    selectSymbol?: CustomReportDataSourceManifestSelect;

    // Amalia format.
    format: FormatsEnum;

    // Appears in default custom report?
    isDefault?: boolean;
    // Can be SQL sorted?
    sortable?: boolean;
    // Is used for fulltext search?
    fulltext?: boolean;
    // Is an enum?
    enum?: boolean;
    // SQL Select for the label of the enum member.
    selectEnumLabel?: string;
    // Transform callback for the label.
    transformEnumLabel?: (value: string, label?: string) => string;
    // Is it a periodId? Will help to build filters.
    isPeriodId?: boolean;
    // Is it a userId? Will help to build filters.
    isUserId?: boolean;
    // Is it an uuid? If so we might need to cast it to VARCHAR sometimes.
    isUUID?: boolean;
    // Used in the frontend to categorize the fields.
    category?: string;
    // If a weighted average is possible, target the column where to find the weights.
    selectWeightedAverage?: CustomReportDataSourceManifestSelect;
    // If the field format have been downcast-ed, store original formats.
    formatsBeforeDowncast?: FormatsEnum[];
  }[];
}

export type CustomReportManifestsMap = Record<string, CustomReportDataSourceManifest>;

/**
 * Hashmap of aggregations available for each format.
 */
export const CustomReportAggregationsForFormat: Record<FormatsEnum, CustomReportAggregationOperation[]> = {
  [FormatsEnum.boolean]: [CustomReportAggregationOperation.all, CustomReportAggregationOperation.some],
  [FormatsEnum.currency]: [
    CustomReportAggregationOperation.sum,
    CustomReportAggregationOperation.avg,
    CustomReportAggregationOperation.min,
    CustomReportAggregationOperation.max,
  ],

  [FormatsEnum.date]: [
    CustomReportAggregationOperation.first,
    CustomReportAggregationOperation.min,
    CustomReportAggregationOperation.max,
  ],

  [FormatsEnum['date-time']]: [
    CustomReportAggregationOperation.first,
    CustomReportAggregationOperation.min,
    CustomReportAggregationOperation.max,
  ],

  [FormatsEnum.number]: [
    CustomReportAggregationOperation.sum,
    CustomReportAggregationOperation.avg,
    CustomReportAggregationOperation.min,
    CustomReportAggregationOperation.max,
  ],

  [FormatsEnum.percent]: [
    CustomReportAggregationOperation.sum,
    CustomReportAggregationOperation.avg,
    CustomReportAggregationOperation.min,
    CustomReportAggregationOperation.max,
  ],

  [FormatsEnum.text]: [
    CustomReportAggregationOperation.first,
    CustomReportAggregationOperation.concat,
    CustomReportAggregationOperation.concat_distinct,
  ],
  [FormatsEnum.table]: [],
};

/**
 * Special aggregation that validates on field definition.
 */
export const CustomReportAggregationSpecial: Partial<
  Record<
    CustomReportAggregationOperation,
    { isApplicable: (fieldDefinition: CustomReportDataSourceManifest['fields'][0]) => boolean }
  >
> = {
  [CustomReportAggregationOperation.weighted_average]: {
    isApplicable: (fieldDefinition) => !!fieldDefinition.selectWeightedAverage,
  },
};

export enum CustomReportFilterOperator {
  CONTAINS = 'CONTAINS',
  MATCHES = 'MATCHES',
  IN = 'IN',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EXISTS = 'EXISTS',
  GREATER_STRICT = 'GREATER_STRICT',
  LOWER_STRICT = 'LOWER_STRICT',
  GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
  LOWER_OR_EQUAL = 'LOWER_OR_EQUAL',
  EQUALS = 'EQUALS',

  // Specials.
  KEYWORD = 'KEYWORD', // Only use for periods, eg: YEAR_TO_DATE, LAST_3_MONTHS, ...
}

export const CustomReportFilterOperatorsForFormat: Record<FormatsEnum, CustomReportFilterOperator[]> = {
  [FormatsEnum.boolean]: [CustomReportFilterOperator.EXISTS, CustomReportFilterOperator.EQUALS],
  [FormatsEnum.date]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.EQUALS,
    CustomReportFilterOperator.GREATER_STRICT,
    CustomReportFilterOperator.LOWER_STRICT,
    CustomReportFilterOperator.GREATER_OR_EQUAL,
    CustomReportFilterOperator.LOWER_OR_EQUAL,
  ],
  [FormatsEnum['date-time']]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.EQUALS,
    CustomReportFilterOperator.GREATER_STRICT,
    CustomReportFilterOperator.LOWER_STRICT,
    CustomReportFilterOperator.GREATER_OR_EQUAL,
    CustomReportFilterOperator.LOWER_OR_EQUAL,
  ],
  [FormatsEnum.currency]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.EQUALS,
    CustomReportFilterOperator.GREATER_STRICT,
    CustomReportFilterOperator.LOWER_STRICT,
    CustomReportFilterOperator.GREATER_OR_EQUAL,
    CustomReportFilterOperator.LOWER_OR_EQUAL,
  ],
  [FormatsEnum.number]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.EQUALS,
    CustomReportFilterOperator.GREATER_STRICT,
    CustomReportFilterOperator.LOWER_STRICT,
    CustomReportFilterOperator.GREATER_OR_EQUAL,
    CustomReportFilterOperator.LOWER_OR_EQUAL,
  ],
  [FormatsEnum.percent]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.EQUALS,
    CustomReportFilterOperator.GREATER_STRICT,
    CustomReportFilterOperator.LOWER_STRICT,
    CustomReportFilterOperator.GREATER_OR_EQUAL,
    CustomReportFilterOperator.LOWER_OR_EQUAL,
  ],
  [FormatsEnum.text]: [
    CustomReportFilterOperator.EXISTS,
    CustomReportFilterOperator.MATCHES,
    CustomReportFilterOperator.CONTAINS,
    CustomReportFilterOperator.STARTS_WITH,
    CustomReportFilterOperator.ENDS_WITH,
    CustomReportFilterOperator.KEYWORD, // Under certain conditions.
    CustomReportFilterOperator.IN,
  ],
  [FormatsEnum.table]: [],
};

export interface CustomReportConfigurationField {
  // The field identifier.
  identifier: string;
  // An alias for the column.
  alias?: string;
  // Joins where the field can be selected.
  joins?: string[];
  // If a group by is activated, the selected aggregation.
  aggregation?: CustomReportAggregation;
}

export interface CustomReportConfigurationFilter {
  // The field identifier.
  identifier: string;

  // Joins where the field can be selected.
  joins?: string[];

  // Operator.
  operator: CustomReportFilterOperator;

  // Not (invert the operator condition).
  not?: boolean;

  value?: boolean | number | string | null;

  values?: string[];
}

export interface CustomReportConfiguration {
  fields: CustomReportConfigurationField[];

  /**
   * Example:
   *    - record_metric -> payment
   *      record_metric -> user
   *      --> [['payment'], ['user']]
   *
   *    - record_metric -> rule_metric -> payment
   *      record_metric -> custom_object
   *      --> [['rule_metric', 'payment'], ['custom_object']]
   */
  joins?: {
    type: 'AUTO' | 'CUSTOM';
    id: string;
    dataSourceConfiguration?: CustomReportDataSourceConfiguration;
  }[][];

  // Value of the field we're doing the groupBy on.
  groupBys?: {
    // The field identifier.
    identifier: string;
    // Joins where the field can be selected.
    joins?: string[];
  }[];

  filters?: {
    ors: CustomReportConfigurationFilter[];
  }[];

  sorts?: {
    // The field identifier.
    identifier: string;

    // Joins where the field can be selected.
    joins?: string[];

    // Sort direction.
    direction: 'ASC' | 'DESC';
  }[];
}

export type CustomReportValue = CurrencyValue | Date | boolean | number | string;

export type CustomReportRow = Record<string, CustomReportValue>;

export type CustomReportToCreate = Pick<CustomReport, 'dataSourceConfiguration' | 'folderId' | 'name' | 'source'>;

export type CustomReportToPatch = Partial<
  Pick<CustomReport, 'configuration' | 'folderId' | 'isAccessibleFromApi' | 'name'>
>;

export enum CustomReportQueryStage {
  SELECT = 'SELECT',
  WHERE = 'WHERE',
  ORDER_BY = 'ORDER BY',
  GROUP_BY = 'GROUP BY',
}

export interface CustomReportColumn {
  identifier: string;
  label: string;
  format: FormatsEnum;
}

export interface CustomReportFilterOption {
  value: string;
  label: string;
}

export type CustomReportFilterOptionsMap = Record<string, CustomReportFilterOption[]>;

export enum CustomReportsPresetsEnum {
  PRESET_LEADERBOARD = 'PRESET_LEADERBOARD',
  PRESET_BENCHMARK_BY_RULE = 'PRESET_BENCHMARK_BY_RULE',
  PRESET_EARNED_OVER_TIME = 'PRESET_EARNED_OVER_TIME',
  PRESET_EARNED_OVER_TIME_BY_PLAN = 'PRESET_EARNED_OVER_TIME_BY_PLAN',
  PRESET_BENCHMARK_BY_PLAN = 'PRESET_BENCHMARK_BY_PLAN',
  PRESET_PLAN_KPIS = 'PRESET_PLAN_KPIS',
}

export const customReportsPresetKeywords = Object.values(CustomReportsPresetsEnum);
