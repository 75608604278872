import { IconUserCheck, IconEqual, IconPlayerRecord } from '@tabler/icons-react';

import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { IconNot, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

export const OPERATOR_ICON_MAPPING: Record<
  FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg,
  TablerIconElement
> = {
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: <IconUserCheck data-testid="icon-user-check" />,
  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: <IconPlayerRecord data-testid="icon-player-record" />,
  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: (
    <IconNot
      data-testid="icon-not-player-record"
      icon={IconPlayerRecord}
    />
  ),

  [FormulaBuilderUserOperatorOneArg.MATCHES]: <IconEqual data-testid="icon-equal" />,
  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: (
    <IconNot
      data-testid="icon-not-equal"
      icon={IconEqual}
    />
  ),
};
