import moment from 'moment';

import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { TokenType } from '@amal-ia/lib-types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.daysAgo, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 2;

func.description = 'Return a date n days ago from a given date';

func.params = [
  {
    name: 'date',
    description: 'Date: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  { name: 'numberOfDaysAgo', description: 'Number of days ago, can be negative for days after' },
];

func.examples = [
  {
    desc: 'Returns "2023-04-26" ',
    formula: 'daysAgo(toDate("2023-04-30"), 4)' as AmaliaFormula,
    result: '1682467200',
  },
  {
    desc: 'Returns "2023-04-18" ',
    formula: 'daysAgo(toDate("2023-04-11"), -7)' as AmaliaFormula,
    result: '1681776000',
  },
  {
    desc: 'Returns a date 30 days before the Close Date.',
    formula: 'daysAgo(opportunity.closeDate, 30)' as AmaliaFormula,
  },
  {
    desc: 'Returns a date 12 days after the statement start date.',
    formula: 'daysAgo(statementPeriod.startDate, -12)' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string, numberOfDays: number | string): string | null =>
  moment(date, 'X').subtract(numberOfDays, 'days').format('X');

export default func;
