import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { lazy, memo, Suspense, useMemo } from 'react';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout, PageContainer } from '@amal-ia/frontend/connected-components/layout';
import { type SelectOption } from '@amal-ia/frontend/design-system/components';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { Spinner, SpinnerBoundary, useStateInLocalStorage } from '@amal-ia/lib-ui';

import CompaniesRoutes from './companies/companies.routes';
import { SuperadminHeader } from './superadmin-header/SuperadminHeader';
import { useCompaniesList, useIsSALoading } from './superAdmin.queries';
import { SARouterContext } from './utils/SARouterContext';

const CalculationsPage = lazy(() => import('./calculations/CalculationsPage'));
const CompaniesEditPage = lazy(() => import('./companies/CompaniesEditPage'));
const MasterSettingsPage = lazy(() => import('./masterSettings/MasterSettingsPage'));
const RefreshmentsPage = lazy(() => import('./refreshments/RefreshmentsPage'));
const ConnectorsPage = lazy(() => import('./connectors/ConnectorsPage'));
const StatementsPage = lazy(() => import('./statements/StatementsPage'));
const UsersPage = lazy(() => import('./users/UsersPage'));
const PlansPage = lazy(() => import('./plans/PlansPage'));

export const useStyles = makeStyles({
  topMenu: {
    overflow: 'hidden',
  },
  filterBar: {
    width: '400px',
    flex: 'initial',
    marginLeft: 'auto',
  },
});

type CompanyOption = SelectOption<string>;

export const SuperAdminRoutes = memo(function SuperAdminRoutes() {
  const isSALoading = useIsSALoading();

  const { data: companyList } = useCompaniesList();

  const [selectedCompanies, setSelectedCompanies] = useStateInLocalStorage<string[]>([], 'saCompaniesListId');

  const companiesOptions: CompanyOption[] = useMemo(
    () =>
      companyList
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          value: company.id,
          label: company.name,
        })),
    [companyList],
  );

  const saRouterContextContent = useShallowObjectMemo({
    selectedCompanies,
    companies: companiesOptions,
  });

  return (
    <Layout>
      <PageContainer showLoadingBar={!!isSALoading}>
        <SuperadminHeader
          companiesOptions={companiesOptions}
          selectedCompanies={selectedCompanies}
          onChangeSelectedCompanies={setSelectedCompanies}
        />

        <SARouterContext.Provider value={saRouterContextContent}>
          <Box mt={2}>
            <Suspense
              fallback={
                <SpinnerBoundary>
                  <Spinner />
                </SpinnerBoundary>
              }
            >
              <RootRoutes>
                <Route
                  path={routes.SUPERADMIN_HOME}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Home" />
                      <CompaniesEditPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_SETTINGS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Master settings" />
                      <MasterSettingsPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={`${routes.SUPERADMIN_COMPANIES}/*`}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Companies" />
                      <CompaniesRoutes />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_CALCULATIONS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Calculations" />
                      <CalculationsPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_REFRESHMENTS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Data refreshments" />
                      <RefreshmentsPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_CONNECTORS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Connectors" />
                      <ConnectorsPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_STATEMENTS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Statements" />
                      <StatementsPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_USERS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Users" />
                      <UsersPage />
                    </AbilityRouteProtector>
                  }
                />

                <Route
                  path={routes.SUPERADMIN_PLANS}
                  element={
                    <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
                      <AmaliaHead title="Superadmin | Plans" />
                      <PlansPage />
                    </AbilityRouteProtector>
                  }
                />
              </RootRoutes>
            </Suspense>
          </Box>
        </SARouterContext.Provider>
      </PageContainer>
    </Layout>
  );
});
