import { Box, Dialog, DialogActions, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button } from '@amal-ia/frontend/design-system/components';
import { DialogTitleWithCloseButton, Text, TextType } from '@amal-ia/lib-ui';

import { type FilterOverwriteRemoveCreationRequestDetails } from './overwrite.types';
import { OVERWRITE_CONTEXT } from './OverwriteModal';
import { OverwriteOptions } from './OverwriteOption';
import { OverwriteRecapInfos } from './recapitulation/OverwriteRecapitulationInfo';

type FilterOverwriteRemoveModalProps = {
  readonly isOpen: boolean;
  readonly currentFilterOverwriteRemoveObjectDetails: FilterOverwriteRemoveCreationRequestDetails;
  readonly handleSubmit: (state: any) => void;
  readonly handleClose: () => void;
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  spacingTop: {
    marginTop: '2Opx',
  },
  spacingBottom: {
    marginBottom: '20px',
  },
  bulkIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 15,
    color: theme.palette.divider,
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },
  formLabel: {
    color: theme.palette.grey['700'],
  },
}));

const initialValues = {
  isApplyToOverall: false,
};

export const FilterOverwriteRemoveModalContainer = memo(function FilterOverwriteRemoveModalContainer({
  isOpen,
  handleClose,
  currentFilterOverwriteRemoveObjectDetails,
  handleSubmit,
}: FilterOverwriteRemoveModalProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const formValidationSchema = Yup.object().shape({
    isApplyToOverall: Yup.boolean(),
  });

  const onSubmit = useCallback(
    (values: any) => {
      handleClose();

      const changed = {
        [currentFilterOverwriteRemoveObjectDetails.rowId]: {
          change: OVERWRITE_CONTEXT.FILTER_ROW_REMOVE,
          isApplyToOverall: values.isApplyToOverall,
          overwriteRequest: currentFilterOverwriteRemoveObjectDetails,
        },
      };

      handleSubmit({ changed });
    },
    [currentFilterOverwriteRemoveObjectDetails, handleClose, handleSubmit],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formValidationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, resetForm }) => (
        <Dialog
          fullWidth
          aria-labelledby="form-dialog-overwrite-data"
          maxWidth="sm"
          open={isOpen}
          onClose={handleClose}
        >
          <DialogTitleWithCloseButton
            handleClose={handleClose}
            id="form-dialog-overwrite"
          >
            <Text type={TextType.MODAL_TITLE}>
              {currentFilterOverwriteRemoveObjectDetails.definitionName ? (
                <FormattedMessage
                  defaultMessage="Overwrite - Remove {name}"
                  values={{ name: currentFilterOverwriteRemoveObjectDetails.definitionName }}
                />
              ) : (
                <FormattedMessage defaultMessage="Overwrite - Remove a row" />
              )}
            </Text>
          </DialogTitleWithCloseButton>
          {/* Information recapitulation */}
          <Box className={classes.spacingBottom}>
            <OverwriteRecapInfos
              currentFilterOverwriteRemoveObjectDetails={currentFilterOverwriteRemoveObjectDetails}
              overwriteContext={OVERWRITE_CONTEXT.FILTER_ROW_REMOVE}
            />
          </Box>

          {/* Options (Apply to whom) */}
          <Box className={classes.spacingTop}>
            <Grid
              container
              alignItems="center"
              direction="row"
              justifyContent="center"
            >
              <Grid>
                <OverwriteOptions
                  isApplyToOverall={values.isApplyToOverall}
                  setIsApplyToOverall={setFieldValue}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button
              variant={Button.Variant.LIGHT}
              onClick={handleClose}
            >
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
            <Button
              aria-label={formatMessage(
                { defaultMessage: 'Remove row {id}' },
                { id: currentFilterOverwriteRemoveObjectDetails.rowNameOrId },
              )}
              onClick={() => {
                onSubmit(values);
                resetForm();
              }}
            >
              <FormattedMessage defaultMessage="Apply" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
});
