import { IconEyeEdit } from '@tabler/icons-react';
import { Fragment, type ReactElement, memo } from 'react';
import { useIntl } from 'react-intl';

import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { OVERWRITE_CONTEXT, OverwriteModalContainer } from '@amal-ia/data-capture/overwrites/components';
import { IconButton, SelectDropdown, type RadioButtonGroupProps } from '@amal-ia/frontend/design-system/components';
import { useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import { useStatementDetailContext } from '@amal-ia/lib-ui';

import * as styles from './KPIList.styles';
import { Section } from './section/Section';
import { useKPIDisplay } from './useKPIDisplay';
import { useKPIOverwrite } from './useKPIOverwrite';
import { useStatementRuleComputedVariables } from './useStatementRuleComputedVariables';

type KPIListProps = {
  readonly ruleDefinition: PlanRule;
  readonly viewSelector?: ReactElement<RadioButtonGroupProps>;
};

export const KPIList = memo(function KPIList({ ruleDefinition, viewSelector }: KPIListProps) {
  const { formatMessage } = useIntl();

  const statementContext = useStatementDetailContext();
  const { data: currentUser } = useCurrentUser();
  const { legacyKpiCardView } = useCompanyCustomization();

  const statementRuleComputedVariables = useStatementRuleComputedVariables(statementContext, ruleDefinition);

  const {
    clearOverwrite,
    closeOverwriteModal,
    isOverwriteModalOpen,
    openOverwriteModal,
    overwriteObjectDetails,
    saveOverwrite,
    setOverwriteObjectDetails,
  } = useKPIOverwrite(statementContext);

  const { availableKPIOptions, displayedKPIIds, setDisplayedKPIIds, KPISections } = useKPIDisplay(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  if (!availableKPIOptions?.length) {
    return null;
  }

  return (
    <Fragment>
      {!!availableKPIOptions.length && (
        <RuleLayout.Kpis>
          <div css={styles.kpisAndFiltersContainer}>
            <div css={styles.sectionsContainer(legacyKpiCardView)}>
              {KPISections.map((section) => (
                <Section
                  key={section.id}
                  clearOverwrite={clearOverwrite}
                  openOverwriteModal={openOverwriteModal}
                  ruleDefinition={ruleDefinition}
                  section={section}
                  setOverwriteObjectDetails={setOverwriteObjectDetails}
                  statementRuleComputedVariables={statementRuleComputedVariables}
                />
              ))}
            </div>

            <div css={styles.filtersContainers}>
              <SelectDropdown
                isMultiple
                options={availableKPIOptions}
                title={formatMessage({ defaultMessage: 'Show/hide variables' })}
                value={displayedKPIIds}
                onChange={setDisplayedKPIIds}
              >
                {({ isDropdownOpen }) => (
                  <IconButton
                    withBackground
                    icon={<IconEyeEdit />}
                    isActive={isDropdownOpen}
                    label={formatMessage({ defaultMessage: 'Show/hide variables' })}
                  />
                )}
              </SelectDropdown>

              {viewSelector}
            </div>
          </div>
        </RuleLayout.Kpis>
      )}

      {!!overwriteObjectDetails && (
        <OverwriteModalContainer
          currentObjectDetails={overwriteObjectDetails}
          currentUser={currentUser}
          handleClose={closeOverwriteModal}
          handleSubmit={saveOverwrite}
          isOpen={isOverwriteModalOpen}
          isSimulation={statementContext.isForecastedView}
          overwriteContext={OVERWRITE_CONTEXT.KPI}
        />
      )}
    </Fragment>
  );
});
