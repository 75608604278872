import { pick } from 'lodash';
import { type ElementType, memo, useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useResizeObserver } from '@amal-ia/ext/react/hooks';

import { type MultiValueLabelProps, type SelectOption, SelectSize } from '../../Select.types';

import { MultiValue } from './multi-value/MultiValue';
import { MultiValueOption, type MultiValueOptionProps } from './multi-value-option/MultiValueOption';
import * as styles from './MultiValueContainer.styles';

const SIZE_GAP_MAPPING: Record<SelectSize, number> = {
  [SelectSize.SMALL]: 6,
  [SelectSize.MEDIUM]: 8,
};

export type MultiValueContainerProps<TOption extends SelectOption = SelectOption> = {
  /** Selected options. */
  readonly options: TOption[];
  /** Select control size. */
  readonly size: SelectSize;
  /** Is the control disabled. */
  readonly disabled?: boolean;
  /** Component to render the label of a selected option inside the control on a multi select component. */
  readonly LabelComponent?: ElementType<MultiValueLabelProps<TOption>>;
};

const MultiValueContainerBase = function MultiValueContainer<TOption extends SelectOption = SelectOption>({
  options,
  size,
  disabled,
  LabelComponent,
}: MultiValueContainerProps<TOption>) {
  const [{ width: containerWidth }, setContainerWidth] = useState({ width: 0 });
  const containerRef = useResizeObserver({ onResize: setContainerWidth });

  // Map option value -> width of the option.
  const [optionsWidth, setOptionsWidth] = useState<Record<PropertyKey, number>>({});
  // Width of the "+X" option.
  const [moreOptionsWidth, setMoreOptionsWidth] = useState(0);

  const onOptionChangeWidth: Required<MultiValueOptionProps<TOption>>['onChangeWidth'] = useCallback(
    (option, width) => setOptionsWidth((prev) => ({ ...prev, [String(option.value)]: width })),
    [],
  );

  // Remove options that are not in the list anymore.
  useEffect(() => {
    setOptionsWidth((prev) =>
      pick(
        prev,
        options.map((option) => String(option.value)),
      ),
    );
  }, [options]);

  // Walk through options and find which options can be rendered alongside the "+X" option.
  // This could be improved by ignoring the "+X" if every option can be rendered but this is fine for now.
  const { options: optionsToRenderMap, count } = options.reduce(
    (acc, option) => {
      const optionWidth = optionsWidth[String(option.value)];

      // If the option does not yet have its size, ignore it. If it has enough size, it will be rendered on next update.
      const nextWidth = optionWidth ? acc.width + optionWidth + SIZE_GAP_MAPPING[size] : acc.width;

      // If the option can fit in the control, render it. Otherwise, ignore it.
      const shouldRenderOption = nextWidth <= containerWidth && !!optionWidth;

      return {
        width: nextWidth,
        options: {
          ...acc.options,
          [String(option.value)]: shouldRenderOption,
        },
        count: acc.count + (shouldRenderOption ? 1 : 0),
      };
    },
    { width: moreOptionsWidth, options: {}, count: 0 } as {
      /** Width acc. Only used to compute options and count. */
      width: number;
      /** Map of option value -> can be rendered. */
      options: Record<PropertyKey, boolean>;
      /** Count of options that can be rendered. */
      count: number;
    },
  );

  return (
    <div
      ref={containerRef}
      className={size}
      css={styles.multiValueContainer}
    >
      {options.map((option) => (
        <MultiValueOption<TOption>
          key={String(option.value)}
          // If it cannot be rendered, render offscreen so we can still get its width.
          css={!optionsToRenderMap[String(option.value)] ? styles.offscreen : undefined}
          disabled={disabled}
          LabelComponent={LabelComponent}
          option={option}
          size={size}
          onChangeWidth={onOptionChangeWidth}
        />
      ))}

      {count < options.length && (
        <MultiValue
          disabled={disabled}
          size={size}
        >
          <FormattedMessage
            defaultMessage="+{count, number}"
            values={{ count: options.length - count }}
          />
        </MultiValue>
      )}

      {/* Get "+X" width with the max size of the options. Otherwise when passing from 1 to 2 digits it could lead to flickering issues. */}
      <MultiValue
        css={styles.offscreen}
        disabled={disabled}
        size={size}
        onChangeWidth={setMoreOptionsWidth}
      >
        <FormattedMessage
          defaultMessage="+{count, number}"
          values={{ count: options.length }}
        />
      </MultiValue>
    </div>
  );
};

export const MultiValueContainer = memo(MultiValueContainerBase) as typeof MultiValueContainerBase;
