import { noop } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type FormulaBuilderFunctionBlockUserOneArgForm } from '@amal-ia/amalia-lang/formula/form';
import { FormulaKeyword } from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { Switch, type SwitchProps } from '@amal-ia/frontend/design-system/components';

import {
  type UseAttributesOptionsOptions,
  useAttributesOptions,
  isQuotaOrVariableTokenType,
  isFieldOrPropertyTokenType,
} from '../../../../hooks/use-attributes-options/useAttributesOptions';
import { useGetFormulaBuilderAttributeLabel } from '../../../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useValueOrAttributeOptions } from '../../../../hooks/use-value-or-attribute-options/useValueOrAttributeOptions';
import { useFormulaBuilderContext } from '../../../formula-builder/FormulaBuilder.context';
import { type FormulaConditionTagProps } from '../../../formula-condition-tag/FormulaConditionTag';
import { functionStringPopoverMessages } from '../../../formula-condition-tag-function-string/function-string-popover/FunctionStringPopover.messages';
import { PopoverValueOrAttributeSelector } from '../../../popover-value-or-attribute-selector/PopoverValueOrAttributeSelector';
import { useValueOrAttributeHandlers } from '../../../popover-value-or-attribute-selector/use-value-or-attribute-handlers/useValueOrAttributeHandlers';
import * as styles from '../FunctionUserPopover.styles';

export type FunctionUserOneArgPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockUserOneArgForm;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockUserOneArgForm>['onChange'];
};

export const FunctionUserOneArgPopover = memo(function FunctionUserOneArgPopover({
  condition,
  onChange = noop,
}: FunctionUserOneArgPopoverProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();

  const USER_ATTRIBUTES_OPTIONS_FILTERS: UseAttributesOptionsOptions = useMemo(
    () => ({
      filterProperty: (property) =>
        property.format === FormatsEnum.text &&
        (!isFieldOrPropertyTokenType(condition.args[0]) ||
          property.machineName !== condition.args[0].propertyMachineName),
      filterVariable: (variable) =>
        variable.format === FormatsEnum.text &&
        (isFieldOrPropertyTokenType(condition.args[0])
          ? variable.machineName !== condition.args[0].propertyMachineName
          : isQuotaOrVariableTokenType(condition.args[0])
            ? variable.machineName !== condition.args[0].machineName
            : true),
      keywords: Object.values(FormulaKeyword),
      showFilteredAsDisabled: false,
    }),
    [condition.args],
  );

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const valueOrAttributeOptions = useValueOrAttributeOptions(propertyName, customObjectDefinition?.name);

  const handleChangeCaseSensitive: Required<SwitchProps>['onChange'] = useCallback(
    (caseSensitive: boolean) =>
      onChange({
        ...condition,
        caseSensitive,
      }),
    [onChange, condition],
  );

  const { handleChangeValueOrAttribute, handleChangeManualValue, handleChangeAttributeValue } =
    useValueOrAttributeHandlers({ condition, onChange });

  const attributesOptions = useAttributesOptions(USER_ATTRIBUTES_OPTIONS_FILTERS);

  return (
    <div css={styles.form}>
      <PopoverValueOrAttributeSelector
        arg={condition.args[1]}
        attributesOptions={attributesOptions}
        conditionCategory={condition.category}
        valueOrAttributeOptions={valueOrAttributeOptions}
        onChangeAttributeValue={handleChangeAttributeValue}
        onChangeManualValue={handleChangeManualValue}
        onChangeValueOrAttribute={handleChangeValueOrAttribute}
      />

      <Switch
        checked={condition.caseSensitive}
        label={<FormattedMessage {...functionStringPopoverMessages.CASE_SENSITIVE} />}
        size={Switch.Size.SMALL}
        tooltip={<FormattedMessage {...functionStringPopoverMessages.CASE_SENSITIVE_TOOLTIP} />}
        onChange={handleChangeCaseSensitive}
      />
    </div>
  );
});
