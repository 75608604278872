import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { type ComputedVariable, type ComputedVariableWithVariableIdAndLabel } from '@amal-ia/lib-types';
import { type StatementDetailContextInterface } from '@amal-ia/lib-ui';
import {
  type StatementThread,
  type StatementThreadScope,
  StatementThreadScopesType,
} from '@amal-ia/payout-collaboration/comments/shared/types';

/**
 * A hook to handle everything related to KPI statement threads (creating, opening, etc.)
 */
export const useKPIThread = (
  statementContext: StatementDetailContextInterface,
  ruleDefinition: PlanRule,
  statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[],
) => {
  const navigate = useNavigate();

  const threadsPerKpi: Record<string, StatementThread> = useMemo(
    () =>
      statementRuleComputedVariables.reduce<Record<string, StatementThread>>((acc, computedVariable) => {
        acc[computedVariable.id] =
          Object.values(statementContext.statementThreads).filter(
            (statementThread: StatementThread) =>
              statementThread.scope?.type === StatementThreadScopesType.KPI &&
              (!statementThread.scope?.ruleId || statementThread.scope?.ruleId === ruleDefinition.id) &&
              statementThread.scope?.id ===
                statementContext.results.definitions.variables[computedVariable.variableMachineName]?.id,
          )[0] || null;

        return acc;
      }, {}),
    [
      statementRuleComputedVariables,
      ruleDefinition.id,
      statementContext.results.definitions.variables,
      statementContext.statementThreads,
    ],
  );

  const openStatementThread = useCallback(
    (statementThreadId: string | null, variable: ComputedVariable) => {
      if (!statementContext.id) {
        return;
      }
      if (!statementThreadId) {
        const scope: StatementThreadScope = {
          type: StatementThreadScopesType.KPI,
          id: statementContext.results.definitions.variables[variable.variableMachineName].id,
          ruleId: ruleDefinition.id,
        };

        statementContext.openStatementThreadPanel(undefined, scope);
      } else {
        navigate(
          generatePath(routes.STATEMENT_COMMENT, {
            id: statementContext.id,
            stid: statementThreadId,
          }),
        );
      }
    },
    [navigate, ruleDefinition.id, statementContext],
  );

  return {
    threadsPerKpi,
    openStatementThread,
  };
};
