import { http } from '@amal-ia/frontend/kernel/http';
import { type Currency } from '@amal-ia/tenants/companies/currency-rates/shared/types';

const apiEndpoint = '/currencies';

export class CurrenciesApiClient {
  public static async getCurrencies(year: string): Promise<Currency[]> {
    const { data } = await http.get<Currency[]>(`${apiEndpoint}/?year=${year}`);
    return data.sort((c1, c2) => c1.date - c2.date);
  }
}
