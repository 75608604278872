import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BounceLoader from 'react-spinners/BounceLoader';

import { colors } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles({
  spinnerContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  background: {
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
  underLabel: {
    position: 'absolute',
    top: 45,
    width: '100%',
    textAlign: 'center',
  },
});

interface SpinnerProps {
  readonly background?: boolean;
}

export const Spinner = memo(function Spinner({ background }: SpinnerProps) {
  const classes = useStyles();
  const [isHoldTightMode, setIsHoldTightMode] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHoldTightMode(true);
    }, 5500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={clsx(classes.spinnerContainer, background && classes.background)}
      data-testid="spinner"
    >
      <BounceLoader
        color={colors['primary-500']}
        size={40}
      />
      {isHoldTightMode ? (
        <Box className={classes.underLabel}>
          <FormattedMessage
            defaultMessage="Hold on…"
            description="Displayed when the app takes too long to load."
          />
        </Box>
      ) : null}
    </div>
  );
});
