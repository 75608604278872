import { MenuItem } from '@mui/material';
import { memo, useCallback } from 'react';

import { type TokenType } from '@amal-ia/lib-types';
import { Text, TextType } from '@amal-ia/lib-ui';

export interface DesignerNewObjectModalItemProps {
  readonly type: TokenType;
  readonly value: string;
  readonly label: string;
  readonly handleCloseMenu: (event) => void;

  readonly onCreate: (type: TokenType) => void;
}

export const DesignerNewObjectModalItem = memo(function DesignerNewObjectModalItem({
  type,
  value,
  label,
  handleCloseMenu,
  onCreate,
}: DesignerNewObjectModalItemProps) {
  const onClickMenuItem = useCallback(
    (e) => {
      handleCloseMenu(e);
      onCreate(type);
    },
    [handleCloseMenu, onCreate, type],
  );

  return (
    <MenuItem
      key={value}
      aria-label={value}
      value={value}
      onClick={onClickMenuItem}
    >
      <Text type={TextType.MODAL_TEXT}> {label} </Text>
    </MenuItem>
  );
});
