import { last } from 'lodash';
import { useCallback } from 'react';

import { RelativePeriodKeyword } from '@amal-ia/compensation-definition/periods/types';
import { type SelectDropdownProps } from '@amal-ia/frontend/design-system/components';

import { type PeriodOption, type PeriodValue } from './PeriodSelect.types';

export const usePeriodSelector = ({
  onChange,
}: {
  onChange: SelectDropdownProps<PeriodOption, true, false>['onChange'];
}) => {
  const handleChangeProxy = useCallback(
    (newValues: PeriodValue[]) => {
      if (newValues.length === 0) {
        onChange([]);
        return;
      }

      const typeOfLastItem: 'KEYWORD' | 'PERIOD' = Object.keys(RelativePeriodKeyword).includes(last(newValues))
        ? 'KEYWORD'
        : 'PERIOD';

      if (typeOfLastItem === 'KEYWORD') {
        onChange([last(newValues)]);
        return;
      }

      onChange(newValues.filter((v) => !Object.keys(RelativePeriodKeyword).includes(v)));
    },
    [onChange],
  );

  return {
    onChange: handleChangeProxy,
  };
};
