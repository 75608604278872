import { css, useTheme } from '@emotion/react';
import { type ComponentPropsWithoutRef, forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { LinearProgress, type LinearProgressProps, Typography } from '@amal-ia/frontend/design-system/components';

export type WorkflowProgressBarProps = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      currentStep: number;
      totalSteps: number;
      backgroundColor?: LinearProgressProps['backgroundColor'];
      progressColor?: LinearProgressProps['progressColor'];
    },
  ]
>;

const WorkflowProgressBarForwardRef = forwardRef<HTMLDivElement, WorkflowProgressBarProps>(function WorkflowProgressBar(
  { currentStep, totalSteps, backgroundColor, progressColor, ...props },
  ref,
) {
  const theme = useTheme();

  return (
    <div
      {...props}
      ref={ref}
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
      `}
    >
      <LinearProgress
        backgroundColor={backgroundColor ?? theme.ds.colors.gray[100]}
        progress={currentStep / totalSteps}
        progressColor={progressColor ?? theme.ds.colors.primary[400]}
        size={8}
        css={css`
          flex: 1;
        `}
      />

      <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>
        <FormattedMessage
          defaultMessage="{current, number}/{total, number}"
          values={{ current: currentStep, total: totalSteps }}
        />
      </Typography>
    </div>
  );
});

export const WorkflowProgressBar = memo(WorkflowProgressBarForwardRef);
