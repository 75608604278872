import { noop } from 'lodash';
import moment from 'moment';
import { memo, type MouseEvent, useCallback, useContext, useMemo } from 'react';

import { FormatsEnum, type Property } from '@amal-ia/data-capture/fields/types';
import { OverwriteTooltip } from '@amal-ia/data-capture/overwrites/components';
import { useClearCustomObject } from '@amal-ia/data-capture/records/shared/state';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { type CustomObject, formatTotal, formatValueTotal, type Overwrite } from '@amal-ia/lib-types';
import { CustomTableContext } from '@amal-ia/lib-ui';

import { TableCell } from './TableCell';

type TableCellProps = Readonly<{
  columnName: string;
  overwrite: Overwrite;
  property: Property;
  row: CustomObject;
  rowIndex: number;
}>;

export const OverwrittenTableCell = memo(function OverwrittenTableCell({
  columnName,
  overwrite,
  property,
  row,
  rowIndex,
}: TableCellProps) {
  const ability = useAbilityContext();
  const { objectDefinition } = useContext(CustomTableContext);

  const { format, ref: valueRef } = property;

  const { mutateAsync: onClear } = useClearCustomObject(objectDefinition.machineName);

  const { overwriteSourceValue, overwriteNewValue } = useMemo(() => {
    if (!overwrite) {
      return { overwriteSourceValue: null, overwriteNewValue: null };
    }
    const owSourceValue = overwrite.sourceValue[columnName];
    const owNewValue = overwrite.overwriteValue[columnName];

    return FormatsEnum.currency === format
      ? {
          overwriteSourceValue: formatTotal(owSourceValue.value, FormatsEnum.currency, owSourceValue.symbol, 1),
          overwriteNewValue: formatTotal(owNewValue.value, FormatsEnum.currency, owNewValue.symbol, 1),
        }
      : {
          overwriteSourceValue: formatValueTotal(owSourceValue?.value || owSourceValue),
          overwriteNewValue: formatValueTotal(owNewValue?.value || owNewValue),
        };
  }, [format, overwrite, columnName]);

  const onClearOverwrite = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();
      if (!overwrite?.id) {
        return;
      }

      await onClear({
        definitionMachineName: objectDefinition.machineName,
        objectExternalId: row.externalId,
        overwriteId: overwrite.id,
      }).catch(noop);
    },
    [overwrite?.id, onClear, objectDefinition.machineName, row.externalId],
  );

  return (
    <OverwriteTooltip
      arrow
      author={`${overwrite.creator.firstName} ${overwrite.creator.lastName}`}
      date={moment(overwrite.createdAt, 'YYYY-MM-DD').format('MMMM Do, YYYY')}
      handleOnClick={onClearOverwrite}
      isReadOnly={ability.cannot(ActionsEnum.overwrite, SubjectsEnum.Data)}
      newValue={overwriteNewValue}
      oldValue={overwriteSourceValue}
      placement="top"
      valueFormat={format}
      valueRef={valueRef}
    >
      <TableCell
        columnName={columnName}
        overwrite={overwrite}
        property={property}
        row={row}
        rowIndex={rowIndex}
      />
    </OverwriteTooltip>
  );
});
