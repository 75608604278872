import { defineMessages } from 'react-intl';

import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
  ValueOrAttributeType,
} from '@amal-ia/amalia-lang/formula/shared/types';

export const formulaConditionTagFunctionUserMessages = defineMessages({
  FILTER_ON: {
    defaultMessage: 'Filter on “{propertyName}”',
  },

  OPERATOR_VALUE: {
    defaultMessage: '{operator}',
  },

  SINGLE_OPERATOR_VALUE: {
    defaultMessage: '{operator}: {value}',
  },

  CLICK_TO_EDIT_CONDITION: {
    defaultMessage: 'Click to edit condition',
  },
} as const);

export const operatorTooltipMessage = defineMessages<
  FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg
>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: 'is selected',
  },
  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is blank',
  },
  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'is not blank',
  },
  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: 'Exactly matches',
  },
  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: 'Does not exactly match',
  },
});

export const valueOrAttributeValuesMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: '"{value}"',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: '{value}',
  },
} as const);
