import { IconMessage, IconMessageCheck } from '@tabler/icons-react';
import { memo, type MouseEvent, type MouseEventHandler, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FormatsEnum, type PropertyRef } from '@amal-ia/data-capture/fields/types';
import { Button, IconButton, Typography } from '@amal-ia/frontend/design-system/components';
import { useClipboard } from '@amal-ia/frontend-kernel-clipboard';
import { eventStopPropagationAndPreventDefault } from '@amal-ia/lib-ui';
import { type StatementThread } from '@amal-ia/payout-collaboration/comments/shared/types';

import { OverwriteTooltipValue, OverwriteTooltipValueVariant } from './overwrite-tooltip-value/OverwriteTooltipValue';
import { messages } from './OverwriteTooltipContent.messages';
import * as styles from './OverwriteTooltipContent.styles';

export type OverwriteTooltipContentProps = {
  readonly isReadOnly: boolean;
  readonly author: string;
  readonly date: string;
  /* FIXME: any are you okay? */
  readonly oldValue: any;
  readonly newValue: any;
  readonly newValueRaw?: any;
  readonly valueFormat?: FormatsEnum;
  readonly valueRef?: PropertyRef;
  readonly handleOnClick?: MouseEventHandler<HTMLButtonElement>;
  readonly cellStatementThread?: StatementThread;
  readonly openStatementThread?: () => void;
};

export const OverwriteTooltipContent = memo(function OverwriteTooltipContent({
  isReadOnly,
  author,
  date,
  oldValue,
  newValue,
  newValueRaw,
  valueFormat,
  valueRef,
  handleOnClick,
  cellStatementThread,
  openStatementThread,
}: OverwriteTooltipContentProps) {
  const { copy } = useClipboard();
  const { formatMessage } = useIntl();

  const canClearOverwrite = !isReadOnly && !!handleOnClick;

  const onClickCopyOldValue = useCallback(
    (e: MouseEvent) => {
      eventStopPropagationAndPreventDefault(e);
      copy(`${oldValue}`);
    },
    [oldValue, copy],
  );

  const onClickCopyNewValue = useCallback(
    (e: MouseEvent) => {
      eventStopPropagationAndPreventDefault(e);
      copy(`${newValue}`);
    },
    [newValue, copy],
  );

  const onClickComment = useCallback(() => {
    openStatementThread?.();
  }, [openStatementThread]);

  return (
    <div css={styles.container}>
      <div css={styles.titleContainer}>
        <div css={styles.title}>
          <Typography variant={Typography.Variant.BODY_SMALL_MEDIUM}>
            <FormattedMessage {...messages.DETAILS} />
          </Typography>
          {openStatementThread ? (
            <IconButton
              withBackground
              data-testid="chat-button"
              label={formatMessage(messages.COMMENT)}
              size={IconButton.Size.SMALL}
              icon={
                cellStatementThread?.thread.isReviewed ? (
                  <IconMessageCheck data-testid="chat-button:is-reviewed" />
                ) : (
                  <IconMessage data-testid="chat-button:not:is-reviewed" />
                )
              }
              onClick={onClickComment}
            />
          ) : null}
        </div>
      </div>
      <div css={styles.body}>
        <div css={styles.valueContainer}>
          <div css={styles.value}>
            <OverwriteTooltipValue
              value={oldValue}
              valueFormat={valueFormat}
              valueRef={valueRef}
              variant={OverwriteTooltipValueVariant.OLD}
              onClick={onClickCopyOldValue}
            />
          </div>
          <div css={styles.value}>
            <OverwriteTooltipValue
              rawValue={newValueRaw}
              value={newValue}
              valueFormat={valueFormat}
              valueRef={valueRef}
              variant={OverwriteTooltipValueVariant.NEW}
              onClick={onClickCopyNewValue}
            />
          </div>
        </div>
        <div css={styles.footer}>
          <div>
            <Typography variant={Typography.Variant.BODY_SMALL_REGULAR}>
              <FormattedMessage
                {...messages.OVERWRITTEN_BY}
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>,
                  author,
                  date,
                }}
              />
            </Typography>
          </div>
          <div>
            {canClearOverwrite ? (
              <Button
                data-testid="clean-overwrite-button"
                variant={Button.Variant.SECONDARY}
                onClick={handleOnClick}
              >
                <FormattedMessage {...messages.CLEAR_OVERWRITE} />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});
