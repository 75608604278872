import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useCallback } from 'react';
import ReactPaginate from 'react-paginate';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

interface PaginationProps {
  readonly current: number;
  readonly total: number;
  readonly onPageChange: (page: number) => any;
  readonly className?: string;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    // React-Paginate creates a ul > li list.
    // So we need to remove its default style.
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  element: {
    verticalAlign: 'middle',
    '& > a': {
      display: 'block',
      padding: theme.spacing(1),
      cursor: 'pointer',
    },
  },
  active: {
    background: 'rgba(106, 53, 255, 0.1)',
    borderRadius: theme.shape.borderRadius,
  },
}));

export const Pagination = memo(function Pagination({ current, total, onPageChange, className }: PaginationProps) {
  const classes = useStyles();

  const onPageChangeProxy = useCallback(
    ({ selected }: { selected: number }) => {
      onPageChange(selected + 1);
    },
    [onPageChange],
  );

  // Careful, this component counts from 0 but nestJs count from
  // 1, thus those +1 / -1.
  return (
    <ReactPaginate
      activeClassName={classes.active}
      containerClassName={clsx(className, classes.root)}
      initialPage={current - 1}
      marginPagesDisplayed={1}
      nextClassName={classes.element}
      nextLabel={<ChevronRight />}
      pageClassName={classes.element}
      pageCount={total}
      pageRangeDisplayed={2}
      previousClassName={classes.element}
      previousLabel={<ChevronLeft />}
      onPageChange={onPageChangeProxy}
    />
  );
});
