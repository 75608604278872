import { css } from '@emotion/react';
import { memo, useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStateWithRef } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { Dropdown } from '../../../overlays/dropdown/Dropdown';
import { useFlattenOptions } from '../../../overlays/select-dropdown/hooks/useFlattenOptions';
import { SelectDropdown, type SelectDropdownProps } from '../../../overlays/select-dropdown/SelectDropdown';
import {
  type SelectDropdownValue,
  type SelectOptionGroup,
} from '../../../overlays/select-dropdown/SelectDropdown.types';
import { useFiltersContext } from '../Filters.context';
import { type FilterOption } from '../Filters.types';

import { FilterTag, type FilterTagProps } from './filter-tag/FilterTag';
import { isEmptyValue } from './helpers/filterValue';

type FilterDropdownProps<
  TOption extends FilterOption = FilterOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = SelectDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, true, TGroup>;

export type FilterProps<
  TOption extends FilterOption = FilterOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
> = MergeAll<
  [
    Omit<
      FilterDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TGroup>,
      | 'children'
      | 'initialFocus'
      | 'isClearable'
      | 'onChangeSearchText'
      | 'searchText'
      | 'shouldDismiss'
      | 'shouldToggleOnClick'
      | 'shouldTriggerOnFocus'
    >,
    Pick<FilterTagProps<TOption, TIsMultiple>, 'allSelectedLabel' | 'id' | 'isStatic' | 'label'>,
    {
      value: Required<FilterDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TGroup>>['value'];
      onChange: Required<FilterDropdownProps<TOption, TIsMultiple, TUseOptionAsValue, TGroup>>['onChange'];
    },
  ]
>;

const FilterBase = function Filter<
  TOption extends FilterOption = FilterOption,
  TIsMultiple extends boolean | undefined = undefined,
  TUseOptionAsValue extends boolean | undefined = undefined,
  TGroup extends SelectOptionGroup<TOption> = SelectOptionGroup<TOption>,
>({
  id,
  label,
  allSelectedLabel,
  isStatic,
  options,
  value: propsValue,
  onChange: propsOnChange,
  ...props
}: FilterProps<TOption, TIsMultiple, TUseOptionAsValue, TGroup>) {
  /** Init isOpen to true if this is the last added filter. This handles automatically opening the dropdown when adding a filter. */
  const { lastFilterAddedId } = useFiltersContext();
  const [isOpen, setIsOpen] = useState(lastFilterAddedId === id);

  // We need to validate the user changes when the dropdown is closed, so we make a copy of the props value and pass them to SelectDropdown.
  // On close, we call onChange with the internal value.
  const [internalValue, setInternalValue, internalValueRef] = useStateWithRef(propsValue);

  const flatOptions = useFlattenOptions(options);
  const hasEveryOptionSelected =
    !!props.isMultiple &&
    (internalValue as SelectDropdownValue<TOption, true, TUseOptionAsValue>).length === flatOptions.length;

  // Sync internalValue with propsValue.
  useEffect(() => {
    setInternalValue(propsValue);
  }, [propsValue, setInternalValue]);

  // On close, if the filter changed, call onChange with the new value.
  const handleChangeIsOpen = useCallback(
    (newIsOpen: boolean) => {
      setIsOpen(newIsOpen);

      // We are using the state ref value here because the state value is not updated yet at this point.
      if (!newIsOpen && internalValueRef.current !== propsValue) {
        propsOnChange(
          hasEveryOptionSelected
            ? ([] as SelectDropdownValue<TOption, true, TUseOptionAsValue> as Parameters<typeof propsOnChange>[0])
            : internalValueRef.current,
        );
      }
    },
    [propsOnChange, propsValue, internalValueRef, hasEveryOptionSelected],
  );

  const handleClear = useCallback(() => {
    setInternalValue((props.isMultiple ? [] : null) as Parameters<typeof propsOnChange>[0]);
    handleChangeIsOpen(false);
  }, [setInternalValue, handleChangeIsOpen, props.isMultiple]);

  return (
    <SelectDropdown<TOption, TIsMultiple, TUseOptionAsValue, true, TGroup>
      {...props}
      isClearable
      shouldToggleOnClick
      shouldTriggerOnFocus
      isOpen={isOpen}
      options={options}
      value={internalValue}
      action={
        <Dropdown.Action
          disabled={isEmptyValue(internalValue)}
          onClick={handleClear}
        >
          <FormattedMessage defaultMessage="Clear filter" />
        </Dropdown.Action>
      }
      onChange={setInternalValue}
      onChangeIsOpen={handleChangeIsOpen}
    >
      {({ isDropdownOpen, hasEveryOptionSelected, value, flatOptions }) => (
        // Wrap in a div to prevent floating-ui from overriding onX callbacks (it overrides the root child element).
        <div
          css={css`
            display: flex;
          `}
        >
          <FilterTag<TOption, TIsMultiple>
            allSelectedLabel={allSelectedLabel}
            flatOptions={flatOptions}
            hasEveryOptionSelected={hasEveryOptionSelected}
            id={id}
            isDropdownOpen={isDropdownOpen}
            isStatic={isStatic}
            label={label}
            value={value}
            onClear={handleClear}
          />
        </div>
      )}
    </SelectDropdown>
  );
};

export const Filter = memo(FilterBase) as typeof FilterBase;
