import { cloneDeep, isArray } from 'lodash';

import { type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type BaseCustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { type CustomObject } from '@amal-ia/lib-types';

import { convertDateToTimestamp } from './dates';

type ContentType = Record<string, unknown>;

export class RowConverterUtils {
  /**
   * Convert row content dates and currencies according to definition, so that they can be used for calculation :
   * - currency fields are converted to their value
   * - date fields are converted to timestamps
   * @param currentObjectMachineName
   * @param content
   * @param relationshipsMap
   * @param customObjectDefinitionsMap
   */
  public static convertRowDatesAndCurrencies(
    currentObjectMachineName: string,
    content: ContentType,
    relationshipsMap: Record<string, Relationship>,
    customObjectDefinitionsMap: Record<string, BaseCustomObjectDefinition>,
  ): object {
    const customObjectDefinition = customObjectDefinitionsMap[currentObjectMachineName];
    const properties = customObjectDefinition.properties;
    const convertedContent = cloneDeep(content);

    Object.keys(properties).forEach((key) => {
      const prop = properties[key];

      if (prop.format === FormatsEnum.date || prop.format === FormatsEnum['date-time']) {
        // If property is a variable date => converts to timestamp.
        convertedContent[key] = null;
        if (content[key]) {
          convertedContent[key] =
            typeof content[key] === 'string' ? convertDateToTimestamp(content[key] as string) : content[key];
        }
      } else if (prop.format === FormatsEnum.currency) {
        convertedContent[key] = this.getCurrencyValue(content[key]);
      }
    });

    Object.keys(convertedContent).forEach((key) => {
      if (convertedContent[key]) {
        const relation = relationshipsMap[key];

        if (relation) {
          // 1 - search if property is a relation => if so, run convertRowDatesAndCurrencies recursively according to relation definition.
          if (isArray(content[key])) {
            convertedContent[key] = (content[key] as []).map((contentItem) =>
              RowConverterUtils.convertRowDatesAndCurrencies(
                relation.toDefinitionMachineName,
                contentItem,
                relationshipsMap,
                customObjectDefinitionsMap,
              ),
            );
          } else {
            convertedContent[key] = RowConverterUtils.convertRowDatesAndCurrencies(
              relation.toDefinitionMachineName,
              convertedContent[key] as ContentType,
              relationshipsMap,
              customObjectDefinitionsMap,
            );
          }
        } else if ((convertedContent[key] as CurrencyValue).symbol) {
          // 2 - search and convert variable object
          // If it's a variable currency => converts to its value.
          convertedContent[key] = this.getCurrencyValue(convertedContent[key]);
        }
      }
    });

    return convertedContent;
  }

  /**
   * Get a row content (row can be a filter row with a content property or
   * a relation : in case of a relation row there is no content property,
   * content is the row object itself).
   * @param row
   */
  public static getRowContent(row: unknown): Record<string, unknown> {
    return (row as CustomObject).content || (row as CustomObject);
  }

  private static getCurrencyValue(currencyValue: unknown): number | null {
    if (currencyValue === null || currencyValue === undefined) {
      return null;
    }

    return (currencyValue as CurrencyValue).symbol ? (currencyValue as CurrencyValue).value : (currencyValue as number);
  }
}
