import { css, useTheme } from '@emotion/react';
import { IconMedal } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type ChallengeLeaderboard } from '@amal-ia/compensation-definition/plans/types';
import { Badge, Table } from '@amal-ia/frontend/design-system/components';

export type ChallengeLeaderboardCellPositionProps = {
  readonly position: ChallengeLeaderboard['position'];
};

export const ChallengeLeaderboardCellPosition = memo(function ChallengeLeaderboardCellPosition({
  position,
}: ChallengeLeaderboardCellPositionProps) {
  const theme = useTheme();
  return (
    <Table.Cell.Main>
      {position === 1 ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          <IconMedal
            color={theme.ds.hues.purple[400]}
            size={16}
          />

          {position}
        </div>
      ) : (
        position ?? (
          <Badge
            size={Badge.Size.SMALL}
            variant={Badge.Status.DANGER}
          >
            <FormattedMessage defaultMessage="Non-eligible" />
          </Badge>
        )
      )}
    </Table.Cell.Main>
  );
});
