import { http } from '@amal-ia/frontend/kernel/http';
import {
  type CommentThreadMessage,
  type MessageContent,
  type StatementThread,
  type StatementThreadScope,
} from '@amal-ia/payout-collaboration/comments/shared/types';
import { type UserContract as UserInterface, type UserContract } from '@amal-ia/tenants/users/shared/types';

export class PayoutCollaborationCommentsApiClient {
  public static async getThreadsForStatement(statementId: string): Promise<StatementThread[]> {
    const { data } = await http.get<StatementThread[]>(`/statements/${statementId}/statementthreads`);
    return data;
  }

  public static async setThreadIsReviewed(
    statementId: string,
    statementThreadId: string,
    isReviewed: boolean,
  ): Promise<StatementThread> {
    const { data } = await http.patch<StatementThread>(
      `/statements/${statementId}/statementthreads/${statementThreadId}`,
      {
        reviewed: isReviewed,
      },
    );
    return data;
  }

  public static async createThreadOnStatement(
    statementId: string,
    scope?: StatementThreadScope,
  ): Promise<StatementThread> {
    const { data } = await http.post<StatementThread>(`/statements/${statementId}/statementthreads`, { scope });
    return data;
  }

  public static async getStatementThreadMessages(
    statementId: string,
    statementThreadId: string,
  ): Promise<CommentThreadMessage[]> {
    const { data } = await http.get<CommentThreadMessage[]>(
      `/statements/${statementId}/statementthreads/${statementThreadId}/messages`,
    );
    return data;
  }

  public static async postMessageOnStatementThread(
    statementId: string,
    statementThreadId: string,
    message: MessageContent[],
  ): Promise<StatementThread> {
    const { data } = await http.post<StatementThread>(
      `/statements/${statementId}/statementthreads/${statementThreadId}/messages`,
      { content: message },
    );
    return data;
  }

  public static async deleteMessageFromStatementThread(
    statementId: string,
    statementThreadId: string,
    messageId: string,
  ): Promise<void> {
    await http.delete(`/statements/${statementId}/statementthreads/${statementThreadId}/messages/${messageId}`);
  }

  public static async updateMessageFromStatementThread({
    statementId,
    statementThreadId,
    messageId,
    content,
  }: {
    statementId: string;
    statementThreadId: string;
    messageId: string;
    content: MessageContent[];
  }): Promise<void> {
    await http.patch(`/statements/${statementId}/statementthreads/${statementThreadId}/messages/${messageId}`, {
      content,
    });
  }

  public static async getUsersAllowedToViewStatement(statementId: string): Promise<Partial<UserContract>[]> {
    const { data } = await http.get<UserInterface[]>(`/statements/${statementId}/statementthreads/usersAllowedToView`);
    return data;
  }
}
