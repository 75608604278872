import { css } from '@emotion/react';
import { IconCheckbox } from '@tabler/icons-react';
import { memo, forwardRef, type ForwardedRef, cloneElement, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { IconButton } from '../../../../general/icon-button/IconButton';
import { TextOverflow } from '../../../../general/text-overflow/TextOverflow';
import { Typography } from '../../../../general/typography/Typography';
import { ColumnTooltip } from '../../column-helpers/column-tooltip/ColumnTooltip';
import { type ColumnDefinition } from '../../Table.types';
import { TableCell, type TableCellProps } from '../table-cell/TableCell';

import * as styles from './TableHeaderCell.styles';

export type TableHeaderCellProps = MergeAll<
  [
    Omit<TableCellProps, 'as'>,
    {
      subTitle?: ColumnDefinition['subTitle'];
      backgroundColor?: ColumnDefinition['headerBackgroundColor'];
      icon?: ColumnDefinition['icon'];
      tooltip?: ColumnDefinition['tooltip'];
      actions?: ColumnDefinition['actions'];
      isSelectionEnabled?: boolean;
      isSelectionActive?: boolean;
      onChangeIsSelectionActive?: (isSelectionActive: boolean) => void;
    },
  ]
>;

const TableHeaderCellForwardRef = forwardRef(function TableHeaderCell(
  {
    children,
    subTitle,
    backgroundColor,
    actions,
    icon,
    tooltip,
    isSelectionEnabled = false,
    isSelectionActive = false,
    onChangeIsSelectionActive,
    ...props
  }: TableHeaderCellProps,
  ref: ForwardedRef<HTMLTableCellElement>,
) {
  const { formatMessage } = useIntl();

  const handleToggleSelectionActive = useCallback(() => {
    onChangeIsSelectionActive?.(!isSelectionActive);
  }, [isSelectionActive, onChangeIsSelectionActive]);

  return (
    <TableCell
      {...props}
      ref={ref}
      as="th"
      css={[
        styles.tableHeaderCell,
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `,
      ]}
    >
      <div css={styles.tableHeaderCellContent}>
        <div css={styles.multiSelectTitleContainer}>
          {!!isSelectionEnabled && (
            <IconButton
              withBackground
              aria-checked={isSelectionEnabled}
              icon={<IconCheckbox />}
              isActive={isSelectionActive}
              role="checkbox"
              size={IconButton.Size.SMALL}
              label={
                isSelectionActive
                  ? formatMessage({ defaultMessage: 'Hide multi-selection' })
                  : formatMessage({ defaultMessage: 'Show multi-selection' })
              }
              onClick={handleToggleSelectionActive}
            />
          )}

          <div
            css={styles.iconTitleContainer}
            data-has-subtitle={!!subTitle}
          >
            {!!icon && cloneElement(icon, { height: 16, width: 16 })}

            <div css={styles.titleContainer}>
              <div css={styles.titleTooltipContainer}>
                <TextOverflow>{children}</TextOverflow>

                {/* If tooltip is a string, wrap inside a ColumnTooltip, otherwise consider that it is already a ColumnTooltip. */}
                {!!tooltip && (
                  <div css={styles.tooltipIconContainer}>
                    {typeof tooltip === 'string' ? <ColumnTooltip content={tooltip} /> : tooltip}
                  </div>
                )}
              </div>

              {!!subTitle && (
                <Typography
                  as={TextOverflow}
                  css={styles.subTitle}
                  variant={Typography.Variant.BODY_XSMALL_REGULAR}
                >
                  {subTitle}
                </Typography>
              )}
            </div>
          </div>
        </div>

        {actions}
      </div>
    </TableCell>
  );
});

export const TableHeaderCell = memo(TableHeaderCellForwardRef);
