import { type Dispatch, type SetStateAction, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Filters } from '@amal-ia/frontend/design-system/components';

import { type FilterProps } from '../OverwritesContainer';

import { useOverwriteFilters } from './useOverwritesFilters';

export type OverwritesFiltersProps = {
  readonly filter: FilterProps | null;
  readonly setFilter: Dispatch<SetStateAction<FilterProps>>;
};

export const OverwritesFilters = memo(function OverwritesFilters({ filter, setFilter }: OverwritesFiltersProps) {
  const { filtersOptions, handleChangeFilter } = useOverwriteFilters({ setFilter });

  const handleChangeUsers = useMemo(() => handleChangeFilter('userIds'), [handleChangeFilter]);
  const handleChangePlans = useMemo(() => handleChangeFilter('planIds'), [handleChangeFilter]);
  const handleChangeDataSources = useMemo(() => handleChangeFilter('dataSourceIds'), [handleChangeFilter]);
  const handleChangePeriods = useMemo(() => handleChangeFilter('periodIds'), [handleChangeFilter]);
  const handleChangeOverwrittenBy = useMemo(() => handleChangeFilter('overwrittenByIds'), [handleChangeFilter]);
  const handleChangeStatus = useMemo(() => handleChangeFilter('statusValues'), [handleChangeFilter]);
  const handleChangeTypes = useMemo(() => handleChangeFilter('typeValues'), [handleChangeFilter]);

  return (
    <Filters>
      <Filters.Filter
        isMultiple
        isStatic
        allSelectedLabel={<FormattedMessage defaultMessage="All users" />}
        id="filterUsers"
        label={<FormattedMessage defaultMessage="Users" />}
        options={filtersOptions?.users || []}
        value={filter?.userIds || []}
        onChange={handleChangeUsers}
      />
      <Filters.Filter
        isMultiple
        isStatic
        allSelectedLabel={<FormattedMessage defaultMessage="All plans" />}
        id="filterPlans"
        label={<FormattedMessage defaultMessage="Plans" />}
        options={filtersOptions?.plans || []}
        value={filter?.planIds || []}
        onChange={handleChangePlans}
      />
      <Filters.Filter
        isMultiple
        allSelectedLabel={<FormattedMessage defaultMessage="All data sources" />}
        id="filterDatasource"
        label={<FormattedMessage defaultMessage="Data sources" />}
        options={filtersOptions?.dataSources || []}
        value={filter?.dataSourceIds || []}
        onChange={handleChangeDataSources}
      />
      <Filters.Filter
        isMultiple
        allSelectedLabel={<FormattedMessage defaultMessage="All periods" />}
        id="filterPeriods"
        label={<FormattedMessage defaultMessage="Periods" />}
        options={filtersOptions?.periods || []}
        value={filter?.periodIds || []}
        onChange={handleChangePeriods}
      />
      <Filters.Filter
        isMultiple
        allSelectedLabel={<FormattedMessage defaultMessage="All authors" />}
        id="filterOverwrittenBy"
        label={<FormattedMessage defaultMessage="Overwritten by" />}
        options={filtersOptions?.overwrittenBy || []}
        value={filter?.overwrittenByIds || []}
        onChange={handleChangeOverwrittenBy}
      />
      <Filters.Filter
        isMultiple
        allSelectedLabel={<FormattedMessage defaultMessage="All statuses" />}
        id="filterStatus"
        label={<FormattedMessage defaultMessage="Statuses" />}
        options={filtersOptions?.status || []}
        value={filter?.statusValues || []}
        onChange={handleChangeStatus}
      />
      <Filters.Filter
        isMultiple
        allSelectedLabel={<FormattedMessage defaultMessage="All types" />}
        id="filterTypes"
        label={<FormattedMessage defaultMessage="Types" />}
        options={filtersOptions?.types || []}
        value={filter?.typeValues || []}
        onChange={handleChangeTypes}
      />
    </Filters>
  );
});
