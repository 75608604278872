import styled from '@emotion/styled';

export const RuleLayout = Object.assign(
  styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
    border-top: 1px solid ${({ theme }) => theme.ds.colors.gray[100]};
  `,
  {
    Kpis: styled.div`
      padding: 32px 0 24px;
      padding-top: ${(props: { noPaddingTop?: boolean }) => (props.noPaddingTop ? '0px' : '32px')};

      &:only-child {
        padding: 32px 0;
      }
    `,

    Charts: styled.div`
      padding: 32px 0 24px;

      &:only-child {
        padding: 32px 0;
      }
    `,

    Dataset: styled.div`
      padding: 16px 0 24px;
      width: 100%;

      &:only-child {
        padding: 24px 0;
      }
    `,

    HnrRules: styled.div`
      margin: 0 -24px;

      > * {
        border-bottom: 1px solid ${({ theme }) => theme.ds.colors.gray[50]};
      }
    `,
  },
);
