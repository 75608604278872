import { defineMessage } from 'react-intl';

import { VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { type Plan } from '@amal-ia/compensation-definition/plans/types';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type QuotaAssignment } from '@amal-ia/management/assignments/quotas/types';
import { type TeamContract } from '@amal-ia/tenants/teams/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { fetchPlans } from '../../redux/plans/actions';
import { type ThunkDispatch } from '../../redux/store';
import { fetchAllTeamsThunkAction } from '../../redux/teams/actions';
import { fetchAllUsers } from '../../redux/users/actions';

export const isTheSameVariableValue = (vv1: QuotaAssignment, vv2: QuotaAssignment): boolean =>
  vv1.startDate === vv2.startDate &&
  vv1.endDate === vv2.endDate &&
  vv1.userId === vv2.userId &&
  vv1.teamId === vv2.teamId &&
  vv1.planId === vv2.planId &&
  vv1.variableId === vv2.variableId;

type BuildCellValueParameter = Plan | TeamContract | UserContract;

export const QuotaEntityAttributes = {
  [VariableObjectsEnum.user]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned users' }),
    uniqueIdAttribute: 'userId',
    buildCellValue: (user: BuildCellValueParameter) => (user ? formatUserFullName(user as UserContract) : ''),
    buildCellLabel: (user: BuildCellValueParameter) => (user as UserContract).email,
    columnToAdd: {
      name: 'user',
      title: defineMessage({ defaultMessage: 'User' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchAllUsers(true)),
  },
  [VariableObjectsEnum.team]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned teams' }),
    uniqueIdAttribute: 'teamId',
    buildCellValue: (team: BuildCellValueParameter) => (team as TeamContract).name,
    buildCellLabel: (team: BuildCellValueParameter) => (team as TeamContract).name,
    columnToAdd: {
      name: 'team',
      title: defineMessage({ defaultMessage: 'Team' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchAllTeamsThunkAction()),
  },
  [VariableObjectsEnum.plan]: {
    showUnassignedMessage: defineMessage({ defaultMessage: 'Show unassigned plans' }),
    uniqueIdAttribute: 'planId',
    buildCellValue: (plan: BuildCellValueParameter) => (plan as Plan).name,
    buildCellLabel: (plan: BuildCellValueParameter) => (plan as Plan).name,
    columnToAdd: {
      name: 'plan',
      title: defineMessage({ defaultMessage: 'Plan' }),
      getCellValue: (row: any) => row.cellValue,
    },
    fetchPromise: (dispatch: ThunkDispatch) => dispatch(fetchPlans()),
  },
};
