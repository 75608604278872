import { IconMessageCheck, IconMessage, IconWriting } from '@tabler/icons-react';
import clsx from 'clsx';
import { noop } from 'lodash';
import { memo } from 'react';

import { IconButton, TextClamp, TextOverflow, Tooltip, Typography } from '@amal-ia/frontend/design-system/components';

import * as styles from './KpiCardItem.styles';

export type KpiCardItemProps = {
  readonly canComment?: boolean;
  readonly canOverwrite?: boolean;
  readonly isThreadOpen?: boolean;
  readonly isThreadReviewed?: boolean;
  readonly isOverwritten?: boolean;
  readonly isForecasted?: boolean;
  readonly commentTooltipText?: string;
  readonly overwriteTooltipText?: string;
  readonly name: string;
  readonly rawValue?: number | string;
  readonly value: number | string;
  readonly onClickComment?: () => void;
  readonly onClickOverwrite?: () => void;
};

export const KpiCardItem = memo(function KpiCardItem({
  canComment = false,
  canOverwrite = false,
  isThreadOpen = false,
  isThreadReviewed = false,
  isOverwritten = false,
  isForecasted = false,
  commentTooltipText,
  overwriteTooltipText,
  name,
  rawValue,
  value,
  onClickComment = noop,
  onClickOverwrite = noop,
}: KpiCardItemProps) {
  return (
    <div
      css={[styles.kpiCardItem, isOverwritten && styles.overwritten, isForecasted && styles.forecasted]}
      data-kpi={name}
    >
      <TextClamp
        shouldFillSpace
        css={[styles.kpiName, isOverwritten && styles.overwritten]}
        maxLines={2}
        variant={TextClamp.Variant.BODY_BASE_REGULAR}
      >
        {name}
      </TextClamp>

      <div css={styles.footer}>
        <Tooltip content={rawValue}>
          <Typography
            as={TextOverflow}
            variant={Typography.Variant.BODY_LARGE_MEDIUM}
          >
            {value}
          </Typography>
        </Tooltip>

        {!!(canOverwrite || canComment) && (
          <div css={styles.actions}>
            {!!canOverwrite && (
              <div css={styles.action}>
                <IconButton
                  withBackground
                  icon={<IconWriting />}
                  label={overwriteTooltipText}
                  size={IconButton.Size.SMALL}
                  onClick={onClickOverwrite}
                />
              </div>
            )}

            {!!canComment && (
              <div
                className={clsx({ [styles.FORCE_SHOW_CLASSNAME]: isThreadOpen })}
                css={styles.action}
              >
                <IconButton
                  withBackground
                  icon={isThreadReviewed ? <IconMessageCheck /> : <IconMessage />}
                  label={commentTooltipText}
                  size={IconButton.Size.SMALL}
                  onClick={onClickComment}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
