import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type KeysOfStringValues } from '@amal-ia/ext/typescript';
import { type LanguagesEnum } from '@amal-ia/kernel/intl/types';
import { type Company } from '@amal-ia/tenants/companies/types';

import { type UserRole } from './userRole';

export enum UserExternalIdSource {
  NONE = 'NONE',
  OTHERSOURCE = 'OTHERSOURCE',
  SALESFORCE = 'SALESFORCE',
  HUBSPOT = 'HUBSPOT',
  ZOHO = 'ZOHO',
}

export enum UserHrisIdSource {
  NONE = 'NONE',
  OTHERSOURCE = 'OTHERSOURCE',
  PERSONIO = 'PERSONIO',
  BAMBOOHR = 'BAMBOOHR',
  WORKDAY = 'WORKDAY',
}

export type UserSettings = {
  isReceiveEmailsOnNewComment?: boolean;
  isReceiveEmailsOnNewExport?: boolean;
  isReceiveEmailsOnReview?: boolean;
  isReceiveEmailsOnPlanAgreementToConfirm?: boolean;
  isReceiveSlacksOnNewComment?: boolean;
  isReceiveSlacksOnNewExport?: boolean;
  isReceiveSlacksOnReview?: boolean;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  ONBOARDING = 'ONBOARDING',
  INVITED = 'INVITED',
}

export enum AmaliaRole {
  TECH_ADMIN = 'TECH_ADMIN',
}

type ActiveUserStatusWithDates = {
  clearedAt?: null;
  joinedAt: Date;
  invitationSentAt: Date;
  previousLastConnection: string;
  status: UserStatus.ACTIVE;
};

type DeactivatedUserStatusWithDates = {
  clearedAt: Date;
  joinedAt?: Date | null;
  invitationSentAt?: Date | null;
  previousLastConnection?: null;
  status: UserStatus.DEACTIVATED;
};

type InvitedUserStatusWithDates = {
  clearedAt?: null;
  joinedAt?: null;
  invitationSentAt: Date;
  previousLastConnection?: null;
  status: UserStatus.INVITED;
};

type OnboardingUserStatusWithDates = {
  clearedAt?: null;
  joinedAt?: null;
  invitationSentAt?: null;
  previousLastConnection?: null;
  status: UserStatus.ONBOARDING;
};

export type UserStatusWithDates = {
  createdAt: Date;
} & (
  | ActiveUserStatusWithDates
  | DeactivatedUserStatusWithDates
  | InvitedUserStatusWithDates
  | OnboardingUserStatusWithDates
);

// FIXME: merge with UserRoot?
export type UserContract = {
  company: Company;
  companyId: string;

  // UserProfile
  id: string;
  createdAt: Date;
  updatedAt: Date;
  lastConnection: Date | null;

  firstName: string | null;
  lastName: string | null;
  email: string;
  pictureURL: string | null;

  role: UserRole;
  currency: CurrencySymbolsEnum;

  language: LanguagesEnum;

  externalId: string | null;
  externalIdSource: UserExternalIdSource;
  // Human resource id (payfit, personio, ...).
  hrisId: string | null;
  hrisIdSource: UserHrisIdSource;

  // UserStatus
  status: UserStatus;
  invitationSentAt: Date | null;
  joinedAt: Date | null;
  clearedAt: Date | null;

  // TODO: lifecycle here is different (only PATCHed by its owner, not needed in directory).
  settings: UserSettings | null;
};

export interface SyncUserRequest {
  id?: string;

  email: string;

  firstName?: string;

  lastName?: string;

  language?: LanguagesEnum;

  currency?: CurrencySymbolsEnum;

  role?: UserRole;

  externalId?: string;
  externalIdSource?: UserExternalIdSource;

  hrisId?: string;
  hrisIdSource?: UserHrisIdSource;
}

export interface BulkSyncUsersRequest {
  users: SyncUserRequest[];
}

export type UserComputed = Pick<UserContract, 'email' | 'externalId' | 'firstName' | 'id' | 'lastName' | 'pictureURL'>;

export type KeysOfUserWithStringValues = KeysOfStringValues<UserComputed>;

export type Employee = UserContract & {
  assigned: boolean;
  userName: string;
  index: number;
  effectiveAsOf?: string;
  effectiveUntil?: string;
};

export type UsersMap = Record<string, UserContract>;

export type MinUserType = Pick<UserContract, 'firstName' | 'lastName'>;

export type UserScope = {
  isSuperAdmin: boolean;
  impersonatorEmail?: string;
  amaliaImpersonatorEmail?: string;
};
