import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider, Unstable_Grid2 as Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { upperFirst } from 'lodash';
import { Fragment, memo, type ReactNode, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Link } from '@amal-ia/ext/react-router-dom';
import { Tooltip, Text, TextType } from '@amal-ia/lib-ui';

import {
  type FilterOverwriteRemoveCreationRequestDetails,
  type OverwriteCreationRequestDetails,
} from '../overwrite.types';
import { OVERWRITE_CONTEXT } from '../OverwriteModal';

import { getObjectClassName, getObjectLabel } from './OverwriteRecapitulationInfo.utils';

type OverwriteRecapInfosProps = {
  readonly overwriteContext: string;
  readonly currentObjectDetails?: OverwriteCreationRequestDetails;
  readonly variableType?: VariableObjectsEnum;
  readonly currentFilterOverwriteRemoveObjectDetails?: FilterOverwriteRemoveCreationRequestDetails;
};

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  infoSpacing: {
    margin: '0 20px',
    padding: '5px 10px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
  rule: {
    margin: '0 20px',
    padding: '5px 10px',
    borderRadius: '2px',
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
  record: {
    margin: '0 20px',
    padding: '5px 10px',
    borderRadius: '2px',
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.record,
    cursor: 'pointer',
  },
  object: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object,
  },
  quota: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.quota,
  },
  statement: {
    backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.statement,
  },
  boxStyle: {
    margin: '2px 0',
    padding: '0px 5px',
  },
  container: {
    marginBottom: theme.spacing(1),
  },
  linkStyle: {
    color: 'inherit',
    textDecoration: 'none',
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
  },
}));

export const OverwriteRecapInfos = memo(function OverwriteRecapInfos({
  overwriteContext,
  currentObjectDetails,
  variableType,
  currentFilterOverwriteRemoveObjectDetails,
}: OverwriteRecapInfosProps) {
  const classes = useStyles();

  const computeTooltip = useCallback(
    (objectCategory: string) => getObjectLabel(objectCategory, variableType),
    [variableType],
  );

  const computeClassName = useCallback(
    (objectCategory: string) => getObjectClassName(objectCategory, variableType),
    [variableType],
  );

  const onRowsTableContext = useMemo(
    () => (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Rule" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.rule}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />
        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Opportunity" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.opportunityObject}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                defaultMessage="Record ID"
                description="Row ID"
              />
            }
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.rowNameOrId}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={clsx(classes.infoSpacing, classes.object)}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Field" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.fieldName}</Text>
          </Tooltip>
        </Grid>
      </Grid>
    ),
    [classes, currentObjectDetails],
  );

  const onFilterRowRemoveContext = useMemo(
    () => (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Rule" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentFilterOverwriteRemoveObjectDetails?.rule}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={clsx(classes.infoSpacing, classes.object)}>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                defaultMessage="Record ID"
                description="Row ID"
              />
            }
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentFilterOverwriteRemoveObjectDetails?.rowNameOrId}</Text>
          </Tooltip>
        </Grid>
      </Grid>
    ),
    [classes, currentFilterOverwriteRemoveObjectDetails],
  );

  const onKPIContext = useMemo(
    () => (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Rule" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.rule}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={clsx(classes.infoSpacing, classes.object)}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Field" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.field}</Text>
          </Tooltip>
        </Grid>
      </Grid>
    ),
    [classes, currentObjectDetails],
  );

  const onCommentDrawerPresentationContext = useMemo(
    () => (
      <Grid
        container
        alignItems="center"
        className={classes.container}
        direction="row"
        justifyContent="center"
      >
        {Object.entries(
          (currentObjectDetails ?? {}) as Record<
            string,
            {
              label: string;
              link?: string;
            }
          > &
            typeof currentObjectDetails,
        ).map(([objectCategory, objectValue], index, rows) => (
          <Fragment key={index}>
            <Grid className={clsx(classes[computeClassName(objectCategory) as keyof typeof classes], classes.boxStyle)}>
              <Tooltip
                placement="top"
                title={upperFirst(computeTooltip(objectCategory))}
              >
                <Text type={TextType.INFO_MESSAGE}>
                  {typeof objectValue === 'object' && objectValue?.link ? (
                    <Link
                      openInNewTab
                      className={classes.linkStyle}
                      to={objectValue.link}
                    >
                      {objectValue.label}
                    </Link>
                  ) : (
                    (objectValue as ReactNode)
                  )}
                </Text>
              </Tooltip>
            </Grid>
            {index + 1 !== rows.length && <ChevronRightIcon fontSize="small" />}
          </Fragment>
        ))}
      </Grid>
    ),
    [classes, currentObjectDetails, computeTooltip, computeClassName],
  );

  const onDataSourceContext = useMemo(
    () => (
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="center"
      >
        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Opportunity" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.opportunityObject}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={classes.infoSpacing}>
          <Tooltip
            placement="top"
            title={
              <FormattedMessage
                defaultMessage="Record ID"
                description="Row ID"
              />
            }
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.rowNameOrId}</Text>
          </Tooltip>
        </Grid>

        <ChevronRightIcon fontSize="small" />

        <Grid className={clsx(classes.infoSpacing, classes.object)}>
          <Tooltip
            placement="top"
            title={<FormattedMessage defaultMessage="Field" />}
          >
            <Text type={TextType.OVERWRITE_RECAP}>{currentObjectDetails?.fieldName}</Text>
          </Tooltip>
        </Grid>
      </Grid>
    ),
    [classes, currentObjectDetails],
  );

  return (
    <Fragment>
      {overwriteContext === OVERWRITE_CONTEXT.DATA && onDataSourceContext}
      {overwriteContext === OVERWRITE_CONTEXT.KPI && onKPIContext}
      {overwriteContext === OVERWRITE_CONTEXT.ROWTABLE && onRowsTableContext}
      {overwriteContext === OVERWRITE_CONTEXT.COMMENTDRAWER && onCommentDrawerPresentationContext}
      {overwriteContext === OVERWRITE_CONTEXT.FILTER_ROW_REMOVE && onFilterRowRemoveContext}

      <Box className={classes.divider}>
        <Divider variant="middle" />
      </Box>
    </Fragment>
  );
});
