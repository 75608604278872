import styled from '@emotion/styled';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type MergeAll } from '@amal-ia/ext/typescript';
import { FatTab, type FatTabButtonProps } from '@amal-ia/frontend/design-system/components';
import { type CurrencyValue } from '@amal-ia/kernel/monetary/types';
import { PaymentCategory, formatCurrencyAmount, roundNumber } from '@amal-ia/lib-types';
import { ForecastedKpiTooltip } from '@amal-ia/lib-ui-business';

const ForecastAmount = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.ds.hues.blue[50]};
`;

export type StatementHeaderPayoutForecastedProps = MergeAll<
  [
    Omit<FatTabButtonProps, 'data' | 'label' | 'subtitle' | 'tooltip'>,
    {
      forecastedTotal: CurrencyValue;
      statementTotal: CurrencyValue;
    },
  ]
>;

export default memo(function StatementHeaderPayoutForecasted({
  forecastedTotal,
  statementTotal,
  ...props
}: StatementHeaderPayoutForecastedProps) {
  const formattedAchievedValue = formatCurrencyAmount({
    amount: roundNumber(statementTotal.value) || 0,
    currencySymbol: statementTotal.symbol,
  });

  const formattedForecastedValue = formatCurrencyAmount({
    amount: roundNumber(forecastedTotal.value) || 0,
    currencySymbol: forecastedTotal.symbol,
  });

  return (
    <FatTab
      {...props}
      label={<FormattedMessage defaultMessage="Total achieved forecasted" />}
      data={
        formattedForecastedValue !== formattedAchievedValue ? (
          <ForecastedKpiTooltip
            isValueForecasted
            formattedAchievedValue={formattedAchievedValue}
            formattedForecastedValue={formattedForecastedValue}
            position="top"
          >
            <ForecastAmount data-cy={`payout-${PaymentCategory.achievement}`}>
              {formattedForecastedValue}
            </ForecastAmount>
          </ForecastedKpiTooltip>
        ) : (
          <ForecastAmount data-cy={`payout-${PaymentCategory.achievement}`}>{formattedForecastedValue}</ForecastAmount>
        )
      }
    />
  );
});
