import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { useStatementDetailContext } from '@amal-ia/lib-ui';

import StatementHeaderPayoutForecasted from '../../../../common/StatementHeaderPayoutForecasted';

export const StatementDetailSummaryPayoutForecasted = memo(function StatementSummaryPayoutForecasted() {
  const statement = useStatementDetailContext();

  const forecastedTotal = useMemo(
    () => ({
      value: statement.total,
      symbol: statement.currency,
    }),
    [statement],
  );

  const statementTotal = useMemo(
    () => ({
      value: statement.originalStatement?.total,
      symbol: statement.originalStatement?.currency,
    }),
    [statement],
  );

  return (
    <StatementHeaderPayoutForecasted
      withBorderTop
      forecastedTotal={forecastedTotal}
      statementTotal={statementTotal}
      css={(theme) => css`
        border-radius: 0 0 ${theme.ds.borderRadiuses.squared} ${theme.ds.borderRadiuses.squared};
      `}
    />
  );
});
