import { css } from '@emotion/react';
import { IconPencilPlus } from '@tabler/icons-react';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { Tooltip } from '@amal-ia/frontend/design-system/components';
import { OverwriteTypesEnum, type DatasetRow, type Statement } from '@amal-ia/lib-types';
import { type Dataset } from '@amal-ia/payout-calculation/shared/types';
import { type StatementThread } from '@amal-ia/payout-collaboration/comments/shared/types';

import { getThreadsOnRow } from '../../RowsTable/RowsTable.utils';

export type DatasetRecordsDataGridRowHighlightMarkerProps = {
  readonly datasetRow: DatasetRow;
  readonly dataset: Dataset;
  readonly statementThreads: Record<string, StatementThread>;
  readonly rule?: PlanRule;
  readonly statement?: Statement;
};

export const DatasetRecordsDataGridRowHighlightMarker = memo(function DatasetRecordsDataGridRowHighlightMarker({
  datasetRow,
  dataset,
  statementThreads,
  rule,
  statement,
}: DatasetRecordsDataGridRowHighlightMarkerProps) {
  const unreviewedThreadsCount = getThreadsOnRow(statementThreads, datasetRow, rule?.id, statement, dataset).filter(
    ({ thread }) => !thread.isReviewed,
  ).length;

  // Ignore dataset row add overwrite because we show a different marker for it.
  const overwritesCount =
    datasetRow.overwrites?.filter((overwrite) => overwrite.overwriteType !== OverwriteTypesEnum.FILTER_ROW_ADD)
      .length || 0;

  return (
    <Fragment>
      {!!datasetRow.isAdditionalRow && (
        <Tooltip content={<FormattedMessage defaultMessage="This record was added via a simulation." />}>
          <div
            css={(theme) => css`
              display: flex;
              width: 20px;
              height: 20px;
              align-items: center;
              justify-content: center;
              border-radius: ${theme.ds.borderRadiuses.squared};
              background-color: ${theme.ds.colors.secondary[50]};
              color: ${theme.ds.colors.secondary[500]};

              &,
              > * {
                flex: none;
              }
            `}
          >
            <IconPencilPlus size={14.4} />
          </div>
        </Tooltip>
      )}

      {!!(unreviewedThreadsCount || overwritesCount) && (
        <Tooltip
          content={
            unreviewedThreadsCount && overwritesCount ? (
              <FormattedMessage
                defaultMessage="There {overwritesCount, plural, one {is an overwrite} other {are overwrites}} and {unreviewedThreadsCount, plural, one {an open thread} other {open threads}} on this record."
                values={{ overwritesCount, unreviewedThreadsCount }}
              />
            ) : unreviewedThreadsCount ? (
              <FormattedMessage
                defaultMessage="There {unreviewedThreadsCount, plural, one {is an open thread} other {are open threads}} on this record."
                values={{ unreviewedThreadsCount }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="There {overwritesCount, plural, one {is an overwrite} other {are overwrites}} on this record."
                values={{ overwritesCount }}
              />
            )
          }
        >
          <div
            css={(theme) => css`
              flex: none;
              width: 8px;
              height: 8px;
              border-radius: ${theme.ds.borderRadiuses.squared};
              background-color: ${theme.ds.hues.red[500]};
            `}
          />
        </Tooltip>
      )}
    </Fragment>
  );
});
