import { defineMessages } from 'react-intl';

import {
  FormulaBuilderStringOperatorNoArgs,
  FormulaBuilderStringOperatorOneArg,
} from '@amal-ia/amalia-lang/formula/shared/types';

export const functionStringPopoverMessages = defineMessages({
  BACK_TO_OPERATOR: {
    defaultMessage: 'Go back to operator',
  },
  INVERT_CONDITIONS: {
    defaultMessage: 'Invert conditions',
  },

  INVERT_CONDITIONS_NEGATIVE_TOOLTIP: {
    defaultMessage: 'Switch to negative conditions',
  },

  INVERT_CONDITIONS_POSITIVE_TOOLTIP: {
    defaultMessage: 'Switch to positive conditions',
  },

  CASE_SENSITIVE: {
    defaultMessage: 'Case sensitive',
  },

  CASE_SENSITIVE_TOOLTIP: {
    defaultMessage: `Compare strings taking into account capital and non capital letters.{br}For example, 'Amalia' is not equal to 'amalia'.`,
  },
} as const);

export const operatorMessages = defineMessages<FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg>(
  {
    [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: {
      defaultMessage: 'is blank',
    },

    [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
      defaultMessage: 'contains',
    },

    [FormulaBuilderStringOperatorOneArg.MATCHES]: {
      defaultMessage: 'exactly matches',
    },

    [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
      defaultMessage: 'starts with',
    },

    [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
      defaultMessage: 'ends with',
    },
  } as const,
);

export const fieldOperatorMessages = defineMessages<FormulaBuilderStringOperatorOneArg>({
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: '“{propertyName}” contains',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” exactly matches',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: '“{propertyName}” starts with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: '“{propertyName}” ends with',
  },
} as const);

export const operatorNegativeMessages = defineMessages<
  FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg
>({
  [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is  not blank',
  },

  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: 'does not contain',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: 'does not exactly match',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: 'does not start with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: 'does not end with',
  },
} as const);

export const fieldOperatorNegativeMessages = defineMessages<FormulaBuilderStringOperatorOneArg>({
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: {
    defaultMessage: '“{propertyName}” does not contain',
  },

  [FormulaBuilderStringOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” does not exactly match',
  },

  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: {
    defaultMessage: '“{propertyName}” does not start with',
  },

  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: {
    defaultMessage: '“{propertyName}” does not end with',
  },
} as const);
