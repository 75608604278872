export enum CSV_DATA_TYPES {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  CURRENCY = 'currency',
  NULL = 'null',
}

export enum CSV_DATE_FORMAT {
  'YYYY-M-D' = 'YYYY-MM-DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'YYYY/M/D' = 'YYYY/MM/DD',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'YYYYMMDD' = 'YYYYMMDD',
  'YYYYDDMM' = 'YYYYDDMM',
  'M/D/YYYY' = 'MM/DD/YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'D/M/YYYY' = 'DD/MM/YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'D-M-YYYY' = 'DD-MM-YYYY',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'M-D-YYYY' = 'MM-DD-YYYY',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
}

export enum CSV_DATETIME_FORMAT {
  'YYYY-M-D h:m:s' = 'YYYY-MM-DD hh:mm:ss',
  'YYYY-MM-DD h:m:s' = 'YYYY-MM-DD hh:mm:ss',
  'YYYY/M/D h:m:s' = 'YYYY/MM/DD hh:mm:ss',
  'YYYY/MM/DD h:m:s' = 'YYYY/MM/DD hh:mm:ss',
  'YYYYMMDD h:m:s' = 'YYYYMMDD hh:mm:ss',
  'YYYYDDMM h:m:s' = 'YYYYDDMM hh:mm:ss',
  'M/D/YYYY h:m:s' = 'MM/DD/YYYY hh:mm:ss',
  'MM/DD/YYYY h:m:s' = 'MM/DD/YYYY hh:mm:ss',
  'D/M/YYYY h:m:s' = 'DD/MM/YYYY hh:mm:ss',
  'DD/MM/YYYY h:m:s' = 'DD/MM/YYYY hh:mm:ss',
  'D-M-YYYY h:m:s' = 'DD-MM-YYYY hh:mm:ss',
  'DD-MM-YYYY h:m:s' = 'DD-MM-YYYY hh:mm:ss',
  'M-D-YYYY h:m:s' = 'MM-DD-YYYY hh:mm:ss',
  'MM-DD-YYYY h:m:s' = 'MM-DD-YYYY hh:mm:ss',
}
