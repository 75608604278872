import { type ActionCreator } from 'redux';

import { CustomObjectsApiClient } from '@amal-ia/data-capture/records/shared/api-client';
import { type OverwriteResponse, OverwriteTypesEnum, type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import { getOverwrites } from '../../services/overwrites/overwrites.repository';
import { clearPaymentOverwrite } from '../../services/payments/payments.repository';
import { clearStatementOverwrite } from '../../services/statements/statements.repository';
import { type OverwriteFilters } from '../../types/overwrites';

import { OVERWRITES_ACTIONS } from './constants';
import { selectPageOptions } from './selectors';
import { type PaginationOptions } from './types';

const overwritesStart: ActionCreator<ReduxAction> = () => ({
  type: OVERWRITES_ACTIONS.START,
});

const overwritesError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: OVERWRITES_ACTIONS.ERROR,
  error,
});

const setList: ActionCreator<ReduxAction> = (list: OverwriteResponse[], pageOptions: PaginationOptions) => ({
  type: OVERWRITES_ACTIONS.SET_OVERWRITES,
  payload: { list, pageOptions },
});
const clearOverwriteAction: ActionCreator<ReduxAction> = (id: string) => ({
  type: OVERWRITES_ACTIONS.CLEAR_OVERWRITE,
  payload: { id },
});

export const fetchOverwrites =
  (
    searchText?: string,
    page = 0,
    limit = 10,
    sortColumn?: string,
    sortOrder = 'asc',
    selectedFilters?: OverwriteFilters,
  ): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(overwritesStart());
    try {
      const list = await getOverwrites(searchText, page, limit, sortColumn, sortOrder, selectedFilters);
      return dispatch(
        setList(list, {
          searchText,
          page,
          limit,
          sortColumn,
          sortOrder,
          selectedFilters,
        }),
      );
    } catch (error) {
      return dispatch(overwritesError(error));
    }
  };

export const clearOverwrite =
  (overwrite: OverwriteResponse): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(overwritesStart());
    const { selectedFilters, searchText, page, limit, sortColumn, sortOrder } = selectPageOptions(getState());

    try {
      switch (overwrite.overwriteType) {
        case OverwriteTypesEnum.PAYMENT:
          await clearPaymentOverwrite(overwrite.paymentId, {
            overwriteId: overwrite.id,
          });
          break;
        case OverwriteTypesEnum.FIELD:
        case OverwriteTypesEnum.KPI:
        case OverwriteTypesEnum.FILTER_ROW_REMOVE:
          await clearStatementOverwrite(overwrite.statementId, overwrite.id);
          break;
        case OverwriteTypesEnum.PROPERTY:
          await CustomObjectsApiClient.clearCustomObject({
            definitionMachineName: overwrite.appliesToDefinitionMachineName,
            objectExternalId: overwrite.appliesToExternalId,
            overwriteId: overwrite.id,
          });
          break;
        default:
          break;
      }

      await dispatch(fetchOverwrites(searchText, page, limit, sortColumn, sortOrder, selectedFilters));
      return dispatch(clearOverwriteAction(overwrite.id));
    } catch (error) {
      return dispatch(overwritesError(error));
    }
  };
