import { css, useTheme } from '@emotion/react';
import { type Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { trim } from 'lodash';
import { Fragment, useCallback } from 'react';

import { TokenType } from '@amal-ia/lib-types';

import { useFormulaEditorContext } from './FormulaEditor.context';
import { FunctionTokenPopover } from './FunctionTokenPopover';

/**
 * The exact color of tokens is specific to the context. In token icons, we use the 100 strength.
 */
const useTokenBackgroundColor = (tokenType: TokenType): string | undefined => {
  const theme = useTheme();

  return {
    [TokenType.VARIABLE]: theme.ds.hues.cyan[50],
    [TokenType.FILTER]: theme.ds.hues.green[50],
    [TokenType.LINK]: theme.ds.hues.red[50],
    [TokenType.QUOTA]: theme.ds.hues.purple[50],
    [TokenType.FUNCTION]: undefined,
    [TokenType.KEYWORD]: theme.ds.hues.magenta[50],
    [TokenType.FIELD]: theme.ds.hues.orange[50],
    [TokenType.PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.VIRTUAL_PROPERTY]: theme.ds.hues.orange[50],
    [TokenType.OBJECT]: theme.ds.hues.orange[50],
    [TokenType.RULE]: undefined,
    [TokenType.PLAN]: undefined,
  }[tokenType];
};

export type FormulaTokenNodeProps = {
  readonly node: ProseMirrorNode & {
    attrs: {
      formula: string;
    };
  };
  readonly getPos: () => number;
};

export const FormulaTokenNode = function FormulaTokenNode({
  node: {
    attrs: { formula },
  },
  getPos,
}: FormulaTokenNodeProps) {
  const { planTokens, onTokenClick, activeNode } = useFormulaEditorContext();
  const token = planTokens[formula];
  const backgroundColor = useTokenBackgroundColor(token.type);

  const onClickHandler = useCallback(() => onTokenClick(token), [onTokenClick, token]);

  const isOpen = activeNode?.node.attrs.formula === token.formula && activeNode.position === getPos();

  return (
    <NodeViewWrapper
      as="span"
      data-text-content={formula}
    >
      <NodeViewContent
        as="span"
        css={[
          (theme) => css`
            ${theme.ds.typographies.bodyBaseMedium};
            display: inline-flex;
            border-radius: ${theme.ds.borderRadiuses.squared};
            padding: 0 6px;
          `,
          (theme) => css`
            color: ${theme.ds.colors.gray[900]};
          `,
          token.type === TokenType.FUNCTION &&
            css`
              padding-left: 0;
              padding-right: 3px;
            `,
          token.id &&
            css`
              cursor: pointer;
            `,
          backgroundColor &&
            css`
              background-color: ${backgroundColor};
            `,
        ]}
        onClick={token.id ? onClickHandler : undefined}
      >
        {token.tooltipContent ? (
          <FunctionTokenPopover
            content={token.tooltipContent}
            isOpen={isOpen}
            onClick={onClickHandler}
          >
            <div>{trim(token.name)}</div>
          </FunctionTokenPopover>
        ) : (
          <Fragment>{trim(token.name)}</Fragment>
        )}
      </NodeViewContent>
    </NodeViewWrapper>
  );
};
