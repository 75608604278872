import { IconSearch } from '@tabler/icons-react';
import { type ForwardedRef, forwardRef, memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDebouncedOnChange } from '@amal-ia/ext/react/hooks';

import { Input, type InputProps } from '../../../data-input/input/Input';

export type SearchProps = Omit<InputProps, 'action' | 'error' | 'label' | 'leftIcon' | 'rightIcon' | 'size' | 'type'>;

const SearchForwardRef = forwardRef(function Search(
  { value: propsValue, onChange: propsOnChange, ...props }: SearchProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { formatMessage } = useIntl();

  const [internalValue, setInternalValue] = useState<string>(propsValue || '');

  useEffect(() => {
    setInternalValue(propsValue || '');
  }, [propsValue]);

  useDebouncedOnChange(internalValue, propsOnChange);

  return (
    <Input
      {...props}
      ref={ref}
      data-testid="datagrid-search-input"
      leftIcon={<IconSearch />}
      placeholder={props.placeholder || formatMessage({ defaultMessage: 'Search…' })}
      size={Input.Size.SMALL}
      value={internalValue}
      onChange={setInternalValue}
    />
  );
});

export const Search = memo(SearchForwardRef);
