import { uniqBy } from 'lodash';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Select } from '@amal-ia/frontend/design-system/components';
import { type KeysOfUserWithStringValues, type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { LabelVariant } from '../../types';
import { UserPrettyFormat } from '../pretty-format/UserPrettyFormat';

import { type UserOption, type UserSelectOption } from './types';
import * as styles from './UserSelector.styles';
import { UserSelectorSingleValueLabel } from './UserSelectorSingleValueLabel';

export type UserSelectorProps<TProperty extends keyof UserComputed> = {
  /** Users to display in the selector. */
  readonly users: UserOption<TProperty>[];
  /**
   * Selected user's "property" value.
   * For example, the selected user's `"externalId"`.
   */
  readonly value: UserOption<TProperty>[TProperty] | null;
  /** Callback when a user option is selected. */
  readonly onChange: (value: UserOption<TProperty>[TProperty] | null) => void;
  /**
   * Property to use as the value.
   * For example, `"externalId"`
   */
  readonly property: TProperty;

  /** Label to display when no user is selected. */
  readonly emptyLabel?: string;
};

const UserSelectorBase = function UserSelector<TProperty extends KeysOfUserWithStringValues>({
  users,
  value,
  onChange,
  property,
  emptyLabel,
}: UserSelectorProps<TProperty>) {
  const { formatMessage } = useIntl();

  const options = useMemo(
    (): UserSelectOption<TProperty>[] =>
      uniqBy(users, property)
        .map((user) => {
          const optionValue = user[property];

          return !!optionValue && !!user.firstName && !!user.lastName
            ? {
                user,
                label: (
                  <UserPrettyFormat
                    firstName={user.firstName}
                    lastName={user.lastName}
                    pictureURL={user.pictureURL}
                    subLabel={optionValue}
                    variant={optionValue === value ? LabelVariant.ACCENTUATED : LabelVariant.DEFAULT}
                  />
                ),
                value: optionValue,
                filterLabel: [user.firstName, user.lastName, user[property]].join(' '),
              }
            : null;
        })
        .filter(Boolean),
    [value, users, property],
  );

  return (
    <div css={styles.container}>
      <Select<UserSelectOption<TProperty>>
        isClearable
        options={options}
        placeholder={emptyLabel ?? formatMessage({ defaultMessage: 'No user selected' })}
        SingleValueLabelComponent={UserSelectorSingleValueLabel}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const UserSelector = memo(UserSelectorBase) as typeof UserSelectorBase;
