import { type Theme, css } from '@emotion/react';

export const container = (isSelected: boolean) => (theme: Theme) => css`
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${isSelected && `background: ${theme.ds.colors.gray[50]};`}
`;

export const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const actions = css`
  display: flex;
  gap: 4px;
`;

export const content = css`
  flex: 1;
  overflow-wrap: break-word;
  max-width: 240px;
`;

export const date = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[700]};
`;

export const mention = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[500]};
  background: ${theme.ds.colors.secondary[50]};
  padding: 4px;
  border-radius: ${theme.ds.borderRadiuses.squared};
`;

export const dateContainer = css`
  display: flex;
  flex-direction: column;
`;
