import { type SyntheticEvent } from 'react';

export const eventPreventDefault = (e?: SyntheticEvent) => e?.preventDefault();

export const eventStopPropagation = (e?: SyntheticEvent) => e?.stopPropagation();

export const eventStopPropagationAndPreventDefault = (e?: SyntheticEvent) => {
  e?.stopPropagation();
  e?.preventDefault();
};
