import { createContext, useContext } from 'react';

import { assert } from '@amal-ia/ext/typescript';

import { type SideMenuItemValue } from './SideMenu.types';

export type SideMenuContextValue<TValue extends SideMenuItemValue = SideMenuItemValue> = {
  value?: TValue | null;
  onChange?: (value: TValue | null) => void;
};

export const SideMenuContext = createContext<SideMenuContextValue | null>(null);

export const useSideMenuContext = <
  TValue extends SideMenuItemValue = SideMenuItemValue,
>(): SideMenuContextValue<TValue> => {
  const context = useContext(SideMenuContext);
  assert(context, 'useSideMenuContext must be used within a <SideMenuContext.Provider>');
  return context as unknown as SideMenuContextValue<TValue>;
};
