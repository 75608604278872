import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.MOD, AmaliaFunctionCategory.NUMBERS);

func.nbParamsRequired = 2;

func.description = 'Return the remainder of a division';

func.params = [
  { name: 'dividend', description: 'Dividend: numbers, fields, properties, variables' },
  { name: 'divisor', description: 'Divisor: numbers, fields, properties, variables' },
];

func.examples = [
  {
    desc: 'Returns 2.',
    formula: 'MOD(8, 3)' as AmaliaFormula,
    result: 2,
  },
  {
    desc: 'Returns 0.',
    formula: 'MOD(10, 2)' as AmaliaFormula,
    result: 0,
  },
  {
    desc: 'Returns the remainder of Amount divided by 2.',
    formula: 'MOD(opportunity.amount, 2)',
  },
];

func.exec = (divident: number, divisor: number): number => divident % divisor;

export default func;
