import { memo } from 'react';
import { generatePath, Navigate } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

export const HomeRedirectView = memo(function HomeRedirectView() {
  const { data: company } = useCurrentCompany();
  const ability = useAbilityContext();

  if (company.featureFlags?.FLOWS_ENV_ONLY) {
    return (
      <Navigate
        replace
        to={generatePath(routes.FLOWS)}
      />
    );
  }

  if (company.featureFlags?.REP_HOME && ability.can(ActionsEnum.view, SubjectsEnum.RepHome)) {
    return (
      <Navigate
        replace
        to={generatePath(routes.HOME)}
      />
    );
  }

  return (
    <Navigate
      replace
      to={generatePath(routes.STATEMENTS)}
    />
  );
});
