import { defineMessages } from 'react-intl';

import { ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';

export const formulaConditionTagFunctionStringMessages = defineMessages({
  FILTER_ON: {
    defaultMessage: 'Filter on “{propertyName}”',
  },

  OPERATOR_VALUE: {
    defaultMessage: '{operator}: {value}',
  },

  CLICK_TO_EDIT_CONDITION: {
    defaultMessage: 'Click to edit condition',
  },
} as const);

export const valueOrAttributeValuesMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: '"{value}"',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: '{value}',
  },
} as const);
