import { type ForwardedRef, forwardRef, memo } from 'react';

import { type PlanCategory, PlanRuleCategoryColor } from '@amal-ia/compensation-definition/plans/types';
import { type MergeAll } from '@amal-ia/ext/typescript';
import { Badge, BadgeHue, type BadgeProps } from '@amal-ia/frontend/design-system/components';

import { usePlanRuleCategoryColor } from '../../hooks/usePlanRuleCategoryColor';

const PLAN_RULE_CATEGORY_COLOR_TO_BADGE_HUE = {
  [PlanRuleCategoryColor.BLUE]: BadgeHue.BLUE,
  [PlanRuleCategoryColor.BROWN]: BadgeHue.BROWN,
  [PlanRuleCategoryColor.CYAN]: BadgeHue.CYAN,
  [PlanRuleCategoryColor.GREY]: BadgeHue.GREY,
  [PlanRuleCategoryColor.MAGENTA]: BadgeHue.MAGENTA,
  [PlanRuleCategoryColor.YELLOW]: BadgeHue.YELLOW,
} as const satisfies Record<PlanRuleCategoryColor, BadgeHue>;

export type PlanRuleCategoryBadgeProps = MergeAll<
  [
    Omit<BadgeProps, 'children' | 'variant'>,
    {
      category: PlanCategory;
    },
  ]
>;

const PlanRuleCategoryBadgeForwardRef = forwardRef(function PlanRuleCategoryBadge(
  { category, ...props }: PlanRuleCategoryBadgeProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const categoryColor = usePlanRuleCategoryColor(category);

  return (
    <Badge
      {...props}
      ref={ref}
      variant={PLAN_RULE_CATEGORY_COLOR_TO_BADGE_HUE[categoryColor]}
    >
      {category.name}
    </Badge>
  );
});

export const PlanRuleCategoryBadge = Object.assign(memo(PlanRuleCategoryBadgeForwardRef), {
  Size: Badge.Size,
});
