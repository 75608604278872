import { defineMessages } from 'react-intl';

import {
  FormulaBuilderUserOperatorNoArgs,
  FormulaBuilderUserOperatorOneArg,
} from '@amal-ia/amalia-lang/formula/shared/types';

export const functionUserPopoverMessages = defineMessages({
  BACK_TO_OPERATOR: {
    defaultMessage: 'Go back to operator',
  },
});

export const helpMessages = defineMessages({
  SELECTED_HELP: {
    defaultMessage: 'Owner of "{datasource}" is matching member selected on the statement',
  },
});

export const operatorNoArgsMessages = defineMessages<FormulaBuilderUserOperatorNoArgs>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: 'is selected',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'is blank',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'is not blank',
  },
});

export const operatorOneArgMessages = defineMessages<FormulaBuilderUserOperatorOneArg>({
  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: 'matches',
  },

  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: 'does not match',
  },
});

export const fieldOperatorMessages = defineMessages<
  FormulaBuilderUserOperatorNoArgs | FormulaBuilderUserOperatorOneArg
>({
  [FormulaBuilderUserOperatorNoArgs.IS_SELECTED]: {
    defaultMessage: '“{propertyName}” is selected',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_BLANK]: {
    defaultMessage: '“{propertyName}” is blank',
  },

  [FormulaBuilderUserOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: '“{propertyName}” is not blank',
  },

  [FormulaBuilderUserOperatorOneArg.MATCHES]: {
    defaultMessage: '“{propertyName}” exactly matches',
  },
  [FormulaBuilderUserOperatorOneArg.NOT_MATCHES]: {
    defaultMessage: '“{propertyName}” does not exactly match',
  },
});
