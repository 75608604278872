import { type Company } from '@amal-ia/tenants/companies/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export interface NewTeam {
  name: string;
  parentTeamId?: string;
}

export interface TeamNode {
  id?: string;
  name: string;
  archived?: boolean;
  childrenTeams: TeamNode[];
}

export interface TeamContract extends TeamNode {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  company?: Company;
  user?: UserContract;

  name: string;

  archived: boolean;

  externalTeamId: string | null;
  // FIXME: add comments to explain what are the differences...
  parentTeam?: TeamContract;
  parentTeamId: string | null;
  childrenTeams: TeamContract[];
  hierarchy?: { teamParents: TeamContract[]; teamChildren: TeamContract[] };
}

// Indexed by teamId.
export type TeamMap = Record<string, TeamContract>;

export interface Team {
  id?: string;

  name?: string;

  archived?: boolean;

  members?: UserContract[];

  managers?: UserContract[];

  employees?: UserContract[];

  childrenTeams?: Team[];
}

export enum TeamFormattedRole {
  TEAM_MANAGER = 'MANAGER',
  TEAM_EMPLOYEE = 'EMPLOYEE',
}

export enum TeamMemberType {
  EMPLOYEE = 'employee',
  MANAGER = 'manager',
}

export interface TeamScope {
  role: AssignmentType;
  scope: MatchScope;
  depth: number;
}

export enum AssignmentType {
  MINE = 'MINE',
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  MEMBER = 'MEMBER',
}

export enum MatchScope {
  ALL = 'ALL',
  SUB = 'SUB',
}
