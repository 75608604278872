import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type BadgeConfiguration } from '@amal-ia/compensation-definition/plans/types';
import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Text, TextType } from '@amal-ia/lib-ui';

import { BADGE_LOGOS } from './badges.enum';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  itemIcon: {
    display: 'block',
    flex: `0 0 ${theme.spacing(15)}`,
    minWidth: theme.spacing(15),
  },
}));

interface BadgeSummaryProps {
  readonly badgeConfiguration: BadgeConfiguration;
  readonly isAwarded?: boolean;
}

export const BadgeSummary = memo(function BadgeSummary({ badgeConfiguration, isAwarded = true }: BadgeSummaryProps) {
  const classes = useStyles();

  const Logo = isAwarded
    ? BADGE_LOGOS[badgeConfiguration.icon].iconColor
    : BADGE_LOGOS[badgeConfiguration.icon].iconGrey;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      padding={amaliaTheme.spacing(1)}
    >
      <Box className={classes.itemIcon}>
        <Logo />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        marginLeft={amaliaTheme.spacing(1.6)}
        maxWidth={amaliaTheme.spacing(200)}
      >
        <Text
          style={{ opacity: isAwarded ? 1 : 0.5 }}
          type={TextType.BADGE_TITLE}
        >
          {badgeConfiguration.name}
        </Text>
        <Box>{badgeConfiguration.description}</Box>
      </Box>
    </Box>
  );
});
