import { Box } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';
import { Fragment, memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, IconButton, Typography } from '@amal-ia/frontend/design-system/components';
import { type WorkflowCondition, WorkflowConditionType, type WorkflowStep, deleteAt } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { useWorkflowStepName } from '../../../statement/workflow/useWorkflowStepName';
import { WorkflowConditionConfiguration } from '../WorkflowConditionConfiguration/WorkflowConditionConfiguration';

import { messages } from './WorkflowStepConfiguration.messages';

interface WorkflowStepConfigurationProps {
  readonly step: WorkflowStep;
  readonly stepIndex: number;
  readonly onChangeStepConfig: (stepIndex: number, newStepConfig: WorkflowStep) => void;
  readonly onClickDeleteStep: (stepIndex: number) => void;
  readonly users: UserContract[];
  readonly isReadOnly?: boolean;
}

export const WorkflowStepConfiguration = memo(function WorkflowStepConfiguration({
  step,
  stepIndex,
  onChangeStepConfig,
  onClickDeleteStep,
  users,
  isReadOnly,
}: WorkflowStepConfigurationProps) {
  const { formatMessage } = useIntl();
  const onAddCondition = useCallback(() => {
    onChangeStepConfig(stepIndex, {
      ...step,
      conditions: step.conditions.concat({
        conditionType: WorkflowConditionType.OWNER,
      }),
    });
  }, [step, stepIndex, onChangeStepConfig]);

  const onDeleteCondition = useCallback(
    (conditionIndex: number) => {
      onChangeStepConfig(stepIndex, {
        ...step,
        conditions: deleteAt(step.conditions, conditionIndex),
      });
    },
    [step, stepIndex, onChangeStepConfig],
  );

  const onChangeCondition = useCallback(
    (conditionIndex: number, newCondition: WorkflowCondition) => {
      // Make a shallow copy of the current value.
      const newConditions = [...step.conditions];
      // Replace element in the shallow copy with the new condition.
      newConditions[conditionIndex] = newCondition;
      // Notify parent of the changes.
      onChangeStepConfig(stepIndex, { ...step, conditions: newConditions });
    },
    [stepIndex, step, onChangeStepConfig],
  );

  const onClickDeleteProxy = useCallback(() => onClickDeleteStep(stepIndex), [onClickDeleteStep, stepIndex]);

  const { formatStepName } = useWorkflowStepName();

  return (
    <Fragment>
      <Box
        alignItems="center"
        display="flex"
        gap={1}
      >
        <Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>
          <FormattedMessage
            {...messages.STEP_NUMBER}
            values={{ stepNumber: stepIndex + 1 }}
          />
        </Typography>
        <IconButton
          disabled={isReadOnly}
          icon={<IconTrash />}
          label={formatMessage(messages.DELETE_STEP, { stepNumber: stepIndex + 1 })}
          size={IconButton.Size.SMALL}
          variant={IconButton.Variant.DANGER}
          onClick={onClickDeleteProxy}
        />
      </Box>

      <Typography variant={Typography.Variant.BODY_BASE_REGULAR}>{formatStepName(step.stepName)}</Typography>

      <Box mt={1}>
        {step.conditions.map((c, conditionIndex) => (
          <WorkflowConditionConfiguration
            key={conditionIndex} // eslint-disable-line react/no-array-index-key
            condition={c}
            conditionIndex={conditionIndex}
            isReadOnly={isReadOnly}
            stepIndex={stepIndex}
            users={users}
            onChangeCondition={onChangeCondition}
            onDeleteCondition={onDeleteCondition}
          />
        ))}
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        mt={1}
      >
        <Button
          aria-label={formatMessage(messages.ADD_CONDITION_IN_STEP, { stepNumber: stepIndex + 1 })}
          disabled={isReadOnly}
          variant={Button.Variant.SECONDARY_LIGHT}
          onClick={onAddCondition}
        >
          <FormattedMessage
            {...messages.ADD_CONDITION_IN_STEP}
            values={{ stepNumber: stepIndex + 1 }}
          />
        </Button>
      </Box>
    </Fragment>
  );
});
