import { css } from '@emotion/react';
import { IconViewfinder } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Link } from '@amal-ia/ext/react-router-dom';
import { AlertBanner, Button, ButtonLink, type AlertBannerVariant } from '@amal-ia/frontend/design-system/components';
import { StatementErrorLevel, StatementErrorType } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';

const ERROR_LEVEL_BANNER_VARIANT_MAPPING: Record<StatementErrorLevel, AlertBannerVariant> = {
  [StatementErrorLevel.ERROR]: AlertBanner.Variant.ERROR,
  [StatementErrorLevel.WARNING]: AlertBanner.Variant.WARNING,
};

export default memo(function StatementDetailHeaderPayoutQuotaErrorProps() {
  const { error, user } = useStatementDetailContext();

  // Note: I'm guessing this condition from the backend code. Returned errors should be clearer.
  const isQuotaError = error.level === StatementErrorLevel.WARNING && error.type === StatementErrorType.CONFIGURATION;

  return (
    <AlertBanner
      inline
      withBorder
      alignCenter={isQuotaError}
      variant={ERROR_LEVEL_BANNER_VARIANT_MAPPING[error.level]}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        <div>{error.message}</div>

        {!!isQuotaError && (
          <ButtonLink
            icon={<IconViewfinder />}
            size={Button.Size.SMALL}
            variant={Button.Variant.LIGHT}
            to={
              <Link
                openInNewTab
                to={generatePath(routes.QUOTAS_USER, { userId: user.id })}
              />
            }
          >
            <FormattedMessage defaultMessage="Go to quotas" />
          </ButtonLink>
        )}
      </div>
    </AlertBanner>
  );
});
