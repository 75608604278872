import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const QuotaListContainer = lazy(() => import('./QuotaListContainer'));
const QuotaValueContainer = lazy(() => import('./variableValue/QuotaValueContainer'));
const QuotaByUserContainer = lazy(() => import('./viewQuotasByUsers/QuotasByUserDetailsContainer'));

export const QuotasRoutes = memo(function QuotasRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="quotas">
      <RootRoutes>
        <Route
          path={routes.QUOTAS_VALUE}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Quota' })} />
              <QuotaValueContainer />
            </AbilityRouteProtector>
          }
        />
        <Route
          path={routes.QUOTAS_USER}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Quota by user' })} />
              <QuotaByUserContainer />
            </AbilityRouteProtector>
          }
        />
        <Route
          path={routes.QUOTAS}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Quota)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Quota list' })} />
              <QuotaListContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
