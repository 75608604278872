import { css } from '@emotion/react';
import {
  IconEqual,
  IconEqualNot,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconMathGreater,
  IconMathLower,
  IconPlayerRecord,
} from '@tabler/icons-react';
import { memo } from 'react';

import {
  FormulaBuilderNumberOperatorNoArgs,
  FormulaBuilderNumberOperatorOneArg,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { IconNot } from '@amal-ia/frontend/design-system/components';

type NumberOperatorNoArgsToIconProps = {
  readonly operator: FormulaBuilderNumberOperatorNoArgs | FormulaBuilderNumberOperatorOneArg;
  readonly size: number;
};

export const NumberOperatorNoArgsToIcon = memo(function NumberOperatorNoArgsToIcon({
  operator,
  size,
}: NumberOperatorNoArgsToIconProps) {
  switch (operator) {
    case FormulaBuilderNumberOperatorNoArgs.IS_BLANK:
      return (
        <IconPlayerRecord
          data-testid="icon-player-record"
          size={size}
        />
      );
    case FormulaBuilderNumberOperatorNoArgs.IS_NOT_BLANK:
      return (
        <IconNot
          icon={IconPlayerRecord}
          size={size}
          // Need to add display flex here to prevent the icon from shifting to the right
          css={css`
            display: flex;
          `}
        />
      );
    case FormulaBuilderNumberOperatorOneArg.EQUALS:
      return (
        <IconEqual
          data-testid="icon-equal"
          size={size}
        />
      );
    case FormulaBuilderNumberOperatorOneArg.NOT_EQUALS:
      return <IconEqualNot size={size} />;
    case FormulaBuilderNumberOperatorOneArg.GREATER_THAN:
      return <IconMathGreater size={size} />;
    case FormulaBuilderNumberOperatorOneArg.GREATER_THAN_OR_EQUAL:
      return <IconMathEqualGreater size={size} />;
    case FormulaBuilderNumberOperatorOneArg.LESS_THAN:
      return <IconMathLower size={size} />;
    case FormulaBuilderNumberOperatorOneArg.LESS_THAN_OR_EQUAL:
      return (
        <IconMathEqualLower
          data-testid="icon-math-equal-lower"
          size={size}
        />
      );
    default:
      return null;
  }
});
