import { css } from '@emotion/react';
import { IconSearch } from '@tabler/icons-react';
import { type MouseEvent, memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { type PlanCategory, type PlanRule, RuleType } from '@amal-ia/compensation-definition/plans/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { AlertBanner, IconButton } from '@amal-ia/frontend/design-system/components';
import {
  type ComputedRule,
  type ComputedVariable,
  formatCurrencyAmount,
  formatTotal,
  roundNumber,
} from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { RuleAccordion } from '@amal-ia/lib-ui-business';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';

import RuleResult from '../RuleResult';

interface RuleDefaultProps {
  readonly rule: PlanRule;
  readonly computedRule: ComputedRule & { originalRuleValue: number };
  readonly setCurrentTracingData: any;
  readonly globalSearchValue: string;
  readonly openRuleTracing: (computedRule: ComputedRule, event?: MouseEvent<HTMLButtonElement>) => void;
  readonly category?: PlanCategory;
  readonly activeRuleId?: string;
}

const RuleDefault = memo(function RuleDefault({
  rule,
  computedRule,
  openRuleTracing,
  globalSearchValue,
  setCurrentTracingData,
  category,
  activeRuleId,
}: RuleDefaultProps) {
  const { formatMessage } = useIntl();
  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(activeRuleId === rule.id, 'ruleExpanded');

  const identifier = `${category?.name || 'none'}-${rule.ruleMachineName}`;
  const statement = useStatementDetailContext();

  const { overwriteMainKpiFormattedValue, overwriteMainKpiVariableName } = useMemo(() => {
    let overwriteMainKpiFormattedValueInitial: string;
    let overwriteMainKpiVariableNameInitial: string;
    // Manage the overwrite of the main Kpi
    if (rule.type === RuleType.NON_PAYOUT && rule.configuration?.mainKpiVariableOverwrite) {
      const variableDefinition = Object.values(statement.results.definitions?.variables || {}).find(
        (v) => v.id === rule.configuration?.mainKpiVariableOverwrite,
      );

      if (variableDefinition) {
        const computedVariable = (statement.results.computedObjects || []).find(
          (co) => (co as ComputedVariable).variableMachineName === variableDefinition.machineName,
        );

        if (computedVariable) {
          overwriteMainKpiVariableNameInitial = statement.isForecastedView
            ? `FORECASTED ${variableDefinition.name}`
            : variableDefinition.name;
          overwriteMainKpiFormattedValueInitial = formatTotal(
            (computedVariable.overwrite?.overwriteValue || computedVariable.value) as ComputeEnginePrimitiveTypes,
            variableDefinition.format,
            computedVariable.currency || statement.currency || CurrencySymbolsEnum.EUR,
            statement.rate,
          )?.toString();
        }
      }
    }
    return {
      overwriteMainKpiFormattedValue: overwriteMainKpiFormattedValueInitial,
      overwriteMainKpiVariableName: overwriteMainKpiVariableNameInitial,
    };
  }, [statement, rule.configuration?.mainKpiVariableOverwrite, rule.type]);

  const { formattedAchievedValue, formattedForecastedValue } = {
    formattedForecastedValue: formatCurrencyAmount({
      amount: roundNumber(computedRule?.value) || 0,
      currencyRate: statement.rate,
      currencySymbol: statement.currency,
    }),
    formattedAchievedValue: formatCurrencyAmount({
      amount: roundNumber(computedRule?.originalRuleValue) || 0,
      currencyRate: statement.originalStatement?.rate,
      currencySymbol: statement.originalStatement?.currency,
    }),
  };

  return (
    <RuleAccordion
      header={
        <RuleAccordion.Header
          key={rule.id}
          hideActions
          category={category}
          helpLabel={rule.description}
          isExpanded={isRuleExpanded}
          label={rule.name}
          machineName={identifier}
          actions={
            !statement.isForecastedView ? (
              <IconButton
                icon={<IconSearch />}
                label={formatMessage({ defaultMessage: 'Open tracing' })}
                size={IconButton.Size.SMALL}
                onClick={(e) => openRuleTracing(computedRule, e)}
              />
            ) : undefined
          }
          mainKpi={{
            type: rule.type,
            value: {
              amount: computedRule?.value || 0,
              currencyRate: statement.rate,
              currencySymbol: statement.currency,
            },
            hideTotal: rule.configuration?.hideTotal,
            overrideFormattedValue: overwriteMainKpiFormattedValue,
            label: overwriteMainKpiVariableName,
            isForecastedView: statement.isForecastedView,
            formattedForecastedValue,
            formattedOriginalValue: formattedAchievedValue,
            isValueForecasted: statement.isForecastedView && formattedAchievedValue !== formattedForecastedValue,
          }}
          onToggleExpanded={toggleRuleExpanded}
        />
      }
    >
      <RuleLayout>
        {computedRule ? (
          <RuleResult
            computedRule={computedRule}
            globalSearchValue={globalSearchValue}
            ruleDefinition={rule}
            setCurrentTracingData={setCurrentTracingData}
          />
        ) : (
          <div
            css={css`
              padding: 24px 0;
            `}
          >
            <AlertBanner inline>
              <FormattedMessage defaultMessage="No data available." />
            </AlertBanner>
          </div>
        )}
      </RuleLayout>
    </RuleAccordion>
  );
});

export default RuleDefault;
