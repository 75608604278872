import { css } from '@emotion/react';
import { memo } from 'react';

import { type UserExternalIdSource } from '@amal-ia/tenants/users/shared/types';

import { UserExternalIdSourceLabel } from './label';
import { UserExternalIdSourceLogo } from './logo';

export type UserExternalIdSourceItemProps = {
  readonly externalIdSource: UserExternalIdSource;
};

export const UserExternalIdSourceItem = memo(function UserExternalIdSourceItem({
  externalIdSource,
}: UserExternalIdSourceItemProps) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;

        gap: 6px;
      `}
    >
      <UserExternalIdSourceLogo externalIdSource={externalIdSource} />
      <UserExternalIdSourceLabel externalIdSource={externalIdSource} />
    </span>
  );
});
