import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { usePeriods } from '@amal-ia/compensation-definition-periods-components';
import { selectCurrentPeriod } from '@amal-ia/frontend/web-data-layers';
import * as PaymentRepository from '@amal-ia/frontend/web-data-layers';
import { type PaginatedResponse, type PaymentContract } from '@amal-ia/lib-types';
import { DataGrid } from '@amal-ia/lib-ui';
import { DashboardWidget, WidgetTitle } from '@amal-ia/lib-ui-business';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import usePaymentDataGrid, { type PaymentDataGridColumns } from '../usePaymentDatagrid';

import PaymentTableCell from './PaymentTableCell';

interface PaymentGridProps {
  readonly selectedPlanIds?: string[];
  readonly selectedTeamIds?: string[];
  readonly selectedUserIds: string[];
  readonly user: Partial<UserContract>;
}

export const PaymentsGrid = memo(function PaymentsGrid({
  selectedPlanIds,
  selectedTeamIds,
  selectedUserIds,
  user,
}: PaymentGridProps) {
  const { periodsMap } = usePeriods();

  const { formatMessage } = useIntl();

  const [payments, setPayments] = useState<PaginatedResponse<PaymentContract>>({
    totalItems: 0,
    items: [],
    pageCount: 0,
  });
  const period = useSelector(selectCurrentPeriod);

  const columns: PaymentDataGridColumns = useMemo(
    () => [
      { name: 'id', title: formatMessage({ defaultMessage: 'Payment Id' }), realSortColumn: 'payment_id' },
      {
        name: 'achievementPeriod',
        title: formatMessage({ defaultMessage: 'Achievement period' }),
        realSortColumn: 'payment_achievementperiod',
      },
      { name: 'salesrep', title: formatMessage({ defaultMessage: 'Sales rep' }), realSortColumn: 'user_firstname' },
      { name: 'plan', title: formatMessage({ defaultMessage: 'Plan' }), realSortColumn: 'payment_plan' },
      { name: 'rule', title: formatMessage({ defaultMessage: 'Rule' }), realSortColumn: 'payment_rule' },
      {
        name: 'dealExternalId',
        title: formatMessage({ defaultMessage: 'Record Id' }),
        realSortColumn: 'payment_dealexternalid',
      },
      { name: 'amount', title: formatMessage({ defaultMessage: 'Amount' }), realSortColumn: 'payment_value' },
      {
        name: 'paymentPeriodId',
        title: formatMessage({ defaultMessage: 'Payment period' }),
        realSortColumn: 'payment_paymentperiod',
      },
      { name: 'split', title: formatMessage({ defaultMessage: 'Split' }), realSortColumn: '' },
    ],
    [formatMessage],
  );

  const { currentPage, options, realSortColumn, rows, pageSize, searchValue, sorting } = usePaymentDataGrid(
    columns,
    payments,
    periodsMap,
    5,
    ['id', 'dealExternalId', 'split'],
  );

  const refreshData = useCallback(async () => {
    setPayments(
      await PaymentRepository.getPaymentPaginate(
        period ? [period.id] : undefined,
        selectedUserIds,
        selectedPlanIds,
        selectedTeamIds,
        undefined,
        undefined,
        currentPage + 1,
        pageSize,
        searchValue,
        realSortColumn,
        sorting[0]?.direction,
      ),
    );
  }, [
    period,
    selectedUserIds,
    selectedPlanIds,
    selectedTeamIds,
    currentPage,
    pageSize,
    searchValue,
    realSortColumn,
    sorting,
  ]);

  useAsyncEffect(async () => {
    await refreshData();
  }, [refreshData]);

  return (
    <DashboardWidget>
      <WidgetTitle
        title={<FormattedMessage defaultMessage="Payments" />}
        userRole={user.role}
      />
      <DataGrid
        columns={columns}
        id="paymentTable"
        options={options}
        rows={rows}
        tableProps={{ cellComponent: PaymentTableCell }}
      />
    </DashboardWidget>
  );
});
