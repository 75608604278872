import { validateValueWithFormat } from '@amal-ia/amalia-lang/formula/evaluate';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { OverwriteScopeEnum } from '@amal-ia/data-capture/overwrites/shared/types';
import { type CustomObjectListDetails, type PatchCustomObjectRequest } from '@amal-ia/lib-types';

const EXCEPTION_VALUE: string[] = ['', 'clean', 'CLEAN', 'delete', 'DELETE'];

const CustomTableUtils = {
  onCommitChanges: (
    objectDefinition: CustomObjectDefinition,
    customObjectListDetails: CustomObjectListDetails,
    { changed }: any,
    snackError: (error: string) => void,
  ): (PatchCustomObjectRequest & { objectId: string }) | null => {
    let result: (PatchCustomObjectRequest & { objectId: string }) | null = null;

    if (changed && objectDefinition?.properties) {
      Object.keys(changed).forEach((value: any) => {
        if (changed[value]) {
          Object.keys(changed[value]).forEach((fieldMachineName) => {
            const sourceValue: any = {};
            const overwriteValue: any = {};
            const rawNewValue = changed[value][fieldMachineName];

            // Keys that must not be considered as fields
            if (['isApplyToOverall'].includes(fieldMachineName)) {
              return;
            }

            // Get column Format
            const { format: columnFormat, isRequired: isFieldRequired } =
              objectDefinition.properties?.[fieldMachineName] || {};

            // Validate data against object definition
            try {
              if (!EXCEPTION_VALUE.includes(rawNewValue)) {
                validateValueWithFormat(rawNewValue, columnFormat, isFieldRequired);
              }
            } catch (error) {
              // If there's a validation error, print it
              snackError(`Validation error: ${(error as Error).message}`);
              return;
            }

            // If the overwrite is on a currency value
            if (customObjectListDetails.itemsWC[value]?.[fieldMachineName]) {
              let sourceValueToSend = customObjectListDetails.itemsWC[value][fieldMachineName];

              if (customObjectListDetails.itemsWO[value]?.[fieldMachineName]) {
                sourceValueToSend =
                  customObjectListDetails.itemsWO[value]?.[fieldMachineName].sourceValue[fieldMachineName];
              }

              // Take as source value the currency object (containing value and currency)
              sourceValue[fieldMachineName] = sourceValueToSend;
              // Put as overwrite the new value along with the currency of the original value
              overwriteValue[fieldMachineName] = {
                ...sourceValueToSend,
                value: rawNewValue,
              };
            } else {
              // Else, just copy the original value as source and into the overwrite value
              let sourceValueToSend = customObjectListDetails.items[value][fieldMachineName];

              if (customObjectListDetails.itemsWO[value]?.[fieldMachineName]) {
                sourceValueToSend =
                  customObjectListDetails.itemsWO[value]?.[fieldMachineName].sourceValue[fieldMachineName];
              }

              sourceValue[fieldMachineName] = sourceValueToSend;
              overwriteValue[fieldMachineName] = `${rawNewValue}`;
            }

            result = {
              objectId: customObjectListDetails.items[value].externalId,
              field: fieldMachineName,
              overwriteValue,
              scope: OverwriteScopeEnum.GLOBAL,
            };
          });
        }
      });
    }

    return result;
  },
};

export default CustomTableUtils;
