import { uniqueId, without } from 'lodash';
import { createContext, type ReactNode, useCallback, useContext, useState } from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { assert } from '@amal-ia/ext/typescript';

export type LayerId = string;

export type LayersContextValue = {
  layerIds: LayerId[];
  onOpenLayer: () => LayerId;
  onCloseLayer: (layerId: LayerId) => void;
};

export const LayersContext = createContext<LayersContextValue | null>(null);

export const useLayersContext = (): LayersContextValue => {
  const context = useContext(LayersContext);
  assert(context, 'useLayersContext must be used within a <LayersContext.Provider>');
  return context;
};

export type LayersContextProviderProps = {
  readonly children: ReactNode;
};

export const LayersContextProvider = function LayersContextProvider({ children }: LayersContextProviderProps) {
  const [layerIds, setLayerIds] = useState<LayerId[]>([]);

  const onOpenLayer = useCallback(() => {
    const layerId = uniqueId('layer#');
    setLayerIds((currentLayerIds) => [...currentLayerIds, layerId]);
    return layerId;
  }, []);

  const onCloseLayer = useCallback(
    (layerId: LayerId) => setLayerIds((currentLayerIds) => without(currentLayerIds, layerId)),
    [],
  );

  const contextValue = useShallowObjectMemo({
    layerIds,
    onOpenLayer,
    onCloseLayer,
  });

  return <LayersContext.Provider value={contextValue}>{children}</LayersContext.Provider>;
};
