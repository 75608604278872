import { subject } from '@casl/ability';
import { css } from '@emotion/react';
import {
  IconDots,
  IconMessage,
  IconMessageCheck,
  IconMessagePlus,
  IconPencil,
  IconPlusMinus,
  IconReload,
} from '@tabler/icons-react';
import moment from 'moment';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { IconButton, IconLoading, MenuDropdown, Typography } from '@amal-ia/frontend/design-system/components';
import { Can } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';
import { formatCurrencyAmount, PaymentCategory, roundNumber, showPayment } from '@amal-ia/lib-types';
import { objectToQs, TextType } from '@amal-ia/lib-ui';
import { Payout } from '@amal-ia/lib-ui-business';

import { computeMessages } from '../../../common/compute-header-action/compute.messages';
import * as computeStyles from '../../../common/compute-header-action/compute.styles';

import { messages } from './StatementDetailHeaderActions.messages';
import * as styles from './StatementDetailHeaderActions.styles';
import {
  type StatementDetailHeaderActionsProps,
  useStatementDetailHeaderActions,
} from './useStatementDetailHeaderActions';

export const StatementDetailHeaderStickyRow = memo(function StatementDetailHeaderStickyRow(
  props: StatementDetailHeaderActionsProps,
) {
  const {
    statement,
    ability,
    isWorkflowBegun,
    formatMessage,
    handleNewAdjustment,
    statementCommentThread,
    onStatementCommentsClicked,
    menuIconsList,
    paymentTotalByType,
  } = useStatementDetailHeaderActions(props);

  const handleClickLaunchCalculation = statement.launchCalculation;
  const handleClickStopCalculation = statement.stopCalculation;

  return (
    <div css={styles.container}>
      {statement.isForecastedView ? (
        <div css={styles.paymentTotal}>
          {!statement?.error && (
            <Payout
              amountTextType={TextType.FORECASTED_PAYOUT}
              forceColor="unset"
              label={undefined}
              amount={formatCurrencyAmount({
                amount: roundNumber(statement.total) || 0,
                currencySymbol: statement.currency,
              })}
            />
          )}
        </div>
      ) : (
        <Typography
          css={styles.paymentTotal}
          variant={Typography.Variant.HEADING_4_BOLD}
        >
          {showPayment(paymentTotalByType, PaymentCategory.achievement)
            ? formatCurrencyAmount({
                amount: roundNumber(paymentTotalByType.achievement) || 0,
                currencyRate: statement.rate,
                currencySymbol: statement.currency,
              })
            : null}
        </Typography>
      )}
      {ability.can(ActionsEnum.calculate, SubjectsEnum.Statement) &&
        (!statement.isCalculationRunning ? (
          <IconButton
            withBackground
            disabled={isWorkflowBegun ? !statement.isForecastedView : null}
            icon={<IconReload />}
            id="newCalculation"
            label={
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <div>
                  <FormattedMessage {...computeMessages.COMPUTE} />
                </div>
                <div>
                  <FormattedMessage
                    {...computeMessages.CALCULATION_TOOLTIP}
                    values={{
                      day: moment(statement.lastCalculationDate).format('YYYY-MM-DD'),
                      hour: moment(statement.lastCalculationDate).format('HH:mm A'),
                    }}
                  />
                </div>
              </div>
            }
            onClick={handleClickLaunchCalculation}
          />
        ) : (
          <Fragment>
            <p css={computeStyles.calculationProgress}>{statement.calculationProgress}</p>
            <IconButton
              isActive
              withBackground
              disabled={isWorkflowBegun ? !statement.isForecastedView : null}
              icon={<IconLoading icon={IconReload} />}
              id="stopCalculation"
              label={formatMessage(computeMessages.STOP)}
              onClick={handleClickStopCalculation}
            />
          </Fragment>
        ))}
      {ability.can(ActionsEnum.adjust, SubjectsEnum.Statement) && !statement.isForecastedView && (
        <IconButton
          withBackground
          data-testid="new-adjustment-sticky"
          disabled={isWorkflowBegun}
          icon={<IconPlusMinus />}
          label={formatMessage(messages.NEW_ADJUSTMENT)}
          onClick={handleNewAdjustment}
        />
      )}
      {!statement.isForecastedView &&
        ability.can(ActionsEnum.view_statement_threads, subject(SubjectsEnum.Statement, statement)) && (
          <IconButton
            withBackground
            data-testid="comment-statement-sticky"
            label={formatMessage(messages.STATEMENT_COMMENTS)}
            icon={
              statementCommentThread ? (
                statementCommentThread.thread.isReviewed ? (
                  <IconMessageCheck />
                ) : (
                  <IconMessagePlus />
                )
              ) : (
                <IconMessage />
              )
            }
            onClick={onStatementCommentsClicked}
          />
        )}

      <Can
        a={SubjectsEnum.Plan}
        I={ActionsEnum.modify}
      >
        <Link
          to={{
            pathname: generatePath(routes.DESIGNER_V2_PLAN, {
              planId: statement.plan.id,
            }),
            search: `?${objectToQs({
              userId: statement.user.id,
              periodId: statement.period.id,
            })}`,
          }}
        >
          <IconButton
            withBackground
            icon={<IconPencil />}
            label={formatMessage(messages.EDIT_IN_DESIGNER)}
          />
        </Link>
      </Can>

      <MenuDropdown
        content={
          <div>
            {menuIconsList.map((action) => (
              <MenuDropdown.Item
                key={action.label}
                icon={action.icon}
                label={action.label}
                onClick={action.handleClick}
              />
            ))}
          </div>
        }
      >
        <MenuDropdown.IconButton
          withBackground
          icon={<IconDots />}
          label={formatMessage(messages.MORE_ACTIONS)}
        />
      </MenuDropdown>
    </div>
  );
});
