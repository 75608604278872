import { type CSSProperties } from 'react';

import { type Variable, VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';

import { FormulaNodeEnum, IFormulaNode } from './IFormulaNode';

export class VariableFormulaNode extends IFormulaNode {
  private readonly variableType: VariableObjectsEnum;

  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Variable,
    variableType: VariableObjectsEnum,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.VARIABLE, nodeObject, objectDefinition, relationship);
    this.variableType = variableType;
  }

  public getDesignerPath() {
    let type: TokenType | null;

    switch (this.variableType) {
      case VariableObjectsEnum.object:
        type = TokenType.FIELD;
        break;
      case VariableObjectsEnum.statement:
        type = TokenType.VARIABLE;
        break;
      case VariableObjectsEnum.user:
      case VariableObjectsEnum.plan:
      case VariableObjectsEnum.team:
        type = TokenType.QUOTA;
        break;
      default:
        type = null;
        break;
    }

    return type && (this.nodeObject as Variable).id ? { type, id: (this.nodeObject as Variable).id } : null;
  }

  public getStyles(): CSSProperties {
    // If this is a quota variable => print with the quota color
    if ([VariableObjectsEnum.user, VariableObjectsEnum.plan, VariableObjectsEnum.team].includes(this.variableType)) {
      return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.quota };
    }
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR[this.variableType] };
  }

  public getLabel(): string {
    return (this.nodeObject as Variable)?.name.trim();
  }
}
