import { css } from '@emotion/react';
import { memo } from 'react';

import { Typography } from '@amal-ia/frontend/design-system/components';
import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { type ChartSeries } from '../chartSeries';

import { LegendIcon } from './LegendIcon';

type LegendProps = {
  readonly seriesRecords?: Record<string, ChartSeries>;
};

const legendContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const itemContainerCss = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Legend = memo(function Legend({ seriesRecords }: LegendProps) {
  return (
    <div css={legendContainerCss}>
      {Object.values(seriesRecords)
        .filter((s) => s.selected)
        .map((item) => (
          <div
            key={item.name}
            css={itemContainerCss}
          >
            <LegendIcon series={item} />
            <div>
              <Typography variant={TypographyVariant.BODY_BASE_MEDIUM}>{item.label}</Typography>
            </div>
          </div>
        ))}
    </div>
  );
});
