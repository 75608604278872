import { Box } from '@mui/material';
import { memo } from 'react';

import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { amaliaThemeInstance } from '@amal-ia/ext/mui/theme';
import { getCurrencySymbolCharacters } from '@amal-ia/lib-types';

interface EarningsCardCurrencyProps {
  readonly symbol: CurrencySymbolsEnum;
}

export const EarningsCardCurrency = memo(function EarningsCardCurrency({ symbol }: EarningsCardCurrencyProps) {
  return (
    <Box
      bgcolor={amaliaThemeInstance.palette.primary.main}
      borderRadius="50%"
      color={amaliaThemeInstance.palette.grey[700]}
      height="30px"
      minWidth="30px"
      paddingLeft="7px"
      paddingRight="7px"
      paddingTop="1px"
      width="auto"
    >
      {getCurrencySymbolCharacters(symbol)}
    </Box>
  );
});
