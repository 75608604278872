/**
 * All the routes of the app.
 *
 * TODO: Routes are needed in front and back, however should we split by domain?
 * In the future we will split it in feature libs.
 */
export const routes = {
  ROOT: '/',

  AUTH_IFRAME: '/auth/iframe',
  AUTH_SSO_INITIATED: '/auth/sso/initiated',
  AUTH_CONNECTOR_CALLBACK: '/auth/:connector/callback',
  AUTH_INTEGRATION_CALLBACK: '/auth-integration/:integration/callback',

  HOME: '/home',

  DATA_EXPORT: '/data_exports/:exportId',

  DESIGNER: '/designer',
  DESIGNER_OBJECT: '/designer/:objectType/:objectId',

  DESIGNER_V2: '/designer/v2',
  DESIGNER_V2_OBJECT: '/designer/v2/:planId/:objectType/:objectId',
  DESIGNER_V2_PLAN: '/designer/v2/:planId',

  PLANS: '/plans',
  PLAN_ASSIGNMENT: '/plans/:planId/assignment',

  TEAM_LIST: '/team',
  TEAM_ITEM: '/team/:teamId',
  TEAM_MEMBERS: '/team/:teamId/members',
  TEAM_PLANS: '/team/:teamId/plans',

  QUOTAS: '/quotas',
  QUOTAS_VALUE: '/quotas/values/:variableId',
  QUOTAS_USER: '/quotas/user/:userId',

  AUDIT: '/audit',

  PLAN_AGREEMENTS: '/planAgreements',
  PLAN_AGREEMENT_ASSIGNMENT_VIEW_DOCUMENT: '/planAgreements/:planAgreementId/assignments/:assignmentId/viewDocument',
  PLAN_AGREEMENT_PREVIEW_DOCUMENT: '/planAgreements/:planAgreementId/previewDocument',
  PLAN_AGREEMENT: '/planAgreements/:planAgreementId',

  CHANGELOG: '/changelog',

  OVERWRITES: '/overwrites',

  COMMISSION_REPORT: '/commissionReport',

  CUSTOM_REPORT_LIST: '/customReport',
  CUSTOM_REPORT_ITEM: '/customReport/:reportId',

  DATA: '/data',
  DATA_SLUG: '/data/:slug',
  DATA_CONNECTORS: '/data/connectors',
  DATA_CONNECTOR: '/data/connectors/:connectorType',
  DATA_CONNECTOR_CONNECTION: '/data/connectors/:connectorType/connection',
  DATA_USER_CONNECTOR: '/data/userconnectors/:connectorType',
  DATA_USER_CONNECTOR_CONNECTION: '/data/userconnectors/:connectorType/connection',

  COMPANY: '/company',
  COMPANY_DIRECTORY: '/company/directory',
  COMPANY_DIRECTORY_CREATE: '/company/directory/new',
  COMPANY_SETTINGS: '/company/settings',
  COMPANY_API: '/company/settings/api',
  COMPANY_RATES: '/company/settings/rates',
  COMPANY_WORKFLOWS: '/company/settings/workflows',
  COMPANY_PAYMENTS_LOCK: '/company/settings/payments-lock',
  COMPANY_CUSTOMIZATION: '/company/settings/customization',

  TODOS: '/todos',

  SUPERADMIN: '/superadmin',
  SUPERADMIN_HOME: '/superadmin/home',
  SUPERADMIN_SETTINGS: '/superadmin/settings',
  SUPERADMIN_COMPANIES: '/superadmin/companies',
  SUPERADMIN_COMPANY: '/superadmin/companies/:companyId',
  SUPERADMIN_CALCULATIONS: '/superadmin/calculations',
  SUPERADMIN_REFRESHMENTS: '/superadmin/refreshments',
  SUPERADMIN_CONNECTORS: '/superadmin/connectors',
  SUPERADMIN_STATEMENTS: '/superadmin/statements',
  SUPERADMIN_USERS: '/superadmin/users',
  SUPERADMIN_PLANS: '/superadmin/plans',

  DASHBOARD: '/dashboard',
  DASHBOARD_START_DATE: '/dashboard/:startDate',

  FLOWS: '/flow',
  FLOW_CREATE: '/flow/create',
  FLOW: '/flow/:flowId',

  STATEMENTS: '/statements',
  STATEMENTS_BY_DATE: '/statements/by-date/:startDate',
  STATEMENTS_BY_USER_PERIOD: '/statements/by-date/:startDate/plan/:planId/user/:userId',
  STATEMENT: '/statements/:id',
  STATEMENT_COMMENT_CREATE: '/statements/:id/comments',
  STATEMENT_COMMENT: '/statements/:id/comments/:stid',
  STATEMENT_RULE: '/statements/:id/rule/:ruleid',
  STATEMENT_RULE_ACTION: '/statements/:id/rule/:ruleid/:action',
  STATEMENT_RULE_ACTION_EXTERNAL_ID: '/statements/:id/rule/:ruleid/:action/:externalid',

  FORECASTS: '/forecasts',
  FORECASTS_BY_DATE: '/forecasts/forecastsByDate/:startDate',
  FORECAST: '/forecasts/:id',
} as const;
