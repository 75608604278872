import { useAuth0 } from '@auth0/auth0-react';
import { isEqual } from 'lodash';
import { Fragment, memo, type ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';

import { Link } from '@amal-ia/ext/react-router-dom';
import { toError } from '@amal-ia/ext/typescript';
import { AuthContext, useFetchAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { http, HttpError } from '@amal-ia/frontend/kernel/http';
import { formatDate } from '@amal-ia/lib-types';
import { getMockedAccessToken, useLoadingScreen, useQueryString } from '@amal-ia/lib-ui';
import { useCompany } from '@amal-ia/tenants/companies/shared/state';

import { AuthorizationProtectorMessages } from './AuthorizationProtector.messages';
import { AuthenticationError } from './authPartials/AuthenticationError';
import { AuthenticationLogin } from './authPartials/AuthenticationLogin';
import { AuthenticationNotInvited } from './authPartials/AuthenticationNotInvited';

interface AuthorizationProtectorProps {
  readonly children: ReactNode;
}

export const AuthorizationProtector = memo(function AuthorizationProtector({ children }: AuthorizationProtectorProps) {
  const { search } = useLocation();
  const { error: errorFromAuth0, error_description: errorFromAuth0Description } = useQueryString();
  const [error, setError] = useState<Error | null>(null);
  const { hideLoading } = useLoadingScreen();
  const { user: auth0User, isAuthenticated: isAuthenticatedWithAuth0, isLoading, getAccessTokenSilently } = useAuth0();

  const { authenticatedContext, error: userError, refetch } = useFetchAuthenticatedContext();

  // Make sure that the redux store is set with the current company before starting the app.
  const { data: company } = useCompany({ enabled: !!authenticatedContext?.user });

  const mockedAccessToken = useMemo(() => {
    const params = new URLSearchParams(search);
    const tokenFromUrl = params.get('token');
    return getMockedAccessToken(tokenFromUrl);
  }, [search]);

  useAsyncEffect(async () => {
    if (errorFromAuth0) {
      setError(new Error(`Error ${errorFromAuth0} from Authentication Provider: ${errorFromAuth0Description}`));
    } else if (userError && !isEqual(userError, error)) {
      setError(toError(userError));
    } else {
      try {
        // If we have a mocked access token, for instance in a test scenario, put
        // it directly in the Authorization header of all future requests. If not,
        // give the getAccessTokenSilently callback to an axios middleware that
        // will call this function on each request to get a token.
        if (mockedAccessToken) {
          http.setJwt(mockedAccessToken);
          await refetch();
        } else {
          http.setTokenInterceptor(getAccessTokenSilently);
        }
      } catch (thrownError) {
        setError(toError(thrownError));
      }
    }
    return () => {
      http.setJwt(null);
      http.clearTokenInterceptor();
    };
  }, [
    error,
    errorFromAuth0,
    errorFromAuth0Description,
    getAccessTokenSilently,
    isAuthenticatedWithAuth0,
    isLoading,
    mockedAccessToken,
    refetch,
    userError,
  ]);

  // We consider the page ready if it's not loading anymore, and if
  // either auth0 doesn't connect anyone (then we'll show the connection page)
  // or there is a user in the store.
  const userIsAnonymous = !auth0User && !mockedAccessToken;
  const isAuthReady = !isLoading && (!!(authenticatedContext && company) || userIsAnonymous || !!error);

  useEffect(() => {
    if (isAuthReady) {
      hideLoading();
    }
  }, [isAuthReady, hideLoading]);

  const MAINTENANCE_MESSAGE = (
    <FormattedMessage
      {...AuthorizationProtectorMessages.MAINTENANCE}
      values={{
        link: (chunks) => (
          <Link
            openInNewTab
            to="https://status.amalia.io"
          >
            {chunks}
          </Link>
        ),
      }}
    />
  );

  switch (true) {
    // The app is actually down but let's pretend it's a planned maintenance lol.
    case !!error && error.message === 'Network Error':
      return (
        <AuthenticationError
          isMaintenance
          message={MAINTENANCE_MESSAGE}
        />
      );

    // Maintenance page.
    case !!error && error instanceof HttpError && [500, 503].includes(error.statusCode): {
      const { payload, message } = error as HttpError<{ startDate?: Date; endDate?: Date } | undefined>;
      const { startDate, endDate } = payload || {};
      return (
        <AuthenticationError
          isMaintenance
          message={message || MAINTENANCE_MESSAGE}
          footer={
            startDate || endDate ? (
              <i>
                {startDate && endDate ? (
                  <FormattedMessage
                    {...AuthorizationProtectorMessages.PLANNED_MAINTENANCE_DATES}
                    values={{
                      startDate: formatDate(startDate, 'LLL'),
                      endDate: formatDate(endDate, 'LLL'),
                    }}
                  />
                ) : startDate && !endDate ? (
                  <FormattedMessage
                    {...AuthorizationProtectorMessages.PLANNED_MAINTENANCE_FROM_DATE}
                    values={{
                      startDate: formatDate(startDate, 'LLL'),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...AuthorizationProtectorMessages.PLANNED_MAINTENANCE_UNTIL_DATE}
                    values={{
                      endDate: formatDate(endDate, 'LLL'),
                    }}
                  />
                )}
              </i>
            ) : null
          }
        />
      );
    }
    case !!error && error instanceof HttpError && error.statusCode === 403:
      return <AuthenticationNotInvited />;

    // We caught an error when no account linked.
    case !!error && !!auth0User:
      return <AuthenticationError message={<FormattedMessage defaultMessage="No linked account exists." />} />;

    // We caught an error during connexion.
    case !!error:
      return (
        <AuthenticationError
          footer={
            error ? (
              <Fragment>
                <br />
                <em>{error.message}</em>
              </Fragment>
            ) : null
          }
          message={
            <FormattedMessage defaultMessage="An error happened while trying to connect you. Please contact your administrator." />
          }
        />
      );

    // Connexion took place but we didn't connect anyone.
    case isAuthReady && userIsAnonymous:
      return <AuthenticationLogin />;

    // Connexion took place, the user is now connected.
    case isAuthReady && !!authenticatedContext:
      return <AuthContext.Provider value={authenticatedContext}>{children}</AuthContext.Provider>;

    // Default case only happen when the user is not loading and not loaded,
    // which means it's the startup of the app (between the first react render
    // and the run of the useEffect that goes fetch `/users/me`). Do not return
    // anything because it's gonna be hidden behind the loading screen anyway.
    default:
      return null;
  }
});
