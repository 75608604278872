import { Badge as BaseBadge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, type ReactNode } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

interface BadgeProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  readonly badgeColor?: string;

  /**
   * The content rendered within the badge.
   */
  readonly badgeContent?: ReactNode;

  readonly children?: ReactNode;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  badge: {
    borderColor: (props: BadgeProps) => props.badgeColor,
    borderWidth: 1,
    borderStyle: 'solid',
    color: (props: BadgeProps) => props.badgeColor,
    backgroundColor: theme.palette.common.white,
  },
}));

export const Badge = memo(function Badge({ children, badgeColor, ...props }: BadgeProps) {
  const classes = useStyles({ badgeColor });
  return (
    <BaseBadge
      {...props}
      classes={classes}
    >
      {children}
    </BaseBadge>
  );
});
