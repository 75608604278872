import { type ConnectorResponseWithLastRefreshment } from '@amal-ia/data-capture/connectors/types';
import { http } from '@amal-ia/frontend/kernel/http';

export async function saGetConnectors(companiesId: string[]): Promise<ConnectorResponseWithLastRefreshment[]> {
  const { data } = await http.get<ConnectorResponseWithLastRefreshment[]>('/sa_connectors', {
    params: {
      companiesId,
    },
  });
  return data;
}
