import { memo } from 'react';

import { useStatementDetailContext } from '@amal-ia/lib-ui';

import { StatementSummaryLayout } from '../../common/statement-summary-layout/StatementSummaryLayout';

import { StatementDetailSummaryHistogram } from './histogram/StatementDetailSummaryHistogram';
import { StatementDetailSummaryKpis } from './kpis/StatementDetailSummaryKpis';
import { StatementDetailSummaryPayoutForecasted } from './payout/payout-forecasted/StatementDetailSummaryPayoutForecasted';
import { StatementDetailSummaryPayout } from './payout/StatementDetailSummaryPayout';
import { StatementDetailSummaryReview } from './review/StatementDetailSummaryReview';
import { StatementDetailSummaryReviewDisabled } from './review-disabled/StatementDetailSummaryReviewDisabled';

export type StatementSummaryProps = {
  readonly onClickGoToStatement: (period: string) => void;
};

export const StatementSummary = memo(function StatementSummary({ onClickGoToStatement }: StatementSummaryProps) {
  const statement = useStatementDetailContext();

  return (
    <StatementSummaryLayout>
      <StatementSummaryLayout.ReviewPayoutContainer>
        <StatementSummaryLayout.ReviewContainer>
          {statement.isForecastedView ? <StatementDetailSummaryReviewDisabled /> : <StatementDetailSummaryReview />}
        </StatementSummaryLayout.ReviewContainer>

        <StatementSummaryLayout.PayoutContainer>
          {statement.isForecastedView ? <StatementDetailSummaryPayoutForecasted /> : <StatementDetailSummaryPayout />}
        </StatementSummaryLayout.PayoutContainer>
      </StatementSummaryLayout.ReviewPayoutContainer>

      <StatementSummaryLayout.HistogramKpisContainer>
        <StatementSummaryLayout.KpisContainer>
          <StatementDetailSummaryKpis />
        </StatementSummaryLayout.KpisContainer>

        <StatementSummaryLayout.HistogramContainer>
          <StatementDetailSummaryHistogram onClickGoToStatement={onClickGoToStatement} />
        </StatementSummaryLayout.HistogramContainer>
      </StatementSummaryLayout.HistogramKpisContainer>
    </StatementSummaryLayout>
  );
});
