import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Skeleton from '@mui/lab/Skeleton';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { capitalize, isEmpty, nth } from 'lodash';
import { type SVGProps, memo, useCallback, type Dispatch, type SetStateAction } from 'react';
import { type XAxisProps, type YAxisProps } from 'recharts';

import { colors, type AmaliaThemeType, amaliaTheme } from '@amal-ia/ext/mui/theme';

import { type ActionButtonsProps, type PlotDefinitions, type Stat } from './ComposedChart';

interface ActionsProps {
  readonly ActionButtons?: ActionButtonsProps;
  readonly XaxisProps?: (Omit<SVGProps<SVGElement>, 'scale'> & XAxisProps)[];
  readonly YaxisProps?: (Omit<SVGProps<SVGElement>, 'scale'> & YAxisProps)[];
  readonly plots?: PlotDefinitions;
  readonly data?: Stat[];
  readonly hidden?: string[];
  readonly setHidden?: Dispatch<SetStateAction<string[]>>;
  readonly loading?: boolean;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  title: {
    width: 'auto',
    marginInline: theme.spacing(0),
    marginBlock: theme.spacing(2.5),
    paddingInline: theme.spacing(1),
    paddingBlock: theme.spacing(1.25),
    borderRadius: theme.spacing(0.75),
  },
  skeleton: {
    width: theme.spacing(20),
    height: theme.spacing(4.5),
    marginBlock: theme.spacing(1),
  },
}));

export const Actions = memo(function Actions({
  ActionButtons,
  XaxisProps,
  YaxisProps,
  data,
  hidden,
  plots,
  setHidden,
  loading,
}: ActionsProps) {
  const handleButtonClick = useCallback(
    (dataKey: string) => {
      setHidden((trueHidden) =>
        trueHidden.includes(dataKey) ? trueHidden.filter((hide) => hide !== dataKey) : [...trueHidden, dataKey],
      );
    },
    [setHidden],
  );
  const styles = useCallback(
    (idx: number) => ({
      width: 'auto',
      marginInline: amaliaTheme.spacing(0),
      marginBlock: amaliaTheme.spacing(2.5),
      paddingInline: amaliaTheme.spacing(1),
      paddingBlock: amaliaTheme.spacing(1.25),
      borderRadius: amaliaTheme.spacing(0.75),
      backgroundColor: nth(ActionButtons.colors || [], idx),
      ...ActionButtons.buttonStyle,
    }),
    [ActionButtons],
  );
  const classes = useStyles();
  if (loading) {
    return (
      <Box {...ActionButtons?.containerStyle}>
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
        />
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
        />
        <Skeleton
          className={classes.skeleton}
          variant="rectangular"
        />
      </Box>
    );
  }
  if (ActionButtons) {
    return (
      !isEmpty(data) && (
        <Box {...ActionButtons.containerStyle}>
          {ActionButtons.CustomButton ? (
            <ActionButtons.CustomButton
              {...{
                data,
                plots,
                XaxisProps,
                YaxisProps,
              }}
            />
          ) : (
            Object.keys(data[0])
              .filter((key) => key !== 'X')
              .map((dataKey, idx) => (
                /* filtering out the X key as it is the X axis key ( by default, can be changed) */
                <button
                  key={`${dataKey}-btn-chart-datakey`}
                  className={clsx('chart-mixed-btn', hidden.includes(dataKey) && 'hidden-btn-chart', classes.title)}
                  style={styles(idx)}
                  type="button"
                  onClick={() => handleButtonClick(dataKey)}
                >
                  <span style={{ marginRight: 15 }}>{ActionButtons.labels[dataKey] || capitalize(dataKey)}</span>
                  {hidden.includes(dataKey) ? (
                    <VisibilityOff
                      fontSize="small"
                      htmlColor={colors['grey-700']}
                    />
                  ) : (
                    <VisibilityIcon
                      fontSize="small"
                      htmlColor={colors['grey-700']}
                    />
                  )}
                </button>
              ))
          )}
        </Box>
      )
    );
  }
  return null;
});

export default memo(Actions);
