import { http } from '@amal-ia/frontend/kernel/http';
import { type OverwriteFiltersResponse, type OverwriteResponse, type PaginatedResponse } from '@amal-ia/lib-types';

import { type OverwriteConfigFilters } from '../../types/overwrites';

const apiEndpoint = '/overwrites';

export async function getDataFilters(): Promise<OverwriteFiltersResponse> {
  const { data } = await http.get<OverwriteFiltersResponse>(`${apiEndpoint}/filters`);
  return data;
}

export async function getOverwrites(
  searchText?: string,
  page = 0,
  limit = 10,
  sortColumn?: string,
  sortOrder = 'asc',
  selectedFilters: OverwriteConfigFilters | null = null,
): Promise<PaginatedResponse<Partial<OverwriteResponse>>> {
  const {
    items: responseItems,
    totalItems,
    pageCount,
  } = (
    await http.get(apiEndpoint, {
      params: {
        sort: sortColumn,
        [sortOrder]: sortOrder,
        q: searchText,
        page,
        limit,
        ...selectedFilters,
      },
    })
  ).data;

  if (!responseItems) {
    return {
      totalItems: 0,
      items: [],
      pageCount: 0,
    };
  }
  return {
    totalItems,
    items: responseItems,
    pageCount,
  };
}
