import { ChevronRight } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, type ReactNode, useCallback, useMemo, useState } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

import { type IconOption } from '../../../../utils/common.types';
import { Tooltip } from '../../Tooltip/Tooltip';
import { Text, TextType } from '../../typography';
import { FormElementContainer } from '../inputs/Input/Input';

export const useSelectIconStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  opened: {
    transform: 'rotate(-90deg)',
  },
  closed: {
    transform: 'rotate(90deg)',
  },
  buttonContainer: {
    height: '30px',
  },
  popup: {
    position: 'absolute',
    display: 'flex',
    columnGap: '10px',
    maxWidth: '250px',
    overflow: 'auto',
    padding: theme.spacing(1),
    zIndex: 3,
  },
  popupVertical: {
    flexDirection: 'column',
  },
  iconContainer: {
    flex: '0 0 80px',
    width: '80px',
    cursor: 'pointer',
  },
}));

export interface SelectIconBaseProps {
  readonly options: IconOption[];
  readonly value: string;
  readonly selectedValueTooltip?: string;
  readonly onChange: (e: IconOption) => void;
  readonly isVerticalLayout?: boolean;
  readonly overloadIconClass?: string;
  readonly hideDeployIcon?: boolean;
  readonly defaultText?: ReactNode;
}

export const SelectIconBase = memo(function SelectIconBase({
  options,
  value,
  selectedValueTooltip,
  onChange,
  isVerticalLayout,
  overloadIconClass,
  hideDeployIcon,
  defaultText,
}: SelectIconBaseProps) {
  const [isSelectionOpen, setIsSelectionOpen] = useState<boolean>(false);

  const classes = useSelectIconStyles();

  const handleSelection = useCallback(() => setIsSelectionOpen((prev) => !prev), []);

  const onSelectIcon = useCallback(
    (icon: IconOption) => {
      const selected = options.find((o) => o.value === icon.value);
      onChange(selected);
      handleSelection();
    },
    [handleSelection, onChange, options],
  );

  const selectedOption = useMemo(() => options.find((o) => o.value === value), [options, value]);
  const SelectedIcon = selectedOption?.icon;

  const content = useMemo(() => {
    if (SelectedIcon) {
      return <SelectedIcon />;
    }
    return defaultText || '';
  }, [defaultText, SelectedIcon]);

  return (
    <FormElementContainer>
      <Box
        className={classes.container}
        onClick={handleSelection}
      >
        {selectedValueTooltip ? (
          <Tooltip title={<Text type={TextType.TOOLTIP}>{selectedValueTooltip}</Text>}>
            <Box className={overloadIconClass || classes.iconContainer}>{content}</Box>
          </Tooltip>
        ) : (
          <Box className={overloadIconClass || classes.iconContainer}>{content}</Box>
        )}
        {!hideDeployIcon && (
          <Box className={classes.buttonContainer}>
            <ChevronRight className={isSelectionOpen ? classes.opened : classes.closed} />
          </Box>
        )}
      </Box>
      {isSelectionOpen ? (
        <Card className={clsx(classes.popup, isVerticalLayout && classes.popupVertical)}>
          {options.map((option) => {
            const Icon = option.icon;
            return (
              <Box
                key={option.value}
                className={overloadIconClass || classes.iconContainer}
                onClick={() => onSelectIcon(option)}
              >
                <Icon />
              </Box>
            );
          })}
        </Card>
      ) : null}
    </FormElementContainer>
  );
});
