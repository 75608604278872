import { type Theme, css } from '@emotion/react';

import { type ColorValue } from '@amal-ia/frontend/design-system/meta';

import { AlertBannerVariant } from './AlertBanner.types';

export const INLINE_CLASSNAME = 'inline';
export const WITH_BORDER_CLASSNAME = 'with-border';
export const ALIGN_CENTER_CLASSNAME = 'align-center';

export const alertBanner = (theme: Theme) => css`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 16px;

  border-radius: ${theme.ds.borderRadiuses.squared};

  &.${ALIGN_CENTER_CLASSNAME} {
    align-items: center;
  }

  &.${AlertBannerVariant.INFO} {
    color: ${theme.ds.colors.info[900]};
    background-color: ${theme.ds.colors.info[50]};

    &.${WITH_BORDER_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.info[100]};
    }
  }

  &.${AlertBannerVariant.WARNING} {
    color: ${theme.ds.colors.warning[900]};
    background-color: ${theme.ds.colors.warning[50]};

    &.${WITH_BORDER_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.warning[100]};
    }
  }

  &.${AlertBannerVariant.ERROR} {
    color: ${theme.ds.colors.danger[900]};
    background-color: ${theme.ds.colors.danger[50]};

    &.${WITH_BORDER_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.danger[100]};
    }
  }

  &.${AlertBannerVariant.SUCCESS} {
    color: ${theme.ds.colors.success[900]};
    background-color: ${theme.ds.colors.success[50]};

    &.${WITH_BORDER_CLASSNAME} {
      outline: 1px solid ${theme.ds.colors.success[100]};
    }
  }

  &.${INLINE_CLASSNAME} {
    display: inline-flex;
  }

  &.${WITH_BORDER_CLASSNAME} {
    outline-offset: -1px;
  }
`;

export const getIconColor = (theme: Theme, variant: AlertBannerVariant): ColorValue => {
  switch (variant) {
    case AlertBannerVariant.WARNING:
      return theme.ds.colors.warning[500];
    case AlertBannerVariant.ERROR:
      return theme.ds.colors.danger[500];
    case AlertBannerVariant.SUCCESS:
      return theme.ds.colors.success[500];
    case AlertBannerVariant.INFO:
    default:
      return theme.ds.colors.info[500];
  }
};
