import { enqueueSnackbar } from 'notistack';

import { type ReduxAction } from '@amal-ia/lib-types';

import { SNACKBAR_ACTIONS, type SnackbarDefinition } from './constants';

export default (state = [] as SnackbarDefinition[], action: ReduxAction) => {
  switch (action.type) {
    case SNACKBAR_ACTIONS.ADD:
      enqueueSnackbar(action.payload.snackbar.message, action.payload.snackbar.options);
  }
  return state;
};
