import { IconHelpCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import { cloneElement, memo, useCallback } from 'react';

import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Typography } from '../../../general/typography/Typography';
import { Tooltip } from '../../../overlays/tooltip/Tooltip';
import { type RadioOptionValue } from '../../radio/Radio.types';
import { RadioButtonGroupSize, type RadioButtonOptionShape } from '../RadioButtonGroup.types';

import * as styles from './RadioButtonOption.styles';

const TYPOGRAPHY_MAPPING: Record<RadioButtonGroupSize, Record<'false' | 'true', TypographyVariant>> = {
  [RadioButtonGroupSize.SMALL]: {
    true: TypographyVariant.BODY_SMALL_MEDIUM,
    false: TypographyVariant.BODY_SMALL_MEDIUM,
  },

  [RadioButtonGroupSize.MEDIUM]: {
    true: TypographyVariant.BODY_BASE_MEDIUM,
    false: TypographyVariant.BODY_BASE_MEDIUM,
  },
};

const ICON_SIZE_MAPPING: Record<RadioButtonGroupSize, number> = {
  [RadioButtonGroupSize.SMALL]: 14,
  [RadioButtonGroupSize.MEDIUM]: 16,
};

export type RadioButtonOptionProps<
  TValue extends RadioOptionValue,
  TShowOnlyIcon extends boolean | undefined = undefined,
> = RadioButtonOptionShape<TValue, TShowOnlyIcon> & {
  /** Field name. */
  readonly name: string;
  /** Is the option checked. */
  readonly checked: boolean;
  /** Radio group size. */
  readonly size: RadioButtonGroupSize;
  /** Change handler. */
  readonly onChange: (newValue: TValue) => void;
  /** Is the option disabled (radio group disabled). */
  readonly disabled?: boolean;
  readonly ['data-testid']?: string;
};

const RadioButtonOptionBase = function RadioButtonOption<
  TValue extends RadioOptionValue,
  TShowOnlyIcon extends boolean | undefined = undefined,
>({
  value,
  label,
  name,
  checked,
  size,
  onChange,
  help = undefined,
  showOnlyIcon = false as TShowOnlyIcon,
  icon = undefined,
  disabled = false,
  'data-testid': testid = undefined,
}: RadioButtonOptionProps<TValue, TShowOnlyIcon>) {
  const onChangeProxy = useCallback(() => onChange(value), [onChange, value]);

  return (
    <Tooltip content={showOnlyIcon ? label : ''}>
      <label
        className={clsx(size, { checked, disabled })}
        css={styles.radioButtonOption}
      >
        <input
          checked={checked}
          css={styles.hidden}
          data-testid={testid}
          disabled={disabled}
          name={name}
          type="radio"
          value={value}
          onChange={onChangeProxy}
        />

        <div css={styles.content}>
          {!!icon && (
            <div css={styles.icon}>
              {cloneElement(icon, {
                size: ICON_SIZE_MAPPING[size],
                // Use specified color for the icon if checked and not disabled.
                // Fallback to current text color for all other cases or if no color was specified.
                color: checked && !disabled ? icon.props.color ?? 'currentColor' : 'currentColor',
              })}
            </div>
          )}

          {!showOnlyIcon && <Typography variant={TYPOGRAPHY_MAPPING[size][`${checked}`]}>{label}</Typography>}

          {!!help && (
            <Tooltip content={help}>
              <IconHelpCircle
                color="currentColor"
                size={ICON_SIZE_MAPPING[size]}
              />
            </Tooltip>
          )}
        </div>
      </label>
    </Tooltip>
  );
};

export const RadioButtonOption = memo(RadioButtonOptionBase) as typeof RadioButtonOptionBase;
