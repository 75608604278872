import { defineMessages } from 'react-intl';

import { ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';

export const valueOrAttributeMessages = defineMessages<ValueOrAttributeType>({
  [ValueOrAttributeType.VALUE]: {
    defaultMessage: 'Manual value',
  },

  [ValueOrAttributeType.ATTRIBUTE]: {
    defaultMessage: 'Attribute',
  },
} as const);

export const valueOrAttributeOptionsMessages = defineMessages({
  FIELD_HELP: {
    defaultMessage: 'Compare “{propertyName}” with other “{customObjectName}” fields or linked fields.',
  },
} as const);
