import { Children, type ReactElement, type ReactNode, memo } from 'react';

import {
  BreadcrumbsBackButton,
  type BreadcrumbsBackButtonProps,
} from './breadcrumbs-back-button/BreadcrumbsBackButton';
import { BreadcrumbsBackLink, type BreadcrumbsBackLinkProps } from './breadcrumbs-back-link/BreadcrumbsBackLink';
import { BreadcrumbsPopoverItem } from './breadcrumbs-popover-item/BreadcrumbsPopoverItem';
import { BreadcrumbsSelectItem } from './breadcrumbs-select-item/BreadcrumbsSelectItem';
import { BreadcrumbsTextItem } from './breadcrumbs-text-item/BreadcrumbsTextItem';
import * as styles from './Breadcrumbs.styles';

export type BreadcrumbsProps = {
  /**
   * Back button, either `BreadcrumbsBackButton` or `BreadcrumbsBackLink`.
   * Redirection must be handled by parent component, and the back button must have a tooltip.
   */
  readonly back?: ReactElement<BreadcrumbsBackButtonProps> | ReactElement<BreadcrumbsBackLinkProps>;
  /** Breadcrumbs items (`BreadcrumbsPopoverItem` or `BreadcrumbsTextItem` elements). */
  readonly children?: ReactNode;
};

export const BreadcrumbsBase = memo(function Breadcrumbs({ back = undefined, children = undefined }: BreadcrumbsProps) {
  const childrenCount = Children.count(children);

  return back || childrenCount ? (
    <div css={styles.breadcrumbs}>
      {back}

      {!!children && <div css={styles.breadcrumbsItems}>{children}</div>}
    </div>
  ) : null;
});

export const Breadcrumbs = Object.assign(BreadcrumbsBase, {
  BackButton: BreadcrumbsBackButton,
  BackLink: BreadcrumbsBackLink,
  PopoverItem: BreadcrumbsPopoverItem,
  SelectItem: BreadcrumbsSelectItem,
  TextItem: BreadcrumbsTextItem,
});
