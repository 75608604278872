import { useTheme } from '@emotion/react';
import { memo, useMemo } from 'react';

import { GaugeGroup, type GaugeGroupProps } from '@amal-ia/lib-ui';

export type StatementGaugeGroupProps = Omit<GaugeGroupProps, 'gaugeColor'> & {
  readonly isForecast?: boolean;
};

export const StatementGaugeGroup = memo(function StatementGaugeGroup({
  isForecast = false,
  ...props
}: StatementGaugeGroupProps) {
  const theme = useTheme();

  const gaugeColor: Required<GaugeGroupProps>['gaugeColor'] = useMemo(
    () =>
      isForecast
        ? {
            primary: theme.ds.hues.blue[300],
            primaryEmptyStroke: theme.ds.hues.blue[50],
            secondary: theme.ds.hues.blue[700],
            secondaryEmptyStroke: theme.ds.hues.blue[50],
          }
        : {
            primary: theme.ds.colors.primary[500],
            primaryEmptyStroke: theme.ds.colors.primary[50],
            secondary: theme.ds.colors.secondary[500],
            secondaryEmptyStroke: theme.ds.colors.secondary[50],
          },
    [isForecast, theme],
  );

  return (
    <GaugeGroup
      {...props}
      gaugeColor={gaugeColor}
    />
  );
});
