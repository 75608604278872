import { createSelector } from 'reselect';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { type RootState, type Statement, type StatementForecast } from '@amal-ia/lib-types';

export const selectLastStatementsFetchParams = (state: RootState): Record<string, any> => state.statements.lastparams;

export const selectStatements = (state: RootState): Record<string, Statement> => state.statements.map;

export const selectUserStatements = (state: RootState): Record<string, Statement> => state.statements.userStatements;

export const selectCurrentStatement = (state: RootState): Statement => state.statements.currentStatement;

export const selectCurrentForecastedStatement = (state: RootState): StatementForecast =>
  state.statements.currentForecastedStatement;

export const selectCurrentPeriod = (state: RootState): Period => state.statements.currentPeriod;

export const selectStatementsIsLoading = (state: RootState): boolean => !!state.statements.isLoading;

export const selectUserStatementsSorted = createSelector([selectUserStatements], (statementsMap) =>
  Object.values(statementsMap).sort((e1, e2) => (e1.period.startDate >= e2.period.startDate ? 1 : -1)),
);
