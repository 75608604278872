import { css } from '@emotion/react';
import { memo } from 'react';

import { type BadgeAward, type BadgeConfiguration } from '@amal-ia/compensation-definition/plans/types';
import { Paper, Typography } from '@amal-ia/frontend/design-system/components';
import { Tooltip } from '@amal-ia/lib-ui';

import { BADGE_LOGOS } from './badges.enum';
import { BadgeSummary } from './BadgeSummary';

interface BadgeItemProps {
  readonly award?: BadgeAward;
  readonly configuration: BadgeConfiguration;
}

export const BadgeItem = memo(function BadgeItem({ award, configuration }: BadgeItemProps) {
  const Logo = award ? BADGE_LOGOS[configuration.icon].iconColor : BADGE_LOGOS[configuration.icon].iconGrey;

  return (
    <Paper
      css={css`
        display: flex;
        align-items: center;
        gap: 24px;
        padding: 8px 32px 8px 24px;
      `}
    >
      <Logo
        css={css`
          width: 64px;
          display: block;
        `}
      />

      <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>
        {configuration.description ? (
          <Tooltip
            title={
              <BadgeSummary
                badgeConfiguration={configuration}
                isAwarded={!!award}
              />
            }
          >
            <div
              css={css`
                opacity: ${award ? 1 : 0.5};
              `}
            >
              {configuration.name}
            </div>
          </Tooltip>
        ) : (
          configuration.name
        )}
      </Typography>
    </Paper>
  );
});
