import { Fragment, memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type ValueOrAttributeDateForm } from '@amal-ia/amalia-lang/formula/form';
import { FORMULA_KEYWORDS } from '@amal-ia/amalia-lang/formula/shared/keywords';
import {
  type AttributeValue,
  type AttributeValueDate,
  DateTransform,
  FormulaKeyword,
  ValueOrAttributeType,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import {
  DatePicker,
  type DatePickerProps,
  Input,
  type InputProps,
  RadioButtonGroup,
  type RadioButtonGroupProps,
  type RadioButtonOptionShape,
  Switch,
  type SwitchProps,
} from '@amal-ia/frontend/design-system/components';
import { toDate } from '@amal-ia/kernel/dates';

import {
  isFieldOrPropertyTokenType,
  isQuotaOrVariableTokenType,
  useAttributesOptions,
  type UseAttributesOptionsOptions,
} from '../../../../hooks/use-attributes-options/useAttributesOptions';
import { AttributeSelector, type AttributeSelectorProps } from '../../../attribute-selector/AttributeSelector';
import { useFormulaBuilderContext } from '../../../formula-builder/FormulaBuilder.context';
import { useDateTransformerOptions } from '../../date-transformer-radio-button-group/useDateTransformerOptions';
import { getOptionTransformValue } from '../FunctionDatePopover';
import { functionDatePopoverMessages } from '../FunctionDatePopover.messages';
import * as styles from '../FunctionDatePopover.styles';

export type ValueOrAttributeDateSelectorProps = {
  readonly arg: ValueOrAttributeDateForm;
  readonly rootArg: AttributeValueDate;
  readonly argIndex: number;
  readonly onChangeValueOrAttribute: (type: ValueOrAttributeType, index: number) => void;
  readonly onChangeManualValue: (value: Date | null, index: number) => void;
  readonly onChangeAttributeValue: (attribute: AttributeValue, index: number) => void;
  readonly onChangeOptionTransform: (transform: DateTransform | null, index: number) => void;
  readonly onChangeOptionTransformValue: (value: string, index: number) => void;
  readonly valueOrAttributeOptions: RadioButtonOptionShape<ValueOrAttributeType>[];
};

export const ValueOrAttributeDateSelector = memo(function FieldDateSelector({
  arg,
  rootArg,
  argIndex,
  valueOrAttributeOptions,
  onChangeValueOrAttribute,
  onChangeManualValue,
  onChangeAttributeValue,
  onChangeOptionTransform,
  onChangeOptionTransformValue,
}: ValueOrAttributeDateSelectorProps) {
  const intl = useIntl();
  const { customObjectDefinition } = useFormulaBuilderContext();

  const DATE_ATTRIBUTES_OPTIONS_FILTERS: UseAttributesOptionsOptions = useMemo(
    () => ({
      filterProperty: (property) =>
        property.format === FormatsEnum.date &&
        (!isFieldOrPropertyTokenType(rootArg) || property.machineName !== rootArg.propertyMachineName),
      filterVariable: (variable) =>
        variable.format === FormatsEnum.date &&
        (isFieldOrPropertyTokenType(rootArg)
          ? variable.machineName !== rootArg.propertyMachineName
          : isQuotaOrVariableTokenType(rootArg)
            ? variable.machineName !== rootArg.machineName
            : true),
      keywords: Object.values(FormulaKeyword).filter((key) => FORMULA_KEYWORDS[key].format === FormatsEnum.date),
      showFilteredAsDisabled: false,
    }),
    [rootArg],
  );

  const dateTransformerOptions = useDateTransformerOptions();

  const handleCheckDateTransform: Required<SwitchProps>['onChange'] = useCallback(
    (checked) => {
      onChangeOptionTransform(checked ? DateTransform.DAYS_AGO : null, argIndex);
    },
    [onChangeOptionTransform, argIndex],
  );

  const handleChangeValueOrAttribute: Required<RadioButtonGroupProps<ValueOrAttributeType>>['onChange'] = useCallback(
    (value) => onChangeValueOrAttribute(value, argIndex),
    [argIndex, onChangeValueOrAttribute],
  );

  const handleChangeManualValueProxy: DatePickerProps['onChange'] = useCallback(
    (value) => onChangeManualValue(value, argIndex),
    [argIndex, onChangeManualValue],
  );

  const handleChangeOptionTransform: Required<RadioButtonGroupProps<DateTransform>>['onChange'] = useCallback(
    (value) => onChangeOptionTransform(value, argIndex),
    [argIndex, onChangeOptionTransform],
  );

  const handleChangeOptionTransformValue: Required<InputProps>['onChange'] = useCallback(
    (value) => onChangeOptionTransformValue(value, argIndex),
    [argIndex, onChangeOptionTransformValue],
  );

  const handleChangeAttributeValue: AttributeSelectorProps['onChange'] = useCallback(
    (attribute) => onChangeAttributeValue(attribute, argIndex),
    [argIndex, onChangeAttributeValue],
  );

  const attributesOptions = useAttributesOptions(DATE_ATTRIBUTES_OPTIONS_FILTERS);

  return (
    <Fragment>
      <div>
        <RadioButtonGroup
          name={`value-or-field-arg${argIndex}`}
          options={valueOrAttributeOptions}
          size={RadioButtonGroup.Size.SMALL}
          value={arg.type}
          onChange={handleChangeValueOrAttribute}
        />
      </div>

      {arg.type === ValueOrAttributeType.VALUE && (
        <div css={styles.buttonSelectField}>
          <DatePicker
            placeholder={intl.formatMessage(functionDatePopoverMessages.MANUAL_VALUE_PLACEHOLDER)}
            portalId={null}
            size={DatePicker.Size.SMALL}
            value={arg.value ? toDate(arg.value) : null}
            onChange={handleChangeManualValueProxy}
          />
        </div>
      )}

      {arg.type === ValueOrAttributeType.ATTRIBUTE && customObjectDefinition ? (
        <Fragment>
          <AttributeSelector
            options={attributesOptions}
            selectedAttribute={arg}
            onChange={handleChangeAttributeValue}
          />

          <Switch
            checked={!!arg.options.transform}
            label={<FormattedMessage {...functionDatePopoverMessages.SHIFT_DATE} />}
            size={Switch.Size.SMALL}
            onChange={handleCheckDateTransform}
          />

          {arg.options.transform !== null && (
            <div css={styles.dateTransformerContainer}>
              <div css={styles.dateTransformerInput}>
                <Input
                  placeholder={intl.formatMessage(functionDatePopoverMessages.DELAY_PLACEHOLDER)}
                  size={Input.Size.SMALL}
                  type="number"
                  value={getOptionTransformValue(arg.options.value)}
                  onChange={handleChangeOptionTransformValue}
                />
              </div>
              <div>
                <RadioButtonGroup
                  name={`date-transformer-arg${argIndex}`}
                  options={dateTransformerOptions}
                  size={RadioButtonGroup.Size.SMALL}
                  value={arg.options.transform}
                  onChange={handleChangeOptionTransform}
                />
              </div>
            </div>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
});
