import { last } from 'lodash';

const TICK_LEVELS = 4;

/**
 * Given a min and max, returns:
 *   - The tick size, aka the width between two ticks
 *   - The number of ticks to display above 0
 *   - The number of ticks to display below 0
 *
 * @param min
 * @param max
 */
const getTickSizeAndRange = (min: number | null, max: number | null) => {
  if (min === null || max === null) {
    return null;
  }

  // Getting the "biggest" value, either positive or negative. It'll give us the "zoom level" of the graph.
  const absoluteMax = Math.max(Math.abs(min), Math.abs(max));

  // Log10 gives us the power of 10 to use in our grid.
  // Example maxAmount = 244000.
  // logScale = 5
  const logScale = Math.floor(Math.log10(absoluteMax));

  // Find the nearest number multiple of 4 on the same log scale as max: this will be our max threshold.
  // Example: 244000 => 250000
  // Example: 500000 => 500000
  // Example: 650000 => 750000
  const maxThreshold = Math.ceil(absoluteMax / 10 ** logScale) * 10 ** logScale;
  const tickSize = maxThreshold / TICK_LEVELS;

  // Given the tickSize and the min/max, we get the number of ticks above and below 0.
  const positiveRange = Math.ceil(Math.max(0, max) / tickSize);
  const negativeRange = Math.ceil(-Math.min(0, min) / tickSize);

  return { tickSize, positiveRange, negativeRange };
};

/**
 * Generate the tick array given tick size and number of ticks to display above and below zero.
 *
 * @param negativeRange
 * @param positiveRange
 * @param tickSize
 */
const buildTickArray = (negativeRange: number, positiveRange: number, tickSize: number) =>
  [
    ...Array.from({ length: negativeRange }, (_, i) => -(i + 1) * tickSize).toReversed(),
    0,
    ...Array.from({ length: positiveRange }, (_, i) => (i + 1) * tickSize),
  ].map((v) => +v.toFixed(2));

/**
 * Given min and max of left and right collections, compute the domains and the ticks for each Y axis.
 *
 * We have to compute them together, because the 0 has to be the same on each axis. To do so:
 *   - First we get the tick size of each collection, based on the min and max and given that
 *     we want to display TICK_LEVELS ticks per side max.
 *   - Then we can compute the number of ticks we have to display above and below 0 for each collection
 *   - We take the max of each collection (for instance it'll give us 4 ticks above 0 and 2 ticks under 0)
 *   - Finally we build the ticks array of each collection, making sure we keep the 0 at the same index.
 *
 * @param leftMin
 * @param leftMax
 * @param rightMin
 * @param rightMax
 */
export const getDomainAndTicks = (
  leftMin: number | null,
  leftMax: number | null,
  rightMin: number | null,
  rightMax: number | null,
) => {
  const left = getTickSizeAndRange(leftMin, leftMax);
  const right = getTickSizeAndRange(rightMin, rightMax);

  const negativeRange = Math.max(left?.negativeRange || 0, right?.negativeRange || 0);
  const positiveRange = Math.max(left?.positiveRange || 0, right?.positiveRange || 0);

  const leftTicks = left && buildTickArray(negativeRange, positiveRange, left.tickSize);
  const rightTicks = right && buildTickArray(negativeRange, positiveRange, right.tickSize);

  return {
    left: left && {
      ticks: leftTicks,
      domain: [leftTicks[0], last(leftTicks)],
    },
    right: right && {
      ticks: rightTicks,
      domain: [rightTicks[0], last(rightTicks)],
    },
  };
};
