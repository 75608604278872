import { useFormikContext } from 'formik';
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { DatePicker, Input } from '@amal-ia/lib-ui';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { OverwriteFormField, type OverwriteFormValuesShape } from '../../overwrite.types';

import { messages } from './selectors.messages';
import { type CommonOverwriteSelectorProps } from './selectors.types';

export type DateTimeOverwriteSelectorProps = CommonOverwriteSelectorProps & {
  readonly currentUser: UserContract;
};

export const DateTimeOverwriteSelector = memo(function DateTimeOverwriteSelector({
  currentUser,
}: DateTimeOverwriteSelectorProps) {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext<OverwriteFormValuesShape>();

  const handleChange = useCallback(
    (date: Date) => setFieldValue(OverwriteFormField.NEW_VALUE, moment(date).format('YYYY-MM-DDTHH:mm:s1.SSS+0000')),
    [setFieldValue],
  );

  return (
    <DatePicker
      autoFocus
      ariaLabelledBy={OverwriteFormField.NEW_VALUE}
      customInput={<Input />}
      id={OverwriteFormField.NEW_VALUE}
      language={currentUser.language}
      name={OverwriteFormField.NEW_VALUE}
      placeholderText={formatMessage(messages[FormatsEnum['date-time']])}
      popperProps={{ positionFixed: true }}
      value={values.newValue}
      onChange={handleChange}
    />
  );
});
