import { useAuth0 } from '@auth0/auth0-react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment, memo, type ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { amaliaTheme, type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Button, Paper } from '@amal-ia/frontend/design-system/components';
import { config } from '@amal-ia/frontend/kernel/config';
import { DrawingError, DrawingMaintenance, LayoutAnonymous, Text, TextType } from '@amal-ia/lib-ui';

const useStyle = makeStyles((theme: AmaliaThemeType) => ({
  textError: {
    color: theme.palette.error.main,
  },
  textWarning: {
    color: theme.palette.warning.main,
  },
}));

interface AuthenticationErrorProps {
  readonly message: ReactNode | string;
  readonly isMaintenance?: boolean;
  readonly footer?: ReactNode | string;
}

export const AuthenticationError = memo(function AuthenticationError({
  message,
  isMaintenance,
  footer,
}: AuthenticationErrorProps) {
  const classes = useStyle();
  const { logout } = useAuth0();
  const logoutProxy = useCallback(() => logout({ logoutParams: { returnTo: config.auth0.redirectUri } }), [logout]);
  return (
    <LayoutAnonymous>
      <Box mt={amaliaTheme.spacing(8)}>
        <Container maxWidth="sm">
          <Paper>
            <Box
              margin="0 auto"
              p={amaliaTheme.spacing(8)}
              textAlign="center"
            >
              {isMaintenance ? (
                <Text
                  className={classes.textWarning}
                  type={TextType.PAGE_TITLE}
                >
                  <span style={{ width: '100%' }}>
                    <FormattedMessage defaultMessage="Amalia is offline" />
                  </span>
                </Text>
              ) : (
                <Text
                  className={classes.textError}
                  type={TextType.PAGE_TITLE}
                >
                  <span style={{ width: '100%' }}>
                    <FormattedMessage defaultMessage="Authentication error" />
                  </span>
                </Text>
              )}
              <p>{message}</p>
              {isMaintenance ? (
                <DrawingMaintenance />
              ) : (
                <Fragment>
                  <DrawingError />
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <Button onClick={logoutProxy}>
                      <FormattedMessage defaultMessage="Try again" />
                    </Button>
                  </Box>
                </Fragment>
              )}
              {footer ? <p>{footer}</p> : null}
            </Box>
          </Paper>
        </Container>
      </Box>
    </LayoutAnonymous>
  );
});
