import { memo, type ReactNode } from 'react';

import { useFetchAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import {
  AmaliaLocalizationProvider,
  type AmaliaLocalizationProviderProps,
} from '@amal-ia/frontend/kernel/intl/context';
import { type LocaleBundle } from '@amal-ia/frontend/kernel/intl/types';
import { type LanguagesEnum } from '@amal-ia/kernel/intl/types';

export type WebLocalizationProviderProps = {
  readonly children: ReactNode;
};

const importLocale: AmaliaLocalizationProviderProps<LanguagesEnum>['importLocale'] = async (locale) =>
  ((await import(`../assets/i18n/${locale}`)) as { bundle: LocaleBundle<LanguagesEnum> }).bundle;

export const WebLocalizationProvider = memo(function WebLocalizationProvider({
  children,
}: WebLocalizationProviderProps) {
  // Cannot use useCurrentUser because this provider is above the AuthContext.
  const { authenticatedContext } = useFetchAuthenticatedContext();

  return (
    <AmaliaLocalizationProvider
      importLocale={importLocale}
      locale={authenticatedContext?.user?.language}
    >
      {children}
    </AmaliaLocalizationProvider>
  );
});
