import { get, noop } from 'lodash';
import { cloneElement, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type FormulaBuilderFunctionBlockDateForm,
  isFormulaBuilderFunctionBlockDateNoArgsForm,
  isFormulaBuilderFunctionBlockDateOneArgForm,
} from '@amal-ia/amalia-lang/formula/form';
import { FormulaBuilderDateOperatorNoArgs, ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { Dropdown, Tooltip } from '@amal-ia/frontend/design-system/components';
import { toDate } from '@amal-ia/kernel/dates';
import { formatDate } from '@amal-ia/lib-types';

import { useGetFormulaBuilderAttributeLabel } from '../../hooks/use-get-formula-builder-attribute-label/useGetFormulaBuilderAttributeLabel';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';
import { FormulaConditionTag, type FormulaConditionTagProps } from '../formula-condition-tag/FormulaConditionTag';
import { valueOrAttributeValuesMessages } from '../formula-condition-tag-function-string/FormulaConditionTagFunctionString.messages';

import {
  formulaConditionTagFunctionDateMessages,
  operatorTag,
  operatorTooltipMessage,
  transformTags,
} from './FormulaConditionTagFunctionDate.messages';
import * as styles from './FormulaConditionTagFunctionDate.styles';
import { FunctionDatePopover } from './function-date-popover/FunctionDatePopover';
import { OPERATOR_ICON_MAPPING } from './function-date-popover/operator.mappers';

export type FormulaConditionTagFunctionDateProps = {
  readonly condition: FormulaBuilderFunctionBlockDateForm;
  readonly path: string;
  readonly onChange?: FormulaConditionTagProps<FormulaBuilderFunctionBlockDateForm>['onChange'];
  readonly onDelete?: FormulaConditionTagProps<FormulaBuilderFunctionBlockDateForm>['onDelete'];
};

export const FormulaConditionTagFunctionDate = memo(function FormulaConditionTagFunctionDate({
  condition,
  path,
  onChange = noop,
  onDelete = undefined,
}: FormulaConditionTagFunctionDateProps) {
  const { activeConditionId, errors: builderErrors } = useFormulaBuilderContext();
  const isActive = condition.id === activeConditionId;
  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();

  const isInitialOpen = !!condition.isDraft && isActive;
  const { isOpen, setOpen, setOpenFalse } = useBoolState(isInitialOpen, 'open');

  const errors: string[] = useMemo(
    () =>
      [
        get(builderErrors, `${path}.args[1].value`) as string | undefined,
        get(builderErrors, `${path}.args[1].fieldType`) as string | undefined,
        get(builderErrors, `${path}.args[1].options.transform`) as string | undefined,
        get(builderErrors, `${path}.args[1].options.value`) as string | undefined,
        get(builderErrors, `${path}.args[2].value`) as string | undefined,
        get(builderErrors, `${path}.args[2].fieldType`) as string | undefined,
        get(builderErrors, `${path}.args[2].options.transform`) as string | undefined,
        get(builderErrors, `${path}.args[2].options.value`) as string | undefined,
      ].filter(Boolean),
    [builderErrors, path],
  );

  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);
  const getTooltipContent = () => {
    if (!condition.operator) {
      return <FormattedMessage {...formulaConditionTagFunctionDateMessages.CLICK_TO_EDIT_CONDITION} />;
    }

    if (isFormulaBuilderFunctionBlockDateNoArgsForm(condition)) {
      return <FormattedMessage {...operatorTooltipMessage[condition.operator]} />;
    }

    if (isFormulaBuilderFunctionBlockDateOneArgForm(condition)) {
      return (
        <FormattedMessage
          {...formulaConditionTagFunctionDateMessages.SINGLE_OPERATOR}
          values={{
            operator: <FormattedMessage {...operatorTooltipMessage[condition.operator]} />,
            value: (
              <FormattedMessage
                {...valueOrAttributeValuesMessages[condition.args[1].type]}
                values={{
                  value:
                    condition.args[1].type === ValueOrAttributeType.VALUE
                      ? condition.args[1].value
                        ? formatDate(toDate(condition.args[1].value), 'L')
                        : ''
                      : getFormulaBuilderAttributeLabel(condition.args[1]),
                }}
              />
            ),
          }}
        />
      );
    }

    return (
      <FormattedMessage
        {...formulaConditionTagFunctionDateMessages.DOUBLE_OPERATOR}
        values={{
          operator: <FormattedMessage {...operatorTooltipMessage[condition.operator]} />,
          value1: (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[1].type]}
              values={{
                value:
                  condition.args[1].type === ValueOrAttributeType.VALUE
                    ? condition.args[1].value
                      ? formatDate(toDate(condition.args[1].value), 'L')
                      : ''
                    : getFormulaBuilderAttributeLabel(condition.args[1]),
              }}
            />
          ),
          value2: (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[2].type]}
              values={{
                value:
                  condition.args[2].type === ValueOrAttributeType.VALUE
                    ? condition.args[2].value
                      ? formatDate(toDate(condition.args[2].value), 'L')
                      : ''
                    : getFormulaBuilderAttributeLabel(condition.args[2]),
              }}
            />
          ),
        }}
      />
    );
  };

  const getTagLabel = () => {
    if (!condition.operator) {
      return propertyName;
    }
    if (
      [FormulaBuilderDateOperatorNoArgs.IS_BLANK, FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK].includes(
        condition.operator,
      )
    ) {
      return (
        <div css={styles.tagLabelContainer}>
          {propertyName}
          {cloneElement(OPERATOR_ICON_MAPPING[condition.operator], {
            size: 16,
          })}
        </div>
      );
    }
    if (isFormulaBuilderFunctionBlockDateNoArgsForm(condition)) {
      return <FormattedMessage {...operatorTag[condition.operator]} />;
    }

    if (isFormulaBuilderFunctionBlockDateOneArgForm(condition)) {
      return (
        <FormattedMessage
          {...operatorTag[condition.operator]}
          values={{
            propertyName,
            dateTransform:
              condition.args[1].type === ValueOrAttributeType.ATTRIBUTE && !!condition.args[1].options.transform ? (
                <FormattedMessage
                  {...transformTags[condition.args[1].options.transform]}
                  values={{
                    operator: condition.args[1].options.value && condition.args[1].options.value > 0 ? '-' : '+',
                    value: condition.args[1].options.value && Math.abs(condition.args[1].options.value),
                    hasTransform: !!condition.args[1].options.value,
                  }}
                />
              ) : null,
            hasValue:
              condition.args[1].type === ValueOrAttributeType.VALUE
                ? !!condition.args[1].value
                : !!getFormulaBuilderAttributeLabel(condition.args[1]),
            value: (
              <FormattedMessage
                {...valueOrAttributeValuesMessages[condition.args[1].type]}
                values={{
                  value:
                    condition.args[1].type === ValueOrAttributeType.VALUE
                      ? condition.args[1].value
                        ? formatDate(toDate(condition.args[1].value), 'L')
                        : ''
                      : getFormulaBuilderAttributeLabel(condition.args[1]),
                }}
              />
            ),
          }}
        />
      );
    }

    return (
      <FormattedMessage
        {...operatorTag[condition.operator]}
        values={{
          propertyName,
          hasValue:
            condition.args[1].type === ValueOrAttributeType.VALUE
              ? !!condition.args[1].value
              : !!getFormulaBuilderAttributeLabel(condition.args[1]),
          value: (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[1].type]}
              values={{
                value:
                  condition.args[1].type === ValueOrAttributeType.VALUE
                    ? condition.args[1].value
                      ? formatDate(toDate(condition.args[1].value), 'L')
                      : ''
                    : getFormulaBuilderAttributeLabel(condition.args[1]),
              }}
            />
          ),
          dateTransform:
            condition.args[1].type === ValueOrAttributeType.ATTRIBUTE && !!condition.args[1].options.transform ? (
              <FormattedMessage
                {...transformTags[condition.args[1].options.transform]}
                values={{
                  operator: condition.args[1].options.value && condition.args[1].options.value > 0 ? '-' : '+',
                  value: condition.args[1].options.value && Math.abs(condition.args[1].options.value),
                  hasTransform: !!condition.args[1].options.value,
                }}
              />
            ) : null,
          hasValue2:
            condition.args[2].type === ValueOrAttributeType.VALUE
              ? !!condition.args[2].value
              : !!getFormulaBuilderAttributeLabel(condition.args[2]),
          value2: (
            <FormattedMessage
              {...valueOrAttributeValuesMessages[condition.args[2].type]}
              values={{
                value:
                  condition.args[2].type === ValueOrAttributeType.VALUE
                    ? condition.args[2].value
                      ? formatDate(toDate(condition.args[2].value), 'L')
                      : ''
                    : getFormulaBuilderAttributeLabel(condition.args[2]),
              }}
            />
          ),
          dateTransform2:
            condition.args[2].type === ValueOrAttributeType.ATTRIBUTE && !!condition.args[2].options.transform ? (
              <FormattedMessage
                {...transformTags[condition.args[2].options.transform]}
                values={{
                  operator: condition.args[2].options.value && condition.args[2].options.value > 0 ? '-' : '+',
                  value: condition.args[2].options.value && Math.abs(condition.args[2].options.value),
                  hasTransform: !!condition.args[2].options.value,
                }}
              />
            ) : null,
        }}
      />
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      content={
        <FunctionDatePopover
          condition={condition}
          onChange={onChange}
          onClose={setOpenFalse}
        />
      }
      onChangeIsOpen={setOpen}
    >
      <FormulaConditionTag
        condition={condition}
        errors={errors}
        label={
          <Tooltip
            content={getTooltipContent()}
            title={
              propertyName ? (
                <FormattedMessage
                  {...formulaConditionTagFunctionDateMessages.FILTER_ON}
                  values={{ propertyName }}
                />
              ) : null
            }
          >
            <span>{getTagLabel()}</span>
          </Tooltip>
        }
        onDelete={onDelete}
      />
    </Dropdown>
  );
});
