import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { type FiltersMap } from '@amal-ia/compensation-definition/plans/types';
import { type RootState } from '@amal-ia/lib-types';

export const selectFiltersMap = (state: RootState): FiltersMap => state.filters.map;

export const selectFiltersList = createSelector([selectFiltersMap], (map) => Object.values(map));

export const selectFiltersByMachineNameMap = createSelector([selectFiltersMap], (filtersMap: FiltersMap) =>
  keyBy(Object.values(filtersMap), 'machineName'),
);

export const selectFiltersIsLoading = (state: RootState): boolean => !!state.filters.isLoading;
