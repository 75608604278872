import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ActionsEnum, SubjectsEnum } from '@amal-ia/lib-rbac';

const AuditContainer = lazy(() => import('./AuditContainer'));

export const AuditRoutes = memo(function AuditRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="audit">
      <RootRoutes>
        <Route
          path={routes.AUDIT}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Audit)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Audit' })} />
              <AuditContainer />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
