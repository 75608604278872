import { keyBy } from 'lodash';
import { combineReducers } from 'redux';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { type Statement, type StatementForecast } from '@amal-ia/lib-types';

import {
  applyOverwriteToComputedStatement,
  removeOverwriteFromComputedStatement,
} from '../../services/statements/statements.service';
import { PLANS_ACTIONS as PLAN_ACTIONS } from '../plans/constants';

import { STATEMENTS_ACTIONS } from './constants';

export default combineReducers({
  isLoading: (state: number = 0, action): number => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.START:
        return state + 1;
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
      case STATEMENTS_ACTIONS.SET_CURRENT_STATEMENT_THREADS:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREADS_LIST:
      case STATEMENTS_ACTIONS.CREATE_STATEMENT_THREAD:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_MESSAGES:
      case STATEMENTS_ACTIONS.ADD_STATEMENT_THREAD_MESSAGE:
      case STATEMENTS_ACTIONS.SET_STATEMENT_THREAD_REVIEWED:
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS:
      case STATEMENTS_ACTIONS.CREATE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.DELETE_ADJUSTMENT:
      case STATEMENTS_ACTIONS.EDIT_ADJUSTMENT:
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
      case STATEMENTS_ACTIONS.CREATE_OVERWRITE:
      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE:
      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE:
      case STATEMENTS_ACTIONS.ERROR:
        return state - 1;
      default:
        return state;
    }
  },
  lastparams: (state: Record<string, any> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return action.payload.options;
      default:
        return state;
    }
  },
  map: (state: Record<string, Statement> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
      case STATEMENTS_ACTIONS.CLEAR_STATEMENTS:
        return {};
      case STATEMENTS_ACTIONS.SET_STATEMENTS_SUCCESS:
        return keyBy(action.payload.statements, 'id');
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP:
        return {
          ...state,
          [action.payload.statementId]: {
            ...state[action.payload.statementId],
            workflowSteps: action.payload.updatedStatement.workflowSteps,
            workflowComplete: action.payload.updatedStatement.workflowComplete,
          },
        };
      default:
        return state;
    }
  },
  userStatements: (state: Record<string, Statement> = {}, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return {};
      case STATEMENTS_ACTIONS.SET_USER_STATEMENTS_SUCCESS:
        return keyBy(action.payload.userStatements, 'id');
      default:
        return state;
    }
  },
  currentPeriod: (state: Period | null = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_CURRENT_PERIOD_SUCCESS: {
        if (action.payload.currentPeriod?.id === state?.id) {
          return state;
        }
        return action.payload.currentPeriod;
      }
      default:
        return state;
    }
  },
  currentStatement: (state: Statement | null = null, action) => {
    switch (action.type) {
      // Reset reducer when plan config or workflow changes
      case PLAN_ACTIONS.SET_SETTINGS:
        return null;
      case STATEMENTS_ACTIONS.SET_STATEMENT_SUCCESS:
        return action.payload.statement;
      case STATEMENTS_ACTIONS.CLEAR_STATEMENT:
        return null;
      case STATEMENTS_ACTIONS.PROCESS_WORKFLOW_STEP: {
        return {
          ...state,
          workflowSteps: action.payload.updatedStatement.workflowSteps,
          workflowComplete: action.payload.updatedStatement.workflowComplete,
        };
      }
      case STATEMENTS_ACTIONS.CREATE_OVERWRITE:
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      case STATEMENTS_ACTIONS.CLEAR_OVERWRITE:
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };

      case STATEMENTS_ACTIONS.CREATE_ADJUSTMENT:
        return state?.id === action.payload.statementId
          ? {
              ...state,
              adjustments: [...state.adjustments, action.payload.adjustment],
            }
          : state;
      case STATEMENTS_ACTIONS.DELETE_ADJUSTMENT:
        return state?.id === action.payload.statementId
          ? {
              ...state,
              adjustments: state.adjustments.filter((adjustment) => adjustment.id !== action.payload.adjustmentId),
            }
          : state;
      case STATEMENTS_ACTIONS.EDIT_ADJUSTMENT: {
        return state?.id === action.payload.statementId
          ? {
              ...state,
              adjustments: state.adjustments.map((adjustment) =>
                adjustment.id === action.payload.adjustment.id ? action.payload.adjustment : adjustment,
              ),
            }
          : state;
      }
      default:
        return state;
    }
  },
  currentForecastedStatement: (state: StatementForecast | null = null, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.SET_FORECASTED_STATEMENT_SUCCESS:
        return action.payload.forecastedStatement;
      case STATEMENTS_ACTIONS.CREATE_SIMULATED_OVERWRITE:
        return {
          ...state,
          results: applyOverwriteToComputedStatement(state.results, action.payload.overwrite),
        };
      case STATEMENTS_ACTIONS.CLEAR_SIMULATED_OVERWRITE:
        return {
          ...state,
          results: removeOverwriteFromComputedStatement(state.results, action.payload.overwrite),
        };
      default:
        return state;
    }
  },
  listFilters: (state: { planId: string; teamId: string } = { planId: '', teamId: '' }, action) => {
    switch (action.type) {
      case STATEMENTS_ACTIONS.CHANGE_LIST_FILTERS:
        return {
          ...state,
          [action.payload.filterType]: action.payload.newFilter,
        };
      default:
        return state;
    }
  },
});
