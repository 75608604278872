import { useTheme } from '@emotion/react';
import { IconExclamationCircle, IconX } from '@tabler/icons-react';
import clsx from 'clsx';
import { isNil } from 'lodash';
import {
  type ReactNode,
  memo,
  useCallback,
  forwardRef,
  type ForwardedRef,
  type ReactElement,
  type ComponentPropsWithoutRef,
} from 'react';
import { useFocusRing } from 'react-aria';
import { useIntl } from 'react-intl';

import { useHover } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { Tooltip } from '../../overlays/tooltip/Tooltip';
import { CountBadge } from '../count-badge/CountBadge';
import { CountBadgeSize, CountBadgeVariant } from '../count-badge/CountBadge.types';
import { IconAction } from '../icon-action/IconAction';
import { Typography } from '../typography/Typography';
import { UnstyledButton } from '../unstyled-button/UnstyledButton';

import * as styles from './Tag.styles';
import { tagTestIds } from './Tag.testIds';
import { type TagValue } from './Tag.types';

export type TagProps<TValue extends TagValue> = MergeAll<
  [
    ComponentPropsWithoutRef<'div'>,
    {
      /** Tag label. */
      children: ReactNode;
      /** Value passed to the callbacks. */
      value?: TValue;
      /** Is the tag in draft status. Takes precedence over isActive. */
      isDraft?: boolean;
      /** Is the tag active. */
      isActive?: boolean;
      /** Error message if any. */
      error?: ReactNode;
      /** Tooltip for the delete button when onDelete is defined. */
      deleteTooltip?: string;
      /** Is the tag disabled. Disables onClick and onDelete. */
      disabled?: boolean;
      /** Optional count. */
      count?: number;
      /** Show the count badge in an active state (useful for filters for example, it's neutral when nothing is selected, and active when something is selected). */
      isCountActive?: boolean;
      /** Label click callback. */
      onClick?: TValue extends undefined ? () => void : (value: TValue) => void;
      /** Delete button click callback. */
      onDelete?: TValue extends undefined ? () => void : (value: TValue) => void;
    },
  ]
>;

const TagForwardRef = forwardRef(function Tag<TValue extends TagValue>(
  {
    children,
    value = undefined,
    onClick = undefined,
    onDelete = undefined,
    isDraft = false,
    isActive = false,
    error = null,
    deleteTooltip = undefined,
    disabled = false,
    count = undefined,
    isCountActive = false,
    ...props
  }: TagProps<TValue>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const theme = useTheme();
  const intl = useIntl();

  const [isLabelHovered, hoverHandlers] = useHover<HTMLButtonElement>();
  const { isFocusVisible, focusProps } = useFocusRing();

  const handleClickDelete = useCallback(() => onDelete?.(value), [onDelete, value]);
  const handleClickLabel = useCallback(() => onClick?.(value), [onClick, value]);

  const hasCount = !isNil(count);

  return (
    <div
      {...props}
      ref={ref}
      css={styles.tag}
      className={clsx(props.className, {
        [styles.DISABLED_CLASSNAME]: disabled,
        [styles.HAS_REMOVE_BUTTON_CLASSNAME]: !!onDelete,
        [styles.HAS_ERROR_CLASSNAME]: !!error,
        [styles.HAS_BADGE_CLASSNAME]: hasCount,
        [styles.IS_LABEL_HOVERED_CLASSNAME]: isLabelHovered,
        [styles.IS_ACTIVE_CLASSNAME]: isActive,
        [styles.IS_DRAFT_CLASSNAME]: isDraft,
        [styles.IS_FOCUS_VISIBLE_CLASSNAME]: isFocusVisible,
      })}
    >
      {!!error && (
        <div css={styles.error}>
          <Tooltip content={error}>
            <IconExclamationCircle
              color={disabled ? 'currentColor' : theme.ds.colors.danger[500]}
              data-testid={tagTestIds.errorAnchor}
              size={14}
            />
          </Tooltip>
        </div>
      )}

      {!!hasCount && (
        <CountBadge
          disabled={disabled || count === 0}
          size={CountBadgeSize.SMALL}
          variant={isCountActive ? CountBadgeVariant.PURPLE : CountBadgeVariant.NEUTRAL}
          withStroke={isActive || isLabelHovered}
        >
          {count}
        </CountBadge>
      )}

      <Typography
        {...hoverHandlers}
        {...focusProps}
        as={UnstyledButton}
        css={styles.label}
        data-testid={props.id ? tagTestIds.label(props.id) : undefined}
        disabled={disabled}
        type="button"
        variant={Typography.Variant.BODY_SMALL_MEDIUM}
        onClick={handleClickLabel}
      >
        {children}
      </Typography>

      {!!onDelete && (
        <IconAction
          disabled={disabled}
          icon={<IconX />}
          label={deleteTooltip ?? intl.formatMessage({ defaultMessage: 'Delete' })}
          size={IconAction.Size.XSMALL}
          variant={IconAction.Variant.DANGER}
          onClick={handleClickDelete}
        />
      )}
    </div>
  );
});

export const Tag = memo(TagForwardRef) as <TValue extends TagValue>(
  props: TagProps<TValue> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReactElement | null;
