import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from 'lodash';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type TokenType } from '@amal-ia/lib-types';

import { designerObjectsDetails } from '../../../utils/designer';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  root: {
    flex: '0 0 2rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 .25rem',
    alignItems: 'center',
  },
  labelContainer: {
    flex: 1,
    marginLeft: '.5rem',
    width: '1.5rem',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '.75rem',
    border: `${theme.spacing(0.2)} solid transparent`,
    minHeight: '5rem',
  },
  label: {
    height: '1rem',
    transform: 'rotate(-90deg)',
    alignSelf: 'center',
    opacity: 0.6,
  },
}));

interface DesignerElementBadgeProps {
  readonly type: TokenType;
}

export const DesignerElementBadge = memo(function DesignerElementBadge({ type }: DesignerElementBadgeProps) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box
        className={classes.labelContainer}
        style={{
          backgroundColor: designerObjectsDetails[type]?.color,
        }}
      >
        <Box className={classes.label}>{capitalize(type)}</Box>
      </Box>
    </Box>
  );
});
