import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.toNumber, AmaliaFunctionCategory.STRING);

func.nbParamsRequired = 1;

func.description = 'Convert a string to a number';

func.params = [{ name: 'string', description: 'String: variables, fields, properties, string' }];

func.examples = [
  {
    desc: 'Returns 5',
    formula: 'toNumber("5")' as AmaliaFormula,
    result: 5,
  },
  {
    desc: 'Returns 3.32',
    formula: 'toNumber("3.32")' as AmaliaFormula,
    result: 3.32,
  },
  {
    desc: 'Returns -12',
    formula: 'toNumber("-12")' as AmaliaFormula,
    result: -12,
  },
  {
    desc: 'Returns 0 when string cannot be converted to number',
    formula: 'toNumber("hello")' as AmaliaFormula,
    result: 0,
  },
];

/**
 * Convert string into a number.
 * @param stringToConvert
 */
func.exec = (stringToConvert: string): number | null => {
  const number = Number(stringToConvert);

  return Number.isNaN(number) ? 0 : number;
};

export default func;
