import { ClassNames } from '@emotion/react';
import { IconExclamationCircle } from '@tabler/icons-react';
import { type ReactNode, memo } from 'react';

import { TypographyVariant } from '@amal-ia/frontend/design-system/meta';

import { Typography } from '../../../general/typography/Typography';
import { FieldSize } from '../types';

import * as styles from './FormFieldError.styles';

const SIZE_TYPOGRAPHY_MAPPING: Record<FieldSize, TypographyVariant> = {
  [FieldSize.SMALL]: TypographyVariant.BODY_XSMALL_MEDIUM,
  [FieldSize.MEDIUM]: TypographyVariant.BODY_SMALL_MEDIUM,
  [FieldSize.LARGE]: TypographyVariant.BODY_BASE_MEDIUM,
};

const SIZE_ICON_SIZE_MAPPING: Record<FieldSize, number> = {
  [FieldSize.SMALL]: 14,
  [FieldSize.MEDIUM]: 16,
  [FieldSize.LARGE]: 18,
};

export type FormFieldErrorProps = {
  /** Field size. */
  readonly size?: FieldSize;
  /** Error message. */
  readonly children: ReactNode;
};

export const FormFieldError = memo(function FormFieldError({ size = FieldSize.MEDIUM, children }: FormFieldErrorProps) {
  return (
    <div
      className={size}
      css={styles.error}
    >
      <ClassNames>
        {({ css, theme }) => (
          <IconExclamationCircle
            color={theme.ds.colors.danger[500]}
            size={SIZE_ICON_SIZE_MAPPING[size]}
            className={css`
              flex: none;
            `}
          />
        )}
      </ClassNames>

      <Typography
        css={styles.text}
        variant={SIZE_TYPOGRAPHY_MAPPING[size]}
      >
        {children}
      </Typography>
    </div>
  );
});
