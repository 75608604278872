import { css } from '@emotion/react';
import { memo } from 'react';

import { type UserHrisIdSource } from '@amal-ia/tenants/users/shared/types';

import { UserHrisIdSourceLabel } from './label';
import { UserHrisIdSourceLogo } from './logo';

export type UserHrisIdSourceItemProps = {
  readonly hrisIdSource: UserHrisIdSource;
};

export const UserHrisIdSourceItem = memo(function UserHrisIdSourceItem({ hrisIdSource }: UserHrisIdSourceItemProps) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;

        gap: 6px;
      `}
    >
      <UserHrisIdSourceLogo hrisIdSource={hrisIdSource} />
      <UserHrisIdSourceLabel hrisIdSource={hrisIdSource} />
    </span>
  );
});
