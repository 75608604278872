import { css, type Theme } from '@emotion/react';

export const FORCE_SHOW_CLASSNAME = 'force-show';

export const kpiCardItem = (theme: Theme) => css`
  border: 1px solid ${theme.ds.colors.gray[100]};
  border-radius: ${theme.ds.borderRadiuses.squared};
  background-color: ${theme.ds.colors.gray[0]};
  padding: 8px 16px;

  transition: ${theme.ds.transitions.default('background-color')};

  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 90px;
  height: 100%;
`;

export const forecasted = (theme: Theme) => css`
  background-color: ${theme.ds.hues.blue[50]};
`;

export const overwritten = (theme: Theme) => css`
  color: ${theme.ds.colors.secondary[500]};
`;

export const kpiName = (theme: Theme) => css`
  color: ${theme.ds.colors.gray[600]};
`;

export const footer = css`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
`;

export const actions = css`
  display: flex;
  align-items: center;
  gap: 6px;

  margin-right: -8px;
`;

export const action = css`
  display: none;

  /* When hovering the card. */
  *:hover > * > * > &,
  &.${FORCE_SHOW_CLASSNAME} {
    display: initial;
  }
`;
