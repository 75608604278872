import { useQuery } from '@tanstack/react-query';

import { RelativePeriodKeyword } from '@amal-ia/compensation-definition/periods/types';
import { getOvertimeWidget } from '@amal-ia/frontend/web-data-layers';
import { formatDate, type PayoutAndPerformanceChartStatistics, reformatDateString } from '@amal-ia/lib-types';

const selectOvertimeDataHandler = (data: PayoutAndPerformanceChartStatistics, selectedPeriodDate: string) => ({
  ...data,
  last12MonthsRecords: data?.records?.slice(0, 12),
  ytdRecords: data?.records?.filter(
    (r) => reformatDateString(r.ruleMetricPeriod__month, 'MM/YYYY', 'YYYY') === formatDate(selectedPeriodDate, 'YYYY'),
  ),
});

enum OVER_TIME_QUERY_KEYS {
  OVERTIME_DATA = 'OVERTIME_DATA',
}

export const useUserOvertimeData = (selectedPeriodDate?: string, selectedUserId?: string, selectedPlanId?: string) =>
  useQuery({
    enabled: !!selectedUserId && !!selectedPlanId && !!selectedPeriodDate,
    queryKey: [OVER_TIME_QUERY_KEYS.OVERTIME_DATA, selectedPeriodDate, selectedUserId, selectedPlanId],
    queryFn: async () =>
      getOvertimeWidget(
        [RelativePeriodKeyword.LAST_12_MONTHS, RelativePeriodKeyword.YEAR_TO_DATE],
        selectedPeriodDate,
        [selectedUserId],
        [selectedPlanId],
      ),
    select: (data) => selectOvertimeDataHandler(data, selectedPeriodDate),
  });

export const useOverTimeData = (
  selectedPeriodDate: string,
  selectedUserIds?: string[],
  selectedPlanIds?: string[],
  selectedTeamIds?: string[],
) =>
  useQuery({
    queryKey: [
      OVER_TIME_QUERY_KEYS.OVERTIME_DATA,
      selectedPeriodDate,
      selectedUserIds,
      selectedPlanIds,
      selectedTeamIds,
    ],
    queryFn: async () =>
      getOvertimeWidget(
        [RelativePeriodKeyword.LAST_12_MONTHS, RelativePeriodKeyword.YEAR_TO_DATE],
        selectedPeriodDate,
        selectedUserIds,
        selectedPlanIds,
        selectedTeamIds,
      ),
    select: (data) => selectOvertimeDataHandler(data, selectedPeriodDate),
  });
