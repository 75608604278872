import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { toLower } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { ListPopUp, LightTooltip, Text, TextType } from '@amal-ia/lib-ui';
import { type TeamContract } from '@amal-ia/tenants/teams/types';

interface SubTeamListPopUpProps {
  readonly teams: TeamContract[];
  readonly showArchived: boolean;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(0.625),
  },
  counter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(1, 1.5),
    borderRadius: '100%',
  },
  margin10: {
    margin: '10px',
  },
}));

export const SubTeamListPopUp = memo(function SubTeamListPopUp({ teams, showArchived }: SubTeamListPopUpProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const allTeams = useMemo(() => (showArchived ? teams : teams.filter((t) => !t.archived)), [teams, showArchived]);

  const teamList = useMemo(
    () => (search !== '' ? allTeams.filter((u) => toLower(u.name).includes(toLower(search))) : allTeams),
    [allTeams, search],
  );

  const Counter = useMemo(
    () => (
      <Box className={classes.container}>
        <Text
          className={classes.title}
          type={TextType.INPUT_NAME}
        >
          <FormattedMessage
            defaultMessage="SUB"
            description="Subteam counter title"
          />
        </Text>
        <Text
          className={classes.counter}
          type={TextType.INPUT_NAME}
        >
          {allTeams.length}
        </Text>
      </Box>
    ),
    [allTeams.length, classes],
  );

  if (teams.length === 0) {
    return Counter;
  }

  return (
    <Box>
      <LightTooltip
        placement="right-end"
        title={
          <ListPopUp
            search={search}
            setSearch={setSearch}
            title={
              <FormattedMessage
                defaultMessage="Sub-teams:"
                description="Subteam list popup title"
              />
            }
          >
            {teamList.map((t) => (
              <Text
                key={t.id}
                className={classes.margin10}
                type={TextType.USERS_IN_TOOLTIP}
              >
                {t.name}
              </Text>
            ))}
          </ListPopUp>
        }
      >
        {Counter}
      </LightTooltip>
    </Box>
  );
});
