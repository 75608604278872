import {
  AbilityBuilder,
  type ConditionsMatcher,
  fieldPatternMatcher,
  type MatchConditions,
  PureAbility as AbilityType,
} from '@casl/ability';

import { CustomReportsPresetsEnum, type Statement } from '@amal-ia/lib-types';
import { type CommentThreadMessage } from '@amal-ia/payout-collaboration/comments/shared/types';
import { type Team } from '@amal-ia/tenants/teams/types';
import { AmaliaRole, type UserContract, userReadOnlyRoleMatcher } from '@amal-ia/tenants/users/shared/types';

import { type AuthenticatedContext } from './authenticatedContext';
import { SubsetAccessEnum } from './subsets/enums';
import { type Ability, type DefinePermissions } from './types/abilities';
import { ActionsEnum } from './types/actions';
import { SubjectsEnum } from './types/subjects';
import { UserRoleForAccessControl } from './types/user';

const lambdaMatcher: ConditionsMatcher<MatchConditions> = (matchConditions) =>
  // FIXME: mlefebvre volunteered.
  // @ts-expect-error -- Predicate is a keyword from us.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  matchConditions.predicate || matchConditions;

export const managerCanAccessStatement = (authenticatedContext: AuthenticatedContext, statement: Statement) =>
  // It's my statement.
  authenticatedContext.user.id === statement.userId ||
  // I was manager of the employee at the beginning of the period.
  authenticatedContext.hierarchy.isManagerOf(statement.userId, statement.period.startDate) ||
  // I was manager of the employee at the end of the period.
  authenticatedContext.hierarchy.isManagerOf(statement.userId, statement.period.endDate);

const canSeePresetReports = ({ presetReportId }: { presetReportId: CustomReportsPresetsEnum }) =>
  [
    CustomReportsPresetsEnum.PRESET_LEADERBOARD,
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
    CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
    CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_PLAN,
    CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
  ].includes(presetReportId);

export const rolePermissions: Record<UserRoleForAccessControl, DefinePermissions> = {
  // IMPORTANT: these roles are cumulative, they need to be called ADDITIONALLY to current user role
  AMALIA_TECH_ADMIN(_, { can }) {
    can(ActionsEnum.modify, SubjectsEnum.SuperAdmin_companies);
    can(ActionsEnum.modify, SubjectsEnum.SuperAdmin_settings);
  },

  AMALIA_ADMIN(_, { can }) {
    can(ActionsEnum.modify_directory_email_domain, SubjectsEnum.Company);

    // Super admin
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_calculations);
    can(ActionsEnum.modify, SubjectsEnum.SuperAdmin_calculations);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_refreshments);
    can(ActionsEnum.modify, SubjectsEnum.SuperAdmin_refreshments);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_connectors);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_statements);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_users);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_plans);
    can(ActionsEnum.view, SubjectsEnum.SuperAdmin_companies);
    can(ActionsEnum.create, SubjectsEnum.SuperAdmin_companies);
    can(ActionsEnum.delete, SubjectsEnum.SuperAdmin_companies);
  },

  // COMMON ROLES
  ADMIN({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement);
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.review, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.adjust, SubjectsEnum.Statement);
    can(ActionsEnum.overwrite, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement);

    can(ActionsEnum.view, SubjectsEnum.Data_Export);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement);
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(ActionsEnum.review_statement_thread, SubjectsEnum.Statement);

    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.assign_values, SubjectsEnum.Quota);

    can(ActionsEnum.view, SubjectsEnum.Todos);

    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Team);
    can(ActionsEnum.automate, SubjectsEnum.Team);
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);
    can(ActionsEnum.modify_assignments, SubjectsEnum.Team);

    can(ActionsEnum.view, SubjectsEnum.Data);
    can(ActionsEnum.modify, SubjectsEnum.Data);
    can(ActionsEnum.overwrite, SubjectsEnum.Data);
    can(ActionsEnum.overwrite, SubjectsEnum.Payment);

    can(ActionsEnum.view, SubjectsEnum.Data_Connector);
    can(ActionsEnum.modify, SubjectsEnum.Data_Connector);

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Plan);

    can(ActionsEnum.view, SubjectsEnum.Plan_Assignment);
    can(ActionsEnum.view_list, SubjectsEnum.Plan_Assignment);
    can(ActionsEnum.modify, SubjectsEnum.Plan_Assignment);

    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);

    can(ActionsEnum.view_settings, SubjectsEnum.Company);
    can(ActionsEnum.modify_settings, SubjectsEnum.Company);

    can(ActionsEnum.view_rates, SubjectsEnum.Company);
    can(ActionsEnum.modify_rates, SubjectsEnum.Company);

    can(ActionsEnum.view_directory, SubjectsEnum.Company);

    can(ActionsEnum.view, SubjectsEnum.ApiKeys);
    can(ActionsEnum.create, SubjectsEnum.ApiKeys);
    can(ActionsEnum.delete, SubjectsEnum.ApiKeys);

    can(ActionsEnum.delete, SubjectsEnum.Company);

    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.Payment);

    can(ActionsEnum.view, SubjectsEnum.PaymentLock);
    can(ActionsEnum.modify, SubjectsEnum.PaymentLock);

    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.reset, SubjectsEnum.Workflow);
    can(ActionsEnum.modify, SubjectsEnum.Workflow);

    can(ActionsEnum.view, SubjectsEnum.Audit);

    can(ActionsEnum.view, SubjectsEnum.CustomReport);
    can(ActionsEnum.modify, SubjectsEnum.CustomReport);
    can(ActionsEnum.delete, SubjectsEnum.CustomReport);
    can(ActionsEnum.create, SubjectsEnum.CustomReport);

    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.view_list, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.modify, SubjectsEnum.PlanAgreements);
    can(ActionsEnum.delete, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.create, SubjectsEnum.Folders);
    can(ActionsEnum.modify, SubjectsEnum.Folders);
    can(ActionsEnum.delete, SubjectsEnum.Folders);

    can(ActionsEnum.view, SubjectsEnum.Flow);
    can(ActionsEnum.modify, SubjectsEnum.Flow);
    can(ActionsEnum.create, SubjectsEnum.Flow);
    can(ActionsEnum.delete, SubjectsEnum.Flow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.modify, SubjectsEnum.UserProfile);

    can(ActionsEnum.impersonate, SubjectsEnum.User);

    can(
      ActionsEnum.toggle_deactivation,
      SubjectsEnum.UserProfile,
      // Can't deactivate themselves
      ({ userId }: { userId: string }) => user.id !== userId,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },
  READ_ONLY_ADMIN({ user }, { can }) {
    can(ActionsEnum.view, SubjectsEnum.Statement);
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view, SubjectsEnum.Data_Export);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement);
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(ActionsEnum.view, SubjectsEnum.Todos);

    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);

    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);

    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(ActionsEnum.view, SubjectsEnum.Workflow);

    can(ActionsEnum.view, SubjectsEnum.Audit);

    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);

    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);
    can(ActionsEnum.view_list, SubjectsEnum.Plan_Assignment);

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.view, SubjectsEnum.Plan_Assignment);

    can(ActionsEnum.view, SubjectsEnum.RepHome);
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      predicate: () => true,
      subset: SubsetAccessEnum.EVERYTHING,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },
  FINANCE(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement);
    can(ActionsEnum.tracing, SubjectsEnum.Statement);
    can(ActionsEnum.export, SubjectsEnum.Statement);
    can(ActionsEnum.customize, SubjectsEnum.Statement);
    can(ActionsEnum.view_list, SubjectsEnum.Statement);
    can(ActionsEnum.calculate, SubjectsEnum.Statement, {
      predicate: () => true,
      subset: SubsetAccessEnum.EVERYTHING,
    });

    can(ActionsEnum.view, SubjectsEnum.Data_Export);

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement);

    can(ActionsEnum.view, SubjectsEnum.Quota, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(ActionsEnum.view, SubjectsEnum.Todos);

    can(ActionsEnum.view, SubjectsEnum.Team, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.view_assignments, SubjectsEnum.Team);

    can(ActionsEnum.view, SubjectsEnum.Overwrite_Recap);
    can(ActionsEnum.export, SubjectsEnum.Overwrite_Recap);

    can(ActionsEnum.view, SubjectsEnum.Payment, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(ActionsEnum.view, SubjectsEnum.Workflow);

    can(ActionsEnum.view, SubjectsEnum.Audit);

    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);
    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, { predicate: () => true, subset: SubsetAccessEnum.EVERYTHING });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.EVERYTHING });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.EVERYTHING,
    });
  },
  MANAGER(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.review, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.calculate, SubjectsEnum.Statement, {
      predicate: (statement: Statement) => managerCanAccessStatement(authenticatedContext, statement),
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.adjust, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.overwrite, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.customize, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    // Doesn't make a lot of sense, see discussion in https://gitlab.com/amal-ia/amalia-web/-/issues/3909
    can(ActionsEnum.overwrite, SubjectsEnum.Data);

    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: { creator: UserContract }) => user.id === creator.id);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );

    can(ActionsEnum.review_statement_thread, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });

    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: { userId: string }) =>
        userId && (userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date())),
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });

    can(ActionsEnum.assign_values, SubjectsEnum.Quota, ({ userId }: { userId: string }) =>
      authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ id }: Team) => authenticatedContext.hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(ActionsEnum.view_assignments, SubjectsEnum.Team, ({ id }: Team) =>
      authenticatedContext.hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
    );

    can(ActionsEnum.view, SubjectsEnum.Todos, ({ statement: { userId } }: { statement: Statement }) =>
      authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Payment, {
      subset: SubsetAccessEnum.MATCH_MANAGEES,
      predicate: ({ userId }: { userId: string }) => authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    });

    can(ActionsEnum.modify, SubjectsEnum.Payment, ({ userId }: { userId: string }) =>
      authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);

    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) =>
        userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },
  READ_ONLY_MANAGER(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.tracing, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.export, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.customize, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.view_list, SubjectsEnum.Statement);

    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: { creator: UserContract }) => user.id === creator.id);

    can(ActionsEnum.view_statement_threads, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(ActionsEnum.add_statement_comments, SubjectsEnum.Statement, (statement: Statement) =>
      managerCanAccessStatement(authenticatedContext, statement),
    );
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });

    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: { userId: string }) =>
        userId && (userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date())),
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });

    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ id }: Team) => authenticatedContext.hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(ActionsEnum.view_assignments, SubjectsEnum.Team, ({ id }: Team) =>
      authenticatedContext.hierarchy.getTeamIdsWhereUserIsManager(new Date()).includes(id),
    );

    can(ActionsEnum.view, SubjectsEnum.Todos, ({ statement: { userId } }: { statement: Statement }) =>
      authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Payment, {
      subset: SubsetAccessEnum.MATCH_MANAGEES,
      predicate: ({ userId }: { userId: string }) => authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
    });

    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(ActionsEnum.view, SubjectsEnum.PresetReport, canSeePresetReports);

    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);
    can(ActionsEnum.view, SubjectsEnum.AnalyticsHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) =>
        userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },
  EMPLOYEE(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.tracing, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.export, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.customize, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: { creator: UserContract }) => creator.id === user.id);

    can(
      ActionsEnum.view_statement_threads,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: Statement) => userId === user.id || statementUser?.id === user.id,
    );
    can(
      ActionsEnum.add_statement_comments,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: Statement) => userId === user.id || statementUser?.id === user.id,
    );
    can(
      ActionsEnum.delete,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );
    can(
      ActionsEnum.modify,
      SubjectsEnum.CommentThreadMessage,
      (statementComment: CommentThreadMessage) => statementComment.authorId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });

    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: { userId: string }) => userId === user.id,
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });

    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ id }: Team) => authenticatedContext.hierarchy.isTeamMember(id, new Date()),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(ActionsEnum.view_assignments, SubjectsEnum.Team, ({ id }: Team) =>
      authenticatedContext.hierarchy.isTeamMember(id, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(
      ActionsEnum.view,
      SubjectsEnum.PresetReport,
      ({ presetReportId }: { presetReportId: CustomReportsPresetsEnum }) =>
        [
          CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
          CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
          CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE,
          CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
        ].includes(presetReportId),
    );

    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);

    can(ActionsEnum.simulate, SubjectsEnum.Forecasted_Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.calculate, SubjectsEnum.Forecasted_Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.view, SubjectsEnum.RepHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) =>
        userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },
  READ_ONLY_EMPLOYEE(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.tracing, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.export, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: { creator: UserContract }) => creator.id === user.id);

    can(
      ActionsEnum.view_statement_threads,
      SubjectsEnum.Statement,
      ({ userId, user: statementUser }: Statement) => userId === user.id || statementUser?.id === user.id,
    );

    can(ActionsEnum.view, SubjectsEnum.Plan, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });

    can(ActionsEnum.view, SubjectsEnum.Quota, {
      predicate: ({ userId }: { userId: string }) => userId === user.id,
      subset: SubsetAccessEnum.MATCH_TEAMS_AND_MANAGEES,
    });

    can(ActionsEnum.view, SubjectsEnum.Team, {
      predicate: ({ id }: Team) => authenticatedContext.hierarchy.isTeamMember(id, new Date()),
      subset: SubsetAccessEnum.MATCH_TEAMS,
    });

    can(ActionsEnum.view_assignments, SubjectsEnum.Team, ({ id }: Team) =>
      authenticatedContext.hierarchy.isTeamMember(id, new Date()),
    );

    can(ActionsEnum.view, SubjectsEnum.Workflow);
    can(ActionsEnum.view, SubjectsEnum.CustomReport);

    can(
      ActionsEnum.view,
      SubjectsEnum.PresetReport,
      ({ presetReportId }: { presetReportId: CustomReportsPresetsEnum }) =>
        [
          CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME,
          CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME_BY_PLAN,
          CustomReportsPresetsEnum.PRESET_BENCHMARK_BY_RULE,
          CustomReportsPresetsEnum.PRESET_PLAN_KPIS,
        ].includes(presetReportId),
    );

    can(ActionsEnum.view, SubjectsEnum.DashboardPayments);

    can(ActionsEnum.view, SubjectsEnum.PlanAgreements);

    can(ActionsEnum.view, SubjectsEnum.Flow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);

    can(ActionsEnum.view, SubjectsEnum.UserProfile, {
      /* can view their own profile and the profile of subordinates */
      predicate: ({ userId }: { userId: string }) =>
        userId === user.id || authenticatedContext.hierarchy.isManagerOf(userId, new Date()),
      subset: SubsetAccessEnum.MATCH_MANAGEES,
    });

    can(ActionsEnum.view, SubjectsEnum.UserData, { predicate: () => false, subset: SubsetAccessEnum.MATCH_MANAGEES });
    can(ActionsEnum.switch_to_another_user, SubjectsEnum.Statement, {
      predicate: () => false,
      subset: SubsetAccessEnum.MATCH_MANAGEES_WITH_DATES,
    });
  },
  DEACTIVATED_USER(authenticatedContext, { can }) {
    const { user } = authenticatedContext;
    can(ActionsEnum.view, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.tracing, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);
    can(ActionsEnum.export, SubjectsEnum.Statement, ({ userId }: Statement) => userId === user.id);

    can(ActionsEnum.view, SubjectsEnum.Data_Export, ({ creator }: { creator: UserContract }) => creator.id === user.id);

    can(ActionsEnum.view, SubjectsEnum.Workflow);

    can(ActionsEnum.view, SubjectsEnum.RepHome);

    can(
      ActionsEnum.view,
      SubjectsEnum.UserProfile,
      /* can view their own profile */ ({ userId }: { userId: string }) => userId === user.id,
    );

    can(
      ActionsEnum.modify,
      SubjectsEnum.UserProfile,
      ['language'],
      /* can modify their own locale */ ({ userId }: { userId: string }) => userId === user.id,
    );
  },
};

export function defineAbilityFor(authenticatedContext?: AuthenticatedContext): Ability {
  const builder = new AbilityBuilder<AbilityType>(AbilityType);

  if (!authenticatedContext?.user) {
    return builder.build({ conditionsMatcher: lambdaMatcher });
  }

  const { user, meta, impersonation } = authenticatedContext;

  const appliedRole = impersonation ? userReadOnlyRoleMatcher[user.role] : user.role;

  if (user.clearedAt) {
    rolePermissions[UserRoleForAccessControl.DEACTIVATED_USER](authenticatedContext, builder);
  } else if (appliedRole && typeof rolePermissions[appliedRole] === 'function') {
    rolePermissions[appliedRole](authenticatedContext, builder);
  } else {
    throw new Error(`Trying to use unknown role "${user.role}"`);
  }

  // Add rights for impersonating to impersonated user rights
  if (meta?.isSuperAdmin) {
    rolePermissions[UserRoleForAccessControl.AMALIA_ADMIN](authenticatedContext, builder);

    // If email is part of the whitelist for amalia tech admins, add the other cumulative role
    if (meta?.amaliaRoles?.includes(AmaliaRole.TECH_ADMIN)) {
      rolePermissions[UserRoleForAccessControl.AMALIA_TECH_ADMIN](authenticatedContext, builder);
    }
  }

  return builder.build({ conditionsMatcher: lambdaMatcher, fieldMatcher: fieldPatternMatcher });
}
