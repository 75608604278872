import { keyBy } from 'lodash';

import { MockVariableBuilder } from '@amal-ia/amalia-lang/tokens/testing/mocks';
import { type Variable, VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';

const planMockVariables: Variable[] = [
  MockVariableBuilder.from({
    id: '53302ef4-3e63-4ee1-9f15-e8eefe1be44a',
    name: 'KAM Allowed Churn',
    machineName: 'kamAllowedChurn',
    formula: '',
    format: FormatsEnum.percent,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.user,
  }).build(),
  MockVariableBuilder.from({
    id: '36973284-04d9-4085-9b02-a8178c556f54',
    name: 'KAM Customer Base',
    machineName: 'kamCustomerBase',
    formula: '',
    format: FormatsEnum.currency,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.user,
  }).build(),
  MockVariableBuilder.from({
    id: 'bcee6d61-b1d4-4cd5-8d26-62ab818587a3',
    name: 'KAM Ramp CAP Bonus Tab\t',
    machineName: 'kamRampCapBonusTab',
    formula: '[[0, 3, 1.4], [3, 5, 1.2], [5, Infinity, 0]]',
    format: FormatsEnum.table,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  }).build(),
  MockVariableBuilder.from({
    id: 'a817cc13-6673-4790-a30f-3b1bbfe7be2e',
    name: 'KAM Ramp Tab\t',
    machineName: 'kamRampTab',
    formula: '[[0, 1, 0], [1, 2, 1 / 6], [2, 3, 1 / 2], [3, 4, 5 / 6], [4, Infinity, 1]]',
    format: FormatsEnum.table,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  }).build(),
  MockVariableBuilder.from({
    id: 'fff9a8c3-57c8-409c-a555-e40079461158',
    name: 'Target Achievement',
    machineName: 'targetAchievement',
    formula: 'TIER(statement.var1, statement.commissionTable)',
    format: FormatsEnum.currency,
    frequency: PeriodFrequencyEnum.null,
    type: VariableObjectsEnum.statement,
  }).build(),
];

export const planMockVariablesMap = keyBy(planMockVariables, 'id');
