import { type JSXElementConstructor, type ReactElement, memo } from 'react';

import { type CellIconActionProps } from '../cell-icon-action/CellIconAction';
import { CellWithActions, type CellWithActionsProps } from '../cell-with-actions/CellWithActions';

type CellIconActionElement = ReactElement<CellIconActionProps, JSXElementConstructor<CellIconActionProps>>;

export type CellActionsProps = Omit<CellWithActionsProps, 'actions' | 'children' | 'hideActions'> & {
  /** Children should be <Table.Cell.Action />[]. */
  readonly children: CellIconActionElement | CellIconActionElement[];
};

export const CellActions = memo(function CellActions({ children, ...props }: CellActionsProps) {
  return (
    <CellWithActions
      {...props}
      actions={children}
      hideActions={false}
    />
  );
});
