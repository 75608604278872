import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amal-ia/amalia-lang/tokens/types';
import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { type Challenge, type ChallengeLeaderboard } from '@amal-ia/compensation-definition/plans/types';
import { ColumnHelper, Table } from '@amal-ia/frontend/design-system/components';
import { formatTotal } from '@amal-ia/lib-types';
import { type Company } from '@amal-ia/tenants/companies/types';
import { type UsersMap } from '@amal-ia/tenants/users/shared/types';

import { ChallengeLeaderboardCellPosition } from './cells/position/ChallengeLeaderboardCellPosition';
import { ChallengeLeaderboardCellUser } from './cells/user/ChallengeLeaderboardCellUser';

const columnHelper = new ColumnHelper<ChallengeLeaderboard>();

export type ChallengeLeaderboardTableProps = {
  readonly challenge: Challenge;
  readonly company: Company;
  readonly currentPeriod: Period;
  readonly comparisonVariable: Pick<VariableDefinition, 'format' | 'name'>;
  readonly usersMap: UsersMap;
};

export const ChallengeLeaderboardTable = memo(function ChallengeLeaderboardTable({
  challenge,
  company,
  currentPeriod,
  comparisonVariable,
  usersMap,
}: ChallengeLeaderboardTableProps) {
  const { formatMessage } = useIntl();

  // I don't think it's necessary but the type is nullable. The previous implementation did not check for null and did not crash.
  const leaderboard = useMemo(() => challenge.leaderboard || [], [challenge.leaderboard]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('position', {
        id: 'position',
        size: 0,
        header: formatMessage({ defaultMessage: 'Rank' }),

        cell: ({ value: position }) => <ChallengeLeaderboardCellPosition position={position} />,
      }),

      columnHelper.accessor('userId', {
        id: 'userId',
        header: formatMessage({ defaultMessage: 'User name' }),

        cell: ({ value: userId, row: leaderboardRow }) =>
          userId && userId in usersMap ? (
            <ChallengeLeaderboardCellUser
              period={currentPeriod}
              statementId={leaderboardRow.statementIds[0]}
              user={usersMap[userId]}
            />
          ) : null,
      }),

      columnHelper.accessor('comparisonValue', {
        id: 'comparisonValue',
        header: comparisonVariable.name,
        cell: ({ value: comparisonValue }) => formatTotal(comparisonValue, comparisonVariable.format, company.currency),
      }),
    ],
    [formatMessage, comparisonVariable, company.currency, currentPeriod, usersMap],
  );

  return (
    <Table
      columns={columns}
      data={leaderboard}
      rowKey={(row) => row.userId!} // The type is optional because the model handles team challenges but the app doesn't.
    />
  );
});
