import { Autocomplete, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { memo, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { type PlanCategory, type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { Button } from '@amal-ia/frontend/design-system/components';
import { DialogTitleWithCloseButton } from '@amal-ia/lib-ui';

interface PlanDetailAddCategoryProps {
  readonly isOpen: boolean;
  readonly onCancel: () => any;
  readonly categoriesMap?: PlanCategory[];
  readonly selectedRule: PlanRule | null;
  readonly onCreateCategory: (ruleCategory: PlanCategory) => Promise<void>;
  readonly onAddCategoryToRule: (rule: PlanRule, categoryName: string) => Promise<void>;
}

export const PlanDetailAddCategoryModal = memo(function PlanDetailAddCategoryModal({
  onCancel,
  categoriesMap,
  selectedRule,
  onCreateCategory,
  onAddCategoryToRule,
}: PlanDetailAddCategoryProps) {
  const categories = useMemo(
    () =>
      (categoriesMap || [])
        .filter((category) => category.name !== selectedRule?.category)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [categoriesMap, selectedRule],
  );

  const [selectedCategory, setSelectedCategory] = useState<PlanCategory | null>(null);

  // Reset value on opening / closing modal.
  useEffect(() => {
    setSelectedCategory({ name: selectedRule?.category });
  }, [selectedRule]);
  const onChange = useCallback(
    async (_, newValue: PlanCategory | null) => {
      if (newValue) {
        if (!categories.map((c) => c.name).includes(newValue.name)) {
          await onCreateCategory(newValue);
        }
        setSelectedCategory(newValue);
      }
    },
    [categories, onCreateCategory],
  );

  const onSubmitProxy = useCallback(async () => {
    if (selectedRule && selectedCategory) {
      await onAddCategoryToRule(selectedRule, selectedCategory.name);
    }
  }, [onAddCategoryToRule, selectedCategory, selectedRule]);

  const filter = createFilterOptions<PlanCategory>();

  return (
    <Dialog
      fullWidth
      open={!!selectedRule}
      onClose={onCancel}
    >
      <DialogTitleWithCloseButton handleClose={onCancel}>
        Add category to rule {selectedRule?.name}
      </DialogTitleWithCloseButton>
      <DialogContent dividers>
        <Autocomplete
          filterSelectedOptions
          getOptionLabel={(option: PlanCategory): string => option.name}
          options={categories}
          value={selectedCategory}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
              filtered.push({
                name: params.inputValue,
              });
            }

            return filtered;
          }}
          renderInput={(params): ReactNode => (
            <TextField
              {...params}
              placeholder="Category"
              variant="outlined"
            />
          )}
          onChange={onChange}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant={Button.Variant.LIGHT}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="apply-rules-btn"
          onClick={onSubmitProxy}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
});
