import {
  type ReactNode,
  memo,
  type ReactElement,
  type JSXElementConstructor,
  useState,
  type ForwardedRef,
} from 'react';

import { useShallowObjectMemo } from '@amal-ia/ext/react/hooks';
import { type MergeAll } from '@amal-ia/ext/typescript';

import { Popover, type PopoverProps } from '../popover/Popover';

import { DropdownAction } from './dropdown-action/DropdownAction';
import { DropdownButton } from './dropdown-button/DropdownButton';
import { DropdownDivider } from './dropdown-divider/DropdownDivider';
import { DropdownFooter } from './dropdown-footer/DropdownFooter';
import { DropdownGroup } from './dropdown-group/DropdownGroup';
import { DropdownIconButton } from './dropdown-icon-button/DropdownIconButton';
import { DropdownItemButton } from './dropdown-item-button/DropdownItemButton';
import { DropdownItemCheckbox } from './dropdown-item-checkbox/DropdownItemCheckbox';
import { DropdownItemLink } from './dropdown-item-link/DropdownItemLink';
import { DropdownItemOption } from './dropdown-item-option/DropdownItemOption';
import { DropdownNoOptions } from './dropdown-no-options/DropdownNoOptions';
import { DropdownSearchInput, type DropdownSearchInputProps } from './dropdown-search-input/DropdownSearchInput';
import { DropdownTitle } from './dropdown-title/DropdownTitle';
import { DropdownContext, type DropdownContextValue } from './Dropdown.context';
import * as styles from './Dropdown.styles';

export type DropdownProps = MergeAll<
  [
    PopoverProps,
    {
      title?: ReactNode;
      footer?: ReactNode;
      action?: ReactNode;
      /** Pass a <DropdownSearchInput /> component if you want to show a search filter. Note that this does not filter anything by itself. */
      searchInput?: ReactElement<DropdownSearchInputProps, JSXElementConstructor<DropdownSearchInputProps>>;
      /** If searchInput is not passed, searchText can be passed instead. Useful when the filter is done outside of the dropdown. */
      searchText?: string;
      /** Dropdown body. */
      content?: ReactNode;
      /** Ref to the scrollable body element. */
      bodyRef?: ForwardedRef<HTMLDivElement>;
      /** Children can be a function taking isOpen as parameter. */
      children: PopoverProps['children'] | ((props: { isOpen: boolean }) => PopoverProps['children']);
    },
  ]
>;

const DropdownBase = memo(function Dropdown({
  title = null,
  searchInput = undefined,
  searchText = undefined,
  isOpen: controlledIsOpen,
  onChangeIsOpen: controlledOnChangeIsOpen,
  content,
  action,
  footer,
  bodyRef,
  children,
  ...props
}: DropdownProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen;
  const onChangeIsOpen = controlledOnChangeIsOpen ?? setUncontrolledIsOpen;

  const contextValue = useShallowObjectMemo<DropdownContextValue>({
    isOpen,
  });

  return (
    <DropdownContext.Provider value={contextValue}>
      <Popover
        {...props}
        isOpen={isOpen}
        content={
          <div css={styles.dropdown}>
            {searchInput}

            <div
              ref={bodyRef}
              css={styles.dropdownBody}
            >
              {!!title && <DropdownTitle>{title}</DropdownTitle>}

              {content || (
                <DropdownNoOptions
                  css={styles.noOptions}
                  searchInputValue={searchText || searchInput?.props.value}
                />
              )}

              {!!footer && <DropdownFooter>{footer}</DropdownFooter>}
            </div>

            {!!action && <div css={styles.dropdownAction}>{action}</div>}
          </div>
        }
        onChangeIsOpen={onChangeIsOpen}
      >
        {typeof children === 'function' ? children({ isOpen }) : children}
      </Popover>
    </DropdownContext.Provider>
  );
});

export const Dropdown = Object.assign(DropdownBase, {
  Action: DropdownAction,
  Button: DropdownButton,
  Divider: DropdownDivider,
  Footer: DropdownFooter,
  Group: DropdownGroup,
  IconButton: DropdownIconButton,
  ItemButton: DropdownItemButton,
  ItemCheckbox: DropdownItemCheckbox,
  ItemContainer: styles.DropdownItemContainer,
  ItemLink: DropdownItemLink,
  ItemOption: DropdownItemOption,
  NoOptions: DropdownNoOptions,
  SearchInput: DropdownSearchInput,
  Title: DropdownTitle,
});
