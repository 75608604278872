import { type ComponentPropsWithoutRef, memo, forwardRef, type ForwardedRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { useTableContext } from '../../Table.context';

export type TableRowProps = MergeAll<
  [
    ComponentPropsWithoutRef<'tr'>,
    {
      index: number;
    },
  ]
>;

const TableRowForwardRef = forwardRef(function TableRow(
  { index, children, ...props }: TableRowProps,
  ref: ForwardedRef<HTMLTableRowElement>,
) {
  const { totalRowsCount } = useTableContext();

  return (
    <tr
      {...props}
      ref={ref}
      data-firstrow={index === 0}
      data-lastrow={index === totalRowsCount - 1}
    >
      {children}
    </tr>
  );
});

export const TableRow = memo(TableRowForwardRef);
