import { css } from '@emotion/react';
import { memo } from 'react';

import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { reformatDateString } from '@amal-ia/lib-types';

type AxisTick = {
  readonly x?: number;
  readonly y?: number;
  readonly payload?: {
    value: string;
  };
  readonly frequency?: string;
};

export const MonthAxisTick = memo(function MonthAxisTick({ x, y, payload, frequency }: AxisTick) {
  const monthLabel = reformatDateString(
    payload.value,
    'MM/YYYY',
    frequency === PeriodFrequencyEnum.quarter ? '[Q]Q YY' : 'MMM YY',
  );

  return (
    <g transform={`translate(${x},${y + 5})`}>
      <text
        dy={10}
        textAnchor="middle"
        css={(theme) => css`
          ${theme.ds.typographies.bodyBaseMedium};
          fill: ${theme.ds.colors.gray[800]};
        `}
      >
        {monthLabel}
      </text>
    </g>
  );
});
