import { css } from '@emotion/react';
import { type ComponentProps, memo } from 'react';

import { type Asset } from '@amal-ia/ext/react/types';
import { type UserHrisIdSource } from '@amal-ia/tenants/users/shared/types';

import { userHrisIdSourceLogos } from './UserHrisIdSource.logos';

export type UserHrisIdSourceLogoProps = ComponentProps<Asset> & {
  readonly compact?: boolean;
  readonly hrisIdSource: UserHrisIdSource;
};

const logo = css`
  --icon-size: 24px;

  min-width: var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
`;

export const UserHrisIdSourceLogo = memo(function UserHrisIdSourceLogo({
  compact = true,
  hrisIdSource,
  ...props
}: UserHrisIdSourceLogoProps) {
  const { Logo, CompactLogo } = userHrisIdSourceLogos[hrisIdSource];

  if (compact) {
    return (
      <CompactLogo
        {...props}
        css={logo}
      />
    );
  }

  return (
    <Logo
      {...props}
      css={logo}
    />
  );
});
