import { type JSXElementConstructor, memo, type ReactElement, type ReactNode } from 'react';

import { TextOverflow, type TextOverflowProps } from '../../../../general/text-overflow/TextOverflow';
import { CellWithActions, type CellWithActionsProps } from '../cell-with-actions/CellWithActions';

import { CellMainLink, type CellMainLinkProps } from './cell-main-link/CellMainLink';

export type CellMainProps = {
  readonly tooltipContent?: TextOverflowProps['tooltipContent'];
  readonly tooltipPlacement?: TextOverflowProps['tooltipPlacement'];
  readonly hideActions?: CellWithActionsProps['hideActions'];
  /** Link href. */
  readonly link?: ReactElement<CellMainLinkProps, JSXElementConstructor<CellMainLinkProps>>;
  /** Cell content. */
  readonly children: ReactNode;
};

const CellMainBase = function CellMain({
  link,
  tooltipContent,
  tooltipPlacement,
  hideActions = undefined,
  children,
}: CellMainProps) {
  return (
    <CellWithActions
      actions={link}
      hideActions={hideActions}
    >
      <TextOverflow
        tooltipContent={tooltipContent}
        tooltipPlacement={tooltipPlacement}
      >
        {children}
      </TextOverflow>
    </CellWithActions>
  );
};

export const CellMain = Object.assign(memo(CellMainBase) as typeof CellMainBase, {
  Link: CellMainLink,
});
