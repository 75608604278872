import { useMemo } from 'react';

import {
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amal-ia/lib-types';

import { getDomainAndTicks } from './amountTickLevels.mapper';

const getMinMaxInCollection = (elements: PayoutAndPerformanceChartStatisticsRecord[], keys: string[]) => {
  const collection = elements
    .flatMap((elt) =>
      keys.map((key) => {
        const value = elt[key];
        return typeof value === 'object' && 'value' in value ? value.value : (value as number);
      }),
    )
    // Careful, 0 values are eligible.
    .filter((v) => v !== null && v !== undefined);

  return {
    min: collection?.length ? Math.min(...collection) : null,
    max: collection?.length ? Math.max(...collection) : null,
  };
};

/**
 * Custom hook to compute amount ticks.
 * @param statistics
 * @param leftKeys
 * @param rightKeys
 */
export const useAmountTicks = (
  statistics: PayoutAndPerformanceChartStatistics,
  leftKeys: string[],
  rightKeys: string[],
) =>
  useMemo(() => {
    if (!statistics?.records) {
      return { left: null, right: null };
    }

    const leftMinMax = getMinMaxInCollection(statistics.records, leftKeys);
    const rightMinMax = getMinMaxInCollection(statistics.records, rightKeys);

    return getDomainAndTicks(leftMinMax.min, leftMinMax.max, rightMinMax.min, rightMinMax.max);
  }, [statistics?.records, leftKeys, rightKeys]);
