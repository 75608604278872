import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo } from 'react';

import { type VariableFormatOptionsTable } from '@amal-ia/amalia-lang/tokens/types';
import { type PlanRuleChart } from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { Typography, useSnackbars } from '@amal-ia/frontend/design-system/components';
import { extractChartConfiguration, type ExtractedChartConfiguration } from '@amal-ia/frontend/web-data-layers';
import { type CardinalChartRowWithNumberOnly, useStatementDetailContext } from '@amal-ia/lib-ui';
import { TargetAchievementChart } from '@amal-ia/lib-ui-business';

const useStyles = makeStyles({
  chart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
    padding: '0 24px',
  },
});

interface ChartItemProps {
  readonly chart: PlanRuleChart;
}

const ChartItem = memo(function ChartItem({ chart }: ChartItemProps) {
  const classes = useStyles();
  const { snackError } = useSnackbars();

  const statement = useStatementDetailContext();

  const extractedChartConfiguration: ExtractedChartConfiguration | null = useMemo(() => {
    const result = extractChartConfiguration(statement, chart);
    if (result?.error) {
      snackError(result.error);
      return null;
    }
    return result;
  }, [chart, statement, snackError]);

  const labelsToAdd = useMemo(() => {
    const result: CardinalChartRowWithNumberOnly[] = [];

    if (extractedChartConfiguration?.values?.table?.variable?.name) {
      result.push({
        x: 0,
        value: 0,
        valueLabel: extractedChartConfiguration.values.table.variable.name,
      });
    }

    if (extractedChartConfiguration?.values?.target?.variable?.name) {
      result.push({
        x: 325,
        value: 415,
        valueLabel: extractedChartConfiguration.values.target.variable.name,
      });
    }

    return result;
  }, [extractedChartConfiguration]);

  const [xAxisFormat, yAxisFormat]: ['number' | 'percent', 'number' | 'percent'] = useMemo(() => {
    const tableFormat = (
      extractedChartConfiguration?.values?.table?.variable?.formatOptions as VariableFormatOptionsTable
    )?.columns?.[2]?.format;
    return [
      extractedChartConfiguration?.values?.target.variable.format === FormatsEnum.percent ? 'percent' : 'number',
      tableFormat === FormatsEnum.percent ? 'percent' : 'number',
    ];
  }, [extractedChartConfiguration]);

  if (!extractedChartConfiguration?.values) {
    return null;
  }

  return (
    <Box className={classes.chart}>
      {!!chart.name && <Typography variant={Typography.Variant.BODY_LARGE_MEDIUM}>{chart.name}</Typography>}

      <TargetAchievementChart
        labelsToAdd={labelsToAdd}
        mode={extractedChartConfiguration.values.mode}
        table={extractedChartConfiguration.values.table.value}
        value={extractedChartConfiguration.values.target.value}
        xAxisFormat={xAxisFormat}
        yAxisFormat={yAxisFormat}
      />
    </Box>
  );
});

export default ChartItem;
