import { type Dispatch, memo, type SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PlanRulesLayout } from '@amal-ia/compensation-definition/plans/components';
import { type PlanCategory } from '@amal-ia/compensation-definition/plans/types';
import { selectCurrentStatement } from '@amal-ia/frontend/web-data-layers';
import { type Adjustment, type TracingTypes } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { BadgeList } from '@amal-ia/lib-ui-business';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

import StatementAdjustmentsList from './Adjustments/StatementAdjustmentsList';
import StatementCategory from './StatementCategory';
import { CURRENCY_RATE } from './StatementDetail';

interface StatementDetailPayoutProps {
  readonly setSelectAdjustment: Dispatch<SetStateAction<Adjustment | null>>;
  readonly setCurrentTracingData: Dispatch<SetStateAction<TracingTypes.CurrentTracingDataType | null>>;
}

const StatementDetailPayout = memo(function StatementDetailPayout({
  setSelectAdjustment,
  setCurrentTracingData,
}: StatementDetailPayoutProps) {
  const { data: company } = useCurrentCompany();
  const { ruleid: ruleId } = useParams<Record<string, string>>();

  const { currency } = useStatementDetailContext();

  const statement = useSelector(selectCurrentStatement);
  const searchValue = '';

  const isBadgesEnabled = useMemo(() => company.featureFlags?.BADGES, [company]);

  return (
    <PlanRulesLayout>
      {!!isBadgesEnabled && (
        <BadgeList
          badgeAwards={statement.badgeAwards || []}
          configurations={statement.results.definitions.plan.badgeConfigurations}
        />
      )}

      <StatementCategory
        activeRuleId={ruleId}
        globalSearchValue={searchValue}
        setCurrentTracingData={setCurrentTracingData}
      />

      {(statement.results.definitions.plan.categoriesV2 || []).map((category: PlanCategory) => (
        <StatementCategory
          key={category.name}
          activeRuleId={ruleId}
          category={category}
          globalSearchValue={searchValue}
          setCurrentTracingData={setCurrentTracingData}
        />
      ))}

      {!!statement.adjustments?.length && (
        <StatementAdjustmentsList
          currencyRate={CURRENCY_RATE}
          currencySymbol={currency}
          setSelectAdjustment={setSelectAdjustment}
          statement={statement}
        />
      )}
    </PlanRulesLayout>
  );
});

export default StatementDetailPayout;
