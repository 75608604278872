import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';

import { Button } from '@amal-ia/frontend/design-system/components';
import { formatUserFullName } from '@amal-ia/lib-types';
import { type MinUserType, type UserComputed } from '@amal-ia/tenants/users/shared/types';

import { Avatar } from '../../../../Avatar';

interface UserTableCellProps {
  readonly onClick?: (...args: any) => any;
  readonly contextProps: Table.DataCellProps;
  readonly user?: Partial<UserComputed>;
}

export const UserTableCell = function UserTableCell({ onClick, contextProps, user }: UserTableCellProps) {
  return (
    <Table.Cell {...contextProps}>
      <Box
        alignItems="center"
        display="flex"
      >
        {user ? (
          <Box
            marginRight={2}
            onClick={onClick}
          >
            <Avatar user={user as UserComputed} />
          </Box>
        ) : null}
        <Button
          variant={Button.Variant.LIGHT_TEXT}
          onClick={onClick}
        >
          {formatUserFullName(user as MinUserType)}
        </Button>
      </Box>
    </Table.Cell>
  );
};
