import { type CSSProperties } from 'react';

import { type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { TokenType } from '@amal-ia/lib-types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

export class KeywordFormulaNode extends IFormulaNode {
  private readonly color: string;

  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: string,
    color: string,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.KEYWORD, nodeObject, objectDefinition, relationship);
    this.color = color;
  }

  public getDesignerPath(): { type: TokenType; id: string } {
    return { type: TokenType.KEYWORD, id: this.partialFormula };
  }

  public getStyles(): CSSProperties {
    return {
      backgroundColor: this.color,
    };
  }

  public getLabel(): string {
    return this.nodeObject as string;
  }
}
