import { type ActionCreator } from 'redux';

import {
  type ObjectsToDisplay,
  type PlanForecast,
  type PlanRuleFieldToDisplay,
  type PlanRuleFilterToDisplay,
  type PlanRuleKpiSection,
} from '@amal-ia/compensation-definition/plans/types';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as PlanForecastRepository from '../../services/plans/planForecasts.repository';
import { addSnackbar } from '../snackbars/actions';

import { PLAN_FORECAST_ACTIONS } from './constants';
import { selectCurrentPlanForecast } from './selectors';

const planForecastStart: ActionCreator<ReduxAction> = () => ({
  type: PLAN_FORECAST_ACTIONS.START,
});

const planForecastError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PLAN_FORECAST_ACTIONS.ERROR,
  error,
});

const setPlanForecast: ActionCreator<ReduxAction> = (planForecast: PlanForecast) => ({
  type: PLAN_FORECAST_ACTIONS.SET_PLAN_FORECAST,
  payload: { planForecast },
});

const editDataset: ActionCreator<ReduxAction> = (datasetId: string, formula: string) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_DATASET,
  payload: { datasetId, formula },
});

const editField: ActionCreator<ReduxAction> = (fieldId: string, formula: string) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELD,
  payload: { fieldId, formula },
});

const editKpi: ActionCreator<ReduxAction> = (kpiId: string, formula: string) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPI,
  payload: { kpiId, formula },
});

const editObjectsToDisplay: ActionCreator<ReduxAction> = (ruleId: string, objectsToDisplay: ObjectsToDisplay) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_OBJECTS_TO_DISPLAY,
  payload: { ruleId, objectsToDisplay },
});

const editKpisToDisplay: ActionCreator<ReduxAction> = (ruleId: string, kpisToDisplay: PlanRuleKpiSection[]) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPIS_TO_DISPLAY,
  payload: { ruleId, kpisToDisplay },
});

const editKpiToDisplayStatus: ActionCreator<ReduxAction> = (ruleId: string, kpiToDisplayId: string) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_KPI_TO_DISPLAY_STATUS,
  payload: { ruleId, kpiToDisplayId },
});

const editFiltersToDisplay: ActionCreator<ReduxAction> = (
  ruleId: string,
  filtersToDisplay: PlanRuleFilterToDisplay[],
) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FILTERS_TO_DISPLAY,
  payload: { ruleId, filtersToDisplay },
});

const editFilterToDisplayStatus: ActionCreator<ReduxAction> = (ruleId: string, filterToDisplayId: string) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FILTER_TO_DISPLAY_STATUS,
  payload: { ruleId, filterToDisplayId },
});

const editFieldsToDisplay: ActionCreator<ReduxAction> = (
  ruleId: string,
  filterId: string,
  fieldsToDisplay: PlanRuleFieldToDisplay[],
) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELDS_TO_DISPLAY,
  payload: { ruleId, fieldsToDisplay, filterId },
});

const editFieldToDisplayStatus: ActionCreator<ReduxAction> = (
  ruleId: string,
  filterId: string,
  fieldMachinename: string,
) => ({
  type: PLAN_FORECAST_ACTIONS.EDIT_FIELD_TO_DISPLAY_STATUS,
  payload: { ruleId, fieldMachinename, filterId },
});

export const PlanForecastSyncActions = {
  editDataset,
  editField,
  editKpi,
  editObjectsToDisplay,
  editKpisToDisplay,
  editKpiToDisplayStatus,
  editFiltersToDisplay,
  editFilterToDisplayStatus,
  editFieldsToDisplay,
  editFieldToDisplayStatus,
};

export const fetchPlanForecast =
  (planId: string, id: string | null): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(planForecastStart());
    try {
      if (id) {
        const planForecast = await PlanForecastRepository.get(planId, id);
        return dispatch(setPlanForecast(planForecast));
      }
      return dispatch(setPlanForecast(null));
    } catch (error) {
      return dispatch(planForecastError(error));
    }
  };

export const editCurrentPlanForecast =
  (syncAction: ReduxAction): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    // Synchronously apply diff to current forecast.
    dispatch(syncAction);

    // Get new forecast and try to update it.
    const newPlanForecast = selectCurrentPlanForecast(getState());

    try {
      dispatch(planForecastStart());
      const updatedForecast = await PlanForecastRepository.update(
        newPlanForecast.planId,
        newPlanForecast.id,
        newPlanForecast,
      );
      dispatch(addSnackbar({ message: 'Forecast updated!', options: { variant: 'success' } }));
      return dispatch(setPlanForecast(updatedForecast));
    } catch (error) {
      dispatch(addSnackbar({ message: 'Failed to update Forecast!', options: { variant: 'error' } }));
      return dispatch(planForecastError(error));
    }
  };
