import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

interface TrendProps {
  readonly nextValue: number | null;
  readonly previousValue: number | null;
  readonly className?: string;
  readonly smallIcons?: boolean;
}

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  flat: {
    color: theme.palette.primary.main,
  },
}));

export const Trend = memo(function Trend({ nextValue, previousValue, className, smallIcons }: TrendProps) {
  const classNames = useStyles({
    nextValue,
    previousValue,
    className,
    smallIcons,
  });

  const trendValue = useMemo(() => {
    if (isNil(nextValue) || isNil(previousValue)) return null;
    if (previousValue === 0 && nextValue !== 0) return (nextValue > 0 ? 1 : -1) * 100;
    if (previousValue === 0 && nextValue === 0) return 0;
    const value = ((nextValue - previousValue) / previousValue) * 100;
    return Math.round(value);
  }, [nextValue, previousValue]);

  const trendFormattedValue = useMemo(() => {
    if (trendValue === null) {
      return null;
    }

    return `${Math.abs(trendValue)} %`;
  }, [trendValue]);

  const rightClassName = useMemo(() => {
    if (trendValue < 0) {
      return 'error';
    }
    if (trendValue === 0) {
      return 'flat';
    }
    return 'success';
  }, [trendValue]);

  return trendValue !== null ? (
    <Box
      alignItems="center"
      className={className}
      display="flex"
      flexDirection="row"
      justifyContent="center"
    >
      <Box
        component="span"
        mr={0.5}
        mt={0.5}
      >
        {trendValue > 0 && (
          <TrendingUp
            className={classNames[rightClassName]}
            fontSize="medium"
          />
        )}
        {trendValue < 0 && <TrendingDown className={classNames[rightClassName]} />}
        {trendValue === 0 && <TrendingFlat className={classNames[rightClassName]} />}
      </Box>
      <Box
        className={classNames[rightClassName]}
        component="span"
      >
        {trendFormattedValue}
      </Box>
    </Box>
  ) : null;
});
