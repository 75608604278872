import { css } from '@emotion/react';
import { memo, useMemo } from 'react';

import { type BadgeAward, type BadgeConfiguration } from '@amal-ia/compensation-definition/plans/types';

import { BadgeItem } from './BadgeItem';

interface BadgeListProps {
  readonly badgeAwards: BadgeAward[];
  readonly configurations: BadgeConfiguration[];
}

export const BadgeList = memo(function BadgeList({ badgeAwards, configurations }: BadgeListProps) {
  const notAwarded = useMemo(() => {
    const awardedIds = badgeAwards.map((b) => b.configurationId);
    return (configurations || []).filter((c) => !c.deletedAt && !awardedIds.includes(c.id));
  }, [configurations, badgeAwards]);

  return (
    !!(badgeAwards.length || notAwarded.length) && (
      <div
        css={css`
          display: flex;
          align-items: stretch;
          flex-wrap: wrap;
          gap: 24px;
        `}
      >
        {badgeAwards.map((a) => (
          <BadgeItem
            key={a.configuration.id}
            award={a}
            configuration={a.configuration}
          />
        ))}
        {notAwarded.map((c) => (
          <BadgeItem
            key={c.id}
            configuration={c}
          />
        ))}
      </div>
    )
  );
});
