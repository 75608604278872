import { useQuery } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import { useMemo, useEffect } from 'react';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { getOvertimeByPlanWidget } from '@amal-ia/frontend/web-data-layers';
import { convertTimestampToDate, formatDate, reformatDateString, type Statement } from '@amal-ia/lib-types';
import { type StatementHistogramItem } from '@amal-ia/lib-ui-business';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

export const useStatementDetailStatistics = (statement: Statement) => {
  const { data: company } = useCurrentCompany();
  const { snackError } = useSnackbars();

  const {
    data: statistics,
    isError,
    error,
    ...rest
  } = useQuery({
    queryKey: ['statement', statement.id, 'statistics'],

    queryFn: () =>
      getOvertimeByPlanWidget(
        formatDate(convertTimestampToDate(statement.period.startDate), 'YYYY-MM-DD'),
        [statement.userId],
        [statement.plan.id],
      ),

    enabled: statement && company.featureFlags.DASHBOARDS,
  });

  useEffect(() => {
    if (isError) {
      snackError((error as AxiosError<{ message?: string }>)?.response?.data?.message || error.message);
    }
  }, [isError, error, snackError]);

  const histogramData: StatementHistogramItem[] = useMemo(() => {
    try {
      return statistics?.records?.map((rec) => {
        const totalCommission = rec.ruleMetricPayment__value;
        const period = rec.ruleMetricPeriod__month.split('/');
        return {
          id: rec.RULE_METRIC__statementId as string,
          period: reformatDateString(period[0], 'MM/YYYY', 'MMM'),
          total: totalCommission?.value || 0,
          format: FormatsEnum.currency,
          currency: totalCommission?.symbol || statement.currency,
          year: period[1],
        };
      });
    } catch (e) {
      return [];
    }
  }, [statistics, statement]);

  return {
    histogramDefinitions: statistics?.definitions,
    statistics,
    histogramData,
    ...rest,
  };
};
