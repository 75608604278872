import { createContext, useContext } from 'react';

import { assert } from '@amal-ia/ext/typescript';

import { type ModalSize, type ModalVariant } from './Modal.types';

export type ModalContextValue = {
  variant: ModalVariant;
  size: ModalSize;
  onCloseModal: () => void;
};

export const ModalContext = createContext<ModalContextValue | null>(null);

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  assert(context, 'useModalContext must be used within a <ModalContext.Provider>');
  return context;
};
