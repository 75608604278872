import { type ActionCreator } from 'redux';

import { PlansApiClient } from '@amal-ia/compensation-definition/api-client';
import {
  type PlanRule,
  type PlanRuleCategory,
  type PlanRuleFilterToDisplay,
  type PlanRuleFieldToDisplay,
  type PlanCategory,
  type Plan,
  type PlanRuleKpiSection,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amal-ia/compensation-definition/plans/types';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as PlanRuleCategoriesRepository from '../../services/plans/planRuleCategories.repository';

import { PLANS_ACTIONS } from './constants';
import { selectCurrentPlan, selectPlansMap, selectPlansMapIsFullyLoaded } from './selectors';

const plansStart: ActionCreator<ReduxAction> = () => ({
  type: PLANS_ACTIONS.START,
});

const plansError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: PLANS_ACTIONS.ERROR,
  error,
});

const setPlans: ActionCreator<ReduxAction> = (plans: Plan[]) => ({
  type: PLANS_ACTIONS.SET_PLANS,
  payload: { plans },
});

export const fetchPlans =
  (forceFetch: boolean = false): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    dispatch(plansStart());
    const isPlanMapFullyLoaded = selectPlansMapIsFullyLoaded(getState());
    const plansMap = selectPlansMap(getState());

    if (isPlanMapFullyLoaded && !forceFetch) {
      return dispatch(setPlans(plansMap));
    }

    try {
      const plans = await PlansApiClient.list();
      return dispatch(setPlans(plans));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

const removeDeletedPlan: ActionCreator<ReduxAction> = (planId: string) => ({
  type: PLANS_ACTIONS.DELETE_PLAN,
  payload: { planId },
});

export const deletePlan =
  (planToDeleteId: string): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(plansStart());

    try {
      await PlansApiClient.delete(planToDeleteId);
      return dispatch(removeDeletedPlan(planToDeleteId));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

export const setCurrentPlan: ActionCreator<ReduxAction> = (plan: Plan | null) => ({
  type: PLANS_ACTIONS.SET_CURRENT_PLAN,
  payload: { plan },
});

export const fetchPlan =
  (planId: string | null): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      if (planId) {
        const plan = await PlansApiClient.get(planId);
        return dispatch(setCurrentPlan(plan));
      }
      return dispatch(setCurrentPlan(null));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

export const clearCurrentPlan = (): ThunkResult<Promise<ReduxAction>> => (dispatch) => {
  dispatch(plansStart());
  return Promise.resolve(dispatch(setCurrentPlan(null)));
};

// ================== HIGHLIGHTED KPIS ==================

const setHighlightedKpi: ActionCreator<ReduxAction> = (kpi: HighlightedKpi) => ({
  type: PLANS_ACTIONS.SET_HIGHLIGHTED_KPI,
  payload: { kpi },
});

export const addHighlightedKpi =
  (planId: string, kpi: HighlightedKpi): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(plansStart());

    try {
      await PlansApiClient.upsertHighlightedKpi(planId, kpi);
      return dispatch(setHighlightedKpi(kpi));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

const deleteHighlightedKpiAction: ActionCreator<ReduxAction> = (identifier: string) => ({
  type: PLANS_ACTIONS.DELETE_HIGHLIGHTED_KPI,
  payload: { identifier },
});

export const deleteHighlightedKpi =
  (planId: string, identifier: HighlightedKpiIdentifier): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      await PlansApiClient.deleteHighlightedKpi(planId, identifier);
      return dispatch(deleteHighlightedKpiAction(identifier));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

// ================== PLAN + PLAN RULES ==================

const renamePlan: ActionCreator<ReduxAction> = (name: string, planId: string) => ({
  type: PLANS_ACTIONS.RENAME_PLAN,
  payload: { name, planId },
});

const setSettings: ActionCreator<ReduxAction> = (settings: Partial<Plan>) => ({
  type: PLANS_ACTIONS.SET_SETTINGS,
  payload: { settings },
});

const addRulesToPlan: ActionCreator<ReduxAction> = (rules: Partial<PlanRule>[]) => ({
  type: PLANS_ACTIONS.ADD_PLAN_RULES,
  payload: { rules },
});

const patchRules: ActionCreator<ReduxAction> = (rules: PlanRule[]) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULES,
  payload: { rules },
});

const patchRuleKpisToDisplay: ActionCreator<ReduxAction> = (ruleId: string, kpisToDisplay: PlanRuleKpiSection[]) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_KPIS_TO_DISPLAY,
  payload: { ruleId, kpisToDisplay },
});

const patchRuleKpiToDisplayStatus: ActionCreator<ReduxAction> = (ruleId: string, kpiToDisplayId: string) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_KPI_TO_DISPLAY_STATUS,
  payload: { ruleId, kpiToDisplayId },
});

const patchRuleRemoveKpiToDisplay: ActionCreator<ReduxAction> = (ruleId: string, kpiToDisplayId: string) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_KPI_TO_DISPLAY,
  payload: { ruleId, kpiToDisplayId },
});

const patchRuleFiltersToDisplay: ActionCreator<ReduxAction> = (
  ruleId: string,
  filtersToDisplay: PlanRuleFilterToDisplay[],
) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FILTERS_TO_DISPLAY,
  payload: { ruleId, filtersToDisplay },
});

const patchRuleFilterToDisplayStatus: ActionCreator<ReduxAction> = (ruleId: string, filterToDisplayId: string) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FILTER_TO_DISPLAY_STATUS,
  payload: { ruleId, filterToDisplayId },
});

const patchRuleRemoveFilterToDisplay: ActionCreator<ReduxAction> = (ruleId: string, filterToDisplayId: string) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FILTER_TO_DISPLAY,
  payload: { ruleId, filterToDisplayId },
});

const patchRuleFieldsToDisplay: ActionCreator<ReduxAction> = (
  ruleId: string,
  filterId: string,
  fieldsToDisplay: PlanRuleFieldToDisplay[],
) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FIELDS_TO_DISPLAY,
  payload: { ruleId, filterId, fieldsToDisplay },
});

const patchRuleFieldToDisplayStatus: ActionCreator<ReduxAction> = (
  ruleId: string,
  filterId: string,
  fieldToDisplayMachinename: string,
) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_FIELD_TO_DISPLAY_STATUS,
  payload: { ruleId, filterId, fieldToDisplayMachinename },
});

const patchRuleRemoveFieldToDisplay: ActionCreator<ReduxAction> = (
  ruleId: string,
  filterId: string,
  fieldToDisplayMachinename: string,
) => ({
  type: PLANS_ACTIONS.ON_PATCH_RULE_REMOVE_FIELD_TO_DISPLAY,
  payload: { ruleId, filterId, fieldToDisplayMachinename },
});

const removeRule: ActionCreator<ReduxAction> = (rule: PlanRule) => ({
  type: PLANS_ACTIONS.ON_REMOVE_RULE,
  payload: { rule },
});

const removeRuleById: ActionCreator<ReduxAction> = (ruleId: string) => ({
  type: PLANS_ACTIONS.ON_REMOVE_RULE_BY_ID,
  payload: { ruleId },
});

const createCategory: ActionCreator<ReduxAction> = (category: PlanCategory) => ({
  type: PLANS_ACTIONS.CREATE_CATEGORY,
  payload: { category },
});

const deleteCategory: ActionCreator<ReduxAction> = (categoryName: string) => ({
  type: PLANS_ACTIONS.DELETE_CATEGORYV2,
  payload: { categoryName },
});

const editCategory: ActionCreator<ReduxAction> = (category: PlanCategory, oldCategoryName: string) => ({
  type: PLANS_ACTIONS.EDIT_CATEGORY,
  payload: { category, oldCategoryName },
});

const moveCategory: ActionCreator<ReduxAction> = (fromIndex: number, toIndex: number) => ({
  type: PLANS_ACTIONS.MOVE_CATEGORY,
  payload: { fromIndex, toIndex },
});

const moveRule: ActionCreator<ReduxAction> = (
  ruleId: string,
  fromIndex: number,
  toIndex: number,
  newCategoryName: string | null,
  lastCategoryName: string | null | undefined,
) => ({
  type: PLANS_ACTIONS.MOVE_RULE,
  payload: {
    ruleId,
    newCategoryName,
    fromIndex,
    lastCategoryName,
    toIndex,
  },
});

export const PlanSyncActions = {
  renamePlan,
  setSettings,
  addRulesToPlan,
  patchRules,
  patchRuleKpisToDisplay,
  patchRuleKpiToDisplayStatus,
  patchRuleRemoveKpiToDisplay,
  patchRuleFiltersToDisplay,
  patchRuleFilterToDisplayStatus,
  patchRuleRemoveFilterToDisplay,
  patchRuleFieldsToDisplay,
  patchRuleFieldToDisplayStatus,
  patchRuleRemoveFieldToDisplay,
  removeRule,
  removeRuleById,
  createCategory,
  deleteCategory,
  editCategory,
  moveCategory,
  moveRule,
};

// We're reusing the same thunk dispatcher for those sync actions because they have
// the same behavior. Because of how the endpoint works, we need apply diff to the plan
// based on what was sent by the action (that is made in the reducer), then PUT it to the API.
export const editCurrentPlan =
  (syncAction: ReduxAction): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch, getState) => {
    const plan = selectCurrentPlan(getState());
    // Synchronously apply diff to current plan.
    dispatch(syncAction);

    // Get new plan and try to update it.
    const newPlan = selectCurrentPlan(getState());

    try {
      dispatch(plansStart());
      const updatedPlan = await PlansApiClient.update(newPlan);
      return dispatch(setCurrentPlan(updatedPlan));
    } catch (error) {
      dispatch(plansStart());
      dispatch(setCurrentPlan(plan));
      return dispatch(plansError(error));
    }
  };
// ================== PLAN ARCHIVING ==================
const onArchivePlan: ActionCreator<ReduxAction> = (updatedPlan: Plan, archived: boolean) => ({
  type: PLANS_ACTIONS.ARCHIVE_PLAN,
  payload: { updatedPlan, archived },
});

export const archivePlan =
  (planId: string, archived: boolean): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    try {
      dispatch(plansStart());
      const updatedPlan = await PlansApiClient.archive(planId, archived);
      return dispatch(onArchivePlan(updatedPlan, archived));
    } catch (error) {
      return dispatch(plansError(error));
    }
  };

// ================== PLAN RULES CATEGORIES ==================

const onSetCategories: ActionCreator<ReduxAction> = (ruleCategories: PlanRuleCategory[]) => ({
  type: PLANS_ACTIONS.SET_CATEGORIES,
  payload: { ruleCategories },
});

export const fetchRuleCategories = (): ThunkResult<Promise<ReduxAction>> => async (dispatch) => {
  dispatch(plansStart());
  try {
    const ruleCategories = await PlanRuleCategoriesRepository.list();
    return dispatch(onSetCategories(ruleCategories));
  } catch (error) {
    return dispatch(plansError(error));
  }
};

export const patchRuleCategories =
  (ruleCategories: PlanRuleCategory[]): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(plansStart());
    try {
      // Dispatching before calling the API to avoid flickering effect when reordering.
      const dispatchResult = dispatch(onSetCategories(ruleCategories));
      await Promise.all(ruleCategories.map((rc) => PlanRuleCategoriesRepository.update(rc)));
      return dispatchResult;
    } catch (error) {
      // We modified the store before being sure that we could actually do it.
      // If shit went sideways, reset store state.
      await dispatch(fetchRuleCategories());
      return dispatch(plansError(error));
    }
  };
