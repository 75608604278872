import { omit } from 'lodash';

import {
  type CreatePlanRequest,
  type HighlightedKpi,
  type HighlightedKpiIdentifier,
  type Plan,
} from '@amal-ia/compensation-definition/plans/types';
import { http } from '@amal-ia/frontend/kernel/http';

export class PlansApiClient {
  public static async list(filters?: { userIds?: string[]; planIds?: string[]; periodId?: string }): Promise<Plan[]> {
    let qs = '';
    if (filters) {
      const { userIds, planIds, periodId } = filters;

      qs = [
        ...(userIds ? userIds.map((u) => `userIds=${u}`) : []),
        ...(planIds ? planIds.map((u) => `planIds=${u}`) : []),
        ...(periodId ? [`periodId=${periodId}`] : [null]),
      ]
        .filter(Boolean)
        .join('&');
    }
    const { data } = await http.get<Plan[]>(`/plans?${qs}`);
    return data;
  }

  public static async get(planId: string): Promise<Plan> {
    const relations =
      'planRules,planRules.rule,planAssignements,planRules.categories,highlightedKpis,highlightedKpis.variable,badgeConfigurations';
    const { data } = await http.get<Plan>(`/plans/${planId}?relations=${relations}`);
    return data;
  }

  public static async searchKeyword(keyword: string): Promise<Plan[]> {
    const { data } = await http.get<Plan[]>(`/plans/search/${encodeURI(keyword)}`);
    return data;
  }

  public static async create(plan: CreatePlanRequest): Promise<Plan> {
    const { data } = await http.post<Plan>('/plans/', plan);
    return data;
  }

  public static async update(plan: Plan): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/${plan.id}`, plan);
    return data;
  }

  public static async archive(planId: string, archived: boolean): Promise<Plan> {
    const { data } = await http.put<Plan>(`/plans/archive/${planId}`, { archived });
    return data;
  }

  public static async delete(planId: string): Promise<void> {
    await http.delete(`/plans/${planId}`);
  }

  public static async upsertHighlightedKpi(planId: string, highlightedKpi: HighlightedKpi): Promise<HighlightedKpi> {
    const { data } = await http.put<HighlightedKpi>(
      `/plans/${planId}/highlighted-kpis/${highlightedKpi.identifier}`,
      omit(highlightedKpi, 'identifier'),
    );

    return data;
  }

  public static async deleteHighlightedKpi(planId: string, identifier: HighlightedKpiIdentifier) {
    await http.delete(`/plans/${planId}/highlighted-kpis/${identifier}`);
  }
}
