import { type TabsProps as MuiTabsProps, Tabs as MuiTabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  tabHeader: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.grey[700],
    '& .MuiTab-textColorInherit': {
      opacity: 1,
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& span': {},
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.tertiary.main,
    },
    '& button': {
      minWidth: 'unset',
      padding: '6px 0',
    },
  },
}));

interface TabsProps extends MuiTabsProps {
  readonly width?: number;
}

export const Tabs = memo(function Tabs({ width, ...props }: TabsProps) {
  const classes = useStyles();
  return (
    <MuiTabs
      {...props}
      className={classes.tabHeader}
      style={{ width: `${width || 50}%` }}
    />
  );
});
