import { defineMessages } from 'react-intl';

export const formulaConditionTagMessages = defineMessages({
  CUSTOM_CONDITION: {
    defaultMessage: 'Custom condition',
  },

  REMOVE_CONDITION: {
    defaultMessage: 'Remove condition',
  },

  CLICK_TO_EDIT_FORMULA: {
    defaultMessage: 'Click to edit formula',
  },
} as const);
