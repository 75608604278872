import { IconEyeX } from '@tabler/icons-react';
import { get, noop } from 'lodash';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { type FormulaBuilderFormulaBlockForm } from '@amal-ia/amalia-lang/formula/form';
import { type AmaliaFormula } from '@amal-ia/amalia-lang/formula/shared/types';
import { useBoolState, useDebouncedOnChange } from '@amal-ia/ext/react/hooks';
import { IconButton, Input, InputSize } from '@amal-ia/frontend/design-system/components';

import { useFormulaEditorContent } from '../../../hooks/use-formula-editor-content/useFormulaEditorContent';
import { useFormulaEditorTokens } from '../../../hooks/use-formula-editor-tokens/useFormulaEditorTokens';
import { useFormulaBuilderContext } from '../../formula-builder/FormulaBuilder.context';
import { FormulaEditorField } from '../../formula-editor-field/FormulaEditorField';

import { formulaPortalMessages } from './FormulaPortal.messages';
import * as styles from './FormulaPortal.styles';
import { formulaPortalTestIds } from './FormulaPortal.testIds';

export type FormulaPortalProps = {
  /** Condition. */
  readonly condition: FormulaBuilderFormulaBlockForm;
  /** Path to node in builder. */
  readonly path: string;
  /** Change handler. */
  readonly onChange?: (condition: FormulaBuilderFormulaBlockForm) => void;
};

export const FormulaPortal = memo(function FormulaPortal({ condition, path, onChange = noop }: FormulaPortalProps) {
  const { editor, setActiveConditionId, errors, planObjects, enableFormulaAutocomplete } = useFormulaBuilderContext();

  const intl = useIntl();
  const { isLabelTouched, setLabelTouchedTrue } = useBoolState(false, 'labelTouched');
  const { isFormulaTouched, setFormulaTouchedTrue } = useBoolState(false, 'formulaTouched');
  const [label, setLabel] = useState(condition.label);

  const handleHideTab = useCallback(() => setActiveConditionId?.(null), [setActiveConditionId]);

  const handleChangeLabel = useCallback(
    (label: string) =>
      onChange({
        ...condition,
        label,
      }),
    [onChange, condition],
  );

  useDebouncedOnChange(label, handleChangeLabel);

  const handleChangeFormula = useCallback(
    (formula: AmaliaFormula) =>
      onChange({
        ...condition,
        isDraft: false,
        formula,
      }),
    [condition, onChange],
  );

  const { tokens } = useFormulaEditorTokens(planObjects);
  const editorContent = useFormulaEditorContent(condition.formula, tokens, planObjects, enableFormulaAutocomplete);

  return (
    <div css={styles.formulaPortal}>
      <div css={styles.nameFieldContainer}>
        <IconButton
          icon={<IconEyeX />}
          label={intl.formatMessage(formulaPortalMessages.HIDE_CONDITION)}
          onClick={handleHideTab}
        />

        <div css={styles.inputContainer}>
          <Input
            required
            autoFocus={condition.isDraft}
            data-testid={formulaPortalTestIds.labelField(condition.id)}
            error={isLabelTouched ? get(errors, `${path}.label`) : null}
            label={<FormattedMessage {...formulaPortalMessages.CONDITION_NAME} />}
            name={`${path}.label`}
            size={InputSize.SMALL}
            value={label}
            onBlur={setLabelTouchedTrue}
            onChange={setLabel}
          />
        </div>
      </div>

      {editor && !editor.isDestroyed ? (
        <FormulaEditorField
          css={styles.formulaEditor}
          data-testid={formulaPortalTestIds.formulaField(condition.id)}
          editor={editor}
          error={isFormulaTouched ? get(errors, `${path}.formula`) : null}
          label={<FormattedMessage {...formulaPortalMessages.FORMULA} />}
          name={`${path}.formula`}
          value={editorContent}
          onBlur={setFormulaTouchedTrue}
          onChange={handleChangeFormula}
        />
      ) : null}
    </div>
  );
});
