import { type CSSProperties } from 'react';

import { type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { type Property } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { AMALIA_OBJECTS_HIGHLIGHT_COLOR } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';

import { IFormulaNode, FormulaNodeEnum } from './IFormulaNode';

export class PropertyFormulaNode extends IFormulaNode {
  public constructor(
    partialFormula: string,
    startIndex: number,
    nodeObject: Property,
    objectDefinition?: CustomObjectDefinition,
    relationship?: Relationship,
  ) {
    super(partialFormula, startIndex, FormulaNodeEnum.OBJECT_FIELD, nodeObject, objectDefinition, relationship);
  }

  public getStyles(): CSSProperties {
    return { backgroundColor: AMALIA_OBJECTS_HIGHLIGHT_COLOR.object };
  }

  public getDesignerPath(): { type: TokenType; id: string; definitionMachinename?: string } {
    const nodeObject = this.nodeObject as Property;
    const nodeId = nodeObject?.machineName;

    return {
      type: TokenType.PROPERTY,
      id: nodeId,
      definitionMachinename: this.objectDefinition.machineName,
    };
  }

  public getLabel(): string {
    return (this.nodeObject as Property).name.trim();
  }
}
