import moment from 'moment';

import {
  type AmaliaFormula,
  AmaliaFunctionCategory,
  AmaliaFunctionKeys,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { ConfigurationError, TokenType } from '@amal-ia/lib-types';

import AmaliaFunction from '../../AmaliaFunction';

const func = new AmaliaFunction(AmaliaFunctionKeys.dateOffset, AmaliaFunctionCategory.DATES);

func.nbParamsRequired = 3;

func.description = 'Offsets a date.';

func.params = [
  {
    name: 'date',
    description:
      'Date to use as reference: variables, properties, fields, keywords or date with format toDate("YYYY-MM-DD")',
    validTokenTypes: [TokenType.VARIABLE, TokenType.PROPERTY, TokenType.FIELD, TokenType.KEYWORD, TokenType.FUNCTION],
    validTokenValues: {
      [TokenType.FUNCTION]: [AmaliaFunctionKeys.toDate],
    },
    validFormats: [FormatsEnum.date, FormatsEnum['date-time']],
  },
  { name: 'offsetValue', description: 'Number of days, months, years, to offset (can be negative)' },
  { name: 'interval', description: 'Interval of the offset (can be `day` or `month` or `year`)' },
];

func.examples = [
  {
    desc: 'Returns "2023-03-19"',
    formula: 'dateOffset(toDate("2023-05-19"), -2, "month")' as AmaliaFormula,
    result: '1679184000',
  },
  {
    desc: 'Returns "2023-05-19"',
    formula: 'dateOffset(toDate("2023-05-17"), 2, "day")' as AmaliaFormula,
    result: '1684454400',
  },
  {
    desc: 'Returns a date 2 months before the Close Date.',
    formula: 'dateOffset(opportunity.closeDate, -2, "month")' as AmaliaFormula,
  },
  {
    desc: 'Returns a date 1 year after the statement start date.',
    formula: 'dateOffset(statementPeriod.startDate, 1, "year")' as AmaliaFormula,
  },
];

func.exec = (date: moment.MomentInput | string, offsetValue: number | string, interval: string): string | null => {
  if (!['day', 'month', 'year'].includes(interval)) {
    throw new ConfigurationError(`Offset value "${interval}" should be one of the following: day, month, year`);
  }
  return moment(date, 'X')
    .add(offsetValue, interval as 'day' | 'month' | 'year')
    .format('X');
};

export default func;
