import { css } from '@emotion/react';
import { IconMedal } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@amal-ia/frontend/design-system/components';

export const RuleChallengeBadge = memo(function RuleChallengeBadge() {
  return (
    <div
      css={(theme) => css`
        display: flex;
        align-items: center;
        gap: 2px;
        color: ${theme.ds.hues.purple[700]};
        background-color: ${theme.ds.hues.purple[50]};
        border-radius: ${theme.ds.borderRadiuses.round};
        padding: 2px 8px 2px 6px;
      `}
    >
      <IconMedal
        size={16}
        css={css`
          flex: none;
        `}
      />

      <Typography variant={Typography.Variant.BODY_XSMALL_MEDIUM}>
        <FormattedMessage defaultMessage="Challenge" />
      </Typography>
    </div>
  );
});
