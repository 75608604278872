import { type Dispatch, type SetStateAction, memo, useMemo } from 'react';

import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { type OverwriteCreationRequestDetails } from '@amal-ia/data-capture/overwrites/components';
import { Typography } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { useCompanyCustomization } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, subject } from '@amal-ia/lib-rbac';
import {
  type ComputedVariable,
  isBeingReviewed,
  type ComputedVariableWithVariableIdAndLabel,
} from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { type ComputeEngineResult } from '@amal-ia/payout-calculation/shared/types';

import ForecastedKPI from '../forecasted-kpi/ForecastedKPI';
import OverwritableKPI from '../overwritable-kpi/OverwritableKPI';
import { type SectionWithComputedKPIS } from '../useKPIDisplay';
import { useKPIThread } from '../useKPIThread';

import * as styles from './Section.styles';
import { SectionContainer } from './Section.styles';

type SectionProps = {
  readonly section: SectionWithComputedKPIS;
  readonly ruleDefinition: PlanRule;
  readonly clearOverwrite: (variable: ComputedVariable<ComputeEngineResult>) => Promise<void>;
  readonly openOverwriteModal: () => void;
  readonly setOverwriteObjectDetails: Dispatch<SetStateAction<OverwriteCreationRequestDetails>>;
  readonly statementRuleComputedVariables: ComputedVariableWithVariableIdAndLabel[];
};

export const Section = memo(function Section({
  section,
  ruleDefinition,
  clearOverwrite,
  openOverwriteModal,
  setOverwriteObjectDetails,
  statementRuleComputedVariables,
}: SectionProps) {
  const ability = useAbilityContext();
  const statementContext = useStatementDetailContext();
  const { legacyKpiCardView } = useCompanyCustomization();
  const isWorkflowBegun = useMemo(() => isBeingReviewed(statementContext), [statementContext]);
  const { openStatementThread, threadsPerKpi } = useKPIThread(
    statementContext,
    ruleDefinition,
    statementRuleComputedVariables,
  );

  return (
    <SectionContainer>
      {!!section.name && (
        <Typography
          css={styles.sectionName}
          variant={Typography.Variant.BODY_LARGE_MEDIUM}
        >
          {section.name}
        </Typography>
      )}
      <div css={[styles.kpisContainer, legacyKpiCardView ? styles.cardViewGrid : styles.kpiList]}>
        {section.kpis.map((variable) =>
          statementContext.isForecastedView ? (
            <ForecastedKPI
              key={variable.id}
              computedVariable={variable}
              openOverwriteModal={openOverwriteModal}
              resetOverwrite={clearOverwrite}
              ruleDefinition={ruleDefinition}
              setOverwriteObjectDetails={setOverwriteObjectDetails}
              variableDefinition={statementContext.results.definitions.variables[variable.variableMachineName]}
              canSimulate={
                ability.can(
                  ActionsEnum.simulate,
                  subject(SubjectsEnum.Forecasted_Statement, { ...statementContext }),
                ) && !!statementContext?.plan.isSimulationEnabled
              }
            />
          ) : (
            <OverwritableKPI
              key={variable.id}
              computedVariable={variable}
              isReadOnly={isWorkflowBegun || ability.cannot(ActionsEnum.overwrite, SubjectsEnum.Statement)}
              openOverwriteModal={openOverwriteModal}
              openStatementThread={openStatementThread}
              resetOverwrite={clearOverwrite}
              ruleDefinition={ruleDefinition}
              setOverwriteObjectDetails={setOverwriteObjectDetails}
              statementThread={threadsPerKpi[variable.id]}
              variableDefinition={statementContext.results.definitions.variables[variable.variableMachineName]}
            />
          ),
        )}
      </div>
    </SectionContainer>
  );
});
