import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Box } from '@mui/material';
import { memo, type ReactNode, Fragment } from 'react';

// PLUGINS
export enum DataGridPluginPosition {
  left = 'left',
  rightEnd = 'right-end',
  rightStart = 'right-start',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left',
}

export interface DataGridPluginDefinition {
  key: string;
  children: ReactNode;
  position: DataGridPluginPosition;
}

export interface DataGridPluginsProps {
  readonly plugins: DataGridPluginDefinition[];
}

export const DataGridPlugins = memo(function DataGridPlugins({ plugins }: DataGridPluginsProps) {
  return (
    <Plugin>
      <Template name="toolbarContent">
        <Box width="100%">
          <Box
            display="flex"
            flexDirection="row-reverse"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              width="100%"
            >
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.rightStart)
                .map((p) => (
                  <Fragment key={p.key}>{p.children}</Fragment>
                ))}
              <TemplatePlaceholder />
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.rightEnd)
                .map((p) => (
                  <Fragment key={p.key}>{p.children}</Fragment>
                ))}
            </Box>
          </Box>
          <Box width="100%">
            <Box
              display="flex"
              flexDirection="row-reverse"
              width="100%"
            >
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.bottomLeft)
                .map((p) => (
                  <Box
                    key={p.key}
                    width="100%"
                  >
                    {p.children}
                  </Box>
                ))}
              {plugins
                .filter((p) => p.position === DataGridPluginPosition.bottomRight)
                .map((p) => (
                  <Box
                    key={p.key}
                    width="100%"
                  >
                    {p.children}
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Template>
    </Plugin>
  );
});
