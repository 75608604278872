import { CSS } from '@dnd-kit/utilities';
import { css, useTheme } from '@emotion/react';
import { type ReactNode, memo, useCallback } from 'react';

import { Sortable } from '@amal-ia/ext/dnd-kit';

import { Switch, type SwitchProps } from '../../../../data-input/switch/Switch';
import { GrabHandle } from '../../../../general/grab-handle/GrabHandle';
import { DropdownItemContent } from '../../../../overlays/dropdown/dropdown-item-content/DropdownItemContent';
import { type ColumnDefinition } from '../../../table/Table.types';

import * as styles from './ColumnOrderAndVisibilityMenuItem.styles';
import { columnOrderAndVisibilityTestIds } from './ColumnOrderAndVisibilityMenuItem.testIds';

export type ColumnOrderAndVisibilityMenuItemProps = {
  readonly id: ColumnDefinition['id'];
  readonly icon?: ColumnDefinition['icon'];
  readonly label: ReactNode;
  readonly isVisible: boolean;
  readonly disableReordering?: boolean;
  readonly disableVisibility?: boolean;
  readonly onChangeIsVisible?: (id: ColumnDefinition['id'], activated: boolean) => void;
};

export const ColumnOrderAndVisibilityMenuItem = memo(function ColumnOrderAndVisibilityMenuItem({
  id,
  icon,
  label,
  isVisible,
  disableReordering,
  disableVisibility,
  onChangeIsVisible,
}: ColumnOrderAndVisibilityMenuItemProps) {
  const theme = useTheme();

  const handleChangeActivated: Required<SwitchProps>['onChange'] = useCallback(
    (activated) => onChangeIsVisible?.(id, activated),
    [id, onChangeIsVisible],
  );

  return (
    <Sortable
      disabled={disableReordering}
      id={id}
    >
      {({ attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging, transition }) => (
        <li
          ref={setNodeRef}
          css={[
            styles.columnOrderAndVisibilityMenuItem,
            css`
              transform: ${CSS.Transform.toString(transform)};
              transition: ${[transition, theme.ds.transitions.default('box-shadow')].join(', ')};
              z-index: ${isDragging ? 1 : 0};
              box-shadow: ${isDragging ? theme.ds.shadows.hard : 'none'};
            `,
          ]}
        >
          <DropdownItemContent
            icon={icon}
            label={label}
            grabHandle={
              disableReordering ? undefined : (
                <GrabHandle
                  {...attributes}
                  {...listeners}
                  ref={setActivatorNodeRef}
                />
              )
            }
            rightActions={
              disableVisibility ? undefined : (
                <Switch
                  checked={isVisible}
                  data-testid={columnOrderAndVisibilityTestIds.switch(id)}
                  size={Switch.Size.SMALL}
                  onChange={handleChangeActivated}
                />
              )
            }
          />
        </li>
      )}
    </Sortable>
  );
});
