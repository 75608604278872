import { type RuleType } from '@amal-ia/compensation-definition/plans/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type Company } from '@amal-ia/tenants/companies/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export interface DataExportRequest {
  format: DataExportFormat;
  type: DataExportType;
  params: DataExportParams;
}

export enum DataExportStatus {
  // New export waiting to be launched
  PENDING = 'PENDING',
  // Export has started
  STARTED = 'STARTED',
  // Export has been done
  SUCCESS = 'SUCCESS',
  // Export has failed
  ERROR = 'ERROR',
}

export enum DataExportType {
  STATEMENT = 'STATEMENT',
  COMMISSION = 'COMMISSION',
  CUSTOM_REPORT = 'CUSTOM_REPORT',
  PAYMENT_DIFF = 'PAYMENT_DIFF',
}

export enum DataExportFormat {
  EXCEL = 'EXCEL',
  PERSONIO = 'PERSONIO',
  PAYFIT = 'PAYFIT',
  PDF = 'PDF',
}

export interface DataExportProperties {
  contentType: string;
  extension: string;
  filename?: string;
}

export type DataExportParams = {
  customReportId?: string;
  periodIds?: string[];
  planIds?: string[];
  ruleIds?: string[];
  teamIds?: string[];
  userIds?: string[];

  isForecast?: boolean;
  paymentPeriodIds?: string[];
  currencies?: CurrencySymbolsEnum[];
  ruleTypes?: RuleType[];
  recordType?: string;
};

export type DataExport = {
  id: string | undefined;
  type: DataExportType;
  format: DataExportFormat;
  status: DataExportStatus;
  createdAt: Date;
  creator: UserContract;
  company?: Company;
  params: DataExportParams;
  properties: DataExportProperties;
};

export type PdfExportStatementRule = {
  name: string;
  type: string;
  total: string;
  kpis: {
    name: string;
    value: string;
  }[];
  dataColumns: {
    name: string;
    label: string;
  }[];
  data: Record<string, string>[];
};

export type PdfExportHighlightedKpi = {
  id: 'primary' | 'secondary';
  name: string;
  value: string;
};

export type PdfExportStatement = {
  salesRep: string;
  profilePictureB64?: string;
  planName: string;
  periodName: string;
  total: string;
  workflowComplete: boolean;
  highlightedKpis: PdfExportHighlightedKpi[];
  rules: PdfExportStatementRule[];
  teams: string[];
};

export type ExportCustomReportPreviewRow = {
  period: string;
  firstName: string;
  lastName: string;
  plan: string;
  rule: string;
  ruleType: string;
  totalCommission: string;
  totalPaid: string;
  totalRemaining: string;
  currency: string;
};
