import { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { RootRoutes, UseParams } from '@amal-ia/ext/react-router-dom';
import { Layout } from '@amal-ia/frontend/connected-components/layout';
import { AbilityRouteProtector, useCurrentUser } from '@amal-ia/frontend/kernel/authz/context';
import { AmaliaHead } from '@amal-ia/frontend/kernel/head';
import { ChangelogView } from '@amal-ia/frontend/views/amalia-meta/changelog';
import { SuperAdminRoutes } from '@amal-ia/frontend/views/amalia-meta/superadmin';
import { DesignerRoutes } from '@amal-ia/frontend/views/compensation-definition/designer';
import { PlansRoutes, QuotasRoutes, TeamRoutes } from '@amal-ia/frontend/views/compensation-definition/management';
import { DataExports, StatementsRoutes } from '@amal-ia/frontend/views/compensation-results/statements';
import { TodosView } from '@amal-ia/frontend/views/compensation-results/todos-table';
import { CustomObjectRoutes, OAuthCallback } from '@amal-ia/frontend/views/data-capture/data';
import { FlowRoutes } from '@amal-ia/frontend/views/data-capture/flows';
import { PaymentView } from '@amal-ia/frontend/views/reporting/commission-report';
import { CustomReportRoutes } from '@amal-ia/frontend/views/reporting/custom-reports';
import { DashboardView, HomeRedirectView, HomeView } from '@amal-ia/frontend/views/reporting/homepage';
import { OverwritesRoutes } from '@amal-ia/frontend/views/reporting/overwrites-report';
import { AuditRoutes } from '@amal-ia/frontend/views/tenants/audit';
import { IntegrationOauthCallback } from '@amal-ia/frontend/views/tenants/company-settings';
import { PlanAgreementsRoutes } from '@amal-ia/frontend/views/tenants/plan-agreements';
import { DirectoryPage, NewInvitationPage } from '@amal-ia/frontend/views/tenants/user';
import { checkLocalStorageOnLoad, useFeatureFlag } from '@amal-ia/frontend/web-data-layers';
import { ActionsEnum, SubjectsEnum, userProfileSubject } from '@amal-ia/lib-rbac';
import { CompanySettings } from '@amal-ia/tenants/companies/settings/layout';
import { CompanyFeatureFlags } from '@amal-ia/tenants/companies/types';
import { DirectoryView } from '@amal-ia/tenants/users/profile/views/directory';
import { MemberProfileView } from '@amal-ia/tenants/users/profile/views/member';
import { CurrentUserProfileView } from '@amal-ia/tenants/users/profile/views/profile';
import { tenantUsersRoutes } from '@amal-ia/tenants/users/shared/routes';

const App = function App() {
  const { data: user } = useCurrentUser();
  const { formatMessage } = useIntl();

  const { isFeatureEnabled: isRepHomeFFEnabled } = useFeatureFlag(CompanyFeatureFlags.REP_HOME);
  const { isFeatureEnabled: isDashboardFFEnabled } = useFeatureFlag(CompanyFeatureFlags.DASHBOARDS);

  useEffect(
    // Clear localstorage if company is different
    () => user && checkLocalStorageOnLoad(user),
    [user],
  );

  return (
    <RootRoutes>
      <Route
        path={routes.AUTH_CONNECTOR_CALLBACK}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <OAuthCallback />
          </Fragment>
        }
      />

      <Route
        path={routes.AUTH_INTEGRATION_CALLBACK}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Connection' })} />
            <IntegrationOauthCallback />
          </Fragment>
        }
      />

      <Route
        path={routes.HOME}
        element={
          <AbilityRouteProtector
            can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.RepHome) && isRepHomeFFEnabled}
          >
            <AmaliaHead title={formatMessage({ defaultMessage: 'Home' })} />
            <HomeView />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.DATA_EXPORT}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Data export' })} />
            <DataExports />
          </Fragment>
        }
      />

      <Route
        element={<DesignerRoutes />}
        path={`${routes.DESIGNER}/*`}
      />

      <Route
        element={<PlansRoutes />}
        path={`${routes.PLANS}/*`}
      />

      <Route
        element={<TeamRoutes />}
        path={`${routes.TEAM_LIST}/*`}
      />

      <Route
        element={<QuotasRoutes />}
        path={`${routes.QUOTAS}/*`}
      />

      <Route
        element={<AuditRoutes />}
        path={`${routes.AUDIT}/*`}
      />

      <Route
        element={<PlanAgreementsRoutes />}
        path={`${routes.PLAN_AGREEMENTS}/*`}
      />

      <Route
        path={routes.CHANGELOG}
        element={
          <Fragment>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Changelog' })} />
            <ChangelogView />
          </Fragment>
        }
      />

      <Route
        element={<OverwritesRoutes />}
        path={`${routes.OVERWRITES}/*`}
      />

      <Route
        path={routes.COMMISSION_REPORT}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Payment)}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Payments' })} />
            <PaymentView />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={tenantUsersRoutes.PROFILE_PAGE}
        element={
          <Layout currentPage="directory">
            <CurrentUserProfileView />
          </Layout>
        }
      />

      <Route
        path={tenantUsersRoutes.MEMBER_PAGE}
        element={
          <UseParams<{ memberId: string }>>
            {({ memberId }) => (
              <Layout currentPage="directory">
                <AbilityRouteProtector
                  can={(ability) => ability.can(ActionsEnum.view, userProfileSubject({ id: memberId }))}
                >
                  <MemberProfileView />
                </AbilityRouteProtector>
              </Layout>
            )}
          </UseParams>
        }
      />

      <Route
        path={routes.COMPANY_DIRECTORY_CREATE}
        element={
          <Layout currentPage="directory">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.modify, userProfileSubject())}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'New directory' })} />
              <NewInvitationPage />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={routes.COMPANY_DIRECTORY}
        element={
          <Layout currentPage="directory">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_directory, SubjectsEnum.Company)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Directory' })} />
              <DirectoryPage />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        path={tenantUsersRoutes.DIRECTORY_PAGE}
        element={
          <Layout currentPage="directory">
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view_directory, SubjectsEnum.Company)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Directory' })} />
              <DirectoryView />
            </AbilityRouteProtector>
          </Layout>
        }
      />

      <Route
        element={<CustomReportRoutes />}
        path={`${routes.CUSTOM_REPORT_LIST}/*`}
      />

      <Route
        path={`${routes.DATA}/*`}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.Data)}>
            <CustomObjectRoutes />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={`${routes.COMPANY_SETTINGS}/*`}
        element={
          <AbilityRouteProtector
            can={(ability) =>
              ability.can(ActionsEnum.view_rates, SubjectsEnum.Company) ||
              ability.can(ActionsEnum.view_directory, SubjectsEnum.Company) ||
              ability.can(ActionsEnum.view_settings, SubjectsEnum.Company)
            }
          >
            <CompanySettings />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.TODOS}
        element={
          <Layout>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Todos' })} />
            <TodosView />
          </Layout>
        }
      />

      <Route
        path={`${routes.SUPERADMIN}/*`}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <SuperAdminRoutes />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.DASHBOARD_START_DATE}
        element={
          <AbilityRouteProtector can={() => isDashboardFFEnabled}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Dashboard' })} />
            <DashboardView />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.DASHBOARD}
        element={
          <AbilityRouteProtector can={() => isDashboardFFEnabled}>
            <AmaliaHead title={formatMessage({ defaultMessage: 'Dashboard' })} />
            <DashboardView />
          </AbilityRouteProtector>
        }
      />

      <Route
        element={<FlowRoutes />}
        path={`${routes.FLOWS}/*`}
      />

      <Route
        element={<StatementsRoutes />}
        path={`${routes.STATEMENTS}/*`}
      />

      <Route
        element={<StatementsRoutes />}
        path={`${routes.FORECASTS}/*`}
      />

      <Route
        element={<HomeRedirectView />}
        path="*"
      />
    </RootRoutes>
  );
};

export default App;
