import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  REMOVE_CONDITION_GROUP_MODAL_TITLE: {
    defaultMessage: 'Delete group of conditions',
  },
  REMOVE_CONDITION_GROUP_MODAL_CONTENT: {
    defaultMessage: 'Are you sure you want to delete this group?',
  },
} as const);
