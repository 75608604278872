import { faker } from '@faker-js/faker';
import { without } from 'lodash';

import { type Variable, VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';

export type DefaultMockVariable = Variable;

export class MockVariableBuilder<TVariable extends Variable = DefaultMockVariable> {
  private readonly properties: TVariable;

  public static new<TVariable extends Variable = DefaultMockVariable>(): MockVariableBuilder<TVariable> {
    return new MockVariableBuilder<TVariable>();
  }

  public static from<TVariable extends Variable = DefaultMockVariable>(
    initialValues: Partial<TVariable>,
  ): MockVariableBuilder<TVariable> {
    return new MockVariableBuilder<TVariable>(initialValues);
  }

  public with<TProperty extends keyof TVariable>(property: TProperty, value: TVariable[TProperty]): this {
    this.properties[property] = value;

    return this;
  }

  public build(): TVariable {
    return { ...this.properties };
  }

  protected constructor(initialValues: Partial<TVariable> = {}) {
    this.properties = {
      id: faker.string.uuid(),
      createdAt: faker.date.past(),
      updatedAt: faker.date.recent(),

      name: faker.commerce.productName(),
      // FIXME: after type split
      isQuotaRequired: false,
      format: faker.helpers.enumValue(FormatsEnum),
      frequency: faker.helpers.enumValue(PeriodFrequencyEnum),
      type: faker.helpers.arrayElement(
        without(
          Object.values(VariableObjectsEnum),
          VariableObjectsEnum.plan,
          VariableObjectsEnum.team,
          VariableObjectsEnum.user,
        ),
      ),
      ...initialValues,
    } as TVariable;
  }
}
