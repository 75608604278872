import { css, useTheme } from '@emotion/react';
import { Fragment, memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { type PeriodFrequencyEnum, RelativePeriodKeyword } from '@amal-ia/compensation-definition/periods/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { Skeleton } from '@amal-ia/frontend/design-system/components';
import { CustomReportsPresetsEnum, type PayoutAndPerformanceChartStatistics } from '@amal-ia/lib-types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

import { DashboardWidget } from '../DashboardWidget/DashboardWidget';
import { type PayoutMode } from '../types/payoutMode';
import { WidgetTitle } from '../WidgetTitle/WidgetTitle';

import { PayoutAndPerformanceChartAlert } from './alert/PayoutAndPerformanceChartAlert';
import { BarLabel } from './bar/BarLabel';
import { formatChartValue } from './chartValue.formatter';
import { Legend as CustomLegend } from './legend/Legend';
import { PayoutModeButton } from './payout-mode-button/PayoutModeButton';
import { payoutAndPerformanceChartMessages } from './PayoutAndPerformanceChart.messages';
import { SeriesSelector } from './series-selector/SeriesSelector';
import { Tooltip as CustomTooltip } from './tooltip/Tooltip';
import { useChartSeries } from './useChartSeries';
import { MonthAxisTick } from './x-axis-months/MonthAxisTick';
import { useAmountTicks } from './y-axis-amount/useAmountTicks';

export type PayoutAndPerformanceChartProps = Readonly<{
  user: UserContract;
  isLoading: boolean;
  isErrorLoading: boolean;
  currency: CurrencySymbolsEnum;
  statistics: PayoutAndPerformanceChartStatistics;
  payoutMode: PayoutMode;
  setPayoutMode: (payoutMode: PayoutMode) => void;
  shouldDisplayKpis?: boolean;
  frequency?: PeriodFrequencyEnum;
}>;

const BAR_WIDTH_SMALL = 32;
const BAR_WIDTH_LARGE = 64;
const BAR_RADIUS: [number, number, number, number] = [4, 4, 0, 0];
// By default it's all at 5 but we need 20 top to let appear the value above the bars
const CHART_MARGIN = { top: 20, right: 5, bottom: 5, left: 5 };

/**
 * Payout and performance chart: displays payout as bar chart and kpis as line chart.
 * You can choose between 2 payout modes: last 12 months or year to date.
 */
export const PayoutAndPerformanceChart = memo(function PayoutAndPerformanceChart({
  user,
  statistics,
  currency,
  isLoading,
  isErrorLoading,
  payoutMode,
  setPayoutMode,
  frequency,
}: PayoutAndPerformanceChartProps) {
  const theme = useTheme();
  const { formatMessage, formatNumber } = useIntl();

  const statisticsRecords = useMemo(() => {
    switch (payoutMode) {
      case RelativePeriodKeyword.LAST_12_MONTHS:
        return statistics?.last12MonthsRecords ?? [];
      case RelativePeriodKeyword.YEAR_TO_DATE:
        return statistics?.ytdRecords ?? [];
      default:
        throw new Error(`Unknown payout mode: ${payoutMode}`);
    }
  }, [payoutMode, statistics?.last12MonthsRecords, statistics?.ytdRecords]);

  const barSize = statisticsRecords?.length > 6 ? BAR_WIDTH_SMALL : BAR_WIDTH_LARGE;

  const amountTickFormatter = useCallback(
    (value: number) => formatChartValue(value, statistics?.definitions.ruleMetricPayment__value.format, currency),
    [currency, statistics],
  );

  const {
    seriesMetadata,
    kpi1DataKey,
    kpi2DataKey,
    isKpi1Selected,
    isKpi2Selected,
    isAmountSelected,
    setHiddenSeries,
  } = useChartSeries(statistics);

  const shouldDisplayPercentAxis = isKpi1Selected || isKpi2Selected;

  // We need to set the value to 0 if it is null or undefined, otherwise the chart will display a gap
  const statisticsAdjusted = useMemo(
    () => ({
      ...statistics,
      records: statisticsRecords?.map((record) => ({
        ...record,
        ruleMetricPayment__value: record.ruleMetricPayment__value ?? { value: 0, symbol: currency },
        [kpi1DataKey]: record[kpi1DataKey] ?? 0,
        [kpi2DataKey]: record[kpi2DataKey] ?? 0,
      })),
    }),
    [currency, kpi1DataKey, kpi2DataKey, statistics, statisticsRecords],
  );

  const amountTicks = useAmountTicks(statisticsAdjusted, ['ruleMetricPayment__value'], [kpi1DataKey, kpi2DataKey]);

  const percentFormatter = useCallback(
    (value: number) => formatNumber(value, { style: 'percent', maximumFractionDigits: 2 }),
    [formatNumber],
  );

  return (
    <DashboardWidget>
      <WidgetTitle
        customReportId={CustomReportsPresetsEnum.PRESET_EARNED_OVER_TIME}
        title={formatMessage(payoutAndPerformanceChartMessages.COMMISSION_AND_PERFORMANCE_EVOLUTION)}
        userRole={user.role}
        actions={
          <Fragment>
            <PayoutModeButton
              payoutMode={payoutMode}
              setPayoutMode={setPayoutMode}
            />
            <SeriesSelector
              seriesMetadata={seriesMetadata}
              setHiddenSeries={setHiddenSeries}
            />
          </Fragment>
        }
      />

      {isLoading ? (
        <Skeleton
          visible
          css={css`
            width: 100%;
            height: 300px;
          `}
        />
      ) : (
        <Fragment>
          <PayoutAndPerformanceChartAlert
            isKpiAvailable
            isPlanSelected
            isError={isErrorLoading}
            recordsLength={statisticsAdjusted?.records?.length}
          />
          <ResponsiveContainer
            height="100%"
            width="100%"
          >
            <ComposedChart
              data={statisticsAdjusted?.records}
              margin={CHART_MARGIN}
            >
              <CartesianGrid
                stroke={theme.ds.colors.gray[100]}
                vertical={false}
              />
              <XAxis
                dataKey={statisticsAdjusted?.definitions?.ruleMetricPeriod__month?.identifier}
                tick={<MonthAxisTick frequency={frequency} />}
                tickLine={false}
              />

              {!!isAmountSelected && !!amountTicks.left && (
                <YAxis
                  axisLine={false}
                  domain={amountTicks.left.domain}
                  orientation="left"
                  tickFormatter={amountTickFormatter}
                  tickLine={false}
                  ticks={amountTicks.left.ticks}
                />
              )}

              {!!shouldDisplayPercentAxis && !!amountTicks.right && (
                <YAxis
                  axisLine={false}
                  domain={amountTicks.right.domain}
                  orientation={isAmountSelected ? 'right' : 'left'}
                  tickFormatter={percentFormatter}
                  tickLine={false}
                  ticks={amountTicks.right.ticks}
                  yAxisId="percent"
                />
              )}

              <Tooltip
                active
                content={
                  <CustomTooltip
                    currency={currency}
                    frequency={frequency}
                    seriesMetadata={seriesMetadata}
                  />
                }
              />

              {!!isAmountSelected && (
                <Bar
                  barSize={barSize}
                  dataKey={`${statisticsAdjusted?.definitions?.ruleMetricPayment__value?.identifier}.value`}
                  fill={theme.ds.hues.blue[900]}
                  radius={BAR_RADIUS}
                >
                  <LabelList
                    dataKey={`${statisticsAdjusted?.definitions?.ruleMetricPayment__value?.identifier}.value`}
                    position="top"
                    content={
                      <BarLabel
                        currency={currency}
                        statistics={statisticsAdjusted}
                      />
                    }
                  />
                </Bar>
              )}

              {!!isKpi1Selected && (
                <Line
                  dataKey={kpi1DataKey}
                  stroke={seriesMetadata[kpi1DataKey].color}
                  strokeWidth={2}
                  type="monotone"
                  yAxisId="percent"
                />
              )}

              {!!isKpi2Selected && (
                <Line
                  dataKey={kpi2DataKey}
                  stroke={seriesMetadata[kpi2DataKey].color}
                  strokeWidth={2}
                  type="monotone"
                  yAxisId="percent"
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <CustomLegend seriesRecords={seriesMetadata} />
        </Fragment>
      )}
    </DashboardWidget>
  );
});
