import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { http } from '@amal-ia/frontend/kernel/http';

const apiEndpoint = '/currency_rates';

export class CurrencyRatesApiClient {
  public static async getCurrencyRate(symbol: CurrencySymbolsEnum, date: number): Promise<number> {
    const { data } = await http.get<number>(`${apiEndpoint}/${symbol}?date=${date}`);
    return data;
  }
}
