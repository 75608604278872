import { keyBy, sortBy, unionBy } from 'lodash';
import { useMemo } from 'react';

import {
  type FormulaKeywordDisplayDetails,
  useFormulaKeywordsDisplayDetails,
} from '@amal-ia/amalia-lang/formula/keywords';
import { FORMULA_KEYWORDS } from '@amal-ia/amalia-lang/formula/shared/keywords';
import { AmaliaFunctionCategory, FormulaKeyword } from '@amal-ia/amalia-lang/formula/shared/types';
import { type Variable, VariableObjectsEnum } from '@amal-ia/amalia-lang/tokens/types';
import { type Filter } from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CustomObjectDefToDataConnectorType } from '@amal-ia/data-capture/models/types';
import { TokenType, type TracingTypes } from '@amal-ia/lib-types';
import { type AmaliaFunctionWithId, type DesignerAllObjects } from '@amal-ia/lib-ui-business';

import { FORMULA_TOKEN_NODE_NAME } from '../../components/formula-editor-content/token-extension/TokenExtension';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';

// FIXME: here I rely on function category to know what is the return type of the function, because it's not exposed.
//  We should expose the return type of the function in the API.
const functionFormat = (category: AmaliaFunctionCategory) => {
  switch (category) {
    case AmaliaFunctionCategory.DATES:
      return FormatsEnum.date;
    case AmaliaFunctionCategory.NUMBERS:
      return FormatsEnum.number;
    case AmaliaFunctionCategory.STRING:
      return FormatsEnum.text;
    case AmaliaFunctionCategory.ARRAY:
      return FormatsEnum.table;
    default:
      return undefined;
  }
};

export const mapFunctionToken = (func: AmaliaFunctionWithId): FormulaEditorToken => {
  const { name, params, hasInfiniteParams } = func;

  const printedParam = hasInfiniteParams ? 'param1, param2, ...' : (params || []).map((p) => p.name).join(', ');
  const formula = `${name}(${printedParam})`;
  return {
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula: func.name },
      },
      {
        type: 'text',
        text: `(${Array(func.nbParamsRequired)
          .map(() => '')
          .join(', ')})`,
      },
    ],
    formula: func.name,
    name: func.name,
    type: TokenType.FUNCTION,
    format: functionFormat(func.category),
    id: func.name,
    tooltipContent: formula,
  };
};

export const mapQuotaToken = (quota: Variable): FormulaEditorToken => {
  const formula = `${quota.type}.${quota.machineName}`;
  return {
    format: quota.format,
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    formula,
    name: quota.name,
    type: TokenType.QUOTA,
    id: quota.id,
  };
};

export const mapFieldToken = (field: Variable): FormulaEditorToken => {
  const formula = `${field.object!.machineName}.${field.machineName}`;

  return {
    format: field.format,
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    formula,
    name: field.name,
    objectDefinitionName: field.object?.machineName,
    type: TokenType.FIELD,
    id: field.id,
  };
};

export const mapFilterToken = (filter: Filter): FormulaEditorToken => {
  const formula = `filter.${filter.machineName}`;
  return {
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    formula,
    format: FormatsEnum.table,
    name: filter.name,
    type: TokenType.FILTER,
    id: filter.id,
    objectDefinitionName: filter.object?.machineName,
    dataConnectorType: filter.object ? CustomObjectDefToDataConnectorType[filter.object.type] : undefined,
  };
};

export const mapPropertyToken = (property: TracingTypes.Property): FormulaEditorToken => {
  const formula = `${property.definition.machineName}.${property.machineName}`;
  return {
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    format: property.format,
    formula,
    name: property.name,
    type: TokenType.PROPERTY,
    id: property.machineName,
    objectDefinitionName: property.definition.machineName,
  };
};

export const mapVirtualPropertyToken = (property: TracingTypes.Property) => {
  const formula = `${property.definition.machineName}.${property.machineName}`;
  return {
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    format: property.format,
    formula,
    name: property.name,
    type: TokenType.VIRTUAL_PROPERTY,
    objectDefinitionName: property.definition.machineName,
  };
};

export const mapVariableToken = (variable: Variable): FormulaEditorToken => {
  const formula = `statement.${variable.machineName}`;
  return {
    format: variable.format,
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    formula,
    name: variable.name,
    type: [VariableObjectsEnum.plan, VariableObjectsEnum.team, VariableObjectsEnum.user].includes(variable.type)
      ? TokenType.QUOTA
      : TokenType.VARIABLE,
    id: variable.id,
  };
};

export const mapKeywordToken = (
  formulaKeywordsDisplayDetails: Record<FormulaKeyword, FormulaKeywordDisplayDetails>,
  keyword: FormulaKeyword,
) => {
  const formula = FORMULA_KEYWORDS[keyword].formula;
  return {
    editorContentToApply: [
      {
        type: FORMULA_TOKEN_NODE_NAME,
        attrs: { formula },
      },
    ],
    format: FORMULA_KEYWORDS[keyword].format,
    formula,
    name: formulaKeywordsDisplayDetails[keyword].name,
    type: TokenType.KEYWORD,
    id: FORMULA_KEYWORDS[keyword].formula,
  };
};

export const useFormulaEditorTokens = (
  /** Designer objects for the current plan (+ global). */
  planObjects?: DesignerAllObjects,
): {
  tokens: FormulaEditorToken[];
  tokensMap: Record<FormulaEditorToken['formula'], FormulaEditorToken>;
} => {
  const formulaKeywordsDisplayDetails = useFormulaKeywordsDisplayDetails();

  const tokens = useMemo(
    () =>
      sortBy(
        unionBy(
          ((planObjects?.FUNCTION || []) as AmaliaFunctionWithId[])
            .filter((func) => func.hiddenFromLibrary !== true)
            .map(mapFunctionToken),
          ((planObjects?.QUOTA || []) as Variable[]).map(mapQuotaToken),
          ((planObjects?.FIELD || []) as Variable[]).map(mapFieldToken),
          ((planObjects?.VARIABLE || []) as Variable[]).map(mapVariableToken),
          ((planObjects?.FILTER || []) as Filter[]).map(mapFilterToken),
          // TODO: Add support for LINKS. Links will be managed in another epic as we are not sure how to handle them yet.
          /*
          ((planObjects?.LINK || []) as Relationship[]).flatMap((link) =>
            ((planObjects?.PROPERTY || []) as TracingTypes.Property[])
              .filter((property) => property.definition.machineName === link.toDefinitionMachineName)
              .map((property) => {
                const formula = `${link.fromDefinitionMachineName}.${link.name}.${property.machineName}`;
                return {
                  editorContentToApply: [
                    {
                      type: FORMULA_TOKEN_NODE_NAME,
                      attrs: { formula },
                    },
                  ],
                  formula,
                  name: property.name,
                  type: TokenType.LINK,
                  objectDefinitionName: property.definition.machineName,
                  id: property.machineName,
                };
              }),
          ),*/
          ((planObjects?.PROPERTY || []) as TracingTypes.Property[]).map(mapPropertyToken),
          ((planObjects?.VIRTUAL_PROPERTY || []) as TracingTypes.Property[]).map(mapVirtualPropertyToken),
          Object.values(FormulaKeyword).map((keyword) => mapKeywordToken(formulaKeywordsDisplayDetails, keyword)),
          'formula',
        ).filter((token) => token.formula),
        'name',
      ),
    [planObjects, formulaKeywordsDisplayDetails],
  );

  const tokensMap = useMemo(() => keyBy(tokens, 'formula'), [tokens]);

  return {
    tokens,
    tokensMap,
  };
};
