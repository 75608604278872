import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { LabelDisplay, LabelVariant, UserPrettyFormat } from '@amal-ia/data-capture/fields/components';
import { Table } from '@amal-ia/frontend/design-system/components';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum, subject } from '@amal-ia/lib-rbac';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export type ChallengeLeaderboardCellUserProps = {
  readonly user: UserContract;
  readonly period: Period;
  readonly statementId?: string;
};

export const ChallengeLeaderboardCellUser = memo(function ChallengeLeaderboardCellUser({
  user,
  period,
  statementId,
}: ChallengeLeaderboardCellUserProps) {
  const ability = useAbilityContext();

  return (
    <Table.Cell.WithActions
      actions={
        statementId &&
        ability.can(ActionsEnum.view, subject(SubjectsEnum.Statement, { user: { id: user.id }, period })) ? (
          <Table.Cell.Main.Link to={generatePath(routes.STATEMENT, { id: statementId })}>
            <FormattedMessage defaultMessage="View statement" />
          </Table.Cell.Main.Link>
        ) : undefined
      }
    >
      <UserPrettyFormat
        display={LabelDisplay.INLINE}
        firstName={user.firstName}
        lastName={user.lastName}
        pictureURL={user.pictureURL}
        variant={LabelVariant.BOLD}
      />
    </Table.Cell.WithActions>
  );
});
