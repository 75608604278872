import { type ActionCreator } from 'redux';

import {
  type AuditDateRange,
  type AuditRecord,
  type CountAuditRecords,
  type SearchAuditRecords,
} from '@amal-ia/kernel/monitoring/audit/types';
import { type ReduxAction, type ThunkResult } from '@amal-ia/lib-types';

import * as AuditRepository from '../../services/audit/audit.repository';
import { type AuditFiltersOptions } from '../../types/audit';

import { AUDIT_ACTIONS } from './constants';

const auditFetchFiltersStart: ActionCreator<ReduxAction> = () => ({
  type: AUDIT_ACTIONS.START_FILTERS,
});

const auditFetchDataStart: ActionCreator<ReduxAction> = () => ({
  type: AUDIT_ACTIONS.START_DATA,
});

const auditFetchCountStart: ActionCreator<ReduxAction> = () => ({
  type: AUDIT_ACTIONS.START_COUNT,
});

const AuditFetchError: ActionCreator<ReduxAction> = (error: Error) => ({
  type: AUDIT_ACTIONS.ERROR,
  error,
});

export const clearAuditRecords: ActionCreator<ReduxAction> = () => ({
  type: AUDIT_ACTIONS.CLEAR_RECORDS,
});

const setAuditFilters: ActionCreator<ReduxAction> = (filters: AuditFiltersOptions) => ({
  type: AUDIT_ACTIONS.SET_FILTERS,
  payload: filters,
});

const setAuditRecords: ActionCreator<ReduxAction> = (records: AuditRecord[]) => ({
  type: AUDIT_ACTIONS.SET_RECORDS,
  payload: records,
});

const setAuditRecordsCount: ActionCreator<ReduxAction> = (count: number) => ({
  type: AUDIT_ACTIONS.SET_RECORDS_COUNT,
  payload: count,
});

export const fetchAuditFilters =
  (dateRange: AuditDateRange): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(auditFetchFiltersStart());
    try {
      const filters = await AuditRepository.getAuditFilters(dateRange);
      return dispatch(setAuditFilters(filters));
    } catch (error) {
      return dispatch(AuditFetchError(error));
    }
  };

export const searchAuditRecords =
  (filters: AuditFiltersOptions, filtersState: SearchAuditRecords): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(auditFetchDataStart());
    try {
      const actions = await AuditRepository.searchAuditRecords(filters, filtersState);
      return dispatch(setAuditRecords(actions));
    } catch (error) {
      return dispatch(AuditFetchError(error));
    }
  };

export const countAuditRecords =
  (filters: AuditFiltersOptions, filtersState: CountAuditRecords): ThunkResult<Promise<ReduxAction>> =>
  async (dispatch) => {
    dispatch(auditFetchCountStart());
    try {
      const { count } = await AuditRepository.countAuditRecords(filters, filtersState);
      return dispatch(setAuditRecordsCount(count));
    } catch (error) {
      return dispatch(AuditFetchError(error));
    }
  };
