import { type ForwardedRef, forwardRef, memo, Fragment, cloneElement } from 'react';

import { toLinkElement, type ToOrLinkElement } from '@amal-ia/ext/react-router-dom';
import { type MergeAll } from '@amal-ia/ext/typescript';

import {
  BreadcrumbsBackButton,
  type BreadcrumbsBackButtonProps,
} from '../breadcrumbs-back-button/BreadcrumbsBackButton';

export type BreadcrumbsBackLinkProps = MergeAll<
  [
    Omit<BreadcrumbsBackButtonProps, 'onClick'>,
    {
      readonly to?: ToOrLinkElement;
    },
  ]
>;

const BreadcrumbsBackLinkForwardRef = forwardRef(function BreadcrumbsBackLink(
  { to, ...props }: BreadcrumbsBackLinkProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const linkElement = toLinkElement(to);

  return (
    <Fragment>
      {cloneElement(
        linkElement,
        { disabled: props.disabled ?? linkElement.props.disabled },
        <BreadcrumbsBackButton
          {...props}
          ref={ref}
        />,
      )}
    </Fragment>
  );
});

export const BreadcrumbsBackLink = memo(BreadcrumbsBackLinkForwardRef);
