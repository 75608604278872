import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type ComputedStatement } from '@amal-ia/lib-types';

/**
 * get the format from the machine name, either from a custom object or a computed object
 *
 * @param machineName the machine name to look for
 * @param computedStatement the computed statement to look in
 * @param customObjectMachineName The custom object definition machine name (to not look inside every custom object definition)
 *
 * @returns an object, composed by the usable format, and the fact that it is required or not
 */
export const getFormatFromMachineName = (
  machineName: string,
  computedStatement: ComputedStatement,
  customObjectMachineName: string,
): {
  format: FormatsEnum;
  isRequired: boolean;
} => {
  // Try to get a computed object with that name
  const computedObjectDefinition = computedStatement.definitions.variables[machineName];

  if (computedObjectDefinition) {
    return {
      format: computedObjectDefinition.format,
      isRequired: false,
    };
  }

  // Try to get the custom object property
  const customObjectDefinition = computedStatement.definitions.customObjects[customObjectMachineName];

  // Get format from customObjectDefinition
  const { format: objectDefinitionFormat, isRequired } = customObjectDefinition.properties[machineName] || {};

  return {
    format: objectDefinitionFormat,
    isRequired,
  };
};

/**
 * Function to validate a raw value against a format
 *
 * @param value
 * @param format
 * @param required
 */
export const validateValueWithFormat = (value: any, format: FormatsEnum, required: boolean = false) => {
  // If value is required and not provided, throw an error
  if (required && (value === undefined || value === null || value === '')) {
    throw new Error('Please enter a value!');
  }

  switch (format) {
    case FormatsEnum.currency:
    case FormatsEnum.number:
    case FormatsEnum.percent:
      // If it's not a number, throw the error
      if (!/^-?\d+(?:\.\d+)?$/u.test(value?.toString()) && value !== '') {
        throw new Error(`Please enter a number${required ? '' : ' or remove it'}!`);
      }
      break;
    case FormatsEnum.date: {
      // If it's not a date following YYYY-MM-DD format, throw an error
      if (!/^\d{4}-\d{2}-\d{2}$/u.test(value?.toString()) && value !== '') {
        throw new Error(`Please enter a date as YYYY-MM-DD${required ? '' : ' or remove it'}!`);
      }
      break;
    }
    case FormatsEnum.boolean: {
      // If it's not a boolean, throw an error
      if (!/^(?:true|false)$/u.test(value?.toString()) && value !== '') {
        throw new Error(`Please enter a boolean (true or false)${required ? '' : ' or remove it'}!`);
      }
      break;
    }
    case FormatsEnum.text:
      if (typeof value !== 'string') {
        throw new Error(`Please enter a text value${required ? '' : ' or remove it'}!`);
      }
      break;
    default:
    // Do nothing
  }
};

/**
 * Validate a value against a definition identified from computedStatement via a machinename
 *
 * @param value The value to check
 * @param machineName Machine name of the definition
 * @param computedStatement Full computed statement
 * @param customObjectMachineName Machine name of the custom object definition to check
 */
export const validateValueForComputedStatementAndMachineName = (
  value: any,
  machineName: string,
  computedStatement: ComputedStatement,
  customObjectMachineName: string,
) => {
  const { format, isRequired } = getFormatFromMachineName(machineName, computedStatement, customObjectMachineName);

  // Don't validate if format was not found
  if (format) {
    validateValueWithFormat(value, format, isRequired);
  }
};
