import { Fade } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import { memo } from 'react';

import { type AmaliaThemeType, colors } from '@amal-ia/ext/mui/theme';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  bar: {
    backgroundColor: theme.palette.tertiary.main,
  },
  root: {
    backgroundColor: colors['blue-100'],
    position: 'absolute',
    width: '100%',
    marginTop: 0,
  },
}));

interface FullWidthLoaderProps {
  readonly show: boolean;
}

export const FullWidthLoader = memo(function FullWidthLoader({ show }: FullWidthLoaderProps) {
  const classes = useStyles();
  return (
    <div style={{ position: 'relative', height: 0, width: '100%' }}>
      <Fade
        in={show}
        timeout={500}
        style={{
          transitionDelay: show ? '200ms' : '0ms',
        }}
      >
        <LinearProgress classes={classes} />
      </Fade>
    </div>
  );
});
