import { uniq } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';

import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { type Challenge, type PlanCategory, type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { useBoolState } from '@amal-ia/ext/react/hooks';
import { toError } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import {
  selectPaymentsByCategoryForCurrentStatement,
  selectCurrentPeriod,
  useThunkDispatch,
  selectTeamMap,
  fetchUsers,
  selectUsersMap,
} from '@amal-ia/frontend/web-data-layers';
import * as ChallengeRepository from '@amal-ia/frontend/web-data-layers';
import { type ComputedRule, type MainKpi } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { LeaderboardDetails, RuleAccordion } from '@amal-ia/lib-ui-business';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

interface RuleChallengeProps {
  readonly rule: PlanRule;
  readonly computedRule: ComputedRule;
  readonly category?: PlanCategory;
  readonly activeRuleId?: string;
}

const RuleChallenge = memo(function RuleChallenge({ rule, computedRule, category, activeRuleId }: RuleChallengeProps) {
  const identifier = `${category?.name || 'none'}-${rule.ruleMachineName}`;

  const { snackError } = useSnackbars();
  const dispatch = useThunkDispatch();
  const usersMap = useSelector(selectUsersMap);
  const teamsMap = useSelector(selectTeamMap);
  const payments = useSelector(selectPaymentsByCategoryForCurrentStatement);
  const { data: company } = useCurrentCompany();
  const currentPeriod = useSelector(selectCurrentPeriod);
  const { formatMessage } = useIntl();

  const { isRuleExpanded, toggleRuleExpanded } = useBoolState(activeRuleId === rule.id, 'ruleExpanded');

  const statement = useStatementDetailContext();

  const comparisonVariableDefinition = useMemo(
    () =>
      Object.values(statement?.results.definitions.variables).find(
        (v) => v.id === rule.configuration?.challengeComparisonVariableId,
      ),
    [rule, statement.results],
  );

  const [challenge, setChallenge] = useState<Challenge | null>(null);

  useAsyncEffect(async () => {
    try {
      const challengeFromApi = await ChallengeRepository.getChallenge(rule.id, statement.period.id);

      setChallenge(challengeFromApi);

      if (!challengeFromApi) {
        return;
      }

      const userIdsToFetch = uniq((challengeFromApi.leaderboard || []).map((r) => r.userId).filter(Boolean));
      if (userIdsToFetch.length) {
        await dispatch(fetchUsers(userIdsToFetch));
      }
    } catch (e) {
      snackError(toError(e).message);
    }
  }, [statement]);

  // Format the display we put on the rule summary.
  const mainKpi = useMemo((): MainKpi | MainKpi[] => {
    if (!challenge?.results) {
      return {
        label: formatMessage({ defaultMessage: 'Rank' }),
        value: 0,
        overrideFormattedValue: '-',
      };
    }

    // Number if you have a rank, null if non eligible, undefined if not loaded.
    const currentUserPosition = challenge?.leaderboard?.find((l) => l.userId === statement.user.id)?.position;
    const contestantsLength = challenge?.leaderboard?.length;

    // If null, it means you're not eligible.
    const rank =
      currentUserPosition === null
        ? formatMessage({ defaultMessage: 'Non-eligible' })
        : currentUserPosition
          ? // If undefined, it means it's not loaded.
            formatMessage(
              { defaultMessage: '{userPosition, number} / {contestantsCount, number}' },
              {
                userPosition: currentUserPosition,
                contestantsCount: contestantsLength,
              },
            )
          : formatMessage(
              { defaultMessage: '- / {contestantsCount, number}' },
              { contestantsCount: contestantsLength },
            );

    // If the challenge is non-payout, display something like:
    // Rank
    // 2 / 5
    // If the challenge is payout, display something like:
    // Payout  | Rank
    // 1500 €  | 2 / 5

    // First try to recover the associated payout.
    const payment = rule.configuration?.challengePricesTableVariableId
      ? payments.paid.find((p) => p.challengeId === challenge.id)
      : null;

    return [
      !!payment && {
        label: formatMessage({ defaultMessage: 'Payout' }),
        value: {
          amount: payment.value || 0,
          currencySymbol: payment.currency,
          // Putting 1 here because the generated payment for the challenge
          // has already the right currency at the right rate.
          currencyRate: 1,
        },
      },
      {
        label: formatMessage({ defaultMessage: 'Rank' }),
        value: 0,
        overrideFormattedValue: rank,
      },
    ].filter(Boolean);
  }, [payments, rule, challenge, statement, formatMessage]);

  // Completely mask the area when the challenge doesn't have a result.
  // It happens if the challenge is deactivated for this period, or if all statements are in error
  // for that period.
  if (!challenge) {
    return null;
  }

  return (
    <RuleAccordion
      header={
        <RuleAccordion.Header
          key={rule.id}
          isChallenge
          category={category}
          helpLabel={rule.description}
          isExpanded={isRuleExpanded}
          label={rule.name}
          machineName={identifier}
          mainKpi={mainKpi}
          onToggleExpanded={toggleRuleExpanded}
        />
      }
    >
      <RuleLayout>
        {!!computedRule && !!challenge?.leaderboard && !!comparisonVariableDefinition && (
          <RuleLayout.Dataset>
            <LeaderboardDetails
              challenge={challenge}
              company={company}
              comparisonVariable={comparisonVariableDefinition}
              currentPeriod={currentPeriod}
              teamsMap={teamsMap}
              usersMap={usersMap}
            />
          </RuleLayout.Dataset>
        )}
      </RuleLayout>
    </RuleAccordion>
  );
});

export default RuleChallenge;
