import { css } from '@emotion/react';
import { Fragment, memo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import {
  type ComputedHighlightedKpi,
  type HighlightedKpiIdentifier,
} from '@amal-ia/compensation-definition/plans/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { Typography } from '@amal-ia/frontend/design-system/components';

import { StatementKpiDot } from '../../statement-kpi-dot/StatementKpiDot';

import * as styles from './KpisTooltipKpiTitleAndValue.styles';

export type StatementCardHighlightedKpisTooltipItemProps = {
  readonly kpiId: HighlightedKpiIdentifier;
  readonly kpi?: ComputedHighlightedKpi;
  readonly isForecast?: boolean;
};

export const KpisTooltipKpiTitleAndValue = memo(function KpisTooltipKpiTitleAndValue({
  kpiId,
  kpi,
  isForecast = false,
}: StatementCardHighlightedKpisTooltipItemProps) {
  return (
    <div css={styles.kpisTooltipKpiTitleAndValue}>
      <StatementKpiDot
        isForecast={isForecast}
        kpiId={kpiId}
      />

      {kpi ? (
        <Fragment>
          <Typography
            variant={Typography.Variant.BODY_XSMALL_REGULAR}
            css={css`
              flex: 1;
            `}
          >
            {kpi.variableLabel}
          </Typography>

          <Typography
            variant={Typography.Variant.BODY_XSMALL_MEDIUM}
            css={css`
              margin-left: 8px;
            `}
          >
            {Number.isFinite(kpi.currentValue) ? (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                style={kpi.format === FormatsEnum.percent ? 'percent' : 'decimal'}
                value={kpi.currentValue}
              />
            ) : (
              <span css={styles.empty}>
                <FormattedMessage defaultMessage="no data" />
              </span>
            )}
          </Typography>
        </Fragment>
      ) : (
        <Typography
          css={styles.empty}
          variant={Typography.Variant.BODY_XSMALL_REGULAR}
        >
          <FormattedMessage defaultMessage="No KPI set for this plan" />
        </Typography>
      )}
    </div>
  );
});
