import { createContext, useContext } from 'react';

import { assert } from '@amal-ia/ext/typescript';

export type DropdownContextValue = {
  isOpen: boolean;
};

export const DropdownContext = createContext<DropdownContextValue | null>(null);

export const useDropdownContext = (): DropdownContextValue => {
  const context = useContext(DropdownContext);
  assert(context, 'useDropdownContext must be used within a <DropdownContext.Provider>');
  return context;
};

export const useOptionalDropdownContext = (): DropdownContextValue | null => useContext(DropdownContext);
