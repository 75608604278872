import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PlansApiClient } from '@amal-ia/compensation-definition/api-client';
import { type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { type Plan } from '@amal-ia/compensation-definition/plans/types';
import { FormikForm, type FormikFormProps } from '@amal-ia/ext/formik';
import { type MergeAll } from '@amal-ia/ext/typescript';
import { useSnackbars } from '@amal-ia/frontend/design-system/components';
import { log } from '@amal-ia/frontend/kernel/logger';
import { getSupportedPlanFrequencies } from '@amal-ia/lib-types';
import { useCurrentCompany } from '@amal-ia/tenants/companies/shared/state';

type CreatePlanFormValues = {
  name: string;
  frequency: PeriodFrequencyEnum;
  redirectToNewPlan?: (planId: string) => Promise<void> | void;
};

type CreatePlanFormikProps = FormikFormProps<CreatePlanFormValues, Plan>;

export type CreatePlanFormProps = MergeAll<
  [
    Omit<CreatePlanFormikProps, 'initialValues' | 'onSubmit'>,
    {
      duplicateOf?: {
        id: string;
        name: string;
      };
    },
  ]
>;

export const CreatePlanForm = memo(function CreatePlanForm({ duplicateOf, ...props }: CreatePlanFormProps) {
  const { snackSuccess, snackError } = useSnackbars();
  const { data: company } = useCurrentCompany();

  const defaultFrequency = getSupportedPlanFrequencies(company)[0];

  const initialValues: CreatePlanFormValues = useMemo(
    () => ({
      name: duplicateOf?.name || '',
      frequency: defaultFrequency,
    }),
    [duplicateOf, defaultFrequency],
  );

  const handleSubmit = useCallback(
    async (values: Plan) => {
      try {
        const newPlan = await PlansApiClient.create({
          ...values,
          categories: [],
          rules: [],
          duplicateOf: duplicateOf?.id,
        });

        snackSuccess(<FormattedMessage defaultMessage="Plan created!" />);
        return newPlan;
      } catch (err) {
        log.info(<FormattedMessage defaultMessage="An error occurred during the creation of the plan" />);
        snackError(err?.response?.data?.message || err?.message || err?.toString());
        throw err;
      }
    },
    [duplicateOf?.id, snackSuccess, snackError],
  );

  return (
    <FormikForm
      {...props}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    />
  );
});
