import { type Period, type PeriodFrequencyEnum } from '@amal-ia/compensation-definition/periods/types';
import { http } from '@amal-ia/frontend/kernel/http';

export class PeriodsApiClient {
  public static async getPeriodByDate(dateString: string, frequency: PeriodFrequencyEnum): Promise<Period> {
    const { data: period } = await http.get<Period>(`/periods/${dateString}`, {
      params: {
        shouldCreate: true,
        frequency,
      },
    });
    return period;
  }

  public static async getAllPeriods(): Promise<Period[]> {
    const { data: period } = await http.get<Period[]>('/periods');
    return period;
  }
}
