import { IconCheckbox } from '@tabler/icons-react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useLocation } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { Link } from '@amal-ia/ext/react-router-dom';
import { IconButton } from '@amal-ia/frontend/design-system/components';

interface TodosPresentationProps {
  readonly todosCount: number | null;
}

export const TodosPresentation = memo(function TodosPresentation({ todosCount }: TodosPresentationProps) {
  const location = useLocation();

  return (
    <Link to={generatePath(routes.TODOS)}>
      <IconButton
        data-testtodoscount={todosCount}
        icon={<IconCheckbox />}
        id="nav-todos"
        isActive={location.pathname.includes('todos')}
        label={<FormattedMessage defaultMessage="Todos" />}
        withDot={!!todosCount}
      />
    </Link>
  );
});
