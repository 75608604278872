import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { type WorkflowDefinition, type WorkflowMap } from '@amal-ia/lib-types';
import { WorkflowsApiClient } from '@amal-ia/payout-collaboration/workflows/shared/api-client';

export enum WORKFLOWS_QUERY_KEYS {
  WORKFLOWS = 'workflows',
}

const useWorkflows = () =>
  useQuery({
    queryKey: [WORKFLOWS_QUERY_KEYS.WORKFLOWS],
    queryFn: () => WorkflowsApiClient.list(),
  });

export const useWorkflowList = () => {
  const { data: workflows, ...rest } = useWorkflows();

  const workflowList = useMemo(() => workflows || [], [workflows]);

  return { data: workflowList, ...rest };
};

export const useWorkflowsMap = () => {
  const { data: workflows, ...rest } = useWorkflows();

  const workflowsMap: WorkflowMap = useMemo(() => keyBy(workflows, 'id'), [workflows]);

  return { data: workflowsMap, ...rest };
};

export const useWorkflow = (workflowId?: string) => {
  const { data: workflows, ...rest } = useWorkflowsMap();
  return { data: workflowId ? workflows[workflowId] : undefined, ...rest };
};

export const useIsFetchingWorkflows = () =>
  useIsFetching({
    queryKey: [WORKFLOWS_QUERY_KEYS.WORKFLOWS],
  }) > 0;

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [WORKFLOWS_QUERY_KEYS.WORKFLOWS, 'create'],
    mutationFn: (args: Parameters<(typeof WorkflowsApiClient)['create']>) => WorkflowsApiClient.create(...args),
    onSuccess: (workflow) => {
      queryClient.setQueryData<WorkflowDefinition[]>([WORKFLOWS_QUERY_KEYS.WORKFLOWS], (currentCache) => [
        ...(currentCache || []),
        workflow,
      ]);
    },
  });
};

export const useUpdateWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [WORKFLOWS_QUERY_KEYS.WORKFLOWS, 'update'],
    mutationFn: (args: Parameters<(typeof WorkflowsApiClient)['update']>) => WorkflowsApiClient.update(...args),
    onSuccess: (workflow) => {
      queryClient.setQueryData<WorkflowDefinition[]>([WORKFLOWS_QUERY_KEYS.WORKFLOWS], (currentCache) =>
        (currentCache || []).map((currentWorkflow) =>
          currentWorkflow.id === workflow.id ? workflow : currentWorkflow,
        ),
      );
    },
  });
};

export const useDeleteWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [WORKFLOWS_QUERY_KEYS.WORKFLOWS, 'delete'],
    mutationFn: (args: Parameters<(typeof WorkflowsApiClient)['delete']>) => WorkflowsApiClient.delete(...args),
    onSuccess: (_, [workflowId]) => {
      queryClient.setQueryData<WorkflowDefinition[]>([WORKFLOWS_QUERY_KEYS.WORKFLOWS], (currentCache) =>
        (currentCache || []).filter((currentWorkflow) => currentWorkflow.id !== workflowId),
      );
    },
  });
};
