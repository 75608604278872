import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { CustomObjectDefinitionType, type BaseCustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { useAbilityContext } from '@amal-ia/frontend/kernel/authz/context';
import { ActionsEnum, SubjectsEnum, subject } from '@amal-ia/lib-rbac';
import { type DatasetRow, type Statement } from '@amal-ia/lib-types';
import { DatasetType, type Dataset } from '@amal-ia/payout-calculation/shared/types';
import {
  type StatementThreadScope,
  type StatementThread,
  StatementThreadScopesType,
} from '@amal-ia/payout-collaboration/comments/shared/types';

import { type ComputedPlanRuleFieldsToDisplayWithSubTitle } from '../../../../RowsTable/RowsTable';
import { RowsTableUtils } from '../../../../RowsTable/RowsTable.utils';

export const useDatasetRecordsDataGridCellThread = ({
  datasetRow,
  dataset,
  field,
  statementThreads,
  rule = undefined,
  statement = undefined,
  customObjectDefinition = undefined,
  isForecasted = false,
  onOpenStatementThreadPanel,
}: {
  datasetRow: DatasetRow;
  dataset: Dataset;
  field: ComputedPlanRuleFieldsToDisplayWithSubTitle;
  statementThreads: Record<string, StatementThread>;
  statement?: Statement;
  rule?: PlanRule;
  customObjectDefinition?: BaseCustomObjectDefinition;
  isForecasted?: boolean;
  onOpenStatementThreadPanel: (statementThread?: StatementThread, scope?: StatementThreadScope) => void;
}) => {
  const navigate = useNavigate();
  const ability = useAbilityContext();

  const isThreadEnabled =
    !isForecasted &&
    dataset.type === DatasetType.filter &&
    customObjectDefinition?.type !== CustomObjectDefinitionType.VIRTUAL &&
    ability.can(ActionsEnum.view_statement_threads, subject(SubjectsEnum.Statement, statement));

  const cellStatementThread = statement
    ? RowsTableUtils.getCellCommentThread(
        customObjectDefinition?.id,
        datasetRow.externalId,
        field.name,
        rule.id,
        statementThreads,
      )
    : undefined;

  const handleOpenThread = useCallback(() => {
    const rowId = datasetRow.externalId;

    if (!statement) {
      return;
    }

    if (!cellStatementThread) {
      navigate(generatePath(routes.STATEMENT_COMMENT_CREATE, { id: statement.id }));
      onOpenStatementThreadPanel(undefined, {
        type: StatementThreadScopesType.OBJECT,
        id: rowId,
        name: datasetRow.name,
        field: field.name,
        ruleId: rule.id,
        definitionId: customObjectDefinition?.id,
        datasetMachineName: dataset.filterMachineName,
      });
    } else {
      navigate(
        generatePath(routes.STATEMENT_COMMENT, {
          id: statement.id,
          stid: cellStatementThread.id,
        }),
      );
    }
  }, [
    cellStatementThread,
    customObjectDefinition?.id,
    dataset,
    datasetRow,
    navigate,
    onOpenStatementThreadPanel,
    rule.id,
    statement,
    field,
  ]);

  return {
    isThreadEnabled,
    cellStatementThread,
    handleOpenThread,
  };
};
