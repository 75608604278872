import { type ReactElement, memo } from 'react';

import { UserPrettyFormat } from '@amal-ia/data-capture/fields/components';
import { Table } from '@amal-ia/frontend/design-system/components';
import { type DatasetRow } from '@amal-ia/lib-types';
import { useUserInformations } from '@amal-ia/tenants/users/shared/state';

import { type DatasetRecordsDataGridRowHighlightMarkerProps } from '../../row-highlight-marker/DatasetRecordsDataGridRowHighlightMarker';

export type DatasetRecordsDataGridUserCellProps = {
  readonly datasetRow: DatasetRow;
  readonly rowHighlightMarker?: ReactElement<DatasetRecordsDataGridRowHighlightMarkerProps>;
};

export const DatasetRecordsDataGridUserCell = memo(function DatasetRecordsDataGridUserCell({
  datasetRow,
  rowHighlightMarker,
}: DatasetRecordsDataGridUserCellProps) {
  const userId = datasetRow.content.userId as string;
  const { data: user, isLoading } = useUserInformations('id', userId);
  return (
    <Table.Cell.WithActions
      actions={rowHighlightMarker}
      hideActions={false}
    >
      {user || isLoading ? (
        <UserPrettyFormat
          firstName={user?.firstName}
          isLoading={isLoading}
          lastName={user?.lastName}
          pictureURL={user?.pictureURL}
          subLabel={user?.externalId}
        />
      ) : (
        userId
      )}
    </Table.Cell.WithActions>
  );
});
