import { defineMessages } from 'react-intl';

import { FormulaBuilderBooleanOperatorNoArgs } from '@amal-ia/amalia-lang/formula/shared/types';

export const formulaBuilderBooleanOperatorNoArgsMessages = defineMessages<FormulaBuilderBooleanOperatorNoArgs>({
  [FormulaBuilderBooleanOperatorNoArgs.IS_TRUE]: {
    defaultMessage: 'True',
  },
  [FormulaBuilderBooleanOperatorNoArgs.IS_FALSE]: {
    defaultMessage: 'False',
  },
  [FormulaBuilderBooleanOperatorNoArgs.IS_BLANK]: {
    defaultMessage: 'Is blank',
  },
  [FormulaBuilderBooleanOperatorNoArgs.IS_NOT_BLANK]: {
    defaultMessage: 'Is not blank',
  },
} as const);

export const formulaConditionTagFunctionBooleanMessages = defineMessages({
  CLICK_TO_EDIT_CONDITION: {
    defaultMessage: 'Click to edit condition',
  },
} as const);
