import { FormattedMessage } from 'react-intl';

import { DateTransform } from '@amal-ia/amalia-lang/formula/shared/types';
import { type RadioButtonOptionShape } from '@amal-ia/frontend/design-system/components';

import { functionDateDelayMessages } from './useDateTransformer.messages';

export const useDateTransformerOptions = (): RadioButtonOptionShape<DateTransform>[] => [
  {
    value: DateTransform.DAYS_AGO,
    label: <FormattedMessage {...functionDateDelayMessages[DateTransform.DAYS_AGO]} />,
  },
  {
    value: DateTransform.MONTHS_AGO,
    label: <FormattedMessage {...functionDateDelayMessages[DateTransform.MONTHS_AGO]} />,
  },
];
