import { defineMessages } from 'react-intl';

import { TeamRole } from '@amal-ia/tenants/assignments/teams/types';

export const TeamRoleLabels = defineMessages<TeamRole>({
  [TeamRole.TEAM_MANAGER]: {
    defaultMessage: 'Manager',
    description: 'team roles names',
  },
  [TeamRole.TEAM_EMPLOYEE]: {
    defaultMessage: 'Member',
    description: 'team roles names',
  },
});
