import { Node, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import escapeStringRegexp from 'escape-string-regexp';

import { type FormulaEditorToken } from '../../../types/formulaEditorToken';

import { FormulaTokenNode, type FormulaTokenNodeProps } from './FormulaTokenNode';

// HTML tag name for formula token nodes, used for parsing and rendering with tiptap.
const FORMULA_TOKEN_NODE_TAG_NAME = 'formula-node';

export const FORMULA_TOKEN_NODE_NAME = 'FormulaTokenNode';

export const generateFormulaTokenNode = (formula: string) =>
  `<${FORMULA_TOKEN_NODE_TAG_NAME} formula="${formula}"></${FORMULA_TOKEN_NODE_TAG_NAME}>`;

export const makeFormulaTokenExtension = (tokens: FormulaEditorToken[]) =>
  Node.create({
    name: FORMULA_TOKEN_NODE_NAME,
    group: 'inline',
    inline: true,
    inlineContent: true,
    atom: true,
    isolating: true,
    selectable: false,

    renderText(props) {
      return (props.node as FormulaTokenNodeProps['node']).attrs.formula;
    },

    addAttributes() {
      return {
        formula: { default: '' },
      };
    },

    parseHTML() {
      return [{ tag: FORMULA_TOKEN_NODE_TAG_NAME }];
    },

    renderHTML({ HTMLAttributes }) {
      return [FORMULA_TOKEN_NODE_TAG_NAME, HTMLAttributes];
    },

    addPasteRules() {
      // Same as input rule, but for pasting.
      // The only difference is that we don't need to match a whitespace character after the keyword, only to check if there is no '.' or character.
      return tokens.map((token) =>
        nodePasteRule({
          // Negative lookbehind and lookahead to make sure we don't match a keyword that is preceded or followed by a word or a dot.
          find: new RegExp(`(?<!\\w|\\.)(${escapeStringRegexp(token.formula)})(?!\\w|\\.)`, 'gu'),
          type: this.type,
          getAttributes: {
            formula: token.formula,
          },
        }),
      );
    },

    addCommands() {
      return {
        appendFunction:
          (attributes) =>
          ({ chain }) =>
            chain().insertContent(`${attributes.amaliaFunction.name} `).run(),
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer(FormulaTokenNode, { as: 'span' });
    },
  });
