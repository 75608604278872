import { type TeamContract } from '@amal-ia/tenants/teams/types';
import { type UserContract } from '@amal-ia/tenants/users/shared/types';

export interface TeamAssignment {
  id: string;
  createdAt: Date;
  updatedAt: Date;

  effectiveAsOf: number | null;
  effectiveUntil: number | null;
  teamRole: TeamRole;

  team?: TeamContract;
  teamId: string;
  user?: UserContract;
  userId: string;

  hierarchy?: string[];
}

export interface TeamAssignmentWithTeam extends TeamAssignment {
  team: TeamContract;
}

export enum TeamRole {
  TEAM_MANAGER = 'TEAM_MANAGER',
  TEAM_EMPLOYEE = 'TEAM_EMPLOYEE',
}
