import { defineMessages } from 'react-intl';

export const attributeSelectorMessages = defineMessages({
  SELECT_FIELD: {
    defaultMessage: 'Select field',
  },

  VARIABLES: {
    defaultMessage: 'Variables',
  },

  PROPERTIES: {
    defaultMessage: 'Properties from {customObjectName}',
  },

  VIRTUALS: {
    defaultMessage: 'Virtuals',
  },

  FIELDS: {
    defaultMessage: 'Fields',
  },

  QUOTAS: {
    defaultMessage: 'Quotas',
  },

  LINKS: {
    defaultMessage: 'Properties from {relationshipName}',
  },

  KEYWORDS: {
    defaultMessage: 'Keywords',
  },
} as const);
