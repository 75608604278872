/**
 * Convert an absolute url to a relative one if it's the same site.
 * @param url
 */
export const convertUrlFromAbsoluteToRelative = (url: string) => url.replace(window.location.origin, '');

/**
 * Convert querystring to an object.
 * @param qs
 */
export const qsToObject = (qs: string) => {
  const output = {} as Record<string, string>;
  const searchParams = new URLSearchParams(qs);
  for (const [k, v] of searchParams) {
    output[k] = v;
  }
  return output;
};

/**
 * Convert object to querystring.
 * @param obj
 */
export const objectToQs = (obj: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams('');

  Object.keys(obj).forEach((key) => {
    if (key && obj[key]) {
      searchParams.set(key, obj[key]);
    }
  });

  return searchParams.toString();
};
