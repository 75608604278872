import { noop } from 'lodash';
import { memo } from 'react';

import { type FormulaBuilderFunctionBlockDateOneArgForm } from '@amal-ia/amalia-lang/formula/form';
import { type ValueOrAttributeType } from '@amal-ia/amalia-lang/formula/shared/types';
import { type RadioButtonOptionShape } from '@amal-ia/frontend/design-system/components';

import { useFunctionDateHandlers } from '../../function-date-handler/useFunctionDateHandlers';
import { ValueOrAttributeDateSelector } from '../value-or-attribute-date-selector/ValueOrAttributeDateSelector';

export type FunctionDateOneArgPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockDateOneArgForm;
  readonly onChange?: (condition: FormulaBuilderFunctionBlockDateOneArgForm) => void;
  readonly valueOrAttributeOptions: RadioButtonOptionShape<ValueOrAttributeType>[];
};

export const FunctionDateOneArgPopover = memo(function FunctionDateOneArgPopover({
  condition,
  onChange = noop,
  valueOrAttributeOptions,
}: FunctionDateOneArgPopoverProps) {
  const {
    handleChangeAttributeValue,
    handleChangeManualValue,
    handleChangeOptionTransform,
    handleChangeOptionTransformValue,
    handleChangeValueOrAttribute,
  } = useFunctionDateHandlers<FormulaBuilderFunctionBlockDateOneArgForm>({ condition, onChange });

  return (
    <ValueOrAttributeDateSelector
      arg={condition.args[1]}
      argIndex={1}
      rootArg={condition.args[0]}
      valueOrAttributeOptions={valueOrAttributeOptions}
      onChangeAttributeValue={handleChangeAttributeValue}
      onChangeManualValue={handleChangeManualValue}
      onChangeOptionTransform={handleChangeOptionTransform}
      onChangeOptionTransformValue={handleChangeOptionTransformValue}
      onChangeValueOrAttribute={handleChangeValueOrAttribute}
    />
  );
});
