import { css } from '@emotion/react';
import { IconChevronDown, IconInfoCircle } from '@tabler/icons-react';
import clsx from 'clsx';
import {
  type ReactNode,
  memo,
  type ReactElement,
  type JSXElementConstructor,
  cloneElement,
  type KeyboardEvent,
  Fragment,
  useMemo,
} from 'react';

import { PlanRuleCategoryBadge } from '@amal-ia/compensation-definition/plans/rules/categories/components';
import { type PlanCategory, RuleType } from '@amal-ia/compensation-definition/plans/types';
import {
  Divider,
  Typography,
  type GrabHandleProps,
  Tooltip,
  TextOverflow,
} from '@amal-ia/frontend/design-system/components';
import { type MainKpi } from '@amal-ia/lib-types';

import { ForecastedKpiTooltip } from '../../ForecastedKpiTooltip/ForecastedKpiTooltip';
import { KpiRuleItem } from '../kpi-rule-item/KpiRuleItem';

import { RuleChallengeBadge } from './rule-challenge-badge/RuleChallengeBadge';
import * as styles from './RuleAccordionHeader.styles';

export type RuleAccordionHeaderProps = {
  readonly label: ReactNode;
  readonly helpLabel?: string;
  readonly machineName: string;
  readonly mainKpi?: MainKpi | MainKpi[];
  readonly isSubSummary?: boolean;
  readonly isChallenge?: boolean;
  readonly actions?: ReactNode;
  readonly category?: PlanCategory;
  readonly grabHandle?: ReactElement<GrabHandleProps, JSXElementConstructor<GrabHandleProps>>;
  readonly isExpanded: boolean;
  readonly hideActions?: boolean;
  readonly onToggleExpanded: () => void;
};

export const RuleAccordionHeader = memo(function RuleAccordionHeader({
  label,
  helpLabel,
  machineName,
  mainKpi,
  isSubSummary,
  isChallenge,
  actions,
  category,
  grabHandle,
  isExpanded,
  hideActions = false,
  onToggleExpanded,
}: RuleAccordionHeaderProps) {
  const interactiveProps = isSubSummary
    ? {}
    : ({
        role: 'button',
        tabIndex: 0,
        onClick: onToggleExpanded,
        onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === 'Enter' || event.key === ' ') {
            onToggleExpanded();
          }
        },
      } as const);

  const kpis = useMemo((): MainKpi[] => ([] as MainKpi[]).concat(mainKpi).filter(Boolean), [mainKpi]);

  const isNonPayoutRule = kpis[0]?.type === RuleType.NON_PAYOUT;
  const isHideTotal = kpis[0]?.hideTotal;

  return (
    <div
      {...interactiveProps}
      aria-expanded={isExpanded}
      className={clsx({ [styles.IS_SUB_SUMMARY_CLASSNAME]: isSubSummary })}
      css={styles.ruleAccordionHeader}
      data-hide-actions={hideActions}
      id={`${machineName}-bh-header`}
    >
      <div css={styles.leftContainer}>
        {!!grabHandle && cloneElement(grabHandle, { size: 18 })}

        <div css={styles.labelContainer}>
          {!isSubSummary && (
            <IconChevronDown
              size={20}
              css={[
                styles.chevronIcon,
                css`
                  transform: rotate(${isExpanded ? -180 : 0}deg);
                `,
              ]}
            />
          )}

          <div css={styles.labelDescriptionContainer}>
            <Typography
              as={TextOverflow}
              variant={isSubSummary ? Typography.Variant.BODY_LARGE_REGULAR : Typography.Variant.HEADING_4_MEDIUM}
              css={(theme) => css`
                color: ${isNonPayoutRule ? theme.ds.colors.gray[500] : theme.ds.colors.gray[900]};
              `}
            >
              {label}
            </Typography>

            {!!helpLabel && (
              <Tooltip content={helpLabel}>
                <IconInfoCircle
                  size={16}
                  css={css`
                    flex: none;
                  `}
                />
              </Tooltip>
            )}
          </div>

          {!!isChallenge && <RuleChallengeBadge />}

          {!!category && (
            <PlanRuleCategoryBadge
              category={category}
              size={PlanRuleCategoryBadge.Size.SMALL}
            />
          )}
        </div>
      </div>

      {!isHideTotal && (
        <div css={styles.rightContainer}>
          <div css={styles.kpisContainer}>
            {kpis.map((kpi, index) => (
              <Fragment key={kpi.label || kpi.type}>
                {index > 0 && <Divider.Vertical />}

                {kpi.isForecastedView &&
                kpi.isValueForecasted &&
                kpi.formattedOriginalValue &&
                kpi.formattedOriginalValue !== kpi.formattedForecastedValue ? (
                  <ForecastedKpiTooltip
                    isValueForecasted
                    formattedAchievedValue={kpi.formattedOriginalValue}
                    formattedForecastedValue={kpi.formattedForecastedValue}
                    position="top"
                  >
                    <KpiRuleItem
                      mainKpi={kpi}
                      variant={isSubSummary ? 'small' : 'default'}
                    />
                  </ForecastedKpiTooltip>
                ) : (
                  <KpiRuleItem
                    mainKpi={kpi}
                    variant={isSubSummary ? 'small' : 'default'}
                  />
                )}
              </Fragment>
            ))}
          </div>

          {!!actions && <div css={styles.actionsContainer}>{actions}</div>}
        </div>
      )}
    </div>
  );
});
