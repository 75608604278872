import { css } from '@emotion/react';
import { type ReactElement, memo } from 'react';

import { RuleLayout } from '@amal-ia/compensation-definition/plans/rules/components';
import { type PlanRule } from '@amal-ia/compensation-definition/plans/types';
import { type RadioButtonGroupProps } from '@amal-ia/frontend/design-system/components';

import ChartItem from './ChartItem';

interface ChartsProps {
  readonly ruleDefinition: PlanRule;
  readonly viewSelector?: ReactElement<RadioButtonGroupProps>;
}

const Charts = memo(function Charts({ ruleDefinition, viewSelector }: ChartsProps) {
  return (
    <RuleLayout.Charts>
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 24px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            gap: 24px;
          `}
        >
          {(ruleDefinition.charts || []).map((c) => (
            <ChartItem
              key={c.id}
              chart={c}
            />
          ))}
        </div>

        {viewSelector}
      </div>
    </RuleLayout.Charts>
  );
});

export default Charts;
