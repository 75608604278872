import { Box, type CheckboxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useCallback } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { type Option } from '@amal-ia/lib-types';

import { Checkbox } from '../formElements/Checkbox/Checkbox';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  selectFilterItem: {
    paddingRight: theme.spacing(1),
  },
}));

interface SelectFilterItemProps {
  readonly onClick: (value: string) => void;
  readonly selected: boolean;
  readonly item: Option;
  readonly color?: Omit<CheckboxProps['color'], 'default'>;
}

export const SelectFilterItem = memo(function SelectFilterItem({
  onClick,
  selected,
  item,
  color,
}: SelectFilterItemProps) {
  const classes = useStyles();
  const onClickProxy = useCallback(() => onClick(item.value), [item, onClick]);

  return (
    <Box className={classes.selectFilterItem}>
      <Checkbox
        compact
        checked={selected}
        color={(color || 'primary') as 'primary' | 'secondary'}
        label={item.label}
        onChange={onClickProxy}
      />
    </Box>
  );
});
