import { useTheme } from '@emotion/react';
import { noop } from 'lodash';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useGetFormulaBuilderAttributeLabel,
  type FormulaConditionTagProps,
} from '@amal-ia/amalia-lang/formula/components';
import { type FormulaBuilderFunctionBlockBooleanForm } from '@amal-ia/amalia-lang/formula/form';
import { FormulaBuilderBooleanOperatorNoArgs } from '@amal-ia/amalia-lang/formula/shared/types';
import { Dropdown } from '@amal-ia/frontend/design-system/components';

import { formulaBuilderBooleanOperatorNoArgsMessages } from '../FormulaConditionTagFunctionBoolean.messages';
import { BooleanOperatorNoArgsToIcon } from '../FormulaConditionTagFunctionBooleanIcon.mapper';

export type FunctionBooleanPopoverProps = {
  readonly condition: FormulaBuilderFunctionBlockBooleanForm;
  readonly onChange: FormulaConditionTagProps<FormulaBuilderFunctionBlockBooleanForm>['onChange'];
  readonly onClose?: () => void;
};

export const FunctionBooleanPopover = memo(function FunctionBooleanPopover({
  condition,
  onChange = noop,
  onClose = noop,
}: FunctionBooleanPopoverProps) {
  const theme = useTheme();

  const getFormulaBuilderAttributeLabel = useGetFormulaBuilderAttributeLabel();
  const propertyName = getFormulaBuilderAttributeLabel(condition.args[0]);

  const handleChangeOperator = useCallback(
    (operator: FormulaBuilderBooleanOperatorNoArgs) => {
      onChange({
        ...condition,
        operator,
        isDraft: false,
      } as FormulaBuilderFunctionBlockBooleanForm);
      onClose();
    },
    [condition, onChange, onClose],
  );

  return (
    <div>
      <Dropdown.Title>{propertyName}</Dropdown.Title>

      {Object.values(FormulaBuilderBooleanOperatorNoArgs).map((operator) => (
        <Dropdown.ItemOption
          key={operator}
          checked={condition.operator === operator}
          label={<FormattedMessage {...formulaBuilderBooleanOperatorNoArgsMessages[operator]} />}
          icon={
            <BooleanOperatorNoArgsToIcon
              operator={operator}
              theme={theme}
            />
          }
          onClick={() => handleChangeOperator(operator)}
        />
      ))}
    </div>
  );
});
