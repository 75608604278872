import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStatementDetailContext } from '@amal-ia/lib-ui';

import { StatementSummaryLayoutKpis } from '../../../common/statement-summary-layout/kpis/StatementSummaryLayoutKpis';
import { StatementKpis } from '../../../statement-kpis/StatementKpis';
import { useStatementKpis } from '../../../statement-kpis/useStatementKpis';

export const StatementDetailSummaryKpis = memo(function StatementDetailSummaryKpis() {
  const statement = useStatementDetailContext();
  const kpis = useStatementKpis(statement, statement.isForecastedView);

  return (
    <StatementSummaryLayoutKpis
      period={statement.period.name}
      title={<FormattedMessage defaultMessage="KPIs" />}
    >
      <StatementKpis
        hideAvatar
        highlightedKpis={kpis}
        isForecast={statement.isForecastedView}
      />
    </StatementSummaryLayoutKpis>
  );
});
