import { useTheme } from '@emotion/react';
import { type ElementType, type ForwardedRef, type HTMLProps, type ReactNode, forwardRef, memo } from 'react';

import { TYPOGRAPHY_DEFAULT_ELEMENTS, TypographyVariant } from '@amal-ia/frontend/design-system/meta';

export type TypographyProps = Omit<HTMLProps<HTMLElement>, 'as' | 'ref' | 'variant'> & {
  /** Variant of the text. */
  readonly variant: TypographyVariant;
  /** Override the default element for the variant. */
  readonly as?: ElementType;
  /** Text content. */
  readonly children?: ReactNode;
};

const TypographyForwardRef = forwardRef(function Typography(
  { variant, as = undefined, children = undefined, ...props }: TypographyProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const theme = useTheme();

  const TypographyComponent = as || TYPOGRAPHY_DEFAULT_ELEMENTS[variant];

  return (
    <TypographyComponent
      {...props}
      ref={ref}
      css={theme.ds.typographies[variant]}
    >
      {children}
    </TypographyComponent>
  );
});

export const Typography = Object.assign(memo(TypographyForwardRef), {
  Variant: TypographyVariant,
});
