import { css } from '@emotion/react';
import { nth } from 'lodash';
import { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type Period } from '@amal-ia/compensation-definition/periods/types';
import { useFormattedPeriodName } from '@amal-ia/compensation-definition-periods-components';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { CardWithTabs } from '@amal-ia/frontend/design-system/components';
import {
  formatValue,
  type PayoutAndPerformanceChartStatistics,
  type PayoutAndPerformanceChartStatisticsRecord,
} from '@amal-ia/lib-types';

import { findPayoutAndPerformanceChartStatisticsKpiColumns } from '../payout-and-performance-chart/kpiColumns.finder';

import { CardContent } from './card-with-kpis/CardContent';
import { tabCardsKpisMessages } from './TabCardsKpis.messages';
import { useYtdRecord } from './useYtdRecord';

const findMonthRecord = (ytdStats: PayoutAndPerformanceChartStatistics, month: string) =>
  ytdStats?.records?.find((r) => r.ruleMetricPeriod__month === month);

const formatTabValue = (record?: PayoutAndPerformanceChartStatisticsRecord, userCurrency?: CurrencySymbolsEnum) =>
  record
    ? formatValue(
        record?.ruleMetricPayment__value?.value ?? 0,
        FormatsEnum.currency,
        record?.ruleMetricPayment__value?.symbol ?? userCurrency,
      )
    : undefined;

type TabCardsKpisProps = Readonly<{
  ytdStats: PayoutAndPerformanceChartStatistics;
  currentPeriod: Period;
  userCurrency?: CurrencySymbolsEnum;
}>;
export const TabCardsKpis = memo(function TabCardsKpis({ ytdStats, currentPeriod, userCurrency }: TabCardsKpisProps) {
  const { formatMessage } = useIntl();

  const { currentPeriodLongLabel, currentPeriodShortLabel, previousPeriodShortLabel, previousPeriodLongLabel } =
    useFormattedPeriodName(currentPeriod);

  const currentPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, currentPeriodShortLabel),
    [currentPeriodShortLabel, ytdStats],
  );
  const previousPeriodRecord = useMemo(
    () => findMonthRecord(ytdStats, previousPeriodShortLabel),
    [previousPeriodShortLabel, ytdStats],
  );

  const [kpi1, kpi2] = useMemo(() => {
    const kpis = findPayoutAndPerformanceChartStatisticsKpiColumns(ytdStats);

    return [nth(kpis, 0), nth(kpis, 1)];
  }, [ytdStats]);

  const ytdAverageRecord: PayoutAndPerformanceChartStatisticsRecord = useYtdRecord(ytdStats?.ytdRecords, kpi1, kpi2);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <CardWithTabs defaultTab="current">
        <CardWithTabs.Tab
          data={formatTabValue(previousPeriodRecord, userCurrency)}
          label={previousPeriodLongLabel}
          value="previous"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={previousPeriodRecord}
          />
        </CardWithTabs.Tab>

        <CardWithTabs.Tab
          data={formatTabValue(currentPeriodRecord, userCurrency)}
          label={currentPeriodLongLabel}
          value="current"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={currentPeriodRecord}
          />
        </CardWithTabs.Tab>

        <CardWithTabs.Tab
          data={formatTabValue(ytdAverageRecord, userCurrency)}
          label={formatMessage(tabCardsKpisMessages.YEAR_TO_DATE)}
          value="next"
        >
          <CardContent
            kpi1={kpi1}
            kpi2={kpi2}
            record={ytdAverageRecord}
          />
        </CardWithTabs.Tab>
      </CardWithTabs>
    </div>
  );
});
