import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { type VariableDefinition } from '@amal-ia/amalia-lang/tokens/types';
import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type ComputedVariable, formatTotal, TRUNCATED_CONSTANT } from '@amal-ia/lib-types';
import { useStatementDetailContext } from '@amal-ia/lib-ui';
import { type ComputeEnginePrimitiveTypes } from '@amal-ia/payout-calculation/shared/types';
import { type StatementThread } from '@amal-ia/payout-collaboration/comments/shared/types';

import { KpiCardItem } from '../kpi-card-item/KpiCardItem';
import { KpiItem } from '../kpi-item/KpiItem';

import { kpiMessages } from './Kpi.messages';

interface StatementKpiProps {
  readonly isReadOnly: boolean;
  readonly canComment: boolean;
  readonly computedVariable: ComputedVariable;
  readonly variableDefinition: VariableDefinition;
  readonly statementThread?: StatementThread | null;
  readonly openStatementThread: (statementThreadId: string | null, variable: ComputedVariable) => void;
  readonly handleOpenModal?: () => void;
  readonly isKpiForecasted?: boolean;
  readonly useLegacyKpiCardView?: boolean;
}

export const StatementKpi = memo(function StatementKpi({
  isReadOnly,
  canComment,
  computedVariable,
  variableDefinition,
  statementThread,
  openStatementThread,
  handleOpenModal,
  isKpiForecasted,
  useLegacyKpiCardView,
}: StatementKpiProps) {
  const statement = useStatementDetailContext();

  const { formatMessage } = useIntl();

  const kpiCurrency = computedVariable.currency || statement.currency || CurrencySymbolsEnum.EUR;

  const openKpiComments = useCallback(
    () => openStatementThread(statementThread?.id || null, computedVariable),
    [statementThread, openStatementThread, computedVariable],
  );

  const { formattedValue, rawValue } = useMemo(() => {
    const valueResult = computedVariable.overwrite?.overwriteValue || computedVariable.value;
    const isNumber =
      [FormatsEnum.number, FormatsEnum.percent].includes(variableDefinition.format) && typeof valueResult === 'number';

    // Only compute the truncated value if it's a small number
    const computeTruncated = isNumber && !!valueResult && valueResult <= 0.01;

    return {
      formattedValue: formatTotal(
        valueResult as ComputeEnginePrimitiveTypes,
        variableDefinition.format,
        kpiCurrency,
        statement.rate,
      ),
      rawValue: computeTruncated
        ? formatTotal(valueResult, variableDefinition.format, kpiCurrency, statement.rate, TRUNCATED_CONSTANT)
        : '',
    };
  }, [computedVariable, variableDefinition, statement, kpiCurrency]);

  const editKpiBtnTitle = statement?.isForecastedView
    ? formatMessage(kpiMessages.CREATE_SIMULATION_LABEL, { variable_name: variableDefinition.name?.trim() })
    : formatMessage(kpiMessages.CREATE_OVERWRITE_LABEL, { variable_name: variableDefinition.name?.trim() });

  const props = {
    canComment,
    canOverwrite: !isReadOnly,
    isThreadOpen: !!statementThread,
    isThreadReviewed: !!statementThread && statementThread.thread.isReviewed,
    isForecasted: isKpiForecasted,
    isOverwritten: !!computedVariable.overwrite,
    name: variableDefinition.name?.trim(),
    overwriteTooltipText: editKpiBtnTitle,
    rawValue,
    value: formattedValue,
    commentTooltipText: formatMessage(kpiMessages.COMMENT_ON_LABEL, {
      variable_name: variableDefinition.name?.trim(),
    }),
    onClickComment: openKpiComments,
    onClickOverwrite: handleOpenModal,
  };

  return useLegacyKpiCardView ? <KpiCardItem {...props} /> : <KpiItem {...props} />;
});
