import { Chip, type ChipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { memo, useMemo } from 'react';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { Typography } from '@amal-ia/frontend/design-system/components';

export type AlertChipProps = Omit<ChipProps, 'label'> & {
  readonly className?: string;
  readonly label: string;
  readonly type: 'default' | 'error' | 'info' | 'success' | 'warning';
  readonly onClick?: () => any;
};

const useAlertChipStyles = makeStyles((theme: AmaliaThemeType) => ({
  common: {
    borderRadius: 8,
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main,
  },
  default: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
  },
  clickable: {
    cursor: 'pointer',
  },
}));

export const AlertChip = memo(function AlertChip(props: AlertChipProps) {
  const styles = useAlertChipStyles();

  const { className, label, type, onClick } = props;

  const computedClassname = useMemo(
    () => clsx(className, styles[type], styles.common, onClick && styles.clickable),
    [className, type, styles, onClick],
  );

  return (
    <Chip
      component="span"
      {...props}
      className={computedClassname}
      clickable={false}
      label={<Typography variant={Typography.Variant.BODY_BASE_MEDIUM}>{label}</Typography>}
      onClick={onClick}
    />
  );
});
