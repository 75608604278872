export enum ObjectsEnum {
  statementPeriod = 'statementPeriod',
  statement = 'statement',
  planAssignement = 'planAssignement',
  teamAssignment = 'teamAssignment',
  filter = 'filter',
  user = 'user',
  team = 'team',
  plan = 'plan',
  row = '$row',
  statementMetric = 'statementMetric',
  objectMetric = 'objectMetric',
  quota = 'quota',
}

export enum OverwriteTypesEnum {
  PROPERTY = 'PROPERTY',
  FIELD = 'FIELD',
  KPI = 'KPI',
  PAYMENT = 'PAYMENT',
  FILTER_ROW_ADD = 'FILTER_ROW_ADD',
  FILTER_ROW_REMOVE = 'FILTER_ROW_REMOVE',
}
