import {
  IconMathEqualLower,
  IconMathEqualGreater,
  IconMathLower,
  IconMathGreater,
  IconEqual,
  IconPlayerRecord,
  IconCalendarCode,
} from '@tabler/icons-react';

import {
  FormulaBuilderDateOperatorNoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorTwoArgs,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { IconNot, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

export const OPERATOR_ICON_MAPPING: Record<
  FormulaBuilderDateOperatorNoArgs | FormulaBuilderDateOperatorOneArg | FormulaBuilderDateOperatorTwoArgs,
  TablerIconElement
> = {
  [FormulaBuilderDateOperatorNoArgs.YEAR_TO_DATE]: <IconCalendarCode />,
  [FormulaBuilderDateOperatorNoArgs.QUARTER_TO_DATE]: <IconCalendarCode />,
  [FormulaBuilderDateOperatorNoArgs.IN_MONTH]: <IconCalendarCode />,
  [FormulaBuilderDateOperatorNoArgs.IN_PERIOD]: <IconCalendarCode />,
  [FormulaBuilderDateOperatorNoArgs.IS_BLANK]: <IconPlayerRecord />,
  [FormulaBuilderDateOperatorNoArgs.IS_NOT_BLANK]: <IconNot icon={IconPlayerRecord} />,

  [FormulaBuilderDateOperatorOneArg.DATE_BEFORE]: <IconMathEqualLower />,
  [FormulaBuilderDateOperatorOneArg.DATE_AFTER]: <IconMathEqualGreater />,
  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_BEFORE]: <IconMathLower />,
  [FormulaBuilderDateOperatorOneArg.DATE_STRICTLY_AFTER]: <IconMathGreater />,
  [FormulaBuilderDateOperatorOneArg.DATE_EQUALS]: <IconEqual />,

  [FormulaBuilderDateOperatorTwoArgs.DATE_BETWEEN]: <IconCalendarCode />,
};
