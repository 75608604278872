import { css } from '@emotion/react';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, memo, forwardRef } from 'react';

import { type MergeAll } from '@amal-ia/ext/typescript';

import { UnstyledButton } from '../../../general/unstyled-button/UnstyledButton';
import { FatTabInner, type FatTabInnerProps } from '../fat-tab-inner/FatTabInner';
import * as styles from '../FatTab.styles';

export type FatTabButtonProps = MergeAll<
  [
    ComponentPropsWithoutRef<'button'>,
    FatTabInnerProps,
    {
      isSelected?: boolean;
      withBorderTop?: boolean;
    },
  ]
>;

const FatTabButtonForwardRef = forwardRef<HTMLButtonElement, FatTabButtonProps>(function FatTabButton(
  { data, label, subtitle, tooltip, isSelected, withBorderTop, ...props },
  ref,
) {
  return (
    <UnstyledButton
      {...props}
      ref={ref}
      className={clsx(props.className, {
        [styles.IS_SELECTED_CLASSNAME]: isSelected,
        [styles.HAS_SUBTITLE_CLASSNAME]: !!subtitle,
        [styles.WITH_BORDER_TOP_CLASSNAME]: !!withBorderTop,
      })}
      css={[
        styles.fatTab,
        css`
          &:only-child {
            cursor: default;
          }
        `,
      ]}
    >
      <FatTabInner
        data={data}
        label={label}
        subtitle={subtitle}
        tooltip={tooltip}
      />
    </UnstyledButton>
  );
});

export const FatTabButton = memo(FatTabButtonForwardRef);
