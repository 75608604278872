import { type Property } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { type Option } from '@amal-ia/lib-types';

export const getFieldAsOption = (definition: Pick<CustomObjectDefinition, 'properties'>, fieldName: string) => {
  const property = definition.properties?.[fieldName];
  return property
    ? {
        label: property.name,
        name: property.machineName,
      }
    : null;
};

export const transformFieldToOption = (field: Property): Option => ({ value: field.machineName, label: field.name });
