import { type Relationship } from '@amal-ia/compensation-definition/plans/types';
import { http } from '@amal-ia/frontend/kernel/http';

export class RelationshipsApiClient {
  public static async list(): Promise<Relationship[]> {
    const { data } = await http.get<Relationship[]>('/relationships');
    return data;
  }

  public static async get(relationshipId: string): Promise<Relationship> {
    const { data } = await http.get<Relationship>(`/relationships/${relationshipId}`);
    return data;
  }

  public static async create(relationship: Relationship): Promise<Relationship> {
    const { data } = await http.post<Relationship>('/relationships/', relationship);
    return data;
  }

  public static async update(relationship: Relationship): Promise<Relationship> {
    const { data } = await http.patch<Relationship>(`/relationships/${relationship.id}`, relationship);
    return data;
  }

  public static async delete(relationshipId: string): Promise<void> {
    await http.delete(`/relationships/${relationshipId}`);
  }
}
