export enum CountBadgeSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum CountBadgeVariant {
  NEUTRAL = 'NEUTRAL',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  CYAN = 'CYAN',
  MAGENTA = 'MAGENTA',
  ORANGE = 'ORANGE',
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  PURPLE = 'PURPLE',
  GREY = 'GREY',
}
