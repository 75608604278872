import { type ReactNode, memo } from 'react';
import { Navigate, generatePath } from 'react-router-dom';

import { routes } from '@amal-ia/common/routes';
import { type Ability } from '@amal-ia/lib-rbac';

import { useAbilityContext } from '../hooks/useAbilityContext';

export type AbilityRouteProtectorProps = {
  readonly can: (Ability: Ability) => boolean;
  readonly children?: ReactNode;
};

export const AbilityRouteProtector = memo(function AbilityRouteProtector({
  can,
  children,
}: AbilityRouteProtectorProps) {
  const ability = useAbilityContext();

  if (!can(ability)) {
    return (
      <Navigate
        replace
        to={generatePath(routes.ROOT)}
      />
    );
  }

  return children;
});
