import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';

import { FORMULA_KEYWORDS, type FormulaKeywordBase } from '@amal-ia/amalia-lang/formula/shared/keywords';
import { FormulaKeyword } from '@amal-ia/amalia-lang/formula/shared/types';

export type FormulaKeywordDisplayDetails = FormulaKeywordBase & {
  color: string;
  name: string;
  description: string;
};

export type FormulaKeywordsDisplayDetailsRecord = Record<FormulaKeyword, FormulaKeywordDisplayDetails>;

export const useFormulaKeywordsDisplayDetails = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return {
    [FormulaKeyword.USER_ID]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_ID],
      name: formatMessage({ defaultMessage: 'User id' }),
      description: formatMessage({ defaultMessage: 'Amalia identifier of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_EXTERNAL_ID]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_EXTERNAL_ID],
      name: formatMessage({ defaultMessage: 'External id' }),
      description: formatMessage({ defaultMessage: 'External identifier of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_TEAM_MEMBERS]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_TEAM_MEMBERS],
      name: formatMessage({ defaultMessage: 'User team members' }),
      description: formatMessage({ defaultMessage: 'All employees of all teams of current manager' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_FIRST_NAME]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_FIRST_NAME],
      name: formatMessage({ defaultMessage: 'User first name' }),
      description: formatMessage({ defaultMessage: 'First name of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_LAST_NAME]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_LAST_NAME],
      name: formatMessage({ defaultMessage: 'User last name' }),
      description: formatMessage({ defaultMessage: 'Last name of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_CURRENCY]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_CURRENCY],
      name: formatMessage({ defaultMessage: 'User currency' }),
      description: formatMessage({ defaultMessage: 'Currency of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_EMAIL]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_EMAIL],
      name: formatMessage({ defaultMessage: 'User email' }),
      description: formatMessage({ defaultMessage: 'Email of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.USER_HRIS_ID]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.USER_HRIS_ID],
      name: formatMessage({ defaultMessage: 'User HRIS id' }),
      description: formatMessage({ defaultMessage: 'HRIS id of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.TEAM_MEMBERS]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_MEMBERS],
      name: formatMessage({ defaultMessage: 'Team members' }),
      description: formatMessage({
        defaultMessage: 'All members (employees + managers) of first team of current user',
      }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.TEAM_MANAGERS]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_MANAGERS],
      name: formatMessage({ defaultMessage: 'Team managers' }),
      description: formatMessage({ defaultMessage: 'All managers of first team of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.TEAM_EMPLOYEES]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_EMPLOYEES],
      name: formatMessage({ defaultMessage: 'Team employees' }),
      description: formatMessage({ defaultMessage: 'All employees of first team of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.TEAM_NAME]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_NAME],
      name: formatMessage({ defaultMessage: 'Team name' }),
      description: formatMessage({ defaultMessage: 'Name of first team of current user' }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_ID]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_ID],
      name: formatMessage({ defaultMessage: 'Period id' }),
      description: formatMessage({ defaultMessage: 'Amalia identifier for the statement period' }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_CURRENT_DATE]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_CURRENT_DATE],
      name: formatMessage({ defaultMessage: 'Current date' }),
      description: formatMessage({ defaultMessage: 'Current date (evaluated during the last calculation)' }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_START_DATE]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_START_DATE],
      name: formatMessage({ defaultMessage: 'Period start date' }),
      description: formatMessage({ defaultMessage: "First day of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_END_DATE]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_END_DATE],
      name: formatMessage({ defaultMessage: 'Period end date' }),
      description: formatMessage({ defaultMessage: "Last day of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_START_OF_MONTH]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_START_OF_MONTH],
      name: formatMessage({ defaultMessage: 'Period start of month' }),
      description: formatMessage({ defaultMessage: "First day of the month of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_END_OF_MONTH]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_END_OF_MONTH],
      name: formatMessage({ defaultMessage: 'Period end of month' }),
      description: formatMessage({ defaultMessage: "Last day of the month of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_START_OF_QUARTER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_START_OF_QUARTER],
      name: formatMessage({ defaultMessage: 'Period start of quarter' }),
      description: formatMessage({ defaultMessage: "First day of the quarter of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_END_OF_QUARTER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_END_OF_QUARTER],
      name: formatMessage({ defaultMessage: 'Period end of quarter' }),
      description: formatMessage({ defaultMessage: "Last day of the quarter of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_START_OF_SEMESTER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_START_OF_SEMESTER],
      name: formatMessage({ defaultMessage: 'Period start of semester' }),
      description: formatMessage({ defaultMessage: "First day of the semester of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_END_OF_SEMESTER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_END_OF_SEMESTER],
      name: formatMessage({ defaultMessage: 'Period end of semester' }),
      description: formatMessage({ defaultMessage: "Last day of the semester of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_START_OF_YEAR]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_START_OF_YEAR],
      name: formatMessage({ defaultMessage: 'Period start of year' }),
      description: formatMessage({ defaultMessage: "First day of the year of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.STATEMENT_PERIOD_END_OF_YEAR]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.STATEMENT_PERIOD_END_OF_YEAR],
      name: formatMessage({ defaultMessage: 'Period end of year' }),
      description: formatMessage({ defaultMessage: "Last day of the year of the statement's period" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_AS_OF]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_AS_OF],
      name: formatMessage({ defaultMessage: 'Plan assignment effective date' }),
      description: formatMessage({ defaultMessage: "First day of current plan's assignment" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_UNTIL]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.PLAN_ASSIGNEMENT_EFFECTIVE_UNTIL],
      name: formatMessage({ defaultMessage: 'Plan assignment effective until' }),
      description: formatMessage({ defaultMessage: "Last day of current plan's assignment" }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_ID]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_ID],
      name: formatMessage({ defaultMessage: 'Plan assignment plan id' }),
      description: formatMessage({ defaultMessage: 'Plan Id related to the plan assignment' }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_NAME]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.PLAN_ASSIGNEMENT_PLAN_ID],
      name: formatMessage({ defaultMessage: 'Plan assignment plan name' }),
      description: formatMessage({ defaultMessage: 'Plan Name related to the plan assignment' }),
      color: theme.ds.hues.cyan[50],
    },
    [FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_AS_OF]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_AS_OF],
      name: formatMessage({ defaultMessage: 'Team assignment effective date' }),
      description: formatMessage({ defaultMessage: "First day of current team's assignment" }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_UNTIL]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.TEAM_ASSIGNMENT_EFFECTIVE_UNTIL],
      name: formatMessage({ defaultMessage: 'Team assignment effective until' }),
      description: formatMessage({ defaultMessage: "Last day of current team's assignment" }),
      color: theme.ds.hues.purple[50],
    },
    [FormulaKeyword.FILTER_METRICS_BY_USER_TEAM_MEMBER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.FILTER_METRICS_BY_USER_TEAM_MEMBER],
      name: formatMessage({ defaultMessage: 'Metrics by user team member' }),
      description: formatMessage({ defaultMessage: 'Metrics on all my managees' }),
      color: theme.ds.hues.magenta[50],
    },
    [FormulaKeyword.FILTER_METRICS_BY_TEAM_MEMBER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.FILTER_METRICS_BY_TEAM_MEMBER],
      name: formatMessage({ defaultMessage: 'Metrics by team member' }),
      description: formatMessage({ defaultMessage: 'Metrics on all members of the first team' }),
      color: theme.ds.hues.magenta[50],
    },
    [FormulaKeyword.FILTER_METRICS_BY_TEAM_EMPLOYEE]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.FILTER_METRICS_BY_TEAM_EMPLOYEE],
      name: formatMessage({ defaultMessage: 'Metrics by team employee' }),
      description: formatMessage({ defaultMessage: 'Metrics on all employees of the first team' }),
      color: theme.ds.hues.magenta[50],
    },
    [FormulaKeyword.FILTER_METRICS_BY_TEAM_MANAGER]: {
      ...FORMULA_KEYWORDS[FormulaKeyword.FILTER_METRICS_BY_TEAM_MANAGER],
      name: formatMessage({ defaultMessage: 'Metrics by team manager' }),
      description: formatMessage({ defaultMessage: 'Metrics on all managers of the first team' }),
      color: theme.ds.hues.magenta[50],
    },
  } as const satisfies FormulaKeywordsDisplayDetailsRecord;
};
