import { memo } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

interface StringComparatorProps {
  readonly s1: string;
  readonly s2: string;
}

export const StringComparator = memo(function StringComparator({ s1, s2 }: StringComparatorProps) {
  return (
    <ReactDiffViewer
      hideLineNumbers
      showDiffOnly
      compareMethod={DiffMethod.CSS}
      extraLinesSurroundingDiff={0}
      newValue={s2}
      oldValue={s1}
      splitView={false}
    />
  );
});
