import { Box } from '@mui/material';
import { memo, useMemo } from 'react';

import { sumTotalForUser, type DataBenchmarkByRule } from '../../../utils/dashboard.utils';

import { BenchmarkByRuleRow } from './BenchmarkByRuleRow';

interface BenchmarkByRuleProps {
  readonly colors?: string[];
  readonly height?: number | string;
  readonly width?: number | string;
  readonly data?: DataBenchmarkByRule[];
  readonly loading?: boolean;
}

export const BenchmarkByRule = memo(function BenchmarkByRule({
  colors = [],
  width = '300px',
  height = '300px',
  loading = true,
  data = [],
}: BenchmarkByRuleProps) {
  const max = useMemo(() => Math.max(...data.map((entry) => sumTotalForUser(entry))), [data]);
  const graphs = useMemo(
    () =>
      data.map((row, idx) => (
        <BenchmarkByRuleRow
          key={idx}
          colors={colors}
          idx={idx}
          loading={loading}
          max={max}
          row={row}
        />
      )),
    [colors, data, loading, max],
  );
  return (
    <Box
      height={height}
      width={width}
    >
      {graphs}
    </Box>
  );
});
