import {
  create,

  // List the dependencies we want to add to mathjs.
  // https://mathjs.org/docs/custom_bundling.html
  type MathJsStatic,
  parserDependencies,
  // operators
  notDependencies,
  andDependencies,
  xorDependencies,
  orDependencies,
  largerEqDependencies,
  smallerEqDependencies,
  compareNaturalDependencies,
  minDependencies,
  maxDependencies,
  // functions
  addDependencies,
  divideDependencies,
} from 'mathjs';

export type AmaliaMathJs = Pick<MathJsStatic, 'import' | 'Infinity' | 'parse' | 'parser'>;

export const getMathJS = (): AmaliaMathJs =>
  // https://mathjs.org/docs/core/configuration.html
  // don't use matrix
  create(
    {
      parserDependencies,
      notDependencies,
      andDependencies,
      xorDependencies,
      orDependencies,
      largerEqDependencies,
      smallerEqDependencies,
      compareNaturalDependencies,
      minDependencies,
      maxDependencies,
      addDependencies,
      divideDependencies,
    },
    { matrix: 'Array' },
  ) as AmaliaMathJs;

/**
 * Avoid potential injection by locking mathjs imports.
 * @param mathJs
 * @see https://mathjs.org/docs/expressions/security.html
 */
export const lockMathJs = (mathJs: AmaliaMathJs): void => {
  mathJs.import(
    {
      import() {
        throw new Error('Function import does not exist');
      },
      createUnit() {
        throw new Error('Function createUnit does not exist');
      },
      evaluate() {
        throw new Error('Function evaluate does not exist');
      },
      parse() {
        throw new Error('Function parse does not exist');
      },
      simplify() {
        throw new Error('Function simplify does not exist');
      },
      derivative() {
        throw new Error('Function derivative does not exist');
      },

      // Unequal from mathjs only works for comparing numbers. Let's write our own then.
      unequal: (a: unknown, b: unknown) => a !== b,
    },
    { override: true },
  );
};

// Export an instance of mathjs for non-parser related operations.
export const commonMathJs = getMathJS();
