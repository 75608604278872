import { useMemo, type MouseEventHandler } from 'react';

import { useAuthenticatedContext } from '@amal-ia/frontend/kernel/authz/context';
import { WorkflowUtils } from '@amal-ia/lib-rbac';
import {
  WorkflowStatementStateAction,
  type WorkflowDefinition,
  type WorkflowStep,
  type Statement,
} from '@amal-ia/lib-types';
import { UserRole, type UserContract } from '@amal-ia/tenants/users/shared/types';

type UseStatementWorkflowPresentationProps = {
  workflow: WorkflowDefinition;
  statement: Statement;
  stepNumber: number;
  onPreviousStep?: MouseEventHandler;
  hasRightToResetWorkflow?: boolean;
  onReset?: MouseEventHandler;
};

export const useStatementWorkflowPresentation = ({
  workflow,
  statement,
  stepNumber,
  onPreviousStep,
  hasRightToResetWorkflow,
  onReset,
}: UseStatementWorkflowPresentationProps) => {
  const { authenticatedContext } = useAuthenticatedContext();

  // List the list of authorization for each step of the statement's workflow
  // for example, for [step1, step2], we may have [UNAUTHORIZED, NEXT] that means
  // Current user can validate step 2 but not step 1
  const canValidSteps: WorkflowStatementStateAction[] = useMemo(
    // For this step, compute the right of current user on it
    () =>
      workflow.steps.map((step: WorkflowStep) =>
        WorkflowUtils.canValidStep(
          authenticatedContext,
          statement.user as UserContract,
          step,
          workflow.workflowOptions,
        ),
      ),
    [authenticatedContext, statement, workflow],
  );

  const haveValidatedStep = canValidSteps
    .slice(0, stepNumber)
    .some((step) => step !== WorkflowStatementStateAction.UNAUTHORIZED);

  const canValidThisStep = canValidSteps[stepNumber] || WorkflowStatementStateAction.UNAUTHORIZED;

  const isReviewed: boolean =
    statement.workflowComplete || (haveValidatedStep && canValidThisStep === WorkflowStatementStateAction.UNAUTHORIZED);

  const canBackStep: boolean = useMemo(
    () =>
      !!onPreviousStep &&
      hasRightToResetWorkflow === true &&
      (stepNumber > 0
        ? WorkflowUtils.canValidStep(
            authenticatedContext,
            statement.user as UserContract,
            workflow.steps[stepNumber - 1],
            workflow.workflowOptions,
          ) !== WorkflowStatementStateAction.UNAUTHORIZED
        : false),
    [statement, stepNumber, authenticatedContext, workflow, hasRightToResetWorkflow, onPreviousStep],
  );

  const canResetSteps: boolean = useMemo(
    () =>
      !!onReset &&
      hasRightToResetWorkflow === true &&
      (stepNumber > 0
        ? WorkflowUtils.canResetSteps(authenticatedContext, statement.user as UserContract, false) !==
          WorkflowStatementStateAction.UNAUTHORIZED
        : false),
    [statement, stepNumber, authenticatedContext, hasRightToResetWorkflow, onReset],
  );

  const canToggleStep: boolean = useMemo(() => {
    if (isReviewed && stepNumber > 0) {
      const canValidatePreviousStep = canValidSteps[stepNumber - 1] !== WorkflowStatementStateAction.UNAUTHORIZED;

      // User has the right to back one step if he can validate this step OR if it's the last step AND he's admin

      // User can toggle step :
      // - If he is allowed to validate previous step on the workflow definition.
      // In the case where workflow is complete, only non employees users are allowed to unreview the statement.
      return (
        canValidatePreviousStep && (!statement.workflowComplete || authenticatedContext.user.role !== UserRole.EMPLOYEE)
      );
    }

    return canValidThisStep !== WorkflowStatementStateAction.UNAUTHORIZED;
  }, [isReviewed, stepNumber, canValidThisStep, canValidSteps, authenticatedContext, statement.workflowComplete]);

  return {
    canBackStep,
    canResetSteps,
    canToggleStep,
    canValidSteps,
    isReviewed,
    haveValidatedStep,
    canValidThisStep,
  };
};
