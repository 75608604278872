import { IconVariable } from '@tabler/icons-react';
import { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  createFormulaBlock,
  createFunctionBooleanBlock,
  createFunctionCurrencyBlock,
  createFunctionDateBlock,
  createFunctionNumberBlock,
  createFunctionPercentBlock,
  createFunctionStringBlock,
  createFunctionUserBlock,
  type FormulaBuilderLogicalOperatorBlockForm,
} from '@amal-ia/amalia-lang/formula/form';
import { FORMULA_KEYWORDS } from '@amal-ia/amalia-lang/formula/shared/keywords';
import { type AttributeValue, FormulaKeyword } from '@amal-ia/amalia-lang/formula/shared/types';
import { type Variable } from '@amal-ia/amalia-lang/tokens/types';
import { FormatsEnum, type Property, PropertyRef } from '@amal-ia/data-capture/fields/types';
import {
  CountBadge,
  SelectDropdown,
  type SelectDropdownOption,
  type SelectDropdownProps,
  type SelectOptionGroup,
} from '@amal-ia/frontend/design-system/components';
import { TokenType } from '@amal-ia/lib-types';

import {
  type AttributeSelectOption,
  useAttributesOptions,
} from '../../hooks/use-attributes-options/useAttributesOptions';
import { attributeSelectorMessages } from '../attribute-selector/AttributeSelector.messages';
import { useFormulaBuilderContext } from '../formula-builder/FormulaBuilder.context';

import { createConditionFormMessages } from './CreateConditionForm.messages';

const formulaOptionType = Symbol('formula');

type ConditionOption<TAttributeValue extends AttributeValue = AttributeValue> =
  | AttributeSelectOption<TAttributeValue>
  | SelectDropdownOption<typeof formulaOptionType>;

export type CreateConditionFormProps = {
  readonly onCreateCondition: (condition: FormulaBuilderLogicalOperatorBlockForm['operands'][number]) => void;
  readonly isOpen?: SelectDropdownProps['isOpen'];
  readonly onChangeIsOpen?: SelectDropdownProps['onChangeIsOpen'];
  readonly children: SelectDropdownProps<ConditionOption, false, true, false>['children'];
};

export const HANDLED_FORMATS = [
  FormatsEnum.date,

  FormatsEnum['date-time'],

  FormatsEnum.text,

  FormatsEnum.number,
  FormatsEnum.percent,
  FormatsEnum.boolean,
  FormatsEnum.currency,
];

const ATTRIBUTE_OPTIONS_FILTERS = {
  filterProperty: (property: Property) => HANDLED_FORMATS.includes(property.format),
  filterVariable: (variable: Variable) => HANDLED_FORMATS.includes(variable.format),
  keywords: Object.values(FormulaKeyword).filter((key) => HANDLED_FORMATS.includes(FORMULA_KEYWORDS[key].format)),
  showFilteredAsDisabled: false,
};

export const CreateConditionForm = memo(function CreateConditionForm({
  onCreateCondition,
  isOpen,
  onChangeIsOpen,
  children,
}: CreateConditionFormProps) {
  const { customObjectDefinition } = useFormulaBuilderContext();
  const intl = useIntl();

  const handleCreateFormula = useCallback(() => onCreateCondition(createFormulaBlock()), [onCreateCondition]);

  const handleCreateFunctionBlockForPropertyRef = useCallback(
    (ref: PropertyRef, attribute: AttributeValue) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- In case we add a new PropertyRef
      if (ref === PropertyRef.USER) {
        onCreateCondition(createFunctionUserBlock(attribute));
      }
    },
    [onCreateCondition],
  );

  const handleCreateFunctionBlock = useCallback(
    ({ format, ref, attribute }: AttributeSelectOption<AttributeValue>) => {
      if (customObjectDefinition) {
        switch (format) {
          case FormatsEnum.text:
            // if text type has a ref to a specific "PropertyRef"
            if (!ref) {
              onCreateCondition(createFunctionStringBlock(attribute));
            } else {
              handleCreateFunctionBlockForPropertyRef(ref, attribute);
            }
            break;
          case FormatsEnum.date:
          case FormatsEnum['date-time']:
            onCreateCondition(createFunctionDateBlock(attribute));
            break;
          case FormatsEnum.number:
            onCreateCondition(createFunctionNumberBlock(attribute));
            break;
          case FormatsEnum.percent:
            onCreateCondition(createFunctionPercentBlock(attribute));
            break;
          case FormatsEnum.currency:
            onCreateCondition(createFunctionCurrencyBlock(attribute));
            break;
          case FormatsEnum.boolean:
            onCreateCondition(createFunctionBooleanBlock(attribute));
            break;
          default:
            break;
        }
      }
    },
    [customObjectDefinition, handleCreateFunctionBlockForPropertyRef, onCreateCondition],
  );

  const handleChange: Required<SelectDropdownProps<ConditionOption, false, true, false>>['onChange'] = useCallback(
    (option) =>
      // Create formula if the value is the formula option type.
      // Otherwise, create a function block (because we only have these 2 option types).
      option.value === formulaOptionType ? handleCreateFormula() : handleCreateFunctionBlock(option),
    [handleCreateFormula, handleCreateFunctionBlock],
  );

  const attributesOptions = useAttributesOptions(ATTRIBUTE_OPTIONS_FILTERS);

  const options: (ConditionOption | SelectOptionGroup<ConditionOption>)[] = useMemo(
    () => [
      {
        icon: <IconVariable />,
        value: formulaOptionType,
        label: intl.formatMessage(createConditionFormMessages.CUSTOM_FORMULA),
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.PROPERTIES, {
          customObjectName: customObjectDefinition?.name ?? '',
        }),
        countBadge: (
          <CountBadge variant={CountBadge.Variant.ORANGE}>
            {attributesOptions[TokenType.PROPERTY].length + attributesOptions[TokenType.VIRTUAL_PROPERTY].length}
          </CountBadge>
        ),
        initialIsOpen: false,
        options: [...attributesOptions[TokenType.PROPERTY], ...attributesOptions[TokenType.VIRTUAL_PROPERTY]],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.VARIABLES),
        initialIsOpen: false,
        countBadge: (
          <CountBadge variant={CountBadge.Variant.CYAN}>{attributesOptions[TokenType.VARIABLE].length}</CountBadge>
        ),
        options: attributesOptions[TokenType.VARIABLE],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.FIELDS),
        initialIsOpen: false,
        countBadge: (
          <CountBadge variant={CountBadge.Variant.ORANGE}>{attributesOptions[TokenType.FIELD].length}</CountBadge>
        ),
        options: attributesOptions[TokenType.FIELD],
      },
      {
        label: intl.formatMessage(attributeSelectorMessages.QUOTAS),
        initialIsOpen: false,
        countBadge: (
          <CountBadge variant={CountBadge.Variant.PURPLE}>{attributesOptions[TokenType.QUOTA].length}</CountBadge>
        ),
        options: attributesOptions[TokenType.QUOTA],
      },
    ],
    [intl, attributesOptions, customObjectDefinition],
  );

  return (
    <SelectDropdown<ConditionOption, false, true, false>
      useOptionAsValue
      isClearable={false}
      isOpen={isOpen}
      options={options}
      onChange={handleChange}
      onChangeIsOpen={onChangeIsOpen}
    >
      {children}
    </SelectDropdown>
  );
});
