import { omit, toLower } from 'lodash';

import { FormatsEnum } from '@amal-ia/data-capture/fields/types';
import { type CustomObjectDefinition } from '@amal-ia/data-capture/models/types';
import { CustomObjectsApiClient } from '@amal-ia/data-capture/records/shared/api-client';
import { type CurrencySymbolsEnum } from '@amal-ia/ext/iso-4217';
import { type OverwriteContract } from '@amal-ia/lib-types';

const MAX_TABLE_DATA = 500;
const KEYWORD = ['clean', 'delete', 'CLEAN', 'DELETE'];

interface ITablePreview {
  id: string;
  column: string;
  value: boolean | number | string;
  sourceValue: boolean | number | string;
  type: boolean;
  symbol: CurrencySymbolsEnum;
}

export const retrieveCustomObject = async (csv: any[], obj: CustomObjectDefinition) => {
  let objectRelated: any = {};
  if (csv.length > 0) {
    const idReference = Object.keys(csv[0])[0];
    const ids = csv?.map((one: any) => one?.[idReference]).join(',');
    objectRelated = await CustomObjectsApiClient.getObjects(
      obj?.machineName,
      {
        page: 0,
        limit: csv?.length,
      },
      null,
      true,
      ids,
    );
  }
  return objectRelated;
};

export const getTheRightMachineName = (objectDefinition: CustomObjectDefinition, columnName: string): string => {
  const properties = objectDefinition?.properties;
  const validMachineNameOnLower: string[] = Object.keys(properties).map((prop) => toLower(prop));
  const indexOfValidMachineOnLower = validMachineNameOnLower.indexOf(toLower(columnName));
  const validMachineName =
    indexOfValidMachineOnLower !== -1 ? Object.keys(properties)[indexOfValidMachineOnLower] : null;
  return validMachineName?.toString();
};

/**
 * Function : create table view form CSV
 * @param csv
 * @param objectdefinition
 * @returns ITablePreview
 */

export const tablePreviewFormaterUtils = async (csv: any[], objectdefinition: CustomObjectDefinition): Promise<any> => {
  const table: ITablePreview[] | null = [];
  let error: any = null;
  if (csv.length > 0) {
    const properties = objectdefinition?.properties;
    const idReference = Object.keys(csv[0])[0];
    const ids = csv.map((row: any) => row?.[idReference]).filter((e: any, i: any, a: any) => a.indexOf(e) !== i);
    const result: any = {};
    let test: any = csv;

    try {
      const customObject = await retrieveCustomObject(csv, objectdefinition);

      // ATTEMPT TO OVERWRITE NAMEFIELD
      if (objectdefinition?.nameField && Object.keys(csv[0]).includes(objectdefinition?.nameField)) {
        error = {
          severity: 'error',
          title: 'You are attempt to overwrite the nameField',
          message: objectdefinition.nameField,
        };
        test = [];
      }

      if (
        Object.keys(csv[0])
          .filter((col) => col === idReference)
          .filter((column: any) => !Object.keys(properties).includes(column)).length > 0
      ) {
        error = {
          severity: 'error',
          title: 'Record ID not found',
          message: idReference,
        };
        test = [];
      }

      // ID PROVIDED NOT FOUND IN OBJECT DEFINITION
      if (customObject.totalItems > 0 && customObject.totalItems !== csv.length) {
        const badId = csv
          .filter((row: any) => !customObject.items.find((obj: any) => obj.externalId === row?.[idReference]))
          .map((item: any) => item?.[idReference]);

        error = {
          severity: 'warning',
          title: `${badId.length > 1 ? 'Some' : 'An'} ID provided ${badId.length > 1 ? 'are' : 'is'} not found in "${
            objectdefinition.name
          }"`,
          message: badId.join(' - '),
        };
        test = csv.filter((row) => !badId.includes(row?.[idReference]));
      }

      // NO DATA FOUND IN OBJECTDEFINITION
      if (customObject.totalItems === 0) {
        error = {
          severity: 'error',
          title: 'No data found',
          message: `Try to load valid data in "${objectdefinition.name}"`,
        };
        test = [];
      }

      // DUPLICATE ROW ID
      if (ids.length > 0) {
        error = {
          severity: 'warning',
          title: 'Duplicate Record ID',
          message: `Try to load ${ids.join(' - ')} once under "${idReference}" column`,
        };
        test = csv.filter((row) => !ids.includes(row?.[idReference]));
      }

      // COLUMN NOT FOUND IN OBJECT DEFINTION
      if (
        Object.keys(csv[0])
          .filter((col) => col !== idReference)
          .filter(
            (column: any) =>
              !Object.keys(properties)
                .map((prop) => toLower(prop))
                .includes(toLower(column)),
          ).length > 0
      ) {
        const allBads = Object.keys(csv[0])
          .filter((col) => col !== idReference)
          .filter(
            (column: any) =>
              !Object.keys(properties)
                .map((prop) => toLower(prop))
                .includes(toLower(column)),
          );
        error = {
          severity: 'warning',
          title: `${allBads.length > 1 ? 'Some' : 'A'} ${allBads.length > 1 ? 'columns' : 'column'}
            provided  ${allBads.length > 1 ? 'are' : 'is'} invalid in ${objectdefinition.name}`,
          message: `${allBads.join(' - ')}`,
        };
        test = csv.map((row) => omit(row, ...allBads));
      }

      test.map((row: any) =>
        Object.keys(row).map((column) => {
          if (column !== idReference && row[column] !== '') {
            const rightMachineName: string =
              objectdefinition && (getTheRightMachineName(objectdefinition, column) || column);
            const sourceValue: any = customObject.items?.find((list: any) => list.externalId === row?.[idReference])?.[
              rightMachineName
            ];
            const overwriteValue: any = row[column];

            table.push({
              id: row?.[idReference],
              column: rightMachineName,
              value: overwriteValue,
              sourceValue,
              type: objectdefinition.properties[rightMachineName]?.format === FormatsEnum.currency,
              symbol: customObject.items?.find((list: any) => list.externalId === row?.[idReference])?.currencyIsoCode,
            });
          }
          return result;
        }),
      );

      if (table.length >= MAX_TABLE_DATA) {
        error = {
          severity: 'warning',
          title: 'Too much data!',
          message: `You have more than ${MAX_TABLE_DATA} data to overwrites`,
        };
      }
    } catch (e) {
      return { status: (e as Error).message, data: [] };
    }
  }
  return { status: error, data: table };
};

/**
 *
 * @param csv
 * @param data
 * @param objectDefinition
 * @returns OverwriteRequest
 */

export const createAnOverwritableFormat = async (
  csv: any[],
  data: ITablePreview[],
  objectDefinition: CustomObjectDefinition,
) => {
  const overwriteToRequest: OverwriteContract[] = [];
  if (csv.length > 0 && data.length > 0) {
    const customObject = await retrieveCustomObject(csv, objectDefinition);

    if (customObject.totalItems > 0 && customObject.itemsWC.length > 0) {
      data.forEach((item) => {
        let result: any = {
          field: item?.column,
          appliesToExternalId: item?.id,
          appliesToDefinitionId: objectDefinition?.id,
          createdOnStatementId: '',
          statementId: '',
          fieldSchema: objectDefinition.properties?.[item?.column],
          sourceValue: {
            [item.column]: item.sourceValue,
          },
          overwriteValue: {
            [item.column]: KEYWORD.includes(item.value.toString())
              ? toLower(item.value.toString())
              : item.value.toString(),
          },
        };
        if (objectDefinition.properties[item.column]?.format === FormatsEnum.currency) {
          result = {
            ...result,
            currency: customObject.itemsWC[0]?.[item.column].symbol,
            sourceValue: {
              [item.column]: { value: item.sourceValue, symbol: customObject.itemsWC[0]?.[item.column]?.symbol },
            },
            overwriteValue: {
              [item.column]: {
                value: KEYWORD.includes(item.value.toString()) ? toLower(item.value.toString()) : Number(item.value),
                symbol: customObject.itemsWC[0]?.[item.column]?.symbol,
              },
            },
          };
        }
        overwriteToRequest.push(result);
      });
    }
  }
  return overwriteToRequest;
};
