import {
  IconBrackets,
  IconBracketsContainEnd,
  IconBracketsContainStart,
  IconEqual,
  IconPlayerRecord,
} from '@tabler/icons-react';

import {
  FormulaBuilderStringOperatorNoArgs,
  FormulaBuilderStringOperatorOneArg,
} from '@amal-ia/amalia-lang/formula/shared/types';
import { IconNot, type TablerIconElement } from '@amal-ia/frontend/design-system/components';

export const OPERATOR_ICON_MAPPING: Record<
  FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg,
  TablerIconElement
> = {
  [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: <IconPlayerRecord data-testid="icon-player-record" />,
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: <IconBrackets data-testid="icon-brackets" />,
  [FormulaBuilderStringOperatorOneArg.MATCHES]: <IconEqual data-testid="icon-equal" />,
  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: <IconBracketsContainStart />,
  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: <IconBracketsContainEnd />,
} as const;

export const NEGATIVE_OPERATOR_ICON_MAPPING: Record<
  FormulaBuilderStringOperatorNoArgs | FormulaBuilderStringOperatorOneArg,
  TablerIconElement
> = {
  [FormulaBuilderStringOperatorNoArgs.IS_BLANK]: (
    <IconNot
      data-testid="icon-not-player-record"
      icon={IconPlayerRecord}
    />
  ),
  [FormulaBuilderStringOperatorOneArg.CONTAINS]: <IconNot icon={IconBrackets} />,
  [FormulaBuilderStringOperatorOneArg.MATCHES]: <IconNot icon={IconEqual} />,
  [FormulaBuilderStringOperatorOneArg.STARTS_WITH]: <IconNot icon={IconBracketsContainStart} />,
  [FormulaBuilderStringOperatorOneArg.ENDS_WITH]: <IconNot icon={IconBracketsContainEnd} />,
} as const;
