import { Box, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { memo, useMemo, type ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { type AmaliaThemeType } from '@amal-ia/ext/mui/theme';
import { TokenType } from '@amal-ia/lib-types';

import { DesignerNewObjectModalItem } from './DesignerNewObjectModalItem';

const useStyles = makeStyles((theme: AmaliaThemeType) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    width: '175px',
    borderRadius: theme.spacing(0.5),
  },
  titleContainer: {
    padding: theme.spacing(1, 2),
  },
}));

export interface DesignerNewObjectModalProps {
  readonly anchorEl: HTMLElement | null;
  readonly isOpen: boolean;
  readonly handleCloseMenu: (event) => void;
  readonly modalTitle: ReactNode;
  readonly onCreate: (type: TokenType) => void;
}

export const DesignerNewObjectModal = memo(function DesignerNewObjectModal({
  anchorEl,
  isOpen,
  handleCloseMenu,
  modalTitle,
  onCreate,
}: DesignerNewObjectModalProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const modalOptions = useMemo(
    () => [
      { value: 'variable', label: formatMessage({ defaultMessage: 'Variable' }), type: TokenType.VARIABLE },
      { value: 'quota', label: formatMessage({ defaultMessage: 'Quota' }), type: TokenType.QUOTA },
      { value: 'field', label: formatMessage({ defaultMessage: 'Field' }), type: TokenType.FIELD },
      { value: 'filter', label: formatMessage({ defaultMessage: 'Filter' }), type: TokenType.FILTER },
      { value: 'link', label: formatMessage({ defaultMessage: 'Link' }), type: TokenType.LINK },
    ],
    [formatMessage],
  );

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={handleCloseMenu}
    >
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>{modalTitle}</Box>
        {modalOptions.map(({ type, value, label }) => (
          <DesignerNewObjectModalItem
            key={value}
            handleCloseMenu={handleCloseMenu}
            label={label}
            type={type}
            value={value}
            onCreate={onCreate}
          />
        ))}
      </Box>
    </Menu>
  );
});
